<template>
  <HeaderTrade/> 
  <!-- Container Start { -->
  <div id="container">
    <!-- Contents Start { -->
    <div id="contents">
      
      <div class="contents-area w-100">

        <!-- 리그 상세 > 안내 start -->
        <div class="league-view-top" v-if="spaceData">
          <div class="view-title">
            <p>{{spaceData.NAME}}</p>
            <span class="badge" :class="spaceData.ISPRIVATE=='Y'?'private':''">{{spaceData.ISPRIVATE=='Y'?'비공개':'공개'}}</span>
            <!-- <span class="badge private">비공개</span> -->
          </div>
          
          <div class="league-view-info">
            <dl class="view-info">
              <dt>대회 기간</dt>
              <dd>{{getChangeDateStr(spaceData.STARTDATE)+' ~ '+getChangeDateStr(spaceData.ENDDATE) }}</dd>
              <dt>총 상금</dt>
              <dd>{{Constant.UTIL.numberWithCommas(spaceData.REWARD)}} 원</dd>
              <dt>대회 종목</dt>
              <dd>{{getTradeType(spaceData.TYPE)}}</dd>
              <dt>참여 인원</dt>
              <dd>{{Constant.UTIL.numberWithCommas(spaceData.memberCount)}} 명</dd>
            </dl>
            
            <div class="view-btns" v-if="spaceData">
              <button type="button" class="btn btn-danger"   @click="onRegisterTrade" >{{getStatusStr(spaceStatus)}}</button>
              <button  v-if="spaceData.STATUS=='1'"      type="button" class="btn btn-primary"  @click="onGotoTrade" >거래하기</button>
            </div>
          </div>
        </div>
        <!-- //리그 상세 > 안내 end -->

        <!-- 리그 상세 > 탭 -->
        <div class="league-view-tab">
          <ul>
            <li><a href="javascript:;" @click="onLeagueTab(0)" :class="leagueTabIdx == 0 ? 'active' : ''">대회안내</a></li>
            <li><a href="javascript:;" @click="onLeagueTab(1)" :class="leagueTabIdx == 1 ? 'active' : ''">랭킹</a></li>
          </ul>
        </div>

        <!-- 리그 상세 > 대회안내 start -->
        <div class="league-view-main" v-if="leagueTabIdx == 0" >
          <div class="main">대회설명</div>
           <div v-if="spaceData" v-html="spaceData.INFO_CONTENTS"></div>
        </div>

        <!-- 리그 상세 > 랭킹 start -->
        <div class="league-view-rank" v-if="leagueTabIdx == 1">
         <!-- <MyBoard
            v-if="isLogin && spaceData"
            :spaceId="spaceData.ID"
            :tradeType="spaceData.TYPE"
            :isEventLeague="true"
          /> -->

          <RankBoard 
            v-if="spaceData"
            :spaceData="spaceData"           
          />

        </div>
        <!-- //리그 상세 > 랭킹 end -->

      </div>
    </div>
    <!-- } Contents End -->

  </div>
  <!-- } Container End -->

    <LeagueApplyModal
      v-if="showModal" 
      @close="closeModal" 
      @cancle="closeModal" 
      @ok="okModal" 
      :spaceData="spaceData"
      
    />

  <Footer/>
</template>

<script>
import moment from 'moment';
import { useStore } from 'vuex';
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import { ref, onMounted, computed, getCurrentInstance } from 'vue';
import { useRoute, useRouter}from 'vue-router'
import {useApicall} from '@/composables/commonFn';
import LeagueApplyModal from '@/components/popups/LeagueApplyModal.vue';
import Constant from '@/constant.js';
//import MyBoard from '@/components/ranking/MyBoard.vue';
import RankBoard from '@/components/ranking/RankBoard.vue';
import Config from '../../public/config.js';
import { event } from 'vue-gtag'

export default {
  components:{  
  HeaderTrade, 
  Footer,
  LeagueApplyModal,
 // MyBoard,
  RankBoard
},
  setup(){
    const store = useStore();
    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_eventLeague'); 
    store.commit('common/CHANGE_CURRENT_PAGE', 'EventLeague');
    const route = useRoute();    
    const router = useRouter(); 
    
    const {apiCall_g, apiCall_gt, apiCall_pt} = useApicall();
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    // const internalInstance = getCurrentInstance(); 
    // const emitter = internalInstance.appContext.config.globalProperties.emitter; 
    const isLogin = computed(() => store.state.user.isLogin);
    const memberData = computed(()=> store.state.user.memberData)
    const showModal = ref(false);
  
    const leagueTabIdx = ref(0)
    const spaceData = ref(null);
    const arrAttendSpaceList = ref([]);
    //0미참가 , 1참가하고 대회 시작전, 2참가하고 대회진입 가능
    const spaceStatus = ref(0);

    //const password = ref("")

    const getSpaceDetail = async(id)=>{
      var res = await apiCall_g(`index/getspacedetail?id=${id}`);
      if( !res.data ) res.data = null;
      //console.log("getSpaceDetail :", res)
      res.data.memberCount = res.memberCount;
      return res.data;  
    }  

    const getAttendSpacelist = async()=>{      
      var res = await apiCall_gt(`vspace/getaccountlist?isBeforeEnddate=Y&applyType=C`);
      if( !res.dataList ) res.dataList = [];
      return res.dataList;  
    }  

    onMounted(async ()=> {
    
      spaceData.value = await getSpaceDetail( route.params.id);  
      setSpaceStatus();
        
    })



    const setSpaceStatus = async () =>{
        arrAttendSpaceList.value =  await getAttendSpacelist();
        const find =  arrAttendSpaceList.value.find(element => element.SPACE_ID ==  spaceData.value.ID)
        if(find)
        {   
          let currnetTimeStp = moment(new Date()).format('X');
          let stratTimeStp = moment( spaceData.value.STARTDATE, 'YYYYMMDDHHmmss' ).format('X');

          if(currnetTimeStp < stratTimeStp)
          {
            // 신청하고 오픈전
             spaceStatus.value = 1; 
          }
          else
          {
            //신청하고 거래가능
             spaceStatus.value = 2; 
          }
        }      
        
        
      //  console.log("setSpaceStatus:~~~ ", spaceStatus.value)
    }


    const getStatusStr = (_spaceStatus) =>{


     // console.log("getStatusStr: ", _spaceStatus)
      var result = '';
      if(spaceData.value)
      {
        if(spaceData.value.STATUS == '3')
        {
          result='대기중'
        }
        else if(spaceData.value.STATUS == '9')
        {
          result='종료'
        }
        else if(spaceData.value.STATUS == '1')
        {
         // console.log("!!!!!!!!!!!!!",spaceStatus.value)
          if(_spaceStatus == 0) 
          {
             result='참가신청';
          }
          else if(_spaceStatus == 1) 
          {
             result='참가대기중';
          }
          else if(_spaceStatus == 2) 
          {
            result='참가중';
          }
        }
      }
     return result;      
    }

    const closeModal =()=>{     
      showModal.value = false; 
    }


    const okModal = async(pass) =>{
     // console.log("okModal:", pass)
      showModal.value = false;
      var id = spaceData.value.ID;
      var params = {
         "spaceId": id,
         "pCode": pass

      }
      var res =  await apiCall_pt('vspace/apply', params, null, (message)=>{
          emitter.emit('EVENT_ALERT',{title:"알림", body:message});
      });
      if(res)
      {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"모의투자에 성공적으로 신청되었습니다."});
          setSpaceStatus();
         // store.dispatch('hts/setApplySpace'); 
         // getVContestData()
          event('participate', { 'ID': memberData.value.MID, 'competitions_name': 'event_' + getSpaceTypeStr(res.spaceType), 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss')});
          window.fbq('trackCustom', 'participate', {'ID': memberData.value.MID,  'competitions_name': 'event_' + getSpaceTypeStr(res.spaceType) , 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss') });
          console.log("event~ :", memberData.value.MID, 'competitions_name', 'event_' + getSpaceTypeStr(res.spaceType),  moment(new Date()).format('YYYYMMDD HH:mm:ss'))

      } 
    }

    const getSpaceTypeStr = (_type) =>{
      var result = null;
      if(_type == 'S')
      {
          result = 'Stock'
      }
      else if(_type == 'F')
      {
          result = 'Futures'
      }
      else if(_type == 'C')
      {
          result = 'Crypto'
      }
      else if(_type == 'G')
      {
          result = 'CoinFutures'
      }
      return result     
    }

    const goLoginPage = ()=>{   
      location.href = Config.URL.flexLifeUrl + `login?app=money&redirect_url=${Config.URL.flexMoneyUrl}`;
    }


    const onRegisterTrade =  () => {     
        if(!isLogin.value)
        {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.", fn:goLoginPage});   
        }
        else
        {
          if(spaceData.value.STATUS == '1')
          {
            
            if(spaceStatus.value == 0)
            {
                showModal.value = true;
            }
            else if(spaceStatus.value == 1)
            {
                emitter.emit('EVENT_ALERT',{title:"알림", body:"이미 신청한 상태입니다. 대회 시작전 입니다."});
            }
            else if(spaceStatus.value == 2)
            {
                emitter.emit('EVENT_ALERT',{title:"알림", body:"이미 참가한 상태입니다."});
            }
          }
          else if(spaceData.value.STATUS == '3')
          {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"대기중인 대회입니다."});
          }
          else if(spaceData.value.STATUS == '9')
          {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"대회가 종료 되었습니다."});
          }
       
        }
    }

    const onGotoTrade = () =>{
        if(!isLogin.value)
        {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.", fn:goLoginPage});
          return;
        }

        if(spaceStatus.value == 0)
        {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"참가신청 후 사용가능합니다."});
          return;
        }


       // console.log("onGotoTrade :", spaceData.value)
        var type = spaceData.value.TYPE
        var name = '';
        if(type == "S")
        {
          name = 'VtradeStock';
        }
        else if(type == "F")
        {
          name = 'VtradeFutures';
        }
        else if(type == "C")
        {
          name = 'VtradeCoin';
        }
        else if(type == "G")
        {
          name = 'VtradeCoinFutures';
        }
       

        router.push({
           name: name,
           params: {
             id:spaceData.value.ID
           }
        }) 

    }

    const onLeagueTab = (e) => {
      leagueTabIdx.value = e;
      // console.log('onLeagueTab=====>', e, leagueTabIdx.value)
    }

    const getChangeDateStr =(str)=>{  
       return moment(str, "YYYYMMDDhhmmss").format("YYYY.MM.DD");
    }

    const getTradeType =(type)=>{  
      var result = ""
      if (type == 'S') result="주식거래"
      else if(type == 'F') result="선물거래"
      else if(type == 'C') result="코인거래"
      else if(type == 'G') result="코인선물거래"

      return result;
    }

    return {
      showModal,
      closeModal,
      okModal,
      onRegisterTrade,
 
      onLeagueTab,
      leagueTabIdx,
      spaceData,
      getChangeDateStr,
      getTradeType,
      Constant,
      isLogin,
      //password,    
      getStatusStr,
      onGotoTrade,
      spaceStatus
    }
  }
}
</script>

<style>

</style>