<template>
        <div class="main-rank-inner">
            <h4>{{title}}</h4>

            <div class="rank-select">
                <select class="form-select form-select-sm" ref="rSeletBox" @change="onSelectChange">
                <option                 
                    v-for="(item, index) in arrSpaceList"      
                    :key = index
                    :value="item.ID" 
                 >
                 {{item.NAME}}                 
                </option>
                <!-- <option value="kstock1">제 2회 주식 모의투자 대회</option> -->
                </select>                  
            </div>

            <table class="table">
                <thead>
                <tr>
                    <th>닉네임</th>
                    <th>{{tradeType=='G'?'손익(USDT)':'손익(원)'}}</th>
                    <th>수익률</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in arrRankingList"      
                    :key = index   
                    :class="getColor(Number(getProfitPer(Number(item.TOTAL_PROFIT), Number(item.PAYMONEY))))"
                >
                    <td scope="row">
                    <span class="nickname text-truncate">
                      <UserNickGrade
                        :itemGrade="item.GRADE"
                        :itemGradeRank="item.GRADE_RANK"
                        :itemNickName="item.MEMBER_NICK"
                      />                      
                    </span>
                    </td>
                    <td>
                    <span class="rate text-truncate">{{Constant.UTIL.numberWithCommas(Number(item.TOTAL_PROFIT).toFixed(tradeType=='G'?2:0))}}</span>
                    </td>
                    <td>
                    <span class="per">
                        <i></i>{{Constant.UTIL.numberWithCommas(Math.abs(getProfitPer(Number(item.TOTAL_PROFIT), Number(item.PAYMONEY))).toFixed(2)) + '%'}}
                    </span>
                    </td>
                </tr>
                <!-- <tr class="up">
                    <td scope="row">
                    <span class="nickname text-truncate">NFTLOVE</span>
                    </td>
                    <td>
                    <span class="rate text-truncate">159,482,200</span>
                    </td>
                    <td>
                    <span class="per">
                        <i></i>958%
                    </span>
                    </td>
                </tr> -->
                </tbody>
            </table>
        </div>
</template>

<script>
import {useApicall} from '@/composables/commonFn';
import Constant from '@/constant.js';
import {onMounted, ref} from 'vue';
import UserNickGrade from '@/components/UserNickGrade.vue';

export default {
  components:{
    UserNickGrade,
  },  
    props:{
      title:{
        type:String,
        required:true
      },
      tradeType:{
        type:String,
        required:true
      }

    },
    setup(props){
        //console.log("props", props)
        const {apiCall_g} = useApicall();
        const rSeletBox = ref(null);
        const arrSpaceList = ref([]);
        const arrRankingList = ref([]);
         const getSpacelist = async()=>{
            var res = await apiCall_g(`index/getspacelist?type=${props.tradeType}&status=1&applyType=A`);
            return res.dataList;  
         }

         onMounted(async()=>{
            arrSpaceList.value = await getSpacelist();
            setTimeout(async ()=>{
               
               arrRankingList.value =  await getRankList(rSeletBox.value.value);
               //console.log("arrRankingList.value :", arrRankingList.value )
            },500)
             
         })


         const getRankList = async (spaceId) =>{
           var res = await apiCall_g(`index/getrankinglist?spaceId=${spaceId}&below_rank=10`);
           return res.dataList;  
         }

         const onSelectChange = async ()=>{
           // console.log("onSelectChange:")
             arrRankingList.value =  await getRankList(rSeletBox.value.value);            
         }

         const getProfitPer = ( totalProfit, paymoney)=>{
               return (totalProfit/paymoney)*100
         }


         const getColor= (num)=>{
            var  result = ''
           if(num > 0)
           {
             result='up'
           }
           else if(num < 0)
           {
              result='down'
           }
           return result;
         }
    
 
        return{
           arrSpaceList,
           rSeletBox,
           onSelectChange,
           arrRankingList,
           Constant,
           getColor,
           getProfitPer
        }
    }

}
</script>

<style>

</style>