<template>   
    <Modal> 
        <template #title>
             삭제 안내
        </template>

        <template #body>
            <div class="sts-apply-popup">
                <p>등록하신 글을 삭제하시겠습니까?</p>
            </div>
        </template>

        <template #footer>
            <button
                type="button" 
                class="btn btn-outline-secondary" 
                @click="onCancle"
            >
                취소
            </button>
            <button 
                type="button" 
                class="btn btn-danger"
                @click="onOk"
            >
                확인
            </button>
        </template>     
    </Modal>
    
</template>

<script>
import Modal from "@/components/popups/Modal.vue"
export default {
  components:{
      Modal
  },
  props:{
    
  },
  emits:['cancle', 'ok'],
  setup(props, {emit}){
  
     const onCancle = () =>{
        emit('cancle')
     }

     const onOk = () =>{
        emit('ok')
     }
     return {
         onCancle,
         onOk,
         props,
     }
   }

}
</script>

<style>

</style>