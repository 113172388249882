import axios from '@/axios';
import { useStore } from 'vuex';
import { getCurrentInstance} from 'vue';



export const useCommonFunction =  ()=>{  
  
    const getPriceGap = (price, item) =>{
       
       var result;
       if(item.TYPE == 'F')
       {
           let price_gap = Number(item.HOGAUNITPRICE)
           if(!price_gap) price_gap = 0.05;
           result = price_gap;
       }
       else if(item.TYPE == 'S')
       {
             if(price < 2000)
             {
                 result =  1;
             }
             else if(price < 5000)
             {
                 result =  5;
             }
             else if(price < 20000)
             {
                 result =  10;
                
             }
             else if(price < 50000)
             {
                 result =  50;
                
             }
             else if(price < 200000)
             {
                 result =  100;
             }
             else if(price < 500000)
             {
                 result =  500;
             }
             else
             {
                 result =  1000;
             }      
           
       }
       else if(item.TYPE == 'C')
       {       

         //  console.log("ssss~~:", price)
           if(price < 1)
           {
               result =  0.00001;
           }
           else if(price < 10)
           {
               result =  0.001;
           }
           else if(price < 100)
           {
               result =  0.01;
           }
           else if(price < 1000)
           {
               result =  0.1;
           }
           else if(price < 10000)
           {
               result =  1;
           }
           else if(price < 100000)
           {
               result =  10;
           }
           else if(price < 500000)
           {
               result =  50;
           }
           else if(price < 1000000)
           {
               result =  100;
           }
           else if(price < 2000000)
           {
               result =  500;
           }                
           else
           {
               result =  1000;
           }
       }
      // console.log("getPriceGap:", price, result)
       return result;
   }

   const getNdotposCoin = (price) =>{
      //console.log("getNdotposCoin:", price)
      
      var result  = 0
      if(price < 1)
      {
         result  = 5;
      }
      else if(price < 10)
      {
         result  = 3;
      }
      else if(price < 100)
      {
         result  = 2;
      }
      else if(price < 1000)
      {
         result  = 1;
      }
      return result;
   }
 
 
    return {
       getPriceGap,
       getNdotposCoin
    }
   
 }


export const useSpaceId =  ()=>{  
    var store = useStore();
   
      const internalInstance = getCurrentInstance(); 
      const emitter = internalInstance.appContext.config.globalProperties.emitter;

      const  setCurrentSpaceId = async (type)=>{
         try{            
             const res = await axios.get(`index/getspacelist?type=${type}&status=1&applyType=A`);
             let json =  res.data; 
             if (json.returnCode == 1) {
                if (json.resultMap.returnVal == 1) { 
                       if(!json.resultMap.dataList || json.resultMap.dataList.length == 0)
                       {
                         emitter.emit('EVENT_INIT_TRADE_FOR_SPACE',{id:null, type:type});
                       }
                       else
                       {                       
                         setAccountId(json.resultMap.dataList[0].ID)
                         emitter.emit('EVENT_INIT_TRADE_FOR_SPACE', {id:json.resultMap.dataList[0].ID, type:type});
                         store.commit('hts/CHANGE_CURRENT_SPACE_NAME', json.resultMap.dataList[0].NAME);                          
                       }
                     
                } else {    
                     emitter.emit('EVENT_INIT_TRADE_FOR_SPACE',{id:null, type:type});  
                  //   console.log(json.message)
                }
             }
             else
             {
                
                console.log("error", json.message);               
             }  
         }catch(error){     
            console.log(error);
           
                 
            return false;           
         }
      }

      const  setCurrentSpaceIdForEvent = async (type, id)=>{

         try{            
             const res = await axios.get(`index/getspacelist?type=${type}&status=1&applyType=C`);
             let json =  res.data; 
             if (json.returnCode == 1) {
                if (json.resultMap.returnVal == 1) { 
                       if(!json.resultMap.dataList || json.resultMap.dataList.length == 0)
                       {
                         emitter.emit('EVENT_INIT_TRADE_FOR_SPACE',{id:null, type:type});
                       }
                       else
                       {            
                        
                        var found = json.resultMap.dataList.find(element => element.ID == id)



                         setAccountId(found.ID)
                         emitter.emit('EVENT_INIT_TRADE_FOR_SPACE', {id:found.ID, type:type});
                         store.commit('hts/CHANGE_CURRENT_SPACE_NAME', found.NAME);                          
                       }
                     
                } else {    
                     emitter.emit('EVENT_INIT_TRADE_FOR_SPACE',{id:null, type:type});  
                  //   console.log(json.message)
                }
             }
             else
             {
                
                console.log("error", json.message);               
             }  
         }catch(error){     
            console.log(error);
           
                 
            return false;           
         }
      }
 
    // const showPopup = (txt_title, txt_body) =>{        
    //     emitter.emit('EVENT_ALERT',{title:txt_title, body:txt_body});
    // }
 
    const  setAccountId = async (spaceId)=>{   
        if(spaceId == null)return;
        var token = sessionStorage.getItem('token');
      // if(!token)showPopup('알림', '로그인후 사용가능합니다.')
           
       try{         
          const res = await axios.get(`vspace/getaccountbyspaceid?spaceId=${spaceId}`,  {
             headers: {
                  'token': token
             }
          });
          let json =  res.data; 
          if (json.returnCode == 1) {
             if (json.resultMap.returnVal == 1) {                      
                   if(json.resultMap.data)
                   {                     
                      store.commit('hts/CHANGE_RETRY_COUNT', json.resultMap.data.RETRYCOUNT);   
                      store.commit('hts/CHANGE_RE_COUNT', json.resultMap.data.RECOUNT); 
                      store.commit('hts/CHANGE_ACCOUNT_ID', json.resultMap.data.ID);               
                   }                                       
             } else {                    
                  // console.log(json.message) 
                   store.commit('hts/CHANGE_ACCOUNT_ID', null);        
             }
          }
          else if(json.returnCode == -102 )
          {
           // console.log("로그인이 필요합니다.");
          }
          else
          {
            
             console.log("error", json.message);               
          }  
       }catch(error){     
          console.log(error);
        alert("getaccountbyspaceid:" +error)
             
          return false;           
       }  
    }
 
 
   //  const  getSpaceListForType = async (type)=>{
   //     var result = null;
   //     try{            
   //         const res = await axios.get(`index/getspacelist?type=${type}`);
   //         let json =  res.data; 
   //         if (json.returnCode == 1) {
   //            if (json.resultMap.returnVal == 1) { 
   //                   if(!json.resultMap.dataList)
   //                   {
   //                     result = null;
   //                   }
   //                   else
   //                   {                      
   //                     result = json.resultMap.dataList;
   //                   }                    
   //                  return result;
   //            } else {    
   //             // console.log(json.message)     
   //                 return result;
   //            }
   //         }
   //         else
   //         {
   //         // console.log(json.message)     
   //            console.log("error", json.message);    
   //            return result;           
   //         }  
   //     }catch(error){     
   //        console.log(error);
         
               
   //        return false;           
   //     }
   //  }
 
 
 
 
       return {
          setCurrentSpaceId,
          setCurrentSpaceIdForEvent,
          setAccountId,
      //    getSpaceListForType,
       }
 }
 



export const useBalance =  ()=>{  
    const internalInstance = getCurrentInstance(); 
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    emitter
    // const showPopup = (txt_title, txt_body) =>{        
    //      emitter.emit('EVENT_ALERT',{title:txt_title, body:txt_body});
    // }

    var token = sessionStorage.getItem('token');
   // if(!token)showPopup('알림', '로그인후 사용가능합니다.')
             
    
    var store = useStore();    
    const  setCurrentBalance = async (spaceId)=>{ 
       var dummyData = {BALANCE: "0.00",                  
                   PAYMONEY: "0.00",
                   RECOUNT: "0",
                   }
       if(spaceId == null)
       {
          store.commit('hts/CHANGE_CURRENT_BALANCE', dummyData);
          return;
       }
      try{ 
          const res = await axios.get(`vspace/getaccountbyspaceid?spaceId=${spaceId}`,{
             headers: {
                  'token': token
             }
          } );
          let json =  res.data;              
          if (json.returnCode == 1) { 
             if (json.resultMap.returnVal == 1) {                  
                   store.commit('hts/CHANGE_CURRENT_BALANCE', json.resultMap.data);                     
             } else { 
                   store.commit('hts/CHANGE_CURRENT_BALANCE', dummyData); 
                              
             }
          }
          else if(json.returnCode == -102 )
          {
              //alert("로그인이 필요합니다.");
          }
          else
          {
            
             console.log("error", json.message);               
          }  
      }catch(error){     
         console.log(error);
        
              
         return false;           
      }  
   }
 return {
    setCurrentBalance,
 }

}



export const useGetItemList =  ()=>{  
   
      var store = useStore();    
      const  getItemList = async (_type, items, fn = null)=>{
          try{
             const res = await axios.get(`index/getitemlist?type=${_type}`);   
             let json =  res.data;       
             if (json.returnCode == 1) {
                if (json.resultMap.returnVal == 1) {  
                      var dataList = json.resultMap.dataList;
                      if(_type == "F")
                      {
                         store.commit('hts/CHANGE_CURRENCY_INFO', json.resultMap.currencyInfo);     
                         var newItems = [];
                         for(var i=0; i<dataList.length; i++)
                         {
                            var item = dataList[i];                           
                            if(item.SUBTYPE == "K")
                            {
                               let _name = item.KORNAME
                               item.KORNAME = _name + " (주간)";
                               item.isNight = false;
                               newItems.push(item);
                               var item2 = Object.assign({},item )
                               item2.KORNAME = _name + " (야간)"
                               item2.isNight = true;
                               newItems.push(item2);                              
                            } 
                            else
                            {
                               newItems.push(item);  
                            }
                         }
                         items.value = newItems;                         
                         store.commit('hts/CHANGE_CURRENT_ITEM_DATAS', newItems);  
                         if(fn) fn();
                      }
                      else{
                         store.commit('hts/CHANGE_CURRENT_FEES', json.resultMap.fees); 
                         store.commit('hts/CHANGE_CURRENT_ITEM_DATAS', dataList);   
                         items.value = dataList;

                         if(fn) fn();
                      }
                     // triggerToast('Successfully getItem');
                } else {
                    console.log(json.message)
                    //  triggerToast(json.message, 'danger');
                }
             }
             else if(json.returnCode == -102 )
            {
               // console.log("로그인이 필요합니다.");
            }
             else
             {
                console.log(json.message)
              //  triggerToast(json.message, 'danger');
             }               
          }catch(error){
           
               // triggerToast('Something went wrong! getItemList', 'danger');
                console.log(error);
          }  
      }
    return {
       getItemList,
    }
   
 }
 