<template>
  <HeaderTrade/> 
  <!-- Container Start { -->
  <div id="container">
    <!-- Contents Start { -->
    <div id="contents">

      <!-- content start -->
      <div class="contents-area">
        
        <div class="section ground-list-title">
          <div class="title">1:1문의</div>
          <div v-if="isLogin">
            <router-link :to="{ name: 'QnaWrite'}"  class="btn btn-sm btn-outline-dark">1 : 1 문의하기</router-link>
          </div>
        </div>
  
        <!-- qna start { -->
        <div class="qna">
          <div
            v-for="(item, index) in qnaLists"
            :key = index           
          >
            <div
              class="qna-list-box"
              v-if="item.REPLY_OK === 'Y'"
              :class="{'active' : item.isActive}"
            >
              <div class="qna-title" @click="item.isActive = !item.isActive">
                  <span class="btn btn-sm btn-outline-gray status success">답변완료</span>
                  <!-- <span class="category">프로모션</span> -->
                  <span class="txt text-truncate">{{ item.SUBJECT }}</span>
                  <span class="chevron"><i></i></span>
              </div>
              <div class="qna-area">
                  <div class="qna-q">
                      <strong>문의</strong>
                      <div class="question">
                          <h4 class="title">{{ item.SUBJECT }}</h4>
                          <div class="mt-20" v-html="item.CONTENTS"></div>
                      </div>
                  </div>
                  <div class="qna-a">
                    <strong>답변</strong>
                    <div class="answer">
                      <div v-html="item.REPLY"></div>
                    </div>
                </div>
              </div>
            </div>

            <div 
              v-else
              class="qna-list-box"
              :class="{'active' : item.isActive}"
            >
              <div class="qna-title" @click="item.isActive = !item.isActive">
                  <span class="btn btn-sm btn-outline-gray status ready">답변대기</span>
                  <!-- <span class="category">프로모션</span> -->
                  <span class="txt text-truncate">{{ item.SUBJECT }}</span>
                  <span class="chevron"><i></i></span>
              </div>
              <div class="qna-area">
                  <div class="qna-q">
                      <strong>문의</strong>
                      <div class="question">
                          <h4 class="title">{{ item.SUBJECT }}</h4>
                          <div class="mt-20" v-html="item.CONTENTS"></div>
                      </div>
                      <div class="qna-btn-box">
                        <router-link :to="{ name:'QnaWrite', params:{ id: item.IDX }}">수정</router-link>
                        <button type="button" class="btn btn-sm btn-danger" @click="onDelete(item.IDX)">삭제</button>
                      </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="qna-list-box none" v-if="!qnaLists.length">
            문의 사항이 없습니다.
          </div>
        </div>

        <!-- 페이징 start -->
        <div class="section paging-area" v-if="qnaLists.length">
          <v-pagination                  
            v-model="currentPage"
            :pages= numberOfPages
            :rangeSize="1"
            activeColor="#ff5d8b"                   
            @update:modelValue="updateHandler(currentPage)"
          />
        </div>
        <!--// 페이징 end -->

      </div>
      <!-- //content end -->  

      <!-- aside start -->
      <div class="aside-area">

        <ToprankTradeSide />
        <GotradeLive />
        <StocklistSide />

      </div>
      <!-- //aside end -->

    </div>
    <!-- } Contents End -->

  </div>
  <!-- } Container End -->

  <boardDeleteModal
      v-if="showModal" 
      @close="closeModal" 
      @cancle="closeModal" 
      @ok="okModal" 
  />  
  
  <Footer/>
</template>

<script>

import { useStore } from 'vuex';
// import { useRouter }from 'vue-router';
import { onMounted, ref, computed, getCurrentInstance } from 'vue';
import { useApicall } from '@/composables/commonFn';
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import GotradeLive from '@/components/home/GotradeLive.vue';
import StocklistSide from '@/components/home/Stocklist_side.vue';
import ToprankTradeSide from '@/components/home/ToprankTrade_side.vue';
import VPagination from "@hennge/vue3-pagination";
import '@/assets/css/vue3-pagination.css';
import boardDeleteModal from '@/components/popups/boardDeleteModal.vue';

export default {
    components:{  
    HeaderTrade, 
    Footer,
    StocklistSide,
    GotradeLive,
    ToprankTradeSide,
    VPagination,
    boardDeleteModal,
  },

  setup(){
    // const trace = getCurrentInstance().appContext.config.globalProperties.$log;    
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    // const router = useRouter();
    const store = useStore();
    const { apiCall_gt } = useApicall();
    const qnaLists = ref([]);
    const limit = ref(10);
    const currentPage = ref(1)
    const numberOfData = ref(0);
    const numberOfPages = computed(()=>{
      return Math.ceil(numberOfData.value/limit.value)
    });
    const isLogin = computed(() => store.state.user.isLogin);
    const showModal = ref(false);
    const boardItemId = ref('');

    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_Qna'); 
    store.commit('common/CHANGE_CURRENT_PAGE', 'Qna'); 

    onMounted(async ()=> {      
      emitter.emit('EVENT_CHECK_LOGIN');    
      updateHandler(1);
    })

    const updateHandler = (e) => {
      getQnaList(e);
    }

    const getQnaList = async(page = currentPage.value) => {
      currentPage.value = page; 
      var offset = (currentPage.value -1)*limit.value;
      var getUrl = `board/counselinglist?limit=${limit.value}&offset=${offset}`;
      var qnaDataList = await apiCall_gt(getUrl)

      try {
        if(qnaDataList.dataList == null) qnaDataList.dataList = [];
        qnaLists.value = qnaDataList.dataList;
        numberOfData.value = qnaDataList.dataCount;
      } catch(error) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인이 필요합니다.\n로그인 후 이용하여 주십시오."});
        // alert('로그인이 필요합니다.\n로그인 후 이용하여 주십시오.');
        return false;
      }            
    }

    const deletCall = async (id) =>{
      var res  =  await apiCall_gt(`board/counselingdelete/${id}`, 'DEL');
      if(res) { 
        // trace('deletCall', id);
        emitter.emit('EVENT_ALERT',{title:"알림", body:"1:1 문의글이 삭제되었습니다."});
      }
      updateHandler(1);
    }
    
    const onDelete = (id) => {
      showModal.value = true;
      var getId = id;
      boardItemId.value = getId;
    }    

    const closeModal =()=>{
      showModal.value = false; 
    }    

    const okModal = async() => {
      showModal.value = false; 
      deletCall(boardItemId.value);
      emitter.emit('EVENT_ALERT',{title:"알림", body:"1:1 문의글이 삭제되었습니다."});
      boardItemId.value = '';
    }

    return{
      qnaLists,
      isLogin,
      currentPage,
      numberOfPages,
      updateHandler,
      onDelete,
      showModal,
      closeModal,
      okModal,
      boardItemId,
    }
  }
}
</script>

<style>

</style>