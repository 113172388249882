<template>
  <HeaderTrade/> 
  <!-- Container Start { -->
  <div id="container">
    <!-- Contents Start { -->
    <div id="contents">

      <!-- content start -->
      <div class="contents-area">

        <div class="section ground-list-title">
          <div class="title">{{ getTitle() }}</div>
          <div class="list-title-select">
            <select class="form-select form-select-sm" aria-label="select" @change="onSelectChange">
              <option value="10" selected>10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>

        <div class="section ground-list-inner">
          <!-- 광고 및 공지사항 리스트 start -->
          <table class="table">
            <colgroup>
              <col style="width:65%">
              <col style="width:14%">
              <col style="width:14%">
              <col style="width:7%">
            </colgroup>
            <thead>
              <th scope="col" class="text-start px-3">제목</th>
              <th scope="col">작성자</th>
              <th scope="col">작성일</th>
              <th scope="col">조회</th>
            </thead>
            <tbody>
              <tr 
                  v-for="(item, index) in boardList"
                  :key = index
              >
                <td class="text-start px-3">
                  <router-link :to="{ name:'NoticeView',  params:{ id:item.IDX }}"  class="link">
                    <span class="txt text-truncate">
                      {{item.SUBJECT}}
                    </span>
                  </router-link>
                </td>
                <td>
                  <span class="writer text-truncate">FLEXMONEY</span>
                </td>
                <td>{{item.CREATIONDATE.split(' ')[0].replaceAll('-','.')}}</td>
                <td>{{item.COUNT}}</td>
              </tr>
              <tr>
                <td v-if="!boardList.length" colspan="4">게시글이 없습니다.</td>
              </tr>
            </tbody>
          </table>              
          <!-- //광고 및 공지사항 리스트 end -->

        </div>

        <!-- 검색 영역 / 글쓰기 버튼 start -->
        <div class="section ground-list-control">
          <div class="control-select">
            <select class="form-select form-select-sm" v-model="srchType">
              <option value="search_like" selected="selected">제목+내용</option>
              <option value="subject_like">제목</option>
              <option value="contents_like">내용</option>
            </select>
          </div>
          <div class="control-search">
            <input type="text" class="form-control form-control-sm" aria-label="search" id="inputSearch" v-model="srchKeyword" @keyup.enter="srchCommunity">
            <button class="btn-search" type="button"><img src="@/assets/images/common/ico_header_search.png" alt="search" @click="srchCommunity"></button>
          </div>
        </div>
        <!-- //검색 영역 / 글쓰기 버튼 end -->

        <!-- 페이징 start-->
        <div class="section paging-area" v-if="boardList.length">
           <v-pagination                  
                v-model="currentPage"
                :pages= numberOfPages
                :rangeSize="1"
                activeColor="#ff5d8b"                   
                @update:modelValue="updateHandler(currentPage, categoryType, categoryCode)"
            />
        </div>
        <!-- //페이징 end -->        

      </div>
      <!-- //content end -->  

      <!-- aside start -->
      <div class="aside-area">

        <!-- <CommLoginSide /> -->
        <ToprankTradeSide />
        <GotradeLive />
        <StocklistSide />

      </div>
      <!-- //aside end -->

    </div>
    <!-- } Contents End -->

  </div>
  <!-- } Container End -->
  <Footer/>
</template>


<script>
import { useStore } from 'vuex';
import { ref, onMounted, computed, getCurrentInstance, onUnmounted } from 'vue';
import { useApicall } from '@/composables/commonFn';
import { useRouter }from 'vue-router'
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import GotradeLive from '@/components/home/GotradeLive.vue';
import StocklistSide from '@/components/home/Stocklist_side.vue';
import ToprankTradeSide from '@/components/home/ToprankTrade_side.vue';
import VPagination from "@hennge/vue3-pagination";

export default {
  components:{  
    HeaderTrade, 
    Footer,
    StocklistSide,
    GotradeLive,
    ToprankTradeSide,
    VPagination
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_notice'); 
    store.commit('common/CHANGE_CURRENT_PAGE', 'Notice');    
    const boardList = ref([]);
    const limit = ref(10);
    const currentPage = ref(1);
    const numberOfData = ref(0);
    const numberOfPages = computed(()=>{
      return Math.ceil(numberOfData.value/limit.value)
    });
    const { apiCall_g } = useApicall();

    const categoryType = ref('aboard');
    const categoryCode = ref('notice');
    const noticeList = ref([]);
    const isLogin = computed(() => store.state.user.isLogin);
    const currentPage_store = computed(() => store.state.common.noticeCurrentPage);

    const page_store = computed(() => store.state.common.currentPage);
    const lastPath = router.options.history.state.back;

    const srchType = ref('search_like');
    const srchKeyword = ref('');
    const boardIdx = ref(0);
    
    onMounted(async ()=> {
      emitter.emit('EVENT_CHECK_LOGIN');  
      updateHandler(currentPage_store.value, categoryType.value, categoryCode.value);
    })

    const updateHandler = (e,  _cType, _cCode) => {
      getDataList(e, _cType, _cCode);
    }

    const getDataList  = async( page = currentPage.value, categoryType, categoryCode ) =>{    
      currentPage.value = page; 
      store.commit('common/CHANGE_NOTICE_CURRENT_PAGE', page);

      var offset = (currentPage.value -1)*limit.value;
      var getUrl = `board/list?limit=${limit.value}&offset=${offset}&categoryType=${categoryType}&categoryCode=${categoryCode}`;

      if( srchKeyword.value ) {
        var addurl = `&${srchType.value}=${srchKeyword.value}`;
        getUrl += addurl;
      }

      var boardDataList = await apiCall_g(getUrl)

      try {
        if(boardDataList.dataList == null) boardDataList.dataList = [];
        boardList.value = boardDataList.dataList;
        numberOfData.value = boardDataList.dataCount;
      } catch (error) {
        boardList.value = [];
        numberOfData.value  = 0;
      }
    }

    const onSelectChange = (e) => {
      limit.value = e.target.value;
      updateHandler(1, categoryType.value, categoryCode.value)
    }

    const srchCommunity = () => {
      updateHandler(1, categoryType.value, categoryCode.value);
    }

    const getTitle = () => {
      var  result = null;
      if(categoryCode.value == "notice") {
        result = '공지사항'
      }
      return result;
    }

    onUnmounted(()=> {     
      if(page_store.value !== 'Notice') {
        store.commit('common/CHANGE_NOTICE_CURRENT_PAGE', 1);
      }
    })

    return {
      isLogin,
      numberOfPages,
      boardList,
      currentPage, 
      updateHandler,
      onSelectChange,
      categoryType,
      categoryCode,
      noticeList,
      srchType,
      srchKeyword,
      srchCommunity,
      getTitle,
      boardIdx,
      lastPath,
      page_store,
    }
  }
}
</script>

<style>

</style>