<template>
            <!-- login :: 로그인 후 start -->
            <div class="section login mypage">
              <div class="aside-user-info">
                <!-- <div class="badge-box">
                  <span class="badge badge-bg-10">100</span>
                </div> -->
                <span class="user text-truncate" v-if="memberData">
                  <UserNickGrade
                    :itemGrade="memberData.GRADE"
                    :itemGradeRank="memberData.GRADE_RANK"
                    :itemNickName="memberData.NICK"
                  />
                </span>
                <div class="logout-btn">
                  <!-- <button @click="onClickModifyProfile" type="button" class="btn btn-outline-secondary btn-sm mr-2">내 정보 변경</button> -->
                  <button @click="onLogout" type="button" class="btn btn-danger">로그아웃</button>
                </div>
              </div>
              
              <div class="aside-mypage-count">
                <ul>
                  <li>
                    <span>게시글</span>
                    <span>{{ myPostCnt }}</span>
                  </li>
                  <li>
                    <span>댓글</span>
                    <span>{{ myCommentCnt }}</span>
                  </li>
                  <li>
                    <span>누적 추천수</span>
                    <span>{{ myLikeCnt }}</span>
                  </li>
                  <li>
                    <span>체결 알림</span>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="chk01" ref="alarmCheckBox" @change="onAlarmChange">
                      <label class="form-check-label" for="chk01"></label>
                    </div>
                  </li>
                  <li>
                    <span>댓글 알림</span>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="chk02" ref="replyAlarmCheckBox" @change="onReplyAlarmChange">
                      <label class="form-check-label" for="chk02"></label>
                    </div>
                  </li>
                  <li>
                    <span>우승 횟수</span>
                    <span>{{ memberData.RANK_1 }}</span>
                  </li>                  
                </ul>
              </div>
              
            </div>
            <!-- //login :: 로그인 후 end --> 

            <div class="section mypage-level-info">
              <ul class="info">
                <li>
                  <span>현재 등급</span>
                  <div class="community-level-box">
                    <span class="level" :class=" 'lv-' + gradeArray.GRADE">
                      <i class="visually-hidden">레벨</i>
                    </span>
                  </div>
                  <span>{{ gradeName }}</span>
                </li>
                <li>
                  <span class="level-arrow">다음등급</span>
                </li>
                <li>
                  <span>다음 등급</span>
                  <div class="community-level-box">
                    <span class="level" :class=" 'lv-' + gradeNextArray.GRADE">
                      <i class="visually-hidden">레벨</i>
                    </span>
                  </div>
                  <span>{{ gradeNextName }}</span>
                </li>
              </ul>

              <div class="progress-box">
                <div class="point">
                  <span>{{ Constant.UTIL.numberWithCommas(Number(gradeArray.STARTSCORE)) }}p</span>
                  <span>{{ Constant.UTIL.numberWithCommas(Number(gradeArray.ENDSCORE)) }}p</span>
                </div>
                <div class="progress">
                  <div class="progress-bar bg-danger" role="progressbar" :style="'width:' + progressBar + '%'" aria-valuemin="0" aria-valuemax="100">
                    <span>{{ Constant.UTIL.numberWithCommas(Number(memberData.SCORE)) }}p</span>
                  </div>
                </div>
              </div>

              <div class="more">
                <router-link :to="{ name:'NoticeView',  params:{ id:2 }}"  class="link">등급표 안내 바로가기</router-link>
              </div>
            </div>

</template>

<script>
import { useStore } from 'vuex';
import { useRouter }from 'vue-router'
import { ref, onMounted, computed, getCurrentInstance, watch } from 'vue';
import { useApicall, useCheckLogin } from '@/composables/commonFn';
import VueCookies from "vue-cookies";
import UserNickGrade from '@/components/UserNickGrade.vue';
import Constant from '@/constant.js';

export default {
    components:{   
    UserNickGrade,
 },  
  setup(){
    // const trace = getCurrentInstance().appContext.config.globalProperties.$log;
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const store = useStore();
    const router = useRouter();
    const { apiCall_gt, apiCall_g, apiCall_pt} = useApicall()
    const isPushComment = computed(() => store.state.user.isPushComment);



    
    const onLogout = async () => {
      // trace('onLogout')
      // apiCall_gt('member/logout')
      if(store.state.user.fcmtoken)
      {
          var res =  await apiCall_pt('fcm/tokendeletepost', {'fcmtoken': store.state.user.fcmtoken})
          console.log('tokendeletepost', res );
      } 
      
      if( await apiCall_gt('member/logout') )
      {
        
        sessionStorage.removeItem('token'); 
        VueCookies.remove('cootoken')
        // sessionStorage.removeItem('groundToken');
        store.commit('user/CHANGE_IS_LOGIN', false);
        store.commit('hts/CHANGE_ORDERLIST', []);  
        store.commit('hts/CHANGE_ASSETLIST', []);
        store.commit('user/CHANGE_MEMBER_DATA', null);

        router.push({
          name: 'Home',
        });       
        emitter.emit('EVENT_TALKBOX_LOGOUT');   
      }
    }
   
    const alarmCheckBox = ref(null);
    const replyAlarmCheckBox = ref(null);

    const myPostCnt = ref('');
    const myCommentCnt = ref('');
    const myLikeCnt = ref('');

    const gradeArray = ref([]);
    const gradeNextArray = ref([]);
    const gradeName = ref('');
    const gradeNextName = ref('');
    const progressBar = ref('');
    
    const { getMember } = useCheckLogin();
    const memberData = ref({});


    onMounted( async() => {
      memberData.value = await getMember();
      // console.log('memberData', memberData.value)

      getMyCommunityCnt();
      // console.log("alarmCheckBox:", alarmCheckBox.value.checked, store.state.common.isOrderAlarm)
      if(store.state.common.isOrderAlarm == 'true')
      {
        alarmCheckBox.value.checked = true;
      }
      else
      {
        alarmCheckBox.value.checked = false;
      }
     
      if(isPushComment.value == 'Y')
      {
        replyAlarmCheckBox.value.checked = true;
      }
      else
      {
        replyAlarmCheckBox.value.checked = false;
      }
      getGradeArray();
    })


    watch(isPushComment, ()=>{
        if(isPushComment.value  == 'Y')
        {
          replyAlarmCheckBox.value.checked = true;
        }
        else
        {
          replyAlarmCheckBox.value.checked = false;
        } 
      }    
    )

    // const onClickModifyProfile =()=>{
    //      let token = sessionStorage.getItem('token');   
    //     location.href = Config.URL.flexLifeUrl + `modifyProfile?token=${token}&app=money&redirect_url=https://dev.flexmoney.co.kr`;
    // }

    // mypage 나의 게시글
    const getMyCommunityCnt = async() =>{ 
      const res = await apiCall_gt(`board/getmycommunityinfo`);
      myPostCnt.value = res.communityCount;
      myCommentCnt.value = res.commentCount;
      myLikeCnt.value = res.likeCount;
    }

    const onAlarmChange = () =>{
        store.commit('common/CHANGE_ISORDER_ALARM',String(alarmCheckBox.value.checked));
        // console.log("alarmCheckBox:", alarmCheckBox.value.checked, store.state.common.isOrderAlarm)
    }

    const onReplyAlarmChange = async() =>{
        // store.commit('common/CHANGE_ISORDER_ALARM',String(alarmCheckBox.value.checked));
        console.log("onReplyAlarmChange:", replyAlarmCheckBox.value.checked)

         if(replyAlarmCheckBox.value.checked)
         {
              store.commit('user/CHANGE_IS_PUSH_COMMENT', 'Y');
              const res1 = await apiCall_pt(`fcm/setpushsetting`,{"isPushComment": "Y"}, 'PUT');
              console.log("setpushsetting", res1 );
              
         }
         else
         {
              store.commit('user/CHANGE_IS_PUSH_COMMENT', 'N');
              const res2 = await apiCall_pt(`fcm/setpushsetting`,{"isPushComment": "N"}, 'PUT');
              console.log("setpushsetting", res2 );
         }

    }


    const getGradeArray = async() => {
      var res = await apiCall_g('index/gradetableinfo');
      var data = res.dataList;
      var userGrade = Number(memberData.value.GRADE);
      // var userGrade = Number(30);

      gradeArray.value = [];
      gradeNextArray.value = [];
      gradeArray.value = data.find(element => element.GRADE == userGrade);
      gradeArray.value.ENDSCORE = Number(gradeArray.value.ENDSCORE) + 1;

      if(userGrade != 30) {
        gradeNextArray.value = data.find(element => element.GRADE == userGrade + 1);
      } else {
        gradeNextArray.value = gradeArray.value;
        gradeArray.value.ENDSCORE = Number(gradeArray.value.ENDSCORE) - 1;
      }

      setGradeData();
      scoreProgress();
    }

    const setGradeData = () => {
      let cNumber = gradeArray.value.NAME.slice(-1);
      let nNumber = '';

      if(cNumber == 1) {
        cNumber = '\u2160'
        nNumber = '\u2161'
      } else if(cNumber == 2) {
        cNumber = '\u2161'
        nNumber = '\u2162'
      } else if(cNumber == 3) {
        cNumber = '\u2162'
        nNumber = '\u2163'
      } else if(cNumber == 4) {
        cNumber = '\u2163'
        nNumber = '\u2164'
      } else if(cNumber == 5) {
        cNumber = '\u2164'
        nNumber = '\u2160'
      }

      gradeName.value = gradeArray.value.CATEGORY + ' ' + cNumber;
      gradeNextName.value = gradeNextArray.value.CATEGORY + ' ' + nNumber;

      if(gradeArray.value.GRADE == 1) {
        gradeArray.value.STARTSCORE = 0;
      }

      if(gradeArray.value.GRADE == 30) {
        gradeNextName.value = '최고레벨도달';
      }    
      // console.log('getGradeCategory', gradeNextName.value, gradeArray.value.ENDSCORE)
    }

    const scoreProgress = () => {
      var nowScore = Number(memberData.value.SCORE);
      var startScore = Number(gradeArray.value.STARTSCORE);
      var endScore = Number(gradeArray.value.ENDSCORE);

      progressBar.value = Math.floor(((nowScore - startScore) / (endScore - startScore)) * 100);
      // if(progressBar.value > 99) {
      //     progressBar.value = 100;
      // }
    }

    return{
      onLogout,
      // onClickModifyProfile,
      myPostCnt,
      myCommentCnt,
      myLikeCnt,
      store,
      alarmCheckBox,
      onAlarmChange,
      Constant,
      gradeArray,
      gradeNextArray,
      gradeName,
      gradeNextName,
      progressBar,
      memberData,
      replyAlarmCheckBox,
      onReplyAlarmChange,
    }
  }
}
</script>

<style>

</style>