
import common from './common';
import user from './user';
import toast from './toast';
import trade from './trade';
import hts from './hts';
import live from './live';
export default {    
    common:common,   
    user:user, 
    toast:toast,
    trade:trade,
    hts: hts,
    live:live,
};