<template>
                    <div class="mypage-main-inner" v-if="myBookmarkList.length">
                      <ul class="inner-list">
                        <li
                          v-for="(item, index) in myBookmarkList"
                          :key = index
                        >
                          <div
                            v-if="item.ISDELETED == 'N'"
                            class="inner-bookmark"
                          >
                            <div class="item-box">
                              <p class="title-box">
                                <span class="title">
                                  <router-link :to="{ name:'CommunityView',  params:{ id:item.BOARD_IDX }}">
                                    {{item.SUBJECT}}
                                  </router-link>
                                </span>
                              </p>
                              <span class="category">{{ item.CATEGORY_NAME }}</span>
                              <span class="date">좋아요 {{ item.LIKE_COUNT }}<span class="pipe">|</span>댓글수 {{ item.COMMENT_COUNT }}</span>
                            </div>
                            <div class="bookmark-cancel">
                              <button type="button" class="btn btn-outline-secondary" @click="onBookmarkCancel(item.IDX)">취소</button>
                            </div>
                          </div>

                          <div
                            v-else
                            class="inner-bookmark del"
                          >
                            <div class="item-box">
                              <p class="title-box">
                                <span class="title">
                                    삭제된 글입니다.
                                </span>
                              </p>                                                            
                            </div>
                            <div class="bookmark-cancel">
                              <button type="button" class="btn btn-outline-secondary" @click="onBookmarkCancel(item.IDX)">취소</button>
                            </div>
                          </div>

                        </li>
                      </ul>
                    </div>
                    <div v-else class="mypage-main-null">등록된 북마크가 없습니다.</div>
                      
                    <!-- 페이징 start-->
                    <div class="section paging-area pb-1 mb-0" v-if="myBookmarkList.length">
                      <v-pagination                  
                        v-model="currentPage"
                        :pages= numberOfPages
                        :rangeSize="1"
                        activeColor="#ff5d8b"                   
                        @update:modelValue="updateHandler(currentPage)"
                      />
                    </div>
                    <!-- //페이징 end --> 

        <!-- 레이어팝업 - 북마크취소 -->
        <BasicModal2b
          v-if="showModal"
          @close="closeModal" 
          @cancle="closeModal" 
          @ok="onModalOk" 
          :titleTxt="txtTitle_alert"
          :bodyTxt="txtBody_alert"
        />

</template>

<script>
import { getCurrentInstance, ref, computed, watch } from 'vue';
import {useApicall} from '@/composables/commonFn';
import VPagination from "@hennge/vue3-pagination";
import BasicModal2b from '@/components/popups/BasicModal2b.vue';
import '@/assets/css/vue3-pagination.css';

export default {
  props: {
    itemType: {
      type:String,
      required: true,
    },
  },  
  components: {
    VPagination,
    BasicModal2b,
  },

  setup(props) {
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const { apiCall_gt } = useApicall();

    const limit = ref(10);
    const offset = ref(0);
    const currentPage = ref(1);     
    const numberOfData = ref(0);
    const numberOfPages = computed(()=>{
      return Math.ceil(numberOfData.value/limit.value)
    });
    const myBookmarkList = ref([]);
    const propsItemType = computed(()=>props.itemType)

    const showModal = ref(false);
    const txtTitle_alert = ref("북마크취소");
    const txtBody_alert = ref("북마크를 취소하시겠습니까?");

    const updateHandler = (e) => {
      getMyBookmarkList(e);
    }

    const getMyBookmarkList = async(page = currentPage.value) => {
      currentPage.value = page;
      var offset = (currentPage.value -1) * limit.value;
      var getUrl = `board/bookmarklist?limit=${limit.value}&offset=${offset}`

      var bookmarkDataList = await apiCall_gt(getUrl);

      try {
        if(bookmarkDataList.dataList == null) bookmarkDataList.dataList = [];
        myBookmarkList.value = bookmarkDataList.dataList;
        numberOfData.value = bookmarkDataList.dataCount;
      } catch(error) {
        myBookmarkList.value = [];
        numberOfData.value  = 0;
      }
    }

    var currentDelIdx = null;
    const onBookmarkCancel = (id) => {
      currentDelIdx = id;
      showModal.value = true;
    }

    const onModalOk = async() => {
      showModal.value = false;
      var res = await apiCall_gt(`board/bookmarkdelete/${currentDelIdx}`, 'DEL');

      if(res){ 
        emitter.emit('EVENT_ALERT',{title:"알림", body:"북마크가 취소되었습니다."});
        updateHandler(currentPage.value);
        currentDelIdx = null;
      }
    }

    const closeModal = () => {
      showModal.value = false;
    }    

    watch(propsItemType, ()=> {
      if(propsItemType.value == 'bookmark') {
        updateHandler(1);
      } else {
        return false;
      }
    })


    return{
      limit,
      offset,
      myBookmarkList,
      numberOfPages,
      currentPage,
      updateHandler,
      onBookmarkCancel,
      onModalOk,
      closeModal,
      txtTitle_alert,
      txtBody_alert,
      showModal
    }
  }
}
</script>

<style>

</style>