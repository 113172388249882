<template>
  <HeaderTrade/> 
  <!-- Container Start { -->
  <div id="container">
    <!-- Contents Start { -->
    <div id="contents">

      <!-- content start -->
      <div class="contents-area">

        <!-- 글쓰기 start -->
        <div class="section ground-write">
          <div class="title">1:1 문의하기</div>
          <div class="ground-write-tb">
            <table class="table">
              <colgroup>
                <col style="width:15%">
                <col style="width:85%">
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">제목</th>
                  <td>
                    <label for="inputSubject" class="visually-hidden">제목</label>
                    <input v-model="rTitle" type="text" class="form-control" id="inputSubject" placeholder="제목을 입력하여 주세요." maxlength="50">
                  </td>
                </tr>
                <tr>
                  <th scope="row">내용</th>
                  <td>
                      <div class="text-danger text-end fs-12">&#8251; 주의사항 : 문의글은 텍스트, 이미지 등을 포함한 전체 10MB 이하입니다.</div>
                     <label for="inputContents" class="visually-hidden">textarea</label>
                    <!-- <textarea class="form-control" id="inputContents" rows="20"></textarea> -->
                    <QuillEditor  class="form-control"    id="inputContents"   style="font-size: 16px; border-radius: 2px; height:400px"  rows="20"  theme="snow"  toolbar="full" ref="rContent" placeholder="내용을 입력해주세요." />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- //글쓰기 end -->

      </div>
      <!-- //content end -->  

      <!-- aside start -->
      <div class="aside-area">

        <!-- 버튼 영역 start -->
        <div class="section ground-write-btn">
            <button class="btn btn-danger" type="button" @click="onSaved">{{ !$route.params.id == '' ? '수정' : '등록'}}</button>
            <button class="btn btn-outline-gray" type="button" @click="$router.go(-1)">취소</button>
        </div>
        <!-- //버튼 영역 end -->

        <!-- 등록 안내 start -->
        <div class="section community-write-info">
          <h2>1:1문의 이용안내</h2>
          <div class="write-info-box t2">
            <h3 class="title">궁금한 사항은 1:1문의를 통하여 문의하여 주세요.</h3>
          </div>
        </div>

      </div>
      <!-- //aside end -->

    </div>
    <!-- } Contents End -->

  </div>
  <!-- } Container End -->
 <Footer/>

</template>

<script>

import HeaderTrade from '@/components/HeaderTrade.vue';
import { useRoute, useRouter }from 'vue-router'
import Footer from '@/components/Footer.vue';
import { useApicall } from '@/composables/commonFn';
import { onMounted, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import '@vueup/vue-quill/dist/vue-quill.bubble.css'

export default {
  components:{  
    HeaderTrade, 
    Footer,
    QuillEditor
  },    
  setup() {

    const trace = getCurrentInstance().appContext.config.globalProperties.$log;
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const route = useRoute();
    const router = useRouter();
    const {apiCall_gt, apiCall_pt} = useApicall();
    const store = useStore();
    const rTitle = ref(null);
    const rContent = ref(null);
    const boardItem = ref(null);

    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_Qna'); 
    store.commit('common/CHANGE_CURRENT_PAGE', 'Qna'); 

    // trace("qnaDataList ===========> route.params.id", route.params.id)

    var mode = 1;

    //mode = 1:쓰기 2:수정

    const onSaved = ()=>{
      var contentString = rContent.value.getHTML().replace(/<[^>]*>?/g, '');
      if( !rTitle.value ) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"제목을 입력하여 주세요."});
        return false;
      }
      if( contentString == '') {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"내용을 입력하여 주세요."});
        return false;
      }
      saveBoard();
    }

    const saveBoard = async () => {
      var params = {};
      var res = null
      
      if( mode == 1 ) {
        params = {
          "subject": rTitle.value,
          "contents": rContent.value.getHTML()   
        }
        res = await apiCall_pt(`board/counselingwrite`, params);
      } else if( mode == 2 ) {
          params = {
          "idx": boardItem.value.IDX,
          "subject": rTitle.value,
          "contents": rContent.value.getHTML()   
        } 
        res = await apiCall_pt(`board/counselingmodify`, params, 'PUT');
      }

      if( res ) {
        if( route.params.id ) {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"1:1 문의가 수정되었습니다."});
        } else {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"1:1 문의가 등록되었습니다."});
        }

        router.push({
          name:'Qna'
        });
      }
    }

    const  getModifyBoardItem = async ()=>{
      const res = await apiCall_gt(`board/counselingview?idx=${route.params.id}`);
      return res.data; 
    }

    onMounted(async () => {
      emitter.emit('EVENT_CHECK_LOGIN');
      if( route.params.id ) {
        mode = 2;
        boardItem.value = await getModifyBoardItem();
        trace("boardItem:~~~~~~~ ", boardItem.value)
        // boardItem.value = JSON.parse(route.params);
        rTitle.value = boardItem.value.SUBJECT;
        rContent.value.setHTML(boardItem.value.CONTENTS);
      } else {
        mode = 1;
      }
    })

    return {
      onSaved,
      rTitle,
      rContent,
      boardItem,
    }

  }
}
</script>

<style>

</style>

