<template>
  <HeaderTrade/> 
  <!-- Container Start { -->
  <div id="container">
    <!-- Contents Start { -->
    <div id="contents">
      
      <div class="policy-box">
        <div class="h5 fw-bold">이용약관</div>
        <textarea class="form-control w-100" readonly="readonly">
제1조 목적
이 약관은 주식회사 플렉스머니(이하 "회사"라 합니다)이 제공하는 서비스(이용자가 PC, 멀티미디어 모바일 등의 각종 디지털기기 또는 프로그램을 통하여 이용할 수 있도록 회사가 제공하는 모든 서비스를 의미합니다)와 관련하여, 회사와 이용자 간에 서비스 이용조건 및 절차, 권리ㆍ의무 및 책임사항 기타 필요한 사항을 규정함을 목적으로 합니다.

제2조 용어의 정의
① “서비스”라 함은 접속 가능한 유, 무선 단말기의 종류와는 상관없이 이용자(또는 회원)가 이용할 수 있는 제반 “서비스”를 의미합니다.
② “이용자”이라 함은 회사의 “서비스”에 접속하여 이 약관에 따라 “회사”가 제공하는 “서비스”를 이용하는 회원, 비회원 고객을 말합니다.
③ “제휴사”란(이하 “제휴사”) 회사와 제휴 계약하여 서비스를 제공하는 자를 말합니다. 
④ “회원”이라 함은 본 약관 제5조에 정해진 절차에 따라 회원등록을 한 자로서, 회사 또는 제휴사 정보를 지속적으로 제공받으며, 서비스를 계속적으로 이용할 수 있는 자를 말합니다. 
⑤ “비회원”이란 회원으로 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
⑥ "통합회원 아이디(ID)"라 함은 "이용자"의 식별과 "서비스" 이용을 위하여 "이용자"가 정하고 "회사"가 승인하는 문자와 숫자의 조합을 의미합니다.
⑦ "비밀번호"라 함은 "이용자"가 부여받은 "아이디”와 일치되는 "이용자"임을 확인하고 비밀보호를 위해 "이용자" 자신이 정한 문자 또는 숫자의 조합을 의미합니다.
⑧ "유료 서비스"라 함은 "회사" 또는 ”제휴사”를 통해  "회원"이 유료로 제공하는 각종 온라인 디지털 콘텐츠(각종 정보 콘텐츠, VOD, 플렉스(캐시), 아이템 기타 유료 콘텐츠를 포함) 및 제반 서비스를 의미를 의미하며, 이는 서비스마다 차이가 있을 수 있습니다.
⑨ "게시물"이라 함은 이용자가 "서비스"를 이용함에 있어 "서비스상"에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
⑩ “포인트”란 회사와 “제휴사” 재화/서비스 구매 시 적립/사용이 가능하도록 제공하는 것을 의미합니다.
⑪ “모의투자대회”이라 함은 “회사”에서 개최하는 참가비가 무료/유료인 모의로 진행되는 투자 대회를 의미하며, 투자 대회를 통해 발생되는 수익은 현금화가 불가능합니다
⑫ “재도전”이라 함은 “이용자”가 “모의투자대회”에서 “수익률” 혹은 “잔고”를 최신화하여 다시 도전할 수 있는 것을 의미합니다.
⑬ “수익률”이라 함은 “모의투자대회”의 거래 수익 %를 의미합니다.
⑭ “잔고”라 함은 “이용자”가 “모의투자대회"에 참여했을때 주어지는 거래 가능 액수를 의미합니다.
⑮ “랭킹”이라 함은 각 “모의투자대회”에서 참가한 “이용자”들의 순위를 의미합니다.

제3조 약관의 효력과 변경
① 본 약관의 내용은 홈페이지 및 서비스 화면에 이를 공시함으로써 효력을 발생합니다.
② “회사”는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률”등 관련법에 위배하지 않는 범위에서 이 약관을 변경할 수 있으며, 약관을 변경할 경우에는 “이용자”이 쉽게 알 수 있도록 서비스 초기화면에 최소 15일 전부터 공지합니다. 다만, 이용자에게 불리한 약관의 개정의 경우에는 그 적용일자 30일 전부터 적용일 이후 상당기간 동안 각각 이를 서비스 홈페이지에 공지하여 그 내용을 확인할 수 있게 됩니다.
③ 이용자는 변경된 약관에 대해 동의하지 않을 권리가 있으며, 시행일로부터 10일(이용자에게 불리하거나 중대한 사항의 변경인 경우에는 30일) 내에 변경된 약관에 대해 거절의 의사를 표시하지 않았을 때에는 본 약관의 변경에 동의한 것으로 간주합니다
④ 이용자는 변경된 약관에 동의하지 않으면, 언제나 “서비스” 이용을 중단하고, 이용계약을 해지할 수 있습니다. 약관의 효력 발생일 이후의 계속적인 “서비스” 이용은 약관의 변경사항에 대한 이용자의 동의로 간주됩니다.

제4조 서비스의 구분
① “회사”와 제휴사 이용자에게 제공하는 “서비스”는 무료 서비스, 유료 서비스, 적립 서비스 등으로 구분합니다.
② 무료 서비스, 유료 서비스 등의 종류와 이용방법 등은 이 약관 및 "회사"와 각 제휴사가 공지 또는 이용안내에서 별도로 정하는 바에 의합니다.
③ 서비스 이용등급은 콘텐츠 초기 화면에 표시된 이용등급정보에 따르며, "회사"와 제휴사 이용자에 대하여 "청소년 보호법" 등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.
④ 회원은 회사와 제휴사에서 재화 또는 상품, 서비스 구입. 이벤트를 통해 포인트를 적립받을 수 있으며, 포인트는 서비스 사정에 따라 회원에게 공지 후 이용범위나 가치가 달라질 수 있습니다. 적립된 포인트는 양도/판매가 불가하며, 탈퇴 시 일정 유예기간 이후 자동으로 소멸됩니다..

제5조 모의투자대회 이용시간
① 모의투자대회에 관한 서비스의 이용은 연중무휴, 1일 24시간을 원칙으로 합니다. 다만 서비스 중 일부에 관하여 별도의 제공시간을 정할 수 있고, 이 경우 관련사항을 회사 홈페이지에 공지합니다.

제6조 모의투자대회 이용료
① 모의투자대회 관한 서비스는 회사가 별도로 정하는 기간까지 무료로 합니다. 단, 회사가 모의투자대회에 관한 서비스를 유료화하는 경우에는 시행 1개월 전에 이를 회사 홈페이지에 공지합니다.

제7조 이용계약의 성립 및 체결 단위
① 이용계약은 이용자의 이용신청에 대한 "회사" 또는 제휴사를 통한 이용 승낙으로 성립합니다.
② 이용계약은 아이디 단위로 체결합니다.
③ "서비스"의 대량 이용 등 특별한 "서비스" 이용에 관한 계약은 별도의 계약으로 합니다.
④ 회사 또는 제휴사를 통해 이용신청을 하는 과정에서 ‘본인 인증’을 요구할 수 있습니다. 본인 인증이 필요한 경우, 회사는 본인 인증을 하지 않은 아이디에 한해 일정 기간 이후 계약 체결을 취소할 수 있습니다.

제8조 이용신청
① 이용자가 "서비스"의 이용을 원할 경우 인터넷 접속 등의 방식으로 "회사" 또는 제휴사에서 제공하는 가입신청 양식에서 요구하는 사항을 기입하여 이용신청을 하여야 합니다.
② "회사"는 이 약관의 주요 내용을 이용자에게 고지하여야 합니다.
③ 이용자가 전항의 고지 하단에 있는, 동의 버튼을 클릭하거나 또는 체크박스에 체크하고 이용신청을 하는 것은 이 약관에 대한 동의로 간주됩니다.
④ 이용신청은 이용자 자신의 실명으로 하여야 합니다.
⑤ 이용자는 회사가 제공하는 본인 확인 방법을 통하여 서비스 이용신청을 하여야 하며, 본인 명의로 등록하지 않은 이용자는 일체의 권리를 주장할 수 없습니다.
⑥ 타인의 정보(아이핀 및 휴대 전화 정보 등)를 도용하여 이용신청을 한 이용자의 허위 계정은 서비스 이용이 정지됩니다.
⑦ “이용신청 고객”이 미성년자(만 14세 미만)인 경우 이용이 불가능할 수 있으며, 서비스마다 상이할 수 있습니다.

제9조 이용신청의 승낙
① "회사"는 전조의 규정에 의한 이용신청에 대하여 업무수행상 또는 기술상 지장이 없는 경우에는 원칙적으로 지체 없이 이용신청을 승낙합니다.
② "회사"는 이용신청을 승낙하는 때에는 다음 각호의 사항을 이용자에게 "서비스"를 통하거나, 전자우편 등의 방법으로 통지합니다.
 1. 아이디
 2. 이용자의 권익보호 및 의무 등에 관한 사항
 3. 기타 이용자가 "서비스" 이용 時 알아야 할 사항

제10조 이용신청에 대한 불승낙과 승낙의 보류
① "회사"는 다음 각호에 해당하는 이용신청에 대하여는 승낙을 하지 아니할 수 있습니다.
 1. 제6조 또는 제12조 제6항을 위반한 경우
 2. 허위의 신청이거나 허위서류를 첨부한 경우
 3. 이용자가 회사에 납부하여야 할 요금 등을 납부하지 않은 경우
 4. 기타 이용자의 귀책사유로 이용 승낙이 곤란한 경우

② "회사"는 다음 각호에 해당하는 사유가 있는 경우에는 그 사유가 해소될 때까지는 승낙을 보류할 수 있습니다.
1. 회사의 설비에 여유가 없는 경우
2. "서비스"에 장애가 있는 경우
3. 제6조 제7항에 해당하는 경우

제11조 아이디 부여 등
① "회사"는 원칙적으로 이용자가 신청한 대로 아이디를 부여합니다.
② 아이디는 변경할 수 없는 것을 원칙으로 합니다.
③ 아이디 또는 닉네임이 제12조 제6항 각호에 해당하는 경우에는 "회사"및 각 서비스사는 해당 이용자에 대한 "서비스" 제공을 중단하고, 새로운 아이디 또는 닉네임으로 이용신청할 것을 권할 수 있습니다. 닉네임에 대한 구체적인 정책은 각 서비스의 이용약관에 따릅니다.

제12조 회사의 의무
① "회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.
② "회사"는 계속적이고 안정적인 "서비스"의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는 이를 지체 없이 수리 또는 복구하며, 다음 각 호의 사유 발생 시 부득이한 경우 예고 없이 서비스의 전부 또는 일부의 제공을 일시 중지할 수 있습니다. 이 경우 그 사유 및 중지 기간 등을 이용자에게 지체 없이 사후 공지합니다.
 1. 회사가 긴급한 시스템 점검, 증설, 교체, 시설의 보수 또는 공사를 하기 위하여 부득이한 경우
 3. 시스템 또는 기타 서비스 설비의 장애, 유무선 Network 장애 등으로 정상적인 서비스 제공이 불가능할 경우
 4. 천재지변, 국가비상사태, 정전 등 회사가 통제할 수 없는 불가항력적 사유로 인한 경우
③ "회사"는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다.
④ “회사”는 대표자의 성명, 상호, 주소, 전화번호, 모사전송 번호(FAX), 통신판매업 신고번호, 이용약관, 개인정보취급방침 등을 이용자가 쉽게 알 수 있도록 온라인 서비스 초기화면에 게시합니다.

제13조 개인정보보호
"회사"는 이용자들의 개인정보를 중요시하며, 정보통신망법, 개인정보보호법, 전기통신사업법 등 관련 법규를 준수하고 있습니다. "회사"는 개인정보보호방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

제14조 이용자의 의무
① 이용자는 회원가입을 통해 이용신청을 할 경우 사실에 근거하여 작성하여야 합니다. 이용자가 허위, 또는 타인의 정보를 등록한 경우 회사에 대하여 일체의 권리를 주장할 수 없으며, 회사는 이로 인하여 발생하는 손해에 대하여 책임을 부담하지 않습니다.
② 이용자는 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 회사가 공지하는 사항을 준수하여야 합니다. 또한 이용자는 회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위를 해서는 안됩니다.
③ 이용자는 청소년 보호법 등 관계 법령을 준수하여야 합니다. 이용자는 청소년 보호법 등 관계 법령을 위반한 경우는 해당 법령에 의거 처벌을 받게 됩니다.
④ 이용자는 주소, 연락처, 전자우편 주소 등 이용계약 사항이 변경된 경우에 즉시 온라인상에서 이를 수정해야 합니다. 수정을 하지 않거나 수정 지연으로 발생되는 책임은 이용자에게 있습니다.
⑤ 이용자는 서비스 아이디와 비밀번호를 직접 관리해야 합니다. 이용자의 관리 소홀로 발생한 문제는 회사에서 책임지지 않습니다.
⑥ 이용자가 아이디, 닉네임, 기타 서비스 내에서 사용되는 명칭 등의 선정 시에는 다음 각 호에 해당하는 내용을 사용하여서는 안됩니다.
 1. 회사가 제공하는 서비스의 공식 운영자를 사칭하거나 유사한 명칭을 사용하여 다른 이용자에게 혼란을 초래하는 행위
 2. 선정적이고 음란한 내용이 포함된 명칭의 사용
 3. 제3자의 상표권, 저작권 등의 권리를 침해할 가능성이 있는 명칭의 사용
 4. 비어, 속어라고 판단되거나 반사회적이고 공서양속을 해치는 내용이 포함된 명칭의 사용
 5. 주민등록번호, 전화번호 등 개인정보 유출 또는 사생활 침해의 우려가 있는 경우
 6. 관계법령에 저촉되거나 기타 이용자의 보호를 위한 합리적인 사유가 있는 경우
⑦ 이용자는 회사의 명시적 동의가 없는 한 서비스 이용 권한, 기타 이용 계약상의 지위를 타인에게 매도, 증여할 수 없으며 무형자산을 담보로 제공할 수 없습니다.
⑧ 이용자는 회사에서 제공하는 서비스를 본래의 이용목적 이외의 용도로 사용해서는 안됩니다. 이용자는 아래 각 호의 행위를 하여서는 안되며, 이에 해당되는 행위를 할 경우에는 본 약관 및 각 서비스 별로 공지하는 운영정책에 따라 이용자의 서비스 이용을 제한하거나 계정의 삭제, 수사기관의 고발 조치 등 적법한 조치를 포함한 제재를 가할 수 있습니다.
 1. 회원 가입 또는 변경 시 개인정보에 허위 내용을 기재하는 행위
 2. 타인의 개인 정보를 도용하거나 부정하게 사용하는 행위
 3. 이용자의 아이디 등 사이버 자산을 타인과 매매하는 행위
 4. 회사의 운영진 또는 직원을 사칭하는 행위
 5. 회사 프로그램 상의 버그를 악용하는 행위
 6. 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 하는 행위
 7. 다른 이용자를 희롱 또는 위협하거나 다른 이용자에게 고통, 피해, 또는 불편을 주는 행위
 8. 회사로부터 승인받지 아니하고 각 서비스의 클라이언트 프로그램을 변경하거나, 서버를 해킹하거나 또는 웹사이트의 일부분을 변경하는 행위, 또는 이러한 목적으로 개발, 유포되는 프로그램을 사용하거나 이의 사용을 장려 또는 광고하는 행위
 9. 조회수 조작, 기타 부정한 목적으로 다량의 정보를 전송하여 서비스의 안정적인 운영을 방해하는 행위
 10. 회사 또는 다른 이용자의 게시물, 영상 기타 광고의 전송을 방해하거나 훼손, 삭제하는 행위
 11. 회사의 승인을 받지 않고 다른 이용자의 개인정보를 수집 또는 저장하는 행위
 12. 수신자의 의사에 반하는 광고성 정보, 전자우편을 지속적으로 전송하는 경우
 13. 타인의 저작권, 상표권, 특허권 등의 지적재산권을 침해하는 경우
 14. 회사의 허가 없이 소프트웨어 또는 하드웨어를 이용하여 서비스의 내용에 권한 없이 관여하는 소프트웨어 혹은 하드웨어를 사용, 배포하거나 사용을 장려하거나 이에 대한 광고를 하는 행위
 15. 회사가 제공하는 서비스 프로그램의 이용방식, 기획의도를 변경하거나 서비스에 비정상적으로 위해를 가하거나 고의로 방해하는 일체의 행위
 16. 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제3자에게 제공하는 행위
 17. 공공질서 및 미풍양속에 위반되는 저속 하거나 음란한 내용의 정보, 문장, 도형, 음향, 동영상 또는 불법 복제된 소프트웨어를 전송, 게시, 전자우편 또는 기타 방법으로 타인에게 유포하는 등 불법적인 목적으로 서비스를 이용하는 행위
 18. 결제기관을 속여 부정한 방법으로 결제하거나 지불 거절(Chargeback)을 악용하여 정당한 이유 없이 유료 서비스를 구매하거나 환불하는 경우
 19. 정당한 후원의 목적 외에 자금을 제공 또는 융통하기 위해 휴대폰이나 상품권 등을 통해 유료 서비스를 이용, 결제, 구매, 환전, 환불하는 행위. 이를 돕거나 권유, 알선, 중개 기타 광고하는 행위
 20. 국가기관으로부터 이용제한 심의, 의결을 받은 경우
 21. 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위 및 기타 관계 법령에 위배되는 행위

제15조 서비스의 제한 등
① "회사"는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
② 무료 서비스는 전항의 규정에도 불구하고, "회사"의 경영정책 등의 사유로 서비스의 전부 또는 일부가 제한되거나 중지될 수 있으며, 유료로 전환될 수 있습니다.
③ "회사"는 "서비스"의 이용을 제한하거나 그 사유 및 제한기간, 예정일시 등을 지체 없이 이용자에게 알리고, 무료서비스를 유료서비스로 전환하는 떄에는 시행 1개월 전에 이를 회사 홈페이지에 공지합니다’
④ 회사는 최종 사용일로부터 연속하여 1년 동안 서비스 사용 이력이 없는 경우 정보통신망법에 따라 개인정보가 파기되는 사실, 기간 만료일 및 파기되는 개인정보의 항목 등을 이용자에게 본 약관 제16조 제1항의 방법으로 알린 후 회원정보를 영구적으로 삭제할 수 있습니다. 단, 유료결제 상품을 보유하고 있을 경우 삭제 대상에서 제외되며 관련 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정기간 동안 회원정보를 보관합니다.

제16조 서비스의 제공 중단, 이용제한 등
① "회사"는 이용자가 유료 서비스의 요금 등을 지정한 기일로부터 1개월 이상 납부하지 않을 경우에는 전부 또는 일부의 서비스 제공을 중단할 수 있습니다.
② 제12조의 규정에 의하여 이용자의 이용을 제한하는 경우, 제한의 종류 및 기간 등 구체적인 기준은 "회사"의 운영정책, 공지, 이용안내 등에서 별도로 정하는 바에 따릅니다.

제17조 이용제한 및 해제 절차
① "회사"가 이용제한을 하고자 하는 때에는 그 사유, 일시 및 기간을 정하여 사전에 전화 또는 전자우편 등의 방법으로 해당 이용자에게 통지합니다. 다만, 긴급하게 이용을 제한할 필요가 있을 경우에는 그러하지 않습니다.
② 전항의 규정에 의하여 이용정지의 통지를 받은 이용자는 그 이용정지의 통지에 대하여 이의가 있을 때에는 이의신청을 할 수 있습니다.
③ "회사"는 이용정지 기간 중에 그 이용정지 사유가 해소된 것이 확인된 경우에는 이용정지 조치를 즉시 해제합니다.
④ "회사"는 이용자의 부당행위가 고의 또는 과실이 없었음이 입증된 경우에는 이용자가 이용 중인 유료 서비스에 대한 이용기간을 정지된 기간만큼 연장해주도록 합니다.

제18조 서비스의 해제, 해지 등
① 이용자가 이용 계약을 해지하고자 하는 때에는 본인이 직접 “회사”의 서비스 홈페이지를 통하거나 서면 또는 전화의 방법으로 “회사”에 신청하여야 합니다.
② “회사”는 전항의 규정에 의하여 해지 신청이 접수되면 지체 없이 서비스의 이용을 제한합니다. 단, 이용자는 30일 이내의 유예 기간 동안 이용 계약 해지를 철회할 수 있습니다. 
③ “회사”는 이용계약을 해지하고자 할 경우에는 해지조치 7일전까지 그 뜻을 이용자에게 통지하여 의견을 진술할 기회를 주어야 합니다. 다만, 다음 각호의 어느 하나에 해당하는 경우에는 즉시 해지를 할 수 있습니다.
 1. 이용자가 관련 법령 또는 약관 기타 서비스 취지에 반하는 중대한 위법, 부정행위를 한 경우
 2. 타인명의 이용신청 또는 허위의 이용신청, 허위서류를 첨부한 이용신청임이 확인된 경우
 3. 이용자가 명백히 약관상 의무이행을 거절하는 의사를 표시하거나 즉시 탈퇴처리에 동의한 경우
④ “회사”는 해지된 이용자의 이용신청에 대하여 해지 후 일정기간 동안에는 불승낙을 할 수 있습니다.
⑤ “회사”는 계약이 해지된 이용자가 "서비스"에 제공하거나, 보관 중인 자료 및 제공한 개인정보를 삭제하고, 이와 관련하여 어떠한 책임도 부담하지 않습니다. 단, 제19조와 제22조에 따라 별도의 계약이 성립된 자료는 삭제하지 않을 수 있습니다.
⑥ “회사”는 통합 아이디를 통해 가입한 개별 서비스가 1건 이상 존재하는 경우. 이를 통합 회원 이용으로 간주하며, 이용중인 서비스가 완료되지 않았거나 Cash가 남아있을 경우 유료 서비스 이용약관 제9조에 따라 해지 신청을 거부할 수 있습니다. 원치 않을 경우, 회원은 “회사”가 정한 별도의 이용 방법으로 해지 신청을 할 수 있습니다.

제19조 손해배상
① "회사"는 무료 서비스의 장애, 제공 중단, 보관된 자료 멸실 또는 삭제, 변조 등으로 인한 손해에 대하여는 배상하지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 경우는 제외합니다.
② "회사"는 "회사"의 귀책사유로 인하여 이용자에게 서비스를 제공하지 못하는 경우 유료 서비스 이용자에게는 공정거래위원회가 고시한 소비자분쟁해결기준에 따라 보상합니다.
③ "회사"는 "콘텐츠"의 하자, 이용중지 또는 장애 등에 의하여 발생한 "이용자"의 손해에 대하여 보상 또는 배상합니다.
④ 제3항에 따른 손해배상 등의 방법 및 절차는 "콘텐츠 이용자 보호지침"에 따라 처리하며, 기타 손해배상의 방법, 절차 등은 관계법령에 따릅니다.

제20조 면책
① "회사"는 이용자가 "회사"의 "서비스" 제공으로부터 기대되는 이익을 얻지 못한 데 대하여 책임이 없습니다.
② "회사"는 제3자가 게시 또는 전송한 자료로 인한 이용자의 손해에 대하여는 책임이 없습니다.
③ "회사"는 이용자 상호 간 또는 이용자와 제3자 간에 "서비스"를 매개로 발생한 분쟁에 대하여는 개입할 의무도 이로 인한 손해를 배상할 책임도 없습니다.
④ “회사”의 책임 있는 사유로 인하여 발생한 손해에 관하여는 제1항 내지 제3항을 적용하지 아니합니다.
⑤ “회사”는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 대한 책임이 면제 됩니다.
⑥”회사”는 “이용자”의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.
⑦”회사”는 서비스용 설비의 보수, 교체, 정기점검 등 계약상 의무를 성실히 이행하기 위한 행위로 인하여 부득이하게 “이용자”에게 발생한 손해에 대하여 책임을 지지 않습니다.
⑧”회사”는 당사 제공 서비스 관련하여 자신이 회사에 등록한 항목 및 비밀번호 보안에 대한 책임을 지며 “회사”는 “회사”의 고의 또는 과실 없이 ID, 비밀번호 등의 회원정보가 유출되어 발생하는 손해에 대해 책임을 지지 않습니다.
⑨”회사”는 “이용자”의 컴퓨터의 오류에 의해 손해가 발생한 경우 또는 “이용자” 본인이에 대한 정보를 부실하게 기재하여 손해가 발생하는 경우에는 책임을 지지 않습니다.
⑩”회사”는 “이용자”가 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 “회사”는 “이용자”가 서비스를 이용하며 타 “이용자”로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
⑪”회사”는 “이용자” 상호간 및 “이용자” 제3자 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임이 없습니다.
⑫”회사”는 “이용자”가 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.
⑬”회사”는 정보통신망에 대한 감청, 해킹(hacking) 등으로 인해 발생한 손해에 대하여 책임을 지지 않습니다.
⑭”회사”는 기간통신사업자의 장애를 포함한 제휴 정보통신 장애로 불가피하게 발생하는 손해와 한국거래소의 시스템장애, 정보제공지연 등으로 인한 손해에 대하여 책임을 지지 않습니다.

제21조 자료의 저장
"회사" 또는 “제휴사”는 이용자가 제공하는 정보, 자료에 대하여 회사의 공지, 서비스 이용안내에서 정한 바에 따라 일정한 게재 기한 및 용량을 정할 수 있습니다.

제22조 공개 게시물 등의 관리
① "회사"는 건전한 통신문화 정착과 효율적 서비스 운영을 위하여 다음 각호에 해당하는 공개 게시물 및 자료의 게시중단, 삭제, 이동, 등록 거부하는 등의 필요조치를 취할 수 있습니다. 이 경우 게시물 및 자료의 삭제 등에 대하여 회사는 귀책사유가 없는 한 어떠한 책임도 부담하지 않습니다.
 1. 타인의 법률상 이익을 침해하는 행위와 관련된 것으로 추정되는 게시물, 자료로서, 이해당사자의 삭제 등 요청이 있거나, 회사가 피소, 고발될 수 있는 사유를 제공하는 게시물, 자료
 2. 서비스에 위해를 가할 소지가 있는 바이러스 등이 포함된 게시물, 자료
 3. 제20조에 의하여 정해진 게재 기한 및 용량을 초과한 게시물, 자료(이 경우 회사는 사전에 해당 이용자에게 삭제, 이동 등에 관한 안내를 할 수 있습니다)
 4. 제12조 제8항 각호를 위반하여 다른 이용자의 서비스 이용에 현저한 지장을 초래하는 게시물, 자료
 5. 전기통신 관계법령 및 형사 관계법령에 따른 국가기관 등의 삭제 등 요구가 있는 게시물, 자료
② "회사"는 게시물 등에 대하여 제3자로부터 명예훼손, 지적재산권 등의 권리 침해를 이유로 게시중단 요청을 받은 경우 이를 임시로 게시중단(전송 중단)할 수 있으며, 게시중단 요청자와 게시물 등록자 간에 소송, 합의 기타 이에 준하는 관련기관의 결정 등이 이루어져 회사에 접수된 경우 이에 따릅니다.
③ 해당 게시물 등에 대해 임시 게시 중단이 된 경우, 게시물을 등록한 이용자는 회사에 소명자료를 첨부하여 이의신청을 할 수 있으며, 게시 중단 사유가 해소된 날로부터 30일 이내에 재게시를 요청하지 아니한 경우 회사는 이를 삭제할 수 있습니다.
④ 이용자는 “회사”에게 본인의 게시물 삭제를 요청할 수 있고, 사망한 이용자의 유족은 사망 사실과 가족관계를 증빙하여 그 이용자의 게시물의 이전, 접근 배제 또는 삭제를 요청할 수 있습니다.

제23조 정보의 제공
① "회사"는 이용자가 서비스 이용 중 필요하다고 인정되는 각종 정보를 게재하거나, 전자우편(E-Mail), 휴대폰 단문 메시지(SMS)와 알림(PUSH), 전화, 우편 등의 방법으로 이용자에게 제공(또는 전송)할 수 있습니다. 이용자는 이를 원하지 않을 경우에 회사가 제공하는 방법에 따라 수신을 거부할 수 있습니다.
② 전항 단서에 따라 수신을 거부한 이용자의 경우에도 이용약관, 개인정보보호정책, 기타 이용자의 이익에 영향을 미칠 수 있는 중요한 사항의 변경 등 이용자가 반드시 알고 있어야 하는 사항에 대해서는 전자우편 등의 방법으로 정보의 제공 등을 할 수 있습니다.
③ 회사는 광고주의 판촉 활동에 이용자가 참여하거나, 거래의 결과로써 발생하는 손실 또는 손해에 대해서는 책임을 지지 않습니다.

제24조 지적재산권
① 이용자는 "서비스"에 제공한 콘텐츠와 관련하여 저작권, 인격권 등 제 3자와의 분쟁 발생 시 그 법적 대응 및 결과에 대한 책임을 지며, "회사"는 해당 "콘텐츠"와 관련된 일체의 책임을 명시적으로 부인합니다.
② "회사"는 "서비스"를 통한 저작권 침해행위나 지적재산권 침해를 허용하지 아니하며, 이용자의 "콘텐츠"가 타인의 지적재산권을 침해한다는 사실을 적절하게 고지받거나 인지하게 되는 경우 저작권법 기타 관련 법령에서 정한 절차에 따라 그 "콘텐츠" 일체의 서비스를 중지하거나 제거할 수 있습니다. 또한 "회사"는 이용자의 "콘텐츠"가 제12조 제8항 각호 또는 제20조 제1항 각호에 해당할 경우 제15조 또는 제20조에서 정한 방법으로 “콘텐츠”를 삭제하거나 이동 또는 등록 거부할 수 있는 권리를 보유합니다.
③ 이용자의 "콘텐츠"에 대한 저작권은 원래의 저작자가 보유하되, 본 약관이 정하는 바에 따라 "회사" 혹은 "회사가 지정한 자"에게 "콘텐츠"에 대한 사용 권한을 부여합니다. "회사"가 이용자의 "콘텐츠"를 사용하는 용도와 방법은 아래와 같습니다.
 1. 이용자가 "서비스"에 제공한 "콘텐츠"를 다른 이용자가 시청하도록 함.
 2. 이용자가 "서비스"에 제공한 "콘텐츠"를 "회사"나 다른 이용자가 녹화/편집/변경하여 새로운 콘텐츠로 제작한 다음 이를 "서비스"의 다른 이용자들이 시청하게 하거나, 또는 "회사"의 제휴사에 제공하여 그 이용자들이 이를 시청할 수 있도록 함.
 3. 이용자가 "서비스"에 제공한 "콘텐츠"를 "회사"가 저장한 후 이를 VOD 등의 다시 보기 서비스로 다른 이용자들이 시청할 수 있게 하거나, "회사"의 제휴사에 제공함으로써 제휴사가 그 이용자들에게 스트리밍 또는 VOD 등의 다시 보기 서비스로 시청할 수 있게끔 함.
 4. 이용자가 “서비스”에 제공한 “콘텐츠”를 “회사”가 복제, 저장, 편집하여 “서비스” 홍보 목적으로 “회사”의 관리 채널 또는 블로그 등에 전송, 게시함.
 5. 이용자가 “서비스”에 제공한 “콘텐츠”를 분석 및 통계 작성, 기술 개발 기타 “서비스” 개선을 위한 목적으로 학술·연구기관·업체에게 제공함
④ 본 조 제3항에 규정한 "회사", 다른 이용자 및 "회사의 제휴사"가 이용자의 "콘텐츠"를 이용할 수 있는 조건은 아래와 같습니다.
 1. 콘텐츠 이용 매체/플랫폼 - 현재 알려져 있거나 앞으로 개발된 모든 형태의 매체, 장비, 기술을 포함함.
 2. 콘텐츠 이용 용도 - 상업적 또는 비상업적 이용을 구분하지 않음.
 3. 콘텐츠 이용 범위 - 국내 및 국외에서의 복제, 수정, 각색, 공연, 전시, 방송, 배포, 대여, 공중송신, 2차적 저작물 작성, 기타 이용
 4. 콘텐츠 이용 조건 - 비독점적, 지속적인 무상의 권리로서 양도 가능하며, 서브 라이선스가 가능함.
⑤ 이용자는 제3항에 따른 사용 허락을 "회사"가 공지, 이용안내에서 정한 바에 따라 장래에 대하여 철회할 수 있습니다.
⑥ “회사”가 이용자를 대신하여 저작권자로부터 저작물의 이용허락을 받을 경우 이용자가 지급한 유료 서비스 수수료의 일부는 그 이용요금을 지급하는 데에 사용됩니다. 단, 이용자는 “회사”가 안내·공지하는 저작물 이용조건 및 가이드를 준수하여야 하며, 이를 위반할 경우 이용자가 책임을 부담하여야 합니다.

제25조 관할법원과 준거법
① 서비스 이용에 관하여 회사와 이용자 간에 분쟁이 발생한 경우 협의로 원만히 해결합니다.
② 전항으로 해결되지 아니할 경우 소송의 관할은 민사소송법에 따라 정합니다.
③ 본 약관의 해석 및 적용은 대한민국 법령을 기준으로 합니다.

제26조 자료 전송 기술 사용 동의
"회사"는 자료의 전송을 위하여 이용자 간에 데이터를 중계 전송하는 기술을 사용할 수 있습니다. 이용자는 이 약관을 통해, PC 등 자신의 이용 장치를 통하여 다른 사람이 중계 전송받는 것을 허용하는데 동의합니다. 전송기술에 대한 세부적인 사항은 이용안내 등에서 게시하여 고지하며, 추가적인 내용에 대한 세부 동의는 별도의 파일전송관리자 이용약관에 대한 동의를 통하여 합니다.
이 약관은 2022년 12월 1일부터 시행합니다.
        </textarea>
      </div>

    </div>
    <!-- } Contents End -->

  </div>
  <!-- } Container End -->
  <Footer/>
</template>

<script>

import { useStore } from 'vuex';
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';

import '@/assets/css/vue3-pagination.css';
export default {
  components:{  
  HeaderTrade, 
  Footer,
},
  setup(){
    const store = useStore();
    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_policy'); 
    store.commit('common/CHANGE_CURRENT_PAGE', 'Policy');    
    return {      

    }
  }
}
</script>

<style>

</style>