<template>
                    <div class="mypage-main-inner" v-if="myReportList.length">                      
                      <!-- qna start { -->
                      <div class="qna">
                        <div
                          v-for="(item, index) in myReportList"
                          :key = index           
                        >
                          <div
                            class="qna-list-box"
                            v-if="item.REPLY_OK === 'Y'"
                            :class="{'active' : item.isActive}"
                          >
                            <div class="qna-title" @click="item.isActive = !item.isActive">
                                <span class="btn btn-sm btn-outline-gray status success">답변완료</span>
                                <span class="txt text-truncate">{{ item.SUBJECT }}</span>
                                <div class="qna-date">게시글 신고시간 : {{ item.MODIFICATIONDATE }}</div>
                                <span class="chevron"><i></i></span>
                            </div>
                            <div class="qna-area">
                                <div class="qna-q">
                                    <strong>신고내용</strong>
                                    <div class="question">
                                        <h4 class="title">{{ item.SUBJECT }}</h4>
                                        <p class="mt-20" v-html="item.CONTENTS"></p>
                                    </div>
                                </div>
                                <div class="qna-a">
                                  <strong>답변</strong>
                                  <div class="answer">
                                    <p v-html="item.REPLY"></p>
                                  </div>
                              </div>
                            </div>
                          </div>

                          <div
                            v-else
                            class="qna-list-box"
                            :class="{'active' : item.isActive}"
                          >
                            <div class="qna-title" @click="item.isActive = !item.isActive">
                                <span class="btn btn-sm btn-outline-gray status ready">답변대기</span>
                                <span class="txt text-truncate">{{ item.SUBJECT }}</span>
                                <div class="qna-date">게시글 신고시간 : {{ item.MODIFICATIONDATE }}</div>
                                <span class="chevron"><i></i></span>
                            </div>
                            <div class="qna-area">
                                <div class="qna-q">
                                    <strong>문의</strong>
                                    <div class="question">
                                        <h4 class="title">{{ item.SUBJECT }}</h4>
                                        <p class="mt-20" v-html="item.CONTENTS"></p>
                                    </div>
                                    <div class="qna-btn-box">
                                      <!-- <router-link :to="{ name:'QnaWrite', params:{ id: item.IDX }}">수정</router-link> -->
                                      <button type="button" class="btn btn-sm btn-danger" @click="onDelete(item.IDX)">삭제</button>
                                    </div>
                                </div>
                                <!-- <div class="qna-q">
                                  <div class="question">
                                    <h3 class="th">제목</h3>
                                    <h4 class="title">{{ item.SUBJECT }}</h4>
                                  </div>
                                  <div class="question">
                                    <h3 class="th">내용</h3>
                                    <p class="mt-20" v-html="item.CONTENTS"></p>
                                  </div>
                                  <div class="qna-btn-box">
                                    <router-link :to="{ name:'QnaWrite', params:{ id: item.IDX }}">수정</router-link>
                                    <button type="button" class="btn btn-sm btn-danger" @click="onDelete(item.IDX)">취소</button>
                                  </div>
                                </div> -->
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div v-else class="mypage-main-null">신고하신 내역이 없습니다.</div>
                      
                    <!-- 페이징 start-->
                    <div class="section paging-area pb-1 mb-0" v-if="myReportList.length">
                      <v-pagination                  
                        v-model="currentPage"
                        :pages= numberOfPages
                        :rangeSize="1"
                        activeColor="#ff5d8b"                   
                        @update:modelValue="updateHandler(currentPage)"
                      />
                    </div>
                    <!-- //페이징 end -->

  <boardDeleteModal
    v-if="showModal" 
    @close="closeModal" 
    @cancle="closeModal" 
    @ok="okModal" 
  />

</template>

<script>
import { getCurrentInstance, ref, computed, watch } from 'vue';
import {useApicall} from '@/composables/commonFn';
import VPagination from "@hennge/vue3-pagination";
import '@/assets/css/vue3-pagination.css';
import boardDeleteModal from '@/components/popups/boardDeleteModal.vue';

export default {
  props: {
    itemType: {
      type:String,
      required: true,
    },
  },  
  components: {
    VPagination,
    boardDeleteModal,
  },

  setup(props) {
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const { apiCall_gt } = useApicall();
    const limit = ref(10);
    const offset = ref(0);
    const currentPage = ref(1);     
    const numberOfData = ref(0);
    const numberOfPages = computed(()=>{
      return Math.ceil(numberOfData.value/limit.value)
    });
    
    const myReportList = ref([]);
    const propsItemType = computed(()=>props.itemType)
    const showModal = ref(false);
    const reportItemId = ref('');
    const popupTitle = ref('');

    const updateHandler = (e) => {
      getMyReportList(e);
    }

    const getMyReportList = async(page = currentPage.value) => {
      currentPage.value = page;
      var offset = (currentPage.value -1) * limit.value;
      var getUrl = `board/myreportlist?limit=${limit.value}&offset=${offset}`

      var reportDataList = await apiCall_gt(getUrl);

      try {
        if(reportDataList.dataList == null) reportDataList.dataList = [];
        myReportList.value = reportDataList.dataList;
        numberOfData.value = reportDataList.dataCount;
      } catch(error) {
        myReportList.value = [];
        numberOfData.value  = 0;
      }
    }

    const deletCall = async (id) =>{
      var res  =  await apiCall_gt(`board/myreportdelete/${id}`, 'DEL');
      if(res) {
       console.log('deletCall', id);
        emitter.emit('EVENT_ALERT',{title:"알림", body:"신고하신 내용이 삭제되었습니다."});
      }
      getMyReportList(1);
    }

    const onDelete = (id) => {
      var getId = id;
      showModal.value = true;
      reportItemId.value = getId;
    }

    const okModal = async() => {
      showModal.value = false; 
      deletCall(reportItemId.value);
      reportItemId.value = '';
    }

    const closeModal = () => {
      showModal.value = false; 
    }    

    watch(propsItemType, ()=> {      
      if(propsItemType.value == 'report') {
        updateHandler(1);
      } else {
        return false;
      }
    })

    return{
      limit,
      offset,
      myReportList,
      numberOfPages,
      currentPage,
      updateHandler,
      onDelete,
      okModal,
      closeModal,
      showModal,
      reportItemId,
      popupTitle,
    }
  }
}
</script>

<style>

</style>