<template>
    <div class="toast-box fcm">
        <transition-group name="slide">
            <div class="fcm-alarm"
                v-for="toast in toastFcm"
                :key="toast.id"
                @click="onLinkUrl"
                >
                <div class="alarm-text">
                    <div class="title text-truncate">{{ toastFcm[0].fcmData.data.title }}</div>
                    <div class="txt text-truncate">{{ toastFcm[0].fcmData.data.body }}</div>
                </div>
                <div class="alarm-ico">
                    <img src="@/assets/images/trade/ico_alarm.png" alt="ico">
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { useToast } from '@/composables/toast';
import Constant from '@/constant.js';
// import { onMounted } from 'vue';

export default {  
    setup(){
        const { toastFcm  } = useToast();
        // setInterval(()=>{
        //     console.log("toastFcm:~~", toastFcm.value.length, toastFcm.value[0], toastFcm.value[0].fcmData.data.body, toastFcm.value[0].fcmData.data.title, toastFcm.value[0].fcmData.data.linkUrl)
        // }, 2000)

        const onLinkUrl = () => {
            if(toastFcm.value[0].fcmData.data.linkUrl) {
                 window.location.href = toastFcm.value[0].fcmData.data.linkUrl;
                // console.log('clicked11111')
            } else {
                return null;
            }
        }

        return {
            toastFcm,
            Constant,
            onLinkUrl
        }
    }
}
</script>

