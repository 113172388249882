<template>
                <!-- 호가 start -->
                <div class="tab-pane fade show active" id="tradePriceTab" role="tabpanel" aria-labelledby="trade-price-tab">
                    <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                    <!-- 호가창 상단 시작  -->
                    <div class="trade-summary">
                    <table class="table">
                        <colgroup>
                        <col style="width: 5.9375%" />
                        <col style="width: 13.9375%" />
                        <col style="width: 14.0625%" />
                        <col style="width: 32.125%" />
                        <col style="width: 14.0625%" />
                        <col style="width: 13.9375%" />
                        <col style="width: 5.9375%" />
                        </colgroup>
                        <tr>
                        <th   colspan="7" style="height:25px;cursor:pointer " class="text-black-50"  @click="onClickCenter"> ⓒ 모의투자 전용입니다</th>
                        </tr>
                        <tr>
                        <th></th>
                        <th colspan="2" class="text-end text-danger">당일고가</th>
                        <th class="text-danger">{{Constant.UTIL.numberWithCommas(Number(highPrice).toFixed(parseInt(selectItem.NDOTPOS)))}}</th>
                        <th colspan="2"></th>
                        <th></th>
                        </tr>
                    </table>
                    </div>
                    <!-- //호가창 상단 종료 -->

                    <!-- 호가창 시작  -->
                    <!-- <div class="trade-area" id="tradeArea"> -->

                    <perfect-scrollbar 
                        @ps-scroll-y="onScroll" 
                        :options="options"
                        :watch-options="watcher"
                        ref="scrollbar"
                        class="trade-area " id="tradeArea"

                    >


                        <table class="table" id="tradeInArea">
                            <colgroup>
                            <col style="width: 5.9375%" />
                            <col style="width: 13.9375%" />
                            <col style="width: 14.0625%" />
                            <col style="width: 32.125%" />
                            <col style="width: 14.0625%" />
                            <col style="width: 13.9375%" />
                            <col style="width: 5.9375%" />
                            </colgroup>
                            <tbody>
           
                            <tr   
                                v-for="(askOrder, index) in askListArr"
                                :key = askOrder.index        
                                :class="index === askListArr.length -1  ? 'down baseline' : 'down'"
                            >
                                <td class="sell top"></td>
                                <td class="bar" colspan="2">
                                    <div :style="{width: askOrder.size_pre + '%'}">-</div> 
                                    <p>{{askOrder.size == 0 ? "" : Constant.UTIL.numberWithCommas(Number(askOrder.size).toFixed(3))}}</p>
                                </td>
                                <td class="up-block" id = "price-area"  @mouseover.stop="mouseOver" @mouseleave.stop="mouseLeave">
                                    <a style="cursor: pointer" @click="onPriceClick(askOrder.price)" :class="askOrder.price == tradeObj.p ? 'selected' : '' ">
                                        <div class="amount"><strong :class="askOrder.colorClass"> {{ askOrder.price==0?'':Constant.UTIL.numberWithCommas(Number(askOrder.price).toFixed(parseInt(selectItem.NDOTPOS)))}} </strong></div>
                                        <div class="ratio" :class="askOrder.colorClass" >{{askOrder.price==0?'':isNaN(Number(askOrder.price_pre)) ? "" : Number(askOrder.price_pre).toFixed(2) + "%"}}</div>
                                        
                                    </a>
                                     <!-- <span v-show="askOrder.price == getCurrentAssetPrice(askOrder.price)"  :class="currentAsset.TRADETYPE == '4' ?'arrow-sell':currentAsset.TRADETYPE != 0?'arrow-buy':'arrow-null'"   ref="arrow_1">&#9654;</span> -->
                                     
                                </td>
                                <td colspan="2" class="trade-info">
                          

                                    <dl                   
                                    v-if="askOrder.index == 16 + 25" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd" >전일대비</dt>
                                        <dd class="info_dd info_dl_dd" :class="tradeObj.p - prevClosingPrice >  0 ? 'up_price_color':'down_price_color'" >{{tradeObj.p - prevClosingPrice >  0 ? "▲ " +  Constant.UTIL.numberWithCommas(Math.abs( tradeObj.p - prevClosingPrice ).toFixed(parseInt(selectItem.NDOTPOS))) : "▼ " + Constant.UTIL.numberWithCommas(Math.abs(tradeObj.p - prevClosingPrice).toFixed(parseInt(selectItem.NDOTPOS)))}}</dd>
                                    </dl>

                                    <dl                   
                                    v-if="askOrder.index == 17 + 25" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">등락률</dt>
                                        <dd class="info_dd info_dl_dd" :class="tradeObj.p - prevClosingPrice >  0 ?'up_price_color':'down_price_color'">{{tradeObj.p - prevClosingPrice >  0 ?  "+" +  Math.abs(tradingPerForCoin).toFixed(2)+ "%"     :    "-" +  Math.abs(tradingPerForCoin).toFixed(2)+ "%"  }}</dd>
                                        <!-- <dd class="info_dd info_dl_dd" :class="tradeObj.trade_price - prevClosingPrice >  0 ?'up_price_color':'down_price_color'">{{tradeObj.trade_price - prevClosingPrice >  0 ?  "+ " +  Math.abs(tradeObj.drate).toFixed(2) : "- " +   Math.abs(tradeObj.drate).toFixed(2)}}</dd> -->
                                    </dl>



                                    <dl                   
                                    v-if="askOrder.index == 18 + 25" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">거래량</dt>
                                        <dd class="info_dd info_dl_dd same_price_color">{{Constant.UTIL.numberWithCommas(Number(tradingVolume).toFixed(2))}}</dd>
                                    </dl>

                                    <dl                   
                                    v-if="askOrder.index == 19 + 25" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">시</dt>
                                        <dd class="info_dd info_dl_dd" :class="prevClosingPrice < openingPrice?'up_price_color':'down_price_color'">{{selectItem.TYPE=="F"?Constant.UTIL.numberWithCommas(Number(openingPrice).toFixed(parseInt(selectItem.NDOTPOS))):Constant.UTIL.numberWithCommas(openingPrice)}}</dd>
                                    </dl>

                                    <dl                   
                                    v-if="askOrder.index == 20 + 25" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">고</dt>
                                        <!-- <dd class="info_dd info_dl_dd up_price_color">{{selectItem.TYPE=="F"?Constant.UTIL.numberWithCommas(Number(highPrice).toFixed(parseInt(selectItem.NDOTPOS))):Constant.UTIL.numberWithCommas(highPrice)}}</dd> -->
                                        <dd class="info_dd info_dl_dd" :class="prevClosingPrice < highPrice?'up_price_color':'down_price_color'">{{Constant.UTIL.numberWithCommas(Number(highPrice).toFixed(parseInt(selectItem.NDOTPOS)))}}</dd>
                                    </dl>

                                    <dl                   
                                    v-if="askOrder.index == 21 + 25" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">저</dt>
                                        <dd class="info_dd info_dl_dd" :class="prevClosingPrice < lowPrice?'up_price_color':'down_price_color'">{{Constant.UTIL.numberWithCommas(Number(lowPrice).toFixed(parseInt(selectItem.NDOTPOS)))}}</dd>
                                    </dl>



                                    <dl                   
                                    v-if="askOrder.index == 22 + 25" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">전일가</dt>
                                        <dd class="info_dd info_dl_dd same_price_color" >{{Constant.UTIL.numberWithCommas(Number(prevClosingPrice).toFixed(parseInt(selectItem.NDOTPOS)))}}</dd>
                                    </dl>
                                    <!-- <dl                   
                                    v-if="askOrder.index == 23" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd"  :class="selectItem.TYPE=='F'?'':'up_price_color'" >{{selectItem.TYPE=="F"?"틱가치":selectItem.TYPE=="C"?'':'상'}}</dt>
                                        <dd class="info_dd info_dl_dd"  :class="selectItem.TYPE=='F'?'':'up_price_color'" >{{selectItem.TYPE=="S"?Constant.UTIL.numberWithCommas(Number(uplmtprice)):selectItem.TYPE=="C"?'-':getTicValueForWon()}}</dd>
                                    </dl>
                                    <dl                   
                                    v-if="askOrder.index == 24" 
                                    class = "info_dl"
                                    >
                                        <dt class="info_dt info_dl_dd" :class="selectItem.TYPE=='F'?'':'down_price_color'">{{selectItem.TYPE=="F"?"만기일":selectItem.TYPE=="C"?'':'하'}}</dt>
                                        <dd class="info_dd info_dl_dd" :class="selectItem.TYPE=='F'?'':'down_price_color'">{{selectItem.TYPE=="S"?Constant.UTIL.numberWithCommas(Number(dnlmtprice)):selectItem.TYPE=="C"?'-':getLiquidationDate()}}</dd>
                                    </dl> -->

                                </td>
                                <td></td>
                            </tr>



                            <tr 
                                v-for="(bidOrder, index ) in bidListArr"
                                :key = bidOrder.index
                                class="up"  :class="index"
                            >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="down-block" id = "price-area" @mouseover="mouseOver" @mouseleave="mouseLeave">
                                    <a style="cursor: pointer" @click="onPriceClick(bidOrder.price)" :class="bidOrder.price == tradeObj.p ? 'selected' : '' ">
                                        <div class="amount"><strong><strong :class="bidOrder.colorClass" >{{ bidOrder.price==0?'':Constant.UTIL.numberWithCommas(Number(bidOrder.price).toFixed(parseInt(selectItem.NDOTPOS)))}}</strong></strong></div>
                                        <div class="ratio" :class="bidOrder.colorClass" >{{ bidOrder.price==0?'':isNaN(Number(bidOrder.price_pre))?"" : Number(bidOrder.price_pre).toFixed(2) + "%"}}</div>
                                    </a>
                                    <!-- <span v-show="bidOrder.price == getCurrentAssetPrice(bidOrder.price)"   :class="currentAsset.TRADETYPE == '4' ?'arrow-sell':currentAsset.TRADETYPE != 0?'arrow-buy':'arrow-null'"  ref="arrow_2">&#9654;</span> -->
                                  
                                </td>
                                <td class="bar" colspan="2">
                                    <div :style="{width: bidOrder.size_pre + '%'}">-</div> 
                                    <p>{{ bidOrder.size == 0 ? "" : Number(bidOrder.size).toFixed(3)  }}</p>
                                </td>
                                <td class="buy bottom"></td>
                            </tr>
      
                            
                            </tbody>
                        </table>
                    </perfect-scrollbar>
                    <!-- //호가창 종료 -->

                    <!-- 호가창 하단 시작 -->
                    <div class="trade-summary">
                    <table class="table t2">
                        <colgroup>
                        <col style="width: 10.9375%" />
                        <col style="width: 10.9375%" />
                        <col style="width: 14.0625%" />
                        <col style="width: 28.125%" />
                        <col style="width: 14.0625%" />
                        <col style="width: 10.9375%" />
                        <col style="width: 10.9375%" />
                        </colgroup>
                        <tr>
                        <th></th>
                        <th colspan="2"></th>
                        <th class="text-primary">{{Constant.UTIL.numberWithCommas(Number(lowPrice).toFixed(parseInt(selectItem.NDOTPOS)))}}</th>
                        <th colspan="2" class="text-primary text-start">당일저가</th>
                        <th></th>
                        </tr>
                        <tr>
                        <th></th>
                        <th colspan="2">{{currentTotalAskSize}}</th>
                        <th>{{selectItem.TYPE=="C"?`수량(${selectItem.CODE.split('-')[1]})`:`수량`}}</th>
                        <th colspan="2">{{currentTotalbidSize}}</th>
                        <th></th>
                        </tr>
                    </table>
                    </div>
                    <!-- //호가창 하단 종료 -->

                </div>
                <!-- //호가 end -->

               
</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { useStore } from 'vuex';
import { onMounted, ref,  getCurrentInstance, computed, reactive, watchEffect, watch, onUnmounted} from 'vue';
import {useCommonFunction} from '@/composables/vtradeFn';
import Constant from '@/constant.js';
export default {
    components: {
        PerfectScrollbar
    },
    props:
    { 
       selectItem:{        
           require:true
       },
    },
    emits:['change-select-price'],
    setup(props, {emit}){
        const  trace = getCurrentInstance().appContext.config.globalProperties.$log;    
        const internalInstance = getCurrentInstance(); 
        const emitter = internalInstance.appContext.config.globalProperties.emitter;  
        const $ = require('jquery');
        const store = useStore();
        const listSize = ref(50); 
        const addSize = ref(0)
        const askListArr = reactive([]);
        const bidListArr = reactive([]);
        const orderbook_a = computed(() => store.state.hts.orderbook_a);
        const orderbook_b = computed(() => store.state.hts.orderbook_b);
        const tradeObj = computed(() => store.state.hts.trade);
        const initData = computed(() => store.state.hts.initTrade);
        const currentTradeType = computed(() => store.state.hts.currentTradeType);
        const currencyInfo =  computed(()=>store.state.hts.currencyInfo)
        const scrollbar = ref(null);
  
        // const currentTotalAskSize = computed(() => Constant.UTIL.numberWithCommas(Number(store.state.hts.currentTotalAskSize).toFixed(currentTradeType.value == "C"?3:0)));
        // const currentTotalbidSize = computed(() => Constant.UTIL.numberWithCommas(Number(store.state.hts.currentTotalbidSize).toFixed(currentTradeType.value == "C"?3:0)));

        const currentTotalAskSize =  ref(0);
        const currentTotalbidSize =  ref(0);
        //const currentTotalAskCount = computed(() => Constant.UTIL.numberWithCommas(parseInt(store.state.hts.currentTotalAskCount)));
        //const currentTotalbidCount = computed(() => Constant.UTIL.numberWithCommas(parseInt(store.state.hts.currentTotalbidCount)));

        //const orderList = computed(() => store.state.hts.orderList);

        const intervalId = ref(null);
        const currentTime = ref("00:00:00");
        const tradingVolume = ref(0);
        const tradingPerForCoin = ref(0);
 

        const openingPrice = ref(0);
        const highPrice = ref(0);
        const lowPrice = ref(0);
        const prevClosingPrice = ref(0);


        const uplmtprice = ref(0);
        const dnlmtprice = ref(0);

   
        const {         
            getPriceGap
        } = useCommonFunction();

        const arrow_1 = ref(null);
        const arrow_2 = ref(null);
        

        const currentAsset =  computed(()=>{
           var result = {};
           var item  = store.state.hts.assetlist.find(element => element.CODE == props.selectItem.CODE);  
               
           if(item && item.PRICE && item.TRADETYPE)
           {
             return  item;
           }
           else
           {
               result.PRICE = 0;
               result.TRADETYPE = 0;
              return  result;
           }
            
        });

       const getCurrentAssetPrice = (t_price)=>{
           var result = 0;
           if(props.selectItem.TYPE == "F")
           {
               result = Number(Number(currentAsset.value.PRICE).toFixed(props.selectItem.NDOTPOS));
           }
           else
           {
                var gapPice = getPriceGap(t_price, props.selectItem); 
                var remain =   currentAsset.value.PRICE % gapPice;
                if(remain > (gapPice/2))
                {
                    result = (currentAsset.value.PRICE - remain) + gapPice;
                }
                else
                {
                    if(remain == 0)
                    {
                        result = currentAsset.value.PRICE;
                    }
                    else
                    {
                        result = currentAsset.value.PRICE - remain
                    }
                }  
           }
           //console.log("getCurrentAssetPrice :", currentAsset.value.PRICE, "result : ", result)
           return result;
       }



        const addList = () => {          
         let _size = listSize.value + addSize.value;
         for(let i =0; i<_size; i++ ){
           let obj_ask = {
              index: i,
              price: 0,
              size: 0,
              count: 0,
              size_pre: 0,
              price_pre:0,          
              colorClass: "same_price_color"
            }

            let obj_bid = {
              index: i,
              price: 0,
              size: 0,
              count:0,
              size_pre: 0,
              price_pre: 0,         
              colorClass: "same_price_color"
            }
          askListArr.push(obj_ask);
          bidListArr.push(obj_bid);
         }
        //  askListArr.reverse();
        }
        

        emitter.off('EVENT_SOCKET_INIT_DATA')
        emitter.on('EVENT_SOCKET_INIT_DATA', () => {    
           
            
            let val = Number(initData.value.volume24h);
            let closePrice = Number(initData.value.prevPrice24h);
     
            let t_openingPrice = Number(initData.value.prevPrice24h);
            let t_highPrice = Number(initData.value.highPrice24h);
            let t_lowPrice = Number(initData.value.lowPrice24h);    
            


            if( props.selectItem.TYPE == "G" )
            {
               let t_per_c = (Number(initData.value.lastPrice) - closePrice ) / closePrice * 100;
               if(!isNaN(t_per_c)) tradingPerForCoin.value = t_per_c;              
            } 



              
            if(!isNaN(closePrice)) 
            {
                prevClosingPrice.value = closePrice;                 
            } 

             tradingVolume.value = 0;
            if(!isNaN(val)) 
            {               
                 //val = (val / 10000);
                 tradingVolume.value = val;
                // console.log("sss", tradingVolume.value )
            } 


            if(!isNaN(t_openingPrice)) 
            {
                openingPrice.value = t_openingPrice;           
            } 
            if(!isNaN(t_highPrice)) 
            {
                highPrice.value = t_highPrice;           
            } 
            if(!isNaN(t_lowPrice)) 
            {
                lowPrice.value = t_lowPrice;           
            } 
                    
        })


        emitter.off('EVENT_INIT_ITEM_HTS');
        emitter.on('EVENT_INIT_ITEM_HTS', () => {
           //  console.log("EVENT_INIT_ITEM_HTS^^~~~")
            scrollReset('#tradeArea');
            
            // for(var i=0; i<20; i++)
            // {                
            //    arrTradeHistory[i] = null;
            // }
            
        })

         emitter.off('EVENT_SCROLL_RESET_HTS');
         emitter.on('EVENT_SCROLL_RESET_HTS', () => {  
              scrollReset('#tradeArea');
         })






       watch(tradeObj, ()=>{

                    
            if(!isNaN( Number(tradeObj.value.v)))
            {
                tradingVolume.value += Number(tradeObj.value.v);
            }
            let t_per_c = (Number(tradeObj.value.p) - Number(initData.value.prevPrice24h) ) / Number(initData.value.prevPrice24h) * 100;
            if(!isNaN(t_per_c)) tradingPerForCoin.value = t_per_c;
            let tr_price = Number(tradeObj.value.p);         
            if(!isNaN(tr_price))
            {                       
               if(highPrice.value < tr_price) highPrice.value = tr_price;
               if(lowPrice.value > tr_price) lowPrice.value = tr_price;             
            }
       })






       const onClickCenter = ()=>{
        scrollReset('#tradeArea');
       }

        



        const scrollReset = (targetArea) => {
           
            trace("scrollReset scrollbar ",$(targetArea ).height() , $(targetArea + ' table').height())
            // console.log("scrollReset:", $(targetArea + ' table').height(), $(targetArea ).height())
            // $(targetArea).scrollTop($(targetArea + ' table').height()/2)

           // console.log("route.name", route.name)
            var oneHeight = $(targetArea + ' table').height() /  (listSize.value + addSize.value);

            oneHeight
            $(targetArea).animate(
            {                
               // scrollTop: ($(targetArea + ' table').height() / 2 - $(targetArea ).height() / 2 )+ (oneHeight/2),
                scrollTop: ($(targetArea + ' table').height() / 2   - $(targetArea ).height() / 2 ) 
            },100,  
            );
        }

        const onScroll = (e) =>{
            e
            // trace(e.target.scrollTop); 
        }




        const  getTime = () =>{
            const time = new Date();
            let hou = time.getHours();
            const hour = hou < 10 ? "0"+ hou : hou ;
            let min = time.getMinutes();
            const minutes = min < 10 ? "0"+ min : min ;
            let sec = time.getSeconds();
            const seconds = sec < 10 ? "0"+ sec : sec ;
            return  hour +":" + minutes + ":"+seconds;
        }

        intervalId.value =  setInterval(()=>{           
             currentTime.value = getTime();
        }, 1000)






        onMounted(()=>{
 
  
        
            addList();
            setTimeout(()=>{
                scrollReset('#tradeArea');
            }, 1000)




            watchEffect(()=>{
                


                //console.log("orderbook:", orderbook.value, orderbook.value.length)
                var _size = listSize.value + addSize.value;    
                
                

                var maxSize = 0;
                var n_orderbook_a = [...orderbook_a.value];           
                var n_orderbook_b = [...orderbook_b.value];            
                n_orderbook_a.reverse();                
                if(!n_orderbook_a   || n_orderbook_a.length == 0  )return;  
                if(!n_orderbook_b   || n_orderbook_b.length == 0  )return;  


                let current_askPrice;
                let current_bidPrice;
                for (let i=0; i<_size; i++) {
                    if(n_orderbook_a[i])
                    {
                        let a_price = Number(n_orderbook_a[i][0]);
                        let a_size =  Number(n_orderbook_a[i][1]); 
                        current_askPrice = a_price;
                        askListArr[i].price = a_price;
                        askListArr[i].size = a_size;

                        if(maxSize < a_size)
                        {                     
                            maxSize = a_size;
                        }
                    }

                    if(n_orderbook_b[i])
                    {
                        
                            let b_price = Number(n_orderbook_b[i][0]);
                            let b_size = Number(n_orderbook_b[i][1]);   
                            current_bidPrice = b_price;

                            
                            bidListArr[i].price = b_price;               
                            bidListArr[i].size = b_size;
                            if(maxSize < b_size)
                            {                           
                                maxSize = b_size;
                            } 
                    }    
            
                    
                    let prev_closing_price = Number(initData.value.prevPrice24h);
                    let t_per_a = (current_askPrice - prev_closing_price ) / prev_closing_price * 100;
                    askListArr[i].price_pre = t_per_a;
                
                    if(t_per_a > 0)  askListArr[i].colorClass = "up_price_color";
                    else if(t_per_a < 0)  askListArr[i].colorClass = "down_price_color";
                    else if(t_per_a == 0)  askListArr[i].colorClass = "same_price_color";


                
                    //let prev_closing_price = Number(initData.value.prevPrice24h);
                    let t_per_b = (current_bidPrice - prev_closing_price ) / prev_closing_price * 100;
                    bidListArr[i].price_pre = t_per_b; 
                    if(t_per_b > 0) bidListArr[i].colorClass = "up_price_color";
                    else if(t_per_b < 0) bidListArr[i].colorClass = "down_price_color";
                    else if(t_per_b == 0) bidListArr[i].colorClass = "same_price_color";


                }




                
                // let current_bidPrice;
                // for (let ii=0; ii<_size; ii++) {

                //     if(n_orderbook_b[ii])
                //     {
                        
                //             let b_price = Number(n_orderbook[ii][0]);
                //             let b_size = Number(n_orderbook[ii][1]);   
                //             current_bidPrice = b_price;

                            
                //             bidListArr[ii - _size].price = b_price;               
                //             bidListArr[ii - _size].size = b_size;
                //             if(maxSize < b_size)
                //             {                           
                //                 maxSize = b_size;
                //             } 
                //     }    
            
                
                //     let prev_closing_price = Number(initData.value.prevPrice24h);
                //     let t_per = (current_bidPrice - prev_closing_price ) / prev_closing_price * 100;
                //     bidListArr[ii - _size].price_pre = t_per; 
                //     if(t_per > 0) bidListArr[ii - _size].colorClass = "up_price_color";
                //     else if(t_per < 0) bidListArr[ii - _size].colorClass = "down_price_color";
                //     else if(t_per == 0) bidListArr[ii - _size].colorClass = "same_price_color";
                // }
                
        
                
                let totalAskSize = 0;
                let totalBidSize = 0;

                for(let j = 0; j<bidListArr.length; j++)
                {
                    let size = bidListArr[j].size;                
                    let size_pre = size * 100 / maxSize ;
                    if(isNaN(size_pre)) size_pre = 0;
                    bidListArr[j].size_pre = Math.floor(size_pre);

                    totalBidSize += bidListArr[j].size; 
                    //console.log("bidListArr[j].size_pre", bidListArr[j].size_pre)
                }
                for(let w = 0; w<askListArr.length; w++)
                {
                    let size = askListArr[w].size;                
                    let size_pre = size * 100 / maxSize ;
                    if(isNaN(size_pre)) size_pre = 0;
                    askListArr[w].size_pre = Math.floor(size_pre); 
                    
                    totalAskSize += askListArr[w].size;
                }

                 currentTotalAskSize.value  =  Constant.UTIL.numberWithCommas(Math.floor(totalAskSize));
                 currentTotalbidSize.value =  Constant.UTIL.numberWithCommas(Math.floor(totalBidSize));

            })
 
                       
         })

        onUnmounted (()=>{
           // emitter.off('EVENT_INIT_ITEM_HTS');
           // emitter.off('EVENT_SCROLL_RESET_HTS');
            clearInterval(intervalId.value);
        })

        const onPriceClick = (price) =>{
             
              emit('change-select-price', Number(price));
        }

        const getTicValueForWon = () =>{
           
            if(props.selectItem.TYPE != "F")return;
            if(props.selectItem.SUBTYPE == 'K')
            {                
                return "12,500원"
            }
            
            if(props.selectItem.PRODUCTCODE == 'HSI')
            {                
                return Constant.UTIL.numberWithCommas(Math.floor(Number(props.selectItem.MINCHANGEAMOUNT )*Number(currencyInfo.value.HKD_KRW))) + "원"
            }
            else
            {            
               return Constant.UTIL.numberWithCommas(Math.floor(Number(props.selectItem.MINCHANGEAMOUNT )*Number(currencyInfo.value.USD_KRW))) + "원"
            }
           
        }


        const getLiquidationDate = () =>{
            if( !props.selectItem.LIQUIDATIONDATE)return;
            let year = props.selectItem.LIQUIDATIONDATE.substring(0,4);
            let mon = props.selectItem.LIQUIDATIONDATE.substring(4,6);
            let day = props.selectItem.LIQUIDATIONDATE.substring(6,8);
            return year + "." + mon + "." + day; 
        }


        return{
            options: {       
                wheelSpeed: 1,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true,
            },
            watcher: true,
            onScroll,
            scrollReset,
            tradeObj,
            askListArr,
            bidListArr,
            scrollbar,             
            currentTotalAskSize,
            currentTotalbidSize,
            currentTime,
            tradingVolume,
            openingPrice,
            highPrice,
            lowPrice,
            prevClosingPrice,
            Constant,
            uplmtprice,
            dnlmtprice,
           // arrTradeHistory,
            onPriceClick,
   
        
     
            currentTradeType,
           
            tradingPerForCoin,
            getTicValueForWon,
            getLiquidationDate,
            currencyInfo,
            //currentTotalAskCount,
            //currentTotalbidCount,
           
            currentAsset,
            arrow_1,
            arrow_2,
            getCurrentAssetPrice,
            onClickCenter
            
        }
    }

}
</script>

<style scoped>
.up_price_color {
    color: #ff024A!important;
}

.down_price_color {
    color: #3182F6!important;
}

.same_price_color {
    color: rgb(0, 0, 0)!important;
}
</style>