<template>
           <!-- 모의투자/플렉스TV 링크 start -->
            <div class="section aside-sts-link">
              <div>
                <span><img src="@/assets/images/main/link_sts.png" alt="모의투자"></span>
                <router-link :to="{ name: 'Home'}"> 모의투자 하러가기</router-link>
              </div>
              <div>
                <span><img src="@/assets/images/main/link_flextv.png" alt="flextv"></span>
                <a href="https://www.flextv.co.kr" target='_blank' >실시간방송 시청하기</a>
              </div>
            </div>
            <!-- //모의투자/플렉스TV 링크 end -->
</template>

<script>
export default {

}
</script>

<style>

</style>