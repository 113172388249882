<template>
  <!-- 알림 창 start -->
        <div class="modal micromodal-slide" id="modal-order-alert" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close="true">
            <div
              class="modal-content modal-box"
              role="dialog"
              aria-modal="true"
            >
              <div class="modal-header">
                <h1 class="modal-title">청산 주문</h1>
                <button type="button" class="btn-close" data-micromodal-close="true"></button>
              </div>
              <div class="modal-body">
                <p class="modal-box-alert">
                  선택한 주문을 청산할까요?
                </p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-micromodal-close="true">취소</button>
                <button type="button" class="btn btn-danger confirm" data-micromodal-close="true">확인</button>
              </div>
            </div>
          </div>
        </div>
        <!-- //알림 창 end -->

        
        <!-- 알림 창 start -->
        <div class="modal micromodal-slide" id="modal-order-alert-one" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close="true">
            <div
              class="modal-content modal-box"
              role="dialog"
              aria-modal="true"
            >
              <div class="modal-header">
                <h1 class="modal-title">청산 주문</h1>
                <button type="button" class="btn-close" data-micromodal-close="true"></button>
              </div>
              <div class="modal-body">
                <p class="modal-box-alert">
                  선택한 주문을 청산할까요?
                </p>
              </div>
              <div class="modal-footer">
                <button type="button" style="display:none;" class="btn btn-outline-secondary" data-micromodal-close="true">취소</button>
                <button type="button" class="btn btn-danger confirm" data-micromodal-close="true">확인</button>
              </div>
            </div>
          </div>
        </div>
        <!-- //알림 창 end -->


</template>

<script>
export default {

}
</script>

<style>

</style>