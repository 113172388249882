<template>
   <HeaderTrade/> 
   <Footer/>
</template>

<script>
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import {useRoute }from 'vue-router'
export default {
      components:{  
        HeaderTrade, 
        Footer,       
    },    
    setup(){
      const route = useRoute();

      setTimeout(() => {
         console.log("route.params.item:^^^^@@@@@@@@@@@@@@@@@@@@@@@@@@22222", route.params)
      }, 5000);
      console.log("route.params.item:^^^^@@@@@@@@@@@@@@@@@@@@@@@@@@", route.params)
      return{

      }
    }

}
</script>


<style>

</style>