import {
	getHistoryKey,
	makeApiRequest,
	generateSymbol,
	//parseFullSymbol,
} from './helpers.js';
import {
	subscribeOnStream,
	unsubscribeFromStream,
	socketId
} from './streaming.js';
import moment from 'moment';
//import { useStore } from 'vuex';
moment

var allSymbolsCache = null;
const lastBarsCache = new Map();

const configurationData = {
	//supported_resolutions: ['1D', '1W', '1M'],
	//supported_resolutions: ["1", "3", "5", "30", "60", "1D", "1W"],
	supported_resolutions: ["1", "3", "5",  "15", "30", "60", "240", "1D", "1W", "1M"],
	exchanges: [
	{
		// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
		value: 'Flex',

		// filter name
		name: 'Flex',

		// full exchange name displayed in the filter popup
		desc: 'Flex',
	},
	],
	symbols_types: [{
		name: 'coinFutures',
		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
		value: 'coinFutures',
	}
		// ...
	],
	supports_time : true
};

async function getAllSymbols() {	
	//const store = useStore();
    //console.log("dddddddddddddddddd~~~:. crypto")

	if(allSymbolsCache == null)
	{
		const data = await makeApiRequest('index/getitemlist?type=G');
		let allSymbols = [];
		for (const item of data.resultMap.dataList) {   
			
			const symbol = generateSymbol('Flex',  item.CODE.substring(0,item.CODE.length-4), item.CODE.substring(item.CODE.length-4));
			//console.log(" item.CODE:",  item.CODE, symbol)		
			var sObj = {
				symbol: symbol.short,
				full_name: symbol.full,
				description: item.NAME,
				exchange:'Flex',
				type: 'coinFutures',
				CODE: item.CODE,
				NAME: item.NAME,
				NDOTPOS:parseInt(item.NDOTPOS)
				
			}
			allSymbols.push(sObj)
		}
		allSymbolsCache = allSymbols;
	}


	//console.log("allSymbols:", allSymbols)
	return allSymbolsCache;
}

export default {
	onReady: (callback) => {
		//console.log('[onReady]: Method call');
		setTimeout(() => callback(configurationData));
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	) => {
		//console.log('[searchSymbols]: Method call');
		const symbols = await getAllSymbols();
		const newSymbols = symbols.filter(symbol => {
			const isExchangeValid = exchange === '' || symbol.exchange === exchange;
			const isFullSymbolContainsInput = symbol.full_name
				.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;
			return isExchangeValid && isFullSymbolContainsInput;
		});
		onResultReadyCallback(newSymbols);
	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
	) => {
		//console.log('[resolveSymbol]: Method call', symbolName);
		const symbols = await getAllSymbols();
		const symbolItem = symbols.find(({
			full_name,
		}) => full_name === symbolName);
		if (!symbolItem) {
			//console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
			onResolveErrorCallback('cannot resolve symbol');
			return;
		}
		const symbolInfo = {
			ticker: symbolItem.full_name,
			name: symbolItem.symbol,
			description: symbolItem.description,
			type: symbolItem.type,
			session: '24x7',
			timezone: 'Etc/UTC',
			//timezone: "Asia/Seoul",	
			exchange: symbolItem.exchange,
			minmov: 1,
			pricescale: Math.pow(10, symbolItem.NDOTPOS),
			has_intraday: true,
			visible_plots_set: 'ohlcv',
			has_weekly_and_monthly: true,
			supported_resolutions: configurationData.supported_resolutions,
			has_empty_bars:false,
			volume_precision: 0,
			data_status: 'streaming',
			code:symbolItem.CODE
		};

		//console.log('[resolveSymbol]: Symbol resolved', symbolName, "symbolInfo:", symbolInfo);
		onSymbolResolvedCallback(symbolInfo);
	},

	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest, countBack } = periodParams;
		//console.log('[getBars]: Method call', symbolInfo, resolution, from, to , firstDataRequest,"socketId:",socketId);
		//const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
		//console.log('[getBars]: parsedSymbol', parsedSymbol);

        var count = countBack + 1;
		if(count > 200) count = 200;
        to
		
		try {
			var res  = null;
			res  = await  getHistoryKey("G" ,socketId, symbolInfo.code, count, from, resolution);         
			if ( res.data.resultMap.returnVal != 1  || !res.data.resultMap.nodeArray || res.data.resultMap.nodeArray.length === 0) {
				// "noData" should be set if there is no data in the requested period.				
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}

            
            var arrData = res.data.resultMap.nodeArray;

			var addPriceCandle = async (nextTime, count) =>{				
				if(count > 200) count = 200;
				var moreData = await  getHistoryKey("G" ,socketId, symbolInfo.code, count, nextTime, resolution);
				var arrData2 = [];		
				if(!moreData.data.resultMap.nodeArray) 
				{
					arrData2 = [];
				}
				else 
				{
					arrData2 = moreData.data.resultMap.nodeArray;
					arrData.push(...arrData2);
				}			
				//console.log("arrData:^^^^^^^0000", countBack, arrData.length)
				if(countBack > arrData.length){
					let moreCnt = countBack - arrData.length; 
					if(arrData2.length == count )	
					{
						await addPriceCandle(parseInt(arrData[arrData.length -1].open_time), moreCnt );
					}
					
				}
					
			}

			
			if(countBack > arrData.length)
			{
				let moreCnt = countBack - arrData.length; 
				if(arrData.length == count )
				{
					await addPriceCandle(parseInt(arrData[arrData.length -1].open_time + 10), moreCnt )
				}
				
			}

           // console.log("arrData:^^^^^^^111111", countBack, arrData.length)

			//arrData.reverse();
			
			let bars = [];
			arrData.forEach(bar => {				
				let time_stamp =  parseInt(bar.open_time);

				//let time_stamp =  bar.timestamp/1000
               //  console.log("www000", moment(time_stamp, "X").format("YYYY-MM-DD  HH:mm:ss"), bar )
				//if (time_stamp >= from && time_stamp < to) {
					bars = [...bars, {
						time: time_stamp*1000,
						low: Number(bar.low),
						high: Number(bar.high),
						open: Number(bar.open),
						close: Number(bar.close),
						volume: Number(bar.volume),
					}];
				//}
			});
			if (firstDataRequest) {
				//moment
                //console.log('www!!!!!!!!!!!!!!!!!! bar.time arrData',arrData[arrData.length - 1])
				//console.log('www!!!!!!!!!!!!!!!!!! bar.time 1111111111to',to , moment(to*1000).format('LLLL'))
                //console.log("wwwfirstDataRequest : ",symbolInfo.full_name,  {	...bars[bars.length - 1]})
				lastBarsCache.set(symbolInfo.full_name, {
					...bars[bars.length - 1],
				});
               // console.log("wwwwwwwwwwwwwwwwww333", moment(bars[bars.length - 1].time, "x").format("YYYY-MM-DD  HH:mm:ss") )

			}
			//console.log(`[getBars]: returned ${bars.length} bar(s)`);
			
			//console.log("arrData:~~~coin~~~~~~~",symbolInfo.description, firstDataRequest , countBack,  "from:",  moment(from, "X").format("YYYY-MM-DD  HH:mm:ss"),                 "to:", moment(to, "X").format("YYYY-MM-DD  HH:mm:ss"))
			//console.log("arrData:~~~coin222~~~~",symbolInfo.description, firstDataRequest,  bars.length,         "   from:" , moment(bars[0].time, "x").format("YYYY-MM-DD  HH:mm:ss") , "to:", moment(bars[bars.length - 1].time, "x").format("YYYY-MM-DD HH:mm:ss") )


	
			
			onHistoryCallback(bars, {
				noData: false,
				//nextTime: Number(bars[0].time)/1000,
			});
		} catch (error) {
			//console.log('[getBars]: Get error', error);
			onErrorCallback(error);
		}
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback,
	) => {
		//console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscribeUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
	},

	unsubscribeBars: (subscriberUID) => {
		//console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
	},
};
