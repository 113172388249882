//import { reactive } from 'vue';

export default {


    namespaced: true,
    state: {       
        currentPageId: "navi_0",
        //normal or
        currentLeagueType: "normal",

    },
    mutations: {
        CHANGE_CURRENT_PAGE_ID(state, payload) {            
            state.currentPageId = payload;
        },    
        CHANGE_CURRENT_LEAGUETYPE(state, payload) {            
            state.currentLeagueType = payload;
        },    
    },
    actions: {
        // triggerToast({ commit }, message, type = 'success') {            
        //     commit('ADD_TOAST', {
        //         id: Date.now(),
        //         message: message,
        //         type: type,
        //     });


        //     setTimeout(() => {
        //         commit('REMOVE_TOAST')                  
        //     }, 10000)
        // }
    },
    //computed 와 동일한기능..
    getters: {
        // toastMessageWithSmile(state) {
        //     return state.toasts.message + "^^~~~";
        // }
    },
}