<template>
                    <div class="mypage-main-inner" v-if="myCommentList.length">
                      <ul class="inner-list">
                        <li
                          v-for="(item, index) in myCommentList"
                          :key = index
                        >
                          <div class="item-box">
                            <p class="title-box">

                              <!-- 타입별 텍스트 컬러 변경 -->
                              <!-- <span class="type">
                                <span class="text-black">나의 댓글</span>
                              </span> -->
                              
                              <span class="title">
                                <router-link :to="{ name:'CommunityView',  params:{ id:item.BOARD_IDX }}">
                                  <span v-html="item.COMMENT.replace(/(?:\r\n|\r|\n)/g, '<br>')"></span>
                                </router-link>
                              </span>
                            </p>
                            <!-- <span class="category">{{ item.CATEGORY_NAME }}</span> -->
                            <span class="date">{{ item.CREATIONDATE }}<span class="pipe">|</span>좋아요 {{ item.LIKE_COUNT }}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div v-else class="mypage-main-null">댓글이 없습니다.</div>
                      
                    <!-- 페이징 start-->
                    <div class="section paging-area pb-1 mb-0" v-if="myCommentList.length">
                      <v-pagination                  
                        v-model="currentPage"
                        :pages= numberOfPages
                        :rangeSize="1"
                        activeColor="#ff5d8b"                   
                        @update:modelValue="updateHandler(currentPage)"
                      />
                    </div>
                    <!-- //페이징 end -->  

</template>

<script>
import { ref, computed, watch } from 'vue';
import {useApicall} from '@/composables/commonFn';
import VPagination from "@hennge/vue3-pagination";
import '@/assets/css/vue3-pagination.css';

export default {
  props: {
    itemType: {
      type:String,
      required: true,
    },
  },  
  components: {
    VPagination,
  },

  setup(props) {
    const { apiCall_gt } = useApicall();

    const limit = ref(10);
    const offset = ref(0);
    const currentPage = ref(1);     
    const numberOfData = ref(0);
    const numberOfPages = computed(()=>{
      return Math.ceil(numberOfData.value/limit.value)
    });
    
    const myCommentList = ref([]);
    const propsItemType = computed(()=>props.itemType)

    const updateHandler = (e) => {
      getMyCommentList(e);
    }

    const getMyCommentList = async(page = currentPage.value) => {
      currentPage.value = page;
      var offset = (currentPage.value -1) * limit.value;
      var getUrl = `board/mycommentlist?limit=${limit.value}&offset=${offset}`

      var replyDataList = await apiCall_gt(getUrl);

      try {
        if(replyDataList.dataList == null) replyDataList.dataList = [];
        myCommentList.value = replyDataList.dataList;
        numberOfData.value = replyDataList.dataCount;
      } catch(error) {
        myCommentList.value = [];
        numberOfData.value  = 0;
      }
    }

    watch(propsItemType, ()=> {      
      if(propsItemType.value == 'reply') {
        updateHandler(1);
      } else {
        return false;
      }
    })

    return{
      limit,
      offset,
      myCommentList,
      numberOfPages,
      currentPage,
      updateHandler,
    }
  }
}
</script>

<style>

</style>