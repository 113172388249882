<template>
<div class="trade-result-area" id="tradeResultArea">

                    <div class="trade-result-tab">
                      <!-- tab button -->
                      <ul class="nav nav-tabs" id="tradeResultTabButton" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="trade-result-hold-Tab" data-bs-toggle="tab" data-bs-target="#tradeResultHoldTab" type="button" role="tab" aria-controls="tradeOrderSellTab" aria-selected="true">
                            {{currentTradeType=='C'?'보유코인':'보유종목'}}
                            <span>({{currentAssetList.length}})</span>
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="trade-result-outstanding-tab" data-bs-toggle="tab" data-bs-target="#tradeResultOutstandingTab" type="button" role="tab" aria-controls="tradeOrderBuyTab" aria-selected="false">
                            미체결
                            <span>({{currentOrderList.length}})</span>
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button   @click="onDailyTradeList"  class="nav-link" id="trade-result-today-tab" data-bs-toggle="tab" data-bs-target="#tradeResultTodayTab" type="button" role="tab" aria-controls="tradeOrderBuyTab" aria-selected="false">
                            당일매매내역
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button @click="onDailyProfitList" class="nav-link" id="trade-result-day-tab" data-bs-toggle="tab" data-bs-target="#tradeResultDayTab" type="button" role="tab" aria-controls="tradeOrderBuyTab" aria-selected="false">
                            일별매매손익
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button @click="onOrderHistory" class="nav-link" id="trade-result-history-tab" data-bs-toggle="tab" data-bs-target="#tradeResultHistoryTab" type="button" role="tab" aria-controls="tradeResultHistoryTab" aria-selected="false">
                            주문내역
                          </button>
                        </li>
                      </ul>
        
                      <!-- tab contents -->
                      <div class="tab-content" id="tradeResultTabContents">
        
                        <!-- 보유 코인/종목/선물 start -->
                        <div class="tab-pane fade show active" id="tradeResultHoldTab" role="tabpanel" aria-labelledby="trade-result-hold-tab">
                          <div class="trade-bottom-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                          <!-- 보유 코인/종목/선물 상단 시작  -->
                          <div class="trade-result-summary">
                            <table class="table">
                              <colgroup>
                                <col style="width:5%">
                                <col style="width:10%">
                                <col style="width:13%">
                                <col style="width:7%">
                                <col style="width:13%">
                                <col style="width:7%">
                                <col style="width:7%">
                                <col style="width:10%">
                                <col style="width:15%">
                                <col style="width:8%">
                                <col style="width:5%">
                              </colgroup>
                              <tr>
                                <th>
                                  <input class="form-check-input" type="checkbox" id="chk11" @click="onCheckAssetSelectAll">
                                </th>
                                <th>{{currentTradeType=='C'?'코인명':'종목명'}} </th>
                                <th>평가손익</th>
                                <th>수익률</th>
                                <th>매입가</th>
                                <th>보유수량</th>
                                <th>구분</th>
                                <th>현재가</th>                           
                                <th>매입금액</th>
                                <th>매도</th>
                                <th>공유하기</th>
                              </tr>
                            </table>
                          </div>
                          <!-- //보유 코인/종목/선물 상단 종료 -->

                          <!-- 보유 코인/종목/선물 내용 start -->
                          <!-- <div class="trade-result-tbl" id="tradeResultHold"> -->
                           

                            <perfect-scrollbar 
                                @ps-scroll-y="onScroll" 
                                :options="options"
                                :watch-options="watcher"
                                ref="scrollbar"
                                class="trade-result-tbl" id="tradeResultHold"
                            >     
                            <table class="table">
                              <colgroup>
                                <col style="width:5%">
                                <col style="width:10%">
                                <col style="width:13%">
                                <col style="width:7%">
                                <col style="width:13%">
                                <col style="width:7%">
                                <col style="width:7%">
                                <col style="width:10%">
                                <col style="width:15%">
                                <col style="width:8%">
                                <col style="width:5%">
                              </colgroup>
                              <tbody>
                                <tr
                                   v-for="AssetItem  in currentAssetList"
                                    :key = AssetItem.ID 
                                >
                                  <td>
                                    <input class="form-check-input" type="checkbox"  v-model="AssetItem.checked">
                                  </td>
                                  <td style="cursor: pointer" class="text-start"  @click="onClickedItemName(AssetItem)" >{{AssetItem.NAME}}</td>
                                  <td :class="AssetItem.COLOR_CLASS">
                                    <span class="hold-profit-loss">{{AssetItem.PROFIT?AssetItem.PROFIT:0}}</span>
                                  </td>
                                  <td :class="AssetItem.COLOR_CLASS">
                                    <span class="hold-per">
                                      <!-- <i></i>{{periodInitDataKeep[AssetItem.CODE]?Math.abs(((periodInitDataKeep[AssetItem.CODE].trade_price - AssetItem.PRICE)/AssetItem.PRICE)*100).toFixed(2) + '%':'0'}} -->
                                      <i></i>{{AssetItem.profitPre?Math.abs(AssetItem.profitPre).toFixed(2) + '%':0}}

                                    </span>
                                  </td>
                                  <td>{{Constant.UTIL.numberWithCommas(Number(AssetItem.PRICE))}}</td>
                                  <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.COUNT, 4))}}</td>
                                  <td>{{getTradeType(AssetItem.TRADETYPE)}}</td>
                                  <!-- <td :class="periodInitDataKeep[AssetItem.CODE]?Constant.UTIL.getUpdownColor(periodInitDataKeep[AssetItem.drate].drate):''"> -->
                                  <td :class="Constant.UTIL.getUpdownColor(AssetItem.drate)">
                                    <!-- <span class="hold-price">{{periodInitDataKeep[AssetItem.CODE]?Constant.UTIL.numberWithCommas(periodInitDataKeep[AssetItem.CODE].trade_price):'0'}}</span> -->
                                    <span class="hold-price">{{AssetItem.currentPrice?Constant.UTIL.numberWithCommas(AssetItem.currentPrice):0}}</span>
                                  </td>
                                  <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.COUNT * AssetItem.PRICE, 2))}}</td>
                                  <td><button type="button" class="btn btn-dark btn-sell"  @click="onClickSell_m(AssetItem)">시장가 정리</button></td>
                                  <td class="bth-share"><button type="button" class="btn" @click="cpModal(AssetItem)"><img src="@/assets/images/trade/ico_share.png" alt="공유하기"></button></td>
                                </tr>                                
                              </tbody>
                            </table>                                
                          <!-- </div> -->
                           </perfect-scrollbar>
                          <!-- //보유 코인/종목/선물 내용 end -->

                          <!-- 보유 코인/종목/선물 하단 시작  -->
                          <div class="trade-result-summary">
                            <table class="table bottom">
                              <colgroup>
                                <col style="width:5%">
                                <col style="width:10%">
                                <col style="width:13%">
                                <col style="width:7%">
                                <col style="width:13%">
                                <col style="width:7%">
                                <col style="width:7%">
                                <col style="width:10%">
                                <col style="width:15%">
                                <col style="width:8%">
                                <col style="width:5%">
                              </colgroup>
                              <tr>
                                <td colspan="11">
                                  <button type="button" class="btn btn-sm btn-outline-primary"  @click="onAssetSellSelected" >일괄매도</button>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <!-- //보유 코인/종목/선물 하단 종료 -->

                        </div>
                        <!-- //보유 코인/종목/선물  end -->
        
                        <!-- 미체결 start -->
                        <div class="tab-pane fade" id="tradeResultOutstandingTab" role="tabpanel" aria-labelledby="trade-result-outstanding-tab">
                          <div class="trade-bottom-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                          <!-- 미체결 상단 시작  -->
                          <div class="trade-result-summary" id="tradeResultOutstanding">
                            <table class="table">
                              <colgroup>
                                <col style="width:5%">
                                <col style="width:10%">
                                <col style="width:5%">
                                <col style="width:8%">
                                <col style="width:8%">
                                <col style="width:12%">
                                <!-- <col style="width:12%"> -->
                                <col style="width:12%">
                                <!-- <col style="width:8%">
                                <col style="width:10%"> -->
                                <col style="width:10%">
                              </colgroup>
                              <tr>
                                <th>
                                  <input class="form-check-input" type="checkbox" id="chk21"  @click="onCheckOrderSelectAll">
                                </th>
                                <th>{{currentTradeType=='C'?'코인명':'종목명'}}</th>
                                <th>주문</th>
                                <th>주문수량</th>
                                <th>미체결수량</th>
                                <th>주문가</th>
                                <!-- <th>현재가</th> -->
                                <th>매입금액</th>
                                <!-- <th>비중</th>
                                <th>주문가 정정</th> -->
                                <th>주문취소</th>
                              </tr>
                            </table>
                          </div>
                          <!-- //미체결 상단 종료 -->

                          <!-- 미체결 내용 start -->
                          <!-- <div class="trade-result-tbl"> -->
                          <perfect-scrollbar 
                              @ps-scroll-y="onScroll" 
                              :options="options"
                              :watch-options="watcher"
                              ref="scrollbar"
                              class="trade-result-tbl"
                          >     

                            <table class="table">
                              <colgroup>
                                <col style="width:5%">
                                <col style="width:10%">
                                <col style="width:5%">
                                <col style="width:8%">
                                <col style="width:8%">
                                <col style="width:12%">
                                <!-- <col style="width:12%"> -->
                                <col style="width:12%">
                                <!-- <col style="width:8%">
                                <col style="width:10%"> -->
                                <col style="width:10%">
                              </colgroup>
                              <tbody>
                                
                                <tr                                
                                    v-for="orderItem  in currentOrderList"
                                    :key = orderItem.ID
                                >
                                  <td class="text-center">
                                    <input class="form-check-input" type="checkbox" v-model="orderItem.checked"  >
                                  </td>
                                  <td style="cursor: pointer" @click="onClickedItemName(orderItem)"  class="text-start">{{orderItem.NAME}}</td>
                                  <td class="text-center">
                                    <span class="outstanding-order-type">{{getTradeType(orderItem.TRADETYPE)}}</span>
                                  </td>
                                  <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(orderItem.ORDERCOUNT, 4))}}</td>
                                  <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(orderItem.REMAINCOUNT, 4))}}</td>
                                  <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(orderItem.ORDERINDEX, 4))}}</td>
                                  <!-- <td>{{periodInitDataKeep[orderItem.CODE]?Constant.UTIL.numberWithCommas(periodInitDataKeep[orderItem.CODE].trade_price):'0'}}</td> -->
                                  <!-- <td>{{orderItem.currentPrice?Constant.UTIL.numberWithCommas(orderItem.currentPrice):0}}</td> -->





                                  <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(orderItem.ORDERINDEX * orderItem.ORDERCOUNT, 2)) }}</td>
                                  <!-- <td>-%</td>
                                  <td class="text-center">
                                    <button type="button" class="btn btn-sm btn-order-price">주문가</button>
                                    <button type="button" class="btn btn-sm btn-outline-dark">정정</button>
                                  </td> -->
                                  <td class="text-center"><button type="button" class="btn btn-sm btn-outline-danger"  @click="onOrderCancleModal(orderItem)">주문취소</button></td>
                                </tr>
                              </tbody>
                            </table>
                          <!-- </div> -->
                          </perfect-scrollbar> 
                          <!-- //미체결 내용 end -->

                          <!-- 미체결하단 시작  -->
                          <div class="trade-result-summary">
                            <table class="table bottom">
                              <colgroup>
                                <col style="width:5%">
                                <col style="width:10%">
                                <col style="width:5%">
                                <col style="width:8%">
                                <col style="width:8%">
                                <col style="width:12%">
                                <col style="width:12%">
                                <col style="width:12%">
                                <!-- <col style="width:8%">
                                <col style="width:10%"> -->
                                <col style="width:10%">
                              </colgroup>
                              <tr>
                                <td colspan="9">
                                  <button type="button" class="btn btn-sm btn-outline-dark"  @click="onOrderCencelSelected" >일괄취소</button>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <!-- //미체결 하단 종료 -->

                        </div>
                        <!-- //미체결 end -->
        
                        <!-- 당일매매내역 start -->
                        <div class="tab-pane fade" id="tradeResultTodayTab" role="tabpanel" aria-labelledby="trade-result-today-tab">
                          <div class="trade-bottom-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                          <!-- 당일매매내역 상단 시작 -->
                          <div class="trade-result-summary">
                    
                            <table class="table">
                              <colgroup>
                                <col style="width:15%">
                                <col style="width:20%">
                                <col style="width:15%">
                                <col style="width:15%">
                                <col style="width:20%">
                                <col style="width:15%">
                              </colgroup>
                              <tr>
                                <th>주문시각</th>
                                <th>{{currentTradeType=='C'?'코인명':'종목명'}}</th>
                                <th>주문</th>
                                <th>수량</th>
                                <th>매매손익</th>
                                <th>수익률</th>
                              </tr>
                            </table>
                          </div>
                          
                          <!-- //당일매매내역 상단 종료 -->

                          <!-- 당일매매내역 내용 start -->
                          <!-- <div class="trade-result-tbl" id="tradeResultToday"> -->
                          <perfect-scrollbar 
                              @ps-scroll-y="onScroll" 
                              :options="options"
                              :watch-options="watcher"
                              ref="scrollbar"
                              class="trade-result-tbl"
                              id="tradeResultToday"
                          >    
                            <table class="table">
                              <colgroup>
                                <col style="width:15%">
                                <col style="width:20%">
                                <col style="width:15%">
                                <col style="width:15%">
                                <col style="width:20%">
                                <col style="width:15%">
                              </colgroup>
                              <tbody>
                                <tr
                                  v-for="(item, index ) in dailyTradeData"
                                  :key = index   
                                >
                                  <td class="text-center">{{item.CREATIONDATE.split(' ')[1]}}</td>
                                  <td class="text-start">{{item.NAME}}</td>
                                  <td class="text-center">
                                    <span class="today-order-type">{{getTradeType(item.TRADETYPE)}}</span>
                                  </td>
                                  <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(item.ORDERCOUNT, 4))}}</td>
                                  <td :class="Constant.UTIL.getUpdownColor(item.TPOL)">
                                    <span class="today-price">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(item.TPOL,2))}}</span>
                                  </td>
                                  <td class="text-center" :class="Constant.UTIL.getUpdownColor(item.TPOL)">
                                    <span class="today-per">
                                      <i></i>{{Math.abs((item.TPOL /  item.TOTALPRICE) *100).toFixed(2) + "%"}}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          <!-- </div> -->
                          </perfect-scrollbar>
                          <!-- //당일매매내역 내용 end -->

                          <!-- 당일매매내역 하단 시작  -->
                          <div class="trade-result-summary">
                            <table class="table bottom">
                              <colgroup>
                                <col style="width:15%">
                                <col style="width:20%">
                                <col style="width:15%">
                                <col style="width:15%">
                                <col style="width:20%">
                                <col style="width:15%">
                              </colgroup>
                              <tr>
                                <td colspan="11" style="height:35px">
                                </td>
                              </tr>
                            </table>
                          </div>
                          <!-- //당일매매내역 하단 종료 -->

                        </div>
                        <!-- //당일매매내역 end -->
        
                        <!-- 일별매매손익 start -->
                        <div class="tab-pane fade" id="tradeResultDayTab" role="tabpanel" aria-labelledby="trade-result-day-tab">
                          <div class="trade-bottom-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                          <!-- 일별매매손익 상단 시작 -->
                          <div class="trade-result-summary">
                          
                            <table class="table">
                              <colgroup>
                                <col style="width:25%">
                                <col style="width:25%">
                                <col style="width:25%">
                                <col style="width:25%">
                              </colgroup>
                              <tr>
                                <th>주문일자</th>
                                <th>매매손익</th>
                                <th>수익률</th>
                                <th>누적손익</th>
                              </tr>
                            </table>
                          </div>
                          <!-- //일별매매손익 상단 종료 -->

                          <!-- 일별매매손익 내용 시작 -->
                          <!-- <div class="trade-result-tbl" id="tradeResultDay"> -->
                          <perfect-scrollbar 
                              @ps-scroll-y="onScroll" 
                              :options="options"
                              :watch-options="watcher"
                              ref="scrollbar"
                              class="trade-result-tbl"
                              id="tradeResultDay"
                          >  
                            <table class="table">
                              <colgroup>
                                <col style="width:25%">
                                <col style="width:25%">
                                <col style="width:25%">
                                <col style="width:25%">
                              </colgroup>
                              <tbody>
                                <tr
                                  v-for="(item, index ) in dailyProfitData"
                                  :key = index   
                                  v-show="Number(item.SELL_TRADEPRICE)!=0"
                                >
                                  <td class="text-center">{{getChangeDateStr(item.YYYYMMDD)}}</td>
                                  <td :class="Constant.UTIL.getUpdownColor(Number(item.SELL_TPOL))">
                                    <span class="day-profit-loss">{{Constant.UTIL.numberWithCommas(Number(item.SELL_TPOL))}}</span>
                                  </td>
                                  <td class="text-center" :class="Constant.UTIL.getUpdownColor(Number(item.SELL_TPOL))">
                                    <span class="day-per">
                                      <i></i>{{Math.abs(((Number(item.SELL_TPOL))/Number(item.SELL_TRADEPRICE))*100).toFixed(2) + "%"}}
                                    </span>
                                  </td>
                                  <td :class="Constant.UTIL.getUpdownColor(item._sumProfit)">
                                    <span class="day-trade-amount">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(item._sumProfit,2))}}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          <!-- </div> -->
                          </perfect-scrollbar>
                          <!-- //일별매매손익 내용 end -->

                          <!-- 일별매매손익 하단 시작  -->
                          <div class="trade-result-summary">
                            <table class="table bottom">
                              <colgroup>
                                <col style="width:25%">
                                <col style="width:25%">
                                <col style="width:25%">
                                <col style="width:25%">
                              </colgroup>
                              <tr>
                                <td colspan="11" style="height:35px">
                                </td>
                              </tr>
                            </table>
                          </div>
                          <!-- //일별매매손익 하단 종료 -->

                        </div>
                        <!-- //일별매매손익 end -->

                        <!-- 주문내역 start -->
                        <div class="tab-pane fade" id="tradeResultHistoryTab" role="tabpanel" aria-labelledby="trade-result-history-tab">
                          <div class="trade-bottom-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                          <!-- 주문내역 상단 시작 -->
                          <div class="trade-result-summary">
                          
                            <table class="table">
                              <colgroup>
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:5%">
                              </colgroup>
                              <tr>
                                <th>날짜</th>
                                <th>구분</th>
                                <th>종목</th>
                                <th>수량</th>
                                <th>가격</th>
                                <th>수수료</th>
                                <th>상태</th>
                                <th>실현손익</th>
                                <th>공유하기</th>
                              </tr>
                            </table>
                          </div>
                          <!-- //주문내역 상단 종료 -->

                          <!-- 주문내역 내용 시작 -->
                          <perfect-scrollbar 
                              @ps-scroll-y="onScroll" 
                              :options="options"
                              :watch-options="watcher"
                              ref="scrollbar"
                              class="trade-result-tbl"
                              id="tradeResultHistory"
                          >  
                            <table class="table text-center">
                              <colgroup>
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:5%">
                              </colgroup>
                              <tbody>
                                <tr                                
                                  v-for="(item, index ) in orderHistoryData"
                                  :key = index   
                                >
                                  <td>{{getChangeDateStr_2(item.ORDERDATE)}}</td>
                                  <td :class="item.TRADETYPE=='1'||item.TRADETYPE=='3'?'up':'down'">
                                    <span class="history-txt">{{getTradeType(item.TRADETYPE)}}</span>
                                    <!-- <span class="">매수</span> -->
                                  </td>
                                  <td> {{item.NAME}}</td>
                                  <td> {{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(item.ORDERCOUNT, 2))}}</td>
                                  <td> {{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(item.STATUS=='1'?item.ORDERINDEX:item.TRADEINDEX, 4))}}</td>
                                  <td>{{item.FEES?Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(item.FEES, 2)):'-'}}</td>
                                  <td>{{getTradeStatus(item.STATUS)}}</td>
                                  <td :class="item.STATUS == '2'?getCssColor(Number(item.TPOL)):''">
                                    <span class="history-per">
                                      <i></i>{{item.STATUS == '2'?Constant.UTIL.numberWithCommas(Math.abs(Math.floor(item.TPOL))):'-'}}
                                    </span>
                                  </td>
                                  <td v-if="item.TRADETYPE=='2' || item.TRADETYPE=='4'" class="bth-share"><button type="button" class="btn" @click="cpModalSell(item)"><img src="@/assets/images/trade/ico_share.png" alt="공유하기"></button></td>
                                </tr>
                              </tbody>
                            </table>
                          <!-- </div> -->
                          </perfect-scrollbar>
                          <!-- //주문내역 내용 end -->

                          <!-- 주문내역 하단 시작  -->
                          <div class="trade-result-summary">
                            <table class="table bottom">
                              <colgroup>
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:5%">
                              </colgroup>
                              <tr>
                                <td colspan="9" style="height:35px">
                                </td>
                              </tr>
                            </table>
                          </div>
                          <!-- //주문내역 하단 종료 -->

                        </div>
                        <!-- //주문내역 end -->
        
                      </div>
                    </div>

                  </div>
    <BasicModal2b
      v-if="showModal3"
      @close="closeModal3" 
      @cancle="closeModal3" 
      @ok="onModalOk3" 
      :titleTxt="txtTitle_alert3"
      :bodyTxt="txtBody_alert3"
    />

    <BasicModal2b
      v-if="showModal2"
      @close="closeModal2" 
      @cancle="closeModal2" 
      @ok="onModalOk2" 
      :titleTxt="txtTitle_alert2"
      :bodyTxt="txtBody_alert2"
    />

    <BasicModal2b
      v-if="showModal"
      @close="closeModal" 
      @cancle="closeModal" 
      @ok="onModalOk" 
      :titleTxt="txtTitle_alert"
      :bodyTxt="txtBody_alert"
    />

    <CaptureModal
      v-if="showModal4" 
      @close="closeModal4" 
      @cancle="closeModal4" 
      @ok="okModal4" 
      :assetData="currentAssetItem"
    />    

    <CaptureModalSell
      v-if="showModal5" 
      @close="closeModal5" 
      @cancle="closeModal5" 
      @ok="okModal5" 
      :assetData="currentOrderHistoryItem"
    />    


</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import axios from '@/axios';
//import { useToast } from '@/composables/toast';
import BasicModal2b from '@/components/popups/BasicModal2b.vue';
import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted, watchEffect, getCurrentInstance, ref, watch} from 'vue';
import Constant from '@/constant.js';
import {useApicall} from '@/composables/commonFn';
import moment from 'moment';
import CaptureModal from '@/components/popups/CaptureModal.vue';
import CaptureModalSell from '@/components/popups/CaptureModalSell.vue';
import { event } from 'vue-gtag'


export default {
 components: {      
   BasicModal2b,
   PerfectScrollbar,
   CaptureModal,
   CaptureModalSell
  },
  props:{
        items:{
            type:Array,
            required: false
        }

    }, 
    emits:['onSelectItem'],
  setup(props,  {emit}){
      // const {         
      //               triggerToast
      //       } = useToast();
      const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
      const store = useStore(); 
      
     // const periodInitDataKeep = computed(()=>store.state.hts.periodInitDataKeep);
      const currentTradeType = computed(()=> store.state.hts.currentTradeType);
      const currentOrderList = computed(()=> store.state.hts.orderList);
      const currentAssetList = computed(()=> store.state.hts.assetlist);
      
      const {apiCall_gt} = useApicall()
      // setTimeout(()=>{
      //  console.log("currentOrderList:", currentOrderList.value)
      //  console.log("currentAssetList:", currentAssetList.value)
      // },3000)
     const  currentAssetItem = ref({})
     const  currentOrderHistoryItem = ref({})
      
     const isLogin = computed(() => store.state.user.isLogin);

      const txtTitle_alert = ref("일괄 매도");
      const txtBody_alert = ref("선택된 주문을 매도하시겠습니까?")


      const txtTitle_alert2 = ref("일괄 취소");
      const txtBody_alert2 = ref("선택된 주문을 취소하시겠습니까?")

      
      const txtTitle_alert3 = ref("시장가 정리");
      const txtBody_alert3 = ref("시장가로 매도를 원하시면 아래 확인 버튼을 눌러주세요.")

      const itemType = computed(()=> store.state.hts.currentTradeType);
      const currencyInfo = computed(()=> store.state.hts.currencyInfo);
      const allTrade = computed(()=> store.state.hts.allTrade);
      const showModal = ref(false);        
      const showModal2 = ref(false); 
      const showModal3 = ref(false);
      const showModal4 = ref(false);
      const showModal5 = ref(false);

      var intervalId;
      var orderCancelItem = null;
      var assetSellItem = null;
      const dailyTradeData = ref({});
      const dailyProfitData = ref({});
      const memberData = computed(()=> store.state.user.memberData);
      const applySpaceList = computed(()=> store.state.hts.applySpaceList);    
      const getIsApplySpace = (spaceId) =>{
          var  found =    applySpaceList.value.find(element => element.SPACE_ID == spaceId);          
          if(found)
          {
            return true;
          }
          else
          {
            return false;
          }
      }


   
      const orderHistoryData = ref({})
      const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;

           
      const onClickedItemName = (item) =>{

          var t_item = props.items.find(element => element.CODE ==  item.CODE); 
          
          
          if(t_item.TYPE== 'F' && t_item.SUBTYPE == 'K')     
          { 
              var date = new Date()
              if(date.getHours() >= 18)
              {
                  t_item =   props.items.find(element => element.CODE ==  item.CODE && element.isNight); 
              }
          }       
          emit('onSelectItem', t_item);   

        // console.log("onClickedItemName", t_item, item)
      }
      onUnmounted(()=>{
        //  console.log("onUnmounted~~~~~~~~~~~2222")          
          store.dispatch('hts/leaveAssetItems');
          clearInterval(intervalId);
      })


      onMounted(()=>{

          store.dispatch('hts/setApplySpace'); 
          store.commit('hts/CHANGE_ASSETLIST',[]);
          store.commit('hts/CHANGE_ORDERLIST', []);
          setTimeout(()=>{
        
            var isApply = getIsApplySpace(store.state.hts.currentSpaceId);
            if(isApply)
            {
                store.dispatch('hts/getOrderList');
                store.dispatch('hts/getAssetList')
            }
            else
            {              
               store.commit('hts/CHANGE_ASSETLIST',[]);
               store.commit('hts/CHANGE_TOTAL_POL',{});
               if(currentTradeType.value == 'F')
               {
                  store.commit('hts/CHANGE_FUTURE_POSSIBLE_COUNT', {});
               }

               store.commit('hts/CHANGE_ORDERLIST', []);
            }     

           // console.log("getIsApplySpace:", isApply)
          }, 2000)

          clearInterval(intervalId);
          intervalId = setInterval(()=>{
              var totalProfit = 0;
              var totalPrice = 0;
              for(var i=0; i<currentAssetList.value.length ; i++)
              {
                  if(currentAssetList.value[i])
                  {
                      totalProfit += Number(currentAssetList.value[i].nProfit) *Number(currentAssetList.value[i].COUNT);
                      totalPrice += (Number(currentAssetList.value[i].PRICE) + Number(currentAssetList.value[i].nProfit))  *Number(currentAssetList.value[i].COUNT);                           
                  }  
              }
              // console.log("onMounted111:", totalProfit, "totalPrice:", totalPrice )
              store.commit('hts/CHANGE_TOTAL_PRICE',totalPrice); 
              store.commit('hts/CHANGE_TOTAL_PROFIT',totalProfit); 
          },1000)                
      })

      const getTradeType = (type) =>{
          switch(type)
          {
              case '1':
                  return "매수"                        
              case '2':
                  return "매도"                       
              case '3':
                  return "매수"                        
              case '4':
                  return "매도"
          }
      }

  
      watchEffect(()=>{
          if(itemType.value == 'F' &&  props.items)
          {

              for(let i=0; i<currentOrderList.value.length ;i++)
              {
                  let orderData = currentOrderList.value[i];
                  let t_item =  props.items.find(element => element.CODE ==  orderData.CODE);
                  if(t_item && t_item.NDOTPOS) orderData._NDOTPOS = t_item.NDOTPOS
                  
              }


              for(let i=0; i<currentAssetList.value.length ;i++)
              {
                  let assetData = currentAssetList.value[i];
                  let t_item =  props.items.find(element => element.CODE ==  assetData.CODE);
                  if(t_item && t_item.NDOTPOS) assetData._NDOTPOS = t_item.NDOTPOS
                  
              }
          }
      })


      watch(allTrade,()=>{              
          var  tradeItem = allTrade.value;   
         // console.log("tradeItem:", tradeItem)
          const found =  currentAssetList.value.find(element => element.CODE ==  tradeItem.code);           
          if(!found)return;        
            var profit; 
            if(tradeItem.itemType == 'F')
            {
                if( props.items)
                {
                    var t_item =  props.items.find(element => element.CODE ==  found.CODE); 
                }
                
                if(t_item)
                {
                    if(t_item.MINCHANGEAMOUNT)
                    {
                        if(t_item.PRODUCTCODE == 'HSI')
                        {
                            profit = (Number(tradeItem.trade_price) - Number(found.PRICE)) / Number(t_item.HOGAUNITPRICE) * Number(t_item.MINCHANGEAMOUNT) * Number(currencyInfo.value.HKD_KRW) * Number(found.COUNT);
                            found.nProfit = (Number(tradeItem.trade_price) - Number(found.PRICE)) / Number(t_item.HOGAUNITPRICE) * Number(t_item.MINCHANGEAMOUNT) * Number(currencyInfo.value.HKD_KRW);                        
                        }
                        else
                        {
                            profit = (Number(tradeItem.trade_price) - Number(found.PRICE)) / Number(t_item.HOGAUNITPRICE) * Number(t_item.MINCHANGEAMOUNT) * Number(currencyInfo.value.USD_KRW) * Number(found.COUNT);
                            found.nProfit = (Number(tradeItem.trade_price) - Number(found.PRICE)) / Number(t_item.HOGAUNITPRICE) * Number(t_item.MINCHANGEAMOUNT) * Number(currencyInfo.value.USD_KRW);                                                     
                        }
                    }
                    else
                    {
                        if(t_item.SUBTYPE == 'K')
                        { 
                            profit = ((Number(tradeItem.trade_price) - Number(found.PRICE)) *Number(found.COUNT))*250000; 
                            found.nProfit = ((Number(tradeItem.trade_price) - Number(found.PRICE)))*250000;    
                        }
                    } 
                    found.currentPrice = tradeItem.trade_price;
                    found.drate = Number(tradeItem.drate);
                }
                

                if(found.TRADETYPE == '4')
                {
                    found.nProfit *= -1;
                    profit *= -1;
                    //found.profitPre *= -1;
                }                 
                profit = Math.floor(profit)   
            }
            else
            {
              profit = (Number(tradeItem.trade_price) - Number(found.PRICE)) *Number(found.COUNT);  
              found.nProfit = (Number(tradeItem.trade_price) - Number(found.PRICE)); 
              let profitPre = (found.nProfit /  Number(found.PRICE)) *100;
              found.profitPre = profitPre;
              found.currentPrice = tradeItem.trade_price;
              if(tradeItem.itemType == 'C')
              {
                  found.drate = Number(tradeItem.trade_price) -  Number(tradeItem.prev_closing_price);
              }
              else
              {
                found.drate = Number(tradeItem.drate);
              }          
              profit = Math.floor(profit);  
            }
        
          if(profit > 0)
          {
              found.COLOR_CLASS = "up"
              found.PROFIT = '+' + Constant.UTIL.numberWithCommas(profit);
              
          }
          else if(profit < 0)
          {
            found.COLOR_CLASS = "down"
            found.PROFIT =  Constant.UTIL.numberWithCommas(profit);
          }
          else
          {
            found.COLOR_CLASS = ""
            found.PROFIT = '0'
          } 

      })



   
    const onDailyProfitList = async()=>{
       // var dateStr = moment(new Date()).format('YYYY-MM-DD');        
        var res = await apiCall_gt(`vspace/getprofitlistbyday?type=${currentTradeType.value}&spaceId=${store.state.hts.currentSpaceId}`);
        dailyProfitData.value = res.dataList;
        
        var prevSumPol = null;
        for(let i= dailyProfitData.value.length -1 ; i>=0; i-- )
        {
          var _data = dailyProfitData.value[i];
          if(prevSumPol)
          {
            if(itemType.value == 'F' )
            {
                _data._sumProfit = prevSumPol +  Number(_data.KF_TPOL) + Number(_data.FF_TPOL);
            }
            else
            {
                 _data._sumProfit = prevSumPol + Number(_data.SELL_TPOL);
            }
            
          }
          else
          {
            if(itemType.value == 'F' )
            {
                 _data._sumProfit = Number(_data.KF_TPOL) + Number(_data.FF_TPOL);
            }
            else
            {
               _data._sumProfit = Number(_data.SELL_TPOL);
            }             
          }         
          prevSumPol = _data._sumProfit;
        }
      }

      const onDailyTradeList = async()=>{
        var dateStr = moment(new Date()).format('YYYY-MM-DD');        
        var res = await apiCall_gt(`vspace/gettradelist?type=${currentTradeType.value}&spaceId=${store.state.hts.currentSpaceId}&startCreationDay=${dateStr}&endCreationDay=${dateStr}&status=2`);
        dailyTradeData.value = res.dataList;
      //  trace("onDailyTradeList: ~", store.state.hts.currentSpaceId )
      }


     const getTradeList = async()=>{
                
        var res = await apiCall_gt(`vspace/gettradelist?type=${currentTradeType.value}&spaceId=${store.state.hts.currentSpaceId}&status=2`);
        orderHistoryData.value = res.dataList;
      //  trace("onDailyTradeList: ~", store.state.hts.currentSpaceId )
      }


      const onCheckAssetSelectAll =(e)=>{
         
           if(e.target.checked == true)
           {           
                currentAssetList.value.forEach(element =>
                {
                   trace("element", element)
                    element.checked =  true;                    
                });
           }
           else
           {
                currentAssetList.value.forEach(element =>
                {
                    element.checked =  false;                    
            });
        }           
      }
      
     const onCheckOrderSelectAll =(e)=>{
        
           if(e.target.checked == true)
           {           
                currentOrderList.value.forEach(element =>
                {                  
                    element.checked =  true;                    
                });
           }
           else
           {
                currentOrderList.value.forEach(element =>
                {
                    element.checked =  false;                    
                });
           }           
      }

     const onAssetSellSelected = ()=>{      
        var isChecked = false       
        if(currentAssetList.value  ||  currentAssetList.value.length > 0)
        {
          currentAssetList.value.forEach(async element =>
          {       
              if(element.checked)
              {
                isChecked = true;
              }
          })
        }

        if(isChecked)
        {
            showModal.value = true;
        }
        else
        {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"선택된 주문이 없습니다."});
        }
     }

     const onOrderCencelSelected = ()=>{
        

          

        var isChecked = false       
        if(currentOrderList.value  ||  currentOrderList.value.length > 0)
        {
          currentOrderList.value.forEach(async element =>
          {       
              if(element.checked)
              {
                isChecked = true;
              }
          })
        }

        if(isChecked)
        {
             showModal2.value = true;
        }
        else
        {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"선택된 주문이 없습니다."});
        }





     }

      const getTradingTypeStr = (type)=>{
        var result = null;
        if(type == 'S')
        {
          result = 'stock_tranding'
        }
        else if(type == 'F')
        {
          result = 'futures_tranding'
        }
        else if(type == 'C')
        {
          result = 'crypto_tranding'
        }
        else if(type == 'G')
        {
          result = 'coinFutures_tranding'
        }
        return result;
      }


     const closeModal =()=>{
        showModal.value = false;
     }

    const closeModal2 =()=>{
        showModal2.value = false;
        orderCancelItem = null;
     }

  
    const onModalOk = ()=>{
        showModal.value = false;
        var token = sessionStorage.getItem('token');   

        

        currentAssetList.value.forEach(async element =>
        {       
            if(element.checked)
            {

                  var t_tradeType; 
                  if(element.TRADETYPE == "1") t_tradeType = "2";
                  else if(element.TRADETYPE == "2") t_tradeType = "1";  
                  else if(element.TRADETYPE == "3") t_tradeType = "4";  
                  else if(element.TRADETYPE == "4") t_tradeType = "3"; 
                  trace("element:", element) 
                   
                  var params = {
                        spaceId: store.state.hts.currentSpaceId,
                        type:  currentTradeType.value,
                        orderType:'M',
                        tradeType : t_tradeType,
                        code:element.CODE,                        
                        count:element.COUNT,   
                        isCleared:"Y"                            
                } 
                    
                try{
                    const res = await axios.post('vspace/orderregister', params ,{
                        headers: {
                                'token': token,                    
                        }
                    });   
                    let json =  res.data;                     
                    if (json.returnCode == 1) {
                    
                        if (json.resultMap.returnVal == 1) {  
                           // triggerToast('주문이 완료되었습니다.');                           
                          store.dispatch('hts/getAssetList');                          
                          var gtag_type = null;
                          if(json.resultMap.orderRow.TRADETYPE == "1" || json.resultMap.orderRow.TRADETYPE == "3")
                          {
                            gtag_type = 'buy' 
                          }
                          else if(json.resultMap.orderRow.TRADETYPE == "2" || json.resultMap.orderRow.TRADETYPE == "4")
                          { 
                            gtag_type = 'sell' 
                          }
                          event( getTradingTypeStr(params.type), { 'ID': memberData.value.MID, 'transaction_type': gtag_type, 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss')});
                          window.fbq('trackCustom', getTradingTypeStr(params.type), {'ID':  memberData.value.MID,  'transaction_type': gtag_type,'time': moment(new Date()).format('YYYYMMDD HH:mm:ss') });
                          //console.log("event~ :",getTradingTypeStr(params.type), memberData.value.MID , gtag_type,  moment(new Date()).format('YYYYMMDD HH:mm:ss'))  
                        } else {
                           // triggerToast(json.message, 'danger');       
                            emitter.emit('EVENT_ALERT',{title:"알림", body:json.message});                            
                            showModal.value = false 
                            showModal2.value = false
                            showModal3.value = false
                        }
                    }
                    else if(json.returnCode == -102 )
                    {
                        alert("로그인이 필요합니다.");
                    }
                    else
                    {
                    //  triggerToast(json.message, 'danger');                     
                    }              
                }catch(error){
                   // triggerToast('Something went wrong!', 'danger');
                    console.log(error);
                } 
            }
        });
     }


   const onOrderCancleModal = (item)=>{
       showModal2.value = true;
       orderCancelItem = item;
   }

    const onOrderCancle = async ()=>{
       showModal2.value = false;
       
      var token = sessionStorage.getItem('token'); 
      var params = {                            
                          type:  currentTradeType.value,
                          orderId:orderCancelItem.ID,       
                    } 
          try{
              orderCancelItem = null;
              const res = await axios.post('vspace/ordercancel', params ,{
                  headers: {
                          'token': token,                    
                  }
              });   
              let json =  res.data;                     
              if (json.returnCode == 1) {
              
                  if (json.resultMap.returnVal == 1) {  
                     // triggerToast('주문이 취소가 완료되었습니다.'); 
                      store.dispatch('hts/getOrderList');      
                  } else {
                      //triggerToast(json.message, 'danger');                        
                      emitter.emit('EVENT_ALERT',{title:"알림", body:json.message});   
                      showModal.value = false 
                      showModal2.value = false
                      showModal3.value = false        
                  }
              }
              else if(json.returnCode == -102 )
              {
                  alert("로그인이 필요합니다.");
              }
              else
              {
               // triggerToast(json.message, 'danger');                     
              }              
          }catch(error){
              //triggerToast('Something went wrong!', 'danger');
              console.log(error);
          } 
    }

    const onScroll = (e) =>{
          e
          // trace(e.target.scrollTop); 
      }

    const onModalOk2 = ()=>{
          showModal2.value = false;

          if(orderCancelItem)
          {
              onOrderCancle()
          }
          else
          {
              var token = sessionStorage.getItem('token');   
              currentOrderList.value.forEach(async element =>
              {       
                  if(element.checked)
                  {

                      var params = {                            
                            type:  currentTradeType.value,
                            orderId:element.ID,       
                      } 
                      try{
                          const res = await axios.post('vspace/ordercancel', params ,{
                              headers: {
                                      'token': token,                    
                              }
                          });   
                          let json =  res.data;                     
                          if (json.returnCode == 1) {
                          
                              if (json.resultMap.returnVal == 1) {  
                                //  triggerToast('주문 취소가 완료되었습니다.'); 
                                store.dispatch('hts/getOrderList');      
                              } else {
                                 // triggerToast(json.message, 'danger');     
                                  emitter.emit('EVENT_ALERT',{title:"알림", body:json.message});                        
                                  showModal.value = false 
                                  showModal2.value = false
                                  showModal3.value = false        
                              }
                          }
                          else if(json.returnCode == -102 )
                          {
                              alert("로그인이 필요합니다.");
                          }
                          else
                          {
                           // triggerToast(json.message, 'danger');                     
                          }              
                      }catch(error){
                         // triggerToast('Something went wrong!', 'danger');
                          console.log(error);
                      } 
                  }
              });
          }

    }
     const closeModal3 = () =>{
         showModal3.value = false;
         assetSellItem = null;
     }
     const onModalOk3 = async () =>{
            var t_tradeType; 
            if(assetSellItem.TRADETYPE == "1") t_tradeType = "2";
            else if(assetSellItem.TRADETYPE == "2") t_tradeType = "1";  
            else if(assetSellItem.TRADETYPE == "3") t_tradeType = "4";  
            else if(assetSellItem.TRADETYPE == "4") t_tradeType = "3"; 
            
            var token = sessionStorage.getItem('token');       
            var params = {
                  spaceId: store.state.hts.currentSpaceId,
                  type:  currentTradeType.value,
                  orderType:'M',
                  tradeType : t_tradeType,
                  code:assetSellItem.CODE,                        
                  count:assetSellItem.COUNT,   
                  isCleared:"Y"                            
            } 
                
            try{
                const res = await axios.post('vspace/orderregister', params ,{
                    headers: {
                            'token': token,                    
                    }
                });   
                let json =  res.data;                     
                if (json.returnCode == 1) {
                
                    if (json.resultMap.returnVal == 1) {  
                       // triggerToast('주문이 완료되었습니다.');                           
                        store.dispatch('hts/getAssetList')                            
                        showModal3.value = false;
                        assetSellItem = null;

                        var gtag_type = null;
                        if(json.resultMap.orderRow.TRADETYPE == "1" || json.resultMap.orderRow.TRADETYPE == "3")
                        {
                          gtag_type = 'buy' 
                        }
                        else if(json.resultMap.orderRow.TRADETYPE == "2" || json.resultMap.orderRow.TRADETYPE == "4")
                        { 
                          gtag_type = 'sell' 
                        }
                        event( getTradingTypeStr(params.type), { 'ID': memberData.value.MID, 'transaction_type': gtag_type, 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss')})
                        window.fbq('trackCustom', getTradingTypeStr(params.type), {'ID':  memberData.value.MID,  'transaction_type': gtag_type,'time': moment(new Date()).format('YYYYMMDD HH:mm:ss') });
                        //console.log("event~ :",getTradingTypeStr(params.type), memberData.value.MID , gtag_type,  moment(new Date()).format('YYYYMMDD HH:mm:ss'))

                    } else {
                       // triggerToast(json.message, 'danger');    
                        emitter.emit('EVENT_ALERT',{title:"알림", body:json.message});                    
                        showModal.value = false 
                        showModal2.value = false
                        showModal3.value = false        
                    }
                }
                else if(json.returnCode == -102 )
                {
                    alert("로그인이 필요합니다.");
                }
                else
                {
                  //triggerToast(json.message, 'danger');                     
                }              
            }catch(error){
                //triggerToast('Something went wrong!', 'danger');
                console.log(error);
            } 
     }
     const onClickSell_m = (item) =>{
        showModal3.value = true;
        assetSellItem = item;
     }


    const getChangeDateStr=(str)=>{  
       return moment(str, "YYYYMMDD").format("YYYY-MM-DD");
     }

    const getChangeDateStr_2=(str)=>{  
       return moment(str, "YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss");
     }

     const onOrderHistory = () =>{
           getTradeList()
     }

     const getTradeStatus = (type) =>{
        switch(type)
        {
            case '1':
                return "신규"                        
            case '2':
                return "체결"                       
            case '8':
                return "거부"                        
            case '9':
                return "취소"
        }
      }

    const getCssColor = (num) => {
          var result = ''
          if(num > 0)
          {
              result = "up" 
          }
          else if(num < 0)
          {
              result = "down"         
          }
          else
          {
              result = ""      
          } 
          return result;
      }

    const cpModal = (item) => {
      currentAssetItem.value = item
      showModal4.value = true; 
    }

    const okModal4 = () => {
      showModal4.value = false; 
    }

    const closeModal4 = () => {
      showModal4.value = false; 
    }    

    const cpModalSell = (item) => {
      currentOrderHistoryItem.value = item;
      showModal5.value = true; 
    }

    const okModal5 = () => {
      showModal5.value = false; 
    }

    const closeModal5 = () => {
      showModal5.value = false; 
    }   


     return{
          options: {       
                wheelSpeed: 1,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true,
          },
          watcher: true,
          currentOrderList,
          currentAssetList,
          Constant,
          getTradeType,
        
          //periodInitDataKeep,
          onCheckAssetSelectAll,
          onCheckOrderSelectAll,
          onAssetSellSelected,
          closeModal,
          onModalOk,
          showModal,
          txtTitle_alert,
          txtBody_alert,
          showModal2,
          txtTitle_alert2,
          txtBody_alert2,
          closeModal2,
          onModalOk2,
          showModal3,
          txtTitle_alert3,
          txtBody_alert3,
          closeModal3,
          onModalOk3,
          onOrderCencelSelected,
          onOrderCancleModal,
          currentTradeType,
          onDailyTradeList,
          dailyTradeData,
          onScroll,
          onDailyProfitList,
          dailyProfitData,
          getChangeDateStr,
          onClickedItemName,
          onClickSell_m,
          isLogin,
          onOrderHistory,
          orderHistoryData,
          getChangeDateStr_2,
          getTradeStatus,
          getCssColor,
          cpModal,
          showModal4,
          closeModal4,
          okModal4,        
          currentAssetItem,
          cpModalSell,
          currentOrderHistoryItem,
          showModal5,
          closeModal5,
          okModal5,
     }
  }

}
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css">

</style>