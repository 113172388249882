//import { reactive } from 'vue';
const getIsOrderAlarm = () => {
  let startFlag = localStorage.getItem("getIsOrderAlarm");
  if (!startFlag) startFlag = "true";
  // console.log("getCurrentCategoryItem:", startItem)
  return startFlag;
};

const getCurrentCategoryItem = () => {
  let startItem = JSON.parse(
    sessionStorage.getItem("cummunityCurrentCategory")
  );
  if (!startItem) startItem = [];
  // console.log("getCurrentCategoryItem:", startItem)
  return startItem;
};

export default {
  namespaced: true,
  state: {
    currentPage: "Home",
    cummunityCurrentPage: 1,
    noticeCurrentPage: 1,
    cummunityCurrentCategory: getCurrentCategoryItem(),
    isOrderAlarm: getIsOrderAlarm(),
    menuRewardState: "Y",
    isLocal: false,
  },
  mutations: {
    CHANGE_ISORDER_ALARM(state, payload) {
      state.isOrderAlarm = payload;
      localStorage.setItem("getIsOrderAlarm", payload);
    },
    CHANGE_CURRENT_PAGE(state, payload) {
      state.currentPage = payload;
    },
    CHANGE_CUMMUNITY_CURRENT_PAGE(state, payload) {
      state.cummunityCurrentPage = payload;
    },
    CHANGE_NOTICE_CURRENT_PAGE(state, payload) {
      state.noticeCurrentPage = payload;
    },
    CHANGE_MENU_REWARD_STATE(state, payload) {
      state.menuRewardState = payload;
    },
    CHANGE_CUMMUNITY_CURRENT_CATEGORY(state, payload) {
      state.cummunityCurrentCategory = payload;
      // console.log("CHANGE_CUMMUNITY_CURRENT_CATEGORY:", payload)
      sessionStorage.setItem(
        "cummunityCurrentCategory",
        JSON.stringify(payload)
      );
    },
  },
  actions: {
    // triggerToast({ commit }, message, type = 'success') {
    //     commit('ADD_TOAST', {np
    //         id: Date.now(),
    //         message: message,
    //         type: type,
    //     });
    //     setTimeout(() => {
    //         commit('REMOVE_TOAST')
    //     }, 10000)
    // }
  },
  //computed 와 동일한기능..
  getters: {
    // toastMessageWithSmile(state) {
    //     return state.toasts.message + "^^~~~";
    // }
  },
};
