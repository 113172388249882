<template>
      <HeaderTrade />

      <!-- Container Start { -->
      <div id="container" class="sts-container">
        <!-- Contents Start { -->
        <div id="contents">

           <TradeMainTop />
            <!-- flex tv 인기 라이브 영상 start -->
            <div class="sts-main-hotlive">
               <h2 class="hotlive-title">
                  <span class="icon-tit-main"><img src="@/assets/images/icon/ico_title_main_live.png"></span>
                  인기 LIVE 영상
               </h2>

               <div class="hotlive-list">

                  <!-- item 1 -->
                  <div
                     class="hotlive-item"
                     v-for="(item) in indexLiveList"
                     :key = item.channelId
                     @click="onLivenowClicked(item)"
                  >
                    <HlsPlayer
                        :title="item.title"
                        :ownerNickname="item.owner.nickname"
                        :ownerThumbUrl="item.owner.thumbUrl"
                        :thumbUrl="item.thumbUrl"
                        :playerCount="item.playerCount"
                        :playUrl="item.playSource?.url"
                    />
                  </div>

               </div>
            </div>
           <!-- // flex tv 인기 라이브 영상 end -->
          <!-- 모의투자 : 메인 > 실시간 랭크 -->
          <div class="sts-main-rank">
            <div class="main-rank-box">
              <!-- <h3>모의투자 참가자 <strong class="text-danger">1,024 명</strong></h3> -->
              <!-- class 설명
                  .table tr  >> 보합
                  .table tr.up  >> 상승
                  .table tr.down  >> 하락
              -->
              <!-- item : 주식거래 start -->
              <RankBoard
                 :title="'주식거래 손익 TOP 10'"
                 :tradeType="'S'"
              />
              <!-- //item : 주식거래 end -->

              <!-- item : 선물거래 start -->
              <RankBoard
                 :title="'선물거래 손익 TOP 10'"
                 :tradeType="'F'"
              />
              <!-- //item : 선물거래 end -->

              <!-- item : 코인거래 start -->
              <RankBoard
                 :title="'코인거래 손익 TOP 10'"
                 :tradeType="'C'"
              />
              <!-- //item : 코인거래 end -->

              <!-- item : 코인선물 start -->
              <RankBoard
                 :title="'코인선물 손익 TOP 10'"
                 :tradeType="'G'"
              />
              <!-- //item : 코인선물 end -->

            </div>
          </div>

          <!-- 모의투자 광고 start -->
          <!-- <div class="sts-ad-box">
            <div><img src="@/assets/images/trade/sts_ad_sam.png" alt="ad"></div>
          </div> -->
          <!-- //모의투자 광고 end -->

        </div>
        <!-- } Contents End -->
      </div>
      <!-- } Container End -->

      <Footer/>

   <MainNoticeModal
      v-if="showModal" 
      @close="closeModal"
      @cancle="closeModal"
      @ok="okModal"
   />
</template>

<script>
import HeaderTrade from '@/components/HeaderTrade.vue';
import TradeMainTop from '@/components/trade/TradeMainTop.vue';
import HlsPlayer from '@/components/home/HlsPlayer.vue';
import Footer from '@/components/Footer.vue';
import { useStore } from 'vuex';
import { ref, onMounted} from 'vue';
import RankBoard from '@/components/RankBoard_home.vue'
import {useGetItemList} from '@/composables/vtradeFn';
import {axiosFlex} from '@/axios';
import Config from '../../public/config.js';
import VueCookies from "vue-cookies";
import MainNoticeModal from '@/components/popups/MainNoticeModal.vue';
import { useApicall } from '@/composables/commonFn';

export default {
    components:{
    HeaderTrade,
    Footer,
    HlsPlayer,
    TradeMainTop,
    RankBoard,
    MainNoticeModal
 },
 setup(){
  //  const  trace = getCurrentInstance().appContext.config.globalProperties.$log;

    const store = useStore();
    const  {getItemList} = useGetItemList()
    const items_s =  ref([]);
    const items_f =  ref([]);
    const items_c =  ref([]);
    const items_g =  ref([]);

    const indexLiveList = ref([])
    const cootoken = ref(VueCookies.get('cootoken'));
  
    const { apiCall_g } = useApicall();
    const showModal = ref(false);
    const popupListCount = ref('');

    const getIndexLiveList = async ()  =>{                 
          //   console.log("indexLiveList:")
         const res = await axiosFlex.get('api/channels/inliveRankings?liveOption=total&includeAdult=false')
            
         // if(!res || !res.data)
         

          
            indexLiveList.value  = [];
            for(var i = 0; i< res.data.data.length; i++)
            {

               var item = res.data.data[i];
               // console.log("res.data.data", item.isForAdult)
               if(!item.isForAdult)
               {

                   indexLiveList.value.push(item);
                   if(indexLiveList.value.length == 4)
                   {
                     break;
                   }

               }
            }

           //  console.log("indexLiveList:", indexLiveList.value,  Config.URL.flextvUrl, cootoken.value)

      }

    const onLivenowClicked = ( item )=>{

        // console.log("onLivenowClicked : ", item)
         if(cootoken.value)
         {

           window.open('about:blank').location.href = Config.URL.flextvUrl + `/redirects/signin?token=${cootoken.value}&redirectTo=/channels/${item.channelId}/live`
         }
         else
         {
            window.open('about:blank').location.href = Config.URL.flextvUrl
         }
    }
   onMounted( async()=>{

        // localStorage.removeItem('startItem_S')       
         getIndexLiveList();
         if(!sessionStorage.getItem('startItem_S') || !sessionStorage.getItem('itemList_S') )
         {
            getItemList('S', items_s, ()=>{
                  //console.log("items_s:", items_s.value[0])
                  sessionStorage.setItem('startItem_S', JSON.stringify(items_s.value[0]));
                  sessionStorage.setItem('itemList_S', JSON.stringify(items_s.value));
            });
         }

       //  localStorage.removeItem('startItem_F')
         if(!sessionStorage.getItem('startItem_F') || !sessionStorage.getItem('itemList_F'))
         {
            getItemList('F', items_f, ()=>{
                 // console.log("items_f~:", items_f.value[0])
                  sessionStorage.setItem('startItem_F', JSON.stringify(items_f.value[0]));
                  sessionStorage.setItem('itemList_F', JSON.stringify(items_f.value));
            });

         }

        // localStorage.removeItem('startItem_C')
         if(!sessionStorage.getItem('startItem_C') || !sessionStorage.getItem('itemList_C') )
         {
            getItemList('C', items_c, ()=>{
                 // console.log("items_c:", items_c.value[0])
                  sessionStorage.setItem('startItem_C', JSON.stringify(items_c.value[0]));
                  sessionStorage.setItem('itemList_C', JSON.stringify(items_c.value));
            });

         }

       // localStorage.removeItem('startItem_G')
         if(!sessionStorage.getItem('startItem_G') || !sessionStorage.getItem('itemList_G'))
         {
            getItemList('G', items_g, ()=>{
                  //console.log("items_g:", items_g.value[0])
                  sessionStorage.setItem('startItem_G', JSON.stringify(items_g.value[0]));
                  sessionStorage.setItem('itemList_G', JSON.stringify(items_g.value));
            });

         }

         popupListCount.value = await getPopupCount();
         noticePopupOpen();
       


         
   })

   const okModal = async() => {
      showModal.value = false; 
    }    

   const closeModal = () => {
      showModal.value = false; 
   }

   const noticePopupOpen = () => {
      if( popupListCount.value > 0 ) {
         showModal.value = true;
         getCheckCookie();
      } else {
         return false;
      }
   }

    const getCheckCookie = () => {
      var popupCookie = VueCookies.get('popCookie');
      if(popupCookie) {
         showModal.value = false; 
      } else {
         return false;
      }
      // console.log('popupCookie================>>>', popupCookie)
    }

    const getPopupCount = async() => {
      const res = await apiCall_g(`board/list?&categoryType=aboard&categoryCode=popup`);
      var result = res.dataCount;
      if(!result) result = [];
      return result
    }


   // trace( 'VtradeMain')
    store.commit('common/CHANGE_CURRENT_PAGE', 'Home');
    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_0');
    return{
         onLivenowClicked,
         indexLiveList,
         showModal,
         okModal,
         closeModal,
         popupListCount,
         noticePopupOpen,
    }
 }


}
</script>

<style>

</style>
