import { useStore } from 'vuex';
import axios from '@/axios';
//import { useToast } from '@/composables/toast';
import {axiosFlex} from '@/axios';


export const useCheckLogin =  ()=>{
    var store = useStore();    
 

    const checkLogin = async ()=>{
         var token = sessionStorage.getItem('token');   
         
         try{
            const res = await axios.get('loginmember/logincheck',{ headers: {'token': token }});   
            
           if(res.data.returnCode == '1') 
           {
             if(res.data.resultMap.returnVal == "1")
             {
               //console.log("!!!!!!!!!!!!!!!!!!!!!!!!")
               store.commit('user/CHANGE_IS_LOGIN', true);  
               //store.commit('user/CHANGE_MEMBER_DATA', res.data.resultMap.member); 
             }
             else
             {
               
               store.commit('user/CHANGE_IS_LOGIN', false);  
             }
              
           }
           else
           {
              store.commit('user/CHANGE_IS_LOGIN', false);  
           }
 
        
                    
         }catch(error){     
            console.log(error);
        
          
            return false;
            
         }  
    }

    const getMember = async ()=>{
        var token = sessionStorage.getItem('token');   
        
        try{
           const res = await axios.get('loginmember/getmember',{ headers: {'token': token }});   
           
          if(res.data.returnCode == '1') 
          {
            if(res.data.resultMap.returnVal == "1")
            {
             
             // store.commit('user/CHANGE_IS_LOGIN', true);  
             store.commit('user/CHANGE_MEMBER_DATA', res.data.resultMap.member); 
             return res.data.resultMap.member;
              
            }
            else
            {
              
              store.commit('user/CHANGE_IS_LOGIN', false);  
            }
             
          }

       
                   
        }catch(error){     
           console.log(error);
       
         
           return false;
           
        }  
   }
    return {
       checkLogin,
       getMember,
    }
   
 }


export const useApicall = () => {


    
   const apiCall_pt = async(_url, _params, _type = null, messageFn = null)=>{
       var store = useStore();  
       var token = sessionStorage.getItem('token');    
      
        try{ 
            var res = null;
            if(_type)
            {   
                if(_type == "PUT") 
                {
                    res = await axios.put(_url, _params ,{headers:{'token': token}});                     
                }
            }
            else
            {
                res = await axios.post(_url, _params,{headers:{'token': token}});
            }             
            let json =  res.data;              
            if(json.returnCode ==1 )
            {
                if(json.resultMap.returnVal == 1)
                {
                   // console.log('apiCall_pt :',_url,':', json.resultMap )
                   return json.resultMap;
                }
                else
                {                       
                   // console.log(_url + " :" +json.message);    
                    if(messageFn)
                    {
                       messageFn(json.message)     
                    }
                              
                    return false;                   
                   
                }
            }
            else if(json.returnCode == -102 )
            {
                //alert("로그인이 필요합니다.");
               // console.log("로그인이 필요합니다.");
                console.log("aaaaaaaaaaaaaaaaaaaa:", store)
                if(store)store.commit('user/CHANGE_IS_LOGIN', false);  
                if(messageFn)
                {
                    messageFn("로그인 후 사용가능합니다.", json.returnCode )     
                }
               // return 'unLogin';
                return false;   
            }
            else
            {
                if(messageFn)
                {
                    messageFn(json.message)     
                }
              //  console.log(_url + " :" +json.message);
                return false;  
            }
            
        }catch(error){     
                console.log("apiCall_pt error", error);             
                return false;           
        }
    }



    const apiCall_gt = async(_url, _type = null, messageFn = null) => {
        var store = useStore();  
        var token = sessionStorage.getItem('token');         
        try {
            var res = null;
            if(_type == 'DEL')
            {
                res = await axios.delete(_url, { headers: {'token': token }});
            }
            else
            {
                res = await axios.get(_url, { headers: {'token': token }});
            }
            
            let json =  res.data;    
             
            if(json.returnCode == 1 )
            {
                if ( json.resultMap.returnVal == 1 ) 
                {
                  //  console.log('apiCall_gt :',_url,':', json.resultMap )
                    return json.resultMap;
                } 
                else 
                {                    
                   // console.log(_url + " :" +json.message);    
                    if(messageFn)
                    {
                       messageFn(json.message)     
                    }
                              
                    return false;  
                }
            }
            else if(json.returnCode == -102 )
            {
                //console.log("sssssssssssss", store)
                if(store)store.commit('user/CHANGE_IS_LOGIN', false);   
                if(messageFn)
                {
                    messageFn("로그인 후 사용가능합니다.", json.returnCode )     
                }
                // return 'unLogin';
                return false; 
            }
            else
            {
                if(messageFn)
                {
                    messageFn(json.message)     
                }
               // console.log(_url + " :" +json.message);
                return false;  
            }
        } catch(error) {
            console.log(" apiCall_gt  error", error);             
            return false;
        }
    }

    const apiCall_p = async(_url, _params, _type = null, messageFn = null)=>{ 
        //console.log("_url", _url, "_params", _params )
        var store = useStore();  
        try{ 

            var res = null;

            if(_type)
            {   
                if(_type == "PUT") res = await axios.put(_url, _params);                 
                else if (_type == "DEL") res = await axios.del(_url, _params);    
            }
            else
            {
                res = await axios.post(_url, _params);
            } 

            let json =  res.data;              
            if(json.returnCode ==1 )
            {
                if(json.resultMap.returnVal == 1  || json.resultMap.returnVal == -1049 /*최초로그인*/ )
                {              
                   return json.resultMap;
                }
                else
                {
                    //console.log(_url + " :" +json.message);
                    if(messageFn)
                    {
                       messageFn(json.message)     
                    }
                    return false;  
                }
            }
            else if(json.returnCode == -102 )
            {                    //alert("로그인이 필요합니다.");
                store.commit('user/CHANGE_IS_LOGIN', false);            
                if(messageFn)
                {
                    messageFn("로그인 후 사용가능합니다.", json.returnCode )     
                }
               // return 'unLogin';
                return false;              
            }
            else
            {
              //  console.log(_url + " :" +json.message);
                return false;  
            }
            
        }catch(error){     
                console.log("apiCall_p error", error);             
                return false;           
        }
    }



    const apiCall_g = async(_url, messageFn = null) => {
        var store = useStore();  
        try {
            const res = await axios.get(_url);
            let json =  res.data;         
            if(json.returnCode == 1 )
            {
                if ( json.resultMap.returnVal == 1 ) 
                {
                   // console.log('apiCall_g :',_url,':', json.resultMap )
                    return json.resultMap;
                } 
                else 
                {                    
                   // console.log(_url + " :" +json.message);
                    if(messageFn)
                    {
                        messageFn(json.message)     
                    }
                    return false;  
                }
            } 
            else if(json.returnCode == -102 )
            { 
                                   //alert("로그인이 필요합니다.");
                 store.commit('user/CHANGE_IS_LOGIN', false);                  
                
                if(messageFn)
                {
                    messageFn("로그인 후 사용가능합니다.", json.returnCode )     
                }
               // return 'unLogin';
                return false;              
            }          
            else
            {
               // console.log(_url + " :" +json.message);
                if(messageFn)
                {
                    messageFn(json.message)     
                }
                return false;  
            }
        } catch(error) {
            console.log(" apiCall_g  error", error);             
            return false;
        }
    }


    

    return {
        apiCall_pt,
        apiCall_gt,
        apiCall_p,
        apiCall_g
    }


}



export const useGetChannels =()=>{
  
    // const {         
    //    triggerToast
    // } = useToast();
    var store = useStore();  
 
    const getChannels = async ( isAdult, bjType, sortType)=>{
 
           let sortStr;
          if(sortType == 1) sortStr = 'total';        
          else if(sortType == 2) sortStr = 'recent'; 
          else if(sortType == 3) sortStr = 'view'; 
          else if(sortType == 4) sortStr = 'newbj'; 
          else sortStr = 'total';
          try{
             //console.log("getChannels ~~~: ", isFin, isAdult, bjType, sortType); 
             const res = await axiosFlex.get(`api/channels/inliveRankings?liveOption=${sortStr}&includeAdult=${isAdult}`);   
             var t_liveList = res.data.data;   
             var liveList = [];
             liveList = t_liveList;  
 
 
             
             store.commit('live/CHANGE_LIVE_LIST', liveList);    
            //console.log("getChannels :", liveList)        
            // triggerToast('Successfully~~ getChannels');
             var loginIds = [];
             liveList.forEach(element => {
               loginIds.push(element.owner.loginId)
             });
  
                      
          }catch(error){     
             
             console.log(error);
             //triggerToast('Something went wrong!', 'danger');
             return false;
             
          }  
    }
 

 
    const getSerchChannels = async (str, lim)=>{
       //console.log("getSerchChannels :", str, lim)    
      try{
        
         const res = await axiosFlex.get(`api/channels/searchRankings?name=${str}&limit=${lim}`);   
         var liveList = res.data.data; 
        // console.log("getSerchChannels :", liveList)          
         store.commit('live/CHANGE_LIVE_LIST', liveList);            
        // triggerToast('Successfully~~ getChannels');
       
         
                  
      }catch(error){     
         
         console.log(error);
         //triggerToast('Something went wrong!', 'danger');
         return false;
         
      }  
 }
 
    const getRecentChannels = async ()=>{    
        var token = sessionStorage.getItem('token');    
        if(!token)
        {           
          return false
        }  
      try{        
         const res = await axiosFlex.get(`api/my/streams/recent`, { headers: {'authorization': `Bearer ${token}` }});   
         var liveList = res.data;    
        // console.log("getRecentChannels :", liveList)       
         store.commit('live/CHANGE_LIVE_LIST', liveList);            
        // triggerToast('Successfully~~ getChannels');    
        return true
      
                  
      }catch(error){
         console.log(error);
         //triggerToast('Something went wrong!', 'danger');
         return false;
       }  
    }
 
    const getSubscribesChannels = async ()=>{      
       try{
          var token = sessionStorage.getItem('token');   
          const res = await axiosFlex.get(`api/subscribes/streams`, { headers: {'authorization': `Bearer ${token}` }} );   
          var liveList = res.data;    
         // console.log("getSubscribesChannels :", liveList)       
          store.commit('live/CHANGE_LIVE_LIST', liveList);            
          //triggerToast('Successfully~~ getChannels');     
          return true         
       }catch(error){
          console.log(error);
          //triggerToast('Something went wrong!', 'danger');
          return false;
       }  
    }
  
    return{
       getChannels,
       getRecentChannels,
       getSubscribesChannels,
       getSerchChannels
    }
 }
 
 