<template>
            <!-- 주식 종목 리스트 start -->
            <div class="section aside-stock-list">
              <div class="main-bbs-tab">
                <!-- tab button -->
                <ul class="nav nav-tabs" id="asideStockTabButton" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="aside-stock-popular" data-bs-toggle="tab" data-bs-target="#asideStockPopular" type="button" role="tab" aria-controls="asideStockPopular" aria-selected="true" @click="onPopularStockClick('popularstock')">
                      인기종목
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="aside-stock-sudden" data-bs-toggle="tab" data-bs-target="#asideStockSudden" type="button" role="tab" aria-controls="asideStockSudden" aria-selected="false" @click="onPopularStockClick('kospilowup')">
                      급상승종목
                    </button>
                  </li>
                  <!-- <li class="nav-item" role="presentation">
                    <button class="nav-link" id="aside-stock-interest" data-bs-toggle="tab" data-bs-target="#asideStockInterest" type="button" role="tab" aria-controls="asideStockInterest" aria-selected="false">
                      관심종목
                    </button>
                  </li> -->
                </ul>

                <!-- tab contents -->
                <div class="tab-content" id="asideStockTabContents">

                  <!-- 주식 : 인기종목 start -->
                  <div class="tab-pane fade show active" id="asideStockPopular" role="tabpanel" aria-labelledby="aside-stock-popular">
                    <!-- <span class="more"><a href="https://finance.naver.com/sise/" target="_blank">더보기</a></span> -->
                    <div class="aside-st-co-inner">

                      <!-- <div class="aside-st-co-search">
                        <input type="text" class="form-control" placeholder="종목명/유가증권 표준코드 검색" aria-label="search">
                        <button class="btn-search" type="button"><img src="@/assets/images/common/ico_header_search.png" alt="search"></button>
                      </div> -->

                      <!-- class 설명 
                          .aside-st-co-box > li >> 보합
                          .aside-st-co-box > li.up >> 상승
                          .aside-st-co-box > li.down >> 하락
                      -->
                      <ul class="aside-st-co-box">
                        <li
                            v-for="(item, index) in popularStockList.slice(0, 10)"
                            :key = index
                            :class="getPopularStockClass(item[2].substr(0, 1))"
                          >
                          <span class="rank">{{ index + 1 }}</span>
                          <span class="item text-truncate">
                            {{ item[0] }}
                          </span>
                          <span class="num text-truncate">{{ item[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').slice(0, -2)}}</span>
                          <span class="per">
                            {{ item[2] }}
                          </span>
                          <!-- <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_check.png" alt="관심"></button>
                          </span> -->
                        </li>
                      </ul>
                    </div>
                    <div class="main-bbs-null" style="display:none">주식 글이 없습니다.</div>
                  </div>
                  <!-- //주식 : 인기종목 end -->

                  <!-- 주식 : 급상승종목 start -->
                  <div class="tab-pane fade" id="asideStockSudden" role="tabpanel" aria-labelledby="aside-stock-sudden">
                    <!-- <span class="more"><a href="javascript:333;">더보기</a></span> -->
                    <div class="aside-st-co-inner">

                      <!-- class 설명 
                          .aside-st-co-box > li >> 보합
                          .aside-st-co-box > li.up >> 상승
                          .aside-st-co-box > li.down >> 하락
                      -->
                      <ul class="aside-st-co-box">
                        <li
                            class="up"
                            v-for="(item, index) in popularStockList.slice(0, 10)"
                            :key = index
                            :class="getPopularStockClass(item[2].substr(0, 1))"
                          >
                          <span class="rank">{{ index + 1 }}</span>
                          <span class="item text-truncate">
                            {{ item[0] }}
                          </span>
                          <span class="num text-truncate">{{ item[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').slice(0, -2)}}</span>
                          <span class="per">
                            <i></i> {{ item[2] }}
                          </span>
                          <!-- <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_check.png" alt="관심"></button>
                          </span> -->
                        </li>
                      </ul>
                    </div>                    
                    <div class="main-bbs-null" style="display:none">급상승종목 글이 없습니다.</div>
                  </div>
                  <!-- //주식 : 급상승종목 end -->

                  <!-- 주식 : 관심종목 start -->
                  <!-- <div class="tab-pane fade" id="asideStockInterest" role="tabpanel" aria-labelledby="aside-stock-interest">
                    <span class="more"><a href="javascript:333;">더보기</a></span>
                    <div class="aside-st-co-inner">

                      <div class="aside-st-co-search">
                        <input type="text" class="form-control" placeholder="종목명/유가증권 표준코드 검색" aria-label="search">
                        <button class="btn-search" type="button"><img src="@/assets/images/common/ico_header_search.png" alt="search"></button>
                      </div> -->

                      <!-- class 설명 
                          .aside-st-co-box > li >> 보합
                          .aside-st-co-box > li.up >> 상승
                          .aside-st-co-box > li.down >> 하락
                      -->
                      <!-- <ul class="aside-st-co-box">
                        <li class="up">
                          <span class="rank">1</span>
                          <span class="item text-truncate">
                            씨아이테크<span>004920</span>
                          </span>
                          <span class="num text-truncate">892</span>
                          <span class="per">
                            <i></i> 14.21%
                          </span>
                          <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_check.png" alt="관심"></button>
                          </span>
                        </li>
                        <li class="up">
                          <span class="rank">2</span>
                          <span class="item text-truncate">
                            에이블씨엔씨<span>078520</span>
                          </span>
                          <span class="num text-truncate">5,060</span>
                          <span class="per">
                            <i></i> 13.84%
                          </span>
                          <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                          </span>
                        </li>                        
                        <li class="up">
                          <span class="rank">3</span>
                          <span class="item text-truncate">
                            미래아이앤지<span>035720</span>
                          </span>
                          <span class="num text-truncate">348</span>
                          <span class="per">
                            <i></i> 22.54%
                          </span>
                          <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                          </span>
                        </li>
                        <li class="up">
                          <span class="rank">4</span>
                          <span class="item text-truncate">
                            흥아해운<span>007120</span>
                          </span>
                          <span class="num text-truncate">1,510</span>
                          <span class="per">
                            <i></i> 22.27%
                          </span>
                          <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                          </span>
                        </li>                        
                        <li class="up">
                          <span class="rank">5</span>
                          <span class="item text-truncate">
                            다이나믹디자인<span>145210</span>
                          </span>
                          <span class="num text-truncate">8,280</span>
                          <span class="per">
                            <i></i> 29.98%
                          </span>
                          <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                          </span>
                        </li>
                        <li class="up">
                          <span class="rank">6</span>
                          <span class="item text-truncate">
                            SK네트웍스우<span>001745</span>
                          </span>
                          <span class="num text-truncate">74,400</span>
                          <span class="per">
                            <i></i> 29.84%
                          </span>
                          <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_check.png" alt="관심"></button>
                          </span>
                        </li>
                        <li class="up">
                          <span class="rank">7</span>
                          <span class="item text-truncate">
                            베트남개발1<span>096300</span>
                          </span>
                          <span class="num text-truncate">396</span>
                          <span class="per">
                            <i></i> 29.84%
                          </span>
                          <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                          </span>
                        </li>
                        <li class="up">
                          <span class="rank">8</span>
                          <span class="item text-truncate">
                            무림페이퍼<span>009200</span>
                          </span>
                          <span class="num text-truncate">2,735</span>
                          <span class="per">
                            <i></i> 27.80%
                          </span>
                          <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                          </span>
                        </li>
                        <li class="up">
                          <span class="rank">9</span>
                          <span class="item text-truncate">
                            한전산업<span>130660</span>
                          </span>
                          <span class="num text-truncate">9,500</span>
                          <span class="per">
                            <i></i> 15.85%
                          </span>
                          <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                          </span>
                        </li>
                        <li class="up">
                          <span class="rank">10</span>
                          <span class="item text-truncate">
                            이수화학<span>005950</span>
                          </span>
                          <span class="num text-truncate">23,750</span>
                          <span class="per">
                            <i></i> 4.46%
                          </span>
                          <span class="btn-box">
                            <button type="button" class="btn btn-interest"><img src="@/assets/images/main/icon_plus.png" alt="관심"></button>
                          </span>
                        </li>
                      </ul>
                    </div>                    
                    <div class="main-bbs-null" style="display:none">관심종목 글이 없습니다.</div>
                  </div> -->
                  <!-- //주식 : 관심종목 end -->

                </div>
              </div>

            </div>
            <!-- //주식 종목 리스트 end -->
</template>

<script>
//import {onMounted, ref } from 'vue'
import { getCurrentInstance, onMounted, ref } from 'vue'
import {useApicall } from '@/composables/commonFn';

export default {
  
  setup(){
    const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
    const {apiCall_g} = useApicall()
    const popularStockList = ref([]);

    trace
    const getPopularStockList = async (_key) => {
      var url = `index/getexternaldata`
      if(_key)
      {
        url +=  `?key=${_key}`  
      }
      const resArray = await apiCall_g(url);

      popularStockList.value = [];
      resArray.data.forEach(element => {
        let _array = element.split(";");
        popularStockList.value.push(_array);
      })
      // trace("popularStockList ===========>>", popularStockList.value)
    }


    const onPopularStockClick = (pkey)=>{
      getPopularStockList(pkey);
        //trace("itemType.value:",   itemType.value)
    }
    
    const getPopularStockClass = (plusMinus) => {    
      var result;
      if (plusMinus == '+') {
        result = 'up'
      } else if (plusMinus == '-') {
        result = 'down'
      } else  if (plusMinus == '') {
        result = ''
      }
      return result;

    }    

    onMounted(async ()=>{
      getPopularStockList('popularstock');
    })

    return {
      onPopularStockClick,
      popularStockList,
      getPopularStockClass,

    }
  }
}
</script>

<style>

</style>