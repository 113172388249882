<template>
                          <!-- 매수, 매도 영역 start -->
                          <div class="trade-order-area">

                            <div class="trade-order-tab">
                              <!-- tab button -->
                              <ul class="nav nav-tabs nav-fill" id="tradeOrderTabButton" role="tablist">
                                <li class="nav-item" role="presentation">
                                  <button @click="tradeOrderTab" class="nav-link active" id="trade-order-buy-tab" data-bs-toggle="tab" data-bs-target="#tradeOrderBuyTab" type="button" role="tab" aria-controls="tradeOrderSellTab" aria-selected="true" ref="btnSellTap">
                                    매수
                                  </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button @click="tradeOrderTab" class="nav-link" id="trade-order-sell-tab" data-bs-toggle="tab" data-bs-target="#tradeOrderSellTab" type="button" role="tab" aria-controls="tradeOrderBuyTab" aria-selected="false">
                                    매도
                                  </button>
                                </li>
                              </ul>
                
                              <!-- tab contents -->
                              <div class="tab-content" id="tradeOrderTabContents">
                
                                <!-- 매수 start -->
                                <div class="tab-pane fade show active" id="tradeOrderBuyTab" role="tabpanel" aria-labelledby="trade-order-buy-tab">
                                  <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                                  <!-- 매수창 시작 -->
                                  <div class="order-box">
                                    <div class="trade-item">
                                      <div class="item-group">
                                        <div>
                                          <input type="radio" class="btn-check" id="chkt4" name="checkType" autocomplete="off" checked="checked" @change="onTapBuyType" ref="radioChk_orderType_1">
                                          <label class="btn btn-outline-danger" for="chkt4">지정가</label>
                                        </div>
                                        <div>
                                          <input type="radio" class="btn-check" id="chkt5"  name="checkType" autocomplete="off" @change="onTapBuyType">
                                          <label class="btn btn-outline-secondary" for="chkt5">시장가</label>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="trade-item balance">         
                                      <div class="item-group">
                                        <div class="item-title">주문가능</div>
                                        <div class="item-number">{{selectItem.TYPE=='F'?Constant.UTIL.numberWithCommas(futureOrderPossibleCnt_buy)+ ' 거래':Constant.UTIL.numberWithCommas(Math.floor(currentPossibleBalance)) + '원'}}</div>
                                      </div>
                                    </div>

                                    <div class="trade-item order-input"  v-if="orderType=='L'">
                                      <div class="item-group">
                                        <label class="item-title" for="buyPrice">가격</label>
                                        <!-- <input type="text" id="buyPrice" class="form-control" v-model="input_currentPrice"> -->
                                        <InputNumber
                                                :nValue="currentOrderPrice"
                                                sClass="form-control"
                                                @updateNvalue="onUpdateOrderPrice"
                                                :comma="selectItem.TYPE=='S'?false:true"
                                                :ndotpos="selectItem.TYPE=='F'?Number(selectItem.NDOTPOS):selectItem.TYPE=='C'?4:null"
                                        />


                                        <span class="item-txt">{{selectItem.TYPE=='F'?'':'원'}}</span>
                                      </div>
                                    </div>

                                    <div class="trade-item order-input quantity">
                                      <div class="item-group">
                                        <label class="item-title" for="buyOrderQuantity">수량</label>
                                        <!-- <input type="text" id="buyOrderQuantity" class="form-control" v-model="input_currentPriceNum"> -->
                                        <InputNumber 
                                                :nValue="currentOrderNum"
                                                sClass="form-control"
                                                @updateNvalue="onUpdateOrderNum"
                                                :comma="selectItem.TYPE=='C'?true:false"
                                                :ndotpos="4"        
                                        />
                                        <!-- <span class="item-num " v-else   @click="onInputChange" >{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(currentOrderNum_buy, 4))}}</span> -->
                                        <!-- <span class="item-num text-danger" v-else   @click="onInputChange" >{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(currentOrderNum_buy, 4))}}</span> -->
                                        <span class="item-txt text-black">{{getOrderNumbering(selectItem.TYPE)}}</span>
                                      </div>
                                    </div>

                                    <div class="trade-item order-btn-area" >                         
                                      <div  v-if="!isSliderMode" class="order-btn" @click="selectRangeBtn($event)"  >
                                        <button type="button" class="btn" @click="onClickRangeActive(1)">10%</button>
                                        <button type="button" class="btn" @click="onClickRangeActive(2)">25%</button>
                                        <button type="button" class="btn" @click="onClickRangeActive(3)">50%</button>                                    
                                        <button type="button" class="btn" @click="onClickRangeActive(4)">100%</button>
                                        <button type="button" class="btn" @click="onClickRangeActive(5)" ref="customRangeBtn">{{isCustomMode?range_1 + '%':'직접입력'}}</button>
                                      </div>

                                      <div   v-else  class="range">
                                        <Slider
                                          v-model="range_1"
                                          class="slider-red"
                                          :tooltipPosition="'bottom'"
                                          :min="1"
                                          :max="100"
                                          @input="onRangeChange_1"
                                      />                                                
                                        <!-- <div class="range__slider">
                                          <input type="range" id="buyRange" step="1"  v-model="range_1"  @input="onRangeChange_1" >
                                        </div> -->
                                        <div class="range-value">
                                          <!-- <input type="text" id="buyOrderValue" class="form-control form-control-sm" v-model="range_1" readonly>
                                          <span class="unit">%</span> -->
                                        </div>
                                        <div class="range-btn">
                                          <button type="button" class="btn btn-sm btn-dark" @click="onClickRangeActive(6)">확인</button>
                                        </div>
                                        <div class="range-division">
                                          <span class="division-1">0</span>
                                          <span class="division-2">25</span>
                                          <span class="division-3">50</span>
                                          <span class="division-4">75</span>
                                          <span class="division-5">100</span>
                                        </div>
                                      </div>
                                    </div>


                                    <div class="trade-item order-input order-price"    v-if="selectItem.TYPE !='F'">
                                     <div class="item-group">
                                        <label class="item-title" for="buyOrderQuantity">주문총액</label>
                                        <!-- <input type="text" id="buyOrderQuantity" class="form-control" v-model="input_currentPriceNum"> -->
                                        <InputNumber 
                                                :nValue="currentTradePrice"
                                                sClass="form-control"
                                                @updateNvalue="onUpdateTradePrice"
                                                :comma="selectItem.TYPE=='C'?false:false"
                                                :ndotpos="0"        
                                        />           
                                        <span class="item-txt text-black">원</span>
                                      </div>
                                     </div>
                                  </div>

                                  <div class="order-box-footer">
                                    <button type="button" class="btn btn-danger order buy"  @click="onOrderBuy">매수</button>
                                    <button type="button" class="btn btn-light cancel" @click="onOrderReset" >초기화</button>
                                  </div>

                                  <div class="order-box-info">
                                    <span v-if="selectItem.TYPE!='S'">{{selectItem.TYPE=='F'?'한계약담보금 ' + Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(selectItem.MARGIN, 2)) + ' 원':'최소주문금액 5,000 KRW'}}</span>
                                    <span  v-if="selectItem.TYPE!='S'" class="pipe">/</span>
                                    <span> {{selectItem.TYPE=='F'?selectItem.SUBTYPE=='K'?'수수료율 ' + Constant.UTIL.toFixedNum(selectItem.FEES,3) +'%':'수수료 $ ' + Constant.UTIL.toFixedNum(selectItem.FEES,3):'수수료율 ' + Constant.UTIL.toFixedNum(selectItem.FEES,3) +'%'}} </span>
                                  </div>
                                  <!-- //매수창 종료 -->

                                </div>
                                <!-- //매수 end -->
                


                                <!-- 매도 ===========================================================================================================================================start -->
                                <!-- 매도 start -->
                                <div class="tab-pane fade" id="tradeOrderSellTab" role="tabpanel" aria-labelledby="trade-order-sell-tab">
                                  <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                                  <!-- 매도창 시작 -->
                                  <div class="order-box">
                                    <div class="trade-item">
                                      <div class="item-group">
                                        <div>
                                          <input type="radio" class="btn-check" id="chkt1" name="checkType1" autocomplete="off" checked="checked" @change="onTapBuyType" ref="radioChk_orderType_2">
                                          <label class="btn btn-outline-danger" for="chkt1">지정가</label>
                                        </div>
                                        <div>
                                          <input type="radio" class="btn-check" id="chkt2" name="checkType1" autocomplete="off" @change="onTapBuyType">
                                          <label class="btn btn-outline-secondary" for="chkt2">시장가</label>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="trade-item balance">                                     
                                      <div class="item-group">
                                        <div class="item-title">{{selectItem.TYPE=='F'?'주문가능':'주문가능'}}</div>
                                        <div class="item-number"> {{selectItem.TYPE=='F'?Constant.UTIL.numberWithCommas(futureOrderPossibleCnt_sell) + ' 거래':Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(getSellPossibleCnt(),4)) + ' ' +getOrderNumbering(selectItem.TYPE)}}</div>                                       
                                      </div>
                                    </div>

                                    <div class="trade-item order-input"  v-if="orderType=='L'">
                                      <div class="item-group">
                                        <label class="item-title" for="sellPrice">가격</label>
                                        <!-- <input type="text" id="sellPrice" class="form-control"> -->
                                          <InputNumber
                                                  :nValue="currentOrderPrice"
                                                  sClass="form-control"
                                                  @updateNvalue="onUpdateOrderPrice"
                                                  :comma="selectItem.TYPE=='S'?false:true"
                                                  :ndotpos="selectItem.TYPE=='F'?Number(selectItem.NDOTPOS):selectItem.TYPE=='C'?4:null"
                                          />
                                        <span class="item-txt">{{selectItem.TYPE=='F'?'':'원'}}</span>
                                      </div>
                                    </div>

                                    <div class="trade-item order-input">
                                      <div class="item-group">
                                        <label class="item-title" for="sellOrderQuantity">수량</label>
                                       <!--  <input type="text" id="sellOrderQuantity" class="form-control"> -->
                                         <InputNumber
                                                  :nValue="currentOrderNum"
                                                  sClass="form-control"
                                                  @updateNvalue="onUpdateOrderNum"
                                                  :comma="selectItem.TYPE=='C'?true:false"
                                                  :ndotpos="4"
                                                  
                                          />
                                          <!-- <span class="item-num text-primary">131,575.0394</span> -->
                                        <span class="item-txt text-black">{{getOrderNumbering(selectItem.TYPE)}}</span>
                                      </div>
                                    </div>



                                    <div class="trade-item order-btn-area" >                         
                                      <div  v-if="!isSliderMode" class="order-btn" @click="selectRangeBtn($event)"  >
                                        <button type="button" class="btn" @click="onClickRangeActive(1)">10%</button>
                                        <button type="button" class="btn" @click="onClickRangeActive(2)">25%</button>
                                        <button type="button" class="btn" @click="onClickRangeActive(3)">50%</button>                                    
                                        <button type="button" class="btn" @click="onClickRangeActive(4)">100%</button>
                                        <button type="button" class="btn" @click="onClickRangeActive(5)" ref="customRangeBtn">{{isCustomMode?range_1 + '%':'직접입력'}}</button>
                                      </div>

                                      <div   v-else  class="range">
                                        <Slider
                                          v-model="range_1"
                                          class="slider-blue"
                                          :tooltipPosition="'bottom'"
                                          :min="1"
                                          :max="100"
                                          @input="onRangeChange_1"
                                      />                                                
                                        <!-- <div class="range__slider">
                                          <input type="range" id="buyRange" step="1"  v-model="range_1"  @input="onRangeChange_1" >
                                        </div> -->
                                        <div class="range-value">
                                          <!-- <input type="text" id="buyOrderValue" class="form-control form-control-sm" v-model="range_1" readonly>
                                          <span class="unit">%</span> -->
                                        </div>
                                        <div class="range-btn">
                                          <button type="button" class="btn btn-sm btn-dark" @click="onClickRangeActive(6)">확인</button>
                                        </div>
                                        <div class="range-division">
                                          <span class="division-1">0</span>
                                          <span class="division-2">25</span>
                                          <span class="division-3">50</span>
                                          <span class="division-4">75</span>
                                          <span class="division-5">100</span>
                                        </div>
                                      </div>
                                    </div>


                                    <div class="trade-item order-input order-price" v-if="orderType=='L' &&  selectItem.TYPE!='F'" >
                                     <div class="item-group">
                                        <label class="item-title" for="buyOrderQuantity">주문총액</label>                          
                                        <InputNumber 
                                                :nValue="currentTradePrice"
                                                sClass="form-control"
                                                @updateNvalue="onUpdateTradePrice"
                                                :comma="selectItem.TYPE=='C'?false:false"
                                                :ndotpos="0"        
                                        />                                      
                                        <span class="item-txt text-black">원</span>
                                      </div>
                                     </div>
                                  </div>

                                  <div class="order-box-footer">
                                    <button type="button" class="btn btn-primary order sell" @click="onOrderSell" >매도</button>
                                    <button type="button" class="btn btn-light cancel" @click="onOrderReset">초기화</button>
                                  </div>
                                  <!-- //매도창 종료 -->

                                  <div class="order-box-info">
                                    <span v-if="selectItem.TYPE!='S'">{{selectItem.TYPE=='F'?'한계약담보금 ' + Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(selectItem.MARGIN, 2)):'최소주문금액 5,000 KRW'}}</span>
                                    <span  v-if="selectItem.TYPE!='S'" class="pipe">/</span>
                                    <span> {{selectItem.TYPE=='F'?selectItem.SUBTYPE=='K'?'수수료율 ' + Constant.UTIL.toFixedNum(selectItem.FEES,3) +'%':'수수료 $ ' + Constant.UTIL.toFixedNum(selectItem.FEES,3):'수수료율 ' + Constant.UTIL.toFixedNum(selectItem.FEES,3) +'%'}} </span>
                                  </div>

                                </div>
                                <!-- //매도 end -->
                
                              </div>
                            </div>

                            <!-- 모의투자 대회 및 랭킹 start -->
                            <div class="trade-sts-info">
                              <div class="sts-info">
                                <h3 class="text-truncate">{{currentSpaceName?currentSpaceName:'모의투자 대회'}}</h3>
                                <p  @click="onClickRetry">재도전  {{recount + '/' +  (retrycount=='-1'?'무한':retrycount)}}</p>
                              </div>
                              <div class="sts-link">
                                <span class="text">제1회 모의투자 대회</span>
                                <span class="link">
                                  <a href="javascript:;">--</a>
                                  <a href="javascript:;">--</a>
                                </span>
                              </div>

                              <div class="sts-my-rank">
                                <!-- class 설명 
                                    ul  >> 보합
                                    ul.up  >> 상승
                                    ul.down  >> 하락
                                -->
                                <ul>
                                <!-- <ul :class="Constant.UTIL.getUpdownColor(getProfitPre())"> -->
                                  <li :class="Constant.UTIL.getUpdownColor(getProfitPre())">
                                    <span>수익률</span>
                                    <span><i></i>{{Constant.UTIL.numberWithCommas(Math.abs(getProfitPre())) + ' %'}}</span>
                                  </li>
                                  <li :class="Constant.UTIL.getUpdownColor(currentTotalProfit)">
                                    <span>평가손익</span>
                                    <span><i></i>{{Constant.UTIL.numberWithCommas(Math.abs(currentTotalProfit)) + ' 원'}}</span>
                                  </li>
                                  <li :class="ProfitData._dataSum?Constant.UTIL.getUpdownColor(Number(ProfitData._dataSum.TPOL) + currentTotalProfit):''">
                                    <span>총 손익</span>
                                    <span><i></i>{{ProfitData._dataSum?Constant.UTIL.numberWithCommas(Math.abs(Math.floor(Number(ProfitData._dataSum.TPOL) + currentTotalProfit ))) + ' 원':'0'}}</span>
                                  </li>
                                  <li>
                                    <span>{{selectItem.TYPE=='F'?'총 평가':'총 평가'}}</span>
                                    <span>{{selectItem.TYPE=='F'? Constant.UTIL.numberWithCommas(Math.floor(totalBalance_f)) + ' 원':Constant.UTIL.numberWithCommas(Math.floor(totalBalance)) + ' 원' }}</span> 
                                  </li>

                                  <li>
                                    <span>{{selectItem.TYPE=='F'?'주문가능금액':'주문가능금액'}}</span>
                                    <span>{{selectItem.TYPE=='F'? Constant.UTIL.numberWithCommas(Math.floor(currentPossibleBalance_future)) + ' 원':Constant.UTIL.numberWithCommas(Math.floor(currentBalance - currentOrderPriceSum)) + ' 원' }}</span> 
                                  </li>
                                  
                                  <li>
                                    <span>나의 순위</span>
                                    <span>{{ProfitData._rankRow?Constant.UTIL.numberWithCommas(ProfitData._rankRow.RANK) + ' 위':'-- 위'}}</span>
                                  </li>
                                </ul>
                              </div>

                            </div>
                            <!-- //모의투자 대회 및 랭킹 end -->

                          </div>
                          <!-- //매수, 매도 영역 end -->


 

    <RetryModal
      v-if="showModal_retry" 
      @close="closeModal" 
      @cancle="closeModal" 
      @ok="okModal" 
      :currentSpaceName="currentSpaceName"
      :recount="recount"
      :retrycount="retrycount"
     />
 

</template>

<script>
import { ref, watchEffect, computed, getCurrentInstance, onMounted, watch, onUnmounted} from 'vue';
import { useStore } from 'vuex';
import Constant from '@/constant.js';
import InputNumber from '@/components/trade/InputNumber.vue';
import {useApicall} from '@/composables/commonFn';
import RetryModal from '@/components/popups/RetryModal.vue'
import Slider from '@vueform/slider';
import Config from '../../../public/config.js';
export default {
  components:{
    InputNumber,
    RetryModal,
    Slider
  },
  props:{    
    currentSelectOrderPrice:{
        type: Number,
        required: true
    },
    selectItem:{        
           require:true
    },
  },
  setup(props){
      // const $ = require('jquery');

      const isRangeMode = ref(false);
      const isSliderMode = ref(false);
      const customRangeBtn = ref(null);      
      const isCustomMode = ref(false)


      const showModal_retry = ref(false)
      const {apiCall_gt, apiCall_pt} = useApicall();
      const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
      const internalInstance = getCurrentInstance();  
      const emitter = internalInstance.appContext.config.globalProperties.emitter;
      const btnSellTap = ref(null);
     // const currentOrderNum_buy = ref(0)
      // M:시장가, L지정가
      const orderType = ref("L");
        //1,매수, 2:매도, 3:선물매수, 4:선물매도
      var tradeType = "1";

     const radioChk_orderType_1 = ref(null) 
     const radioChk_orderType_2 = ref(null)
       
     const currentOrderList = computed(()=> store.state.hts.orderList);

  
      //가격
      const currentOrderPrice =  ref(0);
      //갯수
      const currentOrderNum =  ref(0);
      //주문금액
      const currentTradePrice = ref(0);

      const currentOrderPriceSum = ref(0)
 
      const ProfitData = ref({})

      const store = useStore();
      const isLogin = computed(() => store.state.user.isLogin);
      const currentSpaceName = computed(()=>store.state.hts.currentSpaceName)

      const currentBalance = computed(() => parseInt(store.state.hts.currentBalance.BALANCE));
      const currentTotalPrice  = computed(() => parseInt(store.state.hts.currentTotalPrice));
      const currentTotalProfit = computed(() => parseInt(store.state.hts.currentTotalProfit));

      const retrycount = computed(() => parseInt(store.state.hts.retryCount));
      const recount = computed(() => parseInt(store.state.hts.reCount));

      const payMoney = computed(() => parseInt(store.state.hts.currentBalance.PAYMONEY));

      const tradeObj = computed(() => store.state.hts.trade);
      
      const propsCurrentSelectOrderPrice = computed(()=>props.currentSelectOrderPrice)

      var totalBalance  = computed(() => currentBalance.value + currentTotalPrice.value );
      var totalBalance_f  = computed(() => currentBalance.value + currentTotalProfit.value );
            
      const currentPossibleBalance = computed(()=>store.state.hts.currentPossibleBalance);
      const currentAssetList = computed(()=> store.state.hts.assetlist);
    
      const currentPossibleSellBalnce = ref(0)
      const currentLosscut = computed(()=>store.state.hts.currentLosscut);
    
      const currentPossibleBalance_future = computed(()=>{
          if(store.state.hts.currentFuturePossibleCnt.avavailBalanceLong)
          {
              var long = Number(store.state.hts.currentFuturePossibleCnt.avavailBalanceLong);
              var short = Number(store.state.hts.currentFuturePossibleCnt.availBalanceShort);              
              return  Math.min(long, short)
          }
          else
          {
              return 0;
          }
      })

      const futureOrderPossibleCnt_buy = computed(() => 
      {
          if(store.state.hts.currentFuturePossibleCnt.buy)
          {
              return  parseInt(store.state.hts.currentFuturePossibleCnt.buy)
          }
          else
          {
              return 0;
          }
      });

      const futureOrderPossibleCnt_sell = computed(() => 
      {
          if(store.state.hts.currentFuturePossibleCnt.sell)
          {
              return parseInt(store.state.hts.currentFuturePossibleCnt.sell);
          }
          else
          {
              return 0;
          }
      })

     const range_1 = ref(50);


      const getProfitPre = () =>{
            var result; 
            if(!ProfitData.value._dataSum)return 0;
            if(isNaN(currentTotalProfit.value))return 0;
             //console.log("payMoney.value1111", ProfitData.value._dataSum.TPOL, currentTotalProfit.value, payMoney.value)
            var profit = Number(ProfitData.value._dataSum.TPOL) + currentTotalProfit.value
            result =  (profit/payMoney.value)*100;           
           // console.log("payMoney.value", profit, payMoney.value )
            return Constant.UTIL.toFixedNum(result, 2);
      }

  

     watch(currentOrderList,()=>{
      //console.log("currentOrderList", currentOrderList.value)
      currentOrderPriceSum.value = 0;
      if(currentOrderList.value.length > 0)
      {
        currentOrderList.value.forEach(element => {
           if(element.TRADETYPE == '1')
           {
               currentOrderPriceSum.value  += (Number(element.ORDERINDEX) * Number(element.ORDERCOUNT))
           }
           
        });
      }
      //console.log("currentOrderPriceSum.value :", currentOrderPriceSum.value)
     })
     


       watch(currentBalance,()=>{
         // console.log("currentBalance:~~", currentBalance.value)
          getProfitlist()
       })



      watch(range_1, ()=>{
        
        if(props.selectItem.TYPE == 'F')
        {
            if( tradeType == "1"){
              //매수
              if(isRangeMode.value)
              {
                   currentOrderNum.value = Number(futureOrderPossibleCnt_buy.value)*(range_1.value/100)

              }
            }
            else
            {
               //매도
                 currentOrderNum.value = Number(futureOrderPossibleCnt_sell.value)*(range_1.value/100)
            }
        }
        else
        {
            if( tradeType == "1"){
              //매수
                if(isRangeMode.value)
                {
                      if(orderType.value == "M")
                      {
                          var upOrderPrice = currentOrderPrice.value + (currentOrderPrice.value * 0.05)                
                          currentOrderNum.value = Number((currentPossibleBalance.value * (range_1.value/100))  /  (upOrderPrice + (upOrderPrice * (Number(props.selectItem.FEES)/100)) ));   
                        
                        
                      }
                      else
                      {
                          currentOrderNum.value = Number((currentPossibleBalance.value*(range_1.value/100)) /  (currentOrderPrice.value + (currentOrderPrice.value * (Number(props.selectItem.FEES)/100)) ));                  
                      }

                      if( currentOrderNum.value == Infinity)
                      {
                          console.log("currentOrderNum.value :", currentOrderNum.value)
                          currentOrderNum.value = 0;
                      }

                       
                      currentTradePrice.value = currentOrderPrice.value * currentOrderNum.value; 
                }
            }
            else
            {
              //매도
                if(isRangeMode.value)
                {
                  currentOrderNum.value = Number((getSellPossibleCnt()*(range_1.value/100)));  
                  currentTradePrice.value = currentOrderPrice.value * currentOrderNum.value; 
                }
            }
        }

      })

      watch(currentOrderPrice, ()=>{
         deSelectRangeBtn()  
        if(props.selectItem.TYPE == 'F')return;  
         currentTradePrice.value = currentOrderPrice.value * currentOrderNum.value; 
      })
 

      const getSellPossibleCnt =()=>{

          if( props.selectItem.TYPE == "F")return
          var result = 0;  

          var foundAsset =  store.state.hts.assetlist.find(element => element.CODE == props.selectItem.CODE);
          var foundOrders =  store.state.hts.orderList.filter(element => element.CODE == props.selectItem.CODE);
          if(!foundAsset )
          {     
                  return 0;
          }     

          result = Number(foundAsset.COUNT);
          //부분매수 미체결내역

          foundOrders.forEach(element => {

             if(element.TRADETYPE == '2')
             {
                result -= Number(element.ORDERCOUNT);
             }
          })



          // if(foundOrder && foundOrder.TRADETYPE == '2')
          // {
          //   result = result - Number(foundOrder.ORDERCOUNT);
          // }
          return result;
      }


    const onOrderReset = () =>{
      isRangeMode.value = false;  
      isCustomMode.value = false;
      isSliderMode.value = false; 
      range_1.value = 0;  
      deSelectRangeBtn()  
      
      setTimeout(()=>{
          var t_num = Number(tradeObj.value.trade_price)
          currentOrderNum.value = 0;
          currentOrderPrice.value = 0;
          currentTradePrice.value = 0;
          if(!isNaN(t_num)) {      
            currentOrderPrice.value = t_num;              
          }
      },0)
    }


      const onUpdateOrderPrice = (value) =>{
          currentOrderPrice.value = value;
          currentTradePrice.value = currentOrderPrice.value * currentOrderNum.value;  
      }

      const onUpdateOrderNum = (value) =>{
        isRangeMode.value = false;  
        isCustomMode.value = false;
        isSliderMode.value = false; 
        deSelectRangeBtn()  
        currentOrderNum.value = value;
        currentTradePrice.value = currentOrderPrice.value * currentOrderNum.value;  
      }

      const onUpdateTradePrice = (value) =>{
        isRangeMode.value = false;  
        isCustomMode.value = false;
        isSliderMode.value = false; 
        deSelectRangeBtn() 
        currentTradePrice.value = value;       
        if(orderType.value == "M")
        {
            var upOrderPrice = currentOrderPrice.value + (currentOrderPrice.value * 0.05)                
            currentOrderNum.value = Number(currentTradePrice.value  /  (upOrderPrice + (upOrderPrice * (Number(props.selectItem.FEES)/100)) ));             
        }
        else
        {
            currentOrderNum.value = Number( currentTradePrice.value /  (currentOrderPrice.value + (currentOrderPrice.value * (Number(props.selectItem.FEES)/100)) ));
        }

        if(props.selectItem.TYPE == "S")
        {
          setTimeout(()=>{
           // currentTradePrice.value =  currentOrderNum.value * currentOrderPrice.value;
          },1000)
        }

      }

      

      const getOrderBalance = () =>{
            var result = 0; 
          if(store.state.hts.orderList.length)
          {              
              for(var i=0; i<store.state.hts.orderList.length; i++)
              {
               
                  var t_order = store.state.hts.orderList[i];

                  //console.log("t_order:", t_order)
                  if(t_order.TRADETYPE == '1')
                  {
                      var orderBal = Number(t_order.ORDERINDEX) * Number(t_order.ORDERCOUNT)
                      var t_balance = Math.floor(orderBal + ((orderBal * Number(t_order.FEES))/100)); 
                      result += t_balance
                  }

              }
          }  
          return result;
      } 

     watch(propsCurrentSelectOrderPrice,()=>{
         try{
              if(orderType.value == 'M')return;
               currentOrderPrice.value = propsCurrentSelectOrderPrice.value;    
             // trace("currentOrderPrice.value~~~~~", propsCurrentSelectOrderPrice.value)    
         }catch(e){
          e
         }
        
     })



     const drawPosition = () =>{
          if(currentAssetList.value.length > 0) 
          {

            var isAsset = false;
            currentAssetList.value.forEach(element => {
              if(element.CODE == props.selectItem.CODE)
              {
              
                emitter.emit('EVENT_CREATE_POSITION_LINE',{count:Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(element.COUNT, 2)), price:element.PRICE, tradeType:getTradeType(element.TRADETYPE)});
                isAsset = true
              }
            });


            if(!isAsset)
            {                  
              emitter.emit('EVENT_REMOVE_POSITION_LINE');
            }
          }
          else
          {
              emitter.emit('EVENT_REMOVE_POSITION_LINE');
          }
     }

     
     onMounted(()=>{
          watch(currentAssetList,()=>{  
              try{
                onOrderReset()                
              }
              catch(e){
                e
              }

              drawPosition();

             /*
             if(currentAssetList.value.length > 0) 
             {

                var isAsset = false;
                currentAssetList.value.forEach(element => {
                  if(element.CODE == props.selectItem.CODE)
                  {
                  
                    emitter.emit('EVENT_CREATE_POSITION_LINE',{count:Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(element.COUNT, 2)), price:element.PRICE, tradeType:getTradeType(element.TRADETYPE)});
                    isAsset = true
                  }
                });


                if(!isAsset)
                {                  
                  emitter.emit('EVENT_REMOVE_POSITION_LINE');
                }
              }
              else
              {
                 emitter.emit('EVENT_REMOVE_POSITION_LINE');
              }
              */
          })
  
          watchEffect(()=>{
              var t_curBal =  currentBalance.value - getOrderBalance()
              //currentPossibleBalance.value  =  Math.floor(t_curBal - ((t_curBal * currentFees.value)/100));
              if(t_curBal < 5000) t_curBal = 0;
              store.commit('hts/CHANGE_POSSIBLE_BALANCE', t_curBal);
          })


          emitter.off('EVENT_GET_ASSETLIST_TO_ORDERVIEW');
          emitter.on('EVENT_GET_ASSETLIST_TO_ORDERVIEW', ()=>{
           // trace("EVENT_GET_ASSETLIST_TO_ORDERVIEW^^")
            setTimeout(()=>{
              try{
                    onOrderReset()
              }catch(e){
              e
              }
            }, 500)
          })
          emitter.off('EVENT_SELECTED_ITEM_TOORDER');
          emitter.on('EVENT_SELECTED_ITEM_TOORDER', ()=>{
           // trace("EVENT_SELECTED_ITEM_TOORDER^^", item)
            setTimeout(()=>{
              try{
                    onOrderReset();
                    drawPosition();
              }catch(e){
              e
              }
            }, 1000)
          })
     })

     onUnmounted(()=>{
       
     })

    const goLoginPage = ()=>{     
      location.href = Config.URL.flexLifeUrl + `login?app=money&redirect_url=${Config.URL.flexMoneyUrl}`;
    }

    const onOrderBuy = () =>{       
       // trace("onOrderBuy:", )
        if(!isLogin.value)
        {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.", fn:goLoginPage});
             return
        }

        var orderNum = currentOrderNum.value;
        if(props.selectItem.TYPE == 'S' || props.selectItem.TYPE == 'F')
        {
          orderNum = Math.floor(currentOrderNum.value);
        }

        if(props.selectItem.TYPE == "S")
        {
          currentTradePrice.value =  orderNum * currentOrderPrice.value;
        }
       
        emitter.emit('EVENT_OPEN_MODAL_ORDER', 
          {
              orderType:orderType.value,
              tradeType:tradeType,
              orderPrice:currentOrderPrice.value, 
              orderNum:orderNum,
              tradePrice:Math.floor(currentTradePrice.value),
              spadeId:store.state.hts.currentSpaceId,
              type:props.selectItem.TYPE,
              code:props.selectItem.CODE,
              item:props.selectItem
          }
        );
    }


   

    const onOrderSell = () =>{   
      
        if(!isLogin.value)
        {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.", fn:goLoginPage});
             return
        }

        var orderNum = currentOrderNum.value;
        if(props.selectItem.TYPE == 'S' || props.selectItem.TYPE == 'F' )
        {
          orderNum = Math.floor(currentOrderNum.value);
        }

        if(props.selectItem.TYPE == "S")
        {
          currentTradePrice.value =  orderNum * currentOrderPrice.value;
        }


        emitter.emit('EVENT_OPEN_MODAL_ORDER', 
            {
                orderType:orderType.value,
                tradeType:tradeType,
                orderPrice:currentOrderPrice.value, 
                orderNum:orderNum,
                tradePrice:Math.floor(currentTradePrice.value),
                spadeId:store.state.hts.currentSpaceId,
                type:props.selectItem.TYPE,
                code:props.selectItem.CODE,
                item:props.selectItem
            }
        );
    }

    






    const tradeOrderTab =(e)=>{
       if(e.target.id == "trade-order-buy-tab")
       {
             tradeType = "1"
       }
      else if(e.target.id == "trade-order-sell-tab")
       {
             tradeType = "2";
             
       }


       radioChk_orderType_1.value.checked = true;
       radioChk_orderType_2.value.checked = true;

       orderType.value = "L";

     //  trace("tradeType:", tradeType)
        onOrderReset()      
    }


    


    const onTapBuyType =(e)=>{
       
       if(e.target.id == "chkt4" ||  e.target.id == "chkt1")
       {
          orderType.value = "L";
       }
       else if(e.target.id == "chkt5" ||  e.target.id == "chkt2")
       {
         orderType.value = "M"         
       }
       //  trace("orderType:", orderType.value)
       onOrderReset()
    }

  





    const onRangeChange_1 = () =>{
      trace("onRangeChange_1~~:",  range_1)      
     // currentOrderNum.value =  (rangeForOrderNum * range_1.value)/100
    }



    const getProfitlist = async()=>{
           
        var res = await apiCall_gt(`vspace/getprofitlistbyday?type=${props.selectItem.TYPE}&spaceId=${store.state.hts.currentSpaceId}`);
        if(!res)return       
        ProfitData.value._dataSum = res.dataSum;
        ProfitData.value._rankRow = res.rankRow;        
    }



    const getTradeType = (type) =>{
        switch(type)
        {
            case '1':
                return "매수"                        
            case '2':
                return "매도"                       
            case '3':
                return "매수"                        
            case '4':
                return "매도"
        }
    }

    const closeModal =()=>{
          showModal_retry.value = false; 
    }

    const okModal = async() =>{
        showModal_retry.value = false; 

        var res = await apiCall_pt(`vspace/retrySpace`, {"accountId": store.state.hts.currentAccountId},null,(_message)=>{
           emitter.emit('EVENT_ALERT',{title:"알림", body:_message});
         });
         //console.log("onRetryOk:", res)
         if(!res)return         
         emitter.emit('EVENT_ALERT',{title:"알림", body:"초기화 되었습니다. 새로고침 합니다.", fn:()=>{window.location.reload()}});
    }  

    const onClickRetry =()=>{
        if(!isLogin.value)
        {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.",fn:goLoginPage});
             return
        }

         if(!store.state.hts.currentAccountId)
        {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"대회참가 후 사용가능합니다."});
             return
        }

        showModal_retry.value = true; 

    }


    const selectRangeBtn = (e) => {
      var orderButtons = document.querySelectorAll('.order-btn .btn')
      orderButtons.forEach((orderBtn) => {
        if(orderBtn.classList.contains('active')) {
          orderBtn.classList.remove('active');
        } 
      });
       e.target.classList.add('active'); 
    }

    
    const deSelectRangeBtn = () => {       
      var orderButtons = document.querySelectorAll('.order-btn .btn')
      orderButtons.forEach((orderBtn) => {
        if(orderBtn.classList.contains('active')) {
          orderBtn.classList.remove('active');
        }
      });
    }

    const onClickRangeActive = (_type) => {
      if(_type == 1)
      {
         isRangeMode.value = true;
         isCustomMode.value = false;
         range_1.value = 10;
      }
      else if(_type == 2)
      {
         isRangeMode.value = true;
         isCustomMode.value = false;
         range_1.value = 25;
      }
      else if(_type == 3)
      {
         isRangeMode.value = true;        
         isCustomMode.value = false;
         range_1.value = 50;
      }
      else if(_type == 4)
      {
         isRangeMode.value = true;    
         isCustomMode.value = false;  
         range_1.value = 100; 
        
      }      
      else if(_type == 5)
      {
         isRangeMode.value = true;  
         isSliderMode.value = true;  
         isCustomMode.value = true;   
         if( range_1.value == 0 )range_1.value=50
      }   
      else if(_type == 6)  
      {       
        isSliderMode.value = false;   
        setTimeout(()=>{
           customRangeBtn.value.classList.add('active')
        },500);
      }   
    }





    const getOrderNumbering = (_type) =>{

        var result = null;
        if(_type == "S")
        {
            result = '주'
        }
        else if(_type == "F")
        {
            result = '계약'
        }
        else if(_type == "C")
        {
          result =  props.selectItem.CODE.split('-')[1];         
        }

        return result;
        
    }

      return{
         Constant,
         getProfitPre,
         totalBalance,
         currentOrderPrice,
         currentOrderNum,
         currentTradePrice,
         currentPossibleBalance,
         onUpdateOrderPrice,
         onUpdateOrderNum,
         onOrderBuy,
         onOrderReset,
         tradeOrderTab,         
         onTapBuyType,
         btnSellTap,
         currentPossibleSellBalnce,
         onOrderSell,
         range_1,
         onRangeChange_1,
      
        
         currentTotalProfit,
         currentLosscut,
         ProfitData,
         currentBalance,
         totalBalance_f,
         currentSpaceName,
        
         retrycount,
         recount,
         orderType,
         radioChk_orderType_1,
         radioChk_orderType_2,
         currentOrderPriceSum,
         currentPossibleBalance_future,
         showModal_retry,
         closeModal,
         okModal,
         onClickRetry,    
         selectRangeBtn,
         onClickRangeActive, 
         getOrderNumbering,
         onUpdateTradePrice,
         isRangeMode,
         isSliderMode,
         customRangeBtn,
         isCustomMode,
         getSellPossibleCnt,
         futureOrderPossibleCnt_buy,
         futureOrderPossibleCnt_sell


      }
  }

  

}
</script>

<style src="@vueform/slider/themes/default.css"></style>