<template>
  <div class="community-level-box">
    <span class="level" :class=" 'lv-' + userGrade">
      <i class="visually-hidden">레벨</i>
    </span>                  
    <span class="winner" :class=" 'wi-' + userGradeRank">
      <i class="visually-hidden">우승횟수</i>
    </span>
    <span class="nick">{{ userNickName }}</span>
  </div>

</template>

<script>
import { computed } from 'vue';
// import {useApicall} from '@/composables/commonFn';

export default {
  props: {
    itemGrade : {
      type:String,
      require:true
    },
    itemGradeRank : {
        type:String,
        require:true
    },
    itemNickName : {
        type:String,
        require:true
    },
  },

  setup(props) {
    const userGrade = computed(()=> props.itemGrade);
    const userGradeRank = computed(()=> props.itemGradeRank);
    const userNickName = computed(()=> props.itemNickName);

    // console.log('userGrade=======>', userGrade.value, userGradeRank.value,userNickName.value)
    return{
      userGrade,
      userGradeRank,
      userNickName,
    }
  }
}
</script>

<style>

</style>