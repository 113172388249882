//import { reactive } from 'vue';

export default {


    namespaced: true,
    state: { 
        currentLiveID:null,
        liveList: [], 
    },
    mutations: {
        CHANGE_LIVE_LIST(state, payload) {           
            state.liveList = payload;
        },

        CHANGE_CURRENT_LIVE_ID(state, payload) {           
            state.currentLiveID = payload;
        },


    },
    actions: {

       
        
        // triggerToast({ commit }, message, type = 'success') {            
        //     commit('ADD_TOAST', {
        //         id: Date.now(),
        //         message: message,
        //         type: type,
        //     });


        //     setTimeout(() => {
        //         commit('REMOVE_TOAST')                  
        //     }, 10000)
        // }
    },
    //computed 와 동일한기능..
    getters: {
        // toastMessageWithSmile(state) {
        //     return state.toasts.message + "^^~~~";
        // }
    },
}