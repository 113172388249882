<template>
<!-- 모의투자 랭킹 start -->
            <div class="section aside-sts-rank">
              <div class="main-title-area">
                <h2 class="title">
                  모의투자 랭킹                  
                </h2>
                <span class="more">
                  <!-- <a href="javascript:;">더보기</a> -->
                  <router-link :to="{ name:'Ranking' }">더보기</router-link>
                </span>
              </div>

              <div class="main-bbs-tab">
                <!-- tab button -->
                <ul class="nav nav-tabs" id="asideStsTabButton" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="aside-sts-stock" data-bs-toggle="tab" type="button" role="tab" aria-controls="asideStsStock" aria-selected="true" @click="onTapClick('S')">
                      주식
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="aside-sts-futures" data-bs-toggle="tab"  type="button" role="tab" aria-controls="asideStsFutures" aria-selected="false" @click="onTapClick('F')">
                      선물
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="aside-sts-coin" data-bs-toggle="tab"  type="button" role="tab" aria-controls="asideStsCoin" aria-selected="false" @click="onTapClick('C')">
                      코인현물
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="aside-sts-coinfutures" data-bs-toggle="tab"  type="button" role="tab" aria-controls="asideStsCoinFutures" aria-selected="false" @click="onTapClick('G')">
                      코인선물
                    </button>
                  </li>
                </ul>

                <!-- tab contents -->
                <div class="tab-content" id="asideStsTabContents">

                  <!-- 주식 start -->
                  <div class="tab-pane fade show active" id="asideStsStock" role="tabpanel" aria-labelledby="aside-sts-stock">
                    <div class="aside-sts-inner">

                  <!-- class 설명 

                      수익률/퍼센트
                      .aside-sts-box > li >> 보합
                      .aside-sts-box > li.up >> 상승
                      .aside-sts-box > li.down >> 하락

                      랭킹 변화
                      .num.same >> 보합
                      .num.up >> 상승
                      .num.down >> 하락
                  -->

                      <ul class="aside-sts-box">
                         <li v-if="arrRankingList.length == 0">
                            진행중인 대회가 없습니다.
                         </li>


                        <li 
                      
                          v-for="(item, index) in arrRankingList"      
                          :key = index
                          :class="getColor(Number(getProfitPer(Number(item.TOTAL_PROFIT), Number(item.PAYMONEY))))"

                        >
                          <span class="rank">{{item.RANK}}</span>
                          <!-- <span class="num up">3</span> -->
                          <span class="user text-truncate">
                            <UserNickGrade
                              :itemGrade="item.GRADE"
                              :itemGradeRank="item.GRADE_RANK"
                              :itemNickName="item.MEMBER_NICK"
                            />                            
                            <!-- {{item.MEMBER_NICK}} -->
                          </span>
                          <span class="rate text-truncate">
                            <i></i>
                            {{Constant.UTIL.numberWithCommas(Math.abs(item.TOTAL_PROFIT).toFixed(tradeType=='G'?2:0))}}
                          </span>
                          <span class="per text-truncate">
                            <i></i>
                            {{Constant.UTIL.numberWithCommas(Math.abs(getProfitPer(Number(item.TOTAL_PROFIT), Number(item.PAYMONEY))).toFixed(2)) + '%' }}
                          </span>
                        </li>                        
                      </ul>
                    </div>
                    <div class="main-bbs-null" style="display:none">준비중입니다.</div>
                  </div>
                  <!-- //주식 end -->

                 


                </div>

              </div>

            </div>
            <!-- //모의투자 랭킹 end -->
</template>

<script>
import {useApicall} from '@/composables/commonFn';
import {onMounted, ref} from 'vue';
import Constant from '@/constant.js';
import UserNickGrade from '@/components/UserNickGrade.vue';

export default {
  components:{
    UserNickGrade,
  },  
  setup(){
    const tradeType = ref('S');    
    const {apiCall_g} = useApicall();
    const arrSpaceList = ref([]);
    const arrRankingList = ref([]);
    const spaceId = ref(null);
    const getRankList = async (spaceId) =>{
          if(!spaceId) return [];
           var res = await apiCall_g(`index/getrankinglist?spaceId=${spaceId}&below_rank=10`);
           return res.dataList.splice(0, 10);  
    }


    const getSpacelist = async()=>{
      var res = await apiCall_g(`index/getspacelist?type=${tradeType.value}&status=1&applyType=A`);
      //console.log("res.dataList[0].ID:", res.dataList[0].ID)
      if(!res.dataList || res.dataList.length == 0)
      {
         spaceId.value = null;
      }
      else
      {
         spaceId.value = res.dataList[0].ID;
      }
      
      return res.dataList;  
    }  

    onMounted(async()=>{
        // console.log("arrRankingList.value :~~")
        arrSpaceList.value = await getSpacelist();
        arrRankingList.value =  await getRankList(spaceId.value);           
    
    })

    const getColor= (num)=>{
      var  result = ''
      if(num > 0)
      {
        result='up'
      }
      else if(num < 0)
      {
        result='down'
      }
      return result;
    }
    const getProfitPer = ( totalProfit, paymoney)=>{
         //console.log( "getProfitPer :", profit, balance, paymoney)
         return (totalProfit/paymoney)*100
    }

     const onTapClick = (type) =>{
       changeData(type)
     }


     const changeData = async ( type )=>{
         tradeType.value = type;
         arrSpaceList.value = await getSpacelist();
         arrRankingList.value =  await getRankList(spaceId.value);  
        // console.log("spaceId:", spaceId.value, arrRankingList.value)
     }

    return{
     getColor,
     arrRankingList,
     getProfitPer,
     Constant,
     tradeType,
     onTapClick
    }
  }

}
</script>

<style>

</style>