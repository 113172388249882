// import Config from '@/config.js';
import Config from '../../public/config.js';
import axios from '@/axios';
export async function getHistoryKey(type, socketId, code, limit, timeStamp, resolution) {
	//console.log("getHistory:", socketId, code, limit, timeStamp, String(timeStamp).length, resolution)
	//if(String(timeStamp).length == 7)return
   var candleType = '';
   var unit = 1;

   if(resolution == "1D")
   {
      candleType='D'
   }
   else if(resolution == "1W")
   {
      candleType='W'
   }
   else if(resolution == "1M")
   {
     candleType='C'
   }
   else
   {
        candleType = 'M';
		if(resolution == "1") unit = '1'
		else if(resolution == "3") unit = '3'
		else if(resolution == "5") unit = '5'
		else if(resolution == "10") unit = '10'
		else if(resolution == "15") unit = '15'
		else if(resolution == "30") unit = '30'
		else if(resolution == "60") unit = '60'
		else if(resolution == "240") unit = '240'
   }

	var params = {
		socketId: socketId,
		type: type,
		candleType: candleType,
		unit: unit,
		code: code,
		count: limit,
		timeStamp: timeStamp
	}

	

	try {
		const response = await axios.post( Config.URL.apiGroundUrl + `index/pricecandle`, params);  
		//console.log("getHistoryKey:", response , "params:", params )
		return response
		
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

export async function getHistoryKey_qry(type, socketId, code, limit, timeStamp, resolution, queryDay, queryTime) {
	//console.log("getHistory:",  code, limit, timeStamp,  resolution, "queryDay:", queryDay, "queryTime:", queryTime )
	//if(String(timeStamp).length == 7)return
   var candleType = '';
   var unit = 1;
 

   if(resolution == "1D")
   {
      candleType='D'
   }
   else if(resolution == "1W")
   {
      candleType='W'
   }
   else if(resolution == "1M")
   {
     candleType='C'
   }
   else
   {
        candleType = 'M';
		if(resolution == "1") unit = '1'
		else if(resolution == "3") unit = '3'
		else if(resolution == "5") unit = '5'
		else if(resolution == "10") unit = '10'
		else if(resolution == "15") unit = '15'
		else if(resolution == "30") unit = '30'
		else if(resolution == "60") unit = '60'
		else if(resolution == "240") unit = '240'
   }

	var params = {
		socketId: socketId,
		type: type,
		candleType: candleType,
		unit: unit,
		code: code,
		count: limit,
		timeStamp: timeStamp,
		queryDay:queryDay,
		queryTime:queryTime
	}



 

	try {
		const response = await axios.post( Config.URL.apiGroundUrl + `index/pricecandleqry` , params);  
		//console.log("pricecandlects:", response , "params:", params )
		return response
		
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}





// Make requests to CryptoCompare API
export async function makeApiRequest(path) {
	try {
		const response = await fetch(Config.URL.apiGroundUrl + `${path}`);
		//const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}
// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}
