import { createRouter, createWebHistory } from 'vue-router';
import Home from '../pages/index.vue';
import MyPage from '../pages/myPage.vue';

// community
import Community from '../pages/community.vue';
import CommunityView from '../pages/community_view.vue';
import CommunityWrite from '../pages/community_write.vue';
import CommunityWrite2 from '../pages/community_write2.vue'
import EventLeague from '../pages/eventLeague.vue'
import EventLeagueView from '../pages/eventLeague_view.vue'

// customer center
import Notice from '../pages/notice.vue';
import NoticeView from '../pages/notice_view.vue';
import Policy from '../pages/policy.vue';
import Privacy from '../pages/privacy.vue';
import Qna from '../pages/qna.vue';
import QnaWrite from '../pages/qna_write.vue';

// trade
import VtradeCoin from '../pages/trade/tradeCoin.vue';
import VtradeStock from '../pages/trade/tradeStock.vue';
import VtradeFutures from '../pages/trade/tradeFutures.vue';
import VtradeCoinFutures from '../pages/trade/tradeCoinFutures.vue';
import Ranking from '../pages/ranking.vue';
import LoginFirst from '../pages/login_first.vue';
import TradeResult from '../pages/trade/trade_result.vue';
import TradeReward from '../pages/trade/trade_reward.vue';



const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(){
        return { top: 0 }
    },
    routes: [  
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/myPage',
            name: 'MyPage',
            component: MyPage
        },
        {
            path: '/community',
            name: 'Community',
            component: Community
        },
        {
            path: '/community/:id',           
            name: 'CommunityView',
            component: CommunityView
        },
        {
            path: '/communityWrite/:id?',           
            name: 'CommunityWrite',
            component: CommunityWrite
        },
        {
            path: '/communityWrite2',           
            name: 'CommunityWrite2',
            component: CommunityWrite2
        },
        {
            path: '/vtrade/stock/:id',
            name: 'VtradeStock',
            component: VtradeStock,         
        },
        {
            path: '/vtrade/coin/:id',
            name: 'VtradeCoin',
            component: VtradeCoin
        },
        {
            path: '/vtrade/futures/:id',
            name: 'VtradeFutures',
            component: VtradeFutures
        },
        {
            path: '/vtrade/coinFutures/:id',
            name: 'VtradeCoinFutures',
            component: VtradeCoinFutures
        },
        {
            path: '/rankings',
            name: 'Ranking',
            component: Ranking,
        },
        {
            path: '/tradeResult',
            name: 'TradeResult',
            component: TradeResult,  
        },
        // 상금수령 페이지 추가
        {
            path: '/tradeReward',
            name: 'TradeReward',
            component: TradeReward,  
        },
        {
            path: '/loginFirst',
            name: 'LoginFirst',
            component: LoginFirst
        },
        // 고객센터
        {
            path: '/notice',
            name: 'Notice',
            component: Notice
        },
        {
            path: '/notice/:id',
            name: 'NoticeView',
            component: NoticeView
        },
        {
            path: '/policy',
            name: 'Policy',
            component: Policy
        },
        {
            path: '/privacy',
            name: 'Privacy',
            component: Privacy
        },
        {
            path: '/qna',
            name: 'Qna',
            component: Qna
        },    
        {
            path: '/qnaWrite/:id?',
            name: 'QnaWrite',
            component: QnaWrite
        },
        //이벤트리그 관련 추가 페이지  
        {
            path: '/eventLeague',
            name: 'EventLeague',
            component: EventLeague
        },    
        {
            path: '/eventLeagueView/:id',
            name: 'EventLeagueView',
            component: EventLeagueView
        },    
    ]
});

export default router;
