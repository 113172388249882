<template>
  <div class="hotlive-box">
    <div v-if="playUrl" class="video-card-thumn pb-0">
      <div class="live-video-wrap">
        <video ref="video" autoplay muted />
      </div>
      <div class="video-card-info">
        <span class="img" :style="'background-image: url('+ownerThumbUrl+')'"></span>
        <span>
          <span class="title">{{title}}</span>
          <span class="border-right">{{ownerNickname}}</span>
          <span class="info">{{'참여 '+  playerCount }}</span>
        </span>
      </div>
    </div>
    <div v-else class="video-card-thumn">
      <div class="live-video-wrap">
        <span class="video-thum-img" ><img :src="thumbUrl" alt="img" style="object-fit: cover" ></span>
      </div>

      <div class="video-card-info">
        <span class="img" :style="'background-image: url('+ownerThumbUrl+')'"></span>

        <span>
          <span class="title">{{title}}</span>
          <span class="border-right">{{ownerNickname}}</span>
          <span class="info">{{'참여 '+  playerCount }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
<style>
video {
  width: 100%;
  height: 100%;
}
</style>

<script>
import Hls from 'hls.js'
import { ref, onMounted, onUnmounted} from 'vue';


export default {
  name: "HLSPlayer",
  props:{
    ownerThumbUrl:{
      type:String,
      required:true
    },
    title: {
      type: String,
      required: true
    },
    ownerNickname: {
      type: String,
      required: true
    },
    thumbUrl: {
      type: String,
      required: true
    },
    playerCount:{
      type:Number,
      required:true
    },
    playUrl: {
      type: String,
      required: false
    }
  },

  setup(props){

    const video = ref(null);
    var hls = null;
    onMounted(()=>{     
      if ( props.playUrl) {
        hls = new Hls({
          backBufferLength: 3,
          autoStartLoad: true,
          clickToPlay: false,
          manifestLoadingMaxRetry: 5,
          manifestLoadingRetryDelay: 500,
          liveSyncDurationCount: 2,
        })
         
        hls.attachMedia(video.value)
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {         
          hls.loadSource(props.playUrl)
        })
        hls.on(Hls.Events.MANIFEST_PARSED, () => {          
          hls.startLoad()
        })
      }
    })

    onUnmounted(()=>{      
      if(hls)
      {        
         hls.stopLoad()
         hls = null;
      }
    })

    return{
      video
    }
  }
  
  // mounted() {

    
  //   if (this.$refs.video && this.playUrl) {
  //     const $video = this.$refs.video
  //     const hls = new Hls({
  //       backBufferLength: 3,
  //       autoStartLoad: true,
  //       clickToPlay: false,
  //       manifestLoadingMaxRetry: 5,
  //       manifestLoadingRetryDelay: 500,
  //       liveSyncDurationCount: 2,
  //     })
  //     hls.attachMedia($video)
  //     hls.on(Hls.Events.MEDIA_ATTACHED, () => {
  //       hls.loadSource(this.playUrl)
  //     })
  //     hls.on(Hls.Events.MANIFEST_PARSED, () => {
  //       hls.startLoad()
  //     })
  //   }
  // }
}
</script>

<style scoped>

</style>
