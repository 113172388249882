<template>
      <HeaderTrade/> 
      <!-- Container Start { -->
      <div id="container">
        <!-- Contents Start { -->
        <div id="contents">

          <!-- content start -->
          <div class="contents-area">
            <!-- 그라운드 top start -->
            <div class="section ground-view-top">
              <div class="title">
                {{boardItem.SUBJECT}}
              </div>
              <div class="ground-view-info">
                <!-- view info -->
                <div class="view-info-box">
                  <span class="category">{{boardItem.CATEGORY_NAME}}</span>
                  <span class="date">{{boardItem.CREATIONDATE}}</span>
                  <span class="nickname-box">
                    <span class="nickname">FLEXMONEY</span>
                  </span>
                  <span class="view">{{'조회 '+ boardItem.COUNT}}</span>   
                </div>
              </div>
            </div>
            <!-- //그라운드 top end -->
            
            <!-- 그라운드 contents start -->
            <div class="section ground-view-contents">
              <!-- contents -->
              <div class="view-contents"  v-html="boardItem.CONTENTS">
              </div>

              <!-- 하단 버튼 영역 -->
              <div class="view-btn-box" style="justify-content: center;">
                <div class="btn-list-box">
                  <button type="button" class="btn" @click="onPageList">
                    <span class="ico"><img src="@/assets/images/ground/ico_list.png" alt="목록"></span>
                    목록
                  </button>
                </div>

              </div>

            </div>
            <!-- //그라운드 contents end -->
            
          </div>
          <!-- //content end -->  

          <!-- aside start -->
          <div class="aside-area">

            <!-- <CommLoginSide /> -->
            <ToprankTradeSide />
            <GotradeLive />
            <StocklistSide />
            
          </div>
          <!-- //aside end -->


        </div>
        <!-- } Contents End -->
    
      </div>
      <!-- } Container End -->
     <Footer/>



    <!-- 레이어팝업 - alert : 로그인 요청 -->
    <div class="modal fade modal-alert" id="alertCommentLogin" tabindex="-1" aria-labelledby="comment-login" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            로그인 후 이용이 가능합니다.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">확인</button>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import { onMounted, ref,  getCurrentInstance, onUnmounted, computed } from 'vue';
import {useRoute, useRouter }from 'vue-router'
import { useStore } from 'vuex';
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import StocklistSide from '@/components/home/Stocklist_side.vue';
import GotradeLive from '@/components/home/GotradeLive.vue';
// import CommLoginSide from '@/components/community/CommLogin_side.vue';
import ToprankTradeSide from '@/components/home/ToprankTrade_side.vue';
import { useApicall } from '@/composables/commonFn';

export default {
    components:{  
        HeaderTrade, 
        Footer,
        StocklistSide,
        GotradeLive,
        // CommLoginSide,
        ToprankTradeSide
    },
 setup(){
 
   const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
   const route = useRoute();
   const router = useRouter();
   const boardItem = ref({});
   const { apiCall_g } = useApicall()
   const store = useStore();

   store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_notice');
   store.commit('common/CHANGE_CURRENT_PAGE', 'Notice');
   const page_store = computed(() => store.state.common.currentPage);
  
    const  getBoardItem = async (id)=>{   
      const res = await apiCall_g(`board/view?idx=${id}`);
      return res.data;
    }

    onMounted(async()=>{          
      emitter.emit('EVENT_CHECK_LOGIN');    
      boardItem.value = await getBoardItem(route.params.id);     
    //   console.log('window.history', window.history.state)
    })

    onUnmounted(()=> {
      if(page_store.value !== 'Notice') {
        store.commit('common/CHANGE_NOTICE_CURRENT_PAGE', 1);
      }
    })

    const onPageList = () => {
        // console.log('historyState', window.history.state.back);
        if(window.history.state.back !== null) {
            router.go(-1);
        } else {
            router.push({
                name:'Notice'
            });
        }        
    }

    return {
        boardItem,
        store,
        onPageList,
    }
 }
}
</script>

<style>

</style>