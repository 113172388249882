<template>
  <HeaderTrade/> 
  <!-- Container Start { -->
  <div id="container">
    <!-- Contents Start { -->
    <div id="contents">

      <div class="league-area">
        <div class="league-title">
          <h3>플렉스리그 대회 리스트</h3>
          <select class="form-select" @change="onSeletChange">
            <option value="0" selected>전체 대회</option>
            <option value="1">진행중 대회</option>
            <option value="2">참가중 대회</option>
            <option value="3">종료된 대회</option>
          </select>   
        </div>

        <!-- 대회 리스트 -->
        <ul class="league-list">
          <li   v-for="(item, index) in arrSortSpaceList"
                :key = index
          >
            <!-- <router-link :to="{ name:'CommunityView',  params:{ id:item.IDX }}" > -->
            <router-link :to="{ name:'EventLeagueView',  params:{ id:item.ID } }" >
              <div class="league-thum">
                <div class="thum">
                  <img :src="item.THUMBNAIL_IMG_URL" alt="대회썸네일">
                </div>
                <span v-if="item.isMine"
                  class="status par"
                >
                  참가중
                </span>
               
                <span v-else
                  class="status" :class="item.STATUS=='1'?'ongoing':''"
                >{{item.STATUS=='1'?'진행중':item.STATUS=='9'?'종료':'대기중'}}</span>               
            
              </div>

              <div class="league-info">
                <div class="box-title">
                  <p class="title text-truncate">{{item.NAME}}</p>
                  <div class="day">
                    <span v-if="item.STATUS=='1'" class="d-day">{{'D-' + getEndDateDiff(item.ENDDATE)}}</span>
                    <span :class="item.ISPRIVATE=='Y'?'private':'open'">{{item.ISPRIVATE=='Y'?'비공개':'공개'}}</span>
                    <!-- <span class="private">비공개</span> -->
                  </div>
                </div>
                <div class="box-info">
                  <ul class="inner-text">
                    <li>
                        <span class="txt">대회 시작일</span>
                        <span class="num">{{getChangeDateStr(item.STARTDATE)}}</span>
                    </li>
                    <li>
                        <span class="txt">총 상금</span>
                        <span class="num">{{Constant.UTIL.numberWithCommas(item.REWARD)}} 원</span>
                    </li>
                    <li>
                        <span class="txt">대회 종목</span>
                        <span class="num">{{getTradeType(item.TYPE)}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>

    </div>
    <!-- } Contents End -->

  </div>
  <!-- } Container End -->
  <Footer/>
</template>

<script>

import { useStore } from 'vuex';
import HeaderTrade from '@/components/HeaderTrade.vue';
import {useApicall} from '@/composables/commonFn';
import Footer from '@/components/Footer.vue';
import '@/assets/css/vue3-pagination.css';
import { onMounted, ref, computed } from 'vue';
import moment from 'moment';
import Constant from '@/constant.js';

export default {
  components:{  
  HeaderTrade, 
  Footer,
},
  setup(){
    const store = useStore();
    const {apiCall_g, apiCall_gt} = useApicall();
    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_eventLeague'); 
    store.commit('common/CHANGE_CURRENT_PAGE', 'EventLeague');
    const arrSpaceList = ref([]);
    const arrAttendSpaceList = ref([]);
    const arrSortSpaceList = ref([]);

    const isLogin = computed(() => store.state.user.isLogin); 

    const getSpacelist = async(status = null)=>{
      var res = null 
      if(status)
      {
          res = await apiCall_g(`index/getspacelist?status=${status}&applyType=C`);
      }
      else
      {
          res = await apiCall_g(`index/getspacelist?applyType=C`);
      }
     
      if( !res.dataList ) res.dataList = [];
      return res.dataList;  
    }  

    const getAttendSpacelist = async()=>{      
      var res = await apiCall_gt(`vspace/getaccountlist?isBeforeEnddate=Y&applyType=C`);
      if( !res.dataList ) res.dataList = [];
      return res.dataList;  
    }  

    const getTradeType =(type)=>{  
      var result = ""
      if (type == 'S') result="주식거래"
      else if(type == 'F') result="선물거래"
      else if(type == 'C') result="코인거래"
      else if(type == 'G') result="코인선물거래"

      return result;
    }

    const getChangeDateStr =(str)=>{  
       return moment(str, "YYYYMMDDhhmmss").format("YYYY.MM.DD");
    }

    const getEndDateDiff =(endDate)=>{  
        var end = moment(endDate, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss");
      //  console.log("getEndDateDiff: ", end)
        var now = moment(new Date());
        var duration = moment.duration(now.diff(end));
        //console.log(duration.asDays())
        return Math.floor(Math.abs(duration.asDays()))
     }

    const onSeletChange = (e) =>{  
          console.log("onSeletChange : ", e.target.value)
          var val = e.target.value;
          if(val == 0)
          {
            sortSpaceList()
          }
          else if(val == 1)
          {
            sortSpaceList(1)
          }
          else if(val == 2)
          {
            sortSpaceList(2)
          }
          else if(val == 3)
          {
            sortSpaceList(9)
          }
    }

    const sortSpaceList = async (status = null) =>{
        arrSortSpaceList.value = [];
        arrSpaceList.value = await getSpacelist(status==2?null:status);
        if(status == 9)
        {
          arrSortSpaceList.value = arrSpaceList.value;
          return;
        }
        if(isLogin.value)
        {

          arrAttendSpaceList.value =  await getAttendSpacelist();            
          // arrAttendSpaceList.value.forEach(_element => {
          //    //console.log("_element:", _element)
          //   const found =  arrSpaceList.value.find(element => element.ID == _element.SPACE_ID);
          //   if(found)
          //   {
          //    // console.log("found:", found)
          //     if(found.STATUS == '1')
          //     {
          //         found.isMine = true;
          //         arrSortSpaceList.value.push(found)
          //     }
          //   }           
          // });

         arrSpaceList.value.forEach(_element => {
          const found =  arrAttendSpaceList.value.find(element => element.SPACE_ID == _element.ID);
          if(found) 
          {
            if(_element.STATUS == '1') 
            {
              _element.isMine = true;
              arrSortSpaceList.value.push(_element)
            }
          }
         })
        }
        if(status == 2)
        {        
          return;
        }
        var anotherItems = [];
        arrSpaceList.value.forEach(element => {
          if(!element.isMine)
          {          
            anotherItems.push(element)
          }
        })
 
        var arr_1 =  anotherItems.filter(Symbol => Symbol.STATUS == '1');
        var arr_3 =  anotherItems.filter(Symbol => Symbol.STATUS == '3');
        var arr_9 =  anotherItems.filter(Symbol => Symbol.STATUS == '9');

        var all  = arr_1.concat(arr_3).concat(arr_9);
        arrSortSpaceList.value = arrSortSpaceList.value.concat(all);
     //   console.log("all:", arrSortSpaceList.value)  

       
    }

    onMounted(()=>{     
      setTimeout(sortSpaceList, 100);
     // console.log("arrSpaceList: ",arrSortSpaceList.value, arrAttendSpaceList.value, isLogin.value)
    }) 

    return {      
      arrSortSpaceList,
      getChangeDateStr,
      getEndDateDiff,
      getTradeType,
      Constant,
      onSeletChange
    }
  }
}
</script>

<style>

</style>