import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import globalMethods from "./globalMethods.js";
import Notifications from '@kyvg/vue3-notification'
import mitt from 'mitt';
import VueCookies from "vue-cookies";
import VueGtag from 'vue-gtag'


const emitter = mitt();
const app = createApp(App).use(router).use(store).use(globalMethods).use(Notifications).use(VueCookies)
app.config.globalProperties.emitter = emitter;

// app.use(VueGtag, {
//     config: {
//         id: 'G-M6HXV92773'  // Google Analytics의 Tracking ID
//     }
// });

app.use(
    VueGtag,
    {
      config: {
        id: 'G-M6HXV92773',
        params: {
          send_page_view: false,
        },
      },
    },
    router
  );

app.mount('#app');






