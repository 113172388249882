<template>
                <!-- 호가 start -->
                <div class="tab-pane fade show active" id="tradePriceTab" role="tabpanel" aria-labelledby="trade-price-tab">
                    <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                    <!-- 호가창 상단 시작  -->
                    <div class="trade-summary">
                    <table class="table">
                        <colgroup>
                        <col style="width: 5.9375%" />
                        <col style="width: 13.9375%" />
                        <col style="width: 14.0625%" />
                        <col style="width: 32.125%" />
                        <col style="width: 14.0625%" />
                        <col style="width: 13.9375%" />
                        <col style="width: 5.9375%" />
                        </colgroup>
                        <tr>
                        <th      colspan="7" style="height:25px;cursor:pointer " class="text-black-50 same_price_color"  @click="onClickCenter"> ⓒ 모의투자 전용입니다</th>
                        </tr>
                        <tr>
                        <th></th>
                        <th colspan="2" class="text-end text-danger">당일고가</th>
                        <th class="text-danger">{{Constant.UTIL.numberWithCommas(highPrice)}}</th>
                        <th colspan="2"></th>
                        <th></th>
                        </tr>
                    </table>
                    </div>
                    <!-- //호가창 상단 종료 -->

                    <!-- 호가창 시작  -->
                    <!-- <div class="trade-area" id="tradeArea"> -->

                    <perfect-scrollbar 
                        @ps-scroll-y="onScroll" 
                        :options="options"
                        :watch-options="watcher"
                        ref="scrollbar"
                        class="trade-area " id="tradeArea"

                    >


                        <table class="table" id="tradeInArea">
                            <colgroup>
                            <col style="width: 5.9375%" />
                            <col style="width: 13.9375%" />
                            <col style="width: 14.0625%" />
                            <col style="width: 32.125%" />
                            <col style="width: 14.0625%" />
                            <col style="width: 13.9375%" />
                            <col style="width: 5.9375%" />
                            </colgroup>
                            <tbody>
           
                            <tr   
                                v-for="(askOrder, index) in askListArr"
                                :key = askOrder.index        
                                :class="index === askListArr.length -1  ? 'down baseline' : 'down'"
                            >
                                <td class="sell top"></td>
                                <td class="bar" colspan="2">
                                    <div :style="{width: askOrder.size_pre + '%'}">-</div> 
                                    <p>{{askOrder.size == 0 ? "" : selectItem.TYPE=="C"?Constant.UTIL.numberWithCommas(Number(askOrder.size).toFixed(3)):selectItem.TYPE=="F"? Constant.UTIL.numberWithCommas(Number(askOrder.count).toFixed(0))+'  /  '+Constant.UTIL.numberWithCommas(Number(askOrder.size).toFixed(0)):Constant.UTIL.numberWithCommas(Number(askOrder.size).toFixed(0))}}</p>
                                </td>
                                <td class="up-block" id = "price-area"  @mouseover.stop="mouseOver" @mouseleave.stop="mouseLeave">
                                    <a style="cursor: pointer" @click="onPriceClick(askOrder.price)" :class="askOrder.price == tradeObj.trade_price ? 'selected' : '' ">
                                        <!-- <div class="amount"><strong :class="askOrder.colorClass"> {{ askOrder.price==0?'':selectItem.TYPE=="S"?Constant.UTIL.numberWithCommas(askOrder.price):Constant.UTIL.numberWithCommas(Number(askOrder.price).toFixed(parseInt(selectItem.NDOTPOS)))}} </strong></div> -->
                                        <div class="amount"><strong :class="askOrder.colorClass"> {{ askOrder.price==0?'':Constant.UTIL.numberWithCommas(Number(askOrder.price).toFixed(getHtsNdotpos(selectItem.TYPE, askOrder.price)))}} </strong></div>
                                        <div class="ratio" :class="askOrder.colorClass" >{{askOrder.price==0?'':isNaN(Number(askOrder.price_pre)) ? "" : Number(askOrder.price_pre).toFixed(2) + "%"}}</div>
                                        
                                    </a>
                                     <!-- <span v-show="askOrder.price == getCurrentAssetPrice(askOrder.price)"  :class="currentAsset.TRADETYPE == '4' ?'arrow-sell':currentAsset.TRADETYPE != 0?'arrow-buy':'arrow-null'"   ref="arrow_1">&#9654;</span> -->
                                     
                                </td>
                                <td colspan="2" class="trade-info">
                                   <!-- <dl> 
                                   <dt>전일대비</dt>
                                   <dd class="down">▼ 600</dd>
                                   </dl>  -->
                                    <dl                   
                                    v-if="selectItem.TYPE=='F' && askOrder.index == 9" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd" >환율</dt>
                                        <dd class="info_dd info_dl_dd same_price_color" >{{selectItem.PRODUCTCODE =='HSI'?currencyInfo.HKD_KRW+ "원":currencyInfo.USD_KRW + "원"}}</dd>
                                    </dl>

                                    <dl                   
                                    v-if="askOrder.index == 8" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd " >전일대비</dt>
                                        <dd class="info_dd info_dl_dd" :class="tradeObj.trade_price - prevClosingPrice >  0 ? 'up_price_color':'down_price_color'" >{{tradeObj.trade_price - prevClosingPrice >  0 ? "▲ " +  Constant.UTIL.numberWithCommas(Math.abs(selectItem.TYPE=="C"? tradeObj.change_price : tradeObj.change)) : "▼ " + Constant.UTIL.numberWithCommas(Math.abs(selectItem.TYPE=="C"?tradeObj.change_price:tradeObj.change))}}</dd>
                                    </dl>

                                    <dl                   
                                    v-if="askOrder.index == 7" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">등락률</dt>
                                        <dd class="info_dd info_dl_dd" :class="tradeObj.trade_price - prevClosingPrice >  0 ?'up_price_color':'down_price_color'">{{tradeObj.trade_price - prevClosingPrice >  0 ?  "+ " +  Math.abs(selectItem.TYPE=="C"?tradingPerForCoin:isNaN(tradeObj.drate)?0:tradeObj.drate).toFixed(2)  + "%"  : "- " +   Math.abs(selectItem.TYPE=="C"?tradingPerForCoin:isNaN(tradeObj.drate)?0:tradeObj.drate).toFixed(2) + "%" }}</dd>
                                        <!-- <dd class="info_dd info_dl_dd" :class="tradeObj.trade_price - prevClosingPrice >  0 ?'up_price_color':'down_price_color'">{{tradeObj.trade_price - prevClosingPrice >  0 ?  "+ " +  Math.abs(tradeObj.drate).toFixed(2) : "- " +   Math.abs(tradeObj.drate).toFixed(2)}}</dd> -->
                                    </dl>



                                    <dl                   
                                    v-if="askOrder.index == 6" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">거래량</dt>
                                        <dd class="info_dd info_dl_dd same_price_color">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(tradingVolume, 2))}}</dd>
                                    </dl>

                                    <dl                   
                                    v-if="askOrder.index == 5" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">시</dt>
                                        <dd class="info_dd info_dl_dd" :class="prevClosingPrice < openingPrice?'up_price_color':'down_price_color'">{{Constant.UTIL.numberWithCommas(Number(openingPrice).toFixed(getHtsNdotpos(selectItem.TYPE, openingPrice)))}}</dd>
                                    </dl>

                                    <dl                   
                                    v-if="askOrder.index == 4" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">고</dt>
                                        <!-- <dd class="info_dd info_dl_dd up_price_color">{{selectItem.TYPE=="F"?Constant.UTIL.numberWithCommas(Number(highPrice).toFixed(parseInt(selectItem.NDOTPOS))):Constant.UTIL.numberWithCommas(highPrice)}}</dd> -->
                                        <dd class="info_dd info_dl_dd" :class="prevClosingPrice < highPrice?'up_price_color':'down_price_color'">{{Constant.UTIL.numberWithCommas(Number(highPrice).toFixed(getHtsNdotpos(selectItem.TYPE, highPrice)))}}</dd>
                                    </dl>

                                    <dl                   
                                    v-if="askOrder.index == 3" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">저</dt>
                                        <dd class="info_dd info_dl_dd" :class="prevClosingPrice < lowPrice?'up_price_color':'down_price_color'">{{Constant.UTIL.numberWithCommas(Number(lowPrice).toFixed(getHtsNdotpos(selectItem.TYPE, lowPrice)))}}</dd>
                                    </dl>



                                    <dl                   
                                    v-if="askOrder.index == 2" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd">전일가</dt>
                                        <dd class="info_dd info_dl_dd same_price_color"  >{{Constant.UTIL.numberWithCommas(Number(prevClosingPrice).toFixed(getHtsNdotpos(selectItem.TYPE, prevClosingPrice)))}}</dd>
                                    </dl>
                                    <dl                   
                                    v-if="askOrder.index == 1" 
                                    class = "info_dl "
                                    >
                                        <dt class="info_dt info_dl_dd"  :class="selectItem.TYPE=='F'?'':'up_price_color'" >{{selectItem.TYPE=="F"?"틱가치":selectItem.TYPE=="C"?'':'상'}}</dt>
                                        <dd class="info_dd info_dl_dd"  :class="selectItem.TYPE=='F'?'same_price_color':'up_price_color'" >{{selectItem.TYPE=="S"?Constant.UTIL.numberWithCommas(Number(uplmtprice)):selectItem.TYPE=="C"?'-':getTicValueForWon()}}</dd>
                                    </dl>
                                    <dl                   
                                    v-if="askOrder.index == 0" 
                                    class = "info_dl"
                                    >
                                        <dt class="info_dt info_dl_dd" :class="selectItem.TYPE=='F'?'':'down_price_color'">{{selectItem.TYPE=="F"?"만기일":selectItem.TYPE=="C"?'':'하'}}</dt>
                                        <dd class="info_dd info_dl_dd" :class="selectItem.TYPE=='F'?'same_price_color':'down_price_color'">{{selectItem.TYPE=="S"?Constant.UTIL.numberWithCommas(Number(dnlmtprice)):selectItem.TYPE=="C"?'-':getLiquidationDate()}}</dd>
                                    </dl>

                                </td>
                                <td></td>
                            </tr>



                            <tr 
                                v-for="(bidOrder, index ) in bidListArr"
                                :key = bidOrder.index
                                class="up"  :class="index"
                            >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="down-block" id = "price-area" @mouseover="mouseOver" @mouseleave="mouseLeave">
                                    <a style="cursor: pointer" @click="onPriceClick(bidOrder.price)" :class="bidOrder.price == tradeObj.trade_price ? 'selected' : '' ">
                                        <!-- <div class="amount"><strong><strong :class="bidOrder.colorClass" >{{ bidOrder.price==0?'':selectItem.TYPE=="S"?Constant.UTIL.numberWithCommas(bidOrder.price):Constant.UTIL.numberWithCommas(Number(bidOrder.price).toFixed(Number(selectItem.NDOTPOS)))}}</strong></strong></div> -->
                                        <div class="amount"><strong><strong :class="bidOrder.colorClass" >{{ bidOrder.price==0?'':Constant.UTIL.numberWithCommas(Number(bidOrder.price).toFixed(getHtsNdotpos(selectItem.TYPE, bidOrder.price)))}}</strong></strong></div>
                                        <div class="ratio" :class="bidOrder.colorClass" >{{ bidOrder.price==0?'':isNaN(Number(bidOrder.price_pre))?"" : Number(bidOrder.price_pre).toFixed(2) + "%"}}</div>
                                    </a>
                                    <!-- <span v-show="bidOrder.price == getCurrentAssetPrice(bidOrder.price)"   :class="currentAsset.TRADETYPE == '4' ?'arrow-sell':currentAsset.TRADETYPE != 0?'arrow-buy':'arrow-null'"  ref="arrow_2">&#9654;</span> -->
                                  
                                </td>
                                <td class="bar" colspan="2">
                                    <div :style="{width: bidOrder.size_pre + '%'}">-</div> 
                                    <p>{{ bidOrder.size == 0 ? "" :    selectItem.TYPE=="C"?Constant.UTIL.numberWithCommas(Number(bidOrder.size).toFixed(3)):selectItem.TYPE=="F"?Constant.UTIL.numberWithCommas(Number(bidOrder.size).toFixed(0))+'  /  '+Constant.UTIL.numberWithCommas(Number(bidOrder.count).toFixed(0)):Constant.UTIL.numberWithCommas(Number(bidOrder.size).toFixed(0))}}</p>
                                </td>
                                <td class="buy bottom"></td>
                            </tr>
      
                            
                            </tbody>
                        </table>
                    </perfect-scrollbar>
                    <!-- //호가창 종료 -->

                    <!-- 호가창 하단 시작 -->
                    <div class="trade-summary">
                    <table class="table t2">
                        <colgroup>
                        <col style="width: 10.9375%" />
                        <col style="width: 10.9375%" />
                        <col style="width: 14.0625%" />
                        <col style="width: 28.125%" />
                        <col style="width: 14.0625%" />
                        <col style="width: 10.9375%" />
                        <col style="width: 10.9375%" />
                        </colgroup>
                        <tr>
                        <th></th>
                        <th colspan="2"></th>
                        <th class="text-primary">{{Constant.UTIL.numberWithCommas(lowPrice)}}</th>
                        <th colspan="2" class="text-primary text-start">당일저가</th>
                        <th></th>
                        </tr>
                        <tr>
                        <th></th>
                        <th colspan="2">{{currentTotalAskSize}}</th>
                        <th>{{selectItem.TYPE=="C"?`수량(${selectItem.CODE.split('-')[1]})`:`수량`}}</th>
                        <th colspan="2">{{currentTotalbidSize}}</th>
                        <th></th>
                        </tr>
                    </table>
                    </div>
                    <!-- //호가창 하단 종료 -->

                </div>
                <!-- //호가 end -->

               
</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { useStore } from 'vuex';
import { onMounted, ref,  getCurrentInstance, computed, reactive, watchEffect, onUnmounted} from 'vue';
import {useCommonFunction} from '@/composables/vtradeFn';
import Constant from '@/constant.js';
export default {
    components: {
        PerfectScrollbar
    },
    props:
    { 
       selectItem:{        
           require:true
       },
    },
    emits:['change-select-price'],
    setup(props, {emit}){
        const  trace = getCurrentInstance().appContext.config.globalProperties.$log;    
        const internalInstance = getCurrentInstance(); 
        const emitter = internalInstance.appContext.config.globalProperties.emitter;  
        const $ = require('jquery');
        const store = useStore();
        const listSize = ref(10); 
        const addSize = ref(30)
        const askListArr = reactive([]);
        const bidListArr = reactive([]);
        const orderbook = computed(() => store.state.hts.orderbook);
        const tradeObj = computed(() => store.state.hts.trade);
        const initData = computed(() => store.state.hts.initTrade);
        const currentTradeType = computed(() => store.state.hts.currentTradeType);
        const currencyInfo =  computed(()=>store.state.hts.currencyInfo)
        const scrollbar = ref(null);
  
        const currentTotalAskSize = computed(() => Constant.UTIL.numberWithCommas(Number(store.state.hts.currentTotalAskSize).toFixed(currentTradeType.value == "C"?3:0)));
        const currentTotalbidSize = computed(() => Constant.UTIL.numberWithCommas(Number(store.state.hts.currentTotalbidSize).toFixed(currentTradeType.value == "C"?3:0)));
        
        //const currentTotalAskCount = computed(() => Constant.UTIL.numberWithCommas(parseInt(store.state.hts.currentTotalAskCount)));
        //const currentTotalbidCount = computed(() => Constant.UTIL.numberWithCommas(parseInt(store.state.hts.currentTotalbidCount)));

        //const orderList = computed(() => store.state.hts.orderList);

        const intervalId = ref(null);
        const currentTime = ref("00:00:00");
        const tradingVolume = ref(0);
        const tradingPerForCoin = ref(0);
 

        const openingPrice = ref(0);
        const highPrice = ref(0);
        const lowPrice = ref(0);
        const prevClosingPrice = ref(0);


        const uplmtprice = ref(0);
        const dnlmtprice = ref(0);

    


        const {         
            getPriceGap,
            getNdotposCoin,
        } = useCommonFunction();

        const arrow_1 = ref(null);
        const arrow_2 = ref(null);
        

        const currentAsset =  computed(()=>{
           var result = {};
           var item  = store.state.hts.assetlist.find(element => element.CODE == props.selectItem.CODE);  
               
           if(item && item.PRICE && item.TRADETYPE)
           {
             return  item;
           }
           else
           {
               result.PRICE = 0;
               result.TRADETYPE = 0;
              return  result;
           }
            
        });

       const getCurrentAssetPrice = (t_price)=>{
           var result = 0;
           if(props.selectItem.TYPE == "F")
           {
               result = Number(Number(currentAsset.value.PRICE).toFixed(props.selectItem.NDOTPOS));
           }
           else
           {
                var gapPice = getPriceGap(t_price, props.selectItem); 
                var remain =   currentAsset.value.PRICE % gapPice;
                if(remain > (gapPice/2))
                {
                    result = (currentAsset.value.PRICE - remain) + gapPice;
                }
                else
                {
                    if(remain == 0)
                    {
                        result = currentAsset.value.PRICE;
                    }
                    else
                    {
                        result = currentAsset.value.PRICE - remain
                    }
                }  
           }
           //console.log("getCurrentAssetPrice :", currentAsset.value.PRICE, "result : ", result)
           return result;
       }



        const addList = () => {          
         let _size = listSize.value + addSize.value;
         for(let i =0; i<_size; i++ ){
           let obj_ask = {
              index: i,
              price: 0,
              size: 0,
              count: 0,
              size_pre: 0,
              price_pre:0,          
              colorClass: "same_price_color"
            }

            let obj_bid = {
              index: i,
              price: 0,
              size: 0,
              count:0,
              size_pre: 0,
              price_pre: 0,         
              colorClass: "same_price_color"
            }
          askListArr.push(obj_ask);
          bidListArr.push(obj_bid);
         }
          askListArr.reverse();
        }
        

        emitter.off('EVENT_SOCKET_INIT_DATA')
        emitter.on('EVENT_SOCKET_INIT_DATA', () => {    
           
            
            let val = Number(Constant.UTIL.removeTrash(initData.value.acc_trade_volume));
            let closePrice = Number(Constant.UTIL.removeTrash(initData.value.prev_closing_price));
            //let closePrice = parseInt(66600);
            
     
            let t_openingPrice = Number(Constant.UTIL.removeTrash(initData.value.opening_price));
            let t_highPrice = Number(Constant.UTIL.removeTrash(initData.value.high_price));
            let t_lowPrice = Number(Constant.UTIL.removeTrash(initData.value.low_price));    
            
            let t_uplmtprice = Number(Constant.UTIL.removeTrash(initData.value.uplmtprice));  
            let t_dnlmtprice  = Number(Constant.UTIL.removeTrash(initData.value.dnlmtprice));  



            if( props.selectItem.TYPE == "C" )
            {
               let t_per_c = (Number(Constant.UTIL.removeTrash(initData.value.trade_price)) - closePrice ) / closePrice * 100;
               if(!isNaN(t_per_c)) tradingPerForCoin.value = t_per_c;              
            } 

            if(!isNaN(t_uplmtprice)) 
            {
                uplmtprice.value = t_uplmtprice;           
            } 

            if(!isNaN(t_dnlmtprice)) 
            {
                dnlmtprice.value = t_dnlmtprice;           
            } 


              
            if(!isNaN(closePrice)) 
            {
                prevClosingPrice.value = closePrice;   
              
            } 

             tradingVolume.value = 0;
            if(!isNaN(val)) 
            {               
                 tradingVolume.value = val;
            } 


            if(!isNaN(t_openingPrice)) 
            {
                openingPrice.value = t_openingPrice;           
            } 
            if(!isNaN(t_highPrice)) 
            {
                highPrice.value = t_highPrice;           
            } 
            if(!isNaN(t_lowPrice)) 
            {
                lowPrice.value = t_lowPrice;           
            } 
                    
        })


        emitter.off('EVENT_INIT_ITEM_HTS');
        emitter.on('EVENT_INIT_ITEM_HTS', () => {
            // console.log("EVENT_INIT_ITEM_HTS^^~~~")
            scrollReset('#tradeArea');
            
            // for(var i=0; i<20; i++)
            // {                
            //    arrTradeHistory[i] = null;
            // }
            
        })

       //  emitter.off('EVENT_SCROLL_RESET_HTS');
         emitter.on('EVENT_SCROLL_RESET_HTS', () => {  
              scrollReset('#tradeArea');
         })






       watchEffect(()=>{
           let tr_vol = Number(tradeObj.value.volume);
         
           if(!isNaN(tr_vol))
           {
               tradingVolume.value = tr_vol;
           }
           else {
            
             if(!isNaN( Number(tradeObj.value.trade_volume)) && props.selectItem.TYPE == "C")
             {
                 tradingVolume.value += Number(tradeObj.value.trade_volume);
             }
           }

            if( props.selectItem.TYPE == "C" )
            {
                let t_per_c = (Number(tradeObj.value.trade_price) - Number(tradeObj.value.prev_closing_price) ) / Number(tradeObj.value.prev_closing_price) * 100;
                if(!isNaN(t_per_c)) tradingPerForCoin.value = t_per_c;              
            } 


           let tr_price = Number(tradeObj.value.trade_price);
          // let tr_volume = Number(tradeObj.value.trade_volume);
           if(!isNaN(tr_price))
           {
              // if(!isNaN(tr_volume)) push_tradePrice( tradeObj.value );              
               if(highPrice.value < tr_price) highPrice.value = tr_price;
               if(lowPrice.value > tr_price) lowPrice.value = tr_price;             
           }
       })




       const onClickCenter = ()=>{
        scrollReset('#tradeArea');
       }

        



        const scrollReset = (targetArea) => {
           
            trace("scrollReset scrollbar ",$(targetArea ).height() , $(targetArea + ' table').height())
            // console.log("scrollReset:", $(targetArea + ' table').height(), $(targetArea ).height())
            // $(targetArea).scrollTop($(targetArea + ' table').height()/2)

           // console.log("route.name", route.name)
            var oneHeight = $(targetArea + ' table').height() /  (listSize.value + addSize.value);

            oneHeight
            $(targetArea).animate(
            {                
               // scrollTop: ($(targetArea + ' table').height() / 2 - $(targetArea ).height() / 2 )+ (oneHeight/2),
                scrollTop: ($(targetArea + ' table').height() / 2   - $(targetArea ).height() / 2 ) 
            },100,  
            );
        }

        const onScroll = (e) =>{
            e
            // trace(e.target.scrollTop); 
        }




        const  getTime = () =>{
            const time = new Date();
            let hou = time.getHours();
            const hour = hou < 10 ? "0"+ hou : hou ;
            let min = time.getMinutes();
            const minutes = min < 10 ? "0"+ min : min ;
            let sec = time.getSeconds();
            const seconds = sec < 10 ? "0"+ sec : sec ;
            return  hour +":" + minutes + ":"+seconds;
        }

        intervalId.value =  setInterval(()=>{           
             currentTime.value = getTime();
        }, 1000)


         onMounted(()=>{
           addList();
           setTimeout(()=>{
              scrollReset('#tradeArea');
           }, 1000)




           watchEffect(()=>{
            

            var _size = listSize.value + addSize.value;          
            var maxSize = 0;
           
            
            if(!orderbook.value   || orderbook.value.length == 0  )return;  
           
           
            if( Number(orderbook.value[0].ask_price) == 0 && Number(orderbook.value[0].bid_price) == 0 )
            {
                var dummy_orderBook = 
                {
                    ask_count: "0",
                    ask_price: initData.value.prev_closing_price,
                    ask_size: "0",
                    bid_count: "0",
                    bid_price: initData.value.prev_closing_price,
                    bid_size: "0",

                }
                store.commit('hts/CHANGE_ORDERBOOK',  [dummy_orderBook]);               
            }
            

            if(props.selectItem.TYPE == 'F' || props.selectItem.TYPE == 'S') {
                    var centerPrice = Number(Constant.UTIL.removeTrash((orderbook.value[0].bid_price)));  
                    var  prev_closing_price =  Number(Constant.UTIL.removeTrash(initData.value.prev_closing_price));  
                    var  current_bidPrice = centerPrice;
                    for (let ii=0; ii<_size; ii++) {
                        if(ii != 0) current_bidPrice = current_bidPrice - getPriceGap(current_bidPrice -1 , props.selectItem);   
                        if(props.selectItem.TYPE == 'F') current_bidPrice = Number(current_bidPrice.toFixed(parseInt(props.selectItem.NDOTPOS)));                      
                        bidListArr[ii].price = current_bidPrice;

                       
                        let t_per = (current_bidPrice - prev_closing_price ) / prev_closing_price * 100;
                        bidListArr[ii].price_pre = t_per;
                        
                        if(t_per > 0) bidListArr[ii].colorClass = "up_price_color";
                        else if(t_per < 0) bidListArr[ii].colorClass = "down_price_color";
                        else if(t_per == 0) bidListArr[ii].colorClass = "same_price_color";

                        var  t_bidData =  orderbook.value.find(element => Number(Constant.UTIL.removeTrash(element.bid_price)) == current_bidPrice);
                        
                        if(t_bidData)
                        {
                            let b_size = Number(Constant.UTIL.removeTrash(t_bidData.bid_size));
                            bidListArr[ii].size = b_size;
                            if(props.selectItem.TYPE == 'F')  bidListArr[ii].count = Number(Constant.UTIL.removeTrash(t_bidData.bid_count)); 
                            if(maxSize < b_size)
                            {                           
                                maxSize = b_size;
                            } 
                            bidListArr[ii].isBetPossible = true; 
                        }
                        else
                        {
                            if(current_bidPrice > Number(Constant.UTIL.removeTrash((orderbook.value[orderbook.value.length-1].bid_price))))
                            {
                                 bidListArr[ii].isBetPossible = false;
                            }
                            else
                            {
                                 bidListArr[ii].isBetPossible = true;
                            }
                            bidListArr[ii].size = 0;  
                        }
                    }
                    var  current_askPrice = centerPrice;
                    for (let i=0; i<_size; i++) {
                        current_askPrice = current_askPrice + getPriceGap(current_askPrice, props.selectItem);                         
                        if(props.selectItem.TYPE == 'F') current_askPrice = Number(current_askPrice.toFixed(parseInt(props.selectItem.NDOTPOS)));
                        askListArr[_size - (i+1)].price = current_askPrice;
                        let t_per = (current_askPrice - prev_closing_price ) / prev_closing_price * 100;                        
                        askListArr[_size - (i+1)].price_pre = t_per;
                        if(t_per > 0)  askListArr[_size - (i+1)].colorClass = "up_price_color";
                        else if(t_per < 0)  askListArr[_size - (i+1)].colorClass = "down_price_color";
                        else if(t_per == 0)  askListArr[_size - (i+1)].colorClass = "same_price_color";

                        
                        var  t_askData =  orderbook.value.find(element => Number(Constant.UTIL.removeTrash(element.ask_price)) == current_askPrice);
                        
                        
                        if(t_askData)
                        {
                            let a_size =  Number(Constant.UTIL.removeTrash(t_askData.ask_size));
                            askListArr[_size - (i+1)].size = a_size;
                            if(props.selectItem.TYPE == 'F') askListArr[_size - (i+1)].count = Number(Constant.UTIL.removeTrash(t_askData.ask_count));     
                            if(maxSize < a_size)
                            {                     
                                maxSize = a_size;
                            }  
                            askListArr[_size - (i+1)].isBetPossible = true;                           
                        }
                        else
                        {
                            if(current_askPrice < Number(Constant.UTIL.removeTrash((orderbook.value[orderbook.value.length-1].ask_price))))
                            {
                                 askListArr[_size - (i+1)].isBetPossible = false;
                            }
                            else
                            {
                                 askListArr[_size - (i+1)].isBetPossible = true;
                            }
                            askListArr[_size - (i+1)].size = 0;
                        }                        
                    }
            }
            else if(props.selectItem.TYPE == 'C')
            {    
                    let current_bidPrice;
                    for (let ii=0; ii<_size; ii++) {
                        if(orderbook.value[ii])
                        {
                                let b_price = Number(Constant.UTIL.removeTrash(orderbook.value[ii].bid_price));
                                let b_size = Number(Constant.UTIL.removeTrash(orderbook.value[ii].bid_size));   
                                current_bidPrice = b_price;
    
                                
                                bidListArr[ii].price = b_price;               
                                bidListArr[ii].size = b_size;
                                if(maxSize < b_size)
                                {                           
                                    maxSize = b_size;
                                } 
                        }    
                        else
                        { 
                            current_bidPrice = current_bidPrice  - getPriceGap(current_bidPrice -1, props.selectItem);   
                            bidListArr[ii].price = Constant.UTIL.toFixedNum(current_bidPrice, 4);   
                            bidListArr[ii].size = 0;                            
                        } 
                        bidListArr[ii].isBetPossible = true; 
                        let prev_closing_price = Number(Constant.UTIL.removeTrash(initData.value.prev_closing_price));
                        let t_per = (current_bidPrice - prev_closing_price ) / prev_closing_price * 100;
                        bidListArr[ii].price_pre = t_per; 
                        if(t_per > 0) bidListArr[ii].colorClass = "up_price_color";
                        else if(t_per < 0) bidListArr[ii].colorClass = "down_price_color";
                        else if(t_per == 0) bidListArr[ii].colorClass = "same_price_color";
                    }
                    let current_askPrice;
                    for (let i=0; i<_size; i++) {
                        if(orderbook.value[i])
                        {
                            let a_price = Number(Constant.UTIL.removeTrash(orderbook.value[i].ask_price));
                            let a_size =  Number(Constant.UTIL.removeTrash(orderbook.value[i].ask_size)); 
                            current_askPrice = a_price;
                            askListArr[_size - (i+1)].price = a_price;
                            askListArr[_size - (i+1)].size = a_size;

                            if(maxSize < a_size)
                            {                     
                                maxSize = a_size;
                            }
                        }
                        else
                        { 
                            current_askPrice = current_askPrice  + getPriceGap(current_askPrice, props.selectItem);
                            askListArr[_size - (i+1)].price = Constant.UTIL.toFixedNum(current_askPrice, 4);       
                            askListArr[_size - (i+1)].size = 0;
                        }
                        askListArr[_size - (i+1)].isBetPossible = true; 
                        let prev_closing_price = Constant.UTIL.removeTrash(initData.value.prev_closing_price);
                        let t_per = (current_askPrice - prev_closing_price ) / prev_closing_price * 100;
                        askListArr[_size - (i+1)].price_pre = t_per;
                    
                        if(t_per > 0)  askListArr[_size - (i+1)].colorClass = "up_price_color";
                        else if(t_per < 0)  askListArr[_size - (i+1)].colorClass = "down_price_color";
                        else if(t_per == 0)  askListArr[_size - (i+1)].colorClass = "same_price_color";
                    }

           }
            
            
           

            for(let j = 0; j<bidListArr.length; j++)
            {
                let size = bidListArr[j].size;                
                let size_pre = size * 100 / maxSize ;
                if(isNaN(size_pre)) size_pre = 0;
                bidListArr[j].size_pre = Math.floor(size_pre);
                //console.log("bidListArr[j].size_pre", bidListArr[j].size_pre)
            }
            for(let w = 0; w<askListArr.length; w++)
            {
                let size = askListArr[w].size;                
                let size_pre = size * 100 / maxSize ;
                if(isNaN(size_pre)) size_pre = 0;
                askListArr[w].size_pre = Math.floor(size_pre);              
            }
        })
 
                       
         })

        onUnmounted (()=>{

            //console.log("EVENT_INIT_ITEM_HTS onUnmounted")
            //emitter.off('EVENT_INIT_ITEM_HTS');
            //emitter.off('EVENT_SCROLL_RESET_HTS');
            clearInterval(intervalId.value);
        })

        const onPriceClick = (price) =>{
             
              emit('change-select-price', Number(price));
        }

        const getTicValueForWon = () =>{
           
            if(props.selectItem.TYPE != "F")return;
            if(props.selectItem.SUBTYPE == 'K')
            {                
                return "12,500원"
            }
            
            if(props.selectItem.PRODUCTCODE == 'HSI')
            {                
                return Constant.UTIL.numberWithCommas(Math.floor(Number(props.selectItem.MINCHANGEAMOUNT )*Number(currencyInfo.value.HKD_KRW))) + "원"
            }
            else
            {            
               return Constant.UTIL.numberWithCommas(Math.floor(Number(props.selectItem.MINCHANGEAMOUNT )*Number(currencyInfo.value.USD_KRW))) + "원"
            }
           
        }


        const getLiquidationDate = () =>{
            if( !props.selectItem.LIQUIDATIONDATE)return;
            let year = props.selectItem.LIQUIDATIONDATE.substring(0,4);
            let mon = props.selectItem.LIQUIDATIONDATE.substring(4,6);
            let day = props.selectItem.LIQUIDATIONDATE.substring(6,8);
            return year + "." + mon + "." + day; 
        }

        const getHtsNdotpos = (type, price = null) =>{
            var result = 0;
            if(type == 'C')
            {
               result = getNdotposCoin(price)
            }
            else if(type == 'F')
            {
                result = parseInt(props.selectItem.NDOTPOS);
            }
            else if(type == 'G')
            {
                result = parseInt(props.selectItem.NDOTPOS);
            }
            else if(type == 'S')
            {
                result = 0;
            }
            return result;
        }


        return{
            options: {       
                wheelSpeed: 1,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true,
            },
            watcher: true,
            onScroll,
            scrollReset,
            tradeObj,
            askListArr,
            bidListArr,
            scrollbar,             
            currentTotalAskSize,
            currentTotalbidSize,
            currentTime,
            tradingVolume,
            openingPrice,
            highPrice,
            lowPrice,
            prevClosingPrice,
            Constant,
            uplmtprice,
            dnlmtprice,
           // arrTradeHistory,
            onPriceClick,
   
        
     
            currentTradeType,
           
            tradingPerForCoin,
            getTicValueForWon,
            getLiquidationDate,
            currencyInfo,
            //currentTotalAskCount,
            //currentTotalbidCount,
           
            currentAsset,
            arrow_1,
            arrow_2,
            getCurrentAssetPrice,
            onClickCenter,
            getHtsNdotpos
        }
    }

}
</script>

<style scoped>
.up_price_color {
    color: #ff024A!important;
}

.down_price_color {
    color: #3182F6!important;
}

.same_price_color {
    color: rgb(0, 0, 0)!important;
}
</style>