<template>
  <div id="wrap">    
    <router-view/>      
  </div>
 
  <Toast />
  <ToastFcm />

 <!-- <teleport to="#modal"> -->
    <BasicModal1b
      v-if="showModal"
      @close="closeModal" 
      @ok="okModal" 
      :titleTxt="txtTitle_alert"
      :bodyTxt="txtBody_alert"
    />
  <!-- </teleport > -->

  
</template>

<script>
import { useRouter }from 'vue-router'
import {useApicall, useCheckLogin} from '@/composables/commonFn';
import Toast from '@/components/Toast.vue';
import ToastFcm from '@/components/ToastFcm.vue';
import BasicModal1b from '@/components/popups/BasicModal1b.vue';
import { useStore } from 'vuex';
import {onMounted, getCurrentInstance, ref} from 'vue';
import VueCookies from "vue-cookies";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useToast } from '@/composables/toast';

export default {
  name: 'App',
  components: {   
   Toast,
   BasicModal1b,
   ToastFcm,
  },
  setup(){

       //console.log("test")

        const router = useRouter();
        const {apiCall_p, apiCall_pt, apiCall_g} = useApicall()
        //const router = useRouter();
        const store = useStore();
        var isLocal =  store.state.common.isLocal;
       // const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
        const showModal = ref(false);        
        const txtTitle_alert = ref('')
        const txtBody_alert = ref('')
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;

        const { checkLogin, getMember } = useCheckLogin();
      
        var fcmToken = null
        const { triggerToastFcm } = useToast();

       
           
  //      setTimeout(()=>{
    
  //   emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인후 사용가능합니다."});
  //  }, 2000)


     var talkBox = null;
     
     
      var modalOKCallbackFn = null;


       emitter.on('EVENT_CHECK_LOGIN', () => {
         // console.log("EVENT_CHECK_LOGIN:")
          checkLogin();
       })



       emitter.on('EVENT_ALERT', (data) => {
      
          txtTitle_alert.value = data.title;
          txtBody_alert.value = data.body;
          showModal.value = true;       
          if(data.fn)
          {
            modalOKCallbackFn =  data.fn;
          }
            
       })

       emitter.on('EVENT_TALKBOX_LOGOUT', () => {        
           //window.location.reload()          
          window.location.href = '/';
       })

        const setLogin = async (groundToken)=>{           
            const data =  await apiCall_p('member/tokenlogin',{"ssotoken":groundToken})
            if(data && data.returnVal == -1049)
            {
              //최초로그인
             router.push({
               name: 'LoginFirst',              
             });
              return;
            }
            
            if(!data)
            {              
               sessionStorage.removeItem('token');            
               return;
            }
            store.commit('user/CHANGE_IS_LOGIN', true);  
            store.commit('user/CHANGE_IS_PUSH_COMMENT', data.isPushComment);  
            store.commit('user/CHANGE_MEMBER_DATA', data.member);  
            sessionStorage.setItem('token', data.token); 
            store.dispatch('hts/setApplySpace'); 
            getMember();           
            talkBox.usercode = data.miniTalkCode       
            setFmc();     
           
        }

          const  minitalkOnOff = () => {            
            var frameId = document.getElementById('flexmoney');
            var frameBody = frameId.contentWindow.document.body;
            var addArrow = document.createElement("div");
            frameId.classList.add('on');
            frameBody.style.position = 'relative';
            frameBody.prepend(addArrow);
            addArrow.setAttribute('style','position:absolute; top:16px; right: 15px; width:8px; height:8px; z-index:10; border: solid #fff; border-width: 0 2px 2px 0; transform: rotate(45deg); cursor:pointer;');
            addArrow.addEventListener('click', () => {
              if(frameId.classList.contains('on')) {
                frameId.classList.remove('on');
                addArrow.style.transform = 'rotate(-135deg)';
              } else {
                frameId.classList.add('on');
                addArrow.style.transform = 'rotate(45deg)';                
              }
            });         
          }  
          




        onMounted(()=>{
         
          let query = window.location.search;
          let param = new URLSearchParams(query);
          let token = param.get('token');    
          let recommendId = param.get('recommendId');   
          let recommendMid = param.get('recommendMid');   
          if(token)VueCookies.set('cootoken', token, "3d");
          if(recommendId)VueCookies.set('coorcId', recommendId, "3d");         
          if(recommendMid)VueCookies.set('coorcMid', recommendMid, "3d");         
          var cooToken = VueCookies.get('cootoken'); 
         
          if(isLocal)
          {
              setTimeout(()=>{
                  store.dispatch('hts/setApplySpace'); 
              }, 2000);
          }

 
          var isTalkBox = true
          if(isTalkBox)
          {
            talkBox = new window.Minitalk({
            id:"flexmoney",
            channel:"flexmoney",
            width:"300",
            height:"500",
            templet:"default",           
            listeners:{
                connect:function(minitalk) {               
                minitalk.ui.printTitle("FLEXMONEY");         
                minitalkOnOff();               
              },             
            }      
           });           
          }
          
          
          getMenuRewardOnOff();
 

          if(cooToken && cooToken != null)
          {
              if(!isLocal)
              {
                  setLogin(cooToken)                
              }      
          }
          else
          {             
              sessionStorage.removeItem('token'); 
              console.log("비로그인 data: groundToken 없음") 
          }
        })


        emitter.on('EVENT_LOCALTEST_SETFMC', () => {
         // console.log("EVENT_CHECK_LOGIN:")
          setFmc();
       })



        const setFmc = () =>{

          //#1. 웹 워커 등록
          navigator.serviceWorker
            .register("/sw.js")
            .then(function (registration) {
              console.log("Service worker successfully registered.");
              return registration;
            })
            .catch(function (err) {
              console.error("Unable to register service worker.", err);
            });

          const firebaseConfig = {  //파이어베이스 프로젝트에서 가져올 수 있습니다.
            apiKey: "AIzaSyC4aimH1xUds3sOa62kIOkZDlGx0v1av58",
            authDomain: "flexmoney-b733c.firebaseapp.com",
            projectId: "flexmoney-b733c",
            storageBucket: "flexmoney-b733c.appspot.com",
            messagingSenderId: "511733610121",
            appId: "1:511733610121:web:389c5fb7976e4b1ed97858",
            measurementId: "G-520JKRHBRY"
          };

          //#2. 파이어베이스 접속
          const firebase = initializeApp(firebaseConfig);
          const messaging = getMessaging(firebase);
          //messaging.subscribeToTopic('all');
        //  console.log("firebase:", firebase, "messaging :", messaging )

        Notification.requestPermission()
        .then((permission) => {
          console.log('permission ', permission)
          //   if (permission !== 'granted') {
          //     alert('알림을 허용해주세요')
          //   }
        })

          getToken(messaging, {
            vapidKey:'BNMULNiN7cmWYqHGLdsSuXLTkGXa-CqyOResXkOnUr7g2Su-ARXKAEih0MNpbJbsaWSQ3qXsBFNAqsQ2WNgkMBk',
          })
            .then(async (token) => {
              //console.log('해당 브라우저에서의 토큰 : ', token);
              fcmToken = token;
              store.commit('user/CHANGE_FCM_TOKEN', fcmToken);  
             // console.log("tokenregister:",  fcmToken)
              if(fcmToken)
              {

                  // const registrationTokens = [
                  // fcmToken,
                  //   // ...
                  // //  'YOUR_REGISTRATION_TOKEN_n'
                  // ];


                  // getMessaging().subscribeToTopic()(registrationTokens, 'all')
                  // .then((response) => {
                  //   // See the MessagingTopicManagementResponse reference documentation
                  //   // for the contents of response.
                  //   console.log('Successfully subscribed to topic:', response);
                  // })
                  // .catch((error) => {
                  //   console.log('Error subscribing to topic:', error);
                  // });
                 

                  const res =  await apiCall_pt('fcm/tokenregister',{"fcmtoken":fcmToken, "platform" :  "web"})
                  console.log("tokenregister:",  res)
              }


            })
            .catch((err) => {
              console.log('err :', err);
            });

          //#3. 파이어베이스 메시징 기능 사용 적용
          onMessage(messaging, (payload) => {
            console.log("Message received.1111 ", payload);
            triggerToastFcm(payload);

            // if (Notification.permission === "granted") {
              //   navigator.serviceWorker.ready
                //     .then((registration) => {
                  //       console.log("registration", registration)
                  //       registration
                    //         .showNotification(payload.data.title, {
                      //           body: payload.data.body,
                      //           icon: "/favicon.ico",                      
                      //           image: payload.data.image?payload.data.image:null,
                      //           linkUrl: payload.data.linkUrl?payload.data.linkUrl:null,         
                    //         //  vibrate: [200, 100, 200, 100, 200, 100, 200],
                    //         //  tag: "vibration-sample",
                    //         })
                    //         .finally((arg) => console.log("arg:", arg));
                //     })
                //     .catch((err) => {
                  //       console.log(err);
                //     });
            // }

          });




          //#4. 브라우저 백그라운드 진입시 사용할 파일 적용
          if ("serviceWorker" in navigator) {
            navigator.serviceWorker
              .register("/firebase-messaging-sw.js")
              .then(function (registration) {
                console.log("ServiceWorker registration successful with scope: ", registration);  
              });
          }

        }
        const getMenuRewardOnOff = async() => {
          var res = await apiCall_g(`index/getsettingdetail?key=MENU_REWARD`);
          var result = res.data;
         // console.log("getMenuRewardOnOff:", result)
          if(!result) result = '';
          store.commit('common/CHANGE_MENU_REWARD_STATE', result.VALUE);
        }    


       
        const closeModal =()=>{
          showModal.value = false; 
        }

        const okModal = () =>{
          //console.log("modalOKCallbackFn", modalOKCallbackFn)
          showModal.value = false; 
          if(modalOKCallbackFn)
          {
            modalOKCallbackFn()
            modalOKCallbackFn = null;
            //console.log("modalOKCallbackFn:", modalOKCallbackFn)
          }
          
        }
    return{
      showModal,
      closeModal,
      txtBody_alert,
      txtTitle_alert,
      okModal
    }
  }
}
</script>

<style>
   

    @import  './assets/css/ui.css';
    @import  './assets/css/trade.css';
    /* @import  './assets/css/micromodal.css'; */
</style>
