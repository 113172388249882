<template>
                          <!-- 차트 영역 start -->
                          <div class="trade-chart-area">
                            <div class="chart-info-box">
                              <div class="chart-info-title">
                                <h3>{{selectItem.KORNAME}}<span class="unit">{{selectItem.CODE.split('-')[1]}}</span></h3>
                                <div class="type">원화마켓</div>
                              </div>

                              <div class="chart-info-inner coin">
                                <ul class="inner">
                                  <li :class="Constant.UTIL.getUpdownColor(tradeObj.trade_price - prevClosingPrice)">
                                    <div class="price-box">
                                      <div class="price">
                                        {{ Constant.UTIL.numberWithCommas(Number(tradePrice).toFixed(getNdotposCoin(tradePrice))) }}
                                        <span class="unit">KRW</span>
                                      </div>
                                      <div class="num">
                                        <span class="txt">전일대비</span>
                                        <span class="per">
                                          <i></i>{{Math.abs(tradingPerForCoin).toFixed(2) + '%'}}
                                        </span>
                                        <span class="rate">
                                          <i></i>{{ Constant.UTIL.numberWithCommas(Math.abs(tradeObj.trade_price - prevClosingPrice).toFixed(getNdotposCoin(tradePrice)))  }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="info-box">
                                      <div class="info-num">
                                        <div>고가</div>
                                        <div>{{Constant.UTIL.numberWithCommas(Number(highPrice).toFixed(getNdotposCoin(highPrice)))}}</div>
                                      </div>
                                      <div class="info-num">
                                        <div>저가</div>
                                        <div>{{Constant.UTIL.numberWithCommas(Number(lowPrice).toFixed(getNdotposCoin(lowPrice)))}}</div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="info-box">
                                      <div class="info-num">
                                        <div>거래량(24H)</div>
                                        <div>
                                          {{Constant.UTIL.numberWithCommas(Number(tradeVolume_24).toFixed(3))}}
                                          <span class="unit">{{selectItem.CODE.split('-')[1]}}</span>                                          
                                        </div>
                                      </div>
                                      <div class="info-num">
                                        <div>거래대금(24H)</div>
                                        <div>
                                           {{Constant.UTIL.numberWithCommas(Number(tradePrice_24).toFixed(0))}}
                                          <span class="unit">KRW</span>                                          
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div class="chart-box">
                                  <TVChartContainer  :symbol="t_symbol" symbolType="crypto" :interval="store.state.hts.chartIntrval_c"/> 
                            </div>
                          </div>
                          <!-- //차트 영역 end -->
</template>

<script>

import TVChartContainer from '@/components/trade/ChartContainer.vue';
import { version } from '../../../../public/charting_library'
import {ref, onMounted, getCurrentInstance, watchEffect, computed} from 'vue';
import { useStore } from 'vuex';
import Constant from '@/constant.js';
import {useCommonFunction} from '@/composables/vtradeFn';
export default {
   components: {
    TVChartContainer
  },
  props:{
        selectItem:{     
             type:Object,
             required: true
        },
  },
  setup(props){
    //const  trace = getCurrentInstance().appContext.config.globalProperties.$log;


    // watchEffect(()=>{    
    //   if(props.selectItem.CODE){       
    //     var _symbol = props.selectItem.CODE.split('-')[1];        
    //     t_symbol.value = `Flex:KRW/${_symbol}`;

        
    //   } 
    // })

    const {     
            getNdotposCoin,
    } = useCommonFunction();

    const t_symbol = ref(`Flex:KRW/${props.selectItem.CODE.split('-')[1]}`);
   
    const store = useStore();
    const tradeObj = computed(() => store.state.hts.trade);
    const initData = computed(() => store.state.hts.initTrade);
    const internalInstance = getCurrentInstance(); 
    const emitter = internalInstance.appContext.config.globalProperties.emitter;  

    const tradePrice = ref(0);
    const openingPrice = ref(0);
    const highPrice = ref(0);
    const lowPrice = ref(0);
    const prevClosingPrice = ref(0);
    const tradingVolume = ref(0);   
    const tradingPerForCoin = ref(0);

    const tradePrice_24 = ref(0);
    const tradeVolume_24 = ref(0);

    
    const uplmtprice = ref(0);
    const dnlmtprice = ref(0);
    
    emitter.off('EVENT_SOCKET_INIT_DATA_2')
    emitter.on('EVENT_SOCKET_INIT_DATA_2', () => {    
        
        
        let val = Number(Constant.UTIL.removeTrash(initData.value.acc_trade_volume));
        let closePrice = Number(Constant.UTIL.removeTrash(initData.value.prev_closing_price));
        //let closePrice = parseInt(66600);
        
  
        let t_openingPrice = Number(Constant.UTIL.removeTrash(initData.value.opening_price));
        let t_highPrice = Number(Constant.UTIL.removeTrash(initData.value.high_price));
        let t_lowPrice = Number(Constant.UTIL.removeTrash(initData.value.low_price));    
        
        let t_uplmtprice = Number(Constant.UTIL.removeTrash(initData.value.uplmtprice));  
        let t_dnlmtprice  = Number(Constant.UTIL.removeTrash(initData.value.dnlmtprice));  

        tradePrice.value =  Number(Constant.UTIL.removeTrash(initData.value.trade_price));


        tradePrice_24.value =  Number(Constant.UTIL.removeTrash(initData.value.acc_trade_price_24h));
        tradeVolume_24.value =  Number(Constant.UTIL.removeTrash(initData.value.acc_trade_volume_24h));

       // trace("tradePrice.value:", tradePrice.value)

        if( props.selectItem.TYPE == "C" )
        {
            let t_per_c = (Number(Constant.UTIL.removeTrash(initData.value.trade_price)) - closePrice ) / closePrice * 100;
            if(!isNaN(t_per_c)) tradingPerForCoin.value = t_per_c;              
        } 

        if(!isNaN(t_uplmtprice)) 
        {
            uplmtprice.value = t_uplmtprice;           
        } 

        if(!isNaN(t_dnlmtprice)) 
        {
            dnlmtprice.value = t_dnlmtprice;           
        } 


          
        if(!isNaN(closePrice)) 
        {
            prevClosingPrice.value = closePrice;   
          
        } 

       // trace("~~~~~~~~~~~", prevClosingPrice.value)

          tradingVolume.value = 0;
        if(!isNaN(val)) 
        {               
              tradingVolume.value = val;
        } 


        if(!isNaN(t_openingPrice)) 
        {
            openingPrice.value = t_openingPrice;           
        } 
        if(!isNaN(t_highPrice)) 
        {
            highPrice.value = t_highPrice;           
        } 
        if(!isNaN(t_lowPrice)) 
        {
            lowPrice.value = t_lowPrice;           
        } 
                
    })

   watchEffect(()=>{


           let tr_vol = Number(tradeObj.value.volume);
         
           if(!isNaN(tr_vol))
           {
               tradingVolume.value = tr_vol;
           }
           else {
            
             if(!isNaN( Number(tradeObj.value.trade_volume)) && props.selectItem.TYPE == "C")
             {
                 tradingVolume.value += Number(tradeObj.value.trade_volume);
             }
           }


        let t_per_c = (Number(tradeObj.value.trade_price) - Number(tradeObj.value.prev_closing_price) ) / Number(tradeObj.value.prev_closing_price) * 100;
        if(!isNaN(t_per_c)) tradingPerForCoin.value = t_per_c;  
   })
  





    onMounted(()=>{
     // trace("t_symbol", t_symbol.value)
     console.log('chartlibversion', version )

      watchEffect(()=>{
        try{
              let t_price = Number(Constant.UTIL.removeTrash(tradeObj.value.trade_price)); 
            if(t_price)
            {
                tradePrice.value = t_price;
            }
              //trace("tradePrice.value22:", tradePrice.value)
        }catch (e){
         // trace(e)
         console.log(e)
        }
      })


    })




    return{
        t_symbol,
        tradeObj,
        tradePrice,
        prevClosingPrice,
        Constant,
        tradingPerForCoin,
        highPrice,
        lowPrice,
        tradingVolume,
        tradePrice_24,
        tradeVolume_24,
        store,
        getNdotposCoin

    }
  }
}
</script>

<style>

</style>



