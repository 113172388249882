<template>
  <HeaderTrade/> 
  <!-- Container Start { -->
  <div id="container">
    <!-- Contents Start { -->
    <div id="contents">

      <!-- content start -->
      <div class="contents-area">
        <!-- 그라운드 top start -->
        <div class="section ground-view-top">
          <div class="title">
            {{boardItem.SUBJECT}}
          </div>
          <div class="ground-view-info">
            <!-- view info -->
            <div class="view-info-box">
              <span class="category">{{boardItem.CATEGORY_NAME}}</span>
              <span class="date">{{boardItem.CREATIONDATE}}</span>
              <span class="nickname-box">
                <UserNickGrade
                  :itemGrade="boardItem.GRADE"
                  :itemGradeRank="boardItem.GRADE_RANK"
                  :itemNickName="boardItem.MB_NICK"
                />
              </span>
              <span class="btn-box" v-if="isLogin ? boardItem.MID == myMID : '' ">
                <router-link :to="{ name:'CommunityWrite', params:{ id: boardItem.IDX }}"  class="modify">
                  수정
                </router-link>
                <a href="#" class="delete" @click="onDelete(boardItem.IDX)">삭제</a>
              </span>
            </div>
            <!-- view conunt -->
            <div class="ground-view-count">
              <span class="view">{{'조회 '+ boardItem.COUNT}}</span>
              <span class="comment">{{'댓글 ' + boardItem.COMMENT_COUNT}}</span>
              <span class="like">{{'추천 ' + boardItem.LIKE_COUNT}}</span>
              <span class="like">{{'비추천 ' + boardItem.DISLIKE_COUNT}}</span>
            </div>
          </div>
        </div>
        <!-- //그라운드 top end -->
        
        <!-- 그라운드 contents start -->
        <div class="section ground-view-contents">
          <!-- contents -->
          <div class="view-contents"  v-html="boardItem.CONTENTS">
          </div>

          <!-- 하단 버튼 영역 -->
          <div class="view-btn-box">
            <div class="btn-list-box">
              <button type="button" class="btn btn-sm" @click="onbottomClick(0)">
                <span class="ico"><img src="@/assets/images/ground/ico_list.png" alt="목록"></span>
                목록
              </button>
            </div>
            <div class="btn-like-box">
              <button
                type="button"
                class="btn btn-like"
                @click="onbottomClick(1)"
                :class="boardItem.MYACTION == 'like' ? 'active':''"
              >
                <span class="ico"><img src="@/assets/images/ground/ico_up_2.png" alt="추천"></span>
                {{boardItem.LIKE_COUNT}}
              </button>
              <button
                type="button"
                class="btn btn-blamed"
                @click="onbottomClick(2)"
                :class="boardItem.MYACTION == 'dislike' ? 'active':''"
              >
                <span class="ico"><img src="@/assets/images/ground/ico_down.png" alt="비추천"></span>
                 {{boardItem.DISLIKE_COUNT}}
              </button>
              <button
                type="button"
                class="btn btn-bookmark"
                @click="onbottomClick(3)"
                :class="isFavorate == 'Y' ? 'active':''"
              >
                <span class="ico"><img src="@/assets/images/ground/ico_pin.png" alt="북마크"></span>
              </button>
            </div>
            <div class="btn-share-box">
              <!-- <button type="button" class="btn btn-sm btn-share" data-bs-toggle="modal" data-bs-target="#modalViewShare" @click="onbottomClick(4)">
                <span class="ico"><img src="@/assets/images/ground/ico_share.png" alt="report"></span>
                공유하기
              </button> -->
              <button v-if="isLogin" type="button" class="btn btn-sm btn-report" data-bs-toggle="modal" data-bs-target="#modalViewReport" @click="onbottomClick(4)">
                <span class="ico"><img src="@/assets/images/ground/ico_siren.png" alt="report"></span>
                신고
              </button>
            </div>
          </div>

        </div>
        <!-- //그라운드 contents end -->               

        <!-- 그라운드 comment start -->
        <div class="section ground-view-comment" id="viewComment">
          <!-- count -->
          <div class="comment-count-box">
            <div class="count-box">
              <img src="@/assets/images/ground/ico_chat.png" alt="댓글">댓글
              <span class="count"> {{ boardItem.COMMENT_COUNT }} </span>
            </div>
            <div class="sort-box">
              <button @click="onCommentsort(0)" type="button" class="btn btn-sm active" ref="btnSort1">등록순</button>
              <button @click="onCommentsort(1)" type="button" class="btn btn-sm" ref="btnSort2">인기순</button>
            </div> 
          </div>

          <!-- list -->
          <div class="comment-list-box">

            <!-- 리스트 클래스 설명 
                .comment-list > li > 일반 댓글
                .comment-list > li.comment-best > 베스트 댓글
                .comment-list > li.reply > 답글
                .comment-list > li.comment-best.reply > 베스트 댓글, 답글
            -->
            <ul class="comment-list">
              <li  
                v-for="(item, index) in commentList"                     
                :key = index
              >
                <div v-if="item.ISDELETED == 'N'">
                  <!-- comment best title -->
                  <!-- <div class="best-title">
                      <span class="ico"><img src="@/assets/images/ground/ico_best2.png" alt="best"></span>
                      <span class="text-danger">베스트</span> 댓글
                  </div> -->

                  <!-- comment info -->
                  <div class="comment-info">
                    <div class="nickname-box">

                      <UserNickGrade
                        :itemGrade="item.GRADE"
                        :itemGradeRank="item.GRADE_RANK"
                        :itemNickName="item.MB_NICK"
                      />                      
                      <span class="date">{{`(${item.CREATIONDATE})`}}</span>
                      <span class="btn-inner" v-if="isLogin ? item.MID == myMID : ''">
                        <a href="javascript:;" class="delete" @click="commentDelete(item.IDX)">삭제</a>
                      </span>                            
                    </div>
                    <div class="btn-box">
                      <button
                        type="button"
                        class="btn btn-sm btn-like"
                        @click="onCommentbtnsClicked(0, item.IDX)"
                        :class="item.MYACTION == 'like' ? 'active':''"
                      >
                        <span class="ico"><img src="@/assets/images/ground/ico_up_2.png" alt="추천"></span>
                         {{item.LIKE_COUNT}} 
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-blamed"
                        @click="onCommentbtnsClicked(1, item.IDX)"
                        :class="item.MYACTION == 'dislike' ? 'active':''"
                      >
                        <span class="ico"><img src="@/assets/images/ground/ico_down.png" alt="비추천"></span>
                        {{item.DISLIKE_COUNT}} 
                      </button>
                      <!-- <button type="button" class="btn btn-sm"   >이동</button> -->
                      <button type="button" v-if="isLogin" :id="'#collapseParent' + index" class="btn btn-sm" data-bs-toggle="collapse" :data-bs-target="'#collapseComment'+index" aria-expanded="false" :aria-controls="'#collapseComment'+index" @click="replyClickHandle()">답글</button>
                      <button type="button" v-else class="btn btn-sm" @click="onCommentLogin">답글</button>
                      <!-- <button type="button" class="btn btn-sm" @click="onCommentbtnsClicked(2,  $event)">답글</button>                           -->
                      <!-- <button type="button" class="btn btn-sm btn-report" data-bs-toggle="modal" data-bs-target="#modalViewReport"  @click="onCommentbtnsClicked(3)" >신고</button> -->
                      
                    </div>
                  </div>
                  <!-- comment text -->
                  <div class="text-box">
                    <div class="text-comment" v-html="item.COMMENT.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                  </div>

                  <!-- reply start -->
                  <div class="collapse" :id="'collapseComment'+index" :data-bs-parent="'#collapseParent' + index">                        

                    <div class="comment-input-box" v-if="showReplyInput">
                        <button type="button" class="btn-close" aria-label="Close" @click="replyBoxClose('collapseComment'+index)"></button>

                      <div class="comment-input-info">
                        <div class="thum">
                          <span class="cancel" @click="setResetComments">&times;</span>
                        </div>
                        <div class="comment-info-btn">
                          <div class="info-btn" v-if="isLogin">
                            <button type="button" class="btn btn-sm" @click="onClickFileButton">이미지첨부</button>
                            <input class="d-none" type="file" id="replyInputFile" accept=".png,.jpg,.jpeg,.gif" @change="onFileChange($event)">
                          </div>
                          <span class="length">({{ replyItemLength }}/100)</span>
                        </div>
                      </div>

                      <div class="comment-input">
                        <textarea
                          class="form-control"
                          placeholder="답글을 입력해 주세요"
                          v-model="txtReply"
                          @keyup="onReplyTextLength"
                          maxlength="100"
                        ></textarea>
                          <button @click ="submitReply(item.IDX)" class="btn" type="button">등록</button>
                        </div>

                      </div>


                  </div>
                </div>
                <div v-else class="d-comm">
                  <p>삭제된 댓글입니다.</p>
                </div>

                <div
                  class="reply"
                   v-for="(item, index) in replyList[item.IDX]"                    
                   :key = index
                >
                  <div v-if="item.ISDELETED == 'N'">

                  <!-- comment info -->
                  <div class="comment-info">
                    <div class="nickname-box">
                      <UserNickGrade
                        :itemGrade="item.GRADE"
                        :itemGradeRank="item.GRADE_RANK"
                        :itemNickName="item.MB_NICK"
                      />                       
  
                      <span class="date">{{`(${item.CREATIONDATE})`}}</span>
                      <span class="btn-inner"  v-if="isLogin ? item.MID == myMID : ''">
                        <!-- <a href="javascript:;" class="modify" @click="onCommentModify('collapseComment'+index, item.COMMENT )">수정</a> -->
                        <a href="javascript:;" class="delete" @click="commentDelete(item.IDX)">삭제</a>
                      </span>                           
                    </div>
                    <div class="btn-box">
                      <button
                        type="button"
                        class="btn btn-sm btn-like"
                        @click="onCommentbtnsClicked(0, item.IDX)"
                        :class="item.MYACTION == 'like' ? 'active':''"
                      >
                        <span class="ico"><img src="@/assets/images/ground/ico_up_2.png" alt="추천"></span>
                        {{item.LIKE_COUNT}}
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-blamed"
                        @click="onCommentbtnsClicked(1, item.IDX)"
                        :class="item.MYACTION == 'dislike' ? 'active':''"
                      >
                        <span class="ico"><img src="@/assets/images/ground/ico_down.png" alt="비추천"></span>
                        {{item.DISLIKE_COUNT}}
                      </button>
                      <!-- <button type="button" class="btn btn-sm">이동</button>
                      <button type="button" class="btn btn-sm">답글</button> -->
                      <!-- <button type="button" class="btn btn-sm btn-report" data-bs-toggle="modal" data-bs-target="#modalViewReport">신고</button> -->
                    </div>
                  </div>
                  <!-- comment text -->
                  <div class="text-box">
                    <div class="text-comment" v-html="item.COMMENT.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>
                  </div>
                </div>
                <div v-else class="d-comm">
                  <p>삭제된 답글입니다.</p>
                </div>
                </div>               
              </li>

              <li v-if="!commentList.length"  class="comment-none">
                <p>댓글이 없습니다.</p>
              </li>
            </ul>
          </div>
          
          <!-- comment start -->
          <div class="comment-input-box">

            <div class="comment-input-info">
              <div class="thum">
                <span class="cancel" @click="setResetComments">&times;</span>
              </div>
              <div class="comment-info-btn">
                <div class="info-btn" v-if="isLogin">
                  <button type="button" class="btn btn-sm" @click="onClickFileButton">이미지첨부</button>
                  <input class="d-none" type="file" id="commentInputFile" accept=".png,.jpg,.jpeg,.gif" @change="onFileChange($event)">
                </div>
                <span class="length">({{ commentItemLength }}/100)</span>
              </div>
            </div>

            <div class="comment-input">
            <textarea 
              v-model="txtComment"
              @click="onCommentLogin"
              @keyup="onCommentTextLength"
              :readonly="isReadOnly"
                class="form-control"
                placeholder="댓글을 입력해 주세요"
              maxlength="100"
            ></textarea>
            <button @click ="isLogin ? submitComment() : onCommentLogin()"  class="btn" type="button">등록</button>
            </div>
          
          </div>
          <!-- //그라운드 comment input end -->

        </div>
        <!-- //그라운드 comment end -->     

        <!-- 이전글/다음글 -->
        <div class="section ground-view-bottom">
          <div class="left-btn">
            <!-- <a href="javascript:;" class="btn"><img src="@/assets/images/ground/ico_arrow_prev.png" alt="이전글">이전글</a>
            <a href="javascript:;" class="btn">다음글<img src="@/assets/images/ground/ico_arrow_next.png" alt="다음글"></a> -->
          </div>
          <div class="right-btn">
            <a href="#viewComment" class="btn"><img src="@/assets/images/ground/ico_arrow_up.png" alt="맨위로">맨위로</a>
            <router-link v-if="isLogin" :to="{ name: 'CommunityWrite'}" class="btn"><img src="@/assets/images/ground/ico_write.png" alt="글쓰기">글쓰기</router-link>
          </div>
        </div>

      </div>
      <!-- //content end -->  

      <!-- aside start -->
      <div class="aside-area">

        <CommLoginSide />
        <CommBestSide />     
        <CommPopularSide />     
        <GotradeLive />
      
      </div>
      <!-- //aside end -->


    </div>
    <!-- } Contents End -->

  </div>
  <!-- } Container End -->

  <boardDeleteModal
    v-if="showModal" 
    @close="closeModal2" 
    @cancle="closeModal2" 
    @ok="okModal" 
  />

 <Footer/>

<!-- 레이어팝업 - popup : view > 신고하기 -->
<div class="modal fade" id="modalViewReport" tabindex="-1" aria-labelledby="view-btn-report" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="view-btn-report">신고하기</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="modalReportClose" @click="closeModal"></button>
      </div>        
      <div class="modal-body">
        <label for="reSubject" class="form-label">제목</label>
        <input type="text" class="form-control" id="reSubject" v-model="reportSubject" maxlength="100" placeholder="제목을 입력해주세요.">
        <label for="reContent" class="form-label mt-4">내용</label>
        <textarea class="form-control" id="reContent" rows="5" v-model="reportContent" placeholder="내용을 입력해주세요."></textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="closeModal">취소</button>
        <button type="button" class="btn btn-danger" @click="onReportModal">신고하기</button>
      </div>
    </div>
  </div>
</div>


</template>

<script>
import { onMounted, ref,  getCurrentInstance, computed, onUnmounted } from 'vue';
import {useRoute, useRouter }from 'vue-router'
import { useStore } from 'vuex';

import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import GotradeLive from '@/components/home/GotradeLive.vue';
import CommLoginSide from '@/components/community/CommLogin_side.vue';
import CommPopularSide from '@/components/community/CommPopular_side.vue';
import CommBestSide from '@/components/community/CommBest_side.vue';
import { useApicall } from '@/composables/commonFn';
import boardDeleteModal from '@/components/popups/boardDeleteModal.vue';
import UserNickGrade from '@/components/UserNickGrade.vue';
import moment from 'moment';

export default {
  components:{  
    HeaderTrade, 
    Footer,
    GotradeLive,
    CommLoginSide,
    CommPopularSide,
    CommBestSide,    
    boardDeleteModal,
    UserNickGrade,
  },
  setup() {

    //const trace = getCurrentInstance().appContext.config.globalProperties.$log;
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const route = useRoute();
    const router = useRouter();
    const boardItem = ref({});
    const {apiCall_gt, apiCall_pt} = useApicall()
    const txtComment = ref('');
    const txtReply = ref('');

    const replyFormItem = ref('');
    const isReadOnly = ref(true);
    const store = useStore();
    const myMID = computed(()=> store.state.user.memberData.MID);
    const isLogin = computed(() => store.state.user.isLogin);

    const reportSubject = ref('');
    const reportContent = ref('');
    const modalReportClose = ref(null);
    const isFavorate = ref('');
    const showModal = ref(false);
    const boardItemId = ref('');
    const replyItemLength = ref('0');
    const commentItemLength = ref('0');
    const btnSort1 = ref(null);
    const btnSort2 = ref(null);

    const commentList = ref([]);
    const commentLikeOrder = ref([]);
    const commentKeyOrder = ref([]);    
    const replyList = ref({});
    const page_store = computed(() => store.state.common.currentPage);

    const showReplyInput = ref(false);
    const commentThumnail = ref(null);
    const thumnailTag = ref(null);

    const thumId = ref(null);
    const thumBox = ref(null);
    const thumImg = ref(null);

    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_1');
    store.commit('common/CHANGE_CURRENT_PAGE', 'Community');

    // console.log("route.params.item:^^^^", route.params, boardItem.value);

    const getBoardItem = async (id)=>{   
      const res = await apiCall_gt(`board/view?idx=${id}`);
      isFavorate.value = res.isFavorate;
      return res.data;
    }

    const getCommentList = async (id)=> {
      const res = await apiCall_gt(`board/allcommentlist?boardIdx=${id}&orderby=likeorder`);
      const result = res.dataList;

      commentList.value = result.filter( commentItem => {
         return commentItem.UPPER_IDX == null;
      }) 

      commentList.value.forEach( commentItem => {
          let replyItem = result.filter( item => {
            //  console.log("item :",  moment(item.CREATIONDATE, "YYYY-MM-DD HH:mm:ss").format('X'));
              item.timeStamp = moment(item.CREATIONDATE, "YYYY-MM-DD HH:mm:ss").format('X')
              return   commentItem.IDX ==  item.UPPER_IDX;
         })

         replyItem.sort(function (a, b) {
              if (a.timeStamp > b.timeStamp) {
                return 1;
        }
              if (a.timeStamp < b.timeStamp) {
                return -1;
      }
              return 0;
          });

          replyList.value[commentItem.IDX] = replyItem;
          // console.log('replyList.value', replyList.value[commentItem.IDX])
      })

      commentLikeOrder.value = [];
      commentKeyOrder.value = [];
      
      commentLikeOrder.value = [...commentList.value]
      commentKeyOrder.value = [...commentList.value.sort((a, b) => a.IDX - b.IDX)]
      // commentKeyOrder.value = [...commentList.value.sort((a, b) => a.IDX - b.IDX).reverse()]
      commentList.value = commentKeyOrder.value;      
    }


    onMounted(async() => {   
      emitter.emit('EVENT_CHECK_LOGIN');           
      boardItem.value = await getBoardItem(route.params.id);
      if(boardItem.value.COMMENT_COUNT !== '0') {
        getCommentList(route.params.id);
      }
      getCommunityLoginCheck();
    })

    const onbottomClick = (num) =>{
      // trace("onbottomClick :", num)
      switch(num) {
        case 0:
          router.push({
            name:'Community'
          })
          break;
        case 1:
          // 추천
          setCommunityReactions('like')
          break;
        case 2:
          //비추천
          setCommunityReactions('dislike')
          break;
        case 3:
          //bookmark
          setCommunityBookmarkadd()
          break;
        case 4:
          //신고하기
          break;
      }
    }

    const setCommunityBookmarkadd = async() => {
      if( isLogin.value ) {
        var params = {
          "boardIdx": boardItem.value.IDX,  
        }

        var res  =  await apiCall_pt('board/bookmarkadd', params, null, (message) => {
          emitter.emit('EVENT_ALERT',{title:"알림", body: message });
        });

        if(res){
          emitter.emit('EVENT_ALERT',{title:"알림", body: "북마크가 등록되었습니다.\n 마이페이지에서 확인 및 취소 가능합니다."});
          isFavorate.value = 'Y';
        }
      } else {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 이용 가능합니다."});
      }
    }
    
    const setCommunityReactions = async(_type, _commentIdx = null) => {
      if( isLogin.value ) {
        var params = {
          "boardIdx": boardItem.value.IDX,
          "type":_type
        }              
        if(_commentIdx) {
          params.commentIdx = _commentIdx
        }
        
        var res = await apiCall_pt('board/reactions', params, null, ( message ) => {
          if( message == "이미 선택을 하셨습니다." ) message = "추천/비추천 중복선택은 불가합니다."
          emitter.emit('EVENT_ALERT',{title:"알림", body:message});          
        });

        var item1 = '게시글'
        var item2 = '댓글'
        var like = '추천하였습니다.'
        var unLike = '추천을 해제하였습니다.'
        var disLike = '비추천하였습니다.'
        var unDisLike = '비추천을 해제하였습니다.'

        if (_commentIdx == null) {
          if(_type == 'like') {
            if(res.status == 'add') {
              emitter.emit('EVENT_ALERT',{ title:"알림", body: item1 + '을' + ' ' + like });
            } else if(res.status == 'del') {
              // alert(item1 + ' ' + unLike);
              emitter.emit('EVENT_ALERT',{ title:"알림", body: item1 + ' ' + unLike });
            }
          } else if(_type == 'dislike') {
            if(res.status == 'add') {
              emitter.emit('EVENT_ALERT',{ title:"알림", body: item1 + '을' + ' ' + disLike });
            } else if(res.status == 'del') {
              emitter.emit('EVENT_ALERT',{ title:"알림", body: item1 + ' ' + unDisLike });
            }
          }
        } else {
          if(_type == 'like') {
            if(res.status == 'add') {
              emitter.emit('EVENT_ALERT',{ title:"알림", body: item2 + '을' + ' ' + like });
            } else if(res.status == 'del') {
              emitter.emit('EVENT_ALERT',{ title:"알림", body: item2 + ' ' + unLike });
            }
          } else if(_type == 'dislike') {
            if(res.status == 'add') {
              emitter.emit('EVENT_ALERT',{ title:"알림", body: item2 + '을' + ' ' + disLike });
            } else if(res.status == 'del') {
              emitter.emit('EVENT_ALERT',{ title:"알림", body: item2 + ' ' + unDisLike });
            }
          }

          sortCommentList();
          btnSort1.value.classList.add('active');
        }

        if(res){
          boardItem.value = await getBoardItem(boardItem.value.IDX);
          getCommentList(boardItem.value.IDX);
        }

        document.getElementById('btnClose').focus();

      } else {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 이용 가능합니다."});
      }
    }

    const onCommentsort = (num) => {
      switch(num){
        case 0:
          sortCommentList(num);
          break;
        case 1:
          sortCommentList(num);
          break;
      }
    }

    const sortCommentList = async(num) => {
      let commentBtn = document.querySelector('.collapse');
      btnSort1.value.classList.remove('active');
      btnSort2.value.classList.remove('active');

      if(num == 0) {
        commentList.value = commentKeyOrder.value;
        btnSort1.value.classList.add('active');
      } else if (num == 1) {
        commentList.value = commentLikeOrder.value;
        btnSort2.value.classList.add('active');
      }

      if(commentBtn.classList.contains('show')) {
        commentBtn.classList.remove('show');
      } 
    }

    const onCommentbtnsClicked = (num, _idx = num) =>{
      //trace("onbottomClick :", num)
      switch(num){
        case 0:     
          //추천     
          setCommunityReactions('like', _idx)
          break;
        case 1:
          //비추천 
          setCommunityReactions('dislike', _idx)
          break;
        case 2:
          //trace("onbottomClick :", e.target.parent)
          break;
        case 3:
          break;
        case 4:
          break;
      }      
    }

    const submitComment = async () => {
      if(!txtComment.value) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"댓글 내용을 입력하여 주세요."});
        return false;
      }

      if(thumId.value) commentThumAdd('C');

      var params = {
        "boardIdx": boardItem.value.IDX,
        "comment": txtComment.value
      }

      var res = await apiCall_pt('board/commentadd', params, null, ( message ) => {
        if( message == "댓글은 ID당 3개까지만 가능합니다." ) {
          emitter.emit('EVENT_ALERT',{title:"알림", body:message});
          setResetComments(1);
          return false;
        }
      });

      if(res){ 
        setResetComments(1);
        emitter.emit('EVENT_ALERT',{title:"알림", body:"댓글 등록이 완료되었습니다."});
        boardItem.value = await getBoardItem(route.params.id);
      }

      replyClickHandle();
    }

    const submitReply = async (_id) => {
      if(!txtReply.value) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"답글 내용을 입력하여 주세요."});
        return false;
      }
      
      if(thumId.value) commentThumAdd('R');

      var params = {
        "boardIdx": boardItem.value.IDX,
        "commentIdx": _id,
        "comment": txtReply.value
      }

      var res = await apiCall_pt('board/commentadd', params, null, ( message ) => {
        if( message == "답글은 8개까지만 가능합니다." ) {
          emitter.emit('EVENT_ALERT',{title:"알림", body:message});
          setResetComments(1);
          return false;
        }
      });

      if(res){ 
        setResetComments(1);
        emitter.emit('EVENT_ALERT',{title:"알림", body:"답글 등록이 완료되었습니다."});
        boardItem.value = await getBoardItem(route.params.id);
      }

      replyClickHandle();
    }

    const deletCall = async (id) =>{     
      var res  =  await apiCall_gt(`board/delete/${id}`,'DEL')
      if(res) { 
        emitter.emit('EVENT_ALERT',{title:"알림", body:"게시글이 삭제되었습니다."});
        router.push({
          name: 'Community',
        }) 
      }
    }

    const onDelete = (id) => {
      var getId = id;
      showModal.value = true;
      boardItemId.value = getId;
    }

    const commentDeletCall = async (id) => {
      var res  =  await apiCall_gt(`board/commentdelete/${id}`, 'DEL');
      if(res){ 
        emitter.emit('EVENT_ALERT',{title:"알림", body:"댓글이 삭제되었습니다."});
        setResetComments(1);
        boardItem.value = await getBoardItem(route.params.id);
      }
      replyClickHandle();
    }

    const commentDelete = (id) =>{
      commentDeletCall(id);
    }

    const replyBoxClose = (id) => {
      let replyFormItem = document.getElementById(id);

      if(replyFormItem.classList.contains('show')) {
        replyFormItem.classList.remove('show');
        setResetComments(2);
      }
    }

    const replyClickHandle = () => {
      showReplyInput.value = true;
      let commentBtns = document.querySelectorAll('.collapse.show');

      if(isLogin.value) {
        if (commentBtns.length > 0) {
          commentBtns.forEach((commentBtn) => {
            if(commentBtn.classList.contains('show')) {
              commentBtn.classList.remove('show');
              setResetComments(2);           
            } else {
              commentBtn.classList.add('show');
            }
          });
        }
      } else {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 댓글 입력이 가능합니다."});      
      }
    }

    const onCommentLogin = () => {
      if (!isLogin.value) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 이용 가능합니다."}); 
      }
      return false;
    }

    const getCommunityLoginCheck = () => {
      var commentBtn = document.querySelector('.comment-input-box .btn');
      if (isLogin.value) {
        isReadOnly.value = false;
        commentBtn.classList.remove('read');
      } else {
        isReadOnly.value = true;
        commentBtn.classList.add('read');
      }
    }

    const closeModal = () => {
      reportSubject.value = '';
      reportContent.value = '';
    }

    const closeModal2 = () => {
      showModal.value = false; 
    }

    const onReportModal = async() => {
      //trace('onReportModal');

      if(!reportSubject.value) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"제목을 입력하여주세요."});
        return false;
      } else if (!reportContent.value) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"내용을 입력하여주세요."});
        return false;
      }

      var params = {
        "boardIdx": boardItem.value.IDX,  
        "subject": reportSubject.value,  
        "contents": reportContent.value,  
      }

      var res  =  await apiCall_pt('board/reportadd', params, null, ( message ) => {
        if( message == "이미 신고한 게시글입니다." ) message = "이미 신고한 게시글입니다."
          emitter.emit('EVENT_ALERT',{title:"알림", body:message}); 
          modalReportClose.value.click();
          return false;
      });

      if(res) {
        reportSubject.value = '';
        reportContent.value = '';
        emitter.emit('EVENT_ALERT',{title:"알림", body:"신고가 접수되었습니다."}); 
        modalReportClose.value.click();
      }
      // } else {
      //   emitter.emit('EVENT_ALERT',{title:"알림", body:"신고하기가 실패하였습니다. 다시 작성하여 주세요."}); 
      // }    
      
    }

    const okModal = async() => {
      showModal.value = false; 
      deletCall(boardItemId.value);
      boardItemId.value = '';
    }    

    const onReplyTextLength = () => {
      replyItemLength.value = txtReply.value.length;
      if(replyItemLength.value >= 100) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"최대 100자까지 입력가능합니다."});
      }
    }
      
    const onCommentTextLength = () => {
      commentItemLength.value = txtComment.value.length;
      if(commentItemLength.value >= 100) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"최대 100자까지 입력가능합니다."}); 
      }
    }

    const onClickFileButton = (e) => {
      var btn = e.target;
      btn.nextSibling.click();
    }

    const onFileChange = (e) => {
      var parentTarget = e.target.closest('.comment-input-info');

      if(thumId.value) {
        thumId.value.removeAttribute('id');
        thumId.value = null;
      }

      if(e.target.files[0]) {
        if(/\.(jpg|jpeg|png|gif)$/i.test(e.target.files[0].name)) {
          parentTarget.setAttribute('id', 'addThum');
          setFileThumnail(e);
        } else {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"이미지(png, jpg, jpeg, gif)만 가능합니다."});
          return false;
        }
      } else {
          parentTarget.setAttribute('id', 'addThum');
          return false;        
      }

      e.target.value = '';
    }

    const setFileThumnail = (file) => {
      thumId.value = document.getElementById('addThum');
      thumBox.value = document.querySelector('#addThum .thum');

      if(thumImg.value) thumImg.value.remove();

      thumImg.value = null;

      var reader = new FileReader();
      reader.onload = function(e) {
        var img = document.createElement("img");
        img.setAttribute('src', e.target.result);
        img.setAttribute('id', 'addImg');
        thumBox.value.append(img);
        thumImg.value = document.getElementById('addImg');
      };

      if(file.target.files[0]) {
        reader.readAsDataURL(file.target.files[0]);
      } else {
        setResetComments();
        return false;
      }
    }

    const commentThumAdd = (type) => {
      commentThumnail.value = '';
      thumnailTag.value = '';
      commentThumnail.value = thumImg.value.src;  
      thumnailTag.value = '<p><img src=' + '"' + commentThumnail.value + '"' + '></p>'

      if(type == 'C') {
        txtComment.value = thumnailTag.value += txtComment.value;
      } else {
        txtReply.value = thumnailTag.value += txtReply.value;
      }
    }

    const setResetComments = (type) => {
      // type 1 - 댓글/코멘트 type - 2 답글만
      let collapseButton = document.querySelector('.collapse');

      if( type == 1 ) {
        getCommentList(boardItem.value.IDX);
        txtComment.value = ''
        commentItemLength.value = '0'
        txtReply.value = ''
        replyItemLength.value = '0'
        btnSort1.value.classList.add('active');
        btnSort2.value.classList.remove('active');

        if(collapseButton.classList.contains('.show')) {
          collapseButton.classList.remove('show');
        }
      } else if( type == 2 ) {
        txtReply.value = ''
        replyItemLength.value= '0'
      }
      
      if(type || type == 'null') {
        if(thumId.value == null) thumId.value = document.getElementById('addThum');

        if(thumId.value || thumImg.value || thumBox.value) {
          thumId.value.removeAttribute('id');
          thumImg.value.remove();
          thumId.value = null;
          thumBox.value = null;
          thumImg.value = null;
        }
      }

    }

    onUnmounted(()=> {
      if(page_store.value !== 'Community') {
        store.commit('common/CHANGE_CUMMUNITY_CURRENT_PAGE', 1);
      }
    })

    return {
      boardItem,
      commentList,
      onbottomClick,
      onCommentsort,
      onCommentbtnsClicked,
      txtComment,
      submitComment,
      txtReply,
      submitReply,
      // onModify,
      onDelete,
      store,
      myMID,
      replyFormItem,
      replyBoxClose,
      commentDelete,
      replyClickHandle,
      isLogin,
      onCommentLogin,
      isReadOnly,
      reportSubject,
      reportContent,
      onReportModal,
      closeModal,
      modalReportClose,
      isFavorate,
      showModal,
      boardItemId,
      okModal,
      closeModal2,
      replyItemLength,
      commentItemLength,
      onReplyTextLength,
      onCommentTextLength,
      btnSort1,
      btnSort2,
      replyList,
      commentLikeOrder,
      commentKeyOrder,
      page_store,
      onClickFileButton,
      onFileChange,
      setResetComments,
      showReplyInput,
      commentThumnail,
      thumnailTag,
      thumId,
      thumBox,
      thumImg,
    }
  }
}
</script>

<style>

</style>