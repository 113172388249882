<template>   
    <Modal> 
        <template #title>
             대회신청하기
        </template>

        <template #body>
            <div class="sts-apply-popup">
            <p class="mb-3">
                <span>{{currentItem.NAME}}</span> 대회를 참가하시겠습니까?
            </p>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="Y" id="chk1"   v-model="check" @input="onCheckInput" checked="checked" disabled>
                <label class="form-check-label" for="chk1">
                [필수] 대회 참여(대회 일정 및 상금 수령)를 위해 개인정보처리방침 및 이벤트 수신에 동의합니다.
                <!-- 대회 일정 및 상금 수령을 위해 서비스 관련 정보<br>수신에 동의합니다. -->
                </label>
            </div>
            </div>
        </template>

        <template #footer>
            <button
                type="button" 
                class="btn btn-outline-secondary" 
                @click="onCancle"
            >
                아니오
            </button>
            <button 
                type="button" 
                class="btn btn-danger"
                @click="onOk"
            >
                네
            </button>
        </template>     
    </Modal>
    
</template>

<script>
import Modal from "@/components/popups/Modal.vue"
import {ref} from 'vue'
export default {
  components:{
      Modal
  },
  props:{
    currentItem: {    
      type: Object,
      require:true
    },  
  },
  emits:['cancle', 'ok'],
  setup(props, {emit}){
  
     const check = ref(true)
    //  setInterval(()=>{
    //       console.log("check:!", check.value)
    //  }, 1000)
     const onCancle = () =>{
      
        emit('cancle')
     }

     const onOk = () =>{
        emit('ok')
     }
     const onCheckInput = () =>{
       check.value;
     }
     
     return {
         onCancle,
         onOk,
         props,
         check,
        onCheckInput
     }
   }

}
</script>

<style>

</style>