<template>
<!-- Header Start { -->
      <div id="header">
        <div
          class="header-area"
          :class="[ 
            currentPage == 'TRADE' ? 'sts-head':'',
            headAreaClass,
          ]"
        >

          <div class="gnb-area">
            <h1 class="logo">
              <router-link :to="{ name: 'Home'}"><img src="@/assets/images/common/logo.svg" alt="flexmoney"></router-link>
              <span class="logo-flextv"><a :href="`https://www.flextv.co.kr/redirects/signin?token=${cootoken}&redirectTo=/`" target="_blank"><img src="@/assets/images/common/logo_flextv.png" alt="flextv"></a></span>
            </h1>
            
            <!-- nav start -->
            <nav class="nav-area">
              <ul class="nav">
                <li>
                  <router-link :to="{ name: 'Home' }" id="navi_0" :class="currentSelect=='navi_0'?'on':''">
                    상시모의투자
                    <span class="nav-ico-sts"><img src="@/assets/images/common/ico_gnb_sts.png" alt="상시모의투자"></span>
                  </router-link>
                                    
                  <ul class="depth-2">
                    <li><router-link :to="{ name: 'Home' }">상시모의투자</router-link></li>
                    <li class="w70px"><router-link :to="{ name: 'Ranking' }">랭킹</router-link></li>
                    <li><router-link :to="{ name: 'VtradeStock', params:{id:'0'} }">주식거래</router-link></li>
                    <li><router-link :to="{ name: 'VtradeFutures',  params:{id:'0'} }">선물거래</router-link></li>
                    <li><router-link :to="{ name: 'VtradeCoin',  params:{id:'0'} }">코인거래</router-link></li>
                    <li><router-link :to="{ name: 'VtradeCoinFutures',  params:{id:'0'} }">코인선물</router-link></li>
                    <li><a href="javascript:;" @click="onClickTradeResult" :class="currentPage =='TradeResult'?'router-link-active':''">투자내역</a></li>
                    <li v-if="menuRewardOnOff == 'Y' "><a href="javascript:;" @click="onClickTradeReward" :class="currentPage =='TradeReward'?'router-link-active':''">상금수령</a></li>
                  </ul>
                </li>
                <li>
                  <router-link :to="{ name: 'EventLeague' }" id="navi_event" :class="currentSelect=='navi_eventLeague' || currentSelect=='navi_eventLeague_trade' ?'on':''">플렉스리그</router-link>
                  
                  <ul class="depth-2 e-league" v-if="currentSelect=='navi_eventLeague_trade'">
                    <li><router-link :to="{ name: 'EventLeague' }">대회리스트</router-link></li>
                    <li >  
                      <div class="league-select-title" v-if="mySpaceList.length>0 && route.params.id">
                        <select class="form-select"  @change="onSeletChange" >
                          <option
                                v-for="(item, index) in mySpaceList"      
                                :key = index
                                :value="item.ID+'^'+item.TYPE"
                                :selected="item.ID == route.params.id"
                          >{{item.NAME}}
                          </option>
                          <!-- <option value="0" selected="selected">플렉스머니배 제 1회 주식대회</option>
                          <option value="1">플렉스머니배 제 1회 선물대회</option> -->
                        </select>
                      </div>
                    </li>
                  </ul>
                </li>
                <li><router-link :to="{ name: 'Community' }" id="navi_1" :class="currentSelect=='navi_1'?'on':''">커뮤니티</router-link></li>
                <li><a :href="`https://www.flextv.co.kr/redirects/signin?token=${cootoken}&redirectTo=/`" target="_blank">FLEXTV</a></li> 
                <li><router-link :to="{ name:'Notice' }" :class="currentSelect=='navi_notice'?'on':''">공지사항</router-link></li> 
                <!-- <li><a href="javascript:;" @click="onClickQna" :class="currentSelect=='navi_Qna'?'on':''">1:1문의</a></li> -->
              </ul>
            </nav>

            <ul class="util" v-if="!isLogin">
              <li @click="onSignin"><router-link :to="{ name: '' }" class="btn btn-outline-secondary btn-sm">로그인</router-link></li>
              <li @click="onSignup"><router-link :to="{ name: ''}" class="btn btn-outline-secondary btn-sm">회원가입</router-link></li>  
            </ul>

            <ul class="util" v-else>
              <li>
                <UserNickGrade v-if="memberData"
                  :itemGrade="memberData.GRADE"
                  :itemGradeRank="memberData.GRADE_RANK"
                  :itemNickName="memberData.NICK"
                />
              </li>
              <li><a href="javascript:;" @click="onClickQna" class="btn btn-outline-secondary btn-sm">1:1문의</a></li>
              <li @click="onMypage" ><router-link :to="{ name: ''}" class="btn btn-outline-secondary btn-sm">마이페이지</router-link></li>
              <li @click="onSignout"><button type="button" class="btn btn-outline-secondary btn-sm">로그아웃</button></li>
            </ul>

            <div class="header-depth-bg"></div>
          </div>


        </div>
      </div>
      <!-- } Header End -->  
</template>

<script>
import Config from '../../public/config.js';
//import Config from '@/config.js';
import axios from '@/axios';
import { useStore } from 'vuex';
import { useRouter, useRoute }from 'vue-router'
import {computed, getCurrentInstance, onMounted, ref, watchEffect } from 'vue'
import {useApicall, useCheckLogin} from '@/composables/commonFn';
import VueCookies from "vue-cookies";

import UserNickGrade from '@/components/UserNickGrade.vue';


export default {
  components:{   
    UserNickGrade,
 },  
   setup(){
    const route = useRoute();
    //const trace = getCurrentInstance().appContext.config.globalProperties.$log;
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const store = useStore();
    const router = useRouter();
    const isLogin = computed(() => store.state.user.isLogin);
    const {apiCall_p, apiCall_gt, apiCall_pt} = useApicall();
    const isLocal =  store.state.common.isLocal;
    const { getMember } = useCheckLogin();
    const cootoken = ref(VueCookies.get('cootoken'));
    const currentSelect = computed(() => store.state.trade.currentPageId);
    const currentPage = computed(() => store.state.common.currentPage);
    const mySpaceList = ref([]);

    const menuRewardOnOff = computed(() => store.state.common.menuRewardState);

    const memberData = computed(()=> store.state.user.memberData)

    //console.log("ddddddddddddddddddddddd~~~~~~~~~~~~~~~~~", route.params.id)
  //  setInterval(()=>{
  //       console.log("menuRewardOnOff:~~~~~~~~~~~~~~~~~~~", menuRewardOnOff.value)
  //  },10000)

    onMounted(()=>{
        watchEffect(async()=>{
          if(currentSelect.value == "navi_eventLeague_trade")
          {
            
            if(isLogin.value)
            {              
               mySpaceList.value = await getmyspacelist();             
            }
            
          }
        })

         getMember();
    })
      

    const getmyspacelist = async () =>{
      var result = [];
      var res =  await apiCall_gt(`vspace/getmyspacelist?isBeforeEnddate=Y&applyType=C`);      
      if( !res.dataList )
      {
        result = [];
      } 
      else
      {
          res.dataList.forEach(element => {
            if(element.STATUS == '1')
            {
              result.push(element)
            }
          });
      }
      return result;
    }

    //checkLogin();
  
    const login_to_flexlife =  (_id, _pass)  =>{  
              var param = {}
              param.pid = "flexmoney";
              param.mid = _id;
              param.passwd = _pass;


            axios.post(Config.URL.apiLifeUrl +'member/partnerlogin', param)
            .then(async function (response) {
                let json = response.data;	           
                if (json.returnCode == 1) {
                          if(json.resultMap.returnVal == 1)
                          {                 
                            // console.log("login_to_server:", json.resultMap)      
                             sessionStorage.setItem('groundToken', json.resultMap.token);      
                             // window.location.reload()    
                             var groundToken = sessionStorage.getItem('groundToken');
                             const data = await apiCall_p('member/tokenlogin',{"ssotoken":groundToken})
                            // console.log("llllllllllllldata:", data.miniTalkCode)
                             sessionStorage.setItem('miniTalkCode', data.miniTalkCode); 
                             store.commit('user/CHANGE_IS_LOGIN', true);  
                             store.commit('user/CHANGE_MEMBER_DATA', data.member); 
                             sessionStorage.setItem('token', data.token);
                             store.dispatch('hts/setApplySpace'); 
                             
                          }    
                          else
                          {
                              alert(json.message);
                          }
                }
                else 
                {
                          alert(json.message);			
                }
            })
            .catch (function (error) {
              console.log(error)
            })
            .then(function () {
                // always executed
            });
    }



    const onSignin =()=>{
     
       //openWin(Config.URL.flexLifeUrl + 'plogin/login.html', 'login', 800, 500)
       //for test
      
       if(isLocal)
       {
                 login_to_flexlife('woo0050', 'test4858!')
                // login_to_flexlife('rangboq', 'dune77**')
               //  login_to_flexlife('dodo01', '1q2w3e4r!')
               setTimeout(()=>{
                      emitter.emit('EVENT_LOCALTEST_SETFMC');
               },5000)
             
       }
       else
       {
               location.href = Config.URL.flexLifeUrl + `login?app=money&redirect_url=${Config.URL.flexMoneyUrl}`;
       }
    }

    const onSignout = async ()=>{
      
      // apiCall_gt('member/logout')
       console.log('onSignout1' );       
       emitter.emit('EVENT_ALERT',{title:"알림", body:"로그아웃 되었습니다."});
       if(store.state.user.fcmtoken)
       {
           var res =  await apiCall_pt('fcm/tokendeletepost', {'fcmtoken': store.state.user.fcmtoken})
           console.log('tokendeletepost', res );
       } 

       if(await apiCall_gt('member/logout'))
       {
           console.log('onSignout2' );
        //sessionStorage.removeItem('groundToken');
          VueCookies.remove('cootoken')
          sessionStorage.removeItem('token'); 
          store.commit('hts/CHANGE_ORDERLIST', []);  
          store.commit('hts/CHANGE_ASSETLIST', []);
          store.commit('user/CHANGE_MEMBER_DATA', null);
          store.commit('user/CHANGE_IS_LOGIN', false);

          
          
          router.push({
              name: 'Home',              
          });
          emitter.emit('EVENT_TALKBOX_LOGOUT');
         
       }

    }
    const onMypage =()=>{
       
        router.push({
                name: 'MyPage',              
        });
    }


    const onSignup = ()=>{
      location.href = Config.URL.flexLifeUrl + `certificate?page=Signup&app=money&redirect_url=${Config.URL.flexMoneyUrl}`;
    }

    const onClickQna = () => {
        if (isLogin.value) {
          router.push({
            name:'Qna'
          });
        } else {
          emitter.emit('EVENT_ALERT',{title:"알림", body: "로그인 후 문의 가능합니다."});
        }
    } 

    const onClickTradeResult = () => {
        if (isLogin.value) {
          router.push({
            name:'TradeResult'
          });
        } else {
          emitter.emit('EVENT_ALERT',{title:"알림", body: "로그인 후 입장 가능합니다."});
        }
    }

    const onClickTradeReward = () => {
        if (isLogin.value) {
          router.push({
            name:'TradeReward'
          });
        } else {
          emitter.emit('EVENT_ALERT',{title:"알림", body: "로그인 후 입장 가능합니다."});
        }
    }

    const headAreaClass = computed(() => {
      // console.log(currentPage.value)
      if( currentSelect.value == 'navi_0' || currentSelect.value == 'navi_eventLeague' || currentSelect.value == 'navi_eventLeague_trade') {
        return 'on';
      } else {
        return ''
      }
    });    


    const onSeletChange = (e) =>{  
          console.log("onSeletChange : ", e.target.value);
          var arrData = e.target.value.split('^');
          var spaceId = arrData[0];
          var type  = arrData[1];
          var name = '';
          if(type == "S")
          {
            name = 'VtradeStock';
          }
          else if(type == "F")
          {
            name = 'VtradeFutures';
          }
          else if(type == "C")
          {
            name = 'VtradeCoin';
          }
          else if(type == "G")
          {
            name = 'VtradeCoinFutures';
          }
        

          router.replace({
            name: name,
            params: {
              id:spaceId
            }
          })  
          
    }


    // const openWin =  (pUrl, pName, pW, pH) => {
    //   var winPop = window.open(pUrl, pName,'width='+pW+',height='+pH+',top=0,left=0,toolbars=no,menubars=no,scrollbars=no');
    //   winPop.focus();
    // } 

  //  const onNaviClicked = (e) =>{
  //   var btn = e.target;   
  //   var tName =''
  //   //store.commit('trade/CHANGE_CURRENT_PAGE_ID', btn.id); 
  //   switch(btn.id)
  //   {
  //     case 'navi_0':
  //       tName = 'Home'
  //       break;
  //     case 'navi_1':
  //       tName = 'Ranking'
  //       break;
  //     case 'navi_2':
  //       tName = 'VtradeStock'
  //       break;
  //     case 'navi_3':
  //       tName = 'VtradeFutures'
  //       break;
  //     case 'navi_4':
  //       tName = 'VtradeCoin'
  //       break;
  //     case 'navi_5':
  //       tName = 'VtradeCoinFutures'
  //       break;
  //     case 'navi_6':
  //        tName = 'Community'
  //       break;
  //   }

  //   if(tName){
      
  //     trace("onNaviClicked", tName)
  //       router.push({
  //           name: tName,              
  //       });

  //   }


  //  }

    return{
       isLogin,
       onSignin,
       onSignout,    
       onMypage,
       store,
      //  onNaviClicked,
       currentSelect,
       currentPage,
       onSignup,
       cootoken,
       onClickQna,
       onClickTradeResult,
       headAreaClass,
       mySpaceList,
       onSeletChange,
       route,
       onClickTradeReward,
       menuRewardOnOff,
       memberData
    }
   }
}
</script>

<style>

</style>