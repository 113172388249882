//import { reactive } from 'vue';
import axios from '@/axios';
import { useScoket } from '@/socket';
//import { useToast } from '@/composables/toast';

const getStartCurrentTradeType = () =>{
    let startType = localStorage.getItem('currentTradeType')
    if(!startType) startType = 'S'
    return startType;
}

const getStartCurrentTradeItem = () =>{
    let startItem = JSON.parse(localStorage.getItem('currentItem'));
    if(!startItem) startItem = null;
    return startItem;
}


const getStartCurrencyInfo = () =>{
    let startItem = JSON.parse(sessionStorage.getItem('currencyInfo'));
    if(!startItem) startItem = {"USD_KRW": "0.00", "HKD_KRW": "0.00"};
    return startItem;
}

export default {
    namespaced: true,
    state: {
        orderbook: [],
        orderbook_a: [],
        orderbook_b: [],
        trade: {},
        initTrade: {},
        currentTotalAskSize: 0,
        currentTotalbidSize: 0,
        //currentTotalAskCount: 0,
        //currentTotalbidCount: 0,
        //currentPriceGap:0,
        orderList: [],
        assetlist:[],
       // htsSellBtns:[],
      //  htsBuyBtns:[],       
        currentTradeType:getStartCurrentTradeType(),
        currentSpaceId:null,
        currentAccountId:null,
        currentItem:getStartCurrentTradeItem(),
        allTrade:{},
        rightMenuIndex:1,
        currencyInfo:getStartCurrencyInfo(),
        currentBalance: {BALANCE: "0.00", PAYMONEY: "0.00", RECOUNT: "0"},
        currentTotalPrice:0,
        currentTotalProfit:0,
        currentTotalPol:{},
        currentFees:0,
        currentFuturePossibleCnt:{},
        currentPossibleBalance:0,
        currentLosscut:0,
        periodInitData:{},
       // periodInitDataKeep:{},
        changeAssetOrder:0,
        currentItemDatas:[],
        currentSpaceName:'',
        retryCount:0,
        reCount:0,
        chartIntrval_s:'60',
        chartIntrval_f:'60',
        chartIntrval_c:'60',
        chartIntrval_g:'60',
        applySpaceList:[]

    },
    mutations: {
        CHANGE_APPLY_SPACE_LIST(state, payload) {          
            state.applySpaceList = payload;
        },
        CHANGE_CHART_INTERVAL_S(state, payload) {          
            state.chartIntrval_s = payload;
        },
        CHANGE_CHART_INTERVAL_F(state, payload) {
            state.chartIntrval_f = payload;
        },
        CHANGE_CHART_INTERVAL_C(state, payload) {
            state.chartIntrval_c = payload;
        },
        CHANGE_CHART_INTERVAL_G(state, payload) {
            state.chartIntrval_g = payload;
        },
          



        CHANGE_RETRY_COUNT(state, payload) {
            state.retryCount = payload;
        },
        CHANGE_RE_COUNT(state, payload) {
            state.reCount = payload;
        },  
        CHANGE_CURRENT_SPACE_NAME(state, payload) {
            state.currentSpaceName = payload;
        },
        CHANGE_CURRENT_ITEM_DATAS(state, payload) {
            state.currentItemDatas = payload;
        },
        // CHANGE_PERIOD_INIT_DATA_KEEP(state, payload) {
        //     console.log("CHANGE_PERIOD_INIT_DATA_KEEP:", payload.code )
        //     state.periodInitDataKeep[payload.code] = payload;
        // },
        CHANGE_PERIOD_INIT_DATA(state, payload) {
            state.periodInitData = payload;
        },
        CHANGE_CURRENT_LOSSCUT(state, payload) {
            state.currentLosscut = payload;
        },
        CHANGE_POSSIBLE_BALANCE(state, payload) {
            state.currentPossibleBalance = payload;
        },
        CHANGE_FUTURE_POSSIBLE_COUNT(state, payload) {
            state.currentFuturePossibleCnt = payload;
        },
        CHANGE_ORDERBOOK(state, payload) {
            state.orderbook = payload;
        },
        CHANGE_ORDERBOOK_A(state, payload) {
            state.orderbook_a = payload;
        },
        CHANGE_ORDERBOOK_B(state, payload) {
            state.orderbook_b = payload;
        },
        CHANGE_TEADE(state, payload) {
            state.trade = payload;
            if(!state.trade.price)
            {
                if(payload.lastPrice)state.trade.price = payload.lastPrice;
                if(payload.p)state.trade.price =  payload.p;
            }
             //console.log("state.trade111 :", state.trade)            
            if(!state.trade.code)state.trade.code = payload.symbol
            if(!state.trade.size)state.trade.size = payload.v

           //console.log("state.trade :", state.trade)

        },
        CHANGE_INIT_TEADE(state, payload) {     
            state.trade = payload;
            if(!state.trade.price)
            {
                if(payload.lastPrice)state.trade.price = payload.lastPrice;
                if(payload.p)state.trade.price =  payload.p;
            }
            if(!state.trade.code)state.trade.code = payload.symbol
            state.initTrade = payload;
        },
        CHANGE_TOTAL_ASK_SIZE(state, payload) {
            state.currentTotalAskSize = Number(payload);            
        },
        CHANGE_TOTAL_BID_SIZE(state, payload) {
            state.currentTotalbidSize = Number(payload);            
        },
        // CHANGE_TOTAL_ASK_COUNT(state, payload) {
        //     state.currentTotalAskCount = Number(payload);            
        // },
        // CHANGE_TOTAL_BID_COUNT(state, payload) {
        //     state.currentTotalbidCount = Number(payload);            
        // },
        CHANGE_ORDERLIST(state, payload) {
            if(!payload)  state.orderList = [];
            else  state.orderList = payload;
           
        },
        CHANGE_ASSETLIST(state, payload) {
            if(!payload) state.assetlist = [];
            else state.assetlist = payload;
        },  
        CHANGE_TRADE_TYPE(state, payload){
            state.currentTradeType = payload;
            localStorage.setItem('currentTradeType', payload)
        },
        CHANGE_SPACE_ID(state, payload){
            state.currentSpaceId = payload;
        },
        CHANGE_ACCOUNT_ID(state, payload){
            state.currentAccountId = payload;
        },
        
        CHANGE_CURRENT_ITEM(state, payload){
            state.currentItem = payload;
            localStorage.setItem('currentItem', JSON.stringify(payload));
        },
        CHANGE_ALL_TEADE(state, payload) {
            state.allTrade = payload;
            //console.log("CHANGE_ALL_TEADE", payload)
            if(!state.allTrade.price)
            {
                if(payload.lastPrice)state.allTrade.price = payload.lastPrice;
                if(payload.p)state.allTrade.price = payload.p;
            }           
            if(!state.allTrade.code)state.allTrade.code = payload.symbol            

            //console.log("aaaaa:",  state.allTrade)
        },

        CHANGE_RIGHT_MENU(state, payload) {
            state.rightMenuIndex = payload;
        },
        CHANGE_CURRENCY_INFO(state, payload) {           
            state.currencyInfo = payload;
            sessionStorage.setItem('currencyInfo',  JSON.stringify(payload))
        },
        CHANGE_CURRENT_BALANCE(state, payload) {           
            state.currentBalance = payload;
        },
        CHANGE_TOTAL_PRICE(state, payload) {
            //console.log("dddddddddddddddddd", payload )           
            state.currentTotalPrice = payload;
        },
        CHANGE_TOTAL_PROFIT(state, payload) {           
            state.currentTotalProfit = payload;
        },
        CHANGE_TOTAL_POL(state, payload) {           
            state.currentTotalPol = payload;
        },
        CHANGE_CURRENT_FEES(state, payload) {           
            state.currentFees = payload;
        },
  
        
        
    },
    actions: {

        leaveAssetItems({state}){ 
            const {         
                socket
            } = useScoket(); 
           
            
            for(let i=0; i<state.assetlist.length; i++)
            {
                var item = state.assetlist[i];
                //console.log("leaveTrade~111", state.currentItem.TYPE ) 
                socket.emit("leaveTrade", {
                    itemType:  state.currentItem.TYPE,
                    code: item.CODE,                   
                }, function() {                        
                })
            }
        },
        requesAssetItems({commit, state}){ 
            //if(!state.currentItem)return;
            //console.log("requestAllitems :", state.currentItem)
            const {         
                socket
            } = useScoket(); 
           
            // socket.emit("leaveItemType", {
            //     itemType: 'A'   //S(주식),F(선물),C(코인),A(모두)
            //   }, function(data) {
            //     data
            //     //  console.log("stopAllItem", data);
            // })

            
            
            var assetInitArr = []
            setTimeout(()=>{
                for(let i=0; i<state.assetlist.length; i++)
                {
                    var item = state.assetlist[i];
                     var assetInitData = 
                     { "drate" :  "0" , 
                       "trade_price" : Number(item.CURRENT),
                       "code": item.CODE,
                       "price": Number(item.CURRENT)
                     }
                    // console.log("requestTrade~111", state.currentItem.TYPE ) 
                    if(item.KIND == "S" || item.KIND == "C" || item.KIND == "G" )
                    {
                       assetInitData.itemType = item.KIND;
                    }
                    else
                    {
                       assetInitData.itemType = 'F';                           
                    }

                    assetInitArr.push(assetInitData);                   

                 
                    socket.emit("requestTrade", {
                        itemType:  state.currentItem.TYPE,
                        code: item.CODE,                      
                    }, function() {                        
                    })
                }

                for(let ii=0; ii<assetInitArr.length; ii++)
                {
                   // console.log("aaaaaa~", assetInitArr ) 
                    setTimeout(()=>{
                        commit('CHANGE_ALL_TEADE', assetInitArr.shift()) 
                    }, ii * 200)                    
                }
            },1500)
        },
        async getOrderList({  commit, state })
        {
            if(state.currentSpaceId == null)return;
            var token = sessionStorage.getItem('token');  
            try{
                
                const  res = await axios.get(`vspace/getorderlist?spaceId=${state.currentSpaceId}`, {
                    headers: {
                        'token': token
                   }
                });   
               
                let json =  res.data;    
              //  console.log("getOrderList json", json);            
                if (json.returnCode == 1) {
                    if (json.resultMap.returnVal == 1) {  
                        //triggerToast('Successfully get order list');
                        if(!json.resultMap.dataList) json.resultMap.dataList = [];
                        commit('CHANGE_ORDERLIST', json.resultMap.dataList);
                        
                        if(json.resultMap.dataList.length > 0)
                        {
                            json.resultMap.dataList.forEach(element =>
                            {
                                element.checked =  false;                           
                            } );
        
                        }
                        //dispatch("syncOrderListBnts") 
                    } else {
                        //triggerToast(json.message, 'danger');
                        commit('CHANGE_ORDERLIST', []);
                        console.log(json.message);
                    }
                }
                else if(json.returnCode == -102 )
                {
                    console.log("로그인이 필요합니다.");
                }
                else
                {
               // triggerToast(json.message, 'danger');
                     console.log(json.message);
                }               
            }catch(error){
               // triggerToast('Something went wrong!', 'danger');
                console.log(error);
            }  
        },

        async getAssetList({ dispatch, commit, state })
        { 
            if(state.currentSpaceId == null)return;
            var token = sessionStorage.getItem('token');
            //console.log("getAssetList token : ", token)
            
      
            try{
                const  res = await axios.get(`vspace/getassetlist?spaceId=${state.currentSpaceId}`, {
                    headers: {
                        'token': token
                   }
                });   
                let json =  res.data;    
                //console.log("getAssetList json", json);            
                if (json.returnCode == 1) {
                    if (json.resultMap.returnVal == 1) {  
                        //triggerToast('Successfully get order list');
                        if(!json.resultMap.dataList) json.resultMap.dataList = [];
                        commit('CHANGE_ASSETLIST', json.resultMap.dataList);
                        commit('CHANGE_TOTAL_POL', json.resultMap.dataSum);
                      
                        if(json.resultMap.dataList.length > 0)
                        {
                            json.resultMap.dataList.forEach(element =>
                            {
                                element.checked =  false;                           
                            } );
        
                        }
                        //console.log("CHANGE_TOTAL_POL~~~~~~~~~~", json.resultMap)                        
                        if(state.currentTradeType == 'F')
                        {
                            dispatch("setPossibleOrder")
                            var losscut = 0
                            if(json.resultMap.dataList)
                            {
                                json.resultMap.dataList.forEach(element =>{
                                   // console.log(element);
                                     losscut += Number(element.LOSSCUT)*parseInt(element.COUNT);
                                })
                            }
                            commit('CHANGE_CURRENT_LOSSCUT', losscut);
                            commit('CHANGE_TOTAL_POL', json.resultMap.dataSum);                            
                        }  

                        dispatch("requesAssetItems");

                    } else {
                       
                        
                        commit('CHANGE_ASSETLIST', []);
                        //console.log(json.message);
                        dispatch("requesAssetItems");          
                    }
                }
                else if(json.returnCode == -102 )
                {
                    console.log("로그인이 필요합니다.");
                }
                else
                {
               // triggerToast(json.message, 'danger');
                console.log(json.message);
                }               
            }catch(error){
              // triggerToast('Something went wrong!', 'danger');
                console.log(error);
            }  
        },       

        async setPossibleOrder ({state, commit}){ 
        

            if(state.currentSpaceId == null)return;
           var token = sessionStorage.getItem('token');
           try{         
              const res = await axios.get(`vspace/getAvailableorder?type=F&code=${state.currentItem.CODE}&spaceId=${state.currentSpaceId}`,  {
                 headers: {
                      'token': token
                 }
              });
              let json =  res.data; 
              if (json.returnCode == 1) {
                 if (json.resultMap.returnVal == 1) {    
                     
                       if(json.resultMap.data)
                       {
                        //  console.log("setPossibleOrder:", json.resultMap.data)
                          commit('CHANGE_FUTURE_POSSIBLE_COUNT', json.resultMap.data);                 
                       }   
                     //  triggerToast('Successfully setCurrentACCOUNTId' + json.resultMap.data);                   
                 } else {                    
                      // triggerToast(json.message , 'danger'); 
                 }
              }
              else if(json.returnCode == -102 )
              {
                console.log("로그인이 필요합니다.");
              }    
               else
              {
                // triggerToast(json.message , 'danger');
                 console.log("error", json.message);               
              }  
           }catch(error){     
              console.log(error);
              //triggerToast(error, 'danger');   
                 
              return false;           
           }  
        },

        async setApplySpace ({commit}){ 
        
           
          
           var token = sessionStorage.getItem('token');
         //  console.log("setApplySpace:",  token)
           try{         
              const res = await axios.get(`vspace/getaccountlist?isBeforeEnddate=Y`,  {
                 headers: {
                      'token': token
                 }
              });
              let json =  res.data; 
              if (json.returnCode == 1) {
                 if (json.resultMap.returnVal == 1) {    
                     
                       if(json.resultMap.dataList)
                       {
                         // console.log("setApplySpace:", json.resultMap.dataList)
                          commit('CHANGE_APPLY_SPACE_LIST', json.resultMap.dataList);                 
                       }   
                     //  triggerToast('Successfully setCurrentACCOUNTId' + json.resultMap.data);                   
                 } else {                    
                      // triggerToast(json.message , 'danger'); 
                 }
              }
              else if(json.returnCode == -102 )
              {
                console.log("로그인이 필요합니다.");
              }    
               else
              {
                // triggerToast(json.message , 'danger');
                 console.log("error", json.message);               
              }  
           }catch(error){     
              console.log(error);
              //triggerToast(error, 'danger');   
                 
              return false;           
           }  
        },
        
            
           
            
       
        // triggerToast({ commit }, message, type = 'success') {            
        //     commit('ADD_TOAST', {
        //         id: Date.now(),
        //         message: message,
        //         type: type,
        //     });


        //     setTimeout(() => {
        //         commit('REMOVE_TOAST')                  
        //     }, 10000)
        // }
    },
    //computed 와 동일한기능..
    getters: {
        // toastMessageWithSmile(state) {
        //     return state.toasts.message + "^^~~~";
        // }
    },
}