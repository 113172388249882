//import Config from '@/config.js'
import Config from '../public/config.js'
import axios from 'axios';



export default axios.create({
    withCredentials: true,
    baseURL: Config.URL.apiGroundUrl,
})




export const axiosFlex =  axios.create({
    withCredentials: true,
    baseURL: Config.URL.apiFlextvUrl,
})
