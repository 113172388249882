<template>
          <!-- 모의투자 : 메인 > 안내 -->
          <div class="sts-main-banner">
            <div class="banner-inner">
              <div class="banner-inner-box">
                     <h2>플렉스머니 <strong class="text-danger">베타오픈</strong></h2>
                <p class="title">주식, 선물, 코인 모의투자 거래를<br><strong>단 하나의 플랫폼에서</strong></p>
                <h3>
                  <span class="ico"><img src="@/assets/images/trade/trophy.png" alt="trophy"></span>
                  진행중인 모의투자 대회
                </h3>
                <div class="banner-select">
                  <select class="form-select form-select-lg" aria-label="진행중인 모의투자 대회" @change="onSeletChange"  ref="rSeletBox">
                     <option v-if="vContestData.length == 0">
                      {{'진행중인 모의투자 대회가 없습니다.'}}
                     </option>
                    <option  
                        v-for="(item, index) in vContestData"      
                        :key = index
                        :value="item.ID+'^'+item.TYPE+'^'+item._isApply+'^'+item._recount+'^'+item._retrycount+'^'+index" 
                        
                        >{{item.NAME + (item._isApply?item._isApply==1?' (참가대기중)':' (참가중)':'')}}
                    </option>
                    <!-- <option value="2">오픈 기념 모의투자 대회(마감)</option> -->

                  </select>
                  <button @click="onDetailInfo"     type="button" class="btn btn-detail">상세정보</button>
                </div>
                <div class="banner-info">
                  <ul>
                    <li>플렉스머니 모의투자 신청 시 현재 진행중인 대회에 자동으로 참가됩니다.</li>
                    <li>매월 개최되는 모의투자 대회에서 상위에 입상하고 다양한 특전과 혜택을 받아보세요.</li>
                    <li>모의투자 거래 상품별 나의 순위를 실시간으로 확인하세요.</li>
                  </ul>
                </div>
                <div class="banner-btn">
                  <a href="#" class="btn btn-lg"  @click="onRegisterTrade"    ref="btn_apply_ok">모의투자 신청하기</a>
                  <a href="#" class="btn btn-lg" @click="onGotoTrade">거래하기</a>
                  <button type="button" class="btn btn-lg"  @click="onClickRetry_retry">
                    재도전
                    <!-- {{rSeletBox.value.split('^')[3]}} -->
                    <!-- <span>({{rSeletBox.value.split('^')[3]+'/'+(rSeletBox.value.split('^')[4]=='-1'?'무한':rSeletBox.value.split('^')[4])}})</span> -->
                    
                    <span>({{ currentResetData[0]+'/'+ (currentResetData[1]=='-1'?'무한':currentResetData[1])}})</span>
                  </button>
                </div>
              </div>
              <div class="banner-inner-box w-50">
                <span class="main-img"><img :src="`${mainVisualUrl}`" alt="main"></span>
              </div>

            </div>
          </div>

    <RetryModal
      v-if="showModal_retry" 
      @close="closeModal_retry" 
      @cancle="closeModal_retry" 
      @ok="okModal_retry" 
      :currentSpaceName="currentSelectItem.NAME"
      :recount="currentResetData[0]"
      :retrycount="currentResetData[1]"
     />
 
     <ApplyModal
        v-if="showModal" 
        @close="closeModal" 
        @cancle="closeModal" 
        @ok="okModal" 
        :currentItem="currentSelectItem"
     />

   
     
  

</template>

<script>

import {useApicall} from '@/composables/commonFn';
import {ref, getCurrentInstance, onMounted, computed, watch} from 'vue';
import { useRouter }from 'vue-router'
import { useStore } from 'vuex';
import moment from 'moment';
import ApplyModal from '@/components/popups/ApplyModal.vue';
import RetryModal from '@/components/popups/RetryModal.vue';
import Config from '../../../public/config.js';
import { event } from 'vue-gtag'


export default {
   components: { 
     ApplyModal,
     RetryModal
  },
   setup(){   
    const showModal_retry = ref(false)
   // const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
    const {apiCall_g, apiCall_gt, apiCall_pt} = useApicall();
    const vContestData = ref([]);
    const rSeletBox = ref(null);
     const store = useStore();
    const showModal = ref(false);  
    const router = useRouter();
    const internalInstance = getCurrentInstance(); 
    const emitter = internalInstance.appContext.config.globalProperties.emitter; 
   
    const isLogin = computed(() => store.state.user.isLogin);
    const memberData = computed(()=> store.state.user.memberData)
    const btn_apply_ok = ref(null)
    const currentResetData = ref([0,0]);
    const isChecked = ref(false);

    const currentSelectItem = ref(null);
    const mainVisualUrl = ref(Config.URL.flexMoneyUrl + 'uploads/main/main_visual.png');
   
    const getVContestData = async()=>{
        var res = await apiCall_g(`index/getspacelist?status=1&applyType=A`);
        vContestData.value = res.dataList;
        
        if(vContestData.value == null)vContestData.value=[];

        currentSelectItem.value = vContestData.value[0];
       
        
        if(isLogin.value)
        {
           vContestData.value.forEach(async (element) => {
           var currnetTimeStp = moment(new Date()).format('X');
           //  var applyStratTimeStp = moment( element.APPLYSTARTDATE, 'YYYYMMDDHHmmss' ).format('X');
           //  var applyEndTimeStp = moment( element.APPLYENDDATE, 'YYYYMMDDHHmmss' ).format('X');
             var stratTimeStp = moment( element.STARTDATE, 'YYYYMMDDHHmmss' ).format('X');
            // var eTimeStp = moment( element.ENDDATE, 'YYYYMMDDHHmmss' ).format('X');

             var res = await apiCall_gt(`vspace/getaccountbyspaceid?spaceId=${element.ID}`)
            
             if(res)
             {      
                // 신청기간 참여 오픈전
                if(currnetTimeStp < stratTimeStp)
                {
                  // 신청하고 오픈전
                    element._isApply = 1; 
                }
                else
                {
                  //신청하고 거래가능
                    element._isApply = 2; 
                }
                element._recount = res.data.RECOUNT;
                element._retrycount = res.data.RETRYCOUNT;
                element._accountId = res.data.ID;
              //  trace("vContestData.value:", res.data)
              
                
                // if( res.data.ISUSED == 'Y')
                // {
                //   element._isApply = true; 
                // }
             }
             
          });
        }
    }
    onMounted(()=>{
       //  trace('TmainTop')
         getVContestData()
          setTimeout(()=>{
             setRetrybtn()
          }, 1000)

    })

    const setApplyButton = () =>{
      // trace("rSeletBox:", rSeletBox.value.value.split('^')[3])
       var str = rSeletBox.value.value.split('^')[2];
       if(str == 1  || str == 2){
          btn_apply_ok.value.text = '신청 완료'
          
       }
       else
       {
          btn_apply_ok.value.text = '모의투자 신청하기'
       }
    }
    setTimeout(()=>{
         setApplyButton()
    },1000)
   
    const onSeletChange = () =>{     
        
        currentSelectItem.value = vContestData.value[rSeletBox.value.value.split("^")[5]];
       // trace("currentSelectItem.value:", currentSelectItem.value)
        setApplyButton();
        setRetrybtn()
      
    }


    const setRetrybtn = ()=>{
      if( rSeletBox.value && rSeletBox.value.value)
      {
        if( rSeletBox.value.value.split('^')[3] != 'undefined'  && rSeletBox.value.value.split('^')[3] != undefined && rSeletBox.value.value.split('^')[3] != null && rSeletBox.value.value.split('^')[3] != '')
        {
            currentResetData.value[0] =  rSeletBox.value.value.split('^')[3];
        }

        if( rSeletBox.value.value.split('^')[4] != 'undefined'  && rSeletBox.value.value.split('^')[4] != undefined && rSeletBox.value.value.split('^')[4] != null && rSeletBox.value.value.split('^')[4] != '')
        {
            currentResetData.value[1] =  rSeletBox.value.value.split('^')[4];
        }
      }
    }
   


    watch(isLogin, ()=>{
       // console.log("isLogin", isLogin.value )
         getVContestData()
    })


    const goLoginPage = ()=>{   
      location.href = Config.URL.flexLifeUrl + `login?app=money&redirect_url=${Config.URL.flexMoneyUrl}`;
    }



    const onRegisterTrade =  () =>{
        if(!isLogin.value)
        {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.", fn:goLoginPage});
          return;
        }

        if(vContestData.value.length == 0)
        {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"개최중인 대회가 없습니다."});
          return;
        }


        var str = rSeletBox.value.value.split('^')[2];
        if(str == 1  || str == 2){
            emitter.emit('EVENT_ALERT',{title:"알림", body:"이미 신청한 상태입니다."});
            return;
        }
        else
        {
            showModal.value = true;
        }



      
        

    }

    const onGotoTrade = () =>{
        if(!isLogin.value)
        {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.",fn:goLoginPage});
          return;
        }
        var type = rSeletBox.value.value.split('^')[1]; 
        //trace("onGotoTrade", type, rSeletBox.value.value)
        var name = '';
        if(type == "S")
        {
          name = 'VtradeStock';
        }
        else if(type == "F")
        {
          name = 'VtradeFutures';
        }
        else if(type == "C")
        {
          name = 'VtradeCoin';
        }
        else if(type == "G")
        {
          name = 'VtradeCoinFutures';
        }
       

        router.push({
           name: name,
           params: {
            //  id:rSeletBox.value.value.split('^')[0]
            id:'0'
           }
      }) 

    }


    const onDetailInfo = () =>{
      var currentSpaceId = rSeletBox.value.value.split('^')[0];
      vContestData.value.forEach(element => {
        
        if(element.ID == currentSpaceId){
         // console.log("eeee", element.INFO_LINK_URL,  element.INFO_IMG_URL)
          if(element.INFO_LINK_URL == null)return;
           window.open( element.INFO_LINK_URL); 
        }
        
      });
    }
    
      const closeModal =()=>{
          showModal.value = false; 
       }

    const okModal = async() =>{
        showModal.value = false; 

        var id = rSeletBox.value.value.split('^')[0];
        var params = { "spaceId": id}
        var res =  await apiCall_pt('vspace/apply', params, null, (message)=>{
           emitter.emit('EVENT_ALERT',{title:"알림", body:message});
        });
        if(res)
        {
            emitter.emit('EVENT_ALERT',{title:"알림", body:"모의투자에 성공적으로 신청되었습니다."});
            store.dispatch('hts/setApplySpace'); 
            getVContestData();
            event('participate', { 'ID': memberData.value.MID, 'competitions_name': 'main_' + getSpaceTypeStr(res.spaceType), 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss')});
            window.fbq('trackCustom', 'participate', {'ID': memberData.value.MID,  'competitions_name': 'main_' + getSpaceTypeStr(res.spaceType) , 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss') });
            console.log("event~ :", memberData.value.MID, 'competitions_name', 'main_' + getSpaceTypeStr(res.spaceType),  moment(new Date()).format('YYYYMMDD HH:mm:ss'))

        }
    }

    const getSpaceTypeStr = (_type) =>{
    var result = null;
     if(_type == 'S')
     {
        result = 'Stock'
     }
     else if(_type == 'F')
     {
        result = 'Futures'
     }
     else if(_type == 'C')
     {
        result = 'Crypto'
     }
     else if(_type == 'G')
     {
        result = 'CoinFutures'
     }
     return result
     
    }


    const closeModal_retry =()=>{
          showModal_retry.value = false; 
    }

    const okModal_retry = async() =>{
        showModal_retry.value = false; 
        //var accountId = rSeletBox.value.value.split('^')[0]
        var res = await apiCall_pt(`vspace/retrySpace`, {"accountId": currentSelectItem.value._accountId },null,(_message)=>{
           emitter.emit('EVENT_ALERT',{title:"알림", body:_message});
         });
         //console.log("onRetryOk:", res)
         if(!res)return         
         emitter.emit('EVENT_ALERT',{title:"알림", body:"초기화 되었습니다. 새로고침 합니다.", fn:()=>{window.location.reload()}});
    }  

    const onClickRetry_retry =()=>{
        if(!isLogin.value)
        {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.", fn:goLoginPage});
             return
        }

         if(currentSelectItem.value._isApply != '2')
        {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"대회참가 후 사용가능합니다."});
             return
        }

        showModal_retry.value = true; 

    }



    return{
       vContestData,
       onSeletChange,
       rSeletBox,
       onRegisterTrade,
       onGotoTrade,
       onDetailInfo,
       btn_apply_ok,
       currentResetData,
       showModal,
       closeModal,
       okModal,
       isChecked,
       currentSelectItem,
       showModal_retry,
       closeModal_retry,
       okModal_retry,
       onClickRetry_retry,
       mainVisualUrl
    }
   }
}
</script>

<style>

</style>