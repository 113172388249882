<template>
                    <div class="mypage-main-inner" v-if="myPostList.length">
                      <ul class="inner-list">
                        <li
                          v-for="(item, index) in myPostList"
                          :key = index
                        >
                          <div class="item-box">
                            <p class="title-box">

                              <!-- 타입별 텍스트 컬러 변경 -->
                              <!-- <span class="type">
                                <span class="text-black">나의 게시글</span>
                              </span> -->
                              
                              <span class="title">
                                <router-link :to="{ name:'CommunityView',  params:{ id:item.IDX }}">
                                  {{item.SUBJECT}}
                                </router-link>
                              </span>
                            </p>
                            <span class="category">{{ item.CATEGORY_NAME }}</span>
                            <span class="date">{{ item.CREATIONDATE }}<span class="pipe">|</span>좋아요 {{ item.LIKE_COUNT }}</span>
                          </div>
                        </li>
                      </ul>
                    </div>                    
                    <div v-else class="mypage-main-null">게시글이 없습니다.</div>
                      
                    <!-- 페이징 start-->
                    <div class="section paging-area pb-1 mb-0" v-if="myPostList.length">
                      <v-pagination                  
                        v-model="currentPage"
                        :pages= numberOfPages
                        :rangeSize="1"
                        activeColor="#ff5d8b"                   
                        @update:modelValue="updateHandler(currentPage)"
                      />
                    </div>
                    <!-- //페이징 end -->  

</template>

<script>
import { ref, computed, watch } from 'vue';
import {useApicall} from '@/composables/commonFn';
import VPagination from "@hennge/vue3-pagination";
import '@/assets/css/vue3-pagination.css';

export default {
  props: {
    itemType: {
      type:String,
      required: true,
    },
  },
  components: {
    VPagination,
  },

  setup(props) {
    const { apiCall_gt } = useApicall();

    const limit = ref(10);
    const offset = ref(0);
    const currentPage = ref(1);     
    const numberOfData = ref(0);
    const numberOfPages = computed(()=>{
      return Math.ceil(numberOfData.value/limit.value)
    });
    
    const myPostList = ref([]);
    const propsItemType = computed(()=> props.itemType)

    const updateHandler = (e) => {
      getMyPostList(e);
    }

    const getMyPostList = async(page = currentPage.value) => {
      currentPage.value = page;
      var offset = (currentPage.value -1) * limit.value;
      var getUrl = `board/list?categoryType=community&isMine=Y&limit=${limit.value}&offset=${offset}`

      var postDataList = await apiCall_gt(getUrl);

      try {
        if(postDataList.dataList == null) postDataList.dataList = [];
        myPostList.value = postDataList.dataList;
        numberOfData.value = postDataList.dataCount;
      } catch(error) {
        myPostList.value = [];
        numberOfData.value  = 0;
      }
    }
    
    watch(propsItemType, ()=> {      
      if(propsItemType.value == 'post') {
        updateHandler(1);
      } else {
        return false;
      }
     })

    return{
      limit,
      offset,
      myPostList,
      numberOfPages,
      currentPage,
      updateHandler,
      propsItemType,
    }
  }
}
</script>

<style>

</style>