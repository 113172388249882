import {
	getHistoryKey_qry,
	getHistoryKey,
	makeApiRequest,
	generateSymbol,
	//parseFullSymbol,
} from './helpers.js';
import {
	subscribeOnStream,
	unsubscribeFromStream,
	socketId
} from './streaming.js';
import moment from 'moment';
//import { useStore } from 'vuex';
import Constant from '@/constant.js';





var allSymbolsCache = null;
const lastBarsCache = new Map();
var m_lastDate = null;
var m_lastTime = null;
const configurationData = {
	//supported_resolutions: ['1D', '1W', '1M'],
	//supported_resolutions: ["1", "3", "5",'10', "30", "60", "1D", "1W"],
	supported_resolutions: ["1", "3", "5",  "15", "30", "60", "240", "1D", "1W", "1M"],
	exchanges: [
	{
		// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
		value: 'Flex',

		// filter name
		name: 'Flex',

		// full exchange name displayed in the filter popup
		desc: 'Flex',
	},
	],
	symbols_types: [
	{
		name: 'stock',
		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
		value: 'stock',
	},
		// ...
	],
	supports_time : true
};

async function getAllSymbols() {

	if(allSymbolsCache == null)
	{

		const data = await makeApiRequest('index/getitemlist?type=S');
		let allSymbols = [];
		for (const item of data.resultMap.dataList) {   
			//var pairPart = item.CODE.split("-")
			const symbol = generateSymbol('Flex', item.CODE, 'KR');
			var sObj = {
				symbol: symbol.short,
				full_name: symbol.full,
				description:  item.NAME,
				exchange:'Flex',
				type: 'stock',
				CODE: item.CODE,
				NAME: item.NAME
			}
			allSymbols.push(sObj)
		}		
		//console.log("allSymbols:", allSymbols)
		allSymbolsCache = allSymbols
	}	
	return allSymbolsCache;
}

export default {
	onReady: (callback) => {
		//console.log('[onReady]: Method call');
		setTimeout(() => callback(configurationData));
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	) => {
		//console.log('[searchSymbols]: Method call');
		const symbols = await getAllSymbols();
		const newSymbols = symbols.filter(symbol => {
			const isExchangeValid = exchange === '' || symbol.exchange === exchange;
			const isFullSymbolContainsInput = symbol.full_name
				.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;
			return isExchangeValid && isFullSymbolContainsInput;
		});
		onResultReadyCallback(newSymbols);
	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
	) => {
		//console.log('[resolveSymbol]: Method call', symbolName);		
		const symbols = await getAllSymbols();
		const symbolItem = symbols.find(({
			full_name,
		}) => full_name === symbolName);
		if (!symbolItem) {
			//console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
			onResolveErrorCallback('cannot resolve symbol');
			return;
		}
		const symbolInfo = {
			ticker: symbolItem.full_name,
			name: symbolItem.symbol,
			description: symbolItem.description,
			type: symbolItem.type,
			//session: '0900-1530',
			session: '24x7',
			timezone: 'Etc/UTC',
			//timezone: 'Asia/Seoul',	
			exchange: symbolItem.exchange,
			minmov: 1,
			pricescale: 1,
			has_intraday: true,
			visible_plots_set: 'ohlcv',
			has_weekly_and_monthly: true,
			supported_resolutions: configurationData.supported_resolutions,
			has_empty_bars:false,
			volume_precision: 2,
			data_status: 'streaming',
			code:symbolItem.CODE,
			
		};

		//console.log('[resolveSymbol]: Symbol resolved', symbolName, "symbolInfo:", symbolInfo);
		onSymbolResolvedCallback(symbolInfo);
	},

	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest, countBack } = periodParams;

		//console.log('[resolveSymbol]: getBars ~~~  ', symbolInfo.name);
		var count =  countBack + 1;
		var limit = 2000
		if(count > limit) count = limit;

		

		from
		moment
		//console.log('[getBars]: Method call', symbolInfo, resolution, from, to ,"countBack:", countBack,"socketId:",socketId);		
		//console.log("arrData:~~~stock 요청", firstDataRequest,  "to:", moment(to, "X").format("YYYY-MM-DD  HH:mm:ss") , "countBack:", countBack, n_strCTSDate, n_strCTSTime )
		//console.log("arrData:~~~stock 답변", firstDataRequest,  "from:" , moment(arrData[0].timestamp, "X").format("YYYY-MM-DD  HH:mm:ss") , "to:", moment(arrData[arrData.length - 1].timestamp, "X").format("YYYY-MM-DD HH:mm:ss") )
		// console.log('[getBars]: Method call', symbolInfo, resolution, from, to ,"countBack:", countBack,"socketId:",socketId);
		//const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
		//console.log('[getBars]: parsedSymbol', parsedSymbol);

        var res  = '';
		
		try {

			if(firstDataRequest)
			{				
				res  = await  getHistoryKey("S" ,socketId, symbolInfo.code, count, to, resolution);
			}
			else
			{
				
				if(m_lastDate && m_lastTime){

					let  n_lastDate =  m_lastDate //moment(to, "X").format("YYYYMMDD");
					let  n_lastTime =  m_lastTime //moment(to, "X").format("HHmmss");

					if(n_lastTime.length < 6)
					{
						n_lastTime = Constant.UTIL.fillZero(6, n_lastTime);  
					}
	
					//console.log("arrData:^^^^^^^000000000000~~~~~~~~~", "n_lastDate:", n_lastDate , moment(to, "X").format("YYYYMMDD") , "n_lastTime :", n_lastTime,moment(to, "X").format("HHmmss"))
					res  = await  getHistoryKey_qry("S" ,socketId, symbolInfo.code, count, to, resolution, n_lastDate, n_lastTime);
					n_lastDate = null;
					n_lastTime = null;
				}
			}


			//console.log("aaaaaaaaaaaaaaaa", res, firstDataRequest)
            var returnCandle= () =>{
				console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx111111rrrr")
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}

			//console.log("res.data.resultMap.datainfo.count : ",res.data.resultMap.datainfo.count)
			if ( res.data.resultMap.returnVal != 1  || !res.data.resultMap.nodeArray || res.data.resultMap.nodeArray.length === 0  || res.data.resultMap.datainfo.count <= 0 ) {
				// "noData" should be set if there is no data in the requested period.
				console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx111111sss")
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}

            var arrData = res.data.resultMap.nodeArray;


			var addPriceCandle = async (nDate, nTime, count) =>{				
				if(count > limit) count = limit;
				//console.log("arrData:^^^^^^^11111111", "nDate:", nDate , "nTime :", nTime, "count:", count)
				//console.log("arrData:^^^^^^^11111111", "nDate:", nDate , "nTime :", nTime, "count:", count)
				var moreData = await  getHistoryKey_qry("S" ,socketId, symbolInfo.code, count, to, resolution, nDate, nTime);
                console.log("moreData.data.resultMap.datainfo.count : ", moreData.data.resultMap.datainfo.count)
                if(moreData.data.resultMap.datainfo.count <= 0)
				{
					console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx2222ssss")
					onHistoryCallback([], {
						noData: true,
					});
					returnCandle()
					return;
				}


				var arrData2 = [];		
				if(!moreData.data.resultMap.nodeArray) 
				{
					arrData2 = [];
				}
				else 
				{
					arrData2 = moreData.data.resultMap.nodeArray;
					arrData.unshift(...arrData2);
				}			
				//console.log("arrData:^^^^^^^0000", countBack, arrData.length)
				if(countBack > arrData.length){

					let _Date = String(arrData[0].nDate);
					let _Time = String(arrData[0].nTime);
		
					if(_Time.length < 6)
					{
						_Time = Constant.UTIL.fillZero(6, _Time);  
					}

					let _cnt = countBack - arrData.length; 

					

					if(arrData2.length == count )					
					{
						await addPriceCandle(_Date, _Time, _cnt);
					}

				}
					
			}

			if(countBack > arrData.length)
			{
				var t_Date = String(arrData[0].nDate);
				var t_Time = String(arrData[0].nTime);
	
				if(t_Time.length < 6)
				{
					t_Time = Constant.UTIL.fillZero(6, t_Time);  
				}
				let moreCnt = countBack - arrData.length; 
				if(arrData.length == count )
				{
					await addPriceCandle(t_Date, t_Time, moreCnt);
				}
			}
			

			//console.log("arrData:~~~stock 답변", firstDataRequest,  "from:" , moment(arrData[0].timestamp, "X").format("YYYY-MM-DD  HH:mm:ss") , "to:", moment(arrData[arrData.length - 1].timestamp, "X").format("YYYY-MM-DD HH:mm:ss") )
			//console.log("arrData:~~~stock",symbolInfo.description, firstDataRequest,  "호출:", moment(to, "X").format("YYYY-MM-DD  HH:mm:ss"),    "  답변:",   "from:" , moment(arrData[0].timestamp, "X").format("YYYY-MM-DD  HH:mm:ss") , "to:", moment(arrData[arrData.length - 1].timestamp, "X").format("YYYY-MM-DD HH:mm:ss") )
     
		
			//console.log("arrData:~~~stock", firstDataRequest, arrData,  "from:", moment(from, "X").format("YYYY-MM-DDTHH:mm:ss") , "to:", moment(to, "X").format("YYYY-MM-DDTHH:mm:ss"), "countBack:", countBack, )
			let bars = [];
			arrData.forEach(bar => {				
				//let time_stamp =  parseInt(moment.utc(bar.candle_date_time_utc, "YYYY-MM-DDTHH:mm:ss").format('x')/1000);
                
				let time_stamp =  bar.timestamp//E/1000
                //console.log("www000", moment(bar.timestamp, "X").format("YYYY-MM-DD  HH:mm:ss"))
                // if(resolution == "1D" || resolution == "1W")
				// {
                  
				// 	let dateStr = bar.nDate + "T09:00:00";
				// 	time_stamp = parseInt(moment(dateStr, "YYYYMMDDTHH:mm:ss").format('X'));
				// 	//time_stamp =  bar.timestamp
				// }
				// else
				// {
				// 	if( String(bar.nTime).length == 5 )
				// 	{
				// 		bar.nTime = '0'+ bar.nTime;
				// 	}
				// 	let dateStr = bar.nDate + "T" + bar.nTime;
				// 	console.log("WWWWWW:",String(bar.nTime).length )
				// 	time_stamp = parseInt(moment(dateStr, "YYYYMMDDTHHmmss").format('X'));
				// 	//time_stamp =  bar.timestamp//E/1000 
				// }

				
				//time_stamp -= 3;
               // from
				//if (time_stamp >= from && time_stamp < to) {
					bars = [...bars, {
						time: time_stamp*1000,
						low: Number(bar.low_price),
						high: Number(bar.high_price),
						open: Number(bar.opening_price),
						close: Number(bar.trade_price),
						volume: Number(bar.candle_acc_trade_volume),
					}];
				//}

				//console.log("www000", moment(time_stamp, "X").format("YYYY-MM-DD  HH:mm:ss"), bar, resolution, time_stamp )
			});
			if (firstDataRequest) {
				//moment
                //console.log('www!!!!!!!!!!!!!!!!!! bar.time arrData',arrData[arrData.length - 1])
				//console.log('www!!!!!!!!!!!!!!!!!! bar.time 1111111111to',to , moment(to*1000).format('LLLL'))
                //console.log("wwwfirstDataRequest : ",symbolInfo.full_name,  {	...bars[bars.length - 1]})
				lastBarsCache.set(symbolInfo.full_name, {
					...bars[bars.length - 1],
				});
			}

          //  console.log("arrData:~~~stock~~~~~~~",symbolInfo.description, firstDataRequest , countBack,  "from:",  moment(from, "X").format("YYYY-MM-DD  HH:mm:ss"),                 "to:", moment(to, "X").format("YYYY-MM-DD  HH:mm:ss"))
			//console.log("arrData:~~~stock~~~~",symbolInfo.description, firstDataRequest,  bars.length,         "   from:" , moment(bars[0].time, "x").format("YYYY-MM-DD  HH:mm:ss") , "to:", moment(bars[bars.length - 1].time, "x").format("YYYY-MM-DD HH:mm:ss") )


			//console.log(`[getBars]: returned ${bars.length} bar(s)`);
			m_lastDate = String(arrData[0].nDate);
			m_lastTime = String(arrData[0].nTime);
			onHistoryCallback(bars, {
				noData: false
				//nextTime:Number(arrData[0].timestamp),
			});
		} catch (error) {
			console.log('[getBars]: Get error', error);
			onErrorCallback(error);
		}
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback,
	) => {
		//console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscribeUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
	},

	unsubscribeBars: (subscriberUID) => {
		//console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
	},
};
