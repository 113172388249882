<template>
  <!-- Footer Start { -->
       <div id="footer">
         <div class="foot-area">
           <div class="foot-logo">
             <span><img src="@/assets/images/common/foot_logo.png" alt="logo"></span>
             <p><strong>&copy; 2023. FLEX MONEY Inc.</strong> All Rights Reserved.</p>
           </div>
           <div class="foot-nav">
             <ul>
               <li><router-link :to="{ name:'Policy' }">이용약관</router-link></li>
               <li><router-link :to="{ name:'Privacy' }">개인정보처리방침</router-link></li>
               <li><router-link :to="{ name:'Notice' }">공지사항</router-link></li>
               <li><a href="javascript:;" @click="onClickQna">1:1문의</a></li>
             </ul>
             <address>
               회사명 : (주)플렉스머니 / 대표 : 이상준 / 사업자등록번호 : 558-86-02855 / 주소 : 서울특별시 강남구 테헤란로 503, 10층, 1011호<br>
               대표번호 : 1660-1737 / E-MAIL : <a href="mailto:help@flexmoney.co.kr" class="link-secondary">help@flexmoney.co.kr</a>
             </address>
           </div>
         </div>
 
       </div>
       <!-- } Footer End -->
 </template>
 
 <script>
import { useStore } from 'vuex';
import { computed, getCurrentInstance } from 'vue';
import { useRouter }from 'vue-router';
 export default {
    setup() {
      const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
      const store = useStore();
      const router = useRouter();
      const isLogin = computed(() => store.state.user.isLogin);

      const onClickQna = () => {
        if (isLogin.value) {
          router.push({
            name:'Qna'
          });
        } else {
          emitter.emit('EVENT_ALERT',{title:"알림", body: "로그인 후 이용 가능합니다."});
        }
      }
    return{
      isLogin,
      onClickQna
    }
  }
 }
 </script>
 
 <style>
 
 </style>