<template>
            <!-- 호가 영역 start -->
            <div class="trade-price-area">
                

            <div class="trade-price-tab">
                <!-- tab button -->
                <ul class="nav nav-tabs nav-fill" id="tradePriceTabButton" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="trade-price-tab" data-bs-toggle="tab" data-bs-target="#tradePriceTab" type="button" role="tab" aria-controls="tradePriceTab" aria-selected="true">
                    호가
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button @click="onCurrentTradeInfo" class="nav-link" id="trade-price-status-tab" data-bs-toggle="tab" data-bs-target="#tradePriceStatusTab" type="button" role="tab" aria-controls="tradePriceStatusTab" aria-selected="false">
                    체결
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button @click="onDailyTradeInfo" class="nav-link" id="trade-price-day-tab" data-bs-toggle="tab" data-bs-target="#tradePriceDayTab" type="button" role="tab" aria-controls="tradePriceDayTab" aria-selected="false">
                    일별
                    </button>
                </li>
                </ul>

                <!-- tab contents -->
                <div class="tab-content" id="tradePriceTabContents">

                    <Hts
                      :selectItem="selectItem"
                      @change-select-price = "onChangeSelectPrice"
                    />

                <!-- 체결 start -->
                <div class="tab-pane fade" id="tradePriceStatusTab" role="tabpanel" aria-labelledby="trade-price-status-tab">
                    <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                    <!-- 체결창 상단 시작  -->
                    <div class="trade-summary">
                    <table class="table">
                        <colgroup>
                        <col style="width: 20%" />
                        <col style="width: 35%" />
                        <col style="width: 30%" />
                        <col style="width: 15%" />
                        <!-- <col style="width: 20%" /> -->
                        </colgroup>
                        <tr>
                        <th>시간</th>
                        <th>체결가</th>
                        <th>전일대비</th>
                        <th>체결량</th>
                        <!-- <th>체결강도</th> -->
                        </tr>
                    </table>
                    </div>
                    <!-- //체결창 상단 종료 -->                                  
                    <!-- <div class="trade-price-status" id="tradePriceStatus"> -->
                    <!-- class 설명 
                        td  >> 보합
                        td.up  >> 상승
                        td.down  >> 하락
                    -->
                    <perfect-scrollbar 
                            @ps-scroll-y="onScroll" 
                            :options="options"
                            :watch-options="watcher"
                            ref="scrollbar"
                            class="trade-price-status" id="tradePriceStatus"
                    >     
                    <table class="table">
                        <colgroup>
                        <col style="width: 20%" />
                        <col style="width: 35%" />
                        <col style="width: 30%" />
                        <col style="width: 15%" />
                        <!-- <col style="width: 20%" /> -->
                        </colgroup>
                        <tbody>
                        <tr
                            v-for="(item, index ) in arrTradeHistory"
                            :key = index                           
                        >
                            <td>{{item.time }}</td>
                            <td>{{item.tradaPrice}}</td>
                            <td :class="item.color">
                                <span class="price-previous-day">{{Constant.UTIL.numberWithCommas(Number(item.prevGap).toFixed(getHtsNdotpos(currentTradeType, item.tradaPrice)))}}<i></i></span>
                            </td>
                            <td>{{item.tradaVolume}}</td>
                            <!-- <td>110.44</td> -->
                        </tr>
    
                        </tbody>
                    </table>
                    <!-- </div> -->
                    </perfect-scrollbar>
                </div>
                <!-- //체결 end -->

                <!-- 일별 start -->
                <div class="tab-pane fade" id="tradePriceDayTab" role="tabpanel" aria-labelledby="trade-price-day-tab">
                    <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>
                    <!-- 일별 상단 시작  -->
                    <div class="trade-summary">
                    <table class="table">
                        <colgroup>
                        <col style="width: 12%" />
                        <col style="width: 23%" />
                        <col style="width: 23%" />
                        <col style="width: 17%" />
                        <col style="width: 20%" />
                        </colgroup>
                        <tr>
                        <th>일자</th>
                        <th>종가</th>
                        <th>{{currentTradeType=='F'?'전일대비':'전일대비(KRW)'}}</th>
                        <th>전일대비(%)</th>
                        <th>{{currentTradeType=='C'?`거래량(${selectItem.CODE.split('-')[1]})`:'거래량'}}</th>
                        </tr>
                    </table>
                    </div>
                    <!-- //일별 상단 종료 -->
                    
                    <!-- <div class="trade-price-day" id="tradePriceDay">   -->
                    <!-- class 설명 
                        tr  >> 보합
                        tr.up  >> 상승
                        tr.down  >> 하락
                    -->

                    <perfect-scrollbar 
                        @ps-scroll-y="onScroll" 
                        :options="options"
                        :watch-options="watcher"
                        ref="scrollbar"
                        class="trade-price-day" id="tradePriceDay"
                    >                     
                    <table class="table">
                        <colgroup>
                        <col style="width: 12%" />
                        <col style="width: 23%" />
                        <col style="width: 23%" />
                        <col style="width: 17%" />
                        <col style="width: 20%" />
                        </colgroup>
                        <tbody>
                        <tr 
                            v-for="(item, index ) in arrDailyTradeData"
                            :key = index 
                            :class="item.color"                        
                        >
                            <td>{{item.date}}</td>  
                            <td>
                            <span class="price-closing">{{ Constant.UTIL.numberWithCommas(item.close)}}</span>
                            </td>
                            <td>
                            <span class="price-previous-day">{{ Constant.UTIL.numberWithCommas(Number(item.prevGap).toFixed(getHtsNdotpos(currentTradeType, item.close)))}}<i></i></span>
                            </td>
                            <td>
                            <span class="price-previous-day-per"><i></i>{{ Constant.UTIL.numberWithCommas(Number(item.per).toFixed(2)) + '%'}}</span>
                            </td>
                            <td>{{Constant.UTIL.numberWithCommas(Number(item.volume).toFixed(currentTradeType=='C'?2:0))}}</td>
                        </tr>
                        
                        </tbody>
                    </table>
                    <!-- </div> -->
                    </perfect-scrollbar> 
                </div>
                <!-- //일별 end -->

                </div>
            </div>
                                
            </div>
            <!-- //호가 영역 end -->
</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import Hts from '@/components/trade/Hts.vue';
import { getCurrentInstance, watchEffect, onUnmounted, computed,  ref, onMounted} from 'vue';
import { useStore } from 'vuex';
import {useApicall} from '@/composables/commonFn';
import Constant from '@/constant.js';
import moment from 'moment';
import {useCommonFunction} from '@/composables/vtradeFn';
export default {
    components :{
       Hts,
       PerfectScrollbar
    },
    props:
    { 
       selectItem:{        
           require:true
       },
    },
    emits:['change-select-price'],
    setup(props){
        const { emit } = getCurrentInstance();

        const store = useStore();
        const tradeObj = computed(() => store.state.hts.trade);
        const currentTradeType = computed(()=> store.state.hts.currentTradeType);
        const initData = computed(() => store.state.hts.initTrade);
       
        const {     
            getNdotposCoin,
        } = useCommonFunction();



        const arrTradeHistory = ref([]);
        const internalInstance = getCurrentInstance(); 
        const emitter = internalInstance.appContext.config.globalProperties.emitter; 
        var limit_history = 100

        const {apiCall_p} = useApicall();


        const arrDailyTradeData = ref([]);
        
       const push_tradePrice = (_tradeObj) => {
          var _prevGap = null
          var _time = null;
         if(currentTradeType.value == 'C')
         {
           _prevGap =   _tradeObj.trade_price - _tradeObj.prev_closing_price; 
           _time = moment(_tradeObj.trade_timestamp, 'x').format('HH:mm:ss')
         }
         else
         {
            _time = moment(_tradeObj.trade_time, 'HHmmss').format('HH:mm:ss')
             let closePrice = Number(Constant.UTIL.removeTrash(initData.value.prev_closing_price));
             if(closePrice)
             {
                 _prevGap = _tradeObj.trade_price - closePrice;
             }
             else
             {
                 _prevGap = 0;
             }
         }
          
          var t_colorName;
      
          if( _prevGap > 0 )
          {
               t_colorName = "up";               
          }
          else if( _prevGap < 0)
          {
              t_colorName = "down";               
          }
          else
          {
              t_colorName = "";
          }



          
          var t_obj = {
                tradaPrice : Constant.UTIL.numberWithCommas(_tradeObj.trade_price),
                tradaVolume: Constant.UTIL.numberWithCommas(Number(_tradeObj.trade_volume).toFixed(_tradeObj.itemType == "C"?3:0)),
                color: t_colorName,
                // time: moment(_tradeObj.timestamp).format('HH:mm:ss'),
                time: _time,
                prevGap: _prevGap
            };
          
            arrTradeHistory.value.unshift(t_obj);
            if(arrTradeHistory.value.length > limit_history)
            {
                arrTradeHistory.value.pop();
            }
       }

        watchEffect(()=>{   
            let tr_price = Number(tradeObj.value.trade_price);     
            let tr_volume = Number(tradeObj.value.trade_volume);       
            if(!isNaN(tr_price))
            {
                if(!isNaN(tr_volume) && push_tradePrice) push_tradePrice( tradeObj.value );  
            }
        })


       const onDailyTradeInfo = () =>{
           arrDailyTradeData.value = [];
           setTimeout(()=>{
               getDailyTradeInfo()
           }, 2000)
         
       }
 
      // /*
       const getDailyTradeInfo = async()=>{          
           var dateStr = moment(new Date()).format('X'); 
           var params = {  
               
                "type": currentTradeType.value,
                "unit": "1",
                "candleType": "D",        
                "code": props.selectItem.CODE,
                "count": 50,
                "timeStamp": dateStr
            }
           
           var res = await apiCall_p('index/pricecandle', params)
           var arrRes = res.nodeArray;
           var dataArr = [];
           if(currentTradeType.value == 'C')
           {                  
                for(let i =0; i<arrRes.length; i++)
                {
                    let item = arrRes[i];
                    let itemObj = {};
                    itemObj.date = moment(item.candle_date_time_utc, "YYYY-MM-DDTHH:mm:ss").format('MM.DD');
                    itemObj.close = item.trade_price; 
                    let prevGap =   item.trade_price - item.prev_closing_price;
                    let colorStr = ''
                    if(prevGap > 0)colorStr = 'up';
                    else if((prevGap < 0)) colorStr = 'down';
                    itemObj.prevGap = Math.abs(prevGap);
                    itemObj.color = colorStr;
                    let per = (prevGap/item.trade_price)*100;
                    itemObj.per = Math.abs(per);
                    itemObj.volume = item.candle_acc_trade_volume;
                    dataArr.push(itemObj);
                }   
           }
           else
           {
              var prevClosingPrice = 0;
             for(let i =0; i<arrRes.length; i++)
             {
                let item = arrRes[i];
                let itemObj = {};
                itemObj.date = moment(item.timestamp, "X").format('MM.DD');
                itemObj.prev_closing_price = prevClosingPrice;
                itemObj.close = Number(item.trade_price); 
                let prevGap =    Number(item.trade_price) - itemObj.prev_closing_price;
                let colorStr = ''
                if(prevGap > 0)colorStr = 'up';
                else if((prevGap < 0)) colorStr = 'down';
                itemObj.prevGap = Math.abs(prevGap);
                itemObj.color = colorStr;
                let per = (prevGap/ Number(item.trade_price))*100;
                itemObj.per = Math.abs(per);
                itemObj.volume = item.candle_acc_trade_volume;
                dataArr.push(itemObj);
                prevClosingPrice =  Number(item.trade_price);               
             }
            dataArr.reverse();
           }
            dataArr.pop()
            arrDailyTradeData.value = dataArr;
         // console.log("tradeObj.value: ", res.nodeArray )
       }
       //*/

      const onCurrentTradeInfo = ()=>{
        // console.log("onCurrentTradeInfo1", arrTradeHistory.value )
        // arrTradeHistory.value = [];
       //  console.log("onCurrentTradeInfo2", arrTradeHistory.value )
      }



       onMounted(()=>{
            emitter.off('EVENT_INIT_ITEM_HTS_CON');
            emitter.on('EVENT_INIT_ITEM_HTS_CON', () => { 
           // console.log("EVENT_INIT_ITEM_HTS_CON")
             setTimeout(()=>{
                arrTradeHistory.value = [];
             },500)
           // onDailyTradeInfo()
            // for(var i=0; i<limit_history; i++)
            // {                
            //    arrTradeHistory[i] = null;
            // }
            
           })
       })
       onUnmounted(()=>{
     
        
        setTimeout(()=>{
          arrTradeHistory.value = [];
        },500)
         
       })

        const onScroll = (e) =>{
            e
            // trace(e.target.scrollTop); 
        }

        const onChangeSelectPrice = (price) =>{
              //console.log("onPriceClick :", Number(price));
              emit('change-select-price', Number(price));
        }

        const getHtsNdotpos = (type, price = null) =>{
            var result = 0;
            if(type == 'C')
            {
               result = getNdotposCoin(price)
            }
            else if(type == 'F')
            {
                result = parseInt(props.selectItem.NDOTPOS);
            }
            else if(type == 'G')
            {
                result = parseInt(props.selectItem.NDOTPOS);
            }
            else if(type == 'S')
            {
                result = 0;
            }
            return result;
        }

        return{
            options: {       
                    wheelSpeed: 1,
                    wheelPropagation: true,
                    minScrollbarLength: 20,
                    suppressScrollX: true,
            },
            watcher: true,
           onChangeSelectPrice,
           arrTradeHistory,
           onDailyTradeInfo,
           arrDailyTradeData,
           onScroll,
           Constant,
           currentTradeType,
           onCurrentTradeInfo,
           initData,
           getHtsNdotpos
        }
    }


}
</script>

<style>

</style>