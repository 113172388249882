// import Config from '@/config.js'
import Config from '../../public/config.js';
import { parseFullSymbol } from './helpers.js';
import { io } from "socket.io-client";
import moment from 'moment';

//var socketId;
export var socketId
//console.log('[socket] ~~~~~~~~~~~~~~');

const BASE_URL_WS_CHART = Config.URL.wsChartUrl;

//console.log("BASE_URL_WS_CHART:", BASE_URL_WS_CHART)

var  socket = null;
const channelToSubscription = new Map();
var n_currentUID;



const connectSocket = ()=>{

	if(socket)
	{
		return socket;
	}

	socket = io(BASE_URL_WS_CHART, {
		autoConnect: false,
		transports: ["websocket"]
			//withCredentials: true,
	});
	
	socket.on('connect', () => {
		console.log('[socket] Connected');
	});
	
	socket.on('disconnect', (reason) => {
		console.log('[socket] Disconnected:', reason);
	});
	
	socket.on('error', (error) => {
		console.log('[socket] Error:', error);
	});
	
	socket.on('socketIdMsg', (data) => {
		//console.log('[socket] socketIdMsg:', data);
		socketId = data.socketId;
		
	});
	
	
	
	socket.on('trade', data => {	
		//console.log("trade", data)
		// const [
		// 	eventTypeStr,
		// 	exchange,
		// 	fromSymbol,
		// 	toSymbol,
		// 	,
		// 	,
		// 	tradeTimeStr,
		// 	,
		// 	tradePriceStr,
		// ] = data.split('~');
	
	
		
		// if (parseInt(eventTypeStr) !== 0) {
		// 	// skip all non-TRADE events
		// 	return;
		// }
	
	
		//console.log("wwwwwwwwwwwwwwwwww", moment.utc(new Date()).format('LLL') )
	
		// let today = new Date();   
		// let year = today.getFullYear(); // 년도
		// let month = today.getMonth() + 1;  // 월
		// let date = today.getDate();  // 날짜
		// let t_day = `${year}-${month}-${date} ` +  data.trade_time;   
		// const tradeTime =  parseInt(moment(t_day, "YYYY-MM-DD HHmmss").format('x'));
		
		const tradeTime =  moment(new Date()).format('x');	
	
		//var  tradeTime =  data.timestamp
		//console.log("wwwwwwwwwwwwwwwwwwtradeTime", moment(tradeTime, "x").format("YYYY-MM-DD  HH:mm:ss") )
		//console.log("wwwwwwwwwwwwwwwwww", moment(data.timestamp, "x").format("YYYY-MM-DD  HH:mm:ss") )
		//console.log("wwwwwwwwwwwwwwwwww222", moment.utc(new Date(), "x").format("YYYY-MM-DD  HH:mm:ss") )
		//console.log("wwwwwwwwwwwwwwwwww333", moment(new Date(), "x").format("YYYY-MM-DD  HH:mm:ss") )
		var tradePrice;
		var spSymbol = []; 
		if(data.itemType == 'G')
		{
			tradePrice = parseFloat(data.p);		
			spSymbol[0]= data.code.substring(0,data.code.length-4);  		
			spSymbol[1]= data.code.substring(data.code.length-4); 
		}
		else
		{		
			tradePrice = parseFloat(data.trade_price);
			spSymbol = data.code.split('-');      
			if(!spSymbol[1])spSymbol[1]='KR'
		}

		
		const channelString = `0~${'Flex'}~${spSymbol[0]}~${spSymbol[1]}`;	
		const subscriptionItem = channelToSubscription.get(channelString);
	
		//console.log('[socket] 1111', subscriptionItem, channelString, data.code);
		if (subscriptionItem === undefined) {
			return;
		}
		const lastDailyBar = subscriptionItem.lastDailyBar;	
		const nextDailyBarTime = getNextBarTime(lastDailyBar.time, subscriptionItem.resolution);
		//nextDailyBarTime
		const getVolume=()=>{
			var result;
			if(data.itemType == 'G')
			{
				result = data.v;
			}
			else if(data.itemType == 'F')
			{
				result = data.volume;
			}
			else if(data.itemType == 'C')
			{
				result = data.trade_volume;
			}
			else if(data.itemType == 'S')
			{
				result = data.trade_volume;
			}
		//	console.log("getVolume: ", result )
			return result
		}
	
		
		let bar;	
		if (tradeTime >= nextDailyBarTime) {
			bar = {
				time: nextDailyBarTime,
				open: tradePrice,
				high: tradePrice,
				low: tradePrice,
				close: tradePrice,
				volume: getVolume()
			};
			//console.log('[socket] bar.time Generate new bar', bar);
			//console.log("wwwwwwwwwwwwwwwwww Generate new bar")
		} else {	
			bar = {
				...lastDailyBar,
				high: Math.max(lastDailyBar.high, tradePrice),
				low: Math.min(lastDailyBar.low, tradePrice),
				volume: getVolume(),
				close: tradePrice,
			};
			//console.log('[socket] Update the latest bar by price', tradePrice);
			//console.log("wwwwwwwwwwwwwwwwwwUpdate the latest bar ")
		}
		subscriptionItem.lastDailyBar = bar; 
		// send data to every subscriber of that symbol
	
		//console.log("wwwwwwwwwwwwwwwwww000000000", moment(subscriptionItem.lastDailyBar.time, "x").format("YYYY-MM-DD  HH:mm:ss") )
		//console.log('[socket] ~~ tradeTime : ', tradeTime,  moment(tradeTime).format('LLLL'), bar.time,  "     bar.time:",  moment(bar.time).format('LLLL'));
		subscriptionItem.handlers.forEach((handler) =>{
			//console.log("handler:@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@222222",n_currentUID,  handler)
			if(n_currentUID == handler.id)
			{
				//console.log("handler:@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@00000",n_currentUID,  handler)
				handler.callback(bar)
			}
			
		});
	
		//console.log("wwwwwwwwwwwwwwwwww111111111", moment(subscriptionItem.lastDailyBar.time, "x").format("YYYY-MM-DD  HH:mm:ss") )
	});
	
	socket.open();   
    
} 




//socket = io(BASE_URL_WS_CHART);



// var socket = io(Config.URL.wsChartUrl, {
// 	autoConnect: false,
// 	transports: ["websocket"]
// 		//withCredentials: true,
// });










function getNextBarTime(barTime, resolution) { 

	//console.log("wwwwwwwwwwwwwwwwwwgetNextBarTime 0:", moment(barTime, "x").format("YYYY-MM-DD  HH:mm:ss"), "resolution :", resolution )
	var mo = moment(new Date(barTime));
	//console.log("~~~~111:", mo.format('LLL'))
    var result;
	if(resolution == '1W')  
	{		
		//mo.weekday(7);
		mo.set('date',  mo.get('date')+7);
		//mo.set('hour', 0);
		//mo.set('minute', 0);
		//mo.set('second', 0);
	}
    else if(resolution == '1D')  
	{		
		mo.set('date',  mo.get('date')+1);
		//mo.set('hour', 0);
		//mo.set('minute', 0);
		//mo.set('second', 0);
	}
	else if(resolution == '1')  
	
	{
		mo.set('minute',  mo.get('minute')+1);
		//mo.set('second', 0);
		
	}
	else if(resolution == '3')  
	{
		mo.set('minute',  mo.get('minute')+3);
		//mo.set('second', 0);
	}
	else if(resolution == '5')  
	{
		mo.set('minute',  mo.get('minute')+5);
		//mo.set('second', 0);
	}
	else if(resolution == '10')  
	{
		mo.set('minute',  mo.get('minute')+10);
		//mo.set('second', 0);
	}
	else if(resolution == '15')  
	{
		mo.set('minute',  mo.get('minute')+15);
		//mo.set('second', 0);
	}
	else if(resolution == '30')  
	{
		mo.set('minute',  mo.get('minute')+30);
		//mo.set('second', 0);
	}
	else if(resolution == '60')  
	{
		mo.set('minute',  mo.get('minute')+60);
		//mo.set('second', 0);
	}
	else if(resolution == '240')  
	{
		mo.set('minute',  mo.get('minute')+240);
		//mo.set('second', 0);
	}
	//console.log("~~~~222:", mo.format('LLL'))
	result = parseInt(mo.format('x'))

	//console.log("wwwwwwwwwwwwwwwwwwgetNextBarTime 1 ", moment(result, "x").format("YYYY-MM-DD  HH:mm:ss") )
	return  result;
}






export function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscribeUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {

	
	//onResetCacheNeededCallback()
   
	const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
	//console.log('$$$ subscribeOnStream~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',subscribeUID, symbolInfo,  "lastDailyBar :", moment(lastDailyBar.time, "x").format("YYYY-MM-DD  HH:mm:ss"))
    n_currentUID = subscribeUID;

	const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}`;
	const handler = {
		id: subscribeUID,
		callback: onRealtimeCallback,
	};
	let subscriptionItem = channelToSubscription.get(channelString);
	

   
	//subscriptionItem()
	if (subscriptionItem) {
		// already subscribed to the channel, use the existing subscription
		//onResetCacheNeededCallback()
		subscriptionItem.resolution = resolution;
		subscriptionItem.lastDailyBar = lastDailyBar;
		subscriptionItem.handlers.push(handler);
        //console.log("ddddddddddddddddddddddddddddddddddddddddd", subscriptionItem, channelString)
		return;
	}
	//onResetCacheNeededCallback();
	subscriptionItem = {
		symbolCode:symbolInfo.code,
		symbolType:symbolInfo.type,
		subscribeUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};

	//console.log("subscriptionItem:~~~~", subscriptionItem, symbolInfo.code)
	channelToSubscription.set(channelString, subscriptionItem);
	//console.log('[subscribeBars~~]: Subscribe to streaming. Channel:', "channelString:", channelString, "subscriptionItem:", subscriptionItem, "symbolInfo:", symbolInfo);
	//socket.emit('SubAdd', { subs: [channelString] });
    //console.log("dddddddddddddddd:", symbolInfo)
    let _type;
	var _isNight = false;
	if(subscriptionItem.symbolType == "crypto")
	{
		_type = 'C'
	}
	else if(subscriptionItem.symbolType == "stock")
	{
		_type = 'S'
	}
	else if(subscriptionItem.symbolType == "futures")
	{
		_type = 'F'
		if(symbolInfo.isNight)
		{
			_isNight=true;			
			symbolInfo.code = symbolInfo.code.split('_')[0];
			//console.log("dddddddddddddddd:", symbolInfo)
		}
	}
	else if(subscriptionItem.symbolType == "coinFutures")
	{
		_type = 'G'
	}

    connectSocket()
	
	socket.emit("requestCode", {
		itemType: _type,
		code: symbolInfo.code,
		isNight:_isNight
	}, function() {    
		console.log("sss requestCode ok~~", symbolInfo.code);                 
	})
}

export function unsubscribeFromStream(subscriberUID) {

	//console.log('$$$ [unsubscribeBars]: subscriberUID :', subscriberUID);
	// find a subscription with id === subscriberUID
	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		//console.log("$$$ [unsubscribeBars] 00 subscriptionItem:",channelString,  subscriptionItem)
		const handlerIndex = subscriptionItem.handlers
			.findIndex(handler => handler.id === subscriberUID);
			//console.log('$$$ [unsubscribeBars]: Uns handlerIndex ', handlerIndex, subscriptionItem.handlers)
		if (handlerIndex !== -1) {
			// remove from handlers
			subscriptionItem.handlers.splice(handlerIndex, 1);

			//console.log('[$$$ unsubscribeBars]: Uns')

			if (subscriptionItem.handlers.length === 0) {
				// unsubscribe from the channel, if it was the last handler
				//console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:~~~~ leaveCode', channelString, subscriptionItem.symbolCode);

                let _type;
				if(subscriptionItem.symbolType == "crypto")
				{
					_type = 'C'
				}
				else if(subscriptionItem.symbolType == "stock")
				{
					_type = 'S'
				}
				else if(subscriptionItem.symbolType == "futures")
				{
					_type = 'F'
				}
				else if(subscriptionItem.symbolType == "coinFutures")
				{
					_type = 'G'
				}
                connectSocket()
				socket.emit('leaveCode', { 
					itemType:_type,
					code: subscriptionItem.symbolCode
				}, function() {    
						console.log("sss leaveCode ok~", subscriptionItem.symbolCode);                 
				})	
				channelToSubscription.delete(channelString);
				break;
			}
		}
	}
}
