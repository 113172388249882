<template>

        <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>
        <div class="trade-live-box"     >
        <div class="live-inner">
            <div class="live-contents">
            <iframe v-if="isActive && isReflash"   allow="autoplay;fullscreen"   id="tradeLiveBroadcast" title="실시간 방송" height="100%" frameborder="0" allowtransparency="true" scrolling="no" allowfullscreen="true" :src="`${Config.URL.apiFlextvUrl}redirects/signin?token=${token}&redirectTo=/embed/channels/${currentLiveID}/play`"  ref="liveVideo"></iframe>
            </div>
        </div>
            <div class="chat-inner" >
                <!-- <iframe id="tradeLiveChat" title="실시간 채팅" height="712" frameborder="0" allowtransparency="true" scrolling="no"  allowfullscreen="true" src="https://www.flextv.co.kr/marketings/top-ranking/chat"></iframe> -->
                <iframe v-if="isActive  && isReflash"      id="tradeLiveChat" title="실시간 채팅" height="100%" frameborder="0" allowtransparency="true" scrolling="no" allowfullscreen="true" :src="`${Config.URL.apiFlextvUrl}redirects/signin?token=${token}&redirectTo=/embed/v2/channels/${currentLiveID}/chat`" ref="liveChat"></iframe>

            </div>
        </div>


</template>

<script>
import {axiosFlex} from '@/axios';
import Config from '../../public/config.js';
// import Config from '@/config.js';
import { onMounted, computed, ref, onBeforeUnmount, watch} from 'vue';
import { useStore } from 'vuex';
export default {
 props:{
    isActive:{
        type:Boolean,
        required:true
    }

 },
 setup(props){
    const liveVideo = ref(null);
    const liveChat = ref(null);
    const isReflash = ref(true);
    const store = useStore();
    const currentLiveID= computed(() =>store.state.live.currentLiveID);
    watch(currentLiveID, ()=>{

        if(props.isActive)
        {
           isReflash.value = false;
           setTimeout(()=>{
              isReflash.value = true;
           }, 100)
        }
    })
    const fetchLiveRankings = async () => {
      const res = await axiosFlex.get('api/channels/inliveRankings?liveOption=total&includeAdult=false')
      return res.data?.data?.filter(live => !live.isForAdult) ?? []

    }
    const getStartLiveId = (items)  =>{
      if (items.length === 0) return null
            // res.data.data.forEach(element => {
            //        console.log("res.data.data", element)
            //        if(!element.isForAdult)
            //        {
            //          resultID = element.channelId;
            //        }
            // });


            //resultID = res.data.data[0].channelId;
            return items[0].channelId;
    }
    const token = ref(sessionStorage.getItem('token'));


    // setTimeout(()=>{
    //     console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~", liveVideo.value.src = null)
    // },3000)
    onMounted( async ()=>{
       //  console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ onMounted :", currentLiveID.value)
      if (!currentLiveID.value) {
        const items = await fetchLiveRankings()
        store.commit('live/CHANGE_CURRENT_LIVE_ID', getStartLiveId(items));
        // console.log("getStartLiveId", id)
      } else {
        fetchLiveRankings().then((items) => {
          const included = items.some(live => live.channelId === currentLiveID.value)
          if (!included) { // 현재 시청중인 방송이 방송중이 아님
            store.commit('live/CHANGE_CURRENT_LIVE_ID', getStartLiveId(items));
          }
        })
      }
    })
    onBeforeUnmount(()=>{
         //  console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ onBeforeUnmount :", currentLiveID.value)
            liveVideo.value.src = null;
    })
   return{
        currentLiveID,
        Config,
        token,
        liveVideo,
        liveChat,
        isReflash
   }
 }
}
</script>

<style>

</style>
