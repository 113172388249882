// import Config from '@/config.js';
import Config from '../public/config.js'
import { io } from "socket.io-client";
import { useStore } from 'vuex';
import { getCurrentInstance } from 'vue'
import {useBalance}from '@/composables/vtradeFn';
import { useToast } from '@/composables/toast';
var  socket = null



export const useScoket = () => {
   // console.log("socket login~~~~~~~~~~11111", socket);

   const {         
     triggerToast
   } = useToast();

  
    if(socket)return { socket }
    const store = useStore();
    const {setCurrentBalance} = useBalance();
   
    const BASE_URL_WS = Config.URL.wsBaseUrl;
    //const orderbook2 = computed(() => store.state.hts.orderbook);
    const internalInstance = getCurrentInstance(); 
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
   
    let orderbook = [];
    let orderbook_a = [];
    let orderbook_b = [];
    socket = io(BASE_URL_WS, {
        autoConnect: false,
        transports: ["websocket"]
            //withCredentials: true,
    });
    socket.on('connect', function() {

        console.log('socket login connected...');
        store.commit('isocket/UPDATE_SOCKET_CONNECTED', true);
        var token = sessionStorage.getItem('token');

       // console.log("socket login~~~~~~~~~~ connected ",  token);

        if(token)
        {
            socket.emit("login", {
                token: token
                //token: '468359f48edad216304b35856243ef83'
            }, function(data) {
               console.log("socket login",  data);
            })
        }   
    });
    socket.on('disconnect', function() {
        console.log('disconnected...');
        store.commit('isocket/UPDATE_SOCKET_CONNECTED', false);
    });


    socket.on('orderbook', function(data) {
        //data
     
        if(store.state.hts.currentItem.CODE == data.code )
        {
           if(store.state.hts.currentTradeType == 'S' || store.state.hts.currentTradeType == 'C' || store.state.hts.currentTradeType == 'F')
           {
                orderbook = data.orderbook_units;
                store.commit('hts/CHANGE_ORDERBOOK', orderbook);
                store.commit('hts/CHANGE_TOTAL_ASK_SIZE', data.total_ask_size);
                store.commit('hts/CHANGE_TOTAL_BID_SIZE', data.total_bid_size);
          
            // if(store.state.hts.currentTradeType == 'F')
            // {
            //     store.commit('hts/CHANGE_TOTAL_ASK_COUNT', data.total_ask_count);
            //     store.commit('hts/CHANGE_TOTAL_BID_COUNT', data.total_bid_count); 
            // }
           }
           else if(store.state.hts.currentTradeType == 'G')
           {
                orderbook_a = data.orderbook_units_a;
                orderbook_b = data.orderbook_units_b;
                store.commit('hts/CHANGE_ORDERBOOK_A', orderbook_a);
                store.commit('hts/CHANGE_ORDERBOOK_B', orderbook_b);
           }

           
         
        }    
       
    });


    socket.on('initorderbook', function(data) {
      
       if(store.state.hts.currentItem.CODE == data.code )
       {
           
           if(store.state.hts.currentTradeType == 'S' || store.state.hts.currentTradeType == 'C' || store.state.hts.currentTradeType == 'F')
           {
                orderbook = data.orderbook_units;
                store.commit('hts/CHANGE_ORDERBOOK', orderbook);
                store.commit('hts/CHANGE_TOTAL_ASK_SIZE', data.total_ask_size);
                store.commit('hts/CHANGE_TOTAL_BID_SIZE', data.total_bid_size);
           }
        //    else if(store.state.hts.currentTradeType == 'F')
        //    {
        //        orderbook = data.orderbook_units;
        //        store.commit('hts/CHANGE_ORDERBOOK', orderbook);
        //        store.commit('hts/CHANGE_TOTAL_ASK_COUNT', data.total_ask_count);
        //        store.commit('hts/CHANGE_TOTAL_BID_COUNT', data.total_bid_count); 
        //    }
           else if(store.state.hts.currentTradeType == 'G')
           {
               orderbook_a = data.orderbook_units_a;
               orderbook_b = data.orderbook_units_b;

               store.commit('hts/CHANGE_ORDERBOOK_A', orderbook_a);
               store.commit('hts/CHANGE_ORDERBOOK_B', orderbook_b);
           }
       
       }
      
   });


    socket.on('trade', function(data) {
        // data       
     
        if(store.state.hts.currentItem && store.state.hts.currentItem.CODE == data.code)
        {
            store.commit('hts/CHANGE_TEADE', data)
          //  console.log('trade~ dddd', data);
        }
        store.commit('hts/CHANGE_ALL_TEADE', data)
        //console.log('trade~', data);
        
    });
    socket.on('initdata', function(data) {
        //console.log('initdata~~~~~~~~~', data, store.state.hts.currentItem.CODE);
        if(store.state.hts.currentItem.CODE == (data.itemType=='G'?data.symbol:data.code) )
        {
            
           
            store.commit('hts/CHANGE_INIT_TEADE', data);
           
            emitter.emit('EVENT_SOCKET_INIT_DATA', data);
            emitter.emit('EVENT_SOCKET_INIT_DATA_2', data);
           
            
        }
        store.commit('hts/CHANGE_ALL_TEADE', data)

        
    });


    socket.on('periodInitData', function(data) {
      //  console.log('periodInitData~~~~~~~~~~~', data);
        store.commit('hts/CHANGE_PERIOD_INIT_DATA', data);
      //  store.commit('hts/CHANGE_PERIOD_INIT_DATA_KEEP', data);
        
    });

    socket.on('eventMsg', function(data) {       
        setCurrentBalance(store.state.hts.currentSpaceId);
        if(data.type == 'order') {
            //매도/매수 요청
            store.dispatch('hts/getOrderList');
            store.dispatch('hts/getAssetList');           
        }else if(data.type == 'trade') {   
            //거래확정
            store.dispatch('hts/getOrderList');
            store.dispatch('hts/getAssetList');            
            if(store.state.common.isOrderAlarm == 'true')
            {
                triggerToast(data.data);  
            }
           
           // var isAlertPops = JSON.parse(localStorage.getItem('isAlertPops'));

            
            // if(isAlertPops == null || isAlertPops.trade)
            // {                
            //     var t_type = '';
            //     var n_type = '';
            //     if(data.data.tradeType == '1' || data.data.tradeType == '3')
            //     {                   
            //         t_type = '매수'
            //         n_type = 'error'
            //     }
            //     else if(data.data.tradeType == '2' || data.data.tradeType == '4')
            //     {
            //         t_type = '매도'
            //         n_type = ''
            //     }
               
            //     notify({
            //         title: "거래 체결 안내",
            //         text: `${data.data.name} ${t_type} 주문이 체결되었습니다.`,
            //         type: n_type,
            //     });

            // }


                //             var t_type = '';
                // var n_type = '';
                // if(data.data.tradeType == '1' || data.data.tradeType == '3')
                // {                   
                //     t_type = '매수'
                //     n_type = 'error'
                // }
                // else if(data.data.tradeType == '2' || data.data.tradeType == '4')
                // {
                //     t_type = '매도'
                //     n_type = ''
                // }

          // console.log("aaaaaa")
               
           // console.log("bbbbb", data)
           
        }else if(data.type == 'order_cancel') {
            //거래취소
            store.dispatch('hts/getOrderList');
            store.dispatch('hts/getAssetList')
           
        } 
       
    });
    
    
    socket.open();   
    return { socket };

}