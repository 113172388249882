<template>
            <!-- 좌측 : 종목 조회 start -->
            <div class="trade-search-area" id="tradeSearchArea">
              <table class="trade-search">
                <tr>
                  <td colspan="2" class="search-input">
                    <div class="input">
                      <input 
                                type="text" 
                                class="form-control"
                                placeholder="종목명/코드 검색"
                                v-model="searchText"
                                @keyup="onKeyup"
                                @focus="onFocus"
                      >
                      <button v-if="!searchText" class="btn-search" type="button"><img src="@/assets/images/common/ico_header_search.png" alt="search"></button>  
                      <button v-else class="btn-cancel" type="button" ><img src="@/assets/images/common/ico_close.png" alt="cancel"   @click="onSearchDel"></button>                                 
                    </div>
                  </td>
                </tr>
              </table>

              <div class="trade-search-result">
                <div class="trade-search-tab">
                  <!-- tab button -->
                  <ul class="nav nav-tabs nav-fill" id="tradeSearchTabButton" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="trade-search-won-tab" data-bs-toggle="tab" data-bs-target="#tradeSearchWonTab" type="button" role="tab" aria-controls="tradeSearchWonTab" aria-selected="true">
                        전체종목
                      </button>
                    </li>
              
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="trade-search-interest-tab" data-bs-toggle="tab" data-bs-target="#tradeSearchInterestTab" type="button" role="tab" aria-controls="tradeSearchInterestTab" aria-selected="false" @click="onFavorTapClickd">
                        관심종목
                      </button>
                    </li>
                  </ul>
    
                  <!-- tab contents -->
                  <div class="tab-content" id="tradeSearchTabContents">
    
                    <!-- 원화마켓 start -->
                    <div class="tab-pane fade show active" id="tradeSearchWonTab" role="tabpanel" aria-labelledby="trade-search-won-tab">
                      <!-- <div class="trade-search-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div> -->

                      <!-- 원화마켓 상단 시작  -->
                      <div class="trade-search-summary">
                        <table class="table">
                          <colgroup>
                            <col style="width:40px">
                            <col style="width:200px">
                            <col style="width:150px">
                            <!-- <col style="width:75px">
                            <col style="width:95px"> -->
                          </colgroup>
                          <tr>
                            <th colspan="2">
                              <button type="button" class="btn">
                                종목
                                <!-- <span class="ico"><img src="@/assets/images/trade/search_change.png" alt="한글명"></span> -->
                              </button>
                            </th>
                            <th>
                              <button type="button" class="btn btn-sort-arrow">
                                코드
                                <!-- <span class="ico"><i class="sort"></i></span> -->
                              </button>
                            </th>
                            <!-- <th>
                              <button type="button" class="btn btn-sort-arrow">
                                전일대비
                                <span class="ico"><i class="sort"></i></span>
                              </button>
                            </th>
                            <th>
                              <button type="button" class="btn btn-sort-arrow">
                                거래대금
                                <span class="ico"><i class="sort"></i></span>
                              </button>
                            </th> -->
                          </tr>
                        </table>
                      </div>
                      <!-- //원화마켓 상단 종료 -->

                      <!-- 원화마켓 내용 start -->
                    <div v-if="!filteredItems.length" class="trade-search-null">
                        검색된 아이템이 없습니다.
                    </div>
                      <perfect-scrollbar 
                        @ps-scroll-y="onScroll" 
                        :options="options"
                        :watch-options="watcher"
                        ref="scrollbar"
                        class="trade-search-won" id="tradeSearchWon"
                      >

                        <table class="table">
                          <colgroup>
                            <col style="width:40px">
                            <col style="width:200px">
                            <col style="width:150px">
                          </colgroup>
                          <tbody>

                            <tr 
                               v-for="(item, index) in filteredItems"                     
                               :key = index   
                             
                                                      
                            >
                              <td>
                                <button  @click="onFavorClick(item, $event)" type="button" class="btn btn-favor" :class="item._isFavor?'on':''">관심종목</button>
                                <a   @click="onItemClick(item, $event)"    href="javascript:;" class="searchSelectBorded"  :class="currentItem.KORNAME == item.KORNAME?'selected':''"></a>
                              </td>
                              <td>
                                <!-- <span class="name">{{tradeType=='F'?item.KORNAME + ' ('+ item.YEAR +'/'+ item.MONTH +')'  :item.NAME}}</span> -->
                                <span class="name" v-if="tradeType=='S'">{{ item.NAME }}</span>
                                <span class="name" v-if="tradeType=='F'">
                                  {{ item.KORNAME + ' ('+ item.YEAR +'/'+ item.MONTH +')' }} <strong class="text-danger fs-12" v-if="getIsEndday(item.LIQUIDATIONDATE)">만기일</strong>
                                </span>
                                <!-- <span class="krw">{{item.CODE?item.CODE.replace('-','/'):''}}</span> -->
                              </td>
                              <td>
                                <span class="present">{{item.CODE}}</span>
                              </td>                 
                            </tr>
                
                          </tbody>
                        </table>
                      </perfect-scrollbar>
                      <!-- //원화마켓 내용 end -->
                      
                    </div>
                    <!-- //원화마켓 end -->
    
                    
    
                    <!-- 관심코인 start -->
                    <div class="tab-pane fade" id="tradeSearchInterestTab" role="tabpanel" aria-labelledby="trade-search-interest-tab">
                      <div class="trade-search-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                      <!-- 관심코인 상단 시작  -->
                      <div class="trade-search-summary">
                        <table class="table">
                          <colgroup>
                            <col style="width:40px">
                            <col style="width:200px">
                            <col style="width:150px">
                            <!-- <col style="width:75px">
                            <col style="width:95px"> -->
                          </colgroup>
                          <tr>
                            <th colspan="2">
                              <button type="button" class="btn">
                                종목
                                <!-- <span class="ico"><img src="@/assets/images/trade/search_change.png" alt="한글명"></span> -->
                              </button>
                            </th>
                            <th>
                              <button type="button" class="btn btn-sort-arrow">
                                코드
                                <!-- <span class="ico"><i class="sort"></i></span> -->
                              </button>
                            </th>                      
                          </tr>
                        </table>
                      </div>
                      <!-- //관심코인 상단 종료 -->

                      <!-- 관심코인 내용 start -->
                      <div v-if="!favorItemList.length">
                        관심종목이 없습니다.
                      </div>

                      <div v-else-if="!filteredItems_favor.length">
                        검색된 아이템이 없습니다.
                      </div>
                      <!-- <div class="trade-search-interest" id="tradeSearchInterest"> -->
                      <perfect-scrollbar 
                        @ps-scroll-y="onScroll" 
                        :options="options"
                        :watch-options="watcher"
                        ref="scrollbar"
                        class="trade-search-interest" id="tradeSearchInterest"
                      >
                        <table class="table">
                          <colgroup>
                            <col style="width:40px">
                            <col style="width:200px">
                            <col style="width:150px">
                          </colgroup>
                          <tbody>
                
                             <tr 
                               v-for="(item, index) in filteredItems_favor"                     
                               :key = index                                                     
                              >
                              <td>
                                 <button  @click="onFavorClickInFavor(item)" type="button" class="btn btn-favor " :class="item._isFavor?'on':''">관심종목</button>
                                <a   @click="onItemClick(item, $event)"    href="javascript:;" class="searchSelectBorded"  :class="currentItem.KORNAME == item.KORNAME?'selected':''"></a>
                              </td>
                              <td>
                                <!-- <span class="name">{{tradeType=='F'?item.KORNAME + ' ('+ item.YEAR +'/'+ item.MONTH +')'  :item.NAME}}</span> -->
                                <span class="name" v-if="tradeType=='S'">{{ item.NAME }}</span>
                                <span class="name" v-if="tradeType=='F'">
                                  {{ item.KORNAME + ' ('+ item.YEAR +'/'+ item.MONTH +')' }} <strong class="text-danger fs-12" v-if="getIsEndday(item.LIQUIDATIONDATE)">만기일</strong>
                                </span>                                
                                <!-- <span class="krw">{{item.CODE?item.CODE.replace('-','/'):''}}</span> -->
                              </td>
                              <td>
                                <span class="present">{{item.CODE}}</span>
                              </td>                 
                            </tr>
           
                          </tbody>
                        </table>
                      </perfect-scrollbar>
                      <!-- </div> -->
                      <!-- //관심코인 end -->

                    </div>
                    <!-- //관심코인 end -->
    
                  </div>
                </div>
              </div>

            </div>
            <!-- //좌측 : 종목 조회 end -->
</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import Constant from '@/constant.js';
import {useApicall} from '@/composables/commonFn';
import { useStore } from 'vuex';
import moment from 'moment';

import {ref, getCurrentInstance, onMounted, onUnmounted, computed} from 'vue';
export default {
    components: {
        PerfectScrollbar
    },
    props:{
      tradeType:{
         type: String,
         require:true
      },
      items:{
          type:Array,
          required: true
      }
    },
  setup(props){
        //const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
        const $ = require('jquery');
        $ 
       
        var ispossibleSelect = true;
        const {apiCall_pt, apiCall_gt} = useApicall();
        const currentItem = ref({});
        const scrollbar = ref(null);
        const searchText = ref('');
        const store = useStore();
        const isLogin = computed(() => store.state.user.isLogin);
      
        const favorItemList = ref([]);
        const filteredItems = computed(()=>{
          if(searchText.value){
              return props.items.filter(item => {
                  //return  item.NAME.toLowerCase().includes(searchText.value.toLowerCase()) || item.CODE.toLowerCase().includes(searchText.value.toLowerCase());
                   return  item.TYPE == 'F'? item.KORNAME.toLowerCase().includes(searchText.value.toLowerCase()) || item.CODE.toLowerCase().includes(searchText.value.toLowerCase()):item.NAME.toLowerCase().includes(searchText.value.toLowerCase()) || item.CODE.toLowerCase().includes(searchText.value.toLowerCase());
              })
          }
          return props.items;
        });

        const filteredItems_favor = computed(()=>{
          if(searchText.value){
              return favorItemList.value.filter(item => {
                  //return  item.NAME.toLowerCase().includes(searchText.value.toLowerCase()) || item.CODE.toLowerCase().includes(searchText.value.toLowerCase());
                   return  item.TYPE == 'F'? item.KORNAME.toLowerCase().includes(searchText.value.toLowerCase()) || item.CODE.toLowerCase().includes(searchText.value.toLowerCase()):item.NAME.toLowerCase().includes(searchText.value.toLowerCase()) || item.CODE.toLowerCase().includes(searchText.value.toLowerCase());
              })
          }
          return favorItemList.value;
        });



        const { emit } = getCurrentInstance();



        const onItemClick = (item) =>{
        
            if(!ispossibleSelect) return;
            ispossibleSelect = false;
            if(!isLogin.value)
            {
              emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다."});
              return;
            }
            currentItem.value = item;            
            emit('onSelectItem',item)

            setTimeout(()=>{
                 ispossibleSelect = true;
            }, 2000)
        }




        const addFavorList = async(_accountId, _tradeType, _code) =>{
          var params =   {
              "accountId": _accountId,
              "type": _tradeType,
              "code": _code
          }

          var res = await apiCall_pt(`vspace/favorateitemregister`, params, null, (message)=>{
            emitter.emit('EVENT_ALERT',{title:"알림", body:message});
          });
         // console.log("addFavorList: ", res)
          if(!res)return false   
          
          return true;
        }




        const removeFavorList = async(_code) =>{
          var params =   {
              "code": _code,
              "accountId":store.state.hts.currentAccountId
          }

          var res = await apiCall_pt(`vspace/favorateitemdelete`, params, null, (message)=>{
            emitter.emit('EVENT_ALERT',{title:"알림", body:message});
          });

          if(!res)return

           return true;
        }

        const onFavorClickInFavor = async(item) =>{
          if(await removeFavorList(item.CODE))
          {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"관심종목에서 삭제되었습니다."});
             getFavorItemList();
              var found =  props.items.find(element => element.CODE == item.CODE);
              if(found)
              {                 
                found._isFavor = false;                   
              }

          }
        }


        const onFavorClick = async (item, e) =>{
          // console.log("onFavorClick:", item,  e.target.classList.contains('on'))


          if(e.target.classList.contains('on'))
          {
              //관심종목 지움
              
              if(await removeFavorList(item.CODE))
              {
                  item._isFavor = false;
                   //e.target.classList.remove('on');
                     emitter.emit('EVENT_ALERT',{title:"알림", body:"관심종목에서 삭제되었습니다."});
              }
              else
              {
                  // e.target.classList.add('on');
                    item._isFavor = true;
              }

          }
          else
          {          
            
              //관심종목 추가
              if(!store.state.hts.currentAccountId)
              {
                emitter.emit('EVENT_ALERT',{title:"알림", body:"대회 참가후 사용가능합니다."});
                return;
              }
               
              if(await addFavorList(store.state.hts.currentAccountId, store.state.hts.currentTradeType, item.CODE))
              {
                

                  item._isFavor = true;
                 //  e.target.classList.add('on');
                  emitter.emit('EVENT_ALERT',{title:"알림", body:"관심종목에 추가되었습니다."});
                   
              }
              else
              {
                 item._isFavor = false;
                //   e.target.classList.remove('on');
                 
              }
              
          }
          
         // e.target.classList.toggle('on');
         // e.target.classList.remove('on');
        }

       // const periodInitData = computed(()=>store.state.hts.periodInitData);

      const getFavorItemList = async() =>{
          var res = await apiCall_gt(`vspace/getfavorateitemlist?accountId=${store.state.hts.currentAccountId}&type=${store.state.hts.currentTradeType}`);
          if(!res || res == 'unLogin') return false;
          favorItemList.value = res.favorateList;
         //console.log("_isFavor", favorItemList.value)
         if(!favorItemList.value)favorItemList.value =[];
          favorItemList.value.forEach(_element => {           
             _element._isFavor = true;
             var found =  props.items.find(element => element.CODE == _element.CODE);
             if(found)
             {
                  found._isFavor = true;                  
             }
          });

      }
   
       onMounted(()=>{
        setTimeout(getFavorItemList, 1000)
        
       })
       
       
       onUnmounted(()=>{
       })

       const onFavorTapClickd = ()=>{
         getFavorItemList()
       }

        const onFocus = (e) =>{
            console.log("onFocus", e)  
        }

        const onKeyup =(e)=>{
         if(e.keyCode == 13)
         {             
             scrollbar.value.$el.scrollTop = 0;
         }
        }
        

        const onSearchDel = ()=>{
          searchText.value = ''
        }

        const onScroll = (e) =>{
            e
            // trace(e.target.scrollTop); 
        }


        const getIsEndday = (dateStr)=>{
             var result = false;
             var currentDate = moment(new Date()).subtract(8, 'h' ).format('YYYYMMDD') ;
             //console.log("currentDate :", currentDate, dateStr );
             if(currentDate == dateStr )
             {
                 result = true;
             }
             return result;
        }


    return{

           options: {       
                wheelSpeed: 1,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: false,
            },
            watcher: true,
            onScroll,
            scrollbar,
           
            onItemClick,
            onFavorClick,
            currentItem,
            Constant,
            searchText,
            onKeyup,
            onFocus,
            filteredItems,
            favorItemList,
            onFavorTapClickd,
            onSearchDel,
            onFavorClickInFavor,
            filteredItems_favor,
            getIsEndday
            
    }
  }

}
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css">

</style>