<template>

      <div class="login-f-wrap">
        <div class="login-f-contents py-4">
          <div class="login-f-header">
            <h1 class="text-center"><img src="@/assets/images/common/logo.png" alt="logo"></h1>
          </div>
          <div class="login-f-container my-4">
            <div class="login-f-conts">

              <div class="agree-box">
                <p>이용약관동의 <span class="text-danger">(필수)</span></p>
                <div class="agree-inner">
                  <ul class="signup">
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="chkTerms" ref="checkAll_1" @input="onCheckAll()">
                        <label class="form-check-label" for="chkTerms">
                          전체동의
                        </label>
                      </div>
                    </li>
                    <li>
                      <div class="agree-col2">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" name="chkTerms" id="chkTerms1"  ref="check1_1"  @input="onChange" checked disabled>
                          <label class="form-check-label" for="chkTerms1">
                            이용약관 동의(필수)
                          </label>
                        </div>
                        <div class="right">
                          <button type="button" class="btn" data-bs-toggle="collapse" data-bs-target="#terms1" aria-expanded="false" aria-controls="terms1" readonly >보기</button>
                        </div>
                      </div>

                      <div class="agree-text collapse multi-collapse" id="terms1">
                        <textarea row="10" readonly>
이용약관

제1조 목적
이 약관은 주식회사 플렉스머니(이하 "회사"라 합니다)이 제공하는 서비스(이용자가 PC, 멀티미디어 모바일 등의 각종 디지털기기 또는 프로그램을 통하여 이용할 수 있도록 회사가 제공하는 모든 서비스를 의미합니다)와 관련하여, 회사와 이용자 간에 서비스 이용조건 및 절차, 권리ㆍ의무 및 책임사항 기타 필요한 사항을 규정함을 목적으로 합니다.

제2조 용어의 정의
① “서비스”라 함은 접속 가능한 유, 무선 단말기의 종류와는 상관없이 이용자(또는 회원)가 이용할 수 있는 제반 “서비스”를 의미합니다.
② “이용자”이라 함은 회사의 “서비스”에 접속하여 이 약관에 따라 “회사”가 제공하는 “서비스”를 이용하는 회원, 비회원 고객을 말합니다.
③ “제휴사”란(이하 “제휴사”) 회사와 제휴 계약하여 서비스를 제공하는 자를 말합니다. 
④ “회원”이라 함은 본 약관 제5조에 정해진 절차에 따라 회원등록을 한 자로서, 회사 또는 제휴사 정보를 지속적으로 제공받으며, 서비스를 계속적으로 이용할 수 있는 자를 말합니다. 
⑤ “비회원”이란 회원으로 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
⑥ "통합회원 아이디(ID)"라 함은 "이용자"의 식별과 "서비스" 이용을 위하여 "이용자"가 정하고 "회사"가 승인하는 문자와 숫자의 조합을 의미합니다.
⑦ "비밀번호"라 함은 "이용자"가 부여받은 "아이디”와 일치되는 "이용자"임을 확인하고 비밀보호를 위해 "이용자" 자신이 정한 문자 또는 숫자의 조합을 의미합니다.
⑧ "유료 서비스"라 함은 "회사" 또는 ”제휴사”를 통해  "회원"이 유료로 제공하는 각종 온라인 디지털 콘텐츠(각종 정보 콘텐츠, VOD, 플렉스(캐시), 아이템 기타 유료 콘텐츠를 포함) 및 제반 서비스를 의미를 의미하며, 이는 서비스마다 차이가 있을 수 있습니다.
⑨ "게시물"이라 함은 이용자가 "서비스"를 이용함에 있어 "서비스상"에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
⑩ “포인트”란 회사와 “제휴사” 재화/서비스 구매 시 적립/사용이 가능하도록 제공하는 것을 의미합니다.
⑪ “모의투자대회”이라 함은 “회사”에서 개최하는 참가비가 무료/유료인 모의로 진행되는 투자 대회를 의미하며, 투자 대회를 통해 발생되는 수익은 현금화가 불가능합니다
⑫ “재도전”이라 함은 “이용자”가 “모의투자대회”에서 “수익률” 혹은 “잔고”를 최신화하여 다시 도전할 수 있는 것을 의미합니다.
⑬ “수익률”이라 함은 “모의투자대회”의 거래 수익 %를 의미합니다.
⑭ “잔고”라 함은 “이용자”가 “모의투자대회"에 참여했을때 주어지는 거래 가능 액수를 의미합니다.
⑮ “랭킹”이라 함은 각 “모의투자대회”에서 참가한 “이용자”들의 순위를 의미합니다.

제3조 약관의 효력과 변경
① 본 약관의 내용은 홈페이지 및 서비스 화면에 이를 공시함으로써 효력을 발생합니다.
② “회사”는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률”등 관련법에 위배하지 않는 범위에서 이 약관을 변경할 수 있으며, 약관을 변경할 경우에는 “이용자”이 쉽게 알 수 있도록 서비스 초기화면에 최소 15일 전부터 공지합니다. 다만, 이용자에게 불리한 약관의 개정의 경우에는 그 적용일자 30일 전부터 적용일 이후 상당기간 동안 각각 이를 서비스 홈페이지에 공지하여 그 내용을 확인할 수 있게 됩니다.
③ 이용자는 변경된 약관에 대해 동의하지 않을 권리가 있으며, 시행일로부터 10일(이용자에게 불리하거나 중대한 사항의 변경인 경우에는 30일) 내에 변경된 약관에 대해 거절의 의사를 표시하지 않았을 때에는 본 약관의 변경에 동의한 것으로 간주합니다
④ 이용자는 변경된 약관에 동의하지 않으면, 언제나 “서비스” 이용을 중단하고, 이용계약을 해지할 수 있습니다. 약관의 효력 발생일 이후의 계속적인 “서비스” 이용은 약관의 변경사항에 대한 이용자의 동의로 간주됩니다.

제4조 서비스의 구분
① “회사”와 제휴사 이용자에게 제공하는 “서비스”는 무료 서비스, 유료 서비스, 적립 서비스 등으로 구분합니다.
② 무료 서비스, 유료 서비스 등의 종류와 이용방법 등은 이 약관 및 "회사"와 각 제휴사가 공지 또는 이용안내에서 별도로 정하는 바에 의합니다.
③ 서비스 이용등급은 콘텐츠 초기 화면에 표시된 이용등급정보에 따르며, "회사"와 제휴사 이용자에 대하여 "청소년 보호법" 등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.
④ 회원은 회사와 제휴사에서 재화 또는 상품, 서비스 구입. 이벤트를 통해 포인트를 적립받을 수 있으며, 포인트는 서비스 사정에 따라 회원에게 공지 후 이용범위나 가치가 달라질 수 있습니다. 적립된 포인트는 양도/판매가 불가하며, 탈퇴 시 일정 유예기간 이후 자동으로 소멸됩니다..

제5조 모의투자대회 이용시간
① 모의투자대회에 관한 서비스의 이용은 연중무휴, 1일 24시간을 원칙으로 합니다. 다만 서비스 중 일부에 관하여 별도의 제공시간을 정할 수 있고, 이 경우 관련사항을 회사 홈페이지에 공지합니다.

제6조 모의투자대회 이용료
① 모의투자대회 관한 서비스는 회사가 별도로 정하는 기간까지 무료로 합니다. 단, 회사가 모의투자대회에 관한 서비스를 유료화하는 경우에는 시행 1개월 전에 이를 회사 홈페이지에 공지합니다.

제7조 이용계약의 성립 및 체결 단위
① 이용계약은 이용자의 이용신청에 대한 "회사" 또는 제휴사를 통한 이용 승낙으로 성립합니다.
② 이용계약은 아이디 단위로 체결합니다.
③ "서비스"의 대량 이용 등 특별한 "서비스" 이용에 관한 계약은 별도의 계약으로 합니다.
④ 회사 또는 제휴사를 통해 이용신청을 하는 과정에서 ‘본인 인증’을 요구할 수 있습니다. 본인 인증이 필요한 경우, 회사는 본인 인증을 하지 않은 아이디에 한해 일정 기간 이후 계약 체결을 취소할 수 있습니다.

제8조 이용신청
① 이용자가 "서비스"의 이용을 원할 경우 인터넷 접속 등의 방식으로 "회사" 또는 제휴사에서 제공하는 가입신청 양식에서 요구하는 사항을 기입하여 이용신청을 하여야 합니다.
② "회사"는 이 약관의 주요 내용을 이용자에게 고지하여야 합니다.
③ 이용자가 전항의 고지 하단에 있는, 동의 버튼을 클릭하거나 또는 체크박스에 체크하고 이용신청을 하는 것은 이 약관에 대한 동의로 간주됩니다.
④ 이용신청은 이용자 자신의 실명으로 하여야 합니다.
⑤ 이용자는 회사가 제공하는 본인 확인 방법을 통하여 서비스 이용신청을 하여야 하며, 본인 명의로 등록하지 않은 이용자는 일체의 권리를 주장할 수 없습니다.
⑥ 타인의 정보(아이핀 및 휴대 전화 정보 등)를 도용하여 이용신청을 한 이용자의 허위 계정은 서비스 이용이 정지됩니다.
⑦ “이용신청 고객”이 미성년자(만 14세 미만)인 경우 이용이 불가능할 수 있으며, 서비스마다 상이할 수 있습니다.

제9조 이용신청의 승낙
① "회사"는 전조의 규정에 의한 이용신청에 대하여 업무수행상 또는 기술상 지장이 없는 경우에는 원칙적으로 지체 없이 이용신청을 승낙합니다.
② "회사"는 이용신청을 승낙하는 때에는 다음 각호의 사항을 이용자에게 "서비스"를 통하거나, 전자우편 등의 방법으로 통지합니다.
 1. 아이디
 2. 이용자의 권익보호 및 의무 등에 관한 사항
 3. 기타 이용자가 "서비스" 이용 時 알아야 할 사항

제10조 이용신청에 대한 불승낙과 승낙의 보류
① "회사"는 다음 각호에 해당하는 이용신청에 대하여는 승낙을 하지 아니할 수 있습니다.
 1. 제6조 또는 제12조 제6항을 위반한 경우
 2. 허위의 신청이거나 허위서류를 첨부한 경우
 3. 이용자가 회사에 납부하여야 할 요금 등을 납부하지 않은 경우
 4. 기타 이용자의 귀책사유로 이용 승낙이 곤란한 경우

② "회사"는 다음 각호에 해당하는 사유가 있는 경우에는 그 사유가 해소될 때까지는 승낙을 보류할 수 있습니다.
1. 회사의 설비에 여유가 없는 경우
2. "서비스"에 장애가 있는 경우
3. 제6조 제7항에 해당하는 경우

제11조 아이디 부여 등
① "회사"는 원칙적으로 이용자가 신청한 대로 아이디를 부여합니다.
② 아이디는 변경할 수 없는 것을 원칙으로 합니다.
③ 아이디 또는 닉네임이 제12조 제6항 각호에 해당하는 경우에는 "회사"및 각 서비스사는 해당 이용자에 대한 "서비스" 제공을 중단하고, 새로운 아이디 또는 닉네임으로 이용신청할 것을 권할 수 있습니다. 닉네임에 대한 구체적인 정책은 각 서비스의 이용약관에 따릅니다.

제12조 회사의 의무
① "회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.
② "회사"는 계속적이고 안정적인 "서비스"의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는 이를 지체 없이 수리 또는 복구하며, 다음 각 호의 사유 발생 시 부득이한 경우 예고 없이 서비스의 전부 또는 일부의 제공을 일시 중지할 수 있습니다. 이 경우 그 사유 및 중지 기간 등을 이용자에게 지체 없이 사후 공지합니다.
 1. 회사가 긴급한 시스템 점검, 증설, 교체, 시설의 보수 또는 공사를 하기 위하여 부득이한 경우
 3. 시스템 또는 기타 서비스 설비의 장애, 유무선 Network 장애 등으로 정상적인 서비스 제공이 불가능할 경우
 4. 천재지변, 국가비상사태, 정전 등 회사가 통제할 수 없는 불가항력적 사유로 인한 경우
③ "회사"는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다.
④ “회사”는 대표자의 성명, 상호, 주소, 전화번호, 모사전송 번호(FAX), 통신판매업 신고번호, 이용약관, 개인정보취급방침 등을 이용자가 쉽게 알 수 있도록 온라인 서비스 초기화면에 게시합니다.

제13조 개인정보보호
"회사"는 이용자들의 개인정보를 중요시하며, 정보통신망법, 개인정보보호법, 전기통신사업법 등 관련 법규를 준수하고 있습니다. "회사"는 개인정보보호방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

제14조 이용자의 의무
① 이용자는 회원가입을 통해 이용신청을 할 경우 사실에 근거하여 작성하여야 합니다. 이용자가 허위, 또는 타인의 정보를 등록한 경우 회사에 대하여 일체의 권리를 주장할 수 없으며, 회사는 이로 인하여 발생하는 손해에 대하여 책임을 부담하지 않습니다.
② 이용자는 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 회사가 공지하는 사항을 준수하여야 합니다. 또한 이용자는 회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위를 해서는 안됩니다.
③ 이용자는 청소년 보호법 등 관계 법령을 준수하여야 합니다. 이용자는 청소년 보호법 등 관계 법령을 위반한 경우는 해당 법령에 의거 처벌을 받게 됩니다.
④ 이용자는 주소, 연락처, 전자우편 주소 등 이용계약 사항이 변경된 경우에 즉시 온라인상에서 이를 수정해야 합니다. 수정을 하지 않거나 수정 지연으로 발생되는 책임은 이용자에게 있습니다.
⑤ 이용자는 서비스 아이디와 비밀번호를 직접 관리해야 합니다. 이용자의 관리 소홀로 발생한 문제는 회사에서 책임지지 않습니다.
⑥ 이용자가 아이디, 닉네임, 기타 서비스 내에서 사용되는 명칭 등의 선정 시에는 다음 각 호에 해당하는 내용을 사용하여서는 안됩니다.
 1. 회사가 제공하는 서비스의 공식 운영자를 사칭하거나 유사한 명칭을 사용하여 다른 이용자에게 혼란을 초래하는 행위
 2. 선정적이고 음란한 내용이 포함된 명칭의 사용
 3. 제3자의 상표권, 저작권 등의 권리를 침해할 가능성이 있는 명칭의 사용
 4. 비어, 속어라고 판단되거나 반사회적이고 공서양속을 해치는 내용이 포함된 명칭의 사용
 5. 주민등록번호, 전화번호 등 개인정보 유출 또는 사생활 침해의 우려가 있는 경우
 6. 관계법령에 저촉되거나 기타 이용자의 보호를 위한 합리적인 사유가 있는 경우
⑦ 이용자는 회사의 명시적 동의가 없는 한 서비스 이용 권한, 기타 이용 계약상의 지위를 타인에게 매도, 증여할 수 없으며 무형자산을 담보로 제공할 수 없습니다.
⑧ 이용자는 회사에서 제공하는 서비스를 본래의 이용목적 이외의 용도로 사용해서는 안됩니다. 이용자는 아래 각 호의 행위를 하여서는 안되며, 이에 해당되는 행위를 할 경우에는 본 약관 및 각 서비스 별로 공지하는 운영정책에 따라 이용자의 서비스 이용을 제한하거나 계정의 삭제, 수사기관의 고발 조치 등 적법한 조치를 포함한 제재를 가할 수 있습니다.
 1. 회원 가입 또는 변경 시 개인정보에 허위 내용을 기재하는 행위
 2. 타인의 개인 정보를 도용하거나 부정하게 사용하는 행위
 3. 이용자의 아이디 등 사이버 자산을 타인과 매매하는 행위
 4. 회사의 운영진 또는 직원을 사칭하는 행위
 5. 회사 프로그램 상의 버그를 악용하는 행위
 6. 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 하는 행위
 7. 다른 이용자를 희롱 또는 위협하거나 다른 이용자에게 고통, 피해, 또는 불편을 주는 행위
 8. 회사로부터 승인받지 아니하고 각 서비스의 클라이언트 프로그램을 변경하거나, 서버를 해킹하거나 또는 웹사이트의 일부분을 변경하는 행위, 또는 이러한 목적으로 개발, 유포되는 프로그램을 사용하거나 이의 사용을 장려 또는 광고하는 행위
 9. 조회수 조작, 기타 부정한 목적으로 다량의 정보를 전송하여 서비스의 안정적인 운영을 방해하는 행위
 10. 회사 또는 다른 이용자의 게시물, 영상 기타 광고의 전송을 방해하거나 훼손, 삭제하는 행위
 11. 회사의 승인을 받지 않고 다른 이용자의 개인정보를 수집 또는 저장하는 행위
 12. 수신자의 의사에 반하는 광고성 정보, 전자우편을 지속적으로 전송하는 경우
 13. 타인의 저작권, 상표권, 특허권 등의 지적재산권을 침해하는 경우
 14. 회사의 허가 없이 소프트웨어 또는 하드웨어를 이용하여 서비스의 내용에 권한 없이 관여하는 소프트웨어 혹은 하드웨어를 사용, 배포하거나 사용을 장려하거나 이에 대한 광고를 하는 행위
 15. 회사가 제공하는 서비스 프로그램의 이용방식, 기획의도를 변경하거나 서비스에 비정상적으로 위해를 가하거나 고의로 방해하는 일체의 행위
 16. 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제3자에게 제공하는 행위
 17. 공공질서 및 미풍양속에 위반되는 저속 하거나 음란한 내용의 정보, 문장, 도형, 음향, 동영상 또는 불법 복제된 소프트웨어를 전송, 게시, 전자우편 또는 기타 방법으로 타인에게 유포하는 등 불법적인 목적으로 서비스를 이용하는 행위
 18. 결제기관을 속여 부정한 방법으로 결제하거나 지불 거절(Chargeback)을 악용하여 정당한 이유 없이 유료 서비스를 구매하거나 환불하는 경우
 19. 정당한 후원의 목적 외에 자금을 제공 또는 융통하기 위해 휴대폰이나 상품권 등을 통해 유료 서비스를 이용, 결제, 구매, 환전, 환불하는 행위. 이를 돕거나 권유, 알선, 중개 기타 광고하는 행위
 20. 국가기관으로부터 이용제한 심의, 의결을 받은 경우
 21. 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위 및 기타 관계 법령에 위배되는 행위

제15조 서비스의 제한 등
① "회사"는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
② 무료 서비스는 전항의 규정에도 불구하고, "회사"의 경영정책 등의 사유로 서비스의 전부 또는 일부가 제한되거나 중지될 수 있으며, 유료로 전환될 수 있습니다.
③ "회사"는 "서비스"의 이용을 제한하거나 그 사유 및 제한기간, 예정일시 등을 지체 없이 이용자에게 알리고, 무료서비스를 유료서비스로 전환하는 떄에는 시행 1개월 전에 이를 회사 홈페이지에 공지합니다’
④ 회사는 최종 사용일로부터 연속하여 1년 동안 서비스 사용 이력이 없는 경우 정보통신망법에 따라 개인정보가 파기되는 사실, 기간 만료일 및 파기되는 개인정보의 항목 등을 이용자에게 본 약관 제16조 제1항의 방법으로 알린 후 회원정보를 영구적으로 삭제할 수 있습니다. 단, 유료결제 상품을 보유하고 있을 경우 삭제 대상에서 제외되며 관련 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정기간 동안 회원정보를 보관합니다.

제16조 서비스의 제공 중단, 이용제한 등
① "회사"는 이용자가 유료 서비스의 요금 등을 지정한 기일로부터 1개월 이상 납부하지 않을 경우에는 전부 또는 일부의 서비스 제공을 중단할 수 있습니다.
② 제12조의 규정에 의하여 이용자의 이용을 제한하는 경우, 제한의 종류 및 기간 등 구체적인 기준은 "회사"의 운영정책, 공지, 이용안내 등에서 별도로 정하는 바에 따릅니다.

제17조 이용제한 및 해제 절차
① "회사"가 이용제한을 하고자 하는 때에는 그 사유, 일시 및 기간을 정하여 사전에 전화 또는 전자우편 등의 방법으로 해당 이용자에게 통지합니다. 다만, 긴급하게 이용을 제한할 필요가 있을 경우에는 그러하지 않습니다.
② 전항의 규정에 의하여 이용정지의 통지를 받은 이용자는 그 이용정지의 통지에 대하여 이의가 있을 때에는 이의신청을 할 수 있습니다.
③ "회사"는 이용정지 기간 중에 그 이용정지 사유가 해소된 것이 확인된 경우에는 이용정지 조치를 즉시 해제합니다.
④ "회사"는 이용자의 부당행위가 고의 또는 과실이 없었음이 입증된 경우에는 이용자가 이용 중인 유료 서비스에 대한 이용기간을 정지된 기간만큼 연장해주도록 합니다.

제18조 서비스의 해제, 해지 등
① 이용자가 이용 계약을 해지하고자 하는 때에는 본인이 직접 “회사”의 서비스 홈페이지를 통하거나 서면 또는 전화의 방법으로 “회사”에 신청하여야 합니다.
② “회사”는 전항의 규정에 의하여 해지 신청이 접수되면 지체 없이 서비스의 이용을 제한합니다. 단, 이용자는 30일 이내의 유예 기간 동안 이용 계약 해지를 철회할 수 있습니다. 
③ “회사”는 이용계약을 해지하고자 할 경우에는 해지조치 7일전까지 그 뜻을 이용자에게 통지하여 의견을 진술할 기회를 주어야 합니다. 다만, 다음 각호의 어느 하나에 해당하는 경우에는 즉시 해지를 할 수 있습니다.
 1. 이용자가 관련 법령 또는 약관 기타 서비스 취지에 반하는 중대한 위법, 부정행위를 한 경우
 2. 타인명의 이용신청 또는 허위의 이용신청, 허위서류를 첨부한 이용신청임이 확인된 경우
 3. 이용자가 명백히 약관상 의무이행을 거절하는 의사를 표시하거나 즉시 탈퇴처리에 동의한 경우
④ “회사”는 해지된 이용자의 이용신청에 대하여 해지 후 일정기간 동안에는 불승낙을 할 수 있습니다.
⑤ “회사”는 계약이 해지된 이용자가 "서비스"에 제공하거나, 보관 중인 자료 및 제공한 개인정보를 삭제하고, 이와 관련하여 어떠한 책임도 부담하지 않습니다. 단, 제19조와 제22조에 따라 별도의 계약이 성립된 자료는 삭제하지 않을 수 있습니다.
⑥ “회사”는 통합 아이디를 통해 가입한 개별 서비스가 1건 이상 존재하는 경우. 이를 통합 회원 이용으로 간주하며, 이용중인 서비스가 완료되지 않았거나 Cash가 남아있을 경우 유료 서비스 이용약관 제9조에 따라 해지 신청을 거부할 수 있습니다. 원치 않을 경우, 회원은 “회사”가 정한 별도의 이용 방법으로 해지 신청을 할 수 있습니다.

제19조 손해배상
① "회사"는 무료 서비스의 장애, 제공 중단, 보관된 자료 멸실 또는 삭제, 변조 등으로 인한 손해에 대하여는 배상하지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 경우는 제외합니다.
② "회사"는 "회사"의 귀책사유로 인하여 이용자에게 서비스를 제공하지 못하는 경우 유료 서비스 이용자에게는 공정거래위원회가 고시한 소비자분쟁해결기준에 따라 보상합니다.
③ "회사"는 "콘텐츠"의 하자, 이용중지 또는 장애 등에 의하여 발생한 "이용자"의 손해에 대하여 보상 또는 배상합니다.
④ 제3항에 따른 손해배상 등의 방법 및 절차는 "콘텐츠 이용자 보호지침"에 따라 처리하며, 기타 손해배상의 방법, 절차 등은 관계법령에 따릅니다.

제20조 면책
① "회사"는 이용자가 "회사"의 "서비스" 제공으로부터 기대되는 이익을 얻지 못한 데 대하여 책임이 없습니다.
② "회사"는 제3자가 게시 또는 전송한 자료로 인한 이용자의 손해에 대하여는 책임이 없습니다.
③ "회사"는 이용자 상호 간 또는 이용자와 제3자 간에 "서비스"를 매개로 발생한 분쟁에 대하여는 개입할 의무도 이로 인한 손해를 배상할 책임도 없습니다.
④ “회사”의 책임 있는 사유로 인하여 발생한 손해에 관하여는 제1항 내지 제3항을 적용하지 아니합니다.
⑤ “회사”는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 대한 책임이 면제 됩니다.
⑥”회사”는 “이용자”의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.
⑦”회사”는 서비스용 설비의 보수, 교체, 정기점검 등 계약상 의무를 성실히 이행하기 위한 행위로 인하여 부득이하게 “이용자”에게 발생한 손해에 대하여 책임을 지지 않습니다.
⑧”회사”는 당사 제공 서비스 관련하여 자신이 회사에 등록한 항목 및 비밀번호 보안에 대한 책임을 지며 “회사”는 “회사”의 고의 또는 과실 없이 ID, 비밀번호 등의 회원정보가 유출되어 발생하는 손해에 대해 책임을 지지 않습니다.
⑨”회사”는 “이용자”의 컴퓨터의 오류에 의해 손해가 발생한 경우 또는 “이용자” 본인이에 대한 정보를 부실하게 기재하여 손해가 발생하는 경우에는 책임을 지지 않습니다.
⑩”회사”는 “이용자”가 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 “회사”는 “이용자”가 서비스를 이용하며 타 “이용자”로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
⑪”회사”는 “이용자” 상호간 및 “이용자” 제3자 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임이 없습니다.
⑫”회사”는 “이용자”가 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.
⑬”회사”는 정보통신망에 대한 감청, 해킹(hacking) 등으로 인해 발생한 손해에 대하여 책임을 지지 않습니다.
⑭”회사”는 기간통신사업자의 장애를 포함한 제휴 정보통신 장애로 불가피하게 발생하는 손해와 한국거래소의 시스템장애, 정보제공지연 등으로 인한 손해에 대하여 책임을 지지 않습니다.

제21조 자료의 저장
"회사" 또는 “제휴사”는 이용자가 제공하는 정보, 자료에 대하여 회사의 공지, 서비스 이용안내에서 정한 바에 따라 일정한 게재 기한 및 용량을 정할 수 있습니다.

제22조 공개 게시물 등의 관리
① "회사"는 건전한 통신문화 정착과 효율적 서비스 운영을 위하여 다음 각호에 해당하는 공개 게시물 및 자료의 게시중단, 삭제, 이동, 등록 거부하는 등의 필요조치를 취할 수 있습니다. 이 경우 게시물 및 자료의 삭제 등에 대하여 회사는 귀책사유가 없는 한 어떠한 책임도 부담하지 않습니다.
 1. 타인의 법률상 이익을 침해하는 행위와 관련된 것으로 추정되는 게시물, 자료로서, 이해당사자의 삭제 등 요청이 있거나, 회사가 피소, 고발될 수 있는 사유를 제공하는 게시물, 자료
 2. 서비스에 위해를 가할 소지가 있는 바이러스 등이 포함된 게시물, 자료
 3. 제20조에 의하여 정해진 게재 기한 및 용량을 초과한 게시물, 자료(이 경우 회사는 사전에 해당 이용자에게 삭제, 이동 등에 관한 안내를 할 수 있습니다)
 4. 제12조 제8항 각호를 위반하여 다른 이용자의 서비스 이용에 현저한 지장을 초래하는 게시물, 자료
 5. 전기통신 관계법령 및 형사 관계법령에 따른 국가기관 등의 삭제 등 요구가 있는 게시물, 자료
② "회사"는 게시물 등에 대하여 제3자로부터 명예훼손, 지적재산권 등의 권리 침해를 이유로 게시중단 요청을 받은 경우 이를 임시로 게시중단(전송 중단)할 수 있으며, 게시중단 요청자와 게시물 등록자 간에 소송, 합의 기타 이에 준하는 관련기관의 결정 등이 이루어져 회사에 접수된 경우 이에 따릅니다.
③ 해당 게시물 등에 대해 임시 게시 중단이 된 경우, 게시물을 등록한 이용자는 회사에 소명자료를 첨부하여 이의신청을 할 수 있으며, 게시 중단 사유가 해소된 날로부터 30일 이내에 재게시를 요청하지 아니한 경우 회사는 이를 삭제할 수 있습니다.
④ 이용자는 “회사”에게 본인의 게시물 삭제를 요청할 수 있고, 사망한 이용자의 유족은 사망 사실과 가족관계를 증빙하여 그 이용자의 게시물의 이전, 접근 배제 또는 삭제를 요청할 수 있습니다.

제23조 정보의 제공
① "회사"는 이용자가 서비스 이용 중 필요하다고 인정되는 각종 정보를 게재하거나, 전자우편(E-Mail), 휴대폰 단문 메시지(SMS)와 알림(PUSH), 전화, 우편 등의 방법으로 이용자에게 제공(또는 전송)할 수 있습니다. 이용자는 이를 원하지 않을 경우에 회사가 제공하는 방법에 따라 수신을 거부할 수 있습니다.
② 전항 단서에 따라 수신을 거부한 이용자의 경우에도 이용약관, 개인정보보호정책, 기타 이용자의 이익에 영향을 미칠 수 있는 중요한 사항의 변경 등 이용자가 반드시 알고 있어야 하는 사항에 대해서는 전자우편 등의 방법으로 정보의 제공 등을 할 수 있습니다.
③ 회사는 광고주의 판촉 활동에 이용자가 참여하거나, 거래의 결과로써 발생하는 손실 또는 손해에 대해서는 책임을 지지 않습니다.

제24조 지적재산권
① 이용자는 "서비스"에 제공한 콘텐츠와 관련하여 저작권, 인격권 등 제 3자와의 분쟁 발생 시 그 법적 대응 및 결과에 대한 책임을 지며, "회사"는 해당 "콘텐츠"와 관련된 일체의 책임을 명시적으로 부인합니다.
② "회사"는 "서비스"를 통한 저작권 침해행위나 지적재산권 침해를 허용하지 아니하며, 이용자의 "콘텐츠"가 타인의 지적재산권을 침해한다는 사실을 적절하게 고지받거나 인지하게 되는 경우 저작권법 기타 관련 법령에서 정한 절차에 따라 그 "콘텐츠" 일체의 서비스를 중지하거나 제거할 수 있습니다. 또한 "회사"는 이용자의 "콘텐츠"가 제12조 제8항 각호 또는 제20조 제1항 각호에 해당할 경우 제15조 또는 제20조에서 정한 방법으로 “콘텐츠”를 삭제하거나 이동 또는 등록 거부할 수 있는 권리를 보유합니다.
③ 이용자의 "콘텐츠"에 대한 저작권은 원래의 저작자가 보유하되, 본 약관이 정하는 바에 따라 "회사" 혹은 "회사가 지정한 자"에게 "콘텐츠"에 대한 사용 권한을 부여합니다. "회사"가 이용자의 "콘텐츠"를 사용하는 용도와 방법은 아래와 같습니다.
 1. 이용자가 "서비스"에 제공한 "콘텐츠"를 다른 이용자가 시청하도록 함.
 2. 이용자가 "서비스"에 제공한 "콘텐츠"를 "회사"나 다른 이용자가 녹화/편집/변경하여 새로운 콘텐츠로 제작한 다음 이를 "서비스"의 다른 이용자들이 시청하게 하거나, 또는 "회사"의 제휴사에 제공하여 그 이용자들이 이를 시청할 수 있도록 함.
 3. 이용자가 "서비스"에 제공한 "콘텐츠"를 "회사"가 저장한 후 이를 VOD 등의 다시 보기 서비스로 다른 이용자들이 시청할 수 있게 하거나, "회사"의 제휴사에 제공함으로써 제휴사가 그 이용자들에게 스트리밍 또는 VOD 등의 다시 보기 서비스로 시청할 수 있게끔 함.
 4. 이용자가 “서비스”에 제공한 “콘텐츠”를 “회사”가 복제, 저장, 편집하여 “서비스” 홍보 목적으로 “회사”의 관리 채널 또는 블로그 등에 전송, 게시함.
 5. 이용자가 “서비스”에 제공한 “콘텐츠”를 분석 및 통계 작성, 기술 개발 기타 “서비스” 개선을 위한 목적으로 학술·연구기관·업체에게 제공함
④ 본 조 제3항에 규정한 "회사", 다른 이용자 및 "회사의 제휴사"가 이용자의 "콘텐츠"를 이용할 수 있는 조건은 아래와 같습니다.
 1. 콘텐츠 이용 매체/플랫폼 - 현재 알려져 있거나 앞으로 개발된 모든 형태의 매체, 장비, 기술을 포함함.
 2. 콘텐츠 이용 용도 - 상업적 또는 비상업적 이용을 구분하지 않음.
 3. 콘텐츠 이용 범위 - 국내 및 국외에서의 복제, 수정, 각색, 공연, 전시, 방송, 배포, 대여, 공중송신, 2차적 저작물 작성, 기타 이용
 4. 콘텐츠 이용 조건 - 비독점적, 지속적인 무상의 권리로서 양도 가능하며, 서브 라이선스가 가능함.
⑤ 이용자는 제3항에 따른 사용 허락을 "회사"가 공지, 이용안내에서 정한 바에 따라 장래에 대하여 철회할 수 있습니다.
⑥ “회사”가 이용자를 대신하여 저작권자로부터 저작물의 이용허락을 받을 경우 이용자가 지급한 유료 서비스 수수료의 일부는 그 이용요금을 지급하는 데에 사용됩니다. 단, 이용자는 “회사”가 안내·공지하는 저작물 이용조건 및 가이드를 준수하여야 하며, 이를 위반할 경우 이용자가 책임을 부담하여야 합니다.

제25조 관할법원과 준거법
① 서비스 이용에 관하여 회사와 이용자 간에 분쟁이 발생한 경우 협의로 원만히 해결합니다.
② 전항으로 해결되지 아니할 경우 소송의 관할은 민사소송법에 따라 정합니다.
③ 본 약관의 해석 및 적용은 대한민국 법령을 기준으로 합니다.

제26조 자료 전송 기술 사용 동의
"회사"는 자료의 전송을 위하여 이용자 간에 데이터를 중계 전송하는 기술을 사용할 수 있습니다. 이용자는 이 약관을 통해, PC 등 자신의 이용 장치를 통하여 다른 사람이 중계 전송받는 것을 허용하는데 동의합니다. 전송기술에 대한 세부적인 사항은 이용안내 등에서 게시하여 고지하며, 추가적인 내용에 대한 세부 동의는 별도의 파일전송관리자 이용약관에 대한 동의를 통하여 합니다.
이 약관은 2022년 12월 1일부터 시행합니다.
                        </textarea>
                      </div>
                    </li>
                    <li>
                      <div class="agree-col2">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" name="chkTerms" id="chkTerms2"  ref="check1_2" @input="onChange">
                          <label class="form-check-label" for="chkTerms2">
                            개인정보수집 및 이용 동의(선택)
                          </label>
                        </div>
                        <div class="right">
                          <button type="button" class="btn" data-bs-toggle="collapse" data-bs-target="#terms2" aria-expanded="false" aria-controls="terms2">보기</button>
                        </div>
                      </div>

                      <div class="agree-text collapse multi-collapse" id="terms2">
                        <textarea row="10" readonly>
개인정보처리방침

1. 수집하는 개인정보의 항목 및 처리방법

가. 수집하는 개인정보의 항목
회사는 원활한 서비스 제공을 위하여 다음과 같은 정보를 수집하고 있습니다.

첫째, 회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 아래와 같은 최소한의 개인정보를 필수항목으로 수집하고 있습니다.
▶ 회원 가입 시 : 휴대폰인증 가입 ◀
아이디, 비밀번호, 성명, 닉네임, 휴대폰번호, 생년월일, 성별, 본인확인 정보(CI), 소셜 계정(SNS로 로그인)

둘째, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
IP Address, 쿠키, 방문일시, 서비스 이용기록, 불량 이용 기록, 모바일 앱 설치 시 Device Token, 단말기 정보(모델명, os버전, 기기고유번호, 펌웨어 버전등), 통신사 정보

셋째, 아이디를 이용한 부가 서비스 및 이벤트 응모 과정에서 해당 서비스의 이용자(별도 동의한 회원)에 한해서만 아래와 같은 정보들이 수집됩니다.
선택사항 : 아이디, 이메일, 휴대전화번호, 주소, 이름

넷째, 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
신용카드 결제시 : 카드사명, 카드번호 등
휴대전화 결제시 : 전화번호, 이동 통신사, 결제승인번호 등
계좌이체시 : 은행명, 계좌번호 등
상품권(쿠폰) 이용시 : 상품권(쿠폰) 번호

다섯째, 종량제 결제 서비스를 이용하는 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
결제 등을 불가피하게 필요한 경우 주민등록번호, 휴대전화번호, 통신사 정보 등

나. 개인정보 처리방법
회사는 다음과 같은 방법으로 개인정보를 처리 합니다.
홈페이지, 모바일, 서면양식, 팩스, 전화, 고객센터 문의하기, 이메일, 이벤트 응모, 배송요청
협력회사로부터의 제공
생성정보 수집을 통한 수집

다. 고유식별정보의 처리
회사는 다음과 같은 방법으로 개인정보를 처리합니다. 고유식별정보란 개인정보보호법 제24조 제1항 및 개인정보보호법 시행령 제 19조 “대통령령으로 정하는 정보”로 주민등록번호, 여권번호, 운전면허번호, 외국인등록번호를 말합니다. 회사는 아래 각호의 용도로 고유식별정보를 수집하여 처리하고 있습니다. 거래 및 결제 서비스 사용을 위한 의무준수 현물 경품 당첨자의 제세공과금 부과 불만처리 등 민원처리 수집된 고유식별 정보는 개인정보보호법 기타 법률에서 규정하고 있는 경우를 제외하고는 개인정보처리 목적 이외의 용도로 사용제공되지 않으며 암호화 되어 안전하게 관리되고 있습니다.

1. 개인정보의 처리목적
회사는 다음의 목적을 위하여 개인정보를 처리하며, 다음의 목적 이외의 용도로 이용하지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제 18조에 따라
별도의 동의를 받는 등 필요한 조치를 이행합니다.

가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산
콘텐츠 제공, 특정 맞춤 서비스 제공, 물품배송 또는 청구서 등 발송, 본인인증, 구매 및 요금 결제, 요금추심

나 . 회원관리
회원제 서비스 제공, 개인식별, 이용약관 위반 이용자에 대한 이용제한 조치, 서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용 행위 제재, 가입의사 확인, 가입 및 가입횟수 제한,
불만처리 등 민원처리, 고지사항 전달, 회원탈퇴 의사의 확인

다. 신규 서비스 개발 및 마케팅 광고 활용
신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공, 접속빈도 파악,
회원의 서비스 이용에 대한 통계

2. 개인정보의 공유 및 제공
회사는 이용자들의 개인정보를 “2. 개인정보의 처리목적”에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를
외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.

개인정보의 제3자 제공
제공받는 자
제공 목적
제공하는 항목
보유 및 이용기간
플렉스머니
로그인, 포인트 적립/사용 및 관련 서비스 제공
CI, 이름, 생년월일, 성별, 아이디, 비밀번호, 휴대전화번호, 캐시 정보, 포인트
회원탈퇴 후 6개월간 또는 제휴사 이용약관 및 제3자 제공 동의 철회 후 6개월

가. 이용자가 사전에 동의 한 경우
나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
다. ‘회원’은 제3자에게 개인정보 제공 여부를 선택할 수 있으며, 제 3자 제공 및 활용 동의 여부와 관계없이 “통합회원” 서비스에 가입할 수 있습니다.
      단, ‘제 3자 개인정보 제공 여부’에 따라 서비스 이용이 불가능할 수 있습니다.

4. 개인정보의 처리위탁
회사는 서비스 향상을 위해서 아래와 같이 개인정보 처리를 위탁하고 있으며, 위탁처리 기관과 위탁계약 시 개인정보보호법 제 26조에 따라 필요한 사항을 규정하고 있으며 개인정보가 안전하게 처리하는지 감독하고 있습니다.
회사의 개인정보 위탁처리 기관 및 위탁 업무 내용은 아래와 같습니다.

수탁업체
위탁업무 내용
개인정보 보유 및 이용기간
NICE평가정보
회원 가입 시 본인확인, CI발급
해당 업체에서 이미 보유하고 있는 정보이기 때문에 별도 저장하지 않음

5. 개인정보의 보유 및 이용기간
이용자의 개인정보는 원칙적으로 개인정보의 처리목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 보존이유로 명시한 보존 기간 동안 개인정보보호법 제 21조 단서에 근거하여 보존합니다. 이는 개인정보보호법 제21조 제3항을 준수하기 위하여 기존의 저장공간과 구분된 별도의 안전한 저장공간에 저장됩니다.

가. 회사 내부 방침에 의한 정보보유 사유
보존 이유 : 회원탈퇴 시 소비자의 불만 및 분쟁해결 등 서비스 이용에 혼란 및 부정사용(부정가입 등 비정상적인 서비스 이용)방지
보존 항목 : 아이디, 본인확인 값(CI)
보존 기간 : 6개월

나. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
계약 또는 청약철회 등에 관한 기록 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률, 보존기간 :5년
대금결제 및 재화 등의 공급에 관한 기록 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률, 보존기간 : 5년
전자금융 거래에 관한 기록 보존 이유 : 전자금융거래법, 보존기간 : 5년
소비자의 불만 또는 분쟁처리에 관한 기록 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률, 보존기간 : 3년
웹사이트 방문기록 보존 이유 : 통신비밀보호법, 보존기간 : 3개월
제휴사를 통한 개별적인 회원탈퇴를 하였어도 ‘통합회원 제도’에 따라, 상기 서술된 근거로 개인정보를 보존할 수 있습니다. 

6. 개인정보 파기절차 및 방법
이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 다만 기타 법령에 의해 보관해야 하는 정보는 법령이 정한 일정 기간 동안 별도 분리 보관 후 즉시 파기 합니다.

가. 파기절차
이용자의 개인정보 수집 및 이용목적이 달성되면 지체 없이 파기되며 기타 법령에 따라 보관되어야 할 경우 별도의 DB에 옮겨져 관련 법령 및 내부규정을 준수하여 일정기간 동안 안전하게 보관된 후 지체 없이 파기 됩니다. 동 개인정보는 법령에서 명시한 경우를 제외하고는 보유목적 이외의 다른 용도로 이용되지 않습니다.

나. 파기방법
종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

다. 개인정보 유효기간제(휴면계정 정책)
1년 동안 서비스 이용기록이 없는 휴면상태의 서비스이용자의 개인정보는 이용자의 개인정보와 분리하여 별도로 저장 및 관리 됩니다. 다만, 휴면상태의 서비스이용자의 요청이 있는 경우 서비스 이용을 재개하는 시점에 다시 개인정보가 제공됩니다.
회사는 휴면계정으로 전환되는 시점을 기준으로 30일 이전에 해당 내용에 대해 이메일 등의 방식으로 이용자에게 공지합니다.

라. 회원탈퇴 유예 제도(회원탈퇴 철회 정책)
회원탈퇴를 신청한 시점으로부터 30일간 이를 철회할 수 있습니다. 탈퇴 유예 기간으로 전환된 이후, 회원은 언제든 이를 취소할 수 있으며 해당 기간동안 모든 회원 정보는 보존됩니다. 정확히 30일이 경과한 이후부터 회원탈퇴로 적용됩니다.
분리 보관된 개인정보는 기타 법령에 특별한 규정이 있는 경우를 제외하고는 해당 개인정보를 이용하거나 제공하지 않습니다. 또한 분리 보관된 개인정보는 관련 법령에서 명시하고 있는 일정기간 경과 시 지체 없이 파기되며, 파기 시점을 기준으로 30일 이전에 해당 내용에 대해 이메일 등의 방식으로 이용자에게 공지합니다.

7. 이용자 및 법정대리인의 권리와 그 행사방법
이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 미성년자(14세 ~ 19세) 아동의 개인정보를 조회하거나 수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.
이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을, 가입해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “5. 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항

가. 쿠키란?
회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.
이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 파악하여 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.
쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.

나. 회사의 쿠키 사용 목적
이용자들의 방문한 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기-검색어, 보안접속 여부, 뉴스편집 이용자 규모 등을 파악하여 이용자에게 광고를 포함한 최적화된 맞춤형 정보를 제공하기 위해 사용합니다.

다. 쿠키의 설치/운영 및 거부
이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.
① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.
② [개인정보 탭]을 클릭합니다.
③ [개인정보처리 수준]을 설정하시면 됩니다.

9. 개인정보의 기술적/관리적 보호 대책
회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

가. 비밀번호 암호화
이용자가 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 이용자 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 이용자 본인에 의해서만 가능합니다.

나. 해킹 등에 대비한 대책
회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.

다. 처리 직원의 최소화 및 교육
회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.

라. 개인정보보호전담기구의 운영
그리고 사내 개인정보보호전담기구 등을 통하여 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 회사가 개인정보보호 의무를 다 하였음에도 불구하고 이용자 본인의 부주의나 회사가 관리하지 않는 영역에서의 사고 등 회사의 귀책에 기인하지 않은 손해에 대해서는 회사는 일체의 책임을 지지 않습니다.

10. 개인정보보호 책임자 및 담당자의 연락처
이용자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다.
개인정보 보호 책임자
이름 : 이상준
연락처 : 1660-1737
E-mail : help@flexmoney.co.kr
개인정보 보호 관리자
이름 : 임수천
연락처 : 1660-1737
E-mail : help@flexmoney.co.kr
기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
개인정보 분쟁조정위원회 (www.kopico.go.kr / 전화 1833-6972)
개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
대검찰청 사이버범죄수사과 (www.spo.go.kr / 국번없이 1301)
경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)
청소년정보이용안전망 그린 i-Net (www.greeninet.or.kr / 02-523-3566)

11. 기타
회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 확인하시기 바랍니다.

12. 고지의 의무
본 개인정보처리방침에서 법령 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 변경이 되는 개인정보 처리방침을 시행하기 최소 7일전에 홈페이지의 ‘알려드립니다’ 게시판 혹은 별도 공지를 통해 고지할 것입니다.
- 개인정보처리방침 버전번호 : V1
- 개인정보처리방침 시행일자 : 2022년 10월 1일
- 개인정보처리방침 변경공고일자 : 2022년 10월 1일
                        </textarea>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="agree-box mt-4">
                <p>마케팅정보 수신 동의 <span class="fw-normal">(선택)</span></p>
                <div class="agree-inner">
                  <ul class="signup">
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="chkMar" ref="checkAll_2" @input="onCheckAll()">
                        <label class="form-check-label" for="chkMar">
                          전체동의
                        </label>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="chkMar" id="chkMar1" ref="check2_1"  @input="onChange">
                        <label class="form-check-label" for="chkMar1">
                          이메일
                        </label>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="chkMar" id="chkMar2" ref="check2_2"  @input="onChange">
                        <label class="form-check-label" for="chkMar2">
                          SMS
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <ul class="signup mt-4">
                <li>
                  <label for="nName" class="form-label">닉네임 <span class="text-danger">(필수)</span></label>
                  <div class="sign-col2">
                    <input class="form-control w-70" type="text" id="nName" placeholder="닉네임" v-model="txt_nick" >
                    <button type="button" id="checkId" class="btn btn-sm btn-outline-dark w-30 ml-10" @click="onNickCheck">중복체크</button>
                  </div>
                  <span class="fs-12 text-danger">* 2 ~ 7자 국문, 영문 대소문자, 숫자 (공백,특문 불가)</span>
                </li>
                <li>

                   <!-- <input maxlength='10' ref="inputTxtRec" v-model="recommendMid"  @keyup="onKeyupRecommendMid"  class="form-control" type="text" id="nCode" placeholder="추천인코드">           -->
                  <label for="nCode" class="form-label">추천인 아이디 <span class="fw-normal">(선택)</span></label>
                  <div class="sign-col2">
                    <input maxlength='30' ref="inputTxtRec" v-model="recommendMid"  @keyup="onKeyupRecommendMid"  class="form-control w-70" type="text" id="nCode" placeholder="추천인 아이디">
                    <button type="button"  ref="inputTxtRecBtn" @click="onClickRecommendMidOk"    class="btn btn-sm btn-outline-dark w-30 ml-10">확인</button>
                  </div>
                  <span class="fs-14 text-danger" >{{recommenNotiStr}}</span>
                </li>
              </ul>

            </div>
          </div>
          <div class="login-f-footer">
            <div class="btn-area">
              <button class="btn btn-dark w-100" id="btnSubmit" @click="onClickOk">확인</button>            
              <button id="btnCancle" class="btn btn-secondary w-100 mt-2" @click="onClickCancel">취소</button>
            </div>
          </div>
        </div>

      </div>

</template>

<script>
import { useRouter }from 'vue-router'
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import {getCurrentInstance} from 'vue';
import {useApicall} from '@/composables/commonFn';
import VueCookies from "vue-cookies";
import { event } from 'vue-gtag'
import moment from 'moment';


export default {
    components:{   
 },
 setup(){
    //const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
    const router = useRouter();
    const store = useStore();
    const checkAll_1 = ref(null);
    const checkAll_2 = ref(null);
     
    const recommenNotiStr = ref('')
 

    const check1_1 = ref(null);
    const check1_2 = ref(null);
    const check2_1 = ref(null);
    const check2_2 = ref(null);

    const inputTxtRec = ref(null);
    const inputTxtRecBtn = ref(null)

    const txt_nick = ref('')
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;

    const {apiCall_p, apiCall_g} = useApicall()
    
    var isNickCheck = false;

   // trace( 'login first')
    
    const recommendMid = ref('');

    var isPossibleRecommendMid = false;

    onMounted(()=>{
      var cooRcMid = VueCookies.get('coorcMid');
      if(cooRcMid) 
      {
        recommendMid.value = cooRcMid;
        inputTxtRec.value.disabled = true;
        inputTxtRecBtn.value.disabled = true;
        isPossibleRecommendMid = true
      }


    })
    const onCheckAll = ()=>{
       if(checkAll_1.value.checked)
       {
           //check1_1.value.checked = true;
           check1_2.value.checked = true;
       }
       else
       {
          // check1_1.value.checked = false;
           check1_2.value.checked = false;
       }


       if(checkAll_2.value.checked)
       {
           check2_1.value.checked = true;
           check2_2.value.checked = true;
       }
       else
       {
           check2_1.value.checked = false;
           check2_2.value.checked = false; 
       }
      // console.log("checkAll_1.value.checked :", checkAll_1.value.checked)
      // console.log("checkAll_2.value.checked :", checkAll_2.value.checked)
    }



    const  nick_check = () => {
      var nick = txt_nick.value;      
      var pattern2 = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]{2,7}$/;
      if(!nick){
        alert("닉네임을 입력하세요");
        return false;
      } else if(!pattern2.test(nick)){
         txt_nick.value = '';
         alert("닉네임은 영문, 숫자, 한글 2~7자로 구성하여야 합니다.");
         return false;
      }else{
         return true;
      }  
    }

   const onClickOk = () =>{
    if(!check1_1.value.checked)
    {
       alert("필수동의를 체크하세요");
       return;
    }

    if(!isNickCheck)
    {
       emitter.emit('EVENT_ALERT',{title:"알림", body:'닉네임 중복체크를 해주세요.'})
       return;
    }

    //console.log("recommendMid.value.length", recommendMid.value.length)
    if(recommendMid.value.length > 0)
    {
        if( !isPossibleRecommendMid )
        {
           emitter.emit('EVENT_ALERT',{title:"알림", body:'추천인 아이디를 확인해주세요.'})
           return;
        } 
    }

   

    
    if( nick_check() )
    {
       setLogin();
    }

    
   }

    const setLogin = async ()=>{
        window.TnkSession.actionCompleted();
        var groundToken = VueCookies.get('cootoken');
        var coorcId = VueCookies.get('coorcId');
        var params = {
            "ssotoken":groundToken,
            "agreeInfo": check1_2.value.checked?'Y':'N',
            "agreeEm": check2_1.value.checked?'Y':'N',  
            "agreeSms": check2_2.value.checked?'Y':'N',
            "nick": txt_nick.value,        
        }
        if(coorcId) params.recommendId = coorcId;
        if(recommendMid.value) params.recommendMid = recommendMid.value;
        // console.log(params)
        
        const data = await apiCall_p('member/tokenlogin',params)
        if(!data)return;

    
        event('sign_up', { 'ID': data.member.MID, 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss')})

        window.fbq('trackCustom', 'sign_up ', {'ID': data.member.MID, 'time':  moment(new Date()).format('YYYYMMDD HH:mm:ss') });

        store.commit('user/CHANGE_IS_LOGIN', true);  
        store.commit('user/CHANGE_IS_PUSH_COMMENT', data.isPushComment);  
        store.commit('user/CHANGE_MEMBER_DATA', data.member);  
        sessionStorage.setItem('token', data.token); 
        if(recommendMid.value) VueCookies.remove('coorcMid');
        if(coorcId) VueCookies.remove('coorcId');
        router.push({
              name: 'Home',              
        });
    }


    const onClickCancel = ()=>{
       router.push({
              name: 'Home',              
        });
    }

    const onNickCheck = async() =>{
          var data
          if(nick_check) 
          {
             data = await apiCall_g(`member/nickcheck?nick=${txt_nick.value}`, (message)=>{
                 emitter.emit('EVENT_ALERT',{title:"알림", body:message})
                 isNickCheck = false;
             })
             if(data)
             {
                    isNickCheck = true;
                    emitter.emit('EVENT_ALERT',{title:"알림", body:"사용가능한 닉네임 입니다."});
             }   
             else
             {
                isNickCheck = false;
             }          
          }
      
    }


    const onKeyupRecommendMid = (event) =>{
       //console.log(event.keyCode, inputTxtRec.value.value)

        if (!(event.keyCode >=37 && event.keyCode<=40)) {
          var inputVal = inputTxtRec.value.value;
          inputTxtRec.value.value = inputVal.replace(/[^a-z0-9]/gi,'')
         // inputTxtRec.value.val(inputVal.replace(/[^a-z0-9]/gi,''));
        }

    }

    const onClickRecommendMidOk = async() =>{
      var messageStr = ''
      var res = await apiCall_g(`member/ismid?mid=${recommendMid.value}`, (message)=>{
          //emitter.emit('EVENT_ALERT',{title:"알림", body:message})
         // isNickCheck = false;
         messageStr = message;
      })   
      if(res.returnVal == '1')
      {
        recommenNotiStr.value = '사용가능한 추천인 아이디입니다.'
        isPossibleRecommendMid = true;
      }
      else
      {
        recommenNotiStr.value = messageStr
        isPossibleRecommendMid = false;
      }
    }

   

    return{
      checkAll_1,
      checkAll_2,
      onCheckAll,
      check1_1,
      check1_2,
      check2_1,
      check2_2,
      txt_nick,
      onClickOk,
      onClickCancel,
      onNickCheck,
      recommendMid,
      onKeyupRecommendMid,
      inputTxtRec,
      inputTxtRecBtn,
      recommenNotiStr,
      onClickRecommendMidOk
    }
 }


}
</script>

<style>

</style>