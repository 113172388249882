<template>
  <HeaderTrade/> 
  <!-- Container Start { -->
  <div id="container">
    <!-- Contents Start { -->
    <div id="contents">

      <!-- content start -->
      <div class="contents-area">

        <!-- 커뮤니티 선택 영역 -->
        <div class="bbs-filter-box">
          <h3>선택한 게시판</h3>
          <div class="filter-selected" id="filterSelectBox" @click="onDeleteFilterList">
          </div>

          <div class="filter-btn">
            <span><button type="button" class="btn toggle" @click="finderToggle" :class="isFinderActive ? 'on':''">카테고리 닫기</button></span>
            <!-- <span><button type="button" class="btn off">필터기능 OFF</button></span> -->
            <span><button type="button" class="btn reset" @click="onResetCategoryCode">초기화</button></span>
          </div>
        </div>

        <div class="bbs-filter-finder" :class="isFinderActive ? 'show' : '' ">
          <ul class="finder-list">
            <li><a href="javascript:;" class="item" ref="allCategory" @click="onResetCategoryCode">전체글</a></li>
            <li
              v-for="(item, index) in categorylist"
              :key="index"
               @click="onFilterSelect"
            >
              <a href="javascript:;" class="item" :id="`${item.CODE}`">{{ item.NAME }}</a>
            </li>
          </ul>
        </div>

        <div class="section ground-list-title">
          <div class="title">커뮤니티 게시글</div>
          <div class="list-title-select">
            <select class="form-select form-select-sm" aria-label="select" @change="onSelectChange">
              <option value="10" selected>10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>

        <div class="section ground-list-inner">
          <!-- 광고 및 공지사항 리스트 start -->
          <table class="table">
            <colgroup>
              <col style="width:6%">
              <col style="width:10%">
              <col style="width:40%">
              <col style="width:18%">
              <col style="width:10%">
              <col style="width:6%">
              <col style="width:10%">
            </colgroup>
            <thead>
              <th scope="col">번호</th>
              <th scope="col" colspan="2" class="text-start">제목</th>
              <th scope="col">작성자</th>
              <th scope="col">작성일</th>
              <th scope="col">조회</th>
              <th scope="col">추천/비추천</th>
            </thead>
            <tbody>
              <tr 
                class="notice"
                  v-for="(item, index) in noticeList"
                  :key = index
              >
                <td scope="row" class="text-center">
                  <span class="text-danger">공지</span>
                </td>
                <td class="text-start" colspan="2">
                  <router-link :to="{ name:'NoticeView',  params:{ id:item.IDX }}"  class="link">
                    <span class="ico"><img src="@/assets/images/ground/ico_notice.png" alt="notice"></span>
                    <span class="txt text-truncate">
                      {{item.SUBJECT}}
                    </span>
                  </router-link>                      
                </td>
                <td>
                  <span class="writer text-truncate">FLEX MONEY</span>
                </td>
                <td>{{item.CREATIONDATE.split(' ')[0].replaceAll('-','.')}}</td>
                <td>-</td>
                <td>-</td>
              </tr>           
            </tbody>
          </table>              
          <table class="table">
            <colgroup>
              <col style="width:6%">
              <col style="width:10%">
              <col style="width:40%">
              <col style="width:18%">
              <col style="width:10%">
              <col style="width:6%">
              <col style="width:10%">
            </colgroup>
            <tbody>
              <tr 
                  v-for="(item, index) in boardList"
                  :key = index
              >
                <td scope="row" class="text-center">
                  <span>{{ item.IDX }}</span>
                  <!-- <span>{{ item.IDX }}</span> -->
                </td>
                <td class="category-name">
                  <span>[{{ item.CATEGORY_NAME }}]</span>
                  <!-- <span>{{ item.IDX }}</span> -->
                </td>
                <td class="text-start">
                  <router-link :to="{ name:'CommunityView',  params:{ id:item.IDX }}"  class="link">
                    <span class="txt text-truncate">
                      {{item.SUBJECT}}
                    </span>
                    <span class="reply">[{{ item.COMMENT_COUNT }}]</span>
                  </router-link>
                </td>
                <td>
                  <span class="writer text-truncate">
                    <UserNickGrade
                      :itemGrade="item.GRADE"
                      :itemGradeRank="item.GRADE_RANK"
                      :itemNickName="item.MB_NICK"
                    />
                  </span>
                </td>
                <td>{{item.CREATIONDATE.split(' ')[0].replaceAll('-','.')}}</td>
                <td>{{item.COUNT}}</td>
                <td>{{item.LIKE_COUNT}}/{{item.DISLIKE_COUNT}}</td>
              </tr>
              <tr>
                <td v-if="!boardList.length" colspan="6">게시글이 없습니다.</td>
              </tr>
             
            </tbody>
          </table>
          <!-- //광고 및 공지사항 리스트 end -->

        </div>

        <!-- 검색 영역 / 글쓰기 버튼 start -->
        <div class="section ground-list-control">
          <div class="control-select">
            <select class="form-select form-select-sm" v-model="srchType">
              <option value="search_like" selected="selected">제목+내용</option>
              <option value="subject_like">제목</option>
              <option value="contents_like">내용</option>
              <option value="mb_nick">닉네임</option>
            </select>
          </div>
          <div class="control-search">
            <input type="text" class="form-control form-control-sm" aria-label="search" id="inputSearch" v-model="srchKeyword" @keyup.enter="srchCommunity">
            <button class="btn-search" type="button"><img src="@/assets/images/common/ico_header_search.png" alt="search" @click="srchCommunity"></button>
          </div>
          <button type="button" class="control-btn-write btn btn-sm" v-if="isLogin" @click="writeClickHandle">
          <!-- <button type="button" class="control-btn-write btn btn-sm" @click="writeClickHandle"> -->
            <span class="mr-1"><img src="@/assets/images/ground/ico_write.png" alt="글쓰기"></span>
            글쓰기
            <!-- <router-link :to="{ name: 'CommunityWrite'}" >글쓰기</router-link> -->
          </button>
        </div>
        <!-- //검색 영역 / 글쓰기 버튼 end -->

        <!-- 페이징 start-->
        <div class="section paging-area" v-if="boardList.length">
           <v-pagination                  
                v-model="currentPage"
                :pages= numberOfPages
                :rangeSize="1"
                activeColor="#ff5d8b"                   
                @update:modelValue="updateHandler(currentPage, categoryType, categoryCode)"
            />
        </div>
        <!-- //페이징 end -->        

      </div>
      <!-- //content end -->  

      <!-- aside start -->
      <div class="aside-area">

        <CommLoginSide />
        <CommBestSide />     
        <CommPopularSide />     
        <GotradeLive />
        <!-- <ToprankTradeSide /> -->
        <!-- <StocklistSide /> -->

      </div>
      <!-- //aside end -->

    </div>
    <!-- } Contents End -->

  </div>
  <!-- } Container End -->
  <Footer/>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter }from 'vue-router';
import { useRoute }from 'vue-router';
import { ref, getCurrentInstance, onMounted, computed, onUnmounted } from 'vue';
import { useApicall } from '@/composables/commonFn';
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
// import StocklistSide from '@/components/home/Stocklist_side.vue';
// import ToprankTradeSide from '@/components/home/ToprankTrade_side.vue';
import GotradeLive from '@/components/home/GotradeLive.vue';
import CommLoginSide from '@/components/community/CommLogin_side.vue';
import CommPopularSide from '@/components/community/CommPopular_side.vue';
import CommBestSide from '@/components/community/CommBest_side.vue';
import UserNickGrade from '@/components/UserNickGrade.vue';
import VPagination from "@hennge/vue3-pagination";
import '@/assets/css/vue3-pagination.css';

export default {
  components:{
    HeaderTrade, 
    Footer,
    // StocklistSide,
    // ToprankTradeSide,
    GotradeLive,
    CommLoginSide,
    CommPopularSide,
    CommBestSide,
    UserNickGrade,
    VPagination,
  },
  setup() {
    // const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const store = useStore();
    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_1'); 
    store.commit('common/CHANGE_CURRENT_PAGE', 'Community');    
    const boardList = ref([]);
    const limit = ref(10);
    const currentPage = ref(1);     
    const numberOfData = ref(0);
    const numberOfPages = computed(() => {
      return Math.ceil(numberOfData.value/limit.value)
    });
    const {apiCall_g, apiCall_gt} = useApicall();
    const categorylist = ref([]);
    const categoryType = ref('community');
    const categoryCode = ref([]);
    const noticeList = ref([]);
    const router = useRouter();
    const route = useRoute();
    const isLogin = computed(() => store.state.user.isLogin);
    const currentPage_store = computed(() => store.state.common.cummunityCurrentPage);
    const page_store = computed(() => store.state.common.currentPage);
    const category_store = computed(() => store.state.common.cummunityCurrentCategory);

    const srchType = ref('search_like');
    const srchKeyword = ref('');
    const boardIdx = ref(0);
    const isFinderActive = ref(true);
    const allCategory = ref(null);

    onMounted( async() => {
      emitter.emit('EVENT_CHECK_LOGIN');       
      categorylist.value = await getCategorylist();
      noticeList.value = await getNoticeList();
      setCategoryCode();
      // console.log('onMounted ======>', categoryCode.value, JSON.parse(sessionStorage.getItem('cummunityCurrentCategory')) )
    })

    const updateHandler = (e, _cType, _cCode) => {
      getDataList(e, _cType, _cCode);
    }

    const getDataList  = async( page = currentPage.value, categoryType, categoryCode ) => {
      currentPage.value = page; 
      store.commit('common/CHANGE_CUMMUNITY_CURRENT_PAGE', page);

      var offset = (currentPage.value - 1) * limit.value;
      var getUrl = `board/list?limit=${limit.value}&offset=${offset}&categoryType=${categoryType}`;

      // console.log('getdatalist =======>', categoryCode)
      if( categoryCode != 0 && categoryCode != null) {
        var addCategory = `&categoryArr=${categoryCode.toString()}`;
        getUrl += addCategory;
      }

      if( srchKeyword.value ) {
        var addUrl = `&${srchType.value}=${srchKeyword.value}`;
        getUrl += addUrl;
      }

      var boardDataList = await apiCall_gt(getUrl);

      try {
        if(boardDataList.dataList == null) boardDataList.dataList = [];
        boardList.value = boardDataList.dataList;
        numberOfData.value = boardDataList.dataCount;
        // boardIdxCount();
      } catch (error) {
        boardList.value = [];
        numberOfData.value  = 0;
      }
    }

    const setCategoryCode = () => {
      // console.log('categoryCode.value ====>',categoryCode.value,category_store.value)
      categoryCode.value = category_store.value;

      if( categoryCode.value != 0 && categoryCode.value != null) {
        categoryCode.value.forEach((category) => {
          if(document.getElementById(category)) {
            document.getElementById(category).classList.add('active');
            addFilterList(category);
          } else {
            allCategory.value.classList.add('active');
            categoryCode.value = [];
            store.commit('common/CHANGE_CUMMUNITY_CURRENT_CATEGORY', []);
          }          
        });
        updateHandler(currentPage_store.value, categoryType.value, categoryCode.value);
      } else {
        allCategory.value.classList.add('active');
        categoryCode.value = [];
        updateHandler(currentPage_store.value, categoryType.value, []);
        store.commit('common/CHANGE_CUMMUNITY_CURRENT_CATEGORY', []);
      }      
    }

    const onResetCategoryCode = () => {
      var selectedLists = document.querySelectorAll('.finder-list .item.active');
      
      document.getElementById('filterSelectBox').textContent= '';
      selectedLists.forEach((list) => {
        list.classList.remove('active');
      });
      store.commit('common/CHANGE_CUMMUNITY_CURRENT_CATEGORY', []);
      setCategoryCode();
    }

    const onFilterSelect = (e) => {
      var btn = e.target;
      var selectedLists = document.querySelectorAll('.finder-list .item.active');
      // console.log(btn)

      // 단일선택 변경으로 인한 초기화 추가
      categoryCode.value = [];      

      if(allCategory.value.classList.contains('active')) {
        allCategory.value.classList.remove('active');
      }

      if(selectedLists.length > 4) {
        if(btn.classList.contains('active')) {
          onDeleteFilterList(e);
          btn.classList.remove('active');
          return false;
        } else {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"카테고리는 5개까지 선택 가능합니다."});
          return false;  
        }
      }

      if(btn.classList.contains('active')) {
        onDeleteFilterList(e);
        btn.classList.remove('active');
      } else {
        // 단일선택 변경으로 인한 초기화 추가
        selectedLists.forEach((finder) => {
          finder.classList.remove('active');
        });

        btn.classList.add('active');
        addFilterList(btn);

        categoryCode.value.push(btn.id);
        updateHandler( 1, categoryType.value, categoryCode.value);
        store.commit('common/CHANGE_CUMMUNITY_CURRENT_CATEGORY', categoryCode.value);
      }
      // console.log('category_store ======>', category_store.value, categoryCode.value, JSON.parse(sessionStorage.getItem('cummunityCurrentCategory')))
    }

    const addFilterList = (e) => {
      var filter = e;
      var selectBox = document.getElementById('filterSelectBox');
      var filterLists = document.querySelectorAll('#filterSelectBox > div');      
      var newDiv = document.createElement('div');

      // 단일선택 변경으로 인한 초기화 추가
      if(filterLists.length > 0) {
        filterLists.forEach((list) => {
          list.remove();
        });        
      }      

      if( filter.target == undefined ) {
        newDiv.innerHTML = document.getElementById(filter).textContent;
        newDiv.setAttribute("class", filter);
      } else {
        newDiv.innerHTML = filter.textContent;
        newDiv.setAttribute("class", filter.id);
      }

      selectBox.appendChild(newDiv);
    }

    const onDeleteFilterList = (el) => {
      var item = el.target;
      var filterLists = document.querySelectorAll('#filterSelectBox > div');
      var selectedLists = document.querySelectorAll('.finder-list .item.active');
      let categoryArr = categoryCode.value;

      if(item.id) {
        item.classList.remove('active');
        categoryCode.value = categoryArr.filter((element) => element !== item.id);
        filterLists.forEach((list) => {
          item.id == list.classList.value ? list.remove() : '';
        });
      } else {
        item.remove();
        categoryCode.value = categoryArr.filter((element) => element !== item.classList.value);        
        selectedLists.forEach((finder) => {
          finder.id == item.classList.value ? finder.classList.remove('active') : '';
        });
      }

      if(selectedLists.length-1 == 0 && filterLists.length-1 == 0) {
        allCategory.value.classList.add('active');
      }

      updateHandler( 1, categoryType.value, categoryCode.value );
      store.commit('common/CHANGE_CUMMUNITY_CURRENT_CATEGORY', categoryCode.value);
      // console.log('onDeleteFilterList ====>', category_store.value, categoryCode.value);
    }

    const finderToggle = (e) => {
      var btn = e.target;
      isFinderActive.value = !isFinderActive.value;
      isFinderActive.value ? btn.textContent = '카테고리 닫기' : btn.textContent = '카테고리 열기';
    }

    // const boardIdxCount = () => {
    //   var totalCnt = numberOfData.value;
    //   var pageCnt = 0;
      
    //   if(currentPage.value > 1) {
    //     pageCnt = ((currentPage.value * 10) - 10);
    //   }
    //   totalCnt -= pageCnt;
    //   boardIdx.value = totalCnt;

    //   return boardIdx.value;
    // }

    const onSelectChange = (e) => {
      limit.value = e.target.value;
      let cCode = categorylist.value;
      cCode.CODE = categoryCode.value
      updateHandler(1, categoryType.value, categoryCode.value)
    }

    //카테고리 리스트
    const getCategorylist = async() => {    
      const res = await apiCall_g(`/board/categorylist?type=community`);
      var result =res.dataList;
      if(!result) result = [];
      return result
    }

    //공지사항 리스트
    const getNoticeList = async() => {
      const res = await apiCall_g(`board/list?offset=0&categoryArr=notice&isTop=Y`);
      var result = res.dataList;     
      if(!result) result = [];
      return result
    }

    const writeClickHandle = () => {
      if(!isLogin.value) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 이용 가능합니다."});
      } else {
        router.push({
          name: 'CommunityWrite',
        });
      }
    }

    const srchCommunity = () => {
      updateHandler(1, categoryType.value, categoryCode.value);
    }

    onUnmounted(()=> {     
      if(route.params.id) {
        return false;
      }
      if(page_store.value !== 'Community') {
        store.commit('common/CHANGE_CUMMUNITY_CURRENT_PAGE', 1);
      }
    })

    return {      
      isLogin,
      numberOfPages,
      boardList,
      currentPage, 
      updateHandler,
      onSelectChange,
      categorylist,
      categoryType,
      categoryCode,
      // onCategoryChange,
      noticeList,
      writeClickHandle,
      srchType,
      srchKeyword,
      srchCommunity,
      boardIdx,
      finderToggle,
      isFinderActive,
      onFilterSelect,
      onDeleteFilterList,
      onResetCategoryCode,
      category_store,
      allCategory,
    }
  }
}
</script>

<style>

</style>