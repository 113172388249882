<template>
        <!-- 매수, 매도 영역 start -->
        <div class="trade-order-area">

          <div class="trade-order-coinf">
            <div class="coinf-top-box" data-bs-toggle="modal" data-bs-target="#modalMarginMode">
              <span class="type">격리</span>
              <span class="leverage-long">{{numOfleverage}}x</span>
              <span class="leverage-short">{{numOfleverage}}x</span>
            </div>
            <!-- <div class="calc">
              <button class="btn" data-bs-toggle="modal" data-bs-target="#">
                <img src="@/assets/images/trade/ico_calculator.png" alt="calc">
              </button>
            </div> -->
          </div>

          <div class="trade-order-tab coinf">
            <!-- tab button -->
            <ul class="nav nav-tabs nav-fill" id="tradeOrderTabButton" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="trade-order-buy-tab" data-bs-toggle="tab" data-bs-target="#tradeOrderBuyTab" type="button" role="tab" aria-controls="tradeOrderSellTab" aria-selected="true" @click="onTapPositionType('in')">
                  포지션 진입
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="trade-order-sell-tab" data-bs-toggle="tab" data-bs-target="#tradeOrderBuyTab" type="button" role="tab" aria-controls="tradeOrderBuyTab" aria-selected="false" @click="onTapPositionType('out')">
                  포지션 정리
                </button>
              </li>
            </ul>

            <!-- tab contents -->
            <div class="tab-content" id="tradeOrderTabContents">

              <!-- 매수 start -->
              <div class="tab-pane fade show active" id="tradeOrderBuyTab" role="tabpanel" aria-labelledby="trade-order-buy-tab">
                <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                <!-- 매수창 시작 -->
                <div class="order-box">
                  <div class="trade-item">
                    <div class="item-group">
                      <div>
                        <input type="radio" class="btn-check" id="chkt4" name="checkType" autocomplete="off" checked="checked"  @change="onTapBuyType('L')"  ref="checkTypeL">
                        <label class="btn btn-outline-danger" for="chkt4">지정가</label>
                      </div>
                      <div>
                        <input type="radio" class="btn-check" id="chkt5"  name="checkType" autocomplete="off"  @change="onTapBuyType('M')">
                        <label class="btn btn-outline-secondary" for="chkt5">시장가</label>
                      </div>
                    </div>
                  </div>



                  <div class="trade-item balance">
                    <!-- <div class="item-group">
                      <div class="item-title">잔고</div>
                      <div class="item-number">30,000,000 원</div>
                    </div> -->
                    <div class="item-group">
                      <div class="item-title">주문가능</div>
                      <div class="item-number">
                        <span class="text-danger">{{ Constant.UTIL.numberWithCommas(Number(availableorder_long).toFixed(4)) }}</span> / <span class="text-primary">{{ Constant.UTIL.numberWithCommas(Number( availableorder_short).toFixed(4)) }}</span>
                        <!-- {{ availableorder_long + '/' + availableorder_short }}  -->
                        {{' '+selectItem.CODE.substring(0,selectItem.CODE.length-4)}}
                      </div>
                    </div>
                  </div>

                  <div class="trade-item order-input" v-if="orderType=='L'">
                    <div class="item-group">
                      <label class="item-title" for="buyPrice">가격</label>
                      <!-- <input type="text" id="buyPrice" class="form-control"> -->

                      <InputNumber
                          :nValue="currentOrderPrice"
                          sClass="form-control"
                          @updateNvalue="onUpdateOrderPrice"
                          :comma="true"
                          :ndotpos="selectItem.NDOTPOS?parseInt(selectItem.NDOTPOS):0"
                      />


                      <span class="item-txt">USDT</span>
                    </div>
                  </div>

                  <div class="trade-item order-input">
                    <div class="item-group">
                      <label class="item-title" for="buyOrderQuantity">수량</label>
                      <!-- <input type="text" id="buyOrderQuantity" class="form-control"> -->
                      <InputNumber   v-if="isInputMode"
                              :nValue="currentOrderNum_ipt"
                              sClass="form-control"
                              @updateNvalue="onUpdateOrderNum"
                              :comma="true"
                              :ndotpos="4"
                              
                      />


                      <div class="order-input-box coinf" v-else   @click="onInputChange" >
                        <span class="position">
                          <span class="long">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(currentOrderNum_long, 2))}}</span> /
                          <span class="short">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(currentOrderNum_short, 2))}}</span>
                        </span>
                        <input type="text" id="buyPrice" class="form-control" disabled="disabled">
                      </div>
                      <span class="item-txt text-black">{{selectItem.CODE.substring(0,selectItem.CODE.length-4)}}</span>
                    </div>
                  </div>

           
                  


                  <div class="trade-item order-btn-area" >                         
                    <div  v-if="!isSliderMode" class="order-btn" @click="selectRangeBtn($event)"  >
                      <button type="button" class="btn" @click="onClickRangeActive(1)">10%</button>
                      <button type="button" class="btn" @click="onClickRangeActive(2)">25%</button>
                      <button type="button" class="btn" @click="onClickRangeActive(3)">50%</button>                                    
                      <button type="button" class="btn" @click="onClickRangeActive(4)">100%</button>
                      <button type="button" class="btn" @click="onClickRangeActive(5)" ref="customRangeBtn">{{isCustomMode?range_1 + '%':'직접입력'}}</button>
                    </div>

                    <div   v-else  class="range">
                      <Slider
                        v-model="range_1"
                        class="slider-red"
                        :tooltipPosition="'bottom'"
                        :min="1"
                        :max="100"
                        @input="onRangeChange_1"
                    />                                                
                      <!-- <div class="range__slider">
                        <input type="range" id="buyRange" step="1"  v-model="range_1"  @input="onRangeChange_1" >
                      </div> -->
                      <div class="range-value">
                        <!-- <input type="text" id="buyOrderValue" class="form-control form-control-sm" v-model="range_1" readonly>
                        <span class="unit">%</span> -->
                      </div>
                      <div class="range-btn">
                        <button type="button" class="btn btn-sm btn-dark" @click="onClickRangeActive(6)">확인</button>
                      </div>
                      <div class="range-division">
                        <span class="division-1">0</span>
                        <span class="division-2">25</span>
                        <span class="division-3">50</span>
                        <span class="division-4">75</span>
                        <span class="division-5">100</span>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="trade-item">
                    <div class="range">
                      <div class="range__slider">
                        <input type="range" id="buyRange" step="1" @input="onRangeOrderNum"    @mousedown="onRangeDown"     ref="range" >
                      </div>
                      <div class="range-value">
                        <input type="text" id="buyOrderValue" class="form-control form-control-sm" :value="orderNumRange" readonly>
                        <span class="unit">%</span>
                      </div>
                      <div class="range-division">
                        <span class="division-1">0</span>
                        <span class="division-2">25</span>
                        <span class="division-3">50</span>
                        <span class="division-4">75</span>
                        <span class="division-5">100</span>
                      </div>
                    </div>
                  </div> -->


                  <div class="trade-item order-price">
                    <div class="item-group long">
                      <span class="txt ">롱포지션 합계</span>
                      <span class="number">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(currentTradePrice_long, 2))}}</span>
                      USDT
                    </div>
                    <div class="item-group short">
                      <span class="txt">숏포지션 합계</span>
                      <span class="number">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(currentTradePrice_short, 2))}}</span>
                      USDT
                    </div>
                  </div>



                  <!-- <div class="trade-item order-price">
                    <div class="item-group">
                      총 주문 합계
                    </div>
                    <div class="item-group">
                      <span class="number">{{Constant.UTIL.numberWithCommas(Number(currentTradePrice).toFixed(4))}}</span>
                      USDT
                    </div>
                  </div> -->
                </div>

                <div class="order-box-footer"  v-if="!isLogin || !accountId ">
                  <button type="button" class="btn btn-success order buy" @click="onNeedLogin" >{{tradeType_tap=='in'?'롱 포지션 진입':'롱 포지션 정리'}} </button>
                  <button type="button" class="btn btn-danger cancel" @click="onNeedLogin" >{{tradeType_tap=='in'?'숏 포지션 진입':'숏 포지션 정리'}}</button>
                </div>

                <div class="order-box-footer" v-else>
                  <button type="button" class="btn btn-success order buy" data-bs-toggle="modal"  data-bs-target='#modalOrderConfirmLong'>{{tradeType_tap=='in'?'롱 포지션 진입':'롱 포지션 정리'}} </button>
                  <button type="button" class="btn btn-danger cancel" data-bs-toggle="modal" data-bs-target="#modalOrderConfirmShort">{{tradeType_tap=='in'?'숏 포지션 진입':'숏 포지션 정리'}}</button>
                </div>

                <div class="order-box-info">
                  <!-- <span>최소주문금액 0 원</span>
                  <span class="pipe">/</span> -->
                  <span> 수수료율 {{' 지정가:'+Constant.UTIL.toFixedNum(selectItem.LIMIT_FEES, 2)+'% / '+ '시장가:'+Constant.UTIL.toFixedNum(selectItem.FEES, 2)+'%'}}</span>
                </div>
                <!-- //매수창 종료 -->

              </div>
              <!-- //매수 end -->

         

            </div>
          </div>

          <!-- 모의투자 대회 및 랭킹 start -->
          <div class="trade-sts-info">
            <div class="sts-info">
              <h3 class="text-truncate">{{currentSpaceName?currentSpaceName:'모의투자 대회'}}</h3>
              <p  @click="onClickRetry">재도전  {{recount + '/' +  (retrycount=='-1'?'무한':retrycount)}}</p>
            </div>
            <div class="sts-link">
              <span class="text">제1회 모의투자 대회</span>
              <span class="link">
                <a href="javascript:;">참가신청</a>
                <a href="javascript:;">상세정보</a>
              </span>
            </div>

            <div class="sts-my-rank">
              <!-- class 설명 
                  ul  >> 보합
                  ul.up  >> 상승
                  ul.down  >> 하락
              -->
              <ul>
                <li :class="getCssColor(getProfitPre())">
                  <span>수익률</span>
                  <span>
                    <!-- <i></i>{{ProfitData._rankRow?Math.abs(Number((Number(ProfitData._rankRow.PROFIT)/Number(currentBalance))*100).toFixed(2)):'--'}} % -->
                     <i></i>{{Constant.UTIL.numberWithCommas(Math.abs(getProfitPre())) + ' %'}}
                  </span>
                </li>
                <li :class="getCssColor(currentTotalProfit)">
                  <span>평가손익</span>
                 <!-- <span>{{ProfitData._rankRow?Constant.UTIL.numberWithCommas(ProfitData._rankRow.PROFIT) + ' USDT': '--'}}</span> -->
                  <span><i></i>{{Constant.UTIL.numberWithCommas(Math.abs(Constant.UTIL.toFixedNum(currentTotalProfit, 2))) + ' USDT'}}</span>
                </li>
                <li :class="ProfitData._dataSum?Constant.UTIL.getUpdownColor(Number(ProfitData._dataSum.TPOL) + currentTotalProfit):''">
                  <span>총 손익</span>
                   <!-- <span>{{ProfitData._rankRow?Constant.UTIL.numberWithCommas(ProfitData._rankRow.PROFIT)+ ' USDT': '--'}}</span> -->
                  <span><i></i>{{ProfitData._dataSum?Constant.UTIL.numberWithCommas(Math.abs(Constant.UTIL.toFixedNum(Number(ProfitData._dataSum.TPOL) + currentTotalProfit , 2))) + ' USDT':'0'}}</span>
                                  
                </li>
                <li>
                  <span>총 평가</span>
                  <span>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(totalBalance_f, 2)) + ' USDT' }}</span>
                </li>
                <li>
                  <span>주문가능금액</span>
                  <span>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum( availBalance, 2)) + ' USDT' }}</span>
                </li>
                <li>
                  <span>나의 순위</span>
                  <span>{{ProfitData._rankRow?Constant.UTIL.numberWithCommas(ProfitData._rankRow.RANK) + ' 위':'-- 위'}}</span>
                </li>
              </ul>
            </div>

          </div>
          <!-- //모의투자 대회 및 랭킹 end -->

        </div>
        <!-- //매수, 매도 영역 end -->




 <!-- 레이어팝업 - popup : 모드 선택 및 레버리지 설정 start -->
    <div class="modal fade" id="modalMarginMode" tabindex="-1" aria-labelledby="modal-margin-mode" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal-margin-mode">주문 타입</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>        
          <div class="modal-body">

            <!-- modal 모드 선택/레버리지 start -->
            <div class="trade-coinf-mode-area">
              <div class="trade-coinf-mode-tab">
                <!-- tab button -->
                <ul class="nav nav-tabs nav-fill" id="modalMarginModeTabButton" role="tablist">
               
                  <li class="nav-item w-100" role="presentation">
                    <button class="nav-link active" id="modal-mode-cross-tab" data-bs-toggle="tab" data-bs-target="#modalModeIsolatedTab" type="button" role="tab" aria-controls="modalModeIsolatedTab" aria-selected="true">
                      Isolated(격리)
                    </button>
                  </li>
                </ul>
  
                <!-- tab contents -->
                <div class="tab-content" id="modalMarginModeTabContents">  
     
  
                  <!-- 격리 start -->
                  <div class="tab-pane fade show active" id="modalModeIsolatedTab" role="tabpanel" aria-labelledby="modal-mode-cross-tab">
                    <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                    <!-- 교차 시작 -->
                    <div class="margin-mode-box">
                      <div class="mode-item-info">
                        격리마진은 포지션에 일정량의 증거금, 즉 개시증거금을 적용하며 수동으로 포지션 증거금을 조정할 수 있습니다. 청산 시 이 포지션에 추가된 개시 증거금과 추가 증거금을 잃을 수 있습니다. 레버리지를 조정하면 현재 쌍의 모든 포지션과 활성 주문에 영향을 미칩니다.
                      </div>

                      <div class="mode-item-leverage">
                        
                        <div class="range-value">
                          <p>Leverage</p>
                          <input type="text" id="isolatedLongValue" class="form-control form-control-sm" :value="templeverage + 'x'"   readonly>
                          <!-- <span class="unit">X</span> -->
                        </div>

                        <div v-if="!isSliderMode_pop" class="order-btn" @click="popupRangeBtn($event)">
                          <button type="button" class="btn" @click="onClickRangeActive_pop(1)">10x</button>
                          <button type="button" class="btn" @click="onClickRangeActive_pop(2)">25x</button>
                          <button type="button" class="btn" @click="onClickRangeActive_pop(3)">50x</button>
                          <button type="button" class="btn" @click="onClickRangeActive_pop(4)">100x</button>
                          <button type="button" class="btn" @click="onClickRangeActive_pop(5)" ref="customRangeBtn_pop">{{isCustomMode_pop?templeverage + 'x':'직접입력'}}</button>
                        </div>

                        <div v-else class="range">
                          <Slider
                            v-model="templeverage"
                            class="slider-green"
                            :min="1"
                            :max="100"
                            @input="onChangeLeverage"
                          />  

                          <!-- <div class="range__slider">
                            <input type="range" id="isolatedLongRange" step="1"  @input="onChangeLeverage" :value="templeverage" >
                          </div> -->
                        
                          <div class="range-btn">
                            <button type="button" class="btn btn-sm btn-dark" @click="onClickRangeActive_pop(6)">확인</button>
                          </div>

                          <div class="range-division">
                            <span class="division-0">1x</span>
                            <span class="division-1">10x</span>
                            <span class="division-2">25x</span>
                            <span class="division-3">50x</span>
                            <span class="division-4">100x</span>
                          </div>
                        </div>

                        <!-- <p class="leverage-info">현재 레버리지가 너무 높습니다. 즉시 청산의 위험이 높습니다. 위치를 조정하십시오.</p> -->
                      </div>

                    </div>

                  </div>
                  <!-- //매도 end -->
  
                </div>
              </div>
            </div>
            <!-- //modal order end -->

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">취소</button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="onSetLeverage">확인</button>
          </div>
        </div>
      </div>
    </div>
    <!-- //레이어팝업 - popup : 모드 선택 및 레버리지 설정 end -->


<!-- 레이어팝업 - popup : 롱/진입/정리 start -->
    <div class="modal fade" id="modalOrderConfirmLong" tabindex="-1" aria-labelledby="modal-order-confirm-long" aria-hidden="true" ref="modalOrderConfirmLong">
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal-order-confirm-long">계약 확인</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>        
          <div class="modal-body">
            <h2 class="stock-title">
              {{selectItem.CODE}}
            </h2>
            <div class="item-list">
              <h3 class="item-title">포지션/Leverage</h3>
              <p class="item-content">LONG</p>
              <em class="item-unit">{{numOfleverage}}x</em>
            </div>            
            <div class="item-list type">
              <h3 class="item-title">구분</h3>
              <p class="item-content">{{tradeType_tap=='in'?'진입':'정리'}}</p>
              <em class="item-unit">{{orderType=='L'?'지정가':'시장가'}}</em>
            </div>
            <div class="item-list amount">
              <h3 class="item-title">수량</h3>
              <p class="item-content">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(currentOrderNum_long, 4))}}</p>
              <em class="item-unit">{{selectItem.CODE.substring(0,selectItem.CODE.length-4)}}</em>
            </div>
            <div class="item-list">
              <h3 class="item-title">금액</h3>
              <p class="item-content">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(currentTradePrice_long, 2))}}</p>
              <em class="item-unit">USDT</em>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">취소</button>
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="onClickLong">확인</button>
          </div>
        </div>
      </div>
    </div>
    <!-- //레이어팝업 - popup : 롱/진입/정리 end -->

    <!-- 레이어팝업 - popup : 숏/진입/정리 start -->
    <div class="modal fade" id="modalOrderConfirmShort" tabindex="-1" aria-labelledby="modal-order-confirm-short" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal-order-confirm-short">계약 확인</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>        
          <div class="modal-body">
            <h2 class="stock-title">
               {{selectItem.CODE}}
            </h2>
            <div class="item-list">
              <h3 class="item-title">포지션/Leverage</h3>
              <p class="item-content">SHORT</p>
              <em class="item-unit">{{numOfleverage}}x</em>
            </div>            
            <div class="item-list type">
              <h3 class="item-title">구분</h3>
              <p class="item-content">{{tradeType_tap=='in'?'진입':'정리'}}</p>
              <em class="item-unit">{{orderType=='L'?'지정가':'시장가'}}</em>
            </div>
            <div class="item-list amount">
              <h3 class="item-title">수량</h3>
              <p class="item-content">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(currentOrderNum_short, 4))}}</p>
              <em class="item-unit">{{selectItem.CODE.substring(0,selectItem.CODE.length-4)}}</em>
            </div>
            <div class="item-list">
              <h3 class="item-title">금액</h3>
              <p class="item-content">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(currentTradePrice_short, 2))}}</p>
              <em class="item-unit">USDT</em>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">취소</button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="onClickShort">확인</button>
          </div>
        </div>
      </div>
    </div>
    <!-- //레이어팝업 - popup : 숏/진입/정리 end -->

    <!-- 레이어팝업 - popup  : 재도전 신청 start -->
    <!-- <div class="modal fade" id="modalReChallenge" tabindex="-1" aria-labelledby="alert-re-challenge" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered">         
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="alert-re-challenge">재도전 신청</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>              
          <div class="modal-body">
            <div class="rechallenge-txt">
              <p class="text-danger">[주의] 재도전 시 현시점까지의 기초자산과<br>거래내역은 모두 초기화로 리셋됩니다.</p>
              <div class="rechallenge-info">
                <span>{{currentSpaceName?currentSpaceName:'모의투자 대회'}}</span>
                <span>* 재도전 횟수  {{recount + '/' +  (retrycount=='-1'?'무한':retrycount)}}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">취소</button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal"  @click="onRetryOk">신청</button>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 레이어팝업 - popup : 재도전 신청 end --> 

    <RetryModal
      v-if="showModal_retry" 
      @close="closeModal" 
      @cancle="closeModal" 
      @ok="okModal" 
      :currentSpaceName="currentSpaceName"
      :recount="recount"
      :retrycount="retrycount"
     />
 


</template>

<script>
import { ref,  computed, getCurrentInstance, onMounted, watch} from 'vue';
import { useStore } from 'vuex';
import Constant from '@/constant.js';
import InputNumber from '@/components/trade/InputNumber.vue';
import {useApicall} from '@/composables/commonFn';
import { useRouter }from 'vue-router'
import RetryModal from '@/components/popups/RetryModal.vue'
import Slider from '@vueform/slider';
import Config from '../../../public/config.js';
import { event } from 'vue-gtag'
import moment from 'moment';
export default {
  components:{
    InputNumber,
    RetryModal,
    Slider
  },
  props:{    
    currentSelectOrderPrice:{
        type: Number,
        required: true
    },
    selectItem:{        
           require:true
    },
  },
  setup(props){



      const isRangeMode = ref(false);
      const isRangeMode_pop = ref(false);

      const isSliderMode = ref(false);
      const isSliderMode_pop = ref(false);
      
      const customRangeBtn = ref(null);     
      const customRangeBtn_pop = ref(null);     
      
      const isCustomMode = ref(false)
      const isCustomMode_pop = ref(false)

      const range_1 = ref(50);
     // const range_1_pop = ref(50);
      
      const currentOrderNum_ipt = ref(0)


      const showModal_retry = ref(false)
      const router = useRouter();
      
      const isInputMode = ref(true)
      const numOfleverage = ref(0);

      const templeverage = ref(0);
 
    //  const availableorder = ref(0)

      const availableorder_long = ref(0) 
      const availableorder_short = ref(0)

      //const orderNumRange = ref(100);
      
     // const currentOrderNum = ref(0)

      const currentOrderNum_long = ref(0)
      const currentOrderNum_short = ref(0)

      const range = ref(null);

      const checkTypeL = ref(null)


      // const $ = require('jquery');
      const {apiCall_gt, apiCall_pt} = useApicall();
      const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
      const internalInstance = getCurrentInstance();  
      const emitter = internalInstance.appContext.config.globalProperties.emitter;

      const retrycount = computed(() => parseInt(store.state.hts.retryCount));
      const recount = computed(() => parseInt(store.state.hts.reCount));

      // M:시장가, L지정가
      const orderType = ref("L");

       // 3:선물매수, 4:선물매도 , 5:선물매수정리, 6:선물매도정리

      // in:집입   out:정리
      const tradeType_tap = ref("in")
  
      const ProfitData = ref({})
     
      //가격
      const currentOrderPrice =  ref(props.currentSelectOrderPrice);

      //주문금액
      //const currentTradePrice = ref(1);

      const currentTradePrice_long = ref(0);
      const currentTradePrice_short = ref(0);

      const store = useStore();
      const currentSpaceName = computed(()=>store.state.hts.currentSpaceName)
      const currentBalance = computed(() => Number(store.state.hts.currentBalance.BALANCE));

      //const currentTotalPrice  = computed(() => parseInt(store.state.hts.currentTotalPrice));
      const currentTotalProfit = computed(() => Number(store.state.hts.currentTotalProfit));
      const payMoney = computed(() => Number(store.state.hts.currentBalance.PAYMONEY));

      //var totalBalance  = computed(() => currentBalance.value + currentTotalPrice.value );
      var totalBalance_f  = computed(() => currentBalance.value + currentTotalProfit.value );

      const isLogin = computed(() => store.state.user.isLogin);
      const accountId = computed(() => store.state.hts.currentAccountId);
      const leagueType = computed(() => store.state.trade.currentLeagueType);
 
      const memberData = computed(()=> store.state.user.memberData)
  
      const tradeObj = computed(() => store.state.hts.trade);
      const assetlist = computed(() => store.state.hts.assetlist);
      const orderList = computed(() => store.state.hts.orderList);
      const availBalance = ref(0)
      const propsCurrentSelectOrderPrice = computed(()=>props.currentSelectOrderPrice)


      const drawPosition = () =>{
          if(assetlist.value.length > 0) 
          {
            var isAsset_long = false;
            var isAsset_short = false;

            assetlist.value.forEach(element => {
              if(element.CODE == props.selectItem.CODE)
              {
              // console.log("assetlist:", assetlist.value)
                if(element.TRADETYPE == '3')
                {
                  emitter.emit('EVENT_CREATE_POSITION_LINE_LONG',{count:Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(element.COUNT, 4)), price:element.PRICE, tradeType:'롱 포지션'});
                  isAsset_long = true;

                }
                else if(element.TRADETYPE == '4')
                {
                  emitter.emit('EVENT_CREATE_POSITION_LINE_SHORT',{count:Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(element.COUNT, 4)), price:element.PRICE, tradeType:'숏 포지션'});
                  isAsset_short = true;
                }
              }
            });

            if(!isAsset_long)
            {                  
              emitter.emit('EVENT_REMOVE_POSITION_LINE_LONG');
            }

            if(!isAsset_short)
            {                  
              emitter.emit('EVENT_REMOVE_POSITION_LINE_SHORT');
            }

          }
          else
          {
            emitter.emit('EVENT_REMOVE_POSITION_LINE_LONG');
            emitter.emit('EVENT_REMOVE_POSITION_LINE_SHORT');
          }
      }


      watch(assetlist, ()=>{
        getAvailableorder();
        drawPosition(); 
      })

      watch(orderList, ()=>{
       getAvailableorder();
        
      })
   
      
      


     
     const onClickLong = async ()=>{
     
        if(!store.state.hts.currentAccountId)
        {
          emitter.emit('EVENT_ALERT',{title:`주문 접수`, body: `대회 참가신청후 사용해 주시기 바랍니다.` ,fn:goLeagueApplyPage});
          return;
        }




        var params =   {
            "spaceId": store.state.hts.currentSpaceId,
            "type": "G",
            "orderType": orderType.value,
            "tradeType": tradeType_tap.value == 'in'?'3':'5',
            "code": props.selectItem.CODE,
            "price":currentOrderPrice.value,
            "count": Constant.UTIL.toFixedNum(currentOrderNum_long.value, 4)
        }

        if(params.count == 0)
        {
          
           let txt = tradeType_tap.value == 'in'?'진입 가능한 포지션이 없습니다':'정리 가능한 포지션이 없습니다'
           emitter.emit('EVENT_ALERT',{title:`주문 접수`, body: txt});
           return;
        }

        var res = await apiCall_pt(`vspace/orderregister`, params, null, (message, code)=>{  
            if(code && code ==-102 )
            {
                emitter.emit('EVENT_ALERT',{title:`알림`, body: message, fn:goHome}) 
            }
            else
            {
                emitter.emit('EVENT_ALERT',{title:`알림`, body: message}) 
            }
            
        });     
        if(res)
        {
         // console.log("res", res)

          emitter.emit('EVENT_ALERT',{title:`주문 접수`, body: `주문이 정상 접수되었습니다.`});
          setTimeout(()=>{
            onOrderReset()          
          },0)

          var gtag_type = null;
          if(params.tradeType == '3')
          {
             gtag_type = 'long_in'
          }
          else if(params.tradeType == '5')
          {
             gtag_type = 'long_out'
          }

          event( 'coinFutures_tranding', { 'ID': memberData.value.MID, 'transaction_type': gtag_type, 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss')});
          window.fbq('trackCustom', 'coinFutures_tranding', {'ID':  memberData.value.MID,  'transaction_type': gtag_type,'time': moment(new Date()).format('YYYYMMDD HH:mm:ss') });

          console.log("event~ :", 'coinFutures_tranding', memberData.value.MID , gtag_type,  moment(new Date()).format('YYYYMMDD HH:mm:ss'))
     
        }
     }

     const goHome = () =>{
      router.push({
            name: 'Home'                    
      })   
     }

     const goLeagueApplyPage = () =>{
      if(leagueType.value == 'normal')
      {
        router.push({
              name: 'Home'                    
        })   
      }
      else if(leagueType.value == 'event')
      {
        router.push({
              name: 'EventLeague'                    
        })   
      }

     }
   

     const onClickShort = async ()=>{
        
        if(!store.state.hts.currentAccountId)
        {       
            emitter.emit('EVENT_ALERT',{title:`알림`, body: `대회 참가신청후 사용해 주시기 바랍니다.`, fn:goLeagueApplyPage}); 
            return;
        }


        var params =   {
            "spaceId": store.state.hts.currentSpaceId,
            "type": "G",
            "orderType": orderType.value,
            "tradeType": tradeType_tap.value == 'in'?'4':'6',
            "code": props.selectItem.CODE,
            "price": currentOrderPrice.value,
            "count": Constant.UTIL.toFixedNum(currentOrderNum_short.value, 4)
        }

        if(params.count == 0)
        {
           let txt = tradeType_tap.value == 'in'?'진입 가능한 포지션이 없습니다':'정리 가능한 포지션이 없습니다'
           emitter.emit('EVENT_ALERT',{title:`주문 접수`, body: txt});
           return;
        }


        var res = await apiCall_pt(`vspace/orderregister`, params, null, (message, code)=>{            
          if(code && code ==-102 )
          {
              emitter.emit('EVENT_ALERT',{title:`알림`, body: message, fn:goHome}) 
          }
          else
          {
              emitter.emit('EVENT_ALERT',{title:`알림`, body: message}) 
          }
        });     
        if(res)
        {
         // console.log("res", res)
          emitter.emit('EVENT_ALERT',{title:`주문 접수`, body: `주문이 정상 접수되었습니다.`});


          setTimeout(()=>{
              onOrderReset()   
          },1000)

          var gtag_type = null;
          if(params.tradeType == '4')
          {
             gtag_type = 'short_in'
          }
          else if(params.tradeType == '6')
          {
             gtag_type = 'short_out'
          }

          event( 'coinFutures_tranding', { 'ID': memberData.value.MID, 'transaction_type': gtag_type, 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss')});
          window.fbq('trackCustom', 'coinFutures_tranding', {'ID':  memberData.value.MID,  'transaction_type': gtag_type,'time': moment(new Date()).format('YYYYMMDD HH:mm:ss') });
          console.log("event~ :", 'coinFutures_tranding', memberData.value.MID , gtag_type,  moment(new Date()).format('YYYYMMDD HH:mm:ss'))

        }
     }




 

      watch(currentBalance,()=>{
        // console.log("currentBalance:~~", currentBalance.value)
        getProfitlist()
      })

     watch(propsCurrentSelectOrderPrice,()=>{  
         try{
              if(orderType.value == 'M')return;              
                currentOrderPrice.value = propsCurrentSelectOrderPrice.value;   
                getAvailableorder()
               
         }catch(e){
          e
         }        
     })
     

    watch(range_1, ()=>{

        if(isRangeMode.value)
        {

           currentOrderNum_long.value = availableorder_long.value * (range_1.value/100)
           currentOrderNum_short.value = availableorder_short.value * (range_1.value/100)

           currentTradePrice_long.value = currentOrderPrice.value * currentOrderNum_long.value; 
           currentTradePrice_short.value = currentOrderPrice.value * currentOrderNum_short.value;
            
           
        }




    })










      watch(currentOrderPrice, ()=>{
         deSelectRangeBtn() 
         currentTradePrice_long.value = currentOrderPrice.value * currentOrderNum_long.value; 
         currentTradePrice_short.value = currentOrderPrice.value * currentOrderNum_short.value;

      })







      const onUpdateOrderPrice = (value) =>{
        // console.log("onUpdateOrderPrice", value)
         currentOrderPrice.value = value;
         currentTradePrice_long.value = currentOrderPrice.value * currentOrderNum_long.value; 
         currentTradePrice_short.value = currentOrderPrice.value * currentOrderNum_short.value;


         getAvailableorder()        
      }

      const onUpdateOrderNum = (value) =>{
          isRangeMode.value = false;  
          isCustomMode.value = false;
          isSliderMode.value = false; 
          deSelectRangeBtn()  
          currentOrderNum_ipt.value = value
          currentOrderNum_long.value = value;
          currentOrderNum_short.value = value;
          currentTradePrice_long.value = currentOrderPrice.value * currentOrderNum_long.value; 
          currentTradePrice_short.value = currentOrderPrice.value * currentOrderNum_short.value;

       
      }





      const onOrderReset = () =>{
        isInputMode.value = true;
        isRangeMode.value = false;  
        isCustomMode.value = false;
        isSliderMode.value = false; 
        range_1.value = 0;  
        deSelectRangeBtn()        

        setTimeout(()=>{
            var t_num = Number(tradeObj.value.price)
            
            currentOrderNum_ipt.value = 0
            currentOrderNum_long.value = 0;
            currentOrderNum_short.value = 0;
            if(!isNaN(t_num)) {      
              currentOrderPrice.value = t_num;              
            }
            getAvailableorder();
        },0)

     }

    emitter.on('EVENT_UPDATE_AVAILABLE_COUNT', () => {   
          currentOrderPrice.value = propsCurrentSelectOrderPrice.value;   
          getAvailableorder()
    })
     
     onMounted( async ()=>{          
  
          emitter.off('EVENT_SELECTED_ITEM_TOORDER');
          emitter.on('EVENT_SELECTED_ITEM_TOORDER', (item)=>{
            trace("EVENT_SELECTED_ITEM_TOORDER^^", item)
            setTimeout(()=>{
              try{                
                    
                   
                    drawPosition()                     
                    getleverage();   
                    onOrderReset()                              
              }catch(e){
              e
              }
            }, 500)
          })



          setTimeout(()=>{
              try{
                
                    
                    getleverage(); 
                    onOrderReset()                             
              }catch(e){
              e
              }
          }, 1000)
     })



   const onTapPositionType = (_type) =>{
       orderType.value = 'L'
       checkTypeL.value.checked = true;
       tradeType_tap.value = _type
       onOrderReset();   
   }


   const  onTapBuyType = (_type) =>{
         orderType.value = _type;
         onOrderReset();

   }


    
    const goLoginPage = ()=>{   
      location.href = Config.URL.flexLifeUrl + `login?app=money&redirect_url=${Config.URL.flexMoneyUrl}`;
    }



    const getAvailableorder = async()=>{        
      // console.log("getAvailableorder : ", numOfleverage.value )
       if(tradeType_tap.value == "in")
       {

          if(!currentOrderPrice.value || currentOrderPrice.value == 0  || !store.state.hts.currentSpaceId )return;
          var _price = currentOrderPrice.value;
          if(orderType.value == 'M')
          {
            _price = currentOrderPrice.value + (currentOrderPrice.value * 0.05)     
          }

          var t_availableBalance = currentBalance.value;
          var t_assetMargin = 0; 
          if(assetlist.value && assetlist.value.length > 0)          {
               
               assetlist.value.forEach(element => {                  
                  t_assetMargin += Number(element.ASSET_MARGIN);                 
               })
          }


          var t_orderMargin = 0;
         
          if(orderList.value && orderList.value.length > 0) {
               
               orderList.value.forEach(element => {        
                
                 if(element.TRADETYPE == '3' || element.TRADETYPE == '4')
                 {
                      var orderPrice = Number(element.ORDERINDEX);
                      var orderCnt =  Number(element.REMAINCOUNT);
                      var leverage =  Number(element.LEVERAGE);
                      var fee = orderPrice * orderCnt * (Number(element.FEES) / 100)
                      var orMargin = orderPrice * orderCnt / leverage;    
                      t_orderMargin += (orMargin + fee);
                 }
                  
               })
          }
        
          t_availableBalance -= t_assetMargin;
          t_availableBalance -= t_orderMargin;


          var t_availableCnt = (t_availableBalance * numOfleverage.value  / _price)/(1 + (numOfleverage.value *props.selectItem.FEES/100))
       
          if(t_availableCnt < 0) t_availableCnt = 0;
          if(t_availableBalance < 0) t_availableBalance = 0;

          availableorder_long.value = t_availableCnt;
          availableorder_short.value =t_availableCnt;
          availBalance.value = t_availableBalance;
       }
       else if(tradeType_tap.value == "out")
       {
          setTimeout(()=>{
            var longOutCnt =  getPossblieOutCnt(props.selectItem.CODE, 3);
            var shortOutCnt =  getPossblieOutCnt(props.selectItem.CODE, 4);
          
            availableorder_long.value = longOutCnt;
            availableorder_short.value = shortOutCnt;          
          }, 500)

       }
       
    }

    const getPossblieOutCnt = (_code, _type)=>{
         
          var result = 0;
          if(assetlist.value.length && assetlist.value.length > 0)
          {
               assetlist.value.forEach(element => {
               if(element.CODE == _code  && element.TRADETYPE ==  _type)
               {
                  result = Number(element.COUNT);                              
               } 
                             
             });
          }

           var foundOrder =  orderList.value.find(element => element.CODE == _code);
           if(foundOrder)
           { 
              if(_type == '3')
              {
                    if(foundOrder.TRADETYPE == '5')
                    {
                       result = result - Number(foundOrder.ORDERCOUNT);
                    }
              }
              else if(_type == '4')
              {
                    if(foundOrder.TRADETYPE == '6')
                    {
                       result = result - Number(foundOrder.ORDERCOUNT);
                    }
              }
           }

           //console.log("getPossblieOutCnt :", _code, _type, result)
          return result;
      }

    const getleverage = async()=>{       
         
        if(!store.state.hts.currentAccountId || store.state.hts.currentAccountId == null)return; 
        var res = await apiCall_gt(`vspace/getleverage?accountId=${store.state.hts.currentAccountId}&code=${props.selectItem.CODE}`);     
        if(res)
        {
          
          numOfleverage.value = parseInt(res.data.L_LEVERAGE);
         // console.log("getleverage:~~",numOfleverage.value )
          templeverage.value = numOfleverage.value;   
          getAvailableorder();   
        }       
    }

    const setleverage = async(_leverage)=>{     
       if(!isLogin.value)
       {
            emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.", fn:goLoginPage});
            return;
       }
       if(!accountId.value)
       {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"대회 참가신청후 사용해 주시기 바랍니다", fn:goLeagueApplyPage});
              return;
       }
        var params = {
          "accountId": store.state.hts.currentAccountId,
          "code": props.selectItem.CODE,
          "lLeverage": _leverage,
          "sLeverage": _leverage
        }

   


        var res = await apiCall_pt(`vspace/leveragemodify`, params, null, (message)=>{
          emitter.emit('EVENT_ALERT',{title:"알림", body:message});
        });   
        if(res)
        {
          numOfleverage.value = _leverage;
          templeverage.value = _leverage;          
          getAvailableorder()
        }       
    }
   
    const onChangeLeverage = () =>{
     
     //var val = e.target.value;
     //console.log("onLeverageLong :", templeverage.value )
     // if(val == 0) val =1;
     // templeverage.value = val;
      
    }

    const onSetLeverage = () =>{
          setleverage(  templeverage.value )
         
    }

    const onRangeChange_1 = () =>{
       //trace("onRangeChange_1~~:",  range_1)
       //orderNumRange.value = e.target.value;
      //console.log("orderNumRange.value:", orderNumRange.value)

      // if(orderNumRange.value == 0)orderNumRange.value = 1;
     // currentOrderNum.value =  (availableorder.value *  orderNumRange.value)/100 ;


      //currentOrderNum_long.value = (availableorder_long.value*orderNumRange.value)/100;
      //currentOrderNum_short.value = (availableorder_short.value*orderNumRange.value)/100;

    }


    const onInputChange = () =>{
      deSelectRangeBtn();     
      isInputMode.value =true;
    }


    const getProfitlist = async()=>{
      
        var res = await apiCall_gt(`vspace/getprofitlistbyday?type=${props.selectItem.TYPE}&spaceId=${store.state.hts.currentSpaceId}`);
        if( res)
        {
            ProfitData.value._dataSum = res.dataSum;
            ProfitData.value._rankRow = res.rankRow;
        }
 
       // trace("getProfitlist~~~", ProfitData.value._rankRow, res)
    }
  // setTimeout((getProfitlist), 1000);


  const getProfitPre = () =>{
        var result; 
        if(!ProfitData.value._dataSum)return 0;
        var profit = Number(ProfitData.value._dataSum.TPOL) + currentTotalProfit.value;
        result =  (profit/payMoney.value)*100;           
      //   console.log("payMoney.value", ProfitData.value._dataSum.TPOL, currentTotalProfit.value )
       return Constant.UTIL.toFixedNum(result, 2);
  }


   
    const getCssColor = (num) => {
        var result = ''
        if(num > 0)
        {
            result = "up" 
        }
        else if(num < 0)
        {
            result = "down"         
        }
        else
        {
            result = ""      
        } 
        return result;
    }

    const onNeedLogin = (e) =>{
       console.log("onNeedLogin", e)
       if(!isLogin.value)
       {
            emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.",  fn:goLoginPage});
       }
       else if(!accountId.value)
       {
            
             emitter.emit('EVENT_ALERT',{title:"주문 접수", body:"대회 참가신청후 사용해 주시기 바랍니다.", fn:goLeagueApplyPage});
       }
       
    }


    const closeModal =()=>{
          showModal_retry.value = false; 
    }

    const okModal = async() =>{
        showModal_retry.value = false; 

        var res = await apiCall_pt(`vspace/retrySpace`, {"accountId": store.state.hts.currentAccountId},null,(_message)=>{
           emitter.emit('EVENT_ALERT',{title:"알림", body:_message});
         });
         //console.log("onRetryOk:", res)
         if(!res)return         
         emitter.emit('EVENT_ALERT',{title:"알림", body:"초기화 되었습니다. 새로고침 합니다.", fn:()=>{window.location.reload()}});
    }  

    const onClickRetry =()=>{
        if(!isLogin.value)
        {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다."});
             return
        }

         if(!store.state.hts.currentAccountId)
        {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"대회참가 후 사용가능합니다."});
             return
        }

        showModal_retry.value = true; 

    }


    const selectRangeBtn = (e) => {
       isInputMode.value =false;
      var orderButtons = document.querySelectorAll('.order-btn .btn')
      orderButtons.forEach((orderBtn) => {
        if(orderBtn.classList.contains('active')) {
          orderBtn.classList.remove('active');
        } 
      });
       e.target.classList.add('active'); 
    }

    const deSelectRangeBtn = () => {       
      var orderButtons = document.querySelectorAll('.order-btn .btn')
      orderButtons.forEach((orderBtn) => {
        if(orderBtn.classList.contains('active')) {
          orderBtn.classList.remove('active');
        }
      });
    }
    
    const onClickRangeActive = (_type) => {
      if(_type == 1)
      {
         isRangeMode.value = true;
         isCustomMode.value = false;
         range_1.value = 10;
      }
      else if(_type == 2)
      {
         isRangeMode.value = true;
         isCustomMode.value = false;
         range_1.value = 25;
      }
      else if(_type == 3)
      {
         isRangeMode.value = true;        
         isCustomMode.value = false;
         range_1.value = 50;
      }
      else if(_type == 4)
      {
         isRangeMode.value = true;    
         isCustomMode.value = false;  
         range_1.value = 100; 
        
      }      
      else if(_type == 5)
      {
         isRangeMode.value = true;  
         isSliderMode.value = true;  
         isCustomMode.value = true;   
         if( range_1.value == 0 )range_1.value=50
      }   
      else if(_type == 6)  
      {       
        isSliderMode.value = false;   
        setTimeout(()=>{
           customRangeBtn.value.classList.add('active')
        },500);
      }   
    }

    const onClickRangeActive_pop = (_type) => {
          if(_type == 1)
          {
            isRangeMode_pop.value = true;
            isCustomMode_pop.value = false;
            templeverage.value = 10;
            customRangeBtn_pop.value.classList.remove('active')
          }
          else if(_type == 2)
          {
            isRangeMode_pop.value = true;
            isCustomMode_pop.value = false;
            templeverage.value = 25;
            customRangeBtn_pop.value.classList.remove('active')
          }
          else if(_type == 3)
          {
            isRangeMode_pop.value = true;        
            isCustomMode_pop.value = false;
            templeverage.value = 50;
            customRangeBtn_pop.value.classList.remove('active')
          }
          else if(_type == 4)
          {
            isRangeMode_pop.value = true;    
            isCustomMode_pop.value = false;
            templeverage.value = 100; 
            customRangeBtn_pop.value.classList.remove('active')
            
          }      
          else if(_type == 5)
          {
            isRangeMode_pop.value = true;  
            isCustomMode_pop.value = true;
            isSliderMode_pop.value = true;  
           
            if( templeverage.value == 0 )templeverage.value=50
          }   
          else if(_type == 6)  
          {       
            isSliderMode_pop.value = false;   
            setTimeout(()=>{
              customRangeBtn_pop.value.classList.add('active')
            },500);
          }   
        }

        const popupRangeBtn = (e) => {
          var popupRangeButtons = document.querySelectorAll('.mode-item-leverage .order-btn .btn');
          popupRangeButtons.forEach((popupRangeBtn) => {
            if(popupRangeBtn.classList.contains('active')) {
              popupRangeBtn.classList.remove('active');
            } 
          });
          e.target.classList.add('active'); 
        }


      return{
         Constant,
         orderType,   
         currentOrderPrice,  
         currentTradePrice_long,
         currentTradePrice_short,        
         onUpdateOrderPrice,
         onUpdateOrderNum,   
         onOrderReset,    
         onTapBuyType,        
         onTapPositionType,    
         tradeType_tap,   
         onChangeLeverage,
         numOfleverage,
         templeverage,
         onSetLeverage,
         //onRangeOrderNum,
         //orderNumRange,
         currentOrderNum_long,
         currentOrderNum_short,
         range,
         onClickLong,
         onClickShort,
         checkTypeL,
         getPossblieOutCnt,
         currentSpaceName,
         currentBalance,
         ProfitData,
         getCssColor,
         isInputMode,
         onInputChange,
       
         totalBalance_f,
         getProfitPre,
         currentTotalProfit,         
         onNeedLogin,
         isLogin,
         accountId,     
         retrycount,
         recount,
         availBalance,
         closeModal,
         onClickRetry,
         showModal_retry,
         okModal,
         selectRangeBtn,
         onClickRangeActive,
         onClickRangeActive_pop,
         isSliderMode,
         isSliderMode_pop,
         customRangeBtn,
         customRangeBtn_pop,
         isCustomMode,
         isCustomMode_pop,
         range_1,
      
         currentOrderNum_ipt,
         onRangeChange_1,
         availableorder_long,
         availableorder_short,
         popupRangeBtn,
      } 
  }

  

}
</script>

<style>

</style>