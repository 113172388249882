<template>
      <!-- 매수주문확인(지정가) start -->
      <div class="modal micromodal-slide" id="modal-order-confirm-buy" aria-hidden="true">
        <div class="modal__overlay" tabindex="-1" data-micromodal-close>
          <div
            class="modal-content modal-box"
            role="dialog"
            aria-modal="true"
          >
            <div class="modal-header">
              <h1 class="modal-title">주문 확인</h1>
              <button type="button" class="btn-close" data-micromodal-close="true"></button>
            </div>
            <div class="modal-body">
              <h2 class="stock-title buy">
                {{selectItem.KORNAME + " ("+selectItem.CODE+ ")"}}
              </h2>
              <div class="item-list type buy">
                <h3 class="item-title">주문구분</h3>
                <p class="item-content">지정가</p>
                <em class="item-unit">매수</em>
              </div>
              <div class="item-list">
                <h3 class="item-title">주문가격</h3>
                <p class="item-content">{{Constant.UTIL.numberWithCommas(orderData.orderPrice)}}</p>
                <em class="item-unit">{{selectItem.TYPE == 'F'?'':'KRW'}}</em>
              </div>
              <div class="item-list amount">
                <h3 class="item-title">주문수량</h3>
                <p class="item-content">{{selectItem.TYPE == 'C' ? Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(orderData.orderNum, 8)):Constant.UTIL.numberWithCommas(orderData.orderNum)}}</p>
                <em class="item-unit">{{selectItem.TYPE == 'C' ? selectItem.CODE.split('-')[1]:selectItem.TYPE == 'F'?'계약':'주'}}</em>
              </div>
              <div class="item-list" v-show="selectItem.TYPE != 'F'">
                <h3 class="item-title">주문금액</h3>
                <p class="item-content">{{Constant.UTIL.numberWithCommas(Math.floor(orderData.tradePrice))}}</p>
                <em class="item-unit">KRW</em>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-micromodal-close="true"  @click="onCanceled">취소</button>
              <button type="button" class="btn btn-danger" @click="onBuyOk_type1">매수 확인</button>
            </div>
          </div>
        </div>
      </div>


      <!-- 매도주문확인(지정가) start -->
        <div class="modal micromodal-slide" id="modal-order-confirm-sell" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close="true">
            <div
              class="modal-content modal-box"
              role="dialog"
              aria-modal="true"
            >
              <div class="modal-header">
                <h1 class="modal-title">주문 확인</h1>
                <button type="button" class="btn-close" data-micromodal-close="true"></button>
              </div>
              <div class="modal-body">
                <h2 class="stock-title sell">
                   {{ selectItem.KORNAME + " ("+selectItem.CODE+ ")"}}
                </h2>
                <div class="item-list type sell">
                  <h3 class="item-title">주문구분</h3>
                  <p class="item-content">지정가</p>
                  <em class="item-unit">매도</em>
                </div>
                <div class="item-list"  >
                  <h3 class="item-title">주문가격</h3>
                  <p class="item-content">{{Constant.UTIL.numberWithCommas(orderData.orderPrice)}}</p>
                  <em class="item-unit">{{selectItem.TYPE == 'F'?'':'KRW'}}</em>
                </div>
                <div class="item-list amount">
                  <h3 class="item-title">주문수량</h3>
                  <p class="item-content">{{selectItem.TYPE == 'C' ? Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(orderData.orderNum, 8)):Constant.UTIL.numberWithCommas(orderData.orderNum)}}</p>
                  <em class="item-unit">{{selectItem.TYPE == 'C' ? selectItem.CODE.split('-')[1]:selectItem.TYPE == 'F'?'계약':'주'}}</em>
                </div>
                <div class="item-list" v-show="selectItem.TYPE != 'F'">
                  <h3 class="item-title">주문금액</h3>
                  <p class="item-content">{{Constant.UTIL.numberWithCommas(Math.floor(orderData.tradePrice))}}</p>
                  <em class="item-unit">{{selectItem.TYPE == 'F'?'':'KRW'}}</em>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-micromodal-close="true" @click="onCanceled" >취소</button>
                <button type="button" class="btn btn-primary"  @click="onSellOk_type1" >매도 확인</button>
              </div>
            </div>
          </div>
        </div>
        <!-- //매도주문확인(지정가) end -->


          <!-- 매수주문확인(시장가) -->
        <div class="modal micromodal-slide" id="modal-order-confirm-direct-buy" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close="true">
            <div
              class="modal-content modal-box"
              role="dialog"
              aria-modal="true"
            >
              <div class="modal-header">
                <h1 class="modal-title">주문 확인</h1>
                <button type="button" class="btn-close" data-micromodal-close="true"></button>
              </div>
              <div class="modal-body">
                <h2 class="stock-title buy">
                   {{selectItem.TYPE == 'F'?selectItem.KORNAME+ " ("+selectItem.CODE+ ")":selectItem.KORNAME + " ("+selectItem.CODE+ ")"}}
                </h2>
                <div class="item-list type buy">
                  <h3 class="item-title">주문구분</h3>
                  <p class="item-content">시장가</p>
                  <em class="item-unit">매수</em>
                </div>
                <div class="item-list amount">
                  <h3 class="item-title">주문수량</h3>
                  <p class="item-content">{{selectItem.TYPE == 'C' ? Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(orderData.orderNum, 8)):Constant.UTIL.numberWithCommas(orderData.orderNum)}}</p>
                  <em class="item-unit">{{selectItem.TYPE == 'C' ? selectItem.CODE.split('-')[1]:selectItem.TYPE == 'F'?'계약':'주'}}</em>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-micromodal-close="true" @click="onCanceled">취소</button>
                <button type="button" class="btn btn-danger" @click="onBuyOk_type2" >매수 확인</button>
              </div>
            </div>
          </div>
        </div>

        <!-- 매도주문확인(시장가) -->
        <div class="modal micromodal-slide" id="modal-order-confirm-direct-sell" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close="true">
            <div
              class="modal-content modal-box"
              role="dialog"
              aria-modal="true"
            >
              <div class="modal-header">
                <h1 class="modal-title">주문 확인</h1>
                <button type="button" class="btn-close" data-micromodal-close="true"></button>
              </div>
              <div class="modal-body">
                <h2 class="stock-title sell">
                   {{selectItem.TYPE == 'F'?selectItem.KORNAME+ " ("+selectItem.CODE+ ")":selectItem.KORNAME + " ("+selectItem.CODE+ ")"}}
                </h2>
                <div class="item-list type sell">
                  <h3 class="item-title">주문구분</h3>
                  <p class="item-content">시장가</p>
                  <em class="item-unit">매도</em>
                </div>
                <div class="item-list amount">
                  <h3 class="item-title">주문수량</h3>
                  <p class="item-content">{{selectItem.TYPE == 'C' ? Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(orderData.orderNum, 8)):Constant.UTIL.numberWithCommas(orderData.orderNum)}}</p>
                  <em class="item-unit">{{selectItem.TYPE == 'C' ? selectItem.CODE.split('-')[1]:selectItem.TYPE == 'F'?'계약':'주'}}</em>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-micromodal-close="true" @click="onCanceled">취소</button>
                <button type="button" class="btn btn-primary" @click="onSellOk_type2">매도 확인</button>
              </div>
            </div>
          </div>
        </div>
      <!-- } 주문확인 창 종료 -->
</template>

<script>
import { getCurrentInstance , ref, computed} from 'vue'
import MicroModal from 'micromodal';
import Constant from '@/constant.js';
import axios from '@/axios';
//import { useToast } from '@/composables/toast';
import { useStore } from 'vuex';
import { useRouter }from 'vue-router'
import { event } from 'vue-gtag'
import moment from 'moment';

export default {
  props:{
    selectItem:{     
      required: true
    }
  },
  setup(props)
  {

    var startObj = {
      orderType:"L",
      tradeType:"1",
      orderPrice:0, 
      orderNum:0,
      tradePrice:0

    }
    const router = useRouter();
    MicroModal.init();
    const store = useStore();     

    // const {         
    //         triggerToast
    //  } = useToast();

    const leagueType = computed(() => store.state.trade.currentLeagueType);
 
    const memberData = computed(()=> store.state.user.memberData)

    const orderData = ref(startObj);
    const internalInstance = getCurrentInstance(); 
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    emitter.off('EVENT_OPEN_MODAL_ORDER')
    emitter.on('EVENT_OPEN_MODAL_ORDER', (data) => {  
      // console.log("EVENT_OPEN_MODAL_ORDER", store.state.hts.currentAccountId, data.spadeId)
       if (store.state.hts.currentAccountId == null) {                 
              //if(popId) MicroModal.close(popId);   
              console.log("leagueType.value:", leagueType.value)
              if(leagueType.value == 'normal')
              {
                  alertModal_one(`주문 접수`, '대회 참가신청후 사용해 주시기 바랍니다.', 1);
              }
              else if(leagueType.value == 'event')
              {
                 alertModal_one(`주문 접수`, '대회 참가신청후 사용해 주시기 바랍니다.', 2);
              }
              
              return;                  
        }

        if(data.spadeId == null)
        {
            //if(popId) MicroModal.close(popId);   
            alertModal_one(`주문 접수`, "대회기간이 아닙니다");            
            return;
        }







      var isAlertPops = JSON.parse(localStorage.getItem('isAlertPops'));
     
      
     
      console.log(props.selectItem.CODE, props.selectItem.NAME)
      orderData.value = data;
      if(orderData.value.orderType == "L")
      {
        if(orderData.value.tradeType == "1")
        {           
            if(isAlertPops != null  && !isAlertPops.buy)
            {
               //console.log('EVENT_OPEN_MODAL_ORDER11111');
                 MicroModal.show('modal-order-confirm-buy')
                  onBuyOk_type1();
            }
            else
            {  
              // console.log('EVENT_OPEN_MODAL_ORDER2222');             
                  MicroModal.show('modal-order-confirm-buy')
            }
           
        }
        else if(orderData.value.tradeType == "2")
        {

          if(isAlertPops != null  && !isAlertPops.sell)
          {
                MicroModal.show('modal-order-confirm-sell')
                onSellOk_type1();
          }
          else
          {               
                MicroModal.show('modal-order-confirm-sell')
          }
          
        }
       
      }
      else if(orderData.value.orderType == "M")
      {

        if(orderData.value.tradeType == "1")
        {
          if(isAlertPops != null  && !isAlertPops.buy)
          {
             MicroModal.show('modal-order-confirm-direct-buy');
             onBuyOk_type2();
          }
          else
          {
            MicroModal.show('modal-order-confirm-direct-buy');
          }
            
        }
        else if(orderData.value.tradeType == "2")
        {
           if(isAlertPops != null  && !isAlertPops.sell)
           {
                 MicroModal.show('modal-order-confirm-direct-sell')
                 onSellOk_type2()
           }
           else
           {
                MicroModal.show('modal-order-confirm-direct-sell')
           }
           
        }
      }     

    });

     emitter.off('EVENT_OPEN_MODAL_ORDER_CANCLE')     
     emitter.on('EVENT_OPEN_MODAL_ORDER_CANCLE', (data) => {
      
       //console.log('EVENT_OPEN_MODAL_ORDER_CANCLE')
        var isAlertPops = JSON.parse(localStorage.getItem('isAlertPops'));
        if(data.tradeType == "1")
        {          
            if(isAlertPops != null  && !isAlertPops.order)
            {
                cancle_order(data);
            }
            else
            {
               alertModal_order_cancle("주문취소", "선택한 주문을 취소할까요?", data);
            }
           
        }
        else if(data.tradeType == "2")
        {
            if(isAlertPops != null  && !isAlertPops.order)
            {
                cancle_order(data);
            }
            else
            {
               alertModal_order_cancle("주문취소", "선택한 주문을 취소할까요?", data);
            }      
        }
    });




    const onSellOk_type1 = () =>{        
       sendOrder(orderData.value, 'modal-order-confirm-sell' );       
    };
    const onBuyOk_type1 = () =>{
       sendOrder(orderData.value, 'modal-order-confirm-buy');
    };

    const onSellOk_type2 = () =>{
       sendOrder(orderData.value, 'modal-order-confirm-direct-sell');
    };
    const onBuyOk_type2 = () =>{
       sendOrder(orderData.value, 'modal-order-confirm-direct-buy' );
    };

    const getTradingTypeStr = (type)=>{
      var result = null;
      if(type == 'S')
      {
        result = 'stock_tranding'
      }
      else if(type == 'F')
      {
         result = 'futures_tranding'
      }
      else if(type == 'C')
      {
         result = 'crypto_tranding'
      }
      else if(type == 'G')
      {
         result = 'coinFutures_tranding'
      }
      return result;
    }




    const sendOrder = async (_orderData, popId)=>{                 
          var token = sessionStorage.getItem('token');          
          var params = {
              spaceId: _orderData.spadeId,
              type: _orderData.type,
              orderType:_orderData.orderType,
              tradeType:_orderData.tradeType,
              code:_orderData.code,
              price:_orderData.type == 'F'?_orderData.orderPrice:_orderData.type == 'C'?Number(_orderData.orderPrice).toFixed(2):Math.floor(_orderData.orderPrice),
              count:_orderData.orderNum
          } 
          //console.log("sendOrder :", params)
       
          if(params.count <= 0)
          {
            if(popId) MicroModal.close(popId);   
            alertModal_one(`주문 접수`, "주문 수량을 확인해 주세요.(주문수량:0)");            
            return;
          }

          if(params.type == 'C')
          {
            if(_orderData.tradePrice < 5000)
            {
               if(popId) MicroModal.close(popId);   
              alertModal_one(`주문 접수`, "최소 주문금액은 5,000원입니다.");             
              return;
            }
          }

         
          if(params.type == 'F')
          {
            if(params.tradeType == '1') params.tradeType = '3';
            else if(params.tradeType == '2')  params.tradeType = '4';
            // console.log("sendOrder :", _orderData.item)
            if(_orderData.item.SUBTYPE == "K")
            {    
              
              if(_orderData.item.isNight) params.isNight = "Y";
              else params.isNight = "N";
             // params.isNight = _orderData.item.isNight;
              
            }
          }

        try{
            const res = await axios.post('/vspace/orderregister', params ,{
              headers: {
                    'token': token,                    
              }
            });   
            let json =  res.data;   
            //let orderRow =  json.resultMap.orderRow;

          
            if(popId) MicroModal.close(popId);        
            if (json.returnCode == 1) {
              
              if (json.resultMap.returnVal == 1) {  
                 // triggerToast('주문이 완료되었습니다.');
                  var _type = null;
                  var gtag_type = null
                 if(json.resultMap.orderRow.TRADETYPE == "1" || json.resultMap.orderRow.TRADETYPE == "3")
                 {
                    _type = "매수";   
                    gtag_type = 'buy'               
                   // store.commit('hts/PUSH_BUY_BTN', orderRow);
                 }
                 else if(json.resultMap.orderRow.TRADETYPE == "2" || json.resultMap.orderRow.TRADETYPE == "4")
                 {
                    _type = "매도"
                    gtag_type = 'sell'  
                  //    store.commit('hts/PUSH_SELL_BTN', orderRow);
                 }
                 alertModal_one(`${_type}주문 접수`, `${_type}주문이 정상 접수되었습니다.`  ); 
                
                //  selectButton.innerHTML = "(1)"
                //  selectButton.id = json.resultMap.orderRow.ID;
                //  selectButton.type = "S";
                //  selectButton.tradeType = json.resultMap.orderRow.TRADETYPE;                  
                //  selectButton = null;   
                 event( getTradingTypeStr(params.type), { 'ID': memberData.value.MID, 'transaction_type': gtag_type, 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss')});
                 window.fbq('trackCustom', getTradingTypeStr(params.type), {'ID': memberData.value.MID,  'transaction_type':gtag_type,'time': moment(new Date()).format('YYYYMMDD HH:mm:ss') });
                 console.log("event~ :",getTradingTypeStr(params.type), memberData.value.MID , gtag_type, moment(new Date()).format('YYYYMMDD HH:mm:ss'))



              } 
              else
              {
                // triggerToast(json.message, 'danger');
                 alertModal_one(`주문 접수`, json.message);
              }
            }
            else if(json.returnCode == -102 )
            {
                alert("로그인이 필요합니다.");
            }
            else
            {
              //triggerToast(json.message, 'danger');
               alertModal_one(`주문 접수`, json.message);
            }               
        }catch(error){
           // triggerToast('Something went wrong!', 'danger');
            console.log(error);
        }  
    }




    const cancle_order = async(_data) =>{
        var token = sessionStorage.getItem('token');          
        var params = {           
            type: _data.type,
            orderId:_data.orderId           
        }    

       try{
            const res = await axios.post('/vspace/ordercancel', params ,{
              headers: {
                    'token': token,                    
              }
            });   
            let json =  res.data;    
            //console.log("cancle_order json", json, '_data :', _data ); 
            if (json.returnCode == 1) {
              
              if (json.resultMap.returnVal == 1) {  
                // triggerToast('주문 취소 신청이 접수되었습니다.');                 
                 alertModal_one(`주문 취소 접수`, `주문 취소 신청이 접수되었습니다.`); 
                

              } else {
                //  triggerToast(json.message, 'danger');
                  alertModal_one(`주문 취소 접수`, json.message);
                  
              }
            }
            else if(json.returnCode == -102 )
            {
                alert("로그인이 필요합니다.");
            }
            else
            {
              //triggerToast(json.message, 'danger');
               alertModal_one(`주문 취소 접수`, json.message);
            }               
        }catch(error){
          //  triggerToast('Something went wrong!', 'danger');
            console.log(error);
        }  

    }
     
    // 경고창 호출
    const alertModal_order_cancle = (title, message, data) => {
        document.querySelector('#modal-order-alert .modal-title').innerHTML = title || '알림';
        document.querySelector('#modal-order-alert .modal-box-alert').innerHTML = message || '시스템에 문제가 발생하였습니다.';
        //console.log("alertModal_order_cancle: ", data)
        var okbtn = document.querySelector('#modal-order-alert .confirm');
        okbtn.addEventListener('click', function clicked (event)  {
              event.preventDefault();                
              event.target.removeEventListener('click', clicked);

              //console.log("alertModal_order_cancle  clicked", data)
              cancle_order(data);
        });  

        MicroModal.show('modal-order-alert');
    }

         // 경고창 호출
    const alertModal_one = (title, message, stt = null) =>  {
        document.querySelector('#modal-order-alert-one .modal-title').innerHTML = title || '알림';
        document.querySelector('#modal-order-alert-one .modal-box-alert').innerHTML = message || '시스템에 문제가 발생하였습니다.';
        var okbtn = document.querySelector('#modal-order-alert-one .confirm');
        okbtn.addEventListener('click', function clicked (event)  {
              event.preventDefault();  
              event.target.removeEventListener('click', clicked);
              console.log("참가신청 페이지로 이동", stt);
              if(stt && stt == 1)
              {
                 
                  router.push({
                    name: 'Home'                    
                  })     

              }
              else if(stt && stt == 2)
              {
                   router.push({
                    name: 'EventLeague'                    
                  })     
              }
        });      
        MicroModal.show('modal-order-alert-one');
    }



   // setTimeout(()=>{MicroModal.show('modal-order-confirm-buy')},2000);
    return{
        orderData,
        Constant,
        onSellOk_type1,
        onBuyOk_type1,
        onSellOk_type2,
        onBuyOk_type2,
    }
  }
}
</script>

<style>

</style>