const methods = {
    console_log(){
      
        console.log("trace:", ...arguments)
    }
}


export default {
    install(Vue) {        
        Vue.config.globalProperties.$log = methods.console_log;

    }
}
