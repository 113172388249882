<template>
<div class="TVChartContainer" ref="chartContainer" />
</template>

<script>

import { widget } from '../../../public/charting_library';
import Datafeed from '@/js/datafeed.js';
import Datafeed_stock from '@/js/datafeed_stock.js';
import Datafeed_futures from '@/js/datafeed_futures.js';
import Datafeed_cf from '@/js/datafeed_cf.js';
import { getCurrentInstance} from 'vue';
import { useStore } from 'vuex';
function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
getLanguageFromURL
function getDatafeed( type ){
  if(type == "crypto")return Datafeed;
  else if(type == "stock")return Datafeed_stock;
  else if(type == "futures")return Datafeed_futures;
  else if(type == "coinFutures")return Datafeed_cf;
}

function getTimeFrame(_interval)
{
  var result ;
  if( _interval == '1' || _interval == '3' || _interval == '5' ||  _interval == '10')
  {
         result = '1D'
  }
  else if( _interval == '30' || _interval == '60')
  {
         result = '5D'
  }
  else if( _interval == '1D')
  {
          result = '2M'
  }
  else if( _interval == '1W')
  {
          result = '10M'
  }
  return result;
}


export default {
  name: 'TVChartContainer',
  props: {
    symbol: {
     // default: 'Flex:034220',
      type: String,
    },    
    symbolType: {
      default: 'coinFutures',
      type: String,
    },  
    interval: {
      default: '60',
      type: String,
    },
    datafeedUrl: {
      default: 'https://demo_feed.tradingview.com',
      type: String,
    },
    libraryPath: {
      default: '/charting_library/',
      type: String,
    },
    chartsStorageUrl: {
      default: 'https://saveload.tradingview.com',
      type: String,
    },
    chartsStorageApiVersion: {
      default: '1.1',
      type: String,
    },
    clientId: {
      default: 'tradingview.com',
      type: String,
    },
    userId: {
      default: 'public_user_id',
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    studiesOverrides: {
      type: Object,
    },

  },
  tvWidget: null,
  mounted() {      
    //console.log("this.symbol~~~RRRR^^", this.symbol)
    const container = this.$refs.chartContainer;
    const widgetOptions = {
      debug: false,
      symbol: this.symbol,
      timeframe: getTimeFrame(this.interval),
      //auto_save_delay:10,
      // BEWARE: no trailing slash is expected in feed URL
      // datafeed: new window.Datafeeds.UDFCompatibleDatafeed(this.datafeedUrl),
      datafeed: getDatafeed(this.symbolType),
      interval: this.interval,
      container: container,
      library_path: this.libraryPath,
      timezone: "Asia/Seoul",	
     // timezone: "Etc/UTC",	
      //locale: getLanguageFromURL() || 'ko',
      locale: 'ko',
      disabled_features: [ 'control_bar','timeframes_toolbar', 'header_saveload',"header_symbol_search", "header_compare","header_undo_redo", 
      "symbol_search_hot_key",  "header_saveload",],
     // enabled_features: ['study_templates'],
     // charts_storage_url: this.chartsStorageUrl,
     // charts_storage_api_version: this.chartsStorageApiVersion,
      //client_id: this.clientId,
     // user_id: this.userId,
    //  loading_screen: { backgroundColor: "#005500" },
     // fullscreen: true,
      theme: 'Light',
      //autosize: true,
      //height: 600,
      //width: 1000,
    
      symbol_search_request_delay:1000,
      supports_marks: true,
			support_timescale_marks: true,
      custom_css_url: '../themed.css',
      studies_overrides: {
          "volume.volume.color.0": "rgba(0, 0, 255, 0.2)",
          "volume.volume.color.1": "rgba(255, 0, 0, 0.2)",
          "volume.volume ma.color": "#d10db0",
          "volume.volume ma.linewidth": 1,
          "volume.volume ma.visible": false,
         // "bollinger bands.median.color": "#d10db0",
         // "bollinger bands.upper.linewidth": 7
         "Overlay.candleStyle.upColor": "FF0066"
        // Overlay.candleStyle.downColor: color
      },
       time_scale: { 
        min_bar_spacing : 3 , 
      },
      
      overrides: {
					//	"paneProperties.backgroundType": "gradient",
            // "paneProperties.background": "#ffffff",
						// "paneProperties.vertGridProperties.color": "#454545",
						// "paneProperties.horzGridProperties.color": "#454545",
           // "paneProperties.backgroundGradientStartColor": "#f8efef",
           // "paneProperties.backgroundGradientEndColor": "#806e6e",
						// "scalesProperties.textColor" : "#AAA",
            // "scalesProperties.backgroundColor": "#8a0378",
             "mainSeriesProperties.candleStyle.upColor": "#ff0015",
             "mainSeriesProperties.candleStyle.downColor": "#0067ee",
             "mainSeriesProperties.candleStyle.borderUpColor": "#ff0015",
             "mainSeriesProperties.candleStyle.borderDownColor": "#0067ee",
             "mainSeriesProperties.candleStyle.wickUpColor": "#ff0015",
             "mainSeriesProperties.candleStyle.wickDownColor": "#0067ee",
            
			}

    };
    const store = useStore();
    const internalInstance = getCurrentInstance(); 
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;
  
    tvWidget.onChartReady(() => {
     tvWidget.activeChart().onIntervalChanged().subscribe(null, (e)=>{

        // console.log("ddddddddd", store.state.hts.currentTradeType)
        if(store.state.hts.currentTradeType == "S")
        {
           store.commit('hts/CHANGE_CHART_INTERVAL_S', String(e));
        }
        else if(store.state.hts.currentTradeType == "F")
        {
           store.commit('hts/CHANGE_CHART_INTERVAL_F', String(e));
        }
        else if(store.state.hts.currentTradeType == "C")
        {
            store.commit('hts/CHANGE_CHART_INTERVAL_C', String(e));
        }
        else if(store.state.hts.currentTradeType == "G")
        {
            store.commit('hts/CHANGE_CHART_INTERVAL_G', String(e));
        }
      
      });
    // console.log("!!!!!!!!",  tvWidget.activeChart().onIntervalChanged())
   
   //  tvWidget.activeChart().onIntervalChanged().subscribe(null, (interval, timeframeObj) => timeframeObj.timeframe = { value: "12M", type: "period-back" });

// tvWidget.activeChart().onIntervalChanged().subscribe(null,
//     (interval, timeframeObj) => timeframeObj.timeframe = { from: new Date('2015-01-01').getTime() / 1000, to: new Date('2017-01-01').getTime() / 1000, type: "time-range" }
// );




      tvWidget.headerReady().then(() => {
 
      //  var newPrefs = {Candle:{'upColor':'#4b0ebd', 'downColor' :'#4b0ebd'}}
      //  setTimeout(()=>{
      //     tvWidget.activeChart().getSeries().setUserEditEnabled(true)
      //     tvWidget.activeChart().getSeries().setVisible(true)
      //     tvWidget.activeChart().getSeries().setChartStyleProperties(1, newPrefs);
      //     console.log("getSeries")
      //  },1000)

      emitter.off('EVENT_SELECTED_ITEM');
      emitter.on('EVENT_SELECTED_ITEM', (item)=>{
       // console.log("EVENT_SELECTED_ITEM~~", item);
        if(item.TYPE == "C")
        {
          //console.log("eeeeeeeeeeeeeeeeeee 111 setSymbol", store.state.hts.chartIntrval_s)
          tvWidget.setSymbol(`Flex:KRW/${item.CODE.split('-')[1]}`, store.state.hts.chartIntrval_c)
        }   
        else if(item.TYPE == "S")
        {
        //  console.log("eeeeeeeeeeeeeeeeeee 222 setSymbol", item.CODE)
          tvWidget.setSymbol(`Flex:${item.CODE}/${'KR'}`, store.state.hts.chartIntrval_s)
        }  
        else if(item.TYPE == "F")
        {
          if(item.isNight)
          {
             
              tvWidget.setSymbol(`Flex:${item.CODE}_N/${'KR'}`, store.state.hts.chartIntrval_f)
          }
          else
          {
              tvWidget.setSymbol(`Flex:${item.CODE}/${'KR'}`, store.state.hts.chartIntrval_f)
          }
        
          
        } 
        else if(item.TYPE == "G")
        {
          //console.log("eeeeeeeeeeeeeeeeeee 222 setSymbol", item.CODE)
          tvWidget.setSymbol(`Flex:${ item.CODE.substring(0,item.CODE.length-4)}/${ item.CODE.substring(item.CODE.length-4)}`, store.state.hts.chartIntrval_g)
        }   
      })

      // console.log("eeeeeeee:", tvWidget.symbolInterval())
      
      //  watchEffect(()=>{
      //  console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa222222222222", this.symbol)  
      //     if(this.symbol)
      //     tvWidget.setSymbol(this.symbol, '10' , (e)=>{
      //        console.log("eeeeeeeeeeeeeeeeeee setSymbol",e)
      //    })
      //  })
 
      tvWidget.applyOverrides({        
             "mainSeriesProperties.candleStyle.upColor": "#ff0015",
             "mainSeriesProperties.candleStyle.downColor": "#0067ee",
             "mainSeriesProperties.candleStyle.borderUpColor": "#ff0015",
             "mainSeriesProperties.candleStyle.borderDownColor": "#0067ee",
             "mainSeriesProperties.candleStyle.wickUpColor": "#ff0015",
             "mainSeriesProperties.candleStyle.wickDownColor": "#0067ee",   
      })

      setTimeout(()=>{
          // tvWidget.setSymbol('Flex:KRW/ETH', '10' , (e)=>{
          //   console.log("eeeeeeeeeeeeeeeeeee",e)
          // })
          
        //  tvWidget.activeChart().clearMarks();
        // tvWidget.activeChart().createExecutionShape()
        // .setText("@1,320.75 Limit Buy 1")
        // .setTooltip("@1,320.75 Limit Buy 1")
        // .setTextColor("#FFFFFF")
        // .setArrowColor("#008232")
        // .setDirection("buy")
        // .setTime(tvWidget.activeChart().getVisibleRange().to)
        // .setPrice(21500000);

        // console.log('getMarksIIIIII!')



        // const from = Date.now() / 1000 - 500 * 24 * 3600; // 500 days ago
        // const to = Date.now() / 1000;
        // tvWidget.activeChart().createMultipointShape(
        //     [{ time: from, price: 41250000 }, { time: to, price: 27250000 }],
        //     {
        //         shape: "fib_spiral",
        //         lock: true,
        //         disableSelection: true,
        //         disableSave: true,
        //         disableUndo: true,
        //         text: "text",
        //     },
           
        // );
     

       //tvWidget.activeChart().createShape({ time: Date.now(), price:28250000}, { shape: 'horizontal_line' , text: '김경우 '})
      
       //tvWidget.activeChart().createShape({ time: Date.now(), price:28250000}, { shape: 'vertical_line' , text: '김경우 '});


        // tvWidget.activeChart().createExecutionShape()
        // .setText("@1,320.75 Limit Buy 1")
        // .setTooltip("@1,320.75 Limit Buy 1")
        // .setTextColor("rgba(0,255,0,0.5)")
        // .setArrowColor("#0F0")
        // .setDirection("buy")
        // .setTime(widget.activeChart().getVisibleRange().from)
        // .setPrice(27500000);



         
        //  tvWidget.activeChart().refreshMarks();


        //   tvWidget.chart().createPositionLine()
        //   // .onModify(function() {
        //   //     this.setText("onModify called");
        //   // })
        //   // .onReverse("onReverse called", function(text) {
        //   //     this.setText(text);
        //   // })
        //   // .onClose("onClose called", function(text) {
        //   //     this.setText(text);
        //   // })
        //   .setText("매수평균")
        //   //.setTooltip("Additional position information")
        //    // .setProtectTooltip("Protect position")
        //   //.setCloseTooltip("Close position")
        //  // .setReverseTooltip("Reverse position")
        //   .setQuantity("42.25")
        //   .setPrice(21500000)
        //   .setExtendLeft(true)
        //   .setLineStyle(0)
        //   .setLineLength(250)
        //   .setLineColor("#008232")
        //   .setLineWidth(1)

            
    




           

           //var line =  tvWidget.activeChart().createOrderLine()
            // .setTooltip("Additional order information")
            // .setModifyTooltip("Modify order")
            // .setCancelTooltip("Cancel order")
            // .onMove(function() {
            //     this.setText("onMove called");
            // })
            // .onModify("onModify called", function(text) {
            //     console.log("getMarks111", text )
            //     this.setText(text);
            // })
            // .onCancel("onCancel called", function(text) {
            //    console.log("getMarks222", text )
            //     this.setText(text);
            // })
            // .setText("매수평균")
            // .setQuantity("1.8848")
            // .setLineColor("#008232")
            // .setPrice(21500000)
            // .setLineWidth(1)
            // .setQuantityBackgroundColor("#008232")
            // .setQuantityBorderColor("#008232")
            // .setBodyBorderColor("#008232")
           // .setBodyTextColor("#008232")
             //.setQuantityTextColor("#008232")
          //   console.log('getMarksIIIIII!')
           // tvWidget.activeChart().resetData();


          // tvWidget.activeChart().createOrderLine()
          //   // .setTooltip("Additional order information")
          //   // .setModifyTooltip("Modify order")
          //   // .setCancelTooltip("Cancel order")
          //   // .onMove(function() {
          //   //     this.setText("onMove called");
          //   // })
          //   // .onModify("onModify called", function(text) {
          //   //     console.log("getMarks111", text )
          //   //     this.setText(text);
          //   // })
          //   // .onCancel("onCancel called", function(text) {
          //   //    console.log("getMarks222", text )
          //   //     this.setText(text);
          //   // })
          //   .setText("매도평균")
          //   .setQuantity("100")
          //   .setLineColor("#008232")
          //   .setPrice(21400000)
          //   .setLineWidth(1)

          var positionLine = null;

          emitter.off('EVENT_CREATE_POSITION_LINE');
          emitter.off('EVENT_REMOVE_POSITION_LINE');
         
          emitter.on('EVENT_CREATE_POSITION_LINE', (data)=>{           
            if(positionLine)positionLine.remove()
            positionLine =  tvWidget.activeChart().createOrderLine()
            .setText(data.tradeType + "평균")
            .setQuantity(data.count)
            .setLineColor("#008232")
            .setPrice(data.price)
            .setLineWidth(1)
            .setQuantityBackgroundColor("#008232")
            .setQuantityBorderColor("#008232")
            .setBodyBorderColor("#008232")
            .setBodyTextColor("#008232")
            .setBodyFont("bold 14pt Verdana")
          });
         
           emitter.on('EVENT_REMOVE_POSITION_LINE', ()=>{            
            if(!positionLine)return;
            positionLine.remove()
            positionLine = null
           });
          

           var positionLine_long = null;
           var positionLine_short = null;

          emitter.off('EVENT_CREATE_POSITION_LINE_LONG');
          emitter.off('EVENT_REMOVE_POSITION_LINE_LONG');

          emitter.off('EVENT_CREATE_POSITION_LINE_SHORT');
          emitter.off('EVENT_REMOVE_POSITION_LINE_SHORT');


          emitter.on('EVENT_CREATE_POSITION_LINE_LONG', (data)=>{  
           // console.log("tvWidget.activeChart():",tvWidget.activeChart())          
            if(positionLine_long)positionLine_long.remove()
            positionLine_long =  tvWidget.activeChart().createOrderLine()
            .setText(data.tradeType)
            .setQuantity(data.count)
            .setLineColor("#FF0066")
            .setPrice(data.price)
            .setLineWidth(1)
            .setQuantityBackgroundColor("#FF0066")
            .setQuantityBorderColor("#FF0066")
            .setBodyBorderColor("#FF0066")
            .setBodyTextColor("#FF0066")
            .setBodyFont("bold 14pt Verdana")
          });
         
           emitter.on('EVENT_REMOVE_POSITION_LINE_LONG', ()=>{            
            if(!positionLine_long)return;
            positionLine_long.remove()
            positionLine_long = null
           });


            emitter.on('EVENT_CREATE_POSITION_LINE_SHORT', (data)=>{            
            if(positionLine_short)positionLine_short.remove()
            positionLine_short =  tvWidget.activeChart().createOrderLine()
            .setText(data.tradeType)
            .setQuantity(data.count)
            .setLineColor("#0099FF")
            .setPrice(data.price)
            .setLineWidth(1)
            .setQuantityBackgroundColor("#0099FF")
            .setQuantityBorderColor("#0099FF")
            .setBodyBorderColor("#0099FF")
            .setBodyTextColor("#0099FF")
            .setBodyFont("bold 14pt Verdana")
           
          });
         
           emitter.on('EVENT_REMOVE_POSITION_LINE_SHORT', ()=>{            
            if(!positionLine_short)return;
            positionLine_short.remove()
            positionLine_short = null;
           });
          


        
      },0)
       
      //  console.log('getSeries!', tvWidget.activeChart().getSeries().isVisible());

      //  tvWidget.activeChart().onIntervalChanged().subscribe(null, (interval, timeframeObj) =>{console.log('aaaaonIntervalChanged', interval, timeframeObj)});


        // const button = tvWidget.createButton();

        // button.setAttribute('title', 'Click to show a notification popup');
        // button.classList.add('apply-common-tooltip');

        // button.addEventListener('click', () => tvWidget.showNoticeDialog({
        //     title: 'Notification',
        //     body: 'TradingView Charting Library API works correctly',
        //     callback: () => {
        //       // eslint-disable-next-line no-console
        //      // console.log('Noticed!');
        //     },
        //   }));

        // button.innerHTML = 'Check API';
      });
     });
 
   //tvWidget.activeChart().onDataLoaded().subscribe( null,  () => console.log('New history bars are loaded'),  true    );
     
      //     const createAssetLine =(_price, _profitTxt, _quantity)=>{
           
      //       tvWidget.chart().createPositionLine()
      //       // .onModify(function() {
      //       //     this.setText("onModify called");
      //       // })
      //       // .onReverse("onReverse called", function(text) {
      //       //     this.setText(text);
      //       // })
      //       // .onClose("onClose called", function(text) {
      //       //     this.setText(text);
      //       // })
      //       .setText(_profitTxt)
      //     // .setTooltip("Additional position information")
      //       //.setProtectTooltip("Protect position")
      //       //.setCloseTooltip("Close position")
      //       //.setReverseTooltip("Reverse position")
      //       .setQuantity(_quantity + '개')
      //       .setPrice(_price)
      //       .setExtendLeft(true)
      //       .setLineStyle(0)
      //       .setLineLength(250)
      //       .setLineColor("#0084B0")
      //       .setLineWidth(2)
      //   }
      //    setTimeout(()=>{
      //         //createAssetLine(28400000, "PROFIT: 71.1 (3.31%)", 1000000)
      //    },1000)
        
      // createAssetLine

   


  },
  unmounted() {
    const internalInstance = getCurrentInstance(); 
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    emitter.off('EVENT_SELECTED_ITEM');
    
    emitter.off('EVENT_CREATE_POSITION_LINE');
    emitter.off('EVENT_REMOVE_POSITION_LINE');

    emitter.off('EVENT_CREATE_POSITION_LINE_LONG');
    emitter.off('EVENT_REMOVE_POSITION_LINE_LONG');

    emitter.off('EVENT_CREATE_POSITION_LINE_SHORT');
    emitter.off('EVENT_REMOVE_POSITION_LINE_SHORT');

    if (this.tvWidget !== null) {     
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }
  
}
</script>

<style  scoped>
.TVChartContainer {
  /* height: calc(100vh - 80px); */
}
</style>
