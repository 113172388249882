<template>
  <!-- 리스트 start -->
  <div class="section aside-posts-box">
    <div class="posts-title">
      <h4>인기 게시글<span>(조회수 기준)</span></h4>
      <div class="btn-tab-box" @click="onPeriodType" id="popularBtn">
        <span><button type="button" class="btn active">주간</button></span>
        <span><button type="button" class="btn">일간</button></span>
      </div>
    </div>

    <div class="posts-contents">
      <div class="posts-head" v-if="boardList.length">조회수 / 추천수</div>
      <ul class="posts-list">
        <li
          v-for="(item, index) in boardList"
          :key = index
        >
          <div class="posts-txt text-truncate">
            <span class="category"><span class="fs-10">&#91;</span>{{ item.CATEGORY_NAME }}<span class="fs-10">&#93;</span></span>
            <span class="subject">
              <a :href="`/community/${item.IDX}`" >{{ item.SUBJECT }}</a>
            </span>
          </div>
          <div class="posts-cnt">
            <span class="count">{{ item.COUNT }} / {{ item.LIKE_COUNT }}</span>
          </div>
        </li>
        <li class="none" v-if="!boardList.length">
          인기 게시글이 없습니다.
        </li>
      </ul>
    </div>

  </div>
  <!-- //리스트 end -->       
</template>

<script>
import { onMounted, ref } from 'vue'
import {useApicall } from '@/composables/commonFn';

export default {

  setup(){
    const { apiCall_g } = useApicall()
    const boardList = ref([]);
    const periodType = ref('w');
    const periodButton = ref(false);
    const boardIdx = ref('');

    const getDataList = async () => {
      var getUrl = `/board/popularlist?categoryType=community`

      if(periodType.value) {
        var addUrl = `&periodType=${periodType.value}`
        getUrl += addUrl;
      }

      let getList = await apiCall_g(getUrl);
      try {
        if(getList.dataList == null) getList.dataList = [];
        boardList.value = getList.dataList; 
      } catch (error) {
        boardList.value = [];
      }
    }

    const onPeriodType = (e) => {
      var periodTypeBtns = document.querySelectorAll('#popularBtn .btn')
      var currentBtn = e.target;

      periodTypeBtns.forEach((btn) => {
        btn.classList.remove('active');
      });
      currentBtn.classList.add('active');

      periodType.value = '';
      currentBtn.textContent == '주간' ? periodType.value = 'w' : periodType.value = 'd';
      getDataList();
    }

    onMounted( async() => {
      getDataList();
    })

    return {
      boardList,
      onPeriodType,
      periodType,
      periodButton,
      boardIdx,
    }
  }
}
</script>
