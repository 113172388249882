//import { reactive } from 'vue';

export default {


    namespaced: true,
    state: {       
        isLogin:false,  
        isPushComment:'Y',
        memberData :{},
        fcmtoken:''
    },
    mutations: {
        CHANGE_IS_LOGIN(state, payload) {
            //console.log("CHANGE_IS_LOGIN:", state, payload)
            state.isLogin = payload;
        },      
        CHANGE_IS_PUSH_COMMENT(state, payload) {
            // console.log("CHANGE_MEMBER_DATA:", state, payload)
              state.isPushComment = payload;
          },    
        CHANGE_MEMBER_DATA(state, payload) {
          // console.log("CHANGE_MEMBER_DATA:", state, payload)
             state.memberData = payload;
        },   
        CHANGE_FCM_TOKEN(state, payload) {
            // console.log("CHANGE_MEMBER_DATA:", state, payload)
              state.fcmtoken = payload;
        },  
        
    },
    actions: {
        // triggerToast({ commit }, message, type = 'success') {            
        //     commit('ADD_TOAST', {
        //         id: Date.now(),
        //         message: message,
        //         type: type,
        //     });


        //     setTimeout(() => {
        //         commit('REMOVE_TOAST')                  
        //     }, 10000)
        // }
    },
    //computed 와 동일한기능..
    getters: {
        // toastMessageWithSmile(state) {
        //     return state.toasts.message + "^^~~~";
        // }
    },
}