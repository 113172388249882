<template>
        <HeaderTrade />
      <!-- Container Start { -->
      <div id="container">
        <!-- Contents Start { -->
        <div id="contents">

            <!-- 거래 목록에 다른 클래스 설명
              1개 거래 일 때 >> trade-result-area tbl-1 >> 리스트 15개 노출
              2개 거래 일 때 >> .tbl-2 >> 리스트 10개 노출
              3개 거래 일 때 >> .tbl-3 >> 리스트 7개 노출
              4개 거래 일 때 >> 추가 클래스 X >> 리스트 5개 노출
            --> 

          <div class="trade-result-area" id="resultArea">
           <div class="none" v-if="realSpaceList && realSpaceList.length==0" > <h5>보유종목이 없습니다.</h5></div>
            <!-- 주식거래 시작 -->
            <div class="trade-result-box"  v-if="isApply_S">
              <div class="result-box-top">
                <h3>주식거래</h3>

                <div class="box-top-info" :class="Constant.UTIL.getUpdownColor(profitPer_S)">
                  <div class="info">
                    <span class="txt">총보유자산</span>
                    <span class="num">
                        {{Constant.UTIL.numberWithCommas(Math.floor(totalBalance_S))}}
                        <span class="unit">원</span>
                    </span>
                  </div>
                  <div class="info">
                    <span class="txt">수익률</span>
                    <span class="per"><i></i>{{Constant.UTIL.numberWithCommas( Math.abs( Constant.UTIL.toFixedNum(profitPer_S,2)))}}%</span>
                  </div>
                </div>
              </div>

              <!-- 상단 시작  -->
              <div class="trade-result-summary">
                <table class="table">
                  <colgroup>
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:15%">
                  </colgroup>
                  <tr>
                    <th>종목명</th>
                    <th>평가손익</th>
                    <th>수익률</th>
                    <th>매입가</th>
                    <th>보유수량</th>
                    <th>구분</th>
                    <th>현재가</th>
                    <th>매입금액</th>
                  </tr>
                </table>
              </div>
              <!-- //상단 종료 -->

              <!-- 내용 start -->
              <perfect-scrollbar
                @ps-scroll-y="onScroll" 
                :options="options"
                :watch-options="watcher"
                ref="scrollbar"
                class="trade-result-tbl"                          
              >
                <table class="table">
                  <colgroup>
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:15%">
                  </colgroup>
                  <tbody>
                    <tr
                        v-for="AssetItem  in assetListData_S"
                        :key = AssetItem.ID 
                    >
                      <td style="cursor: pointer" @click="onClickedItemName(AssetItem)" >{{AssetItem.NAME}}</td>
                      <td :class="AssetItem.COLOR_CLASS">
                        <span class="hold-profit-loss">{{AssetItem.PROFIT?AssetItem.PROFIT:0}}</span>
                      </td>
                      <td :class="AssetItem.COLOR_CLASS">
                        <span class="hold-per">
                           <i></i>{{AssetItem.profitPre?Math.abs(AssetItem.profitPre).toFixed(2) + '%':0}}
                        </span>
                      </td>
                      <td>{{Constant.UTIL.numberWithCommas(Number(AssetItem.PRICE))}}</td>
                      <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.COUNT, 4))}}</td>
                      <td>{{getTradeType(AssetItem.TRADETYPE)}}</td>
                      <td :class="Constant.UTIL.getUpdownColor(AssetItem.drate)">
                        <span class="hold-price">{{AssetItem.currentPrice?Constant.UTIL.numberWithCommas(AssetItem.currentPrice):0}}</span>
                      </td>
                      <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.COUNT * AssetItem.PRICE, 2))}}</td>
                    </tr>
                  </tbody>
                </table>

                <div class="none" v-if="assetListData_S.length==0">보유종목이 없습니다.</div>
              </perfect-scrollbar>
              <!-- // 내용 end -->

            </div>
            <!-- //주식거래 끝 -->

            <!-- 선물거래 시작 -->
            <div class="trade-result-box" v-if="isApply_F">
              <div class="result-box-top">
                <h3>선물거래</h3>

                <div class="box-top-info" :class="Constant.UTIL.getUpdownColor(profitPer_F)">
                  <div class="info">
                    <span class="txt">총보유자산</span>
                    <span class="num">
                      {{Constant.UTIL.numberWithCommas(Math.floor(totalBalance_F))}}
                      <span class="unit">원</span>
                    </span>
                  </div>
                  <div class="info">
                    <span class="txt">수익률</span>
                    <span class="per"><i></i>{{Constant.UTIL.numberWithCommas(Math.abs(profitPer_F).toFixed(2))}}%</span>
                  </div>
                </div>
              </div>

              <!-- 상단 시작  -->
              <div class="trade-result-summary">
                <table class="table">
                  <colgroup>
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                  </colgroup>
                  <tr>
                    <th>종목명</th>
                    <th>평가손익</th>
                    <th>매입가</th>
                    <th>보유수량</th>
                    <th>구분</th>
                    <th>현재가</th>
                  </tr>
                </table>
              </div>
              <!-- //상단 종료 -->

              <!-- 내용 start -->
              <perfect-scrollbar
                @ps-scroll-y="onScroll" 
                :options="options"
                :watch-options="watcher"
                ref="scrollbar"
                class="trade-result-tbl"                          
              >
                <table class="table">
                  <colgroup>
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                  </colgroup>
                  <tbody>
                    <tr
                        v-for="AssetItem  in assetListData_F"
                        :key = AssetItem.ID 
                    >
                      <td style="cursor: pointer" @click="onClickedItemName(AssetItem)" >{{AssetItem.NAME}}</td>
                      <td :class="AssetItem.COLOR_CLASS">
                        <span class="hold-profit-loss">{{AssetItem.PROFIT?AssetItem.PROFIT:0}}</span>
                      </td>
                      <td>{{Constant.UTIL.numberWithCommas(Number(AssetItem.PRICE))}}</td>
                      <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.COUNT, 4))}}</td>
                      <td>{{getTradeType(AssetItem.TRADETYPE)}}</td>
                      <td :class="Constant.UTIL.getUpdownColor(AssetItem.drate)">
                        <span class="hold-price">{{AssetItem.currentPrice?Constant.UTIL.numberWithCommas(AssetItem.currentPrice):0}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="none" v-if="assetListData_F.length == 0">보유종목이 없습니다.</div>
              </perfect-scrollbar>
              <!-- // 내용 end -->

            </div>
            <!-- //선물거래 끝 -->

            <!-- 코인거래 시작 -->
            <div class="trade-result-box" v-if="isApply_C">
              <div class="result-box-top">
                <h3>코인거래</h3>

                <div class="box-top-info" :class="Constant.UTIL.getUpdownColor(profitPer_C)">
                  <div class="info">
                    <span class="txt">총보유자산</span>
                    <span class="num">
                      {{Constant.UTIL.numberWithCommas(Math.floor(totalBalance_C))}}
                      <span class="unit">원</span>
                    </span>
                  </div>
                  <div class="info">
                    <span class="txt">수익률</span>
                    <span class="per"><i></i>{{Constant.UTIL.numberWithCommas( Constant.UTIL.toFixedNum(Math.abs(profitPer_C), 2))}}%</span>
                  </div>
                </div>
              </div>

              <!-- 상단 시작  -->
              <div class="trade-result-summary">
                <table class="table">
                  <colgroup>
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:15%">
                  </colgroup>
                  <tr>
                    <th>코인명</th>
                    <th>평가손익</th>
                    <th>수익률</th>
                    <th>매입가</th>
                    <th>보유수량</th>
                    <th>구분</th>
                    <th>현재가</th>
                    <th>매입금액</th>
                  </tr>
                </table>
              </div>
              <!-- //상단 종료 -->

              <!-- 내용 start -->
              <perfect-scrollbar
                @ps-scroll-y="onScroll" 
                :options="options"
                :watch-options="watcher"
                ref="scrollbar"
                class="trade-result-tbl"                          
              >
                <table class="table">
                  <colgroup>
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:15%">
                    <col style="width:15%">
                  </colgroup>
                  <tbody>
                    <tr
                        v-for="AssetItem  in assetListData_C"
                        :key = AssetItem.ID 
                    >
                      <td style="cursor: pointer" @click="onClickedItemName(AssetItem)" >{{AssetItem.NAME}}</td>
                      <td :class="AssetItem.COLOR_CLASS">
                        <span class="hold-profit-loss">{{AssetItem.PROFIT?AssetItem.PROFIT:0}}</span>
                      </td>
                      <td :class="AssetItem.COLOR_CLASS">
                        <span class="hold-per">
                           <i></i>{{AssetItem.profitPre?Math.abs(AssetItem.profitPre).toFixed(2) + '%':0}}
                        </span>
                      </td>
                      <td>{{Constant.UTIL.numberWithCommas(Number(AssetItem.PRICE))}}</td>
                      <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.COUNT, 4))}}</td>
                      <td>{{getTradeType(AssetItem.TRADETYPE)}}</td>
                      <td :class="Constant.UTIL.getUpdownColor(AssetItem.drate)">
                        <span class="hold-price">{{AssetItem.currentPrice?Constant.UTIL.numberWithCommas(AssetItem.currentPrice):0}}</span>
                      </td>
                      <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.COUNT * AssetItem.PRICE, 2))}}</td>
                    </tr>
                  </tbody>
                </table>

                <div class="none" v-if="assetListData_C.length == 0">보유종목이 없습니다.</div>
              </perfect-scrollbar>
              <!-- // 내용 end -->

            </div>
            <!-- //코인거래 끝 -->

            <!-- 코인선물 시작 -->
            <div class="trade-result-box" v-if="isApply_G">
              <div class="result-box-top">
                <h3>코인선물</h3>

                <div class="box-top-info" :class="Constant.UTIL.getUpdownColor(profitPer_G)">
                  <div class="info">
                    <span class="txt">총보유자산</span>
                    <span class="num">
                      {{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(totalBalance_G, 2))}}
                      <span class="unit">USDT</span>
                    </span>
                  </div>
                  <div class="info">
                    <span class="txt">수익률</span>
                    <span class="per"><i></i>{{Constant.UTIL.numberWithCommas(Math.abs(Constant.UTIL.toFixedNum(profitPer_G, 2))) + ' %'}}</span>
                  </div>
                </div>
              </div>

              <!-- 상단 시작  -->
              <div class="trade-result-summary">
                <table class="table">
                  <colgroup>
                    <col style="width:12%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:12%">
                    <col style="width:14%">
                    <col style="width:12%">
                  </colgroup>
                  <tr>
                    <th>계약</th>
                    <th>수량</th>
                    <th>주문금액(USDT)</th>
                    <th>진입가(USDT)</th>
                    <th>강제 청산 가격(USDT)</th>
                    <th>포지션 증거금(USDT)</th>
                    <th>미실현 손익(%)</th>
                    <th>실현손익(USDT)</th>
                  </tr>
                </table>
              </div>
              <!-- //상단 종료 -->

              <!-- 내용 start -->              
              <perfect-scrollbar
                @ps-scroll-y="onScroll" 
                :options="options"
                :watch-options="watcher"
                ref="scrollbar"
                class="trade-result-tbl cf"                          
              >
                <table class="table">
                  <colgroup>
                    <col style="width:12%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:10%">
                    <col style="width:12%">
                    <col style="width:14%">
                    <col style="width:12%">
                  </colgroup>
                  <tbody>
                    <tr
                        v-for="AssetItem  in assetListData_G"
                        :key = AssetItem.ID 
                    >
                      <td>
                        <span style="cursor: pointer"  :class="getLongShortColor(AssetItem.TRADETYPE)"  @click="onClickedItemName(AssetItem)">
                          <span>{{AssetItem.NAME}}</span><br>
                          <strong>Isolated {{ AssetItem.LEVERAGE?parseInt(AssetItem.LEVERAGE):'1'}}x</strong>
                        </span>
                      </td>
                      <td>{{Constant.UTIL.numberWithCommas(Number(AssetItem.COUNT))}}</td>
                      <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(Number(AssetItem.PRICE)*Number(AssetItem.COUNT), 4))}}</td>
                      <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.PRICE, getNdotpos_G(AssetItem.CODE)))}}</td>
                      <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.LQPRICE, getNdotpos_G(AssetItem.CODE)))}}</td>
                      <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.ASSET_MARGIN, 2))}}</td>
                      <td :class="getCssColor(AssetItem._unrealizedPL)">
                        <span class="unrealized">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem._unrealizedPL, 2))}}</span><br>
                        <span class="unrealized">({{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem._unrealizedPLPer, 2))+ '%'}})</span><br>
                        <!-- <span>= 162,222,555</span> -->
                      </td>
                      <td :class="getCssColor(Number(AssetItem.TPOL))">
                        <span class="today-price">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.TPOL, 2))}}</span>
                    </td>
                    </tr>
                  </tbody>
                </table>

                <div class="none" v-if="assetListData_G.length == 0">보유종목이 없습니다.</div>
              </perfect-scrollbar>
              <!-- // 내용 end -->

            </div>
            <!-- //코인선물 끝 -->
          </div>          

        </div>
        <!-- } Contents End -->
      </div>
      <!-- } Container End -->
    <Footer/>
</template>

<script>
import Constant from '@/constant.js';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import axios from '@/axios';
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import { useStore } from 'vuex';
import { onMounted, computed, ref, onUnmounted, watch, getCurrentInstance } from 'vue';
import { useScoket } from '@/socket';
import {useRouter }from 'vue-router';
import {useApicall} from '@/composables/commonFn';
export default {
 components:{   
        HeaderTrade,
        Footer,
        PerfectScrollbar
    },

  setup(){
      const store = useStore();
      store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_0');
      store.commit('common/CHANGE_CURRENT_PAGE', 'TradeResult');
      const router = useRouter();
      const applySpaceList = computed(()=> store.state.hts.applySpaceList);
      const currencyInfo = computed(()=> store.state.hts.currencyInfo);
      const realSpaceList = ref([])
      const allTrade = computed(()=> store.state.hts.allTrade);
      const isApply_S = ref(false)
      const isApply_F = ref(false)
      const isApply_C = ref(false)
      const isApply_G = ref(false)
      const assetListData_S =ref([]);
      const assetListData_F =ref([]);
      const assetListData_C =ref([]);
      const assetListData_G =ref([]);
      const {socket} = useScoket();
      const {apiCall_gt} = useApicall();
      var currentBalance_S=null;
      var currentBalance_F=null;
      var currentBalance_C=null;
      var currentBalance_G=null;
      
      var profitData_S = null;
      var profitData_F = null;
      var profitData_C = null;
      var profitData_G = null;

      var intervalId; 

      

      var totalBalance_S  = ref(0);
      var totalBalance_F  = ref(0);
      var totalBalance_C  = ref(0);
      var totalBalance_G  = ref(0);
      

      var profitPer_S  = ref(0);
      var profitPer_F  = ref(0);
      var profitPer_C  = ref(0);
      var profitPer_G  = ref(0);

      const internalInstance = getCurrentInstance(); 
      const emitter = internalInstance.appContext.config.globalProperties.emitter;
      const  getCurrentBalance = async (spaceId)=>{       
         var token = sessionStorage.getItem('token');  
        try{            
            const res = await axios.get(`vspace/getaccountbyspaceid?spaceId=${spaceId}`,{
                headers: {
                    'token': token
                }
            } );
            let json =  res.data;              
            if (json.returnCode == 1) { 
                if (json.resultMap.returnVal == 1) {     
                    // console.log("getCurrentBalance:", json.resultMap.data.BALANCE, json.resultMap.data.PAYMONEY)               
                     return json.resultMap.data;                     
                } else { 
                     return false
                                
                }
            }           
            else
            {              
                console.log("error", json.message);    
                return false           
            }  
        }catch(error){     
            console.log(error);  
            return false;           
        }  
      }
      const getProfitlist = async(_type, _spaceId)=>{            
          var res = await apiCall_gt(`vspace/getprofitlistbyday?type=${_type}&spaceId=${_spaceId}`);
          if(!res)return
          if(!res.dataSum)return null;


          return res.dataSum;
      }

      const getAssetList = async (spaceId) => { 
            var result = []; 
            var token = sessionStorage.getItem('token');
            try{
                const  res = await axios.get(`vspace/getassetlist?spaceId=${spaceId}`, {
                    headers: {
                        'token': token
                   }
                });   
                let json =  res.data;   
                if (json.returnCode == 1) {
                    if (json.resultMap.returnVal == 1) { 
                        if(!json.resultMap.dataList) 
                        {
                          result = [];
                        }
                        else
                        {
                          result = json.resultMap.dataList
                        }
                         //store.commit('hts/CHANGE_ASSETLIST', json.resultMap.dataList);
                         //store.commit('hts/CHANGE_TOTAL_POL', json.resultMap.dataSum);
                        return result 

                     //  requesAssetItems()
                    } else {
                        // store.commit('hts/CHANGE_ASSETLIST', []);    
                        return result   
                    }
                }
                else if(json.returnCode == -102 )
                {
                    console.log("로그인이 필요합니다.");
                    return result 
                }
                else
                {               
                  console.log(json.message);
                  return result 
                }               
            }catch(error){             
                console.log(error);
                return result 
            }  
        } 
      onUnmounted(()=>{
          clearInterval(intervalId);
          if(assetListData_S.value) leaveAssetItems(assetListData_S.value)
          if(assetListData_F.value) leaveAssetItems(assetListData_F.value)
          if(assetListData_C.value) leaveAssetItems(assetListData_C.value)
          if(assetListData_G.value) leaveAssetItems(assetListData_G.value)
      })

      onMounted(()=>{
        emitter.emit('EVENT_CHECK_LOGIN');    
        getListCount();
        store.dispatch('hts/setApplySpace'); 


        setTimeout(async ()=>{
           applySpaceList.value.forEach(element => {
          
            if(element.SPACE_STATUS == '1')
            {        
              //console.log("applySpaceList",element )
              if(element.APPLY_TYPE == 'A')
              {
                  realSpaceList.value.push(element)
              }
              
            }     
          
          });

          var spaceType_S = realSpaceList.value.find(element => element.SPACE_TYPE ==  "S"); 
          var spaceType_F = realSpaceList.value.find(element => element.SPACE_TYPE ==  "F"); 
          var spaceType_C = realSpaceList.value.find(element => element.SPACE_TYPE ==  "C"); 
          var spaceType_G = realSpaceList.value.find(element => element.SPACE_TYPE ==  "G"); 
          
          if(spaceType_S)isApply_S.value = true;
          if(spaceType_F)isApply_F.value = true;
          if(spaceType_C)isApply_C.value = true;
          if(spaceType_G)isApply_G.value = true;


          if(spaceType_S)assetListData_S.value = await getAssetList(spaceType_S.SPACE_ID)
          if(spaceType_F)assetListData_F.value = await getAssetList(spaceType_F.SPACE_ID)
          if(spaceType_C)assetListData_C.value = await getAssetList(spaceType_C.SPACE_ID)
          if(spaceType_G)assetListData_G.value = await getAssetList(spaceType_G.SPACE_ID)
          
          if(spaceType_S)currentBalance_S = await getCurrentBalance(spaceType_S.SPACE_ID);
          if(spaceType_F)currentBalance_F = await getCurrentBalance(spaceType_F.SPACE_ID);
          if(spaceType_C)currentBalance_C = await getCurrentBalance(spaceType_C.SPACE_ID);
          if(spaceType_G)currentBalance_G = await getCurrentBalance(spaceType_G.SPACE_ID);


          if(spaceType_S)profitData_S = await getProfitlist('S', spaceType_S.SPACE_ID);
          if(spaceType_F)profitData_F = await getProfitlist('F',spaceType_F.SPACE_ID);
          if(spaceType_C)profitData_C = await getProfitlist('C',spaceType_C.SPACE_ID);
          if(spaceType_G)profitData_G = await getProfitlist('G',spaceType_G.SPACE_ID);

          if(assetListData_S.value) requesAssetItems(assetListData_S.value)
          if(assetListData_F.value) requesAssetItems(assetListData_F.value)
          if(assetListData_C.value) requesAssetItems(assetListData_C.value)
          if(assetListData_G.value) requesAssetItems(assetListData_G.value)
        },1000)    

          clearInterval(intervalId);
          intervalId = setInterval(()=>{

              if(assetListData_S.value)
              {
                 setTotalProfit(assetListData_S.value, 'S')    
              }

              if(assetListData_F.value)
              {
                 setTotalProfit(assetListData_F.value, 'F')
              }

              if(assetListData_C.value)
              {
                 setTotalProfit(assetListData_C.value, 'C')
              }

              if(assetListData_G.value)
              {
                 setTotalProfit_G(assetListData_G.value)
              }                
          },1000)       

      })


      const setTotalProfit_G = (assetData) =>{
          var totalProfit = 0;              
          for(var i=0; i<assetData.length ; i++)
          {
              var data = assetData[i]
              if(data)
              {
                  totalProfit += Number(data._unrealizedPL);                         
              }  
          } 
          if(isNaN(totalProfit)) return;
          if(currentBalance_G && profitData_G)
          {
              totalBalance_G.value = totalProfit +  Number(currentBalance_G.BALANCE); 
              let profit = Number(profitData_G.TPOL)+totalProfit
              profitPer_G.value = (profit/Number(currentBalance_G.PAYMONEY))*100;    
          }
      }

      const setTotalProfit = (assetData,  _type)=>{
          var totalProfit = 0;
          var totalPrice = 0;
          for(var i=0; i<assetData.length ; i++)
          {
              var data = assetData[i]
              if(data)
              {
                  totalProfit += Number(data.nProfit) *Number(data.COUNT);
                  totalPrice += (Number(data.PRICE) + Number(data.nProfit))  *Number(data.COUNT);                           
              }  
          }
          //console.log("currentBalance_S:", currentBalance_S)
          if(isNaN(totalProfit) || isNaN(totalPrice)) return
          if(_type == 'S' && currentBalance_S && profitData_S)
          {                
            totalBalance_S.value = totalPrice +  Number(currentBalance_S.BALANCE);    
            let profit = Number(profitData_S.TPOL)+totalProfit
            profitPer_S.value = (profit/Number(currentBalance_S.PAYMONEY))*100; 
          }
          else if(_type == 'F' && currentBalance_F && profitData_F)
          {
                    
            totalBalance_F.value = totalProfit +  Number(currentBalance_F.BALANCE);  
            let profit = Number(profitData_F.TPOL)+totalProfit
            profitPer_F.value = (profit/Number(currentBalance_F.PAYMONEY))*100;    
           
          }
          else if(_type == 'C' && currentBalance_C && profitData_C)
          {     
            totalBalance_C.value = totalPrice +  Number(currentBalance_C.BALANCE);
            let profit = Number(profitData_C.TPOL)+totalProfit
            profitPer_C.value = (profit/Number(currentBalance_C.PAYMONEY))*100;    
          
          }
      }


      watch(allTrade, ()=>{              
          var  tradeItem = allTrade.value;   
          if(tradeItem.itemType != 'G')return;

          const found_long =  assetListData_G.value.find(element => element.CODE ==  tradeItem.code  && element.TRADETYPE == '3'); 
          const found_short = assetListData_G.value.find(element => element.CODE ==  tradeItem.code  && element.TRADETYPE == '4'); 
          var unrealizedPL; 
          var unrealizedPLPer; 
          var realizedPL; 
          var feeVal;

          if(found_long)
          {
                feeVal = Number(found_long.PRICE) *  Number(found_long.COUNT) * (Number(found_long.FEES)/100);
                unrealizedPL = (Number(tradeItem.price) - Number(found_long.PRICE)) * Number(found_long.COUNT);  
                unrealizedPLPer = unrealizedPL / (Number(found_long.ASSET_MARGIN) + feeVal) * 100;
               // realizedPL =   unrealizedPL - feeVal; 
                realizedPL =   -feeVal; 
               // console.log("unrealizedPL:", unrealizedPL, feeVal)
                found_long._unrealizedPLPer = unrealizedPLPer;
                found_long._unrealizedPL = unrealizedPL;
                found_long._realizedPL = realizedPL;

                found_long.COLOR_CLASS = getCssColor(realizedPL);       

          }

          if(found_short)
          {
                feeVal = Number(found_short.PRICE) *  Number(found_short.COUNT) * (Number(found_short.FEES)/100);
                unrealizedPL = (Number(found_short.PRICE) - Number(tradeItem.price)) * Number(found_short.COUNT);  
                unrealizedPLPer = unrealizedPL / (Number(found_short.ASSET_MARGIN) + feeVal) * 100;
                //realizedPL =   unrealizedPL - feeVal;       
                realizedPL =  -feeVal; 
                found_short._unrealizedPLPer = unrealizedPLPer;
                found_short._unrealizedPL = unrealizedPL;
                found_short._realizedPL = realizedPL;

                found_short.COLOR_CLASS = getCssColor(realizedPL);              
          }
      })


      watch(allTrade,()=>{          
        var  tradeItem = allTrade.value;   
        if(tradeItem.itemType == 'G')return;
        var  found =  null;        
        
        
        found = assetListData_S.value.find(element => element.CODE ==  tradeItem.code);  
        if(!found) found = assetListData_F.value.find(element => element.CODE ==  tradeItem.code);           
        if(!found) found = assetListData_C.value.find(element => element.CODE ==  tradeItem.code);           
       // if(!found) found = assetListData_G.value.find(element => element.CODE ==  tradeItem.code);           
        if(!found)return;        
        var profit; 
        //console.log("found :", found)
        if(tradeItem.itemType == 'F')
        {

              var items =  JSON.parse(sessionStorage.getItem('itemList_F'))
              if(items)
              {
                  var t_item =  items.find(element => element.CODE ==  found.CODE); 
              }
              
              if(t_item)
              {
                  if(t_item.MINCHANGEAMOUNT)
                  {
                     
                      if(t_item.PRODUCTCODE == 'HSI')
                      {
                          profit = (Number(tradeItem.trade_price) - Number(found.PRICE)) / Number(t_item.HOGAUNITPRICE) * Number(t_item.MINCHANGEAMOUNT) * Number(currencyInfo.value.HKD_KRW) * Number(found.COUNT);
                          found.nProfit = (Number(tradeItem.trade_price) - Number(found.PRICE)) / Number(t_item.HOGAUNITPRICE) * Number(t_item.MINCHANGEAMOUNT) * Number(currencyInfo.value.HKD_KRW);                        
                      }
                      else
                      {
                          profit = (Number(tradeItem.trade_price) - Number(found.PRICE)) / Number(t_item.HOGAUNITPRICE) * Number(t_item.MINCHANGEAMOUNT) * Number(currencyInfo.value.USD_KRW) * Number(found.COUNT);
                          found.nProfit = (Number(tradeItem.trade_price) - Number(found.PRICE)) / Number(t_item.HOGAUNITPRICE) * Number(t_item.MINCHANGEAMOUNT) * Number(currencyInfo.value.USD_KRW);  
                                               
                      }
                  }
                  else
                  {
                      if(t_item.SUBTYPE == 'K')
                      { 
                          profit = ((Number(tradeItem.trade_price) - Number(found.PRICE)) *Number(found.COUNT))*250000; 
                          found.nProfit = ((Number(tradeItem.trade_price) - Number(found.PRICE)))*250000;  
                      }
                  } 
                  found.currentPrice = tradeItem.trade_price;
                  found.drate = Number(tradeItem.drate);
              } 
              if(found.TRADETYPE == '4')
              {
                  found.nProfit *= -1;
                  profit *= -1;
              }                 
              profit = Math.floor(profit)   

            
          }
          else
          {
            profit = (Number(tradeItem.trade_price) - Number(found.PRICE)) *Number(found.COUNT);  
            found.nProfit = (Number(tradeItem.trade_price) - Number(found.PRICE)); 
            let profitPre = (found.nProfit /  Number(found.PRICE)) *100;
            found.profitPre = profitPre;
            found.currentPrice = tradeItem.trade_price;
            if(tradeItem.itemType == 'C')
            {
                found.drate = Number(tradeItem.trade_price) -  Number(tradeItem.prev_closing_price);
            }
            else
            {
              found.drate = Number(tradeItem.drate);
            }          
            profit = Math.floor(profit);  
          }

          

          if(profit > 0)
          {
              found.COLOR_CLASS = "up"
              found.PROFIT = '+' + Constant.UTIL.numberWithCommas(profit);
              
          }
          else if(profit < 0)
          {
            found.COLOR_CLASS = "down"
            found.PROFIT =  Constant.UTIL.numberWithCommas(profit);
          }
          else
          {
            found.COLOR_CLASS = ""
            found.PROFIT = '0'
          } 

      })


      const leaveAssetItems =  (listData) => {   
          for(let i=0; i<listData.length; i++)
          {
              var item = listData[i];      
              socket.emit("leaveTrade", {
                  itemType: item.type,
                  code: item.CODE,                   
              }, function() {                        
              })
          }
      }

      const requesAssetItems =  (listData) => {   
          console.log("requesAssetItems:", listData)
          var assetInitArr = []
          setTimeout(()=>{
              for(let i=0; i<listData.length; i++)
              {
                  var item = listData[i];         
                        
                  var assetInitData = 
                  { 
                    "drate" :  "0" , 
                    "trade_price" : Number(item.CURRENT),
                    "code": item.CODE,
                    "price": Number(item.CURRENT)
                  }



                  if(item.KIND == "S" || item.KIND == "C" || item.KIND == "G" )
                  {
                    item.type = item.KIND;
                    assetInitData.itemType = item.KIND;
                  }
                  else
                  {
                    item.type = 'F';
                    assetInitData.itemType = 'F';                           
                  }
                  
                  assetInitArr.push(assetInitData);     
                  socket.emit("requestTrade", {
                      itemType:item.type,
                      code: item.CODE,
                      // isNight:isNight  
                  }, function() {       
                      // console.log("connect socket requestTrade~111",state.currentTradeType, item.CODE ) 
                  })
              }

              for(let ii=0; ii<assetInitArr.length; ii++)
              {
                  //  console.log("aaaaaa~", assetInitArr.shift() ) 
                  setTimeout(()=>{
                      store.commit('hts/CHANGE_ALL_TEADE', assetInitArr.shift()) 
                  }, ii * 200)                    
              }
          },1500)
      }    

      //리스트 갯수별 클래스 적용
      const getListCount = () => {
        var resultArea = document.getElementById('resultArea');
        var resultList = document.querySelectorAll('.trade-result-box');
        var resultListCount = resultList.length;
        
        resultArea.classList.remove();
        if( resultListCount == 3 ) {
          resultArea.classList.add('tbl-3');
        } else if( resultListCount == 2 ) {
          resultArea.classList.add('tbl-2');
        } else if ( resultListCount == 1 ) {
          resultArea.classList.add('tbl-1');
        } else {
          return false;
        }
      }
      const getTradeType = (type) =>{
          switch(type)
          {
              case '1':
                  return "매수"                        
              case '2':
                  return "매도"                       
              case '3':
                  return "매수"                        
              case '4':
                  return "매도"
          }
      }



     const  onClickedItemName = (item) =>{
          // console.log("item:", item)
            var name = null;
            var itemData = null;
            if(item.type == "S")
            {
              name = 'VtradeStock';
              let items =  JSON.parse(sessionStorage.getItem('itemList_S'))
              //console.log("items:", items.length)
              itemData = items.find(element => element.CODE ==  item.CODE); 
              sessionStorage.setItem('startItem_S', JSON.stringify(itemData));
            }
            else if(item.type == "F")
            {
              name = 'VtradeFutures';
              let items =  JSON.parse(sessionStorage.getItem('itemList_F'))
              itemData = items.find(element => element.CODE ==  item.CODE); 
              sessionStorage.setItem('startItem_F', JSON.stringify(itemData));
            }
            else if(item.type == "C")
            {
              name = 'VtradeCoin';
             let items =  JSON.parse(sessionStorage.getItem('itemList_C'))
              itemData = items.find(element => element.CODE ==  item.CODE); 
              sessionStorage.setItem('startItem_C', JSON.stringify(itemData));
            }
            else if(item.type == "G")
            {
              name = 'VtradeCoinFutures';
              let items =  JSON.parse(sessionStorage.getItem('itemList_G'))
              itemData = items.find(element => element.CODE ==  item.CODE); 
              sessionStorage.setItem('startItem_G', JSON.stringify(itemData));
            }
          
            router.push({
              name: name,    
              params: {           
               id:'0'
              }
            })
     }

    const getLongShortColor=(_tradeType)=>{ 
      var result = '' 
      if(_tradeType == '3' || _tradeType == '5')
      {
            result = 'long'
      }
      else if(_tradeType == '4' || _tradeType == '6')
      {
           result = 'short'
      }
       
      return result;
     }

      const getNdotpos_G = (_code) =>{
        var result = 0
        var items_G =  JSON.parse(sessionStorage.getItem('itemList_G'))
        if(items_G && items_G.length > 0)
        {
            var found = items_G.find(element => element.CODE ==  _code); 
            if(found && found.NDOTPOS )
            {
              result = parseInt(found.NDOTPOS);
            }
        }
        // console.log("getNdotpos ", _code , result, store.state.hts.currentItemDatas)
        return result;
      }

       const getCssColor = (num) => {
          var result = ''
          if(num > 0)
          {
              result = "up" 
          }
          else if(num < 0)
          {
              result = "down"         
          }
          else
          {
              result = ""      
          } 
          return result;
      }

     return {
        options: {       
              wheelSpeed: 1,
              wheelPropagation: true,
              minScrollbarLength: 20,
              suppressScrollX: true,
        },
        watcher: true, 
        assetListData_S,
        assetListData_F,
        assetListData_C,
        assetListData_G,
        getTradeType,
        Constant,
        onClickedItemName,

        isApply_S,
        isApply_F,
        isApply_C,
        isApply_G,
        totalBalance_S,
        totalBalance_F,
        totalBalance_C,
        totalBalance_G,
        profitPer_S,
        profitPer_F,
        profitPer_C,
        profitPer_G,

        getLongShortColor,
        getNdotpos_G,
        getCssColor,
        realSpaceList

     }
  }
}
</script>

<style>

</style>