<template>
  <!-- <div class="toast-box" style="z-index:20">
     <transition-group name="slide">
        <div
          v-for="toast in toasts"
          :key="toast.id"
          class="alert"
          :class="`alert-${toast.type}`"
          role="alert"
        >
          {{toast.message}}         
        </div>
     </transition-group>
  </div> -->

  <div class="toast-box order">
     <transition-group name="slide">
        <div class="order-alarm"
           v-for="toast in toasts"
          :key="toast.id"
        >
          <!-- class > red : 매수/롱포지션, blue : 매도/숏포지션, force : 강제청산 -->
          <div class="alarm-order" :class="getOrderColor(toast.tradeData.type ,toast.tradeData.tradeType)">{{getOrderStr(toast.tradeData.type ,toast.tradeData.tradeType)}}</div>
          <!-- <div class="alarm-order blue">매도</div> -->
          <!-- <div class="alarm-order red">롱<br>진입</div> -->
          <!-- <div class="alarm-order blue">숏<br>진입</div> -->
          <!-- <div class="alarm-order red">롱<br>정리</div> -->
          <!-- <div class="alarm-order blue">숏<br>정리</div> -->
          <!-- <div class="alarm-order force">강제<br>청산</div> -->

          <div class="alarm-contents">
            <p class="item"><span>{{getTradeTypeStr(toast.tradeData.type)}}거래 : </span>{{toast.tradeData.name}}</p>
            <span class="price">
              체결가
              <span class="num">{{Constant.UTIL.numberWithCommas(Number(toast.tradeData.tradeIndex))}}</span>
            </span>
            /
            <span class="quantity">
              체결수량
              <span class="num">{{Constant.UTIL.numberWithCommas(Number(toast.tradeData.tradeCount).toFixed(toast.tradeData.type=='C'?4:toast.tradeData.type=='G'?2:0))}}</span>
            </span>
          </div>
          <div class="alarm-ico"><img src="@/assets/images/trade/ico_alarm.png" alt="alarm"></div>
        </div>
     </transition-group>
  </div>
</template>

<script>
import { useToast } from '@/composables/toast';
import Constant from '@/constant.js';
export default {  
  setup(){
    const { toasts  } = useToast();
    // setInterval(()=>{
    //  console.log("toasts:~~", toasts.value.length, toasts.value[0])
    // },1000)

    const getTradeTypeStr = (type) =>{
      var result = null;
      if(type == 'S') result = '주식';
      else if(type == 'F') result = '선물';
      else if(type == 'C') result = '코인';
      else if(type == 'G') result = '코인선물';

      return result;
    }

     const getOrderStr = (_tType, type) =>{
      var result = [];
      if(_tType == 'G')
      {
          if(type == '3')
          {
            result = '롱 진입';
          }
          else if(type == '4' )
          {
            result = '숏 진입';
          }
          else if(type == '5' )
          {
            result = '롱 정리';
          }
          else if(type == '6' )
          {
            result = '숏 정리';
          }
      }
      else
      {
        if(type == '1' || type == '3')
        {
          result = '매수';
        }
        else if(type == '2' || type == '4' )
        {     
          result = '매도';
        }
      }

      return result;

     }

    const getOrderColor = (_tType, type) =>{
      var result = null;
      if(_tType == 'G')
      {
         if(type == '3' || type == '5')
         {
            result = 'red';
         }
         else  if(type == '4' || type == '6')
         {
            result = 'blue';
         }
      }
      else
      {
        if(type == '1' || type == '3')
        {
          result = 'red';
        }
        else if(type == '2' || type == '4' )
        {     
          result = 'blue';
        }
      }
      return result;
    }
   
    return {
      toasts,
      getOrderColor,
      getOrderStr,
      Constant,
      getTradeTypeStr
    }
  }
}
</script>

