<template>
            <!-- 좌측 : 종목 조회 start -->
            <div class="trade-search-area" id="tradeSearchArea">
              <table class="trade-search">
                <tr>
                  <td colspan="2" class="search-input">
                    <div class="input">
                      <input 
                                type="text" 
                                class="form-control"
                                placeholder="코인명/심볼 검색"
                                v-model="searchText"
                                @keyup="onKeyup"
                                @focus="onFocus"
                      >
                      <button v-if="!searchText"    class="btn-search" type="button"><img src="@/assets/images/common/ico_header_search.png" alt="search"></button>   
                      <button v-else class="btn-cancel" type="button" ><img src="@/assets/images/common/ico_close.png" alt="cancel"   @click="onSearchDel"></button>                
                    </div>
                  </td>
                </tr>
              </table>

              <div class="trade-search-result">
                <div class="trade-search-tab">
                  <!-- tab button -->
                  <ul class="nav nav-tabs nav-fill" id="tradeSearchTabButton" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="trade-search-won-tab" data-bs-toggle="tab" data-bs-target="#tradeSearchWonTab" type="button" role="tab" aria-controls="tradeSearchWonTab" aria-selected="true" @click="onTapClicked('normal')">
                        원화마켓
                      </button>
                    </li>
           
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="trade-search-interest-tab" data-bs-toggle="tab" data-bs-target="#tradeSearchInterestTab" type="button" role="tab" aria-controls="tradeSearchInterestTab" aria-selected="false" @click="onTapClicked('favor')">
                        관심코인
                      </button>
                    </li>
                  </ul>
    
                  <!-- tab contents -->
                  <div class="tab-content" id="tradeSearchTabContents">
    
                    <!-- 원화마켓 start -->
                    <div class="tab-pane fade show active" id="tradeSearchWonTab" role="tabpanel" aria-labelledby="trade-search-won-tab">
                      <!-- <div class="trade-search-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div> -->

                      <!-- 원화마켓 상단 시작  -->
                      <div class="trade-search-summary">
                        <table class="table">
                          <colgroup>
                            <col style="width:40px">
                            <col style="width:100px">
                            <col style="width:80px">
                            <col style="width:75px">
                            <col style="width:95px">
                          </colgroup>
                          <tr>
                            <th colspan="2">
                              <button type="button" class="btn" @click="onChangeName">
                                {{itemNameType}}
                                <span class="ico"><img src="@/assets/images/trade/search_change.png" alt="한글명"></span>
                              </button>
                            </th>
                            <th>
                              <button type="button" class="btn btn-sort-arrow" @click="onListSort(1)">
                                현재가
                                <span class="ico"><i class="sort"></i></span>
                              </button>
                            </th>
                            <th>
                              <button type="button" class="btn btn-sort-arrow" @click="onListSort(2)">
                                전일대비
                                <span class="ico"><i class="sort"></i></span>
                              </button>
                            </th>
                            <th>
                              <button type="button" class="btn btn-sort-arrow" @click="onListSort(3)">
                                거래대금
                                <span class="ico"><i class="sort"></i></span>
                              </button>
                            </th>
                          </tr>
                        </table>
                      </div>
                      <!-- //원화마켓 상단 종료 -->

                      <!-- 원화마켓 내용 start -->
                      <!-- <div class="trade-search-won" id="tradeSearchWon"> -->
                      <div v-if="!filteredItems.length" class="trade-search-null">
                            검색된 아이템이 없습니다.
                      </div>
                       <perfect-scrollbar 
                        @ps-scroll-y="onScroll" 
                        :options="options"
                        :watch-options="watcher"
                        ref="scrollbar"
                        class="trade-search-won" id="tradeSearchWon"
                      >

                        <table class="table">
                          <colgroup>
                            <col style="width:30px">
                            <col style="width:110px">
                            <col style="width:80px">
                            <col style="width:70px">
                            <col style="width:98px">
                          </colgroup>
                          <tbody>

                            <tr 
                               v-for="(item, index) in filteredItems"                     
                               :key = index   
                               :class="item._dGapPrice?Constant.UTIL.getUpdownColor(item._dGapPrice):''"
                                                      
                            >
                              <td>
                                <button  @click="onFavorClick(item, $event)" type="button" class="btn btn-favor " :class="item._isFavor?'on':''">관심종목</button>
                                <a   @click="onItemClick(item, $event)"    href="javascript:;" class="searchSelectBorded"  :class="currentItem.CODE == item.CODE?'selected':''"></a>
                              </td>
                              <td>
                                <span class="name">{{itemNameType=='한글명'?item.KORNAME:item.NAME}}</span>
                                <span class="krw">{{item.CODE?item.CODE.replace('-','/'):''}}</span>
                              </td>
                              <!-- <td class="coin-current-price" >
                                <span class="c-price">
                                <span class="present">{{item._currentPrice?Constant.UTIL.numberWithCommas(Number(item._currentPrice).toFixed(parseInt(item.NDOTPOS))):'0'}}</span>
                                </span>
                              </td> -->
                              <td>
                                <span class="present">{{item._currentPrice?Constant.UTIL.numberWithCommas(Number(item._currentPrice).toFixed(getNdotposCoin(item._currentPrice))):'0'}}</span>
                              </td>
                              <td>
                                <span class="per">{{item._dGapPrice? Constant.UTIL.getPlusMinus(item._dGapPrice) + Constant.UTIL.numberWithCommas(Math.abs(item._drate).toFixed(2)) + '%':'0%'}}</span>
                                <span class="num">{{ item._dGapPrice? Constant.UTIL.getPlusMinus(item._dGapPrice)  +    Constant.UTIL.numberWithCommas(Math.abs(item._dGapPrice).toFixed(getNdotposCoin(item._currentPrice))):'0'}}</span>
                              </td>
                              <td>
                                <span class="price">{{item._volumePrice?Constant.UTIL.numberWithCommas(item._volumePrice):'0'}}</span>
                                <span class="unit">백만</span>
                              </td>
                            </tr>
                           
                          </tbody>
                        </table>
                      </perfect-scrollbar>
                      <!-- </div> -->
                      <!-- //원화마켓 내용 end -->
                      
                    </div>
                    <!-- //원화마켓 end -->
    
                   
    
                    <!-- 관심코인 start -->
                    <div class="tab-pane fade" id="tradeSearchInterestTab" role="tabpanel" aria-labelledby="trade-search-interest-tab">
                      <div class="trade-search-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                      <!-- 관심코인 상단 시작  -->
                      <div class="trade-search-summary">
                        <table class="table">
                          <colgroup>
                            <col style="width:40px">
                            <col style="width:100px">
                            <col style="width:80px">
                            <col style="width:75px">
                            <col style="width:95px">
                          </colgroup>
                          <tr>
                            <th colspan="2">
                              <button type="button" class="btn" @click="onChangeName">
                                 {{itemNameType}}
                                <span class="ico"><img src="@/assets/images/trade/search_change.png" alt="한글명"></span>
                              </button>
                            </th>
                            <th>
                              <button type="button" class="btn btn-sort-arrow"  @click="onListSort_favor(1)">
                                현재가
                                <span class="ico"><i class="sort"></i></span>
                              </button>
                            </th>
                            <th>
                              <button type="button" class="btn btn-sort-arrow"  @click="onListSort_favor(2)">
                                전일대비
                                <span class="ico"><i class="sort"></i></span>
                              </button>
                            </th>
                            <th>
                              <button type="button" class="btn btn-sort-arrow"  @click="onListSort_favor(3)">
                                거래대금
                                <span class="ico"><i class="sort"></i></span>
                              </button>
                            </th>
                          </tr>
                        </table>
                      </div>
                      <!-- //관심코인 상단 종료 -->

                      <!-- 관심코인 내용 start -->
                      <!-- <div class="trade-search-interest" id="tradeSearchInterest"> -->
                      <div v-if="!favorItemList.length">
                        관심종목이 없습니다.
                      </div>

                      <div v-else-if="!filteredItems_favor.length">
                        검색된 아이템이 없습니다.
                      </div>

                      
                       <perfect-scrollbar 
                        @ps-scroll-y="onScroll" 
                        :options="options"
                        :watch-options="watcher"
                        ref="scrollbar"
                        class="trade-search-interest" id="tradeSearchInterest"
                      >

                        <table class="table">
                          <colgroup>
                            <col style="width:40px">
                            <col style="width:100px">
                            <col style="width:80px">
                            <col style="width:75px">
                            <col style="width:95px">
                          </colgroup>
                          <tbody>
                            <tr 
                               v-for="(item, index) in filteredItems_favor"                     
                               :key = index   
                               :class="item._dGapPrice?Constant.UTIL.getUpdownColor(item._dGapPrice):''"
                                                      
                            >
                              <td>
                                 <button  @click="onFavorClickInFavor(item)" type="button" class="btn btn-favor " :class="item._isFavor?'on':''">관심종목</button>
                                <a   @click="onItemClick(item, $event)"    href="javascript:;" class="searchSelectBorded"  :class="currentItem.CODE == item.CODE?'selected':''"></a>
                              </td>
                              <td>
                                <span class="name">{{itemNameType=='한글명'?item.KORNAME:item.NAME}}</span>
                                <span class="krw">{{item.CODE?item.CODE.replace('-','/'):''}}</span>
                              </td>
                              <td class="coin-current-price">
                                <span class="c-price">
                               

                                <span class="present">{{item._currentPrice?Constant.UTIL.numberWithCommas(Number(item._currentPrice).toFixed(getNdotposCoin(item._currentPrice))):'0'}}</span>
                                </span>
                              </td>
                              <!-- <td>
                                <span class="present">{{item._currentPrice?Constant.UTIL.numberWithCommas(item._currentPrice):'0'}}</span>
                              </td> -->
                              <td>
                                <span class="per">{{item._dGapPrice? Constant.UTIL.getPlusMinus(item._dGapPrice) + Constant.UTIL.numberWithCommas(Math.abs(item._drate).toFixed(2)) + '%':'0%'}}</span>
                                <span class="num">{{ item._dGapPrice? Constant.UTIL.getPlusMinus(item._dGapPrice)  +    Constant.UTIL.numberWithCommas(Math.abs(item._dGapPrice).toFixed(getNdotposCoin(item._currentPrice))):'0'}}</span>
                              </td>
                              <td>
                                <span class="price">{{item._volumePrice?Constant.UTIL.numberWithCommas(item._volumePrice):'0'}}</span>
                                <span class="unit">백만</span>
                              </td>
                            </tr>
                            
                          </tbody>
                        </table>
                      </perfect-scrollbar> 
                      <!-- </div> -->
                      <!-- //관심코인 end -->

                    </div>
                    <!-- //관심코인 end -->
    
                  </div>
                </div>
              </div>

            </div>
            <!-- //좌측 : 종목 조회 end -->
</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import Constant from '@/constant.js';
import {useApicall} from '@/composables/commonFn';
import { useScoket } from '@/socket';
import { useStore } from 'vuex';
import {ref, getCurrentInstance, onMounted, onUnmounted, computed, watchEffect} from 'vue';
import {useCommonFunction} from '@/composables/vtradeFn';
export default {
   components: {
        PerfectScrollbar
    },
   props:{
        items:{
            type:Array,
            required: true
        }
   }, 
  setup(props){
       // const  trace = getCurrentInstance().appContext.config.globalProperties.$log;

        const {     
                getNdotposCoin,
        } = useCommonFunction();

        // normal or favor        
        const currentMode = ref('normal');
       
        const {apiCall_pt, apiCall_gt} = useApicall();
        const currentItem = ref({})
        const { socket } = useScoket(); 
        const store = useStore();
        const isLogin = computed(() => store.state.user.isLogin);
        const { emit } = getCurrentInstance();
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
        const favorItemList = ref([]);
        const scrollbar = ref(null);
        const searchText = ref('');
        const filteredItems = computed(()=>{
          if(searchText.value){
              return props.items.filter(item => {
                  return  item.KORNAME.toLowerCase().includes(searchText.value.toLowerCase()) || item.NAME.toLowerCase().includes(searchText.value.toLowerCase()) || item.CODE.toLowerCase().includes(searchText.value.toLowerCase());
              })
          }
          return props.items;
        });


       const filteredItems_favor = computed(()=>{
          if(searchText.value){
              return favorItemList.value.filter(item => {
                  return  item.KORNAME.toLowerCase().includes(searchText.value.toLowerCase()) || item.NAME.toLowerCase().includes(searchText.value.toLowerCase()) || item.CODE.toLowerCase().includes(searchText.value.toLowerCase());
              })
          }
          return favorItemList.value;
        });



        
   
        const onItemClick = (item) =>{
            if(!isLogin.value)
            {
              emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다."});
              return;
            }
            currentItem.value = item;            
            emit('onSelectItem',item)
        }



        const onFavorClickInFavor = async(item) =>{
          if(await removeFavorList(item.CODE))
          {
             emitter.emit('EVENT_ALERT',{title:"알림", body:"관심종목에서 삭제되었습니다."});
             getFavorItemList();
              var found =  props.items.find(element => element.CODE == item.CODE);
              if(found)
              {                 
                found._isFavor = false;                   
              }

          }
        }


        const onFavorClick = async (item, e) =>{
          //trace("onFavorClick:", item,  e.target.classList.contains('on'))


          if(e.target.classList.contains('on'))
          {
              //관심종목 지움              
              if(await removeFavorList(item.CODE))
              {
                   
                   //e.target.classList.remove('on');
                   item._isFavor = false;
                   emitter.emit('EVENT_ALERT',{title:"알림", body:"관심종목에서 삭제되었습니다."});
              }
              else
              {
                   item._isFavor = true;
                  // e.target.classList.add('on');
              }

          }
          else
          {            
              //관심종목 추가
              if(!store.state.hts.currentAccountId)
              {
                emitter.emit('EVENT_ALERT',{title:"알림", body:"대회 참가후 사용가능합니다."});
                return;
              }
              if(await addFavorList(store.state.hts.currentAccountId, store.state.hts.currentTradeType, item.CODE))
              {
                  item._isFavor = true;
                  // e.target.classList.add('on');
                   emitter.emit('EVENT_ALERT',{title:"알림", body:"관심종목에 추가되었습니다."});
              }
              else
              {
                  item._isFavor = false;
                  // e.target.classList.remove('on');
              }
          }
          
         // e.target.classList.toggle('on');
         // e.target.classList.remove('on');
        }


        const periodInitData = computed(()=>store.state.hts.periodInitData);

        const addFavorList = async(_accountId, _tradeType, _code) =>{
          var params =   {
              "accountId": _accountId,
              "type": _tradeType,
              "code": _code
          }

          var res = await apiCall_pt(`vspace/favorateitemregister`, params, null, (message)=>{
             emitter.emit('EVENT_ALERT',{title:"알림", body:message});
          });
         // console.log("addFavorList: ", res)
          if(!res)return false 

          return true
        }

        const removeFavorList = async(_code) =>{
          var params =   {
              "code": _code,
              "accountId":store.state.hts.currentAccountId
          }

          var res = await apiCall_pt(`vspace/favorateitemdelete`, params, null, (message)=>{
            emitter.emit('EVENT_ALERT',{title:"알림", body:message});
          });
          if(!res)return  
          
          return true
        }

        

        const getFavorItemList = async() =>{
            var res = await apiCall_gt(`vspace/getfavorateitemlist?accountId=${store.state.hts.currentAccountId}&type=${store.state.hts.currentTradeType}`);
            if(!res) return false;
            favorItemList.value = res.favorateList;       
            if(!favorItemList.value) favorItemList.value = [];
            favorItemList.value.forEach(_element => { 
              _element._isFavor = true;
              var found =  props.items.find(element => element.CODE == _element.CODE);
              if(found)
              {                 
                found._isFavor = true;                   
              }
          
            });

        }

       const onTapClicked = (_status)=>{
         currentMode.value = _status;
         if(_status == 'favor')
         {
           itemNameType.value = "한글명";
           getFavorItemList();
         }
       }

       onMounted(()=>{
         
         setTimeout(()=>{
            socket.emit("requestInitAll", {
              itemType: 'C'
            }, function(data) {
                console.log("periodInitData:", data);
            })
         },1000)

        watchEffect(()=>{        
          try{  
                if(currentMode.value == 'normal')
                {

                    props.items.forEach(element => {
                      if(!periodInitData.value.dataObj)return;
                      var found = periodInitData.value.dataObj[element.CODE]; 
                      if(!found)return;
                      element._currentPrice = Number(found.trade_price);
                      element._dGapPrice = Number(found.trade_price) - Number(found.prev_closing_price);
                      element._drate =  Constant.UTIL.toFixedNum(found.drate * 100, 2);   
                      element._volumePrice =  Math.floor(found.acc_trade_price_24h/1000000);         
                    });
                              
                }
                else if(currentMode.value == 'favor')
                {
                    favorItemList.value.forEach(element => {
                      if(!periodInitData.value.dataObj)return;
                      var found = periodInitData.value.dataObj[element.CODE]; 
                      if(!found)return;
                      element._currentPrice = Number(found.trade_price);
                      element._dGapPrice = Number(found.trade_price) - Number(found.prev_closing_price);
                      element._drate =  Constant.UTIL.toFixedNum(found.drate * 100, 2);   
                      element._volumePrice =  Math.floor(found.acc_trade_price_24h/1000000);              
                    });                          

                }


          }catch(e)
          {
            console.log("eeee", e)
          }
        })

         setTimeout( ()=>{
             getFavorItemList() 
         }, 1000)
        //  setTimeout (()=>{
        //       onListSort(1)
        //  }, 2000)

       })
       
       
       onUnmounted(()=>{
       
          socket.emit("leaveInitAll", {
              itemType: 'C'
          }, function(data) {
              console.log(data);
          })
       })


        const onScroll = (e) =>{
            e
            // trace(e.target.scrollTop); 
        }

        const onFocus = (e) =>{
            console.log("onFocus", e)
         

           // console.log("favorItems.value.length :", favorItems.value.length)
        }
        const onKeyup =(e)=>{
         // console.log("onKeyup:", searchText.value.length, e.keyCode)
         if(e.keyCode == 13)
         {             
             scrollbar.value.$el.scrollTop = 0;
         }
        }

        const onSearchDel = ()=>{
          searchText.value = ''
        }

        const itemNameType = ref('한글명')
        const onChangeName = () =>{
          if(itemNameType.value == "한글명")
          {
               itemNameType.value = "영문명";
          }
          else if(itemNameType.value == "영문명")
          {
               itemNameType.value = "한글명";
          }
        }

        var isSortTypeToggle_1= false;
        var isSortTypeToggle_2 = false;
        var isSortTypeToggle_3 = false;
        const onListSort = (type) =>{
           //  console.log("onListSort :", type)
            if(type == 1)
            {
                  isSortTypeToggle_1 = !isSortTypeToggle_1
                  filteredItems.value.sort((a,b)=>{                      
                    if (a._currentPrice < b._currentPrice) {
                      return isSortTypeToggle_1?1:-1;
                    }
                    if (a._currentPrice > b._currentPrice) {
                      return isSortTypeToggle_1?-1:1;
                    }
                    return 0;   
                  })
            }
            else if(type == 2)
            {
                  isSortTypeToggle_2 = !isSortTypeToggle_2
                  filteredItems.value.sort((a,b)=>{                      
                    if (a._drate < b._drate) {
                      return isSortTypeToggle_2?1:-1;
                    }
                    if (a._drate > b._drate) {
                      return isSortTypeToggle_2?-1:1;
                    }
                    return 0;   
                  })
            }
            else if(type == 3)
            {
                isSortTypeToggle_3 = !isSortTypeToggle_3
                filteredItems.value.sort((a,b)=>{                      
                  if (a._volumePrice < b._volumePrice) {
                    return isSortTypeToggle_3?1:-1;
                  }
                  if (a._volumePrice > b._volumePrice) {
                    return isSortTypeToggle_3?-1:1;
                  }
                  return 0;   
                })            
            }
        }


        var isSortTypeToggle_favor_1= false;
        var isSortTypeToggle_favor_2 = false;
        var isSortTypeToggle_favor_3 = false;
        const onListSort_favor = (type) =>{
            if(type == 1)
            {
                  isSortTypeToggle_favor_1 = !isSortTypeToggle_favor_1
                  filteredItems_favor.value.sort((a,b)=>{                      
                    if (a._currentPrice < b._currentPrice) {
                      return isSortTypeToggle_favor_1?1:-1;
                    }
                    if (a._currentPrice > b._currentPrice) {
                      return isSortTypeToggle_1?-1:1;
                    }
                    return 0;   
                  })
            }
            else if(type == 2)
            {
                  isSortTypeToggle_favor_2 = !isSortTypeToggle_favor_2
                  filteredItems_favor.value.sort((a,b)=>{                      
                    if (a._drate < b._drate) {
                      return isSortTypeToggle_favor_2?1:-1;
                    }
                    if (a._drate > b._drate) {
                      return isSortTypeToggle_favor_2?-1:1;
                    }
                    return 0;   
                  })
            }
            else if(type == 3)
            {
                isSortTypeToggle_favor_3 = !isSortTypeToggle_favor_3
                filteredItems_favor.value.sort((a,b)=>{                      
                  if (a._volumePrice < b._volumePrice) {
                    return isSortTypeToggle_favor_3?1:-1;
                  }
                  if (a._volumePrice > b._volumePrice) {
                    return isSortTypeToggle_favor_3?-1:1;
                  }
                  return 0;   
                })            
            }
        }


        const getIteminfo = ( _item )=>{
          console.log('getIteminfo', _item)
        }

    return{
           options: {       
                wheelSpeed: 1,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: false,
           },
           watcher: false,
           onScroll,
          
           onItemClick,
           onFavorClick,
           currentItem,
           Constant,
           searchText,
           onKeyup,
           onFocus,
           filteredItems,
           onTapClicked,
           favorItemList,
           currentMode,
           onSearchDel,
           onChangeName,
           onListSort,
           onListSort_favor,
           itemNameType,
           onFavorClickInFavor,
           filteredItems_favor,
           getIteminfo,
           getNdotposCoin
    }
  }

}
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css">

</style>