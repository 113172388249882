<template>   
    <Modal> 
        <template #title>
             재도전 신청
        </template>

        <template #body>
            <div class="rechallenge-txt">
              <p class="text-danger">[주의] 재도전 시 현시점까지의 기초자산과<br>거래내역은 모두 초기화로 리셋됩니다.</p>
              <div class="rechallenge-info">
                <span>{{currentSpaceName?currentSpaceName:'모의투자 대회'}}</span>
                <span>* 재도전 횟수  {{recount + '/' +  (retrycount==-1?'무한':retrycount)}}</span>
              </div>
            </div>
        </template>

        <template #footer>
            <button
                type="button" 
                class="btn btn-outline-secondary" 
                @click="onCancle"
            >
                취소
            </button>
            <button 
                type="button" 
                class="btn btn-danger"
                @click="onOk"
            >
                신청
            </button>
        </template>     
    </Modal>
    
</template>

<script>
import Modal from "@/components/popups/Modal.vue"
//import {ref} from 'vue'
export default {
  components:{
      Modal
  },
  props:{
 
    currentSpaceName:{
        type:String,
        required: true
    },
    recount:{
        type:Number,
        required: true
    },
    retrycount:{
        type:Number,
        required: true
    },
  },
  emits:['cancle', 'ok'],
  setup(props, {emit}){
  

    // conseole.log("ssssssssssssssssssss", props)
    // const check = ref(true)
    //  setInterval(()=>{
    //       console.log("check:!", check.value)
    //  }, 1000)
     const onCancle = () =>{
      
        emit('cancle')
     }

     const onOk = () =>{
        emit('ok')
     }

     
     return {
         onCancle,
         onOk,
         props,
      

     }
   }

}
</script>

<style>

</style>