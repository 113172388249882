

module.exports = {
    URL: {
        apiGroundUrl: "https://api.dev.flexmoney.co.kr/",        
        apiLifeUrl: "https://api.dev.flexlife.co.kr/", 
        apiFlextvUrl: 'https://www.hotaetv.com/',
        flextvUrl: 'https://www.hotaetv.com',

        wsBaseUrl: "wss://ws.dev.flexmoney.co.kr/",
        wsChartUrl: "wss://wschart.dev.flexmoney.co.kr/",  

        flexMoneyUrl: 'https://dev.flexmoney.co.kr/',
        flexLifeUrl: 'https://dev.flexlife.co.kr/',
       
    }

}