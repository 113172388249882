<template>
  <!-- login :: 로그인 전 start -->
  <div class="section login sub" v-if="!isLogin">
    <div class="btn-login">
      <button  @click="onLogin" class="btn btn-danger" type="button">FLEX-LIFE 통합계정 로그인</button>
    </div>
  </div>
  <!-- //login :: 로그인 전 end -->

  <!-- login :: 로그인 후 start -->
  <div class="section login sub" v-if="isLogin">

    <!-- <div class="aside-user-info">
      <div class="badge-box">
        <span class="badge badge-bg-10">100</span>
      </div>
      <span class="user text-truncate">
        <span class="ico"><img src="@/assets/images/main/aside_sts_rank.png" alt="랭킹"></span>
        {{ store.state.user.memberData.NAME}}
      </span>
      <div class="logout-btn">
        <button  @click="onMypage"  type="button" class="btn btn-outline-secondary btn-sm mr-2">마이페이지</button>
        <button @click="onLogout" type="button" class="btn btn-outline-secondary btn-sm">로그아웃</button>
      </div>
    </div> -->
     
    <router-link :to="{ name: 'CommunityWrite'}"  class="btn-aside-write btn btn-danger">커뮤니티 글쓰기</router-link>

  </div>
  <!-- //login :: 로그인 후 end -->
</template>

<script>
import Config from '../../../public/config.js';
// import Config from '@/config.js';
import axios from '@/axios';
import { useStore } from 'vuex';
import { useRouter }from 'vue-router';
import {computed, getCurrentInstance} from 'vue';
import {useApicall} from '@/composables/commonFn';
//import VueCookies from "vue-cookies";
export default {
setup(){
const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
const store = useStore();
const router = useRouter();
const isLogin = computed(() => store.state.user.isLogin);
const isLocal =  store.state.common.isLocal;
const {apiCall_p} = useApicall()



const login_to_flexlife =  (_id, _pass)  =>{  
    var param = {}
    param.pid = "flexmoney";
    param.mid = _id;
    param.passwd = _pass;


  axios.post( Config.URL.apiLifeUrl +'member/partnerlogin', param)
  .then(async function (response) {
      let json = response.data;	           
      if (json.returnCode == 1) {
                if(json.resultMap.returnVal == 1)
                {                 
                  // console.log("login_to_server:", json.resultMap)      
                   sessionStorage.setItem('groundToken', json.resultMap.token);      
                   // window.location.reload()    
                  var groundToken = sessionStorage.getItem('groundToken');
                   const data = await apiCall_p('member/tokenlogin',{"ssotoken":groundToken})
                   store.commit('user/CHANGE_IS_LOGIN', true);  
                   store.commit('user/CHANGE_MEMBER_DATA', data.member);  
                   sessionStorage.setItem('token', data.token);                  
                }    
                else
                {
                    alert(json.message);
                }
      }
      else 
      {
                alert(json.message);			
      }
  })
  .catch (function (error) {
    console.log(error)
  })
  .then(function () {
      // always executed
  });
}

// const openWin =  (pUrl, pName, pW, pH) => {
// var winPop = window.open(pUrl, pName,'width='+pW+',height='+pH+',top=0,left=0,toolbars=no,menubars=no,scrollbars=no');
// winPop.focus();
// }  


const onLogin =()=>{
trace('onLogin')
// openWin(Config.URL.apiLifeUrl + 'plogin/login.html', 'login', 800, 500)
//for test
if(isLocal)
{
         login_to_flexlife('woo0000', 'ruddn4858@')
}
else
{
        location.href = Config.URL.flexLifeUrl + `login?app=money&redirect_url=${Config.URL.flexMoneyUrl}`;
}
}


// const onLogout =()=>{
// trace('onLogout')
// // apiCall_gt('member/logout')
// if(apiCall_gt('member/logout'))
// {
// //sessionStorage.removeItem('groundToken');
//   sessionStorage.removeItem('token'); 
//   VueCookies.remove('cootoken')
// store.commit('user/CHANGE_IS_LOGIN', false);
// //    router.push({
// //         name: 'Home',              
// //    });
// }

// }

const onMypage =()=>{
trace('onMypage')
router.push({
      name: 'MyPage',              
});
}
return{
isLogin,
onLogin,
//onLogout,
onMypage,
store
}
}
}
</script>

<style>

</style>