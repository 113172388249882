<template>
  <HeaderTrade/> 
  <!-- Container Start { -->
  <div id="container" class="sts-container">
    <!-- Contents Start { -->
    <div id="contents">
      
      <!-- 예상 상금 상단 -->
      <div class="my-reward-data">
        <div class="reward-inner">
          <h3>{{ groupData ? groupData.NAME : '' }} <span>{{ groupData ? groupData.SUBNAME : '' }}</span></h3>
          <span class="txt">이번달 예상 수령 상금 :</span>
          <span class="num" v-if="groupMember != null">
            {{ Constant.UTIL.numberWithCommas(Number(groupMember.AMOUNT)) }} <em>원</em>
          </span>
          <span class="num" v-else>0 <em>원</em></span>
        </div>
      </div>

      <div class="survey-area" v-if="isSurvey == true">
        <div class="survey-top">
          <span class="img"><img src="@/assets/images/common/logo.png" alt="flexmoney"></span>
          <span class="txt">이용자 설문조사</span>
        </div>

        <div class="survey-form">
          <!-- item 1 -->
          <p class="survey-title">{{ surveyList[0].SUBJECT }} <span class="text-danger">&#42;</span></p>
          <div class="survey-box">
            <div 
              class="form-check form-check-inline"
              v-for="(item, index) in surveyType1"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="surveyGender"
                :id="genderId + index"
                :value="item"
                name="gender"
              >
              <label class="form-check-label" :for="genderId + index">{{ item }}</label>
            </div>
          </div>

          <!-- item 2 -->
          <p class="survey-title">{{ surveyList[1].SUBJECT }} <span class="text-danger">&#42;</span></p>
          <div class="survey-box">
            <div 
              class="form-check form-check-inline"
              v-for="(item, index) in surveyType2"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="surveyAge"
                :id="ageId + index"
                :value="item"
                name="age"
              >
              <label class="form-check-label" :for="ageId + index">{{ item }}</label>
            </div>

          </div>

          <!-- item 3 -->
          <p class="survey-title">{{ surveyList[2].SUBJECT }} <span class="text-danger">&#42;</span></p>
          <div class="survey-box rating">
            <span class="rating-txt">부족함</span>
            <div 
              class="form-check form-check-inline"
              v-for="(item, index) in surveyType3"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="surveyRating"
                :id="ratingId + index"
                :value="item"
                name="rating"
              >
              <label class="form-check-label" :for="ratingId + index">{{ item }}</label>
            </div>
            <span class="rating-txt">훌륭함</span>
          </div>

          <!-- item 4 -->
          <p class="survey-title">{{ surveyList[3].SUBJECT }} <span class="text-secondary">(선택)</span></p>
          <div class="survey-box">
            <textarea class="form-control" id="textarea1" rows="5" v-model="surveyTextarea" placeholder="서비스 개선 사항을 입력하여 주세요."></textarea>
          </div>

          <!-- 계좌정보 입력 -->
          <div v-if="isBankAccount == true">
            <p class="survey-title text-danger">계좌정보입력 &ast;</p>
            <div class="survey-box bank">
              <span class="survey-bank">
                <select class="form-select" v-model="bankName">
                  <optgroup label="은행">
                        <option value="" disabled selected hidden>은행을 선택하여 주세요.</option>
                        <option
                          v-for="(item, index) in listTypeBank"
                          :key = index
                          :value="item.NAME"
                          >
                          {{ item.NAME }}
                        </option>
                      </optgroup>
                      <optgroup label="상호금융기관">
                        <option
                          v-for="(item, index) in listTypeMutual"
                          :key = index
                          :value="item.NAME" 
                          >
                          {{ item.NAME }}
                        </option>
                      </optgroup>
                      <optgroup label="금융투자회사">
                        <option                 
                          v-for="(item, index) in listTypeInvestment"
                          :key = index
                          :value="item.NAME" 
                          >
                          {{ item.NAME }}
                        </option>
                      </optgroup>
                </select>
              </span>
              <span class="survey-bank">
                <input type="text" placeholder="예금주명" class="form-control" v-model="accountHolder" @input="onAccountNameCheck">
              </span>
              <span class="survey-bank">
                <input type="text" placeholder="'-'를 제외한 숫자만 입력" class="form-control" v-model="bankAccount" @input="onbankAccountCheck">
              </span>
            </div>
            <p class="fs-12 mt-2">&#42; 가입자와 상금 수령자 정보가 상이할 경우 상금 지급이 되지 않습니다</p>
          </div>

          <!-- 신분증 등록 -->
          <div v-if="isIdCard == true">
            <p class="survey-title text-danger">신분증 등록 &ast;</p>
            <div class="survey-box">
              <ul class="idcard-caution">
                <li><strong class="text-danger">상금이 10만 원 이상일 경우 상금 수령을 위해 신분증 등록이 필요하며, 25만원을 초과할 경우 4.4%의 세금이 발생합니다.</strong></li>
                <li class="img">
                  <span><img src="@/assets/images/common/img_idcard.png" alt="신분증 안내"></span>
                </li>
                <li>어두운 환경 및 약간 기울여서 신분증을 촬영하면 빛반사를 최소화할 수 있습니다.</li>
                <li>인증 가능 문서 유형 - <strong>여권, 주민등록증, 운전면허증(PNG, JPG, JPEG)</strong></li>
                <li>주민등록번호 <span class="text-danger">뒷자리</span>를 모두 표기해 주세요.</li>
              </ul>

              <div class="survey-file">
                <div class="file-box"
                  @dragover="onFileDragOver"
                  @drop="onFileDrop"
                  @click="onFileClick"
                  :class="isDragged ? 'on' : ''"
                >
                    <div class="file-img" ref="surveyFileThumb">
                        <span ref="surveyFileThumbBox" v-html="groupMember.ID_IMG"></span>
                    </div>
                    <input class="d-none" type="file" ref="surveyFile" accept=".png,.jpg,.jpeg" @change="onFileDrop"> <!--파일 input box 형태-->
                    <div class="file-txt" v-if="!isDragged">
                      <span>파일을 끌어오시거나 클릭하셔서 추가하여 주십시오.</span>
                    </div>
                </div>

                <div class="btns" v-if="isDragged">
                  <button type="button" class="btn btn-secondary" @click="onFileClick">수정</button>  
                  <button type="button" class="btn btn-danger" @click="onFileDelete">삭제</button>  
                </div>
              </div>

            </div>
          </div>

          <div class="text-center">
            <button type="button" class="btn btn-dark btn-lg" @click="onSaved">제출하기</button>
          </div>
        </div>

      </div>
    </div>
    <!-- } Contents End -->

  </div>
  <!-- } Container End -->

  <Footer/>
</template>

<script>

import { useStore } from 'vuex';
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import { ref, onMounted, computed, getCurrentInstance } from 'vue'
import { useApicall } from '@/composables/commonFn';
import Constant from '@/constant.js';
import { useRouter }from 'vue-router'
import moment from 'moment';

export default {
  components:{  
  HeaderTrade, 
  Footer,
},
  setup(){
    const store = useStore();
    const router = useRouter();
    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_0'); 
    store.commit('common/CHANGE_CURRENT_PAGE', 'TradeReward');
    // const { apiCall_g, apiCall_gt } = useApicall();
    const { apiCall_g, apiCall_gt, apiCall_pt } = useApicall();
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const isLogin = computed(() => store.state.user.isLogin);

    const surveyFile = ref(null);
    const surveyFileThumb = ref(null);
    const isDragged = ref(false);

    const groupData = ref({});
    const groupMember = ref({});
    const surveyList = ref([]);
    const memberData = ref({});

    const surveyType1 = ref([]);
    const surveyType2 = ref([]);
    const surveyType3 = ref([]);

    const genderId = ref('gender');
    const ageId = ref('age');
    const ratingId = ref('rating');

    const surveyGender = ref('남성');
    const surveyAge = ref('20대');
    const surveyRating = ref('5');
    const surveyTextarea = ref('');

    const bankName = ref('');
    const listTypeBank = ref([]);
    const listTypeInvestment = ref([]);
    const listTypeMutual = ref([]);
    const accountHolder = ref('');
    const bankAccount = ref('');

    const idCardFile = ref('');
    const surveyFileThumbBox = ref(null);

    const isSurvey = ref(false)
    const isBankAccount = ref(false)
    const isIdCard = ref(false)

    // groupMode 1(설문 O)
    // groupMode 2(설문 O, 상금 10만 미만)
    // groupMode 3(설문 O, 상금 10만 미만, 수령신청완료)
    // groupMode 4(설문 O, 상금 10만원 이상)
    // groupMode 5(설문 O, 상금 10만원 이상, 수령신청완료)
    var groupMode = 1;

    onMounted(() => {
      if(isLogin.value) {
        getGroupData();
      } else {
        emitter.emit('EVENT_ALERT',{title:"알림", body: "로그인 후 입장 가능합니다."});
        router.push({
          name:'Home'
        });
      }
    })

    const getGroupData = async() => {
      const res = await apiCall_gt(`vspace/getmyspacegroup`, null, ( message ) => {
        if( message == "상금 수령대상이 아닙니다." ) {
          emitter.emit('EVENT_ALERT',{title:"알림", body:message});
          return false;
        }
      });

      var result = res;
      if(!result) result = [];

      groupData.value = result.groupRow;
      groupMember.value = result.groupMemberRow;
      surveyList.value = result.surveyList;
      memberData.value = result.memberRow;

      getGroupMode();
      getOpenedCheck();
      getGroupAmount();

      if( groupMode == 3 || groupMode == 5) surveyIsApply();
      // console.log('getGroupData======>',groupData.value, groupMember.value, surveyList.value, memberData.value )
    }

    const getOpenedCheck = () => {
      var getTime = new Date();
      var now = moment(getTime,"YYYY-MM-DD HH:mm:ss" ).format("YYYYMMDDHHmmss");

      if( groupMode != 1 ) {
        if( groupData.value.STARTDATE < now && groupData.value.ENDDATE > now ) {
          //게시시간 O, 오픈 O
          if( groupData.value.ISOPENED == "Y" ) {
            getSurveyArray();
            isSurvey.value = true;
          }
        //게시시간 X
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    const getSurveyArray = () => {
      if(surveyList.value) {
        surveyType1.value = [];
        surveyType2.value = [];
        surveyType3.value = [];

        surveyList.value.forEach(arr => {
          if(arr.TYPE == 'survey_1') {
              surveyType1.value = arr.CONTENTS.split("/");
            } else if(arr.TYPE == 'survey_2') {
              surveyType2.value = arr.CONTENTS.split("/");
            } else if(arr.TYPE == 'survey_3') {
              surveyType3.value = arr.CONTENTS.split("/");
            }
          });
        } else {
          return false;
        }
    }

    const getGroupMode = () => {      
      if( groupMember.value == null ) {
        groupMode = 1;
      }
      if( groupMember.value != null && groupMember.value.AMOUNT < 100000 ) {
        groupMode = 2;
      }
      if( groupMember.value != null && groupMember.value.AMOUNT < 100000 && groupMember.value.ISAPPLY == 'Y' ) {
        groupMode = 3;
      }
      if( groupMember.value != null && groupMember.value.AMOUNT >= 100000 ) {
        groupMode = 4;
      }
      if( groupMember.value != null && groupMember.value.AMOUNT >= 100000 && groupMember.value.ISAPPLY == 'Y' ) {
        groupMode = 5;
      }
      // console.log('getGroupMode =====>', groupMode);
    }

    const getGroupAmount = () => {
      //상금 O
      if( groupMember.value != null ) {
          getBankList();
          isBankAccount.value = true;
          if( groupMode == 4 || groupMode == 5 ) {
            isIdCard.value = true;
          }
      } else {
        return false;
      }
    }

    const getBankList = async () => {
      var res = await apiCall_g(`index/getbanklist`);
      var result = res.dataList;

      if(result) {
        listTypeBank.value = [];
        listTypeInvestment.value = [];
        listTypeMutual.value = [];

        result.forEach(el => {
          if(el.TYPE == 'bank') {
            listTypeBank.value.push(el);
          } else if(el.TYPE == 'investment') {
            listTypeInvestment.value.push(el);
          } else {
            listTypeMutual.value.push(el);
          }
        });

        listTypeBank.value.reverse()
        listTypeInvestment.value.reverse()
        listTypeMutual.value.reverse()
      }
    }

    const onSaved = () => {
      if( groupMode != 1 ) {
        if( !bankName.value ) {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"은행을 선택하여 주세요."});
          return false;
        }
        if( !accountHolder.value ) {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"예금주명을 입력하여 주세요."});
          return false;
        }
        if( !bankAccount.value ) {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"계좌번호를 입력하여 주세요."});
          return false;
        }
      }

      if( groupMode == 4 || groupMode == 5 ) {
        if( document.querySelector('.file-img > span') ) {
          idCardFile.value = document.querySelector('.file-img > span > img');
        } else {
          idCardFile.value = document.querySelector('.file-img > img');
        }

        if( idCardFile.value == null ) {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"신분증을 등록하여 주세요."});
          return false;
        }
      }

      surveySumbit();
    }

    const surveySumbit = async() => {
      var params = {};
      var fileImgTag = '<img src=' + '"' + idCardFile.value.src + '"' + '>'

      params.idx = null;
      params.bankName = null;
      params.bankAccount = null;
      params.accountHolder = null;
      params.survey_1 = surveyGender.value;
      params.survey_2 = surveyAge.value;
      params.survey_3 = surveyRating.value;
      params.survey_etc = surveyTextarea.value;
      params.idImg = null;

      if( groupMode != 1 ) {
        params.idx = groupMember.value.IDX;
        params.bankName = bankName.value;
        params.bankAccount = bankAccount.value;
        params.accountHolder = accountHolder.value;
      }

      if( groupMode == 4 || groupMode == 5 ) {
        params.idImg = fileImgTag;
      }

      var res = await apiCall_pt(`vspace/rewardapply`, params, 'PUT', ( message ) => {
        if( message ) {
          emitter.emit('EVENT_ALERT',{title:"알림", body:message});
          return false;
        }
      });

      if( res ) {
        if( groupMode == 3 || groupMode == 5 ) {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"설문조사 내용이 수정되었습니다."});
        } else {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"설문조사 등록이 완료되었습니다."});
        }
      }

    }


    const surveyIsApply = () => {
      if( groupMode == 3 ) {
        surveyGender.value = groupMember.value.SURVEY_1;
        surveyAge.value = groupMember.value.SURVEY_2;
        surveyRating.value = groupMember.value.SURVEY_3;
        surveyTextarea.value = groupMember.value.SURVEY_ETC;
        bankName.value = groupMember.value.BANK_NAME;
        accountHolder.value = groupMember.value.ACCOUNT_HOLDER;
        bankAccount.value = groupMember.value.BANK_ACCOUNT;
      }

      if( groupMode == 5 ) {
        surveyGender.value = groupMember.value.SURVEY_1;
        surveyAge.value = groupMember.value.SURVEY_2;
        surveyRating.value = groupMember.value.SURVEY_3;
        surveyTextarea.value = groupMember.value.SURVEY_ETC;
        bankName.value = groupMember.value.BANK_NAME;
        accountHolder.value = groupMember.value.ACCOUNT_HOLDER;
        bankAccount.value = groupMember.value.BANK_ACCOUNT;
        idCardFile.value = document.querySelector('.file-img > span > img');
        isDragged.value = true;
      }
    }

    // 예금주명 : 한글, 영문, 숫자
    const onAccountNameCheck = () => {
      var regexp = /[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g;
      if ( regexp.test(accountHolder.value) ) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"한글, 영문, 숫자만 입력 가능합니다."});
        accountHolder.value = '';
      }
      return;
    }

    // 계좌번호 : 숫자만
    const onbankAccountCheck = () => {
      var regexp = /[^0-9]/gi;
      if ( regexp.test(bankAccount.value) ) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"숫자만 입력 가능합니다."});
        bankAccount.value = '';
      }
      return;
    }    

    const onFileDragOver = (e) => {
      e.preventDefault();
    }
    
    const onFileDrop = (event) => {
      // console.log("onDrop =====>",event.target, event.dataTransfer.files[0].type);
      event.preventDefault();
      fileTypeIsValid(event);
    }

    const fileTypeIsValid = (type) => {
      // console.log('fileTypeIsValid', type)
       //파일 일때
      if(type.target == surveyFile.value) {
        if(type.target.files[0]) {
          if(/\.(jpg|jpeg|png)$/i.test(type.target.files[0].name)) {
            setFileThumnail(type);
          } else {
            emitter.emit('EVENT_ALERT',{title:"알림", body:"신분증은 이미지(png, jpg, jpeg)만 가능합니다."});
            return false;
          }
        }
        return false;
        //드래그 일때
      } else {
        if(/\.(jpg|jpeg|png)$/i.test(type.dataTransfer.files[0].name)) {
          setFileThumnail(type);
        } else {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"신분증은 이미지(png, jpg, jpeg)만 가능합니다."});
          return false;
        }
      }
    }

     const setFileThumnail = (event) => {
        isDragged.value = true;
        var thumb = document.querySelector('.file-img > img');

        if(thumb) thumb.remove();
        if(surveyFileThumbBox.value) surveyFileThumbBox.value.remove();

        var reader = new FileReader();
        reader.onload = function(e) {
          var img = document.createElement("img");
          img.setAttribute('src', e.target.result);
          surveyFileThumb.value.append(img);  
        };

        if(event.target == surveyFile.value) {
          if(!event.target.files[0]) {
            surveyFile.value.value = '';
            isDragged.value = false;
            thumb.remove();
            return false;
          } else {
            reader.readAsDataURL(event.target.files[0]);
          }
        } else {
          //드래그 파일 일때
          reader.readAsDataURL(event.dataTransfer.files[0]);
        }
     }

    const onFileClick = () => {
      surveyFile.value.click();
    }

    const onFileDelete = () => {
      if(surveyFileThumbBox.value) {
        surveyFileThumbBox.value.remove();
      }
      if(document.querySelector('.file-img > img')) {
        document.querySelector('.file-img > img').remove();
      }
      surveyFile.value.value = '';
      isDragged.value = false;
    }


    return {
      isLogin,
      surveyFile,
      surveyFileThumb,
      onFileDelete,
      onFileDragOver,
      onFileDrop,
      onFileClick,
      isDragged,
      Constant,
      groupData,
      surveyList,
      getGroupData,
      groupMember,
      memberData,
      surveyType1,
      surveyType2,
      surveyType3,
      genderId,
      ageId,
      ratingId,
      surveyGender,
      surveyAge,
      surveyRating,
      surveyTextarea,
      surveySumbit,
      bankName,
      listTypeBank,
      listTypeInvestment,
      listTypeMutual,
      accountHolder,
      bankAccount,
      idCardFile,
      onSaved,
      onAccountNameCheck,
      onbankAccountCheck,
      surveyFileThumbBox,
      isSurvey,
      isBankAccount,
      isIdCard,
    }
  }
}


</script>

<style>

</style>