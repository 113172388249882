<template>
  <HeaderTrade/> 
  <!-- Container Start { -->
  <div id="container">
    <!-- Contents Start { -->
    <div id="contents">

      <!-- content start -->
      <div class="contents-area">

        <!-- 글쓰기 start -->
        <div class="section ground-write">
          <div class="title">커뮤니티 글쓰기</div>
          <div class="ground-write-tb">
            <table class="table">
              <colgroup>
                <col style="width:15%">
                <col style="width:85%">
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">카테고리</th>
                  <td>
                    <ul class="write-category-list" @click="onSelectCategory">
                      <li
                        v-for="(item, index) in categorylist"
                        :key=index
                      >
                        <a href="javascript:;" class="item" :id="`${item.CODE}`">{{item.NAME}}</a>
                      </li>
                    </ul>
                    <!-- <select class="form-select w-25" aria-label="카테고리" ref="rSelectBox">

                       <option 
                        v-for="(item, index) in categorylist"
                        :key = index
                        :value= item.CODE
                        :selected="item.NAME=='국내주식'?true:false"
                       >
                       <option 
                        v-for="(item, index) in categorylist"
                        :key = index
                        :value= item.CODE
                       >
                       {{item.NAME}}
                       </option>
                    </select>                       -->
                  </td>
                </tr>
                <tr>
                  <th scope="row">제목</th>
                  <td>
                      <label for="inputSubject" class="visually-hidden">제목</label>
                      <input v-model="rTitle" type="text" class="form-control" id="inputSubject" placeholder="제목을 입력하여 주세요." maxlength="50">

                  </td>
                </tr>
                <tr>
                  <th scope="row">내용</th>
                  <td class="ql-editor-area">
                    <div class="text-danger text-end fs-12">
                      &#8251; 주의사항 : 게시물은 텍스트, 이미지를 포함해 10MB 이하입니다. 동영상파일은 40MB이하입니다.
                    </div>
                     <label for="inputContents" class="visually-hidden">textarea</label>
                     <!-- <textarea class="form-control" id="inputContents" rows="20"></textarea> -->
                     <QuillEditor
                      class="form-control"
                      id="inputContents"
                      style="font-size: 16px; border-radius: 2px; height:400px"
                      rows="20"
                      theme="snow"
                      :toolbar="[
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }],
                        ['link', 'image'],
                        ['video'],
                      ]"
                      ref="rContent"
                      :modules="modules"
                      @textChange="txtChange"
                      />
                      <form class="visually-hidden">
                        <input type="file" name="videoUpload" id="videoUpload"  @input="onFlieInput" accept="video/mp4, video/ogg, video/wmv"/>
                      </form>
                  </td>
                </tr>
           
              </tbody>
              
            </table>

          </div>
        </div>
        
        <!-- //글쓰기 end -->

      </div>
      <!-- //content end -->  

      <!-- aside start -->
      <div class="aside-area">

        <!-- 버튼 영역 start -->
        <div class="section ground-write-btn">
            <!-- <button class="btn btn-danger" type="button" data-bs-toggle="modal" data-bs-target="#alertGroundCompleted" @click="onSaved" >등록</button>
            <button class="btn btn-outline-gray" type="button" data-bs-toggle="modal" data-bs-target="#alertGroundTemplrary" @click="onTempSaved" >임시저장</button> -->

            <button class="btn btn-danger" type="button" @click="onSaved">{{ !$route.params.id == '' ? '수정' : '등록'}}</button>
            <!-- <button class="btn btn-outline-gray" type="button"  @click="onTempSaved" >임시저장</button> -->
            <button class="btn btn-outline-gray" type="button" @click="$router.go(-1)">취소</button>
        </div>
        <!-- //버튼 영역 end -->

        <!-- 등록 안내 start -->
        <div class="section community-write-info">
          <h2>커뮤니티 이용정책</h2>
          <div class="write-info-box">
            <h3 class="title">1조 저작권 및 회원가입/활동 금지사항</h3>
            <ul class="list">
              <li>1항. 등록하는 게시물로 인하여 본인 또는 타인에게 손해 및 기타 문제가 발생하는 경우 , 회원은 이에 대한 책임을 질 수 있으며 명예훼손이나 개인정보 유출, 저작권등 법률에 위배되는 게시물 및 댓글은 관련 법안에 따라 민형사상 처벌을 받을 수 있음</li>
              <li>2항. 지적재산권 및 저작권이 있는 저작물을 원작자의 허가 없이 게시 또는 배포하는 행위금지<br>* 뉴스의 경우, 기사 내용의 전부 혹은 일부를 게시하는 경우에는 저작권에 위배될 수 있기 때문에 단순링크만을 허용함</li>
              <li>3항. 타인의 개인정보를 이용한 가입 및 활동이 발견될 경우 이용에 제한을 받을 수 있음</li>
              <li>4항. 회원가입시 타인의 정보를 무단 도용/허위내용을 등록한 경우 강제 탈퇴처리 됨</li>
              <li>5항. 다수의 아이디를 생성하여 허위신고 및 타인 사칭을 통한 문제 발생 시 , 이용 제한을 받을 수 있음</li>
            </ul>
            <h3 class="title">2조 게시물 제한 규정</h3>
            <ul class="list">
              <li>
                1항. 상업성 광고글에 관한 게시물을 제한함
                <p class="bullet">상품광고, 영업점 홍보, 사이트홍보, 개인블로그나 SNS , 유튜브등 영상채널 홍보</p>
                <p class="bullet">성인광고, 도박 등의 광고</p>
              </li>
              <li>
                2항. 회원간의 분란을 조장하거나 선동하는 게시물을 제한함
                <p class="bullet">플렉스머니 회원을 기만하는 게시물</p>
                <p class="bullet">회원들에게 불편을 조장하는 게시물</p>
                <p class="bullet">특정인물 및 특정단체를 지목 하거나 저격성 게시물</p>
              </li>
              <li>
                3항. 개인정보의 유포 및 수집에 관한 게시물을 제한함
                <p class="bullet">본인, 혹은 타인의 실명,주민등록번호,전화번호,사진 등의 개인정보가 담겨 있는 게시물</p>
                <p class="bullet">설문조사 ,공유, 홍보 등의 목적으로 개인정보 (이메일 , 연락처 등)를 유도하는 행위</p>
              </li>
              <li>
                4항. 음란자료에 대한 게시물
                <p>정보통신 윤리위원회의 컴퓨터 음란물 심의규정에 의거하여 다음과 같은 음란자료 및 링크에 대해 처벌을 받을 수 있습니다.
                  <br>정보통신윤리 음란물 심의 기준 제7조(음란성에 관한 기준)<br>심의규정 제 15조 제1항에 해당하는 내용은 다음 각 호와 같다.
                </p>
                <p class="bullet">불륜관계, 근친상간 등 패륜적·반인륜적 성행위를 자세하게 소개하거나 흥미위주로 묘사한 내용</p>
                <p class="bullet">매춘 등 불법 성행위를 정당하게 묘사한 내용</p>
                <p class="bullet">음란정보 또는 퇴폐업소가 있는 장소를 안내 또는 매개하는 내용</p>
              </li>
              <li>
                5항. 신체노출에 대한 게시물
                <p class="bullet">착의상태라도 남녀의 성기 등이 지나치게 강조/근접 촬영되어 윤곽, 굴곡이 드러난 내용</p>
                <p class="bullet">남녀의 성기, 국부, 음모 또는 항문이 노출되거나 투명한 의상 등을 통해 비치는 내용</p>
                <p class="bullet">남녀의 성기를 저속하게 표현한 내용</p>
                <p class="bullet">신체부위에 과도한 문신·낙인, 변태적 복장·장신구 등을 부착하여 혐오감을 주는 내용</p>
                <p class="bullet">다른 사람의 신체를 의사에 반하여 촬영하거나, 촬영한 사진을 등록한 게시물</p>
              </li>
              <li>
                6항. 성행위 및 기타사항에 대한 게시물
                <p class="bullet">성행위시 기성을 포함한 신음소리를 묘사한 내용</p>
                <p class="bullet">수간, 혼음, 성고문 등 변태성욕을 묘사한 내용</p>
                <p class="bullet">이성 또는 동성간의 정사, 구강성교, 성기애무 등 성행위를 직접적으로 묘사한 내용</p>
                <p class="bullet">성폭력, 강간, 윤간 등 성범죄를 구체적·사실적으로 묘사 하거나 미화한 내용</p>
                <p class="bullet">신체의 일부, 성기구를 이용한 자위행위 묘사 내용</p>
                <p class="bullet">매춘, 사이버섹스, 노골적인 성적대화 등 성적유희 대상을 찾거나 매개하는 내용</p>
                <p class="bullet">방뇨·배설시의 오물, 인체에 부착된 오물, 정액 및 여성생리 등을 묘사하여 혐오감을 주는 내용</p>
                <p class="bullet">출산상황을 저속·흥미위주로 묘사하여 혐오감을 주는 내용</p>
              </li>
              <li>7항. 범죄행위에 관한 게시물을 제한함</li>              
              <li>8항. 허위사실을 유포하거나 낚시성 게시물을 제한함</li>              
              <li>
                9항. 악성코드 / 혐오감 조성에 관한 게시물을 제한함
                <p class="bullet">악성코드 및 스파이웨어를 포함하거나 유도 관련 게시물</p>
                <p class="bullet">타인에게 혐오감이나 불쾌감을 줄 수 있는 게시물</p>
                <p class="bullet">각종 신체훼손에 관한 내용이 포함된 게시물</p>
                <p class="bullet">과도한 폭력성이 음성, 영상 사진 등으로 표현된 게시물</p>
              </li>              
              <li>10항. 특정 종교에 대한 포교 및 선교활동에 관한 게시물을 제한함</li>
              <li>
                11항. 도배성 게시물 / 무의미한 게시물을 제한함
                <p class="bullet">다량 게시를 목적으로 의미 없는 제목 , 내용으로 작성한 게시</p>
                <p class="bullet">비정상적으로 추천, 게시글 댓글을 유도하거나 요구하는 게시물<br>ex) 추천시 애인생김 등</p>
              </li>
              <li>12항. 과도한 욕설, 협박 및 인격과 신체에 대한 모욕하는 게시물을 제한함</li>
              <li>
                13항. 타인을 사칭하는 게시물을 제한함
                <p class="bullet">플렉스머니 운영자나 공인, 특정이슈 관련된 당사자 혹은 주변인을 사칭하여 작성한 게시물</p>
              </li>
              <li>
                14항. 인종/지역/성별/종교/장애 차별 및 비하하는 게시물을 제한함
                <p class="bullet">윤락행위, 원조교제 알선, 마약, 사기 등 범죄와 관련 있거나 범죄를 유도하는 게시물</p>
              </li>
              <li>15항. 다수의 계정으로 추천수를 늘리거나 댓글, 신고를 하는 행위를 제한함</li>
              <li>16항. 플렉스머니와 플렉스머니 입점 업체에 대한 비난과 확인되지 않은 내용을 유포하거나 분위기를 조장하는 게시물을 제한함</li>
              <li>17항. 추천 또는 댓글을 유도하거나 요구하는 게시물을 제한함</li>
              <li>18항. 기타 법령 또는 미풍양속을 저해하는 게시물을 제한함</li>
              <li>19항. 플렉스머니 운영에 방해되거나 지장을 초래할 수 있는 게시물을 제한함</li>
            </ul>
            <h3 class="title">3조 이용제한 안내</h3>
            <ul class="list">
              <li>1항. 위반 사실이 중할 경우 누적 횟수에 상관없이 영구정지 및 회원 박탈 조치가 취해질 수 있음</li>
              <li>2항. 경고를 받은 회원이 지속적으로 문제발생시 플렉스머니 이용정지 처분을 받을 수 있음</li>
            </ul>            
            <h3 class="title">4조 청소년 유해 내용 (심의규정 제 7조 제3항 의함)</h3>
            <ul class="list">
              <li>1항. 하반신이 관련된 체위를 구체적으로 묘사한 내용</li>
              <li>2항. 일반작품과 예술작품이라도 극도로 선정적인 내용</li>
              <li>3항. 성행위까지의 방법, 과정, 작업, 감정을 구체적으로 음란하게 묘사한 내용</li>
              <li>4항. 성교육 등을 위한 성관련 정보를 지나치게 흥미위주로 묘사한 내용</li>
              <li>5항. 착의 상태일지라도 지나친 다리 벌림, 여성의 둔부를 강조하는 자태, 흥분상태의 표정, 포옹, 애무장면 등 성욕을 자극시키고 지나치게 선정적으로 묘사내용</li>
              <li>6항. 결혼 및 가정생활의 상식적인 관례를 벗어나는 행동을 묘사한 내용</li>
              <li>7항. 남녀의 둔부 또는 여성의 가슴이 노출되거나 투명한 의상 등을 통해 비치는 내용</li>
              <li>8항. 상식적으로 노출을 금한 공공장소에서 신체부위를 과도 노출한 내용</li>
              <li>9항. 남녀의 성기 등을 편집처리로 가린 내용</li>
              <li>10항. 성폭력 사건 등을 구체적·흥미위주로 묘사한 내용</li>
            </ul>
            <h3 class="title">* 운영정책의 조항은 관련 법규의 개정 , 원활한 운영을 위하여 지속적으로 수정됨</h3>
          </div>
        </div>

      </div>
      <!-- //aside end -->

    </div>
    <!-- } Contents End -->

  </div>
  <!-- } Container End -->
 <Footer/>

</template>

<script>

import HeaderTrade from '@/components/HeaderTrade.vue';
import { useRoute, useRouter }from 'vue-router'
import Footer from '@/components/Footer.vue';
import { useApicall } from '@/composables/commonFn';
import { onMounted, ref,  getCurrentInstance, computed, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axios from '@/axios';
import Config from '../../public/config.js';
import MagicUrl from 'quill-magic-url'

export default {
  components: {  
    HeaderTrade, 
    Footer,
    QuillEditor
  },    
  setup() {

    //  const trace = getCurrentInstance().appContext.config.globalProperties.$log;
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const route = useRoute();
    const router = useRouter();
    const categorylist = ref([]);
    const categoryCode = ref('');
    const {apiCall_g, apiCall_pt} = useApicall();
    const store = useStore();
    const rTitle = ref(null);
    const rSelectBox = ref('null');
    const rContent = ref(null);
    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_1');
    store.commit('common/CHANGE_CURRENT_PAGE', 'Community');
    const boardItem = ref(null);
    const page_store = computed(() => store.state.common.currentPage);

    const openGraphTags = ref('');

    var mode = 1;

    //mode = 1:쓰기 2:수정
    const getCategorylist = async()=>{    
    const res = await apiCall_g(`/board/categorylist?type=community`);
    var result = res.dataList;
    if(!result) result = [];
      return result
    }

    onMounted(async ()=> {
      emitter.emit('EVENT_CHECK_LOGIN');
      addVideoUploadButton();
      rContent.value.getQuill().setSelection(0);

      categorylist.value = await getCategorylist();

      if( route.params.id ) {
        mode = 2;
        boardItem.value = await getModifyBoardItem();
        rTitle.value = boardItem.value.SUBJECT;
        categoryCode.value = boardItem.value.CATEGORY_CODE;
        rContent.value.setHTML(boardItem.value.CONTENTS);
        document.getElementById(categoryCode.value).classList.add('active');
        openGraphTags.value = 2;
      } else {
        mode = 1;
        categoryCode.value = categorylist.value[0].CODE;
        document.querySelector('.write-category-list').classList.add('write');
      }
    })

    const onSelectCategory = (e) => {
      var btn = e.target;
      var categoryLists = document.querySelectorAll('.write-category-list .item');
      var writeClassRemove = document.querySelector('.write-category-list.write');

      if(writeClassRemove) {
        writeClassRemove.classList.remove('write');
      }
        
      categoryLists.forEach((list) => {
        list.classList.remove('active');
      });
      btn.classList.add('active');
      categoryCode.value = btn.id;
      // console.log('onSelectCategory  ========>', categoryCode.value);
    }

    const onSaved = ()=> {     
      var contentString = rContent.value.getHTML().replace(/<[^>]*>?/g, '');
      if( !categoryCode.value ) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"카테고리를 선택하여 주세요"});
        return;
      }

      if( !rTitle.value ) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"제목을 입력해주세요"});
        return;
      }
      if( contentString == '' ) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"내용을 입력해주세요"});
        return;
      }
      
      if(rTitle.value.length <= 2 || contentString.length <= 2) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"제목/내용은 2글자 이상 작성하여 주세요."});
        return;
      }

      saveBoard()
    }

    const saveBoard = async ()=>{           
      var params ={};
      var res = null
      
      if( mode == 1 ) {
        params = {
          "categoryCode": categoryCode.value,
          "subject": rTitle.value,
          "contents": rContent.value.getHTML()
        }
        
        res = await apiCall_pt(`board/write`, params);

      } else  if( mode == 2 ) {
        params = {
          "idx": boardItem.value.IDX,
          "categoryCode": categoryCode.value,
          "subject": rTitle.value,
          "contents": rContent.value.getHTML()
        } 
        res = await apiCall_pt(`board/modify`, params, 'PUT');
      }

      if(res) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"게시글을 저장했습니다."});
        router.push({
          name:'Community'
        })
      }
    }

    const getModifyBoardItem = async() => {
      const res = await apiCall_g(`board/view?idx=${route.params.id}`);
      return res.data; 
    }

    const txtChange = (delta) => {  
      var opsArry = delta.delta.ops;
      // console.log('txtChange---:', delta)

      if(opsArry.some(ops => ops.attributes)) {
        var attrArry = [];
        var urlArry = [];

        var editorLinkTags = document.querySelectorAll('.ql-editor > p > a');
        editorLinkTags.forEach(linkTag => {
          linkTag.parentNode.classList.add('og-link');
        });

        var ogLinkTags = document.querySelectorAll('.og-link');
        if(ogLinkTags.length !== 0) {
          ogLinkTags.forEach(ogLink => {
            if(ogLink.nextSibling !== null) {
              if(ogLink.nextSibling.firstChild.tagName == 'BR') {
                ogLink.nextSibling.remove();
              }
            }
            ogLink.classList.remove('og-link');
          });
        } else {
          return false;
        }

        for(const item of opsArry) {
          attrArry.push(item.attributes);
        }

        urlArry = attrArry.filter((element) => element !== undefined);

        if( mode == 1 ) {
          if(urlArry.length > 1) {
            emitter.emit('EVENT_ALERT',{title:"알림", body:"붙여넣기 URL은 1개씩 입력하여 주십시오."});
            return false;
          } else {
            getOpenGraph(urlArry[0].link);
          }
        } else if( mode == 2 ) {
          if( openGraphTags.value == 1 ) {
            if(urlArry.length > 1) {
              emitter.emit('EVENT_ALERT',{title:"알림", body:"붙여넣기 URL은 1개씩 입력하여 주십시오."});
              return false;
            } else {
              getOpenGraph(urlArry[0].link);
            }
          }
          openGraphTags.value = 1;
        }

        // console.log('ogLinkTags22222222', ogLinkTags.length, openGraphTags.value);
        // var urlTagArry = [];
        // urlTagArry = [...ogLinkTags];
        // let tagValues = [];
        // const tagFilter = urlTagArry.map(v => ({...v, tag:v}));
        // tagValues = [...tagFilter];
        // console.log('tagValues', tagValues)
        // let mergeArry = urlArry.map((item, i) => Object.assign({}, item, tagValues[i]));
        // console.log('urlTagArry', ogLinkTags, urlArry)

      } else {
        return false;
      }

    }

    const getOpenGraph = async(_ogUrl) => {
      // console.log('getOpenGraph111111',_ogUrl);

      try {
        const res = await axios.post(`index/getHtmlText`, { 'url' : _ogUrl });
        let json =  res.data;
        var getHtml = null;
        // var getUrl = null;

        // console.log('getOpenGraph222222222:', json);
        if (json.returnCode == 1) {
          // getUrl = json.resultMap.url;
          getHtml = document.createElement('div');
          getHtml.innerHTML = json.resultMap.responseData;
          setOpenGraph(getHtml);
        } else {
          console.log("error", json.message);
          emitter.emit('EVENT_ALERT',{title:"알림", body:"해당사이트의 오픈그래프가 존재하지 않습니다.\n다시 입력하여 주세요."});
        }
      } catch(error) {     
        console.log('error2', error);
        emitter.emit('EVENT_ALERT',{title:"알림", body:"해당사이트의 오픈그래프가 존재하지 않습니다.\n다시 입력하여 주세요."});
        return false;
      }
    }

    const setOpenGraph = (_html) => {     
      var ogTit = _html.querySelector('meta[property="og:title"]').content;
      var ogDesc = _html.querySelector('meta[property="og:description"]').content;
      var ogImg = _html.querySelector('meta[property="og:image"]').content;

      //  var ogUrlTag = `<a href="${_url}" rel="noopener noreferrer" target="_blank">${_url}</a><br>`
      //  var ogImgTag = `<img src="${ogImg}" alt="${ogTit}">`
      // console.log('setOpenGraph', ogTit, ogDesc, ogImg, _html, _url, ogUrlTag, ogImgTag)

      var quill = rContent.value.getQuill();
      var delta = quill.clipboard.convert();
      // var range = quill.getSelection();

      if( ogTit !== null || ogDesc !== null || ogImg !== null ) {
        quill.insertText(delta, ogTit, 'user');
        quill.insertText(delta, ogDesc, 'user');
        quill.insertEmbed(delta, 'image', ogImg, 'api');
        quill.setSelection(quill.getLength());
        // console.log(1111111111111, quill.getSelection(), quill.getLength());

        var getEditorDescTags = document.querySelectorAll('.ql-editor > p > img');
        getEditorDescTags.forEach(descTag => {
          descTag.parentNode.previousSibling.classList.add('og-desc');
        });

        var getEditorTitleTags = document.querySelectorAll('.og-desc');
        getEditorTitleTags.forEach(titTag => {
          titTag.previousSibling.classList.add('og-tit');
          titTag.nextSibling.firstChild.classList.add('og-img');
        });

      } else {
        quill.insertText(delta, '해당 사이트의 오픈그래프가 존재하지 않습니다.');
        // quill.update('user');
      }

    }

    onUnmounted(()=> {
      if(page_store.value !== 'Community') {
        store.commit('common/CHANGE_CUMMUNITY_CURRENT_PAGE', 1);
      }
    })   
    
    var limitSize = 40 * 1024 * 1024;

    const onFlieInput = (e) => {
      var frm = new FormData();
      frm.append("upfile",  e.target.files[0]);

      // console.log(11111, e.target.files[0], e.target.files[0].size/1024, limitSize)
      if(e.target.files[0]) {
        if(/\.(mp4|ogg|wmv)$/i.test(e.target.files[0].name)) {
          callVideoUploadApi(frm);
        } else {
          emitter.emit('EVENT_ALERT',{title:"알림", body:"동영상은 (mp4, ogg, wmv)만 가능합니다."});
          e.target.value = '';
          return;
        }

        if(e.target.files[0].size > limitSize)
        {
          emitter.emit('EVENT_ALERT',{title:"알림", body:`동영상은 용량은 ${ limitSize / (1024 * 1024) }MB 이하만 가능합니다.`});
          return;
        }
      } 

      e.target.value = '';
      // console.log(22222, e.target.files[0])
    }

    const callVideoUploadApi = (frm) =>{
         var token = sessionStorage.getItem('token');      
        //  console.log("", Config.URL.apiGroundUrl + 'board/uploadvideo')
         axios.post(Config.URL.apiGroundUrl + 'board/uploadvideo', frm, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'token': token,
          }
         })
         .then((response) => {
            // 응답 처리
            const range = rContent.value.getQuill().getSelection();
            // const newRange = addVideoRange !== null ? addVideoRange.index : 0;
            rContent.value.getQuill().insertEmbed(range.index, 'video', response.data.resultMap.data.linkUrl);
            rContent.value.getQuill().setSelection(range.index + 1);
         })
         .catch((error) => {
            // emitter.emit('EVENT_ALERT',{title:"알림", body:"동영상은 (mp4, ogg, wmv)만 가능합니다."});
            console.log("callVideoUploadApi: error ->", error )
         // 예외 처리
         })
    }

    const addVideoUploadButton = () => {
      var toolbar = document.querySelector('.ql-toolbar');
      var videoUploadBtn = document.createElement("span");

      videoUploadBtn.classList.add('ql-inner-btn');
      videoUploadBtn.innerHTML = `<button type="button" class="btn ql-video-upload">upload</button>`
      toolbar.append(videoUploadBtn);

      document.querySelector('.ql-video-upload').addEventListener('click', () => {
        document.getElementById('videoUpload').click();
      });
    }

    return {
      categorylist,
      onSaved,
      rTitle,
      rContent,
      rSelectBox,
      boardItem,
      page_store,
      onSelectCategory,
      categoryCode,
      onFlieInput,
      modules : {
        name: 'magicUrl',
        module:MagicUrl,
      },
      txtChange,
      openGraphTags,
      // getOpenGraph,      
    }
  }
}
</script>

<style>

</style>

