<template>   
    <Modal> 
        <template #title>
            공지사항
        </template>

        <template #body>
            <div id="noticePopupBody">
                <Carousel
                    v-if="noticePopupId.length == 1"
                    :mouseDrag="false"
                >
                <!-- <Carousel 
                    :autoplay="3000" >> 오토 슬라이드 시간
                    :wrap-around="true"  >> 슬라이드 무한루프
                    :pauseAutoplayOnHover="true"  >> 커서 hover 일 때 멈춤
                    :mouseDrag="false" >> 마우스 드래그
                    :settings="settings" >> 기타 옵션 설정
                > -->
                    <Slide v-for="(item, index) in boardItem" :key="index">
                        <div v-html="item.CONTENTS"></div>
                    </Slide>
                    <!-- 하단 페이징 -->
                    <template #addons>
                        <!-- <Pagination /> -->
                    </template>
                </Carousel>

                <Carousel
                    v-else
                    :autoplay="3000"
                    :wrap-around="true"
                    :pauseAutoplayOnHover="true"
                    :mouseDrag="true"
                >
                    <Slide v-for="(item, index) in boardItem" :key="index">
                        <div v-html="item.CONTENTS"></div>
                    </Slide>
                    <!-- 하단 페이징 -->
                    <template #addons>
                        <Pagination />
                    </template>
                </Carousel>
            </div>
        </template>

        <template #footer>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" ref="dayCookies" id="dayCheckbox">
                <label class="form-check-label" for="dayCheckbox">
                    오늘하루 열지 않기
                </label>
            </div>
            <button 
                type="button" 
                class="btn btn-danger w-25"
                @click="onOk"
            >
                확인
            </button>
        </template>     
    </Modal>
    
</template>

<script>
import Modal from "@/components/popups/Modal.vue"
import { ref, onMounted } from 'vue';
import { useApicall } from '@/composables/commonFn';
import VueCookies from "vue-cookies";

import { Carousel, Slide, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
  components:{
      Modal,
      Carousel,
      Slide,
      Pagination,
    },
  props:{
    
  },
  emits:['cancle', 'ok'],
  setup(props, {emit}){

     const { apiCall_g } = useApicall();
     const popupList = ref([]);
     const noticePopupId = ref([]);
     const boardItem = ref([]);
     const dayCookies = ref(null);
  
     const onCancle = () => {
        emit('cancle')
     }

     const onOk = () => {
        if(dayCookies.value.checked == true) {
            VueCookies.set('popCookie', "popupCookie", "1d");
        }
        emit('ok');
     }

     onMounted( async() => {
        noticeModalIdx();
        popupList.value = await getPopupList();
        getPopupId();  
        getPopupContents();
    })     

    const getPopupList = async() => {
      const res = await apiCall_g(`board/list?offset=0&limit=5&categoryType=aboard&categoryCode=popup`);
      var result = res.dataList;
      if(!result) result = [];
      return result
    }

    const getPopupId = () => {
        noticePopupId.value = [];
        noticePopupId.value = popupList.value.map((x) => {
            return x.IDX
        });
        // noticePopupId.value = [90, 91]
        // console.log('popupList', noticePopupId.value, noticePopupId.value.length)
    }

    const getPopupContents = async() => {
        var getIdx = noticePopupId.value
        getIdx.forEach(async(id) => {
            var res = await apiCall_g(`board/view?idx=${id}`); 
            boardItem.value.push(res.data);
        });
    }

   const noticeModalIdx = () => {
        var popupBody = document.getElementById('noticePopupBody');
        var modalBody = popupBody.closest('.modal-wrapper');
        modalBody.classList.add('modal-notice');
   }    
    
     return {
         onCancle,
         onOk,
         props,
         popupList,
         noticePopupId,
         boardItem,
         dayCookies,
     }
   }

}
</script>

<style>

</style>