<template>

        <div class="rank-cnt-time">
        <p class="txt">대회 종료까지 남은 시간</p>
            <div class="time-box">
                <div>
                <span>{{ rankCntDays }}</span>
                <span>days</span>
                </div>
                <div>
                <span>{{ rankCntHours }}</span>
                <span>hours</span>
                </div>
                <div>
                <span>{{ rankCntMinute }}</span>
                <span>minute</span>
                </div>
                <div>
                <span>{{ rankCntSecond }}</span>
                <span>second</span>
                </div>
            </div>
        </div>

        <MyBoard
            v-if="isLogin && spaceData"
            :spaceId="spaceData.ID"
            :tradeType="spaceData.TYPE"
            :isEventLeague="true"
         />
        <div class="rank-update-time">
        <span>&#8251; 마지막 업데이트 : <span class="time">{{ getUpdateDate() }}</span></span>
        <!-- <button type="button" class="btn btn-sm refresh" @click="onTapClick(tradeType)"><img src="@/assets/images/trade/ico_refresh.png"></button> -->
        </div>

        <table class="table">
            <colgroup>
                <col style="width:8%">
                <col style="width:20%">
                <col style="width:16%">
                <col style="width:16%">
                <col style="width:16%">
                <col style="width:8%">
                <col style="width:16%">
            </colgroup>
            <thead>
                <tr>
                <th>No</th>
                <th>닉네임</th>
                <th>총 보유자산</th>
                <th>합산손익</th>
                <th>수익률</th>
                <th>거래일수</th>
                <th>예상상금</th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    v-for="(item, index) in arrRankingList"      
                    :key = index
                    :class="getColor(Number(getProfitPer(Number(item.TOTAL_PROFIT), Number(item.PAYMONEY))))"
                >
                <td scope="row" :class="parseInt(item.RANK)<4?'text-danger':''">{{item.RANK}}</td>
                <!-- <td>PRO</td> -->
                <td>
                    <span class="nickname text-truncate">
                        <UserNickGrade
                        :itemGrade="item.GRADE"
                        :itemGradeRank="item.GRADE_RANK"
                        :itemNickName="item.MEMBER_NICK"
                        />
                    </span>
                </td>
                <td>{{Constant.UTIL.numberWithCommas(Number(item.TOTAL_ASSET).toFixed(spaceData.TYPE=='G'?2:0))}}</td>
                <td>
                    <span class="rate">{{Constant.UTIL.numberWithCommas(Number(item.TOTAL_PROFIT).toFixed(spaceData.TYPE=='G'?2:0))}}</span>
                </td>
                <td>
                    <span class="per"><i></i>{{Constant.UTIL.numberWithCommas(Math.abs(getProfitPer(Number(item.TOTAL_PROFIT), Number(item.PAYMONEY))).toFixed(2)) + '%' }}</span>
                </td>
                <td>{{ Constant.UTIL.numberWithCommas(item.TRADEDAYCOUNT) }}</td>
                <td>{{ Constant.UTIL.numberWithCommas(Number(getRankForReward(item.RANK))) }}</td>
                </tr>
            </tbody>
        </table>


                <!-- 페이징 start-->
        <div class="section paging-area" v-if="arrRankingList && arrRankingList.length">
            <v-pagination                  
                v-model="currentPage"
                :pages= numberOfPages
                :rangeSize="1"
                activeColor="#ff5d8b"                   
                @update:modelValue="updateHandler(currentPage)"
            />
        </div>
        <!-- //페이징 end -->     

</template>

<script>
import Constant from '@/constant.js';
import { onMounted, ref, computed  } from 'vue';
import moment from 'moment';
import VPagination from "@hennge/vue3-pagination";
import '@/assets/css/vue3-pagination.css';
import {useApicall} from '@/composables/commonFn';
import UserNickGrade from '@/components/UserNickGrade.vue';
import MyBoard from '@/components/ranking/MyBoard.vue';
import { useStore } from 'vuex';
export default {
    components:{   
        VPagination,
        MyBoard,
        UserNickGrade,
    },
    props:
    { 
       spaceData:{        
            type:Object,
            require:true
       },
    },
    setup(props){
        const store = useStore();


        var rankCountTimer  = null;
        const rankCntDays = ref('00');
        const rankCntHours = ref('00');
        const rankCntMinute = ref('00');
        const rankCntSecond = ref('00');

        const isLogin = computed(() => store.state.user.isLogin);
        const {apiCall_g} = useApicall();
        const arrRankingList = ref([]);
        const currentPage = ref(1);
        const limit = ref(10);
        const numberOfData = ref(0);
        const numberOfPages = computed(()=>{
            return Math.ceil(numberOfData.value/limit.value)
        });

        const arrRewardList = ref([]);

        const getEndDate = () => {
            if(rankCountTimer) clearInterval(rankCountTimer);

            rankCntDays.value = "00";
            rankCntHours.value = "00";
            rankCntMinute.value = "00";
            rankCntSecond.value = "00";                
            rankCountTimer = setInterval(endDateDiff, 1000);
        }


        const endDateDiff = () => {
            var end = moment(props.spaceData.ENDDATE, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss");
            var now = moment(new Date());
            var duration = moment.duration(now.diff(end));
            var timeBox = document.querySelector('.time-box');

            if( Math.sign(duration) == -1 ) {
                var day = duration.asDays();
                rankCntDays.value = Math.floor(Math.abs(day));
                // rankCntDays.value = moment(duration.days(),"DD").format("DD");
                rankCntHours.value = moment(duration.hours(),"hh").format("HH");
                rankCntMinute.value = moment(duration.minutes(),"mm").format("mm");
                rankCntSecond.value = moment(duration.seconds(),"ss").format("ss");

                if( duration.asDays() == 0 ) {
                    rankCntDays.value = "00";
                }
                if( duration.hours() == 0 ) {
                    rankCntHours.value = "00";
                }
                if( duration.minutes() == 0 ) {
                    rankCntMinute.value = "00";
                }

                if(timeBox) timeBox.classList.remove('hidden');
                // console.log('음수' , duration);
            } else {
                if(timeBox) timeBox.classList.add('hidden');
                if(rankCountTimer) clearInterval(rankCountTimer);
            }
            // console.log("currentSpaceList =======>", spaceEndDate.value.ENDDATE, end, now, duration, duration.days(),duration.hours(),duration.minutes(),duration.seconds());
        }

        const getUpdateDate = () =>{
            if(arrRankingList.value[0])
            {
                return moment(arrRankingList.value[0].MODIFICATIONDATE, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:00");
            } 
            else
            {
                return  "0000-00-00 00:00:00"
            }
        }

        const getRankList  = async(page = currentPage.value, spaceId) =>{    
            currentPage.value = page; 
            //console.log("currentPage.value", currentPage.value)
            var offset = (currentPage.value -1)*limit.value;
            //var getUrl = `board/list?limit=${limit.value}&offset=${offset}&categoryType=${categoryType}&categoryCode=${categoryCode}`;

            var getUrl = `index/getrankinglist?spaceId=${spaceId}&below_rank=60&limit=${limit.value}&offset=${offset}`;      
            var res = await apiCall_g(getUrl);
            
            try{
                if(res.dataList == null) res.dataList = [];           
                numberOfData.value = res.dataCount;
            }catch(error)
            {
                res.dataList = [];
                numberOfData.value  = 0;
            }


            if(res.spaceOk == 'N' )
            {
                var prevTotalAsset = null;
                var prevRank = null
                for(let i=0; i<res.dataList.length; i++)
                {
                let _item = res.dataList[i];    
                if(prevTotalAsset == Number(_item.TOTAL_ASSET))
                {
                    _item.RANK = prevRank;
                }
                else
                {
                    _item.RANK = (i+1)+offset;
                }
                prevTotalAsset = Number(_item.TOTAL_ASSET)
                prevRank =  _item.RANK;
                }
            }


            //numberOfData.value = res.dataCount;
            // console.log("res.dataList", res.dataList)
            return res.dataList;  
        }



        onMounted(async()=>{
            getEndDate();
            arrRankingList.value =  await getRankList(1, props.spaceData.ID);
            getRewardList();
            //  console.log("props.spaceData.ID", props.spaceData.ID);
        })


      const updateHandler = async (page) => {
       
        arrRankingList.value =  await getRankList(page, props.spaceData.ID);

        // console.log("updateHandler :", arrRankingList.value)
      }
        const getColor= (num)=>{
            var  result = ''
            if(num > 0)
            {
                result='up'
            }
            else if(num < 0)
            {
                result='down'
            }
            return result;
        }

    
        const getProfitPer = ( totalProfit, paymoney)=>{
            //console.log( "getProfitPer :", profit, balance, paymoney)
            return (totalProfit/paymoney)*100
        }

        const getRewardList = async() => {    
            const res = await apiCall_g(`index/getrewardlist?spaceId=${props.spaceData.ID}`);
            var result = res.dataList;
            if(!result || result==null || result==undefined) result = [];
            return arrRewardList.value = result
        }

        const getRankForReward = (rank) =>{
            var result = 0;
            arrRewardList.value.forEach(element => {
                if(rank >= parseInt(element.START_RANK) && rank <= parseInt(element.END_RANK) )
                {
                result =  Number(element.REWARD);
                }
            });
            return result;
        }


        return{
         getUpdateDate,
         numberOfPages,
         getColor,
         getProfitPer,
         Constant,
         arrRankingList,
         currentPage,
         updateHandler,
         isLogin,
         rankCntDays,
         rankCntHours,
         rankCntMinute,
         rankCntSecond,
         arrRewardList,
         getRankForReward,
        }
    }
}
</script>

<style>

</style>