<template>   
    <Modal> 
        <template #title>
             공유하기
        </template>

        <template #body>
            <div class="share-item-info">
              <p>
                - 이미지로 저장하여 공유하여 보세요!<br>
                - 이미지로 저장 버튼을 클릭하시면 아래의 이미지로 캡처 후 저장됩니다.
              </p>
              <span>
                <button type="button" class="btn btn-danger" @click="onSaveImg">
                  <i><img src="@/assets/images/trade/ico_capture.png" alt="capture" class="ico-capture"></i> 저장하기
                </button>
              </span>
            </div>

            <div class="share-item-box">

              <div class="share-item-inner" id="capture">
                <div class="share-item-head">
                  <span><img src="@/assets/images/common/logo.png"></span>
                </div>

                <!--  class 
                  up / down 시 
                  이미지 및 수익 변경
                -->
                <!-- <div class="share-item-body" :class="getCssColor(currentTradeType=='F'?Number(assetData._profit):currentTradeType=='G'?assetData._unrealizedPLPer:assetData.profitPre)"> -->
                <div class="share-item-body" :class="getCssColor(Number(assetData.TPOL))">
                  <div class="user-box">
                    <UserNickGrade
                      :itemGrade="assetData.GRADE"
                      :itemGradeRank="assetData.GRADE_RANK"
                      :itemNickName="assetData.MEMBER_NICK"
                    />
                  </div>
                  <div class="item-box">
                    <div class="item">{{assetData.NAME}}</div>
                    <span class="position long" v-if="currentTradeType=='G' && assetData.TRADETYPE=='3' || assetData.TRADETYPE=='5' "  >
                      LONG <span>{{Number(assetData.LEVERAGE)}}x</span>
                    </span>
                    <span class="position short" v-if="currentTradeType=='G' && assetData.TRADETYPE=='4' || assetData.TRADETYPE=='6'">
                      SHORT <span>{{Number(assetData.LEVERAGE)}}x</span>
                    </span>
                  </div>
                  <div class="per-box">
                    <span class="txt">{{currentTradeType=='F'?'수익':'수익'}}</span>
                    <span class="per">
                      <i></i> {{currentTradeType=='F'?Constant.UTIL.numberWithCommas(Math.abs(assetData.TPOL))+'원':currentTradeType=='G'?Constant.UTIL.numberWithCommas(Math.abs(assetData.TPOL).toFixed(2))+' USDT':Constant.UTIL.numberWithCommas(Math.abs(Math.floor(assetData.TPOL))) +'원'}}
                    </span>
                  </div>
                  <div class="price-box">
                    <div class="price">
                      진입가
                      <span class="num">{{Constant.UTIL.numberWithCommas(Number(assetData.ASSETINDEX))}}</span><span class="unit">{{currentTradeType=='F'?'':currentTradeType=='G'?'USDT':'KRW'}}</span>
                    </div>
                    <div class="price">
                      청산가
                      <span class="num">{{Constant.UTIL.numberWithCommas(Number(assetData.TRADEINDEX))}}</span><span class="unit">{{currentTradeType=='F'?'':currentTradeType=='G'?'USDT':'KRW'}}</span>
                    </div>
                  </div>

                  <div class="qrcode-box">
                    <qrcode-vue
                      :value="qrCodeValue"
                      :margin="qrCodeMargin"
                      level="L"
                    />
                  </div>
                  
                </div>
              </div>
            </div>

        </template>

        <template #footer>
            <button
                type="button" 
                class="btn btn-outline-secondary" 
                @click="onCancle"
            >
                닫기
            </button>
            <!-- <button 
                type="button" 
                class="btn btn-danger"
                @click="onOk"
            >
                확인
            </button> -->
        </template>     
    </Modal>

</template>

<script>
import Modal from "@/components/popups/Modal.vue"
import { onMounted, computed, ref } from 'vue';
import domtoimage from "dom-to-image-more";
import Constant from '@/constant.js';
import { useStore } from 'vuex';
import UserNickGrade from '@/components/UserNickGrade.vue';
import QrcodeVue from 'qrcode.vue'
import Config from '../../../public/config.js';

export default {
  components: {
    Modal,
    UserNickGrade,
    QrcodeVue
  },
  props: {
   
   assetData:{
      type:Object,
      require:true
   }


  },
  emits:['cancle', 'ok'],

  setup(props, {emit}) {

    const store = useStore(); 
    const imageCapture = domtoimage;
    const currentTradeType = computed(() => store.state.hts.currentTradeType);

    const qrCodeValue = ref(Config.URL.flexMoneyUrl + '?recommendMid=' + props.assetData.MID);
    const qrCodeMargin = ref(3);
    // console.log(qrCodeValue.value, typeof(qrCodeMargin.value))


   // console.log("props", props.assetData, currentTradeType.value)

    onMounted( async() => {
      captureModalIdx();
    })         

    const onCancle = () => {
      emit('cancle')
    }

    const onOk = () =>{
      emit('ok')
    }

    const captureModalIdx = () => {
      var popupBody = document.querySelector('.share-item-box');
      var modalBody = popupBody.closest('.modal-wrapper');
      modalBody.classList.add('modal-capture');
    }

    const imageCaptureUrl = ref('');
    const imageCaptureFile = ref([]);
    
    // var msgSettings = {
    //   container: '#kakaotalk-sharing-btn', // 카카오공유버튼ID
    //   objectType: 'feed',
    //   content: {
    //     title: "FLEXMONEY", // 보여질 제목
    //     description: "쏟아지는 상금에 투자경험까지, 가장 안전한 모의투자, 스마트 트레이딩 플랫폼 플렉스머니에서 만나세요!", // 보여질 설명
    //     // imageUrl: "devpad.tistory.com/", // 콘텐츠 URL
    //     imageUrl: "", // 콘텐츠 URL
    //     link: {
    //       mobileWebUrl: "http://localhost:8080",
    //       webUrl: "http://localhost:8080"
    //     }
    //   }
    // }

    const onSaveImg = () => {
      var node = document.getElementById('capture');

      imageCapture.toJpeg(node, { quality: 1 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "flexmoney.jpeg";
        link.href = dataUrl;
        link.click();
      // console.log('domImages =======>',link )     
      });
    }

  const getCssColor = (num) => {
        var result = ''
        if(num > 0)
        {
            result = "up" 
        }
        else if(num < 0)
        {
            result = "down"         
        }
        else
        {
            result = ""      
        } 
        return result;
    }
    return {
      onCancle,
      onOk,
      props,
      onSaveImg,
      imageCapture,
      imageCaptureUrl,  
      imageCaptureFile,   
      Constant,
      getCssColor,
      currentTradeType,
      qrCodeValue,
      qrCodeMargin,
    }
  }

}
</script>
