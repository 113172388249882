<template>
                    <div class="mypage-main-inner" v-if="myQnaList.length">
                      <p class="qna-info-txt">* 문의등록/수정은 1:1문의 게시판을 이용하여주시기 바랍니다.</p>
                      <!-- qna start { -->
                      <div class="qna">
                        <div
                          v-for="(item, index) in myQnaList"
                          :key = index           
                        >
                          <div
                            class="qna-list-box"
                            v-if="item.REPLY_OK === 'Y'"
                            :class="{'active' : item.isActive}"
                          >
                            <div class="qna-title" @click="item.isActive = !item.isActive">
                                <span class="btn btn-sm btn-outline-gray status success">답변완료</span>
                                <!-- <span class="category">프로모션</span> -->
                                <span class="txt text-truncate">{{ item.SUBJECT }}</span>
                                <span class="chevron"><i></i></span>
                            </div>
                            <div class="qna-area">
                                <div class="qna-q">
                                    <strong>문의</strong>
                                    <div class="question">
                                        <h4 class="title">{{ item.SUBJECT }}</h4>
                                        <p class="mt-20" v-html="item.CONTENTS"></p>
                                    </div>
                                </div>
                                <div class="qna-a">
                                  <strong>답변</strong>
                                  <div class="answer">
                                    <p v-html="item.REPLY"></p>
                                  </div>
                              </div>
                            </div>
                          </div>

                          <div 
                            v-else
                            class="qna-list-box"
                            :class="{'active' : item.isActive}"
                          >
                            <div class="qna-title" @click="item.isActive = !item.isActive">
                                <span class="btn btn-sm btn-outline-gray status ready">답변대기</span>
                                <!-- <span class="category">프로모션</span> -->
                                <span class="txt text-truncate">{{ item.SUBJECT }}</span>
                                <span class="chevron"><i></i></span>
                            </div>
                            <div class="qna-area">
                                <div class="qna-q">
                                    <strong>문의</strong>
                                    <div class="question">
                                        <h4 class="title">{{ item.SUBJECT }}</h4>
                                        <p class="mt-20" v-html="item.CONTENTS"></p>
                                    </div>
                                    <div class="qna-btn-box">
                                      <!-- <router-link :to="{ name:'QnaWrite', params:{ id: item.IDX }}">수정</router-link> -->
                                      <button type="button" class="btn btn-sm btn-danger" @click="onDelete(item.IDX)">삭제</button>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                                    
                      <!-- <ul class="inner-list">
                        <li
                          v-for="(item, index) in myQnaList"
                          :key = index
                        >
                          <div class="item-box">
                            <p class="title-box">

                              타입별 텍스트 컬러 변경
                              <span class="type">
                                <span class="text-black">나의 댓글</span>
                              </span>
                              
                              <span class="title">
                                {{ item.SUBJECT }}
                                <router-link :to="{ name:'CommunityView',  params:{ id:item.BOARD_IDX }}">
                                </router-link>
                              </span>
                            </p>
                            <span class="category">{{ item.CATEGORY_NAME }}</span>
                            <span class="date">{{ item.CREATIONDATE }}</span>
                          </div>
                        </li>
                      </ul> -->
                    </div>
                    <div v-else class="mypage-main-null">등록된 문의가 없습니다.</div>
                      
                    <!-- 페이징 start-->
                    <div class="section paging-area pb-1 mb-0" v-if="myQnaList.length">
                      <v-pagination                  
                        v-model="currentPage"
                        :pages= numberOfPages
                        :rangeSize="1"
                        activeColor="#ff5d8b"                   
                        @update:modelValue="updateHandler(currentPage)"
                      />
                    </div>
                    <!-- //페이징 end -->  

</template>

<script>
import { getCurrentInstance, ref, computed, watch } from 'vue';
import {useApicall} from '@/composables/commonFn';
import VPagination from "@hennge/vue3-pagination";
import '@/assets/css/vue3-pagination.css';

export default {
  props: {
    itemType: {
      type:String,
      required: true,
    },
  },  
  components: {
    VPagination,
  },

  setup(props) {
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const { apiCall_gt } = useApicall();
    const limit = ref(10);
    const offset = ref(0);
    const currentPage = ref(1);     
    const numberOfData = ref(0);
    const numberOfPages = computed(()=>{
      return Math.ceil(numberOfData.value/limit.value)
    });
    
    const myQnaList = ref([]);
    const propsItemType = computed(()=>props.itemType)

    const updateHandler = (e) => {
      getMyCommentList(e);
    }

    const getMyCommentList = async(page = currentPage.value) => {
      currentPage.value = page;
      var offset = (currentPage.value -1) * limit.value;
      var getUrl = `board/counselinglist?limit=${limit.value}&offset=${offset}`

      var qnaDataList = await apiCall_gt(getUrl);

      try {
        if(qnaDataList.dataList == null) qnaDataList.dataList = [];
        myQnaList.value = qnaDataList.dataList;
        numberOfData.value = qnaDataList.dataCount;
      } catch(error) {
        myQnaList.value = [];
        numberOfData.value  = 0;
      }
    }

    const deletCall = async (id) =>{
      var res  =  await apiCall_gt(`board/counselingdelete/${id}`, 'DEL');
      if(res) { 
       // console.log('deletCall', id);
        emitter.emit('EVENT_ALERT',{title:"알림", body:"1:1 문의글이 삭제되었습니다."});
      }
    }
    
    const onDelete = (id) =>{
      deletCall(id);
      updateHandler(1);
    }

    watch(propsItemType, ()=> {      
      if(propsItemType.value == 'qna') {
        updateHandler(1);
      } else {
        return false;
      }
    })

    return{
      limit,
      offset,
      myQnaList,
      numberOfPages,
      currentPage,
      updateHandler,
      onDelete,
    }
  }
}
</script>

<style>

</style>