export default {
    namespaced: true,
    state: {
        toasts: [],        
        toastFcm: [],        
    },
    mutations: {       
        ADD_TOAST(state, payload) {
            state.toasts.push(payload)
        },
        REMOVE_TOAST(state) {
            state.toasts.shift();
        },
        ADD_TOAST_FCM(state, payload) {
            state.toastFcm.push(payload)
        },
        REMOVE_TOAST_FCM(state) {
            state.toastFcm.shift();
        },
    },
    actions: {
        triggerToast({ commit }, payload) {          
            // commit('ADD_TOAST', {
            //     id: Date.now(),
            //     message: payload.message,
            //     type: payload.type,
            // });
           // console.log("aaaaa:", payload)
            commit('ADD_TOAST', {
                id: Date.now(),
                tradeData: payload             
            });

            setTimeout(() => {
                commit('REMOVE_TOAST')                    
            }, 7000)
        },
        triggerToastFcm({ commit }, payload) {
            commit('ADD_TOAST_FCM', {
                id: Date.now(),
                fcmData: payload,
            });

            setTimeout(() => {
                commit('REMOVE_TOAST_FCM')                    
            }, 7000)
        }
    },
    //computed 와 동일한기능..
    getters: {
        // toastMessageWithSmile(state) {
        //     return state.toasts.message + "^^~~~";
        // }
    },
}