<template>
   <HeaderTrade />

      <!-- Container Start { -->
      <div id="container" class="sts-trade-container">
        <!-- Contents Start { -->
        <div id="contents">

          <!-- 모의투자 content start -->
          <div class="trade-content-area">

             <ItemListFutures 
              :items="items"
              @onSelectItem="onSelectItem"
              tradeType="F"
             />

            <!-- 우측 start -->
            <div class="trade-container" :class="isLiveActive?'live':''">
              
              <div class="trade-container-top">

                <div class="trade-top-tab">
                  <!-- tab button -->
                  <ul class="nav nav-tabs" id="tradeTopTabButton" role="tablist">
                    <li class="nav-item" role="presentation">
                       <button class="nav-link"  :class="!isLiveActive?'active':''" @click="topTabClickHandle(false)">
                        모의투자
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                     <button class="nav-link" :class="isLiveActive?'active':''" @click="topTabClickHandle(true)">
                        실시간 방송
                      </button>
                    </li>
                  </ul>

                  <div class="trade-btn-layout">
                    <ul class="layout-list-btn">
                      <li><button type="button" class="btn-layout-search" @click="contsBoxClose('tradeSearchArea')">검색창접기</button></li>
                      <!-- <li><button type="button" class="btn-layout-item" @click="contsBoxClose('tradeResultArea')">검색창/보유종목접기</button></li> -->
                    </ul>
                  </div>

                  <!-- tab contents -->
                  <div class="tab-content" id="tradeTopTabContents">
    
                    <!-- 모의투자 start -->
                    <div  v-show="!isLiveActive" >
                      <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                        <div class="trade-top-contents">
                          <!-- 차트 영역 start -->
                          <ChartContainerFutures
                            :selectItem="selectItem"
                          />
                          <!-- 호가 영역 start -->
                          <HTSContainer 
                            :selectItem="selectItem"                           
                            @change-select-price = "onChangeSelectPrice"
                          />
                          <!-- 매수, 매도 영역 start -->
                          <TradeOrderContainer
                             :selectItem="selectItem"
                             :currentSelectOrderPrice="currentSelectOrderPrice" 
                           />

                      </div>
                    </div>
                    <!-- //모의투자 end -->
    
                    <!-- 실시간 방송 start -->
                    <div v-if="isLiveActive">
                       <LiveIframe 
                        :isActive="isLiveActive"
                       />
                    </div>
                    <!-- //실시간 방송 end -->

                  </div>
                </div>

              </div>

              <div class="trade-container-bottom">
                <!-- 체결 / 손익 start -->
                <div class="trade-bottom-contents">
                  <TradeResultF
                   :items="items"
                   @onSelectItem="onSelectItem"
                   />
                  <LiveList
                   @select-live="onLiveSelect"
                   />

                </div>
                <!-- //체결 / 손익 end -->

              </div>

            </div>
            <!-- //우측 end -->

          </div>
          <!-- //모의투자 content end -->

        </div>
        <!-- } Contents End -->
      </div>
      <!-- } Container End -->
   
    <Footer/>
     <OrderModal :selectItem="selectItem"/>
     <AlertModal />
  
</template>

<script>
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import MicroModal from 'micromodal';
import ItemListFutures from '@/components/trade/stock/ItemListStock.vue';
import ChartContainerFutures from '@/components/trade/futures/ChartContainerFutures.vue';
import HTSContainer from '@/components/trade/HTSContainer.vue';
import TradeOrderContainer from '@/components/trade/TradeOrderContainer.vue';
import TradeResultF from '@/components/trade/TradeResult_f.vue';
import LiveList from '@/components/trade/LivelistRight.vue';
import LiveIframe from '@/components/LiveIframe.vue';
import {ref, getCurrentInstance, onMounted, onUnmounted, onBeforeUnmount, computed, watch} from 'vue';
import { useScoket } from '@/socket';
import {useGetItemList, useSpaceId, useBalance} from '@/composables/vtradeFn';
import { useStore } from 'vuex';
import { useRoute }from 'vue-router';

import OrderModal from '@/components/trade/OrderModal.vue';
import AlertModal from '@/components/trade/AlertModal.vue';


export default {
    components:{   
        HeaderTrade,
        Footer,
        ItemListFutures,
        ChartContainerFutures,
        HTSContainer,
        TradeOrderContainer,
        TradeResultF,
        LiveList,
        OrderModal,
        AlertModal,
        LiveIframe
     
    },
    setup(){
      const route = useRoute();
     // const  trace = getCurrentInstance().appContext.config.globalProperties.$log;
      const isLiveActive = ref(false);
      //var isSession  =  sessionStorage.getItem('isLive_f');  
      //초기데이터 임시
      //var startObj = {CODE: "ADZ22", NAME: "Australian Dollar(2022.12)", KORNAME: "호주달러", TYPE: "F", YEAR:"2022", MONTH:"12"};
      var startObj = null;
      if(sessionStorage.getItem('startItem_F'))
      {
        startObj = JSON.parse(sessionStorage.getItem('startItem_F'));       
      }
      const selectItem = ref(startObj);
     // console.log("selectItem :", selectItem.value)
      const {         
        socket
      } = useScoket(); 
      MicroModal.init();
      const {setCurrentSpaceId, setCurrentSpaceIdForEvent} = useSpaceId();
      const {setCurrentBalance} = useBalance(); 
      const currentTradeType = ref("F");
      const store = useStore();
      const items =  ref([]);  
      const currentSelectOrderPrice = ref(0);      

      const internalInstance = getCurrentInstance(); 
      const emitter = internalInstance.appContext.config.globalProperties.emitter;
      const  {getItemList} = useGetItemList()
      var  prevItem = null;

     // store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_0'); 
     // setCurrentSpaceId(currentTradeType.value);
      const routeId = computed(()=> route.params.id )
      watch(routeId,()=>{
          // console.log("~~~~~~~~~~", routeId.value)
          if(route.params.id != '0')window.location.reload();    
      })
      
      if(route.params.id == '0')
      {       
        store.commit('trade/CHANGE_CURRENT_LEAGUETYPE', 'normal');
        setCurrentSpaceId(currentTradeType.value);
        store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_0'); 
      }
      else
      {      
        store.commit('trade/CHANGE_CURRENT_LEAGUETYPE', 'event');
        setCurrentSpaceIdForEvent(currentTradeType.value, route.params.id);
        store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_eventLeague_trade'); 
      }



      setTimeout(()=>{      
        var t_price = Number(store.state.hts.trade.trade_price);       
        if(!isNaN(t_price))
        {
              currentSelectOrderPrice.value = t_price; 
        }
      }, 1000);

   

      emitter.on('EVENT_INIT_TRADE_FOR_SPACE', (obj) => {
        emitter.off('EVENT_INIT_TRADE_FOR_SPACE');      
        store.commit('common/CHANGE_CURRENT_PAGE', "TRADE");  
        store.commit('hts/CHANGE_TRADE_TYPE', 'F');
        onSelectItem (startObj);
        if(obj == null)
        {
          store.commit('hts/CHANGE_SPACE_ID', null);
        }
        else
        {
          store.commit('hts/CHANGE_SPACE_ID', obj.id);         
          setCurrentBalance(obj.id);        
        }       
      })



      



      const onSelectItem = (item) =>{
        //trace("selectItem:~~~",  item)
        if(isLiveActive.value) 
        {         
          isLiveActive.value = false;
          //sessionStorage.setItem('isLive_f', false);
        }

        // if(isSession == 'true')
        // {     
        //   isLiveActive.value = true;
        //   isSession = 'false';
        // }


        emitter.emit('EVENT_SELECTED_ITEM', item);
        emitter.emit('EVENT_SELECTED_ITEM_TOORDER', item); 
        selectItem.value = item; 
        store.commit('hts/CHANGE_CURRENT_ITEM', item);
        sessionStorage.setItem('startItem_F', JSON.stringify(item));

        if(prevItem != item)
        {
          if(prevItem && prevItem.CODE)
          {
              socket.emit("leaveCode", {
                  itemType:  prevItem.TYPE,
                  code: prevItem.CODE
              }, function() { 
                  // console.log("leaveCode:",  item.CODE)    
              })                  
          }  
          
          initItem(item); 
        }     
        prevItem = item;
   



        // setTimeout(() => {
        //   store.dispatch('hts/getOrderList');
        //   store.dispatch('hts/getAssetList');
        // },500)
      }


      const initItem = (item) =>
      { 
       store.commit('hts/CHANGE_ORDERBOOK', []);
       store.commit('hts/CHANGE_INIT_TEADE', []);


          socket.emit("requestInit", {
            itemType: item.TYPE,
            code: item.CODE,
            isNight:item.isNight?item.isNight:false         
          }, function() {      
          
            setTimeout(()=>{
              socket.emit("requestCode", {
                    itemType:  item.TYPE,
                    code: item.CODE,
                    isNight:item.isNight?item.isNight:false         
                }, function() {                        
              })

              var t_price = Number(store.state.hts.trade.trade_price);       
              if(!isNaN(t_price))
              {
                    currentSelectOrderPrice.value = t_price; 
              }
              store.dispatch('hts/setPossibleOrder');
              
              
            },500)
            emitter.emit('EVENT_INIT_ITEM_HTS');
            emitter.emit('EVENT_INIT_ITEM_HTS_CON');
          })
      }


        // 경고창 호출
      const alertModal = (title, message) =>  {
        document.querySelector('#modal-order-alert .modal-title').innerHTML = title || '알림';
        document.querySelector('#modal-order-alert .modal-box-alert').innerHTML = message || '시스템에 문제가 발생하였습니다.';
        MicroModal.show('modal-order-alert');
      }
       

      onBeforeUnmount(()=>{
        //console.log("onBeforeUnmount")
        isLiveActive.value = false;
      })

      onUnmounted(()=>{
        isLiveActive.value = false;

        if(prevItem && prevItem.CODE)
        {
            socket.emit("leaveCode", {
                itemType:  prevItem.TYPE,
                code: prevItem.CODE,
                isNight:prevItem.isNight?prevItem.isNight:false  
            }, function() { 
                //  console.log("leaveCode:",  item.CODE)    
            })                  
        }  

      })

      onMounted(()=>{
        emitter.emit('EVENT_CHECK_LOGIN');        
        getItemList(currentTradeType.value, items); 
       
        // socket.emit("leaveItemType", {
        // itemType: 'A'   //S(주식),F(선물),C(코인),A(모두)
        // }, function(data) {
        //   trace("종목 모두 닫는다.", data);
        //   // store.dispatch('hts/getOrderList');
        //   // store.dispatch('hts/getAssetList');
        // })     
      })

      const onChangeSelectPrice = (e) =>{

        currentSelectOrderPrice.value = e
        //trace("onChangeSelectPrice: ", e)
      }

      // 검색 아이템 및 하단 결과 닫기 토글
      const contsBoxClose = (id) => {
        setTimeout(()=>{ emitter.emit('EVENT_SCROLL_RESET_HTS')},500);       
        document.getElementById(id).classList.toggle("hidden");
        contsBoxControl();
      }

      // 검색 아이템 및 하단 결과 닫기 토글
      const contsBoxControl = () => {
        let tradeContainer= document.querySelector('.trade-container');
        let tradeLeftArea = document.getElementById('tradeSearchArea');
        let tradeBottomArea = document.getElementById('tradeResultArea');
        let searchLayoutbtn = document.querySelector('.btn-layout-search');
        let layoutItembtn = document.querySelector('.btn-layout-item');

        if ( tradeLeftArea.classList.contains('hidden') ) {
          searchLayoutbtn.classList.add('on');
          tradeContainer.classList.add('w100');
        } else {
          searchLayoutbtn.classList.remove('on');
          tradeContainer.classList.remove('w100');
        }

        if ( tradeBottomArea.classList.contains('hidden') ) {
          layoutItembtn.classList.add('on');
          tradeContainer.classList.add('h100');
          // scrollReset('#tradeArea'); 
        } else {
          layoutItembtn.classList.remove('on');
          tradeContainer.classList.remove('h100');
          // scrollReset('#tradeArea'); 
        }        
      }

      // 모의투자, 라이브 탭 토글
      const topTabClickHandle = (_stt) => {
        isLiveActive.value = _stt;
        //sessionStorage.setItem('isLive_f', _stt);
        if(_stt) 
        {
          if( !localStorage.getItem('isFirstNotice'))
          {
            localStorage.setItem('isFirstNotice', true)
            emitter.emit('EVENT_ALERT',{title:"알림", body:"최초 가입 한정 10렉스를 드립니다! 지금 후원하시고 열혈팬이 되어보세요!"});
          }
        }
      }

      const onLiveSelect = () =>{        
        if(!isLiveActive.value) 
        {
          isLiveActive.value = true
        //  sessionStorage.setItem('isLive_f', true);
        }
      }

      return{
         onSelectItem,
         selectItem,
         currentTradeType,
         currentSelectOrderPrice,
         onChangeSelectPrice,
         alertModal,
         MicroModal,
         contsBoxClose,
         contsBoxControl,
         topTabClickHandle,
         items,
         isLiveActive,
         onLiveSelect
      }
    }
}
</script>

<style>

</style>