<template>
  <!-- <teleport to="#modal">
    <div class="modal fade" >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                      <slot name="title"></slot>
                    </h5>
                    <button type="button" class="close"  aria-label="Close">
                    <span @click="onClose">&times;</span>
                    </button>
                </div>
                <div class="modal-body">                  
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer"></slot>
                     
                </div>
            </div>
        </div>
    </div>
 </teleport>
 -->




  <teleport to="#modal">
    <div class="modal-wrapper">
        <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                      <slot name="title"></slot>
                    </h5>
                    <button type="button" class="close"  aria-label="Close">
                    <span @click="onClose">&times;</span>
                    </button>
                </div>
                <div class="modal-body">                  
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer"></slot>
                     
                </div>
            </div>
        </div>
    </div>
 </teleport>










</template>

<script>
import {getCurrentInstance} from 'vue';
export default {  
   emits:['close'], 
   setup(){
    const { emit} = getCurrentInstance();
     const onClose = () =>{  
         emit('close');
     }
     return {
         onClose,       
     }
   }

}
</script>

<style scoped>
    .modal-wrapper{
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5)

    }

</style>