<template>
      <HeaderTrade/> 
      <!-- Container Start { -->
      <div id="container">
        <!-- Contents Start { -->
        <div id="contents">

          <!-- content start -->
          <div class="contents-area">

            <h5 class="mb-2">마이페이지</h5>

            <!-- 마이페이지 : 메인 tab start -->
            <div class="section">
              <div class="mypage-main-tab">
                <!-- tab button -->
                <ul class="nav nav-tabs nav-fill" id="mypageMainTabButton" role="tablist" @click="onMyPageTabClick"> 
                  <!-- <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="mypage-contest-tab" data-bs-toggle="tab" data-bs-target="#mypageContestTab" type="button" role="tab" aria-controls="mypageContestTab" aria-selected="true" @click="onMyPageTabClick(0)">
                      모의투자대회 만들기
                    </button>
                  </li> -->
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="mypage-mydata-tab" data-bs-toggle="tab" data-bs-target="#mypageMydataTab" type="button" role="tab" aria-controls="mypageContestTab" aria-selected="true">
                      내 정보
                    </button>
                  </li>
                  <!-- <li class="nav-item" role="presentation">
                    <button class="nav-link" id="mypage-alert-tab" data-bs-toggle="tab" data-bs-target="#mypageAlertTab" type="button" role="tab" aria-controls="mypageAlertTab" aria-selected="true" @click="onMyPageTabClick(1)">
                      알림
                    </button>
                  </li> -->
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="mypage-post-tab" data-bs-toggle="tab" data-bs-target="#mypagePostTab" type="button" role="tab" aria-controls="mypagePostTab" aria-selected="false">
                      나의 게시글
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="mypage-reply-tab" data-bs-toggle="tab" data-bs-target="#mypageReplyTab" type="button" role="tab" aria-controls="mypageReplyTab" aria-selected="false">
                      나의 댓글
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="mypage-bookmark-tab" data-bs-toggle="tab" data-bs-target="#mypageBookmarkTab" type="button" role="tab" aria-controls="mypageBookmarkTab" aria-selected="false">
                      북마크
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="mypage-qna-tab" data-bs-toggle="tab" data-bs-target="#mypageQnaTab" type="button" role="tab" aria-controls="mypageQnaTab" aria-selected="false">
                      1:1문의
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="mypage-report-tab" data-bs-toggle="tab" data-bs-target="#mypageReportTab" type="button" role="tab" aria-controls="mypageReportTab" aria-selected="false">
                      신고내역
                    </button>
                  </li>
                </ul>

                <!-- tab contents -->
                <div class="tab-content" id="mypageMainTabContents">

                  <!-- 내정보/변경/탈퇴 start -->
                  <div class="tab-pane fade show active" id="mypageMydataTab" role="tabpanel" aria-labelledby="mypage-mydata-tab">
                    <div class="mypage-main-inner pb-4">

                      <!-- 내 정보 -->
                      <div class="mypage-mydata-inner">
                        <!-- <p class="fs-6">내 정보</p> -->

                        <div class="mydata-inner-box">
                          <label for="uId" class="form-label">아이디</label>
                          <div class="col2">
                            <input type="text" class="form-control" id="uId" readonly="readonly" :value="myInfoData.MID">
                            <a href="javascript:;" class="btn btn-change ms-2" @click="onRecommendUrl">추천인 URL복사</a>
                          </div>
                        </div>

                        <div class="mydata-inner-box">
                          <label for="uName" class="form-label">이름</label>
                          <input type="text" class="form-control" id="uName" readonly="readonly" :value="myInfoData.NAME">
                        </div>

                        <div class="mydata-inner-box">
                          <label for="nName" class="form-label">닉네임</label>
                          <div class="col2">
                            <input type="text" class="form-control" id="nName" readonly="readonly" :value="myInfoData.NICK">
                            <button type="button" class="btn btn-change ms-2" data-bs-toggle="modal" data-bs-target="#modalChangeNickname">닉네임변경</button>
                          </div>
                        </div>

                        <div class="mydata-inner-box">
                          <label for="uPhone" class="form-label">휴대전화</label>
                          <input type="text" class="form-control" id="uPhone" readonly="readonly" :value="myInfoData.PHONE">
                        </div>

                        <div class="mydata-inner-box">
                          <label for="uEmail" class="form-label">이메일</label>
                          <input type="email" class="form-control" id="uEmail" readonly="readonly" :value="myInfoData.EMAIL">
                        </div>

                        <div class="mydata-inner-box">
                          <label for="bankNum" class="form-label">나의 계좌</label>
                          <div class="col2 bank-info">
                            <input type="text" class="form-control bank-input" id="bankNum1" readonly="readonly" :value="myInfoData.ACCOUNT_NAME">
                            <input type="text" class="form-control bank-input" id="bankNum2" readonly="readonly" :value="myInfoData.BANK_NAME">
                            <input type="text" class="form-control bank-input" id="bankNum3" readonly="readonly" :value="myInfoData.BANK_ACCOUNT">
                            <button type="button" class="btn btn-change" data-bs-toggle="modal" data-bs-target="#modalChangeBank" @click="onCheckBankData">계좌정보 변경</button>
                          </div>
                          <p class="fs-12 mt-2">&#42; 가입자와 상금 수령자 정보가 상이할 경우 상금 지급이 되지 않습니다</p>
                        </div>

                        <!-- <div class="mydata-inner-box">
                          <label for="zipcode" class="form-label">주소</label>
                          <div class="mb-2"><input class="form-control w-70" type="text" id="zipcode" placeholder="우편번호" readonly="readonly"></div>
                          <div class="mb-2"><input class="form-control" type="text" id="addr1" placeholder="주소" readonly="readonly" :value="myInfoData.ADDR1"></div>
                          <input class="form-control" type="text" id="addr2" placeholder="나머지 주소" readonly="readonly" :value="myInfoData.ADDR2">                          
                        </div> -->
<!-- 
                        <div class="mydata-inner-box">
                          <p class="form-p-txt">마케팅 활용동의</p>
                          <div class="chk-box">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="chk1" ref="chekEM" readonly="readonly">
                              <label class="form-check-label" for="chk1">이메일</label>
                            </div>
                            <div class="form-check mt-3">
                              <input class="form-check-input" type="checkbox" value="" id="chk2" ref="chekSM" readonly="readonly">
                              <label class="form-check-label" for="chk2">SMS</label>
                            </div>
                          </div>
                        </div> -->

                        <!-- <div class="mydata-btn-box d-flex mt-4">
                          <button class="btn btn-light w-50 border text-black-50" type="button" @click="onClickRemoveProfile">회원탈퇴</button>
                          <button class="btn btn-dark w-50 ml-2" type="button" @click="onClickModifyProfile">회원정보 변경</button>
                        </div> -->
                      </div>
                    </div>
                    <div class="mypage-main-null" style="display:none;">정보를 가져오지 못했습니다.</div>
                  </div>

                  <!-- 나의 게시글 start -->
                  <div class="tab-pane fade" id="mypagePostTab" role="tabpanel" aria-labelledby="mypage-post-tab">
                    <MyPageBoard
                      :itemType="itemType"
                    />
                  </div>

                  <!-- 나의 댓글 start -->
                  <div class="tab-pane fade" id="mypageReplyTab" role="tabpanel" aria-labelledby="mypage-reply-tab">
                    <MyPageReply
                      :itemType="itemType"
                    />                    
                  </div>

                  <!-- 북마크 start -->
                  <div class="tab-pane fade" id="mypageBookmarkTab" role="tabpanel" aria-labelledby="mypage-bookmark-tab">
                    <MyPageBookmark
                    :itemType="itemType"
                  />
                  </div>                  

                  <!-- QnA start -->
                  <div class="tab-pane fade" id="mypageQnaTab" role="tabpanel" aria-labelledby="mypage-qna-tab">
                    <MyPageQna
                      :itemType="itemType"
                    />  
                  </div>

                  <!-- QnA start -->
                  <div class="tab-pane fade" id="mypageReportTab" role="tabpanel" aria-labelledby="mypage-report-tab">
                    <MyPageReport
                      :itemType="itemType"
                    />  
                  </div>

                </div>              
              </div>              

            </div>
            <!-- //마이페이지 : 메인 tab end -->

          </div>
          <!-- //content end -->  

          <!-- aside start -->
          <div class="aside-area">

            <MyLoginSide />

            <!-- <div class="section aside-mypage-benefits">
              <div class="inner-text">
                <p>훌룡합니다! 플렉스그라운드와 오랫동안 함께하셨군요?</p>
                <a href="#">지금 바로 커뮤니티 활동 보상을 확인하세요!</a>
              </div>
              <div class="inner-gift">
                <p class="title">커뮤니티 활동 보상 LV.100</p>
                <p class="nickname">FLEX헤미넴</p>
                <a href="javascript:;">뭐가 들어있을까? 빨리 열어보자!</a>
              </div>
            </div> -->

            <div class="section aside-mypage-bottom">
              <a href="javascript:;" class="btn btn-outline-dark" @click="onClickModifyProfile">내 정보 변경하기</a>
              <a href="javascript:;" class="btn btn-light w-100 border" type="button" @click="onClickRemoveProfile">회원탈퇴</a>
              <!-- <a href="javascript:;" class="btn btn-indigo">플렉스그라운드 PRO 신청하기</a> -->
            </div>


          </div>
          <!-- //aside end -->

        </div>
        <!-- } Contents End -->

      </div>
      <!-- } Container End -->
      <Footer/>
      

      <!-- 레이어팝업 - popup : view > 닉네임변경 -->
      <div class="modal fade" id="modalChangeNickname" aria-labelledby="change-nickname" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="change-nickname">닉네임변경</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>        
            <div class="modal-body">
              <div class="mydata-inner-box">
                <div class="col2">
                  <input type="text" class="form-control" id="nNameC"  placeholder="닉네임변경"  v-model="txt_nick" >
                  <button type="button" class="btn btn-change ms-2" @click="onNickCheck" >중복확인</button>
                </div>
                <span class="txt-val text-danger" v-if="!isNickCheck">* 닉네임 중복확인을 해주세요.</span>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
              <button type="button" class="btn btn-danger" data-bs-dismiss="" v-if="!isNickCheck" @click="onChangeNick">변경하기</button>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal" v-else @click="onChangeNick">변경하기</button>
            </div>
          </div>
        </div>
      </div>

      <!-- 레이어팝업 - popup : view > 계좌정보입력 -->
      <div class="modal fade" id="modalChangeBank" tabindex="-1" aria-labelledby="change-bank" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="change-bank">계좌정보입력</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="modalBankAddClose"></button>
            </div>
            <div class="modal-body">
              <div class="mydata-inner-box">
                <div class="bank-info">
                  <table class="table">
                    <colgroup>
                      <col style="width:30%">
                      <col style="width:70%">
                    </colgroup>
                    <tbody>
                    <tr>
                      <th><label for="bankName" class="form-label">은행명</label></th>
                      <td>
                        <select class="form-select bank-select" id="bankName" aria-label="계좌정보 입력" ref="bankSeletBox">
                          <optgroup label="은행">
                            <option
                              v-for="(item, index) in listTypeBank"      
                              :key = index
                              :value="item.NAME"
                              >
                              {{ item.NAME }}         
                            </option>
                          </optgroup>
                          <optgroup label="상호금융기관">
                            <option
                              v-for="(item, index) in listTypeMutual"      
                              :key = index
                              :value="item.NAME" 
                              >
                              {{ item.NAME }}
                            </option>
                          </optgroup>
                          <optgroup label="금융투자회사">
                            <option                 
                              v-for="(item, index) in listTypeInvestment"      
                              :key = index
                              :value="item.NAME" 
                              >
                              {{ item.NAME }}         
                            </option>
                          </optgroup>
                        </select>                        
                      </td>
                    </tr>
                    <tr>
                      <th><label for="bankNum4" class="form-label">예금주명</label></th>
                      <td>
                        <input type="text" class="form-control bank-input w-100" id="bankNum4" v-model="accountName" @input="onAccountNameCheck">                   
                      </td>
                    </tr>
                    <tr>
                      <th><label for="bankNum5" class="form-label">계좌번호</label></th>
                      <td>
                        <input type="text" class="form-control bank-input w-100" id="bankNum5" v-model="bankAccount" @input="onbankAccountCheck">
                        <span class="txt-val text-danger">* 계좌번호는 - 을 제외한 숫자만 입력하여 주세요.</span>              
                      </td>
                    </tr>
                    <!-- <tr>
                      <td colspan="2">
                        <button type="button" class="btn btn-change">계좌 확인</button>  
                      </td>
                    </tr> -->
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
              <button type="button" class="btn btn-danger" @click="onBankAdd">등록하기</button>
            </div>
          </div>
        </div>
      </div>

</template>

<script>
 import { useStore } from 'vuex';
//  import { useRouter }from 'vue-router'
 import {  ref, onMounted, getCurrentInstance} from 'vue';
import {useApicall, useCheckLogin} from '@/composables/commonFn';
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import MyLoginSide from '@/components/myPage/MyLogin_side.vue';
import MyPageBoard from '@/components/myPage/MyPageBoard.vue';
import MyPageReply from '@/components/myPage/MyPageReply.vue';
import MyPageBookmark from '@/components/myPage/MyPageBookmark.vue';
import MyPageQna from '@/components/myPage/MyPageQna.vue';
import MyPageReport from '@/components/myPage/MyPageReport.vue';
// import Config from '@/config.js';
import Config from '../../public/config.js';


export default {
  components:{   
    HeaderTrade,
    Footer,  
    MyLoginSide,
    MyPageBoard,
    MyPageReply,
    MyPageBookmark,
    MyPageQna,
    MyPageReport
    // Datepicker,
 },
  setup(){
    //const trace = getCurrentInstance().appContext.config.globalProperties.$log;

    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    const store = useStore();
    store.commit('common/CHANGE_CURRENT_PAGE', 'MyPage');
    store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_myPage'); 
   
    const txt_nick = ref('')
    const { apiCall_g, apiCall_pt } = useApicall();
    const {getMember} = useCheckLogin();

    const date = ref();
    const itemType = ref('mydata');
 
    const myInfoData= ref({});
    const chekEM = ref(null);
    const chekSM = ref(null);
   
    const bankSeletBox = ref('KDB산업은행');
    const listTypeBank = ref([]);
    const listTypeInvestment = ref([]);
    const listTypeMutual = ref([]);
    const bankAddItem = ref([]);
    const accountName = ref('');
    const bankAccount = ref('');
    const modalBankAddClose = ref(null);

    var isNickCheck = ref(false);
    // mypage 내정보 리스트
    const getMyInfoData = async() =>{ 
      var result = await  getMember();     
      if(!result) result = {};
      myInfoData.value = result;
      // if( myInfoData.value.AGREE_EM=='Y')
      // {
      //   chekEM.value.checked = true;
      // }
      // else
      // {
      //    chekEM.value.checked = false;
      // }

      // if( myInfoData.value.AGREE_SMS=='Y')
      // {
      //   chekSM.value.checked = true;
      // }
      // else
      // {
      //    chekSM.value.checked = false;
      // }
    }

    const onMyPageTabClick = (e) => {
      itemType.value = e.target.id.split('-')[1];
      // console.log('itemType.value=========>', itemType.value);
    }

    const getMypageTabClass = () => {
      var mypageTabButtons = document.querySelectorAll('.mypage-main-tab .nav-link');
      mypageTabButtons.forEach((tabBtn) => {
        if(tabBtn.classList.contains('active')) {
          tabBtn.classList.remove('active');
        }
        mypageTabButtons[0].classList.add('active');          
      });
    }

    const onClickModifyProfile = () =>{
           let token = sessionStorage.getItem('token');   
           location.href = Config.URL.flexLifeUrl + `modifyProfile?token=${token}&app=money&redirect_url=${Config.URL.flexMoneyUrl}`;
    }

    const onClickRemoveProfile = () =>{
         let token = sessionStorage.getItem('token');   
         location.href = Config.URL.flexLifeUrl + `removeProfile?token=${token}&app=money&redirect_url=${Config.URL.flexMoneyUrl}`;
    }

    onMounted(() => {
      getMyInfoData()
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      date.value = [startDate, endDate];
      getMypageTabClass();
    })


    const  nick_check = () => {
      var nick = txt_nick.value;      
      var pattern2 = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]{2,7}$/;
      if(!nick){
        emitter.emit('EVENT_ALERT',{title:"알림", body:"닉네임을 입력하세요"});
        return false;
      } else if(!pattern2.test(nick)){
         txt_nick.value = '';
         emitter.emit('EVENT_ALERT',{title:"알림", body:"닉네임은 영문, 숫자, 한글 2~7자로 구성하여야 합니다."});
         return false;
      }else{
         return true;
      }  
    }

    const onNickCheck = async() =>{
          var data
          if(nick_check) 
          {
             data = await apiCall_g(`member/nickcheck?nick=${txt_nick.value}`, (message)=>{ emitter.emit('EVENT_ALERT',{title:"알림", body:message})})
             if(data)
             {
                   isNickCheck.value = true;
                   emitter.emit('EVENT_ALERT',{title:"알림", body:"사용가능한 닉네임 입니다."});
                  //  alert("사용가능한 닉네임 입니다.");
             }
             else
             {
                 isNickCheck.value = false;
             }
          }
    }

   const onChangeNick = async()=>{
         
         if(!isNickCheck.value)
         {
            emitter.emit('EVENT_ALERT',{title:"알림", body:"중복확인 후 변경바랍니다."});
            return;
         }
         var data = await apiCall_pt(`loginmember/nickmodify`, { "nick": txt_nick.value }, "PUT" )
         if(data)
         {
              getMyInfoData();
              emitter.emit('EVENT_ALERT',{title:"알림", body:"닉네임이 변경 되었습니다."});
         }
   }

   const onCheckBankData = () => {
      if( myInfoData.value.BANK_ACCOUNT || myInfoData.value.ACCOUNT_NAME ) {
        bankSeletBox.value.value = myInfoData.value.BANK_NAME
        accountName.value = myInfoData.value.ACCOUNT_NAME
        bankAccount.value = myInfoData.value.BANK_ACCOUNT
      }
      getBankList();
    }

   const getBankList = async () => {
      var res  =  await apiCall_g(`index/getbanklist`);
      var result = res.dataList;

      if(result) {
        listTypeBank.value = [];
        listTypeInvestment.value = [];
        listTypeMutual.value = [];

        result.forEach(el => {
          if(el.TYPE == 'bank') {
            listTypeBank.value.push(el);
          } else if(el.TYPE == 'investment') {
            listTypeInvestment.value.push(el);
          } else {
            listTypeMutual.value.push(el);
          }
        });

        listTypeBank.value.reverse()
        listTypeInvestment.value.reverse()
        listTypeMutual.value.reverse()
      }
    }

    const onBankAdd = async() => {
      if(!accountName.value) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"예금주명을 입력하여주십시오."});
        return false;
      }
      if(!bankAccount.value) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"계좌번호를 입력하여주십시오."});
        return false;
      }

      var params = {
          "bankName": bankSeletBox.value.value,
          "bankAccount":bankAccount.value,
          "accountName":accountName.value,
      }
      var res = await apiCall_pt(`/loginmember/bankaccountmodify`, params, 'PUT');

      if(res){
        emitter.emit('EVENT_ALERT',{title:"알림", body:"계좌정보가 입력되었습니다."});
        bankSeletBox.value.value = 'KDB산업은행'
        bankAccount.value = ''
        accountName.value = ''
        modalBankAddClose.value.click();
      } else {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"계좌정보 입력이 실패하였습니다.\n 다시 작성하여 주십시오."});
      }

      getMyInfoData();
    }

    // 예금주명 : 한글, 영문, 숫자
    const onAccountNameCheck = () => {
      var regexp = /[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g;
      if ( regexp.test(accountName.value) ) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"한글, 영문만 입력 가능합니다."});
        accountName.value = '';
      }
      return;
    }

    // 계좌번호 : 숫자만
    const onbankAccountCheck = () => {
      var regexp = /[^0-9]/gi;
      if ( regexp.test(bankAccount.value) ) {
        emitter.emit('EVENT_ALERT',{title:"알림", body:"숫자만 입력 가능합니다."});
        bankAccount.value = '';
      }
      return;
    }

    const onRecommendUrl = () => {
        var copyText = document.createElement("textarea");

        document.body.appendChild(copyText);
        copyText.value = Config.URL.flexMoneyUrl + '?recommendMid=' +  myInfoData.value.MID;
        copyText.select();
        document.execCommand('copy');
        document.body.removeChild(copyText);

        // console.log('onRecommendUrl:', myInfoData.value.MID)

      emitter.emit('EVENT_ALERT',{title:"알림", body:"URL 코드를 복사했습니다."})
    }


    return{
      date,
      myInfoData,
      chekEM,
      chekSM,
      onMyPageTabClick,
      onClickModifyProfile,
      onClickRemoveProfile,
      itemType,
      onNickCheck,
      txt_nick,
      onChangeNick,
      isNickCheck,
      bankSeletBox,      
      listTypeBank,
      listTypeInvestment,
      listTypeMutual,
      onBankAdd,
      bankAddItem,
      bankAccount,
      accountName,
      onCheckBankData,
      modalBankAddClose,
      onAccountNameCheck,
      onbankAccountCheck,
      onRecommendUrl,
      // checkNumber,
    }
  }
}
</script>

<style>

</style>