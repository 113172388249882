<template>
    <div class="trade-result-area" id="tradeResultArea">

      <div class="trade-result-tab">
        <!-- tab button -->
        <ul class="nav nav-tabs" id="tradeResultTabButton" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="trade-result-position-tab" data-bs-toggle="tab" data-bs-target="#tradeResultPositionTab" type="button" role="tab" aria-controls="tradeResultPositionTab" aria-selected="false">
              포지션
              <span>({{currentAssetList.length}})</span>
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="trade-result-close-tab" data-bs-toggle="tab" data-bs-target="#tradeResultCloseTab" type="button" role="tab" aria-controls="tradeResultCloseTab" aria-selected="false" @click="onClosePolTap">
              마감손익
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="trade-result-outstanding-tab" data-bs-toggle="tab" data-bs-target="#tradeResultOutstandingTab" type="button" role="tab" aria-controls="tradeResultOutstandingTab" aria-selected="false">
              미체결 주문
              <span>({{currentOrderList.length}})</span>
            </button>
          </li>                              
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="trade-result-hold-Tab" data-bs-toggle="tab" data-bs-target="#tradeResultHoldTab" type="button" role="tab" aria-controls="tradeResultHoldTab" aria-selected="true"  @click="onOrderHistory">
              주문내역
            </button>
          </li>
        </ul>

        <!-- tab contents -->
        <div class="tab-content" id="tradeResultTabContents">

          <!-- 포지션 start -->
          <div class="tab-pane fade show active" id="tradeResultPositionTab" role="tabpanel" aria-labelledby="trade-result-position-tab">
            <div class="trade-bottom-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

            <!-- 포지션 상단 시작 -->
            <div class="trade-result-summary">
              <table class="table">
                <colgroup>
                  <col style="width:12%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:12%">
                  <col style="width:12%">
                  <col style="width:10%">
                  <col style="width:9%">
                  <col style="width:5%">
                </colgroup>
                <tr>
                  <th>계약</th>
                  <th>수량</th>
                  <th>주문금액(USDT)</th>
                  <th>진입가(USDT)</th>
                  <th>강제 청산 가격(USDT)</th>
                  <th>포지션 증거금(USDT)</th>
                  <th>미실현 손익(%)</th>
                  <th>실현손익(USDT)</th>
                  <th>청산</th>
                  <th>공유하기</th>
                </tr>
              </table>
            </div>
            <!-- //포지션 상단 종료 -->

            <!-- 포지션 내용 start -->
            <!-- <div class="trade-result-tbl" id="tradeResultPosition"> -->
            <perfect-scrollbar 
                @ps-scroll-y="onScroll" 
                :options="options"
                :watch-options="watcher"
                ref="scrollbar"
                class="trade-result-tbl" id="tradeResultPosition"
            >     

              <table class="table t-center">
                <colgroup>
                  <col style="width:12%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:12%">
                  <col style="width:12%">
                  <col style="width:10%">
                  <col style="width:9%">
                  <col style="width:5%">
                </colgroup>
                <tbody>
                  <tr
                      v-for="AssetItem  in currentAssetList"
                      :key = AssetItem.ID 
                  >
                    <td>
                      <span :class="getLongShortColor(AssetItem.TRADETYPE)">
                        <span>{{AssetItem.NAME}}</span><br>
                        <strong>Isolated {{ AssetItem.LEVERAGE?parseInt(AssetItem.LEVERAGE):'1'}}x</strong>
                      </span>
                    </td>
                    <td>{{Constant.UTIL.numberWithCommas(Number(AssetItem.COUNT))}}</td>
                    <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(Number(AssetItem.PRICE)*Number(AssetItem.COUNT), 4))}}</td>
                    <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.PRICE, getNdotpos(AssetItem.CODE)))}}</td>
                    <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.LQPRICE, getNdotpos(AssetItem.CODE)))}}</td>
                    <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.ASSET_MARGIN, 2))}}</td>
                    <td :class="getCssColor(AssetItem._unrealizedPL)">
                      <span class="unrealized">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem._unrealizedPL, 2))}}</span><br>
                      <span class="unrealized">({{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem._unrealizedPLPer, 2))+ '%'}})</span><br>
                      <!-- <span>= 162,222,555</span> -->
                    </td>
                    <td :class="getCssColor(Number(AssetItem.TPOL))">
                      <span class="today-price">{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(AssetItem.TPOL, 2))}}</span>
                    </td>
                    <!-- <td>                                    
                      <button type="button" class="btn btn-sm btn-outline-dark btn-coinf" data-bs-toggle="modal" data-bs-target="#modalTpsl">설정</button>
                    </td> -->
                    <td>
                      <button type="button" class="btn btn-sm btn-dark btn-coinf"  @click="onFositionOut_M(AssetItem)">시장가 정리</button>
                    </td>
                    <td class="bth-share"><button type="button" class="btn" @click="cpModal(AssetItem)"><img src="@/assets/images/trade/ico_share.png" alt="공유하기"></button></td>
                  </tr>
                </tbody>
              </table>
            <!-- </div> -->
             </perfect-scrollbar>
            <!-- //포지션 내용 end -->

            <!-- 포지션 하단 시작  -->
            <div class="trade-result-summary">
              <table class="table bottom">
                <colgroup>
                  <col style="width:10%">
                </colgroup>
                <tr>
                  <td colspan="11" style="height:35px">
                  </td>
                </tr>
              </table>
            </div>
            <!-- //포지션 하단 종료 -->

          </div>
          <!-- //포지션 end -->

          <!-- 마감손익 start -->
          <div class="tab-pane fade" id="tradeResultCloseTab" role="tabpanel" aria-labelledby="trade-result-close-tab">
            <div class="trade-bottom-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

            <!-- 마감손익 상단 시작 -->
            <div class="trade-result-summary">
              <table class="table">
                <colgroup>
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:15%">
                </colgroup>
                <tr>
                  <th>계약</th>
                  <th>수량</th>
                  <th>진입가(USDT)</th>
                  <th>거래 타입</th>
                  <th>청산가(USDT)</th>
                  <th>마감손익(USDT)</th>
                  <th>주문 종료 타입</th>
                  <th>거래 시간</th>
                </tr>
              </table>
            </div>
            <!-- //마감손익 상단 종료 -->

            <!-- 마감손익 내용 시작 -->
            <!-- <div class="trade-result-tbl" id="tradeResultClose"> -->
            <perfect-scrollbar 
                @ps-scroll-y="onScroll" 
                :options="options"
                :watch-options="watcher"
                ref="scrollbar"
                class="trade-result-tbl" id="tradeResultClose"
            >     
              <table class="table t-center">
                <colgroup>
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:15%">
                </colgroup>
                <tbody>
                  <tr
                      v-for="CloseItem  in closedTradeList"
                      :key = CloseItem.ID 
                  >
                    <td>
                      <span :class="getLongShortColor(CloseItem.TRADETYPE)">
                        <span>{{CloseItem.NAME}}</span><br>
                         <strong>Isolated {{ CloseItem.LEVERAGE?parseInt(CloseItem.LEVERAGE):'1' }}x</strong>
                      </span>
                    </td>
                    <td>{{Constant.UTIL.numberWithCommas(Number(CloseItem.TRADECOUNT))}}</td>
                    <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(CloseItem.ASSETINDEX, getNdotpos(CloseItem.CODE)))}}</td>
                    <td>{{CloseItem.TRADETYPE=='5'?'롱 주문':'숏 주문'}}</td>
                    <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(CloseItem.TRADEINDEX, getNdotpos(CloseItem.CODE)))}}</td>
                    <td :class="getCssColor(Number(CloseItem.TPOL))">
                      <span class="close-result-price">
                       {{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(CloseItem.TPOL, 4))}}
                      </span>
                      <!-- <button type="button" class="btn btn-sm btn-outline-dark btn-coinf" data-bs-toggle="modal" data-bs-target="#modalShareCapture">공유</button> -->
                    </td>
                    <td>{{CloseItem.CALL == 'C'?'거래':'청산'}}</td>
                    <td>                      
                      {{getChangeDateStr(CloseItem.RESULTDATE)}}
                    </td>
                  </tr>
                </tbody>
              </table>
              </perfect-scrollbar>
            <!-- </div> -->
            <!-- //마감손익 내용 end -->

            <!-- 마감손익 하단 시작  -->
            <div class="trade-result-summary">
              <table class="table bottom">
                <colgroup>
                  <col style="width:10%">
                </colgroup>
                <tr>
                  <td colspan="8" style="height:35px"></td>
                </tr>
              </table>
            </div>
            <!-- //마감손익 하단 종료 -->

          </div>
          <!-- //마감손익 end -->

          <!-- 미체결 start -->
          <div class="tab-pane fade" id="tradeResultOutstandingTab" role="tabpanel" aria-labelledby="trade-result-outstanding-tab">
            <div class="trade-bottom-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

            <!-- 미체결 상단 시작  -->
            <div class="trade-result-summary" id="tradeResultOutstanding">
              <table class="table">
                <colgroup>
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:12%">
                  <col style="width:10%">
                </colgroup>
                <tr> 
                  <th>계약</th>
                  <th>수량</th>
                  <th>주문가(USDT)</th>
                  <th>주문 총량</th>
                  <th>주문 타입</th>
                  <th>거래 타입</th>
                  <th>거래 시간</th>
                  <th>주문 취소</th>
                </tr>
              </table>
            </div>
            <!-- //미체결 상단 종료 -->

            <!-- 미체결 내용 start -->
            <!-- <div class="trade-result-tbl"> -->
            <perfect-scrollbar 
              @ps-scroll-y="onScroll" 
              :options="options"
              :watch-options="watcher"
              ref="scrollbar"
              class="trade-result-tbl"
            >     
              <table class="table t-center">
                <colgroup>
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:12%">
                  <col style="width:10%">
                </colgroup>
                <tbody>
                  <tr
                      v-for="OrderItem  in currentOrderList"
                      :key = OrderItem.ID 
                  >
                    <td>
                      <span :class="getLongShortColor(OrderItem.TRADETYPE)">
                        <span>{{OrderItem.NAME}}</span><br>
                        <strong>Isolated  {{ OrderItem.LEVERAGE?parseInt(OrderItem.LEVERAGE):'1'}}x</strong>
                      </span>
                    </td>
                    <td>{{Constant.UTIL.numberWithCommas(Number(OrderItem.REMAINCOUNT))}}</td>
                    <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(OrderItem.ORDERINDEX, parseInt(OrderItem.NDOTPOS)))}}</td>
                    <td>
                      <!-- <span>{{Constant.UTIL.numberWithCommas(Number(OrderItem.ORDERCOUNT))}}</span><br> -->
                      <span>{{Constant.UTIL.numberWithCommas(Number(OrderItem.ORDERCOUNT))}}</span>
                    </td>
                    <td>{{OrderItem.ORDERTYPE == 'L'?'지정가':'시장가'}}</td>
                    <td>{{OrderItem.TRADETYPE == '3'?'롱 주문':'숏 주문'}}</td>
                    <td>{{OrderItem.CREATIONDATE}}</td>
                    <td><button type="button" class="btn btn-sm btn-outline-danger" @click="onOrderCancleModal(OrderItem)">취소</button></td>
                  </tr>
                </tbody>
              </table>
            <!-- </div> -->
             </perfect-scrollbar> 
            <!-- //미체결 내용 end -->
            
            <!-- 미체결하단 시작  -->
            <div class="trade-result-summary">
              <table class="table bottom">
                <colgroup>
                  <col style="width:10%">
                </colgroup>
                <tr>
                  <td colspan="8" style="height:35px"></td>
                </tr>
              </table>
            </div>
            <!-- //미체결 하단 종료 -->

          </div>
          <!-- //미체결 end -->

          <!-- 주문내역 start -->
          <div class="tab-pane fade" id="tradeResultHoldTab" role="tabpanel" aria-labelledby="trade-result-hold-tab">
            <div class="trade-bottom-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

            <!-- 주문내역 상단 시작  -->
            <div class="trade-result-summary">
              <table class="table">
                <colgroup>
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:5%">
                </colgroup>
                <tr> 
                  <th>날짜</th>
                  <th>구분</th>
                  <th>종목</th>
                  <th>수량</th>
                  <th>가격(USDT)</th>
                  <th>수수료(USDT)</th>
                  <!-- <th>주문 타입</th> -->
                  <th>상태</th>
                  <th>실현손익(USDT)</th>
                  <th>공유하기</th>
                </tr>
              </table>
            </div>
            <!-- //주문내역 상단 종료 -->

            <!-- 주문내역 내용 start -->
            <!-- <div class="trade-result-tbl" id="tradeResultHold"> -->
            <perfect-scrollbar 
              @ps-scroll-y="onScroll" 
              :options="options"
              :watch-options="watcher"
              ref="scrollbar"
              class="trade-result-tbl"
            >     
              <table class="table t-center">
                <colgroup>
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:5%">
                </colgroup>
                <tbody>
                  <tr
                      v-for="TradeItem  in allTradeList"
                      :key = TradeItem.ID 
                  >
                    <td>{{getChangeDateStr(TradeItem.ORDERDATE)}}</td>
                    <td>
                      {{getTradeType(TradeItem.TRADETYPE, TradeItem.CALL)}}<br>
                      {{TradeItem.ORDERTYPE == 'L'?'지정가':'시장가'}}
                    </td>
                    <td>
                      <span :class="getLongShortColor(TradeItem.TRADETYPE)">
                        <span>{{TradeItem.NAME}}</span><br>
                        <strong>Isolated {{ TradeItem.LEVERAGE?parseInt(TradeItem.LEVERAGE):'1'  }}x</strong>
                      </span>
                    </td>
                    <td>
                      <!-- <span>{{TradeItem.TRADECOUNT?Constant.UTIL.numberWithCommas(Number(TradeItem.TRADECOUNT)):'-'}}</span><br> -->
                      {{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(TradeItem.ORDERCOUNT, 4))}}
                    </td>
                    <td>{{Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(TradeItem.TRADEINDEX, parseInt(getNdotpos(TradeItem.CODE))))}}</td>
                    <td>{{TradeItem.FEES?Constant.UTIL.numberWithCommas(Constant.UTIL.toFixedNum(TradeItem.FEES, 3)):'-'}}</td>
                    <td>{{getTradeStatus(TradeItem.STATUS)}}</td>
                    <td :class="TradeItem.STATUS == '2'?getCssColor(Number(TradeItem.TPOL)):''">
                        <span class="history-per">
                          <i></i>{{TradeItem.STATUS == '2'?Math.abs(TradeItem.TPOL):'-'}}
                        </span>                      
                    </td>
                    <td v-if="TradeItem.TRADETYPE=='5' || TradeItem.TRADETYPE=='6'" class="bth-share"><button type="button" class="btn" @click="cpModalSell(TradeItem)"><img src="@/assets/images/trade/ico_share.png" alt="공유하기"></button></td>
                  </tr>
                </tbody>
              </table>  
            </perfect-scrollbar>                            
            <!-- </div> -->
            <!-- //주문내역 내용 end -->

            <!-- 주문내역 하단 시작  -->
            <div class="trade-result-summary">
              <table class="table bottom">
                <colgroup>
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:15%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:10%">
                  <col style="width:5%">
                </colgroup>
                <tr>
                  <td colspan="9" style="height:35px"></td>
                </tr>
              </table>
            </div>
            <!-- //주문내역 하단 종료 -->

          </div>
          <!-- //주문내역 end -->

        </div>
      </div>

    </div>
  

    <BasicModal2b
      v-if="showModal2"
      @close="closeModal2" 
      @cancle="closeModal2" 
      @ok="onModalOk2" 
      :titleTxt="txtTitle_alert2"
      :bodyTxt="txtBody_alert2"
    />

    <BasicModal2b
      v-if="showModal"
      @close="closeModal" 
      @cancle="closeModal" 
      @ok="onModalOk" 
      :titleTxt="txtTitle_alert"
      :bodyTxt="txtBody_alert"
    />

    <CaptureModal
      v-if="showModal3" 
      @close="closeModal3" 
      @cancle="closeModal3" 
      @ok="okModal3" 
      :assetData="currentAssetItem"
    />   

    <CaptureModalSell
      v-if="showModal4" 
      @close="closeModal4" 
      @cancle="closeModal4" 
      @ok="okModal4" 
      :assetData="currentOrderHistoryItem"
    />    


</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import axios from '@/axios';
//import { useToast } from '@/composables/toast';
import BasicModal2b from '@/components/popups/BasicModal2b.vue';
import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted, ref, getCurrentInstance, watch} from 'vue';
import Constant from '@/constant.js';
import {useApicall} from '@/composables/commonFn';
import moment from 'moment';
import CaptureModal from '@/components/popups/CaptureModal.vue';
import CaptureModalSell from '@/components/popups/CaptureModalSell.vue';
import { event } from 'vue-gtag'

export default {
 components: {      
   BasicModal2b,
   PerfectScrollbar,
   CaptureModal,
   CaptureModalSell
  },
  props:{
        items:{
            type:Array,
            required: false
        }

    }, 
    emits:['onSelectItem'],
  setup(props,  {emit}){
      // const {         
      //               triggerToast
      //       } = useToast();
    
      const store = useStore(); 
      const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
     // const periodInitDataKeep = computed(()=>store.state.hts.periodInitDataKeep);
      const currentTradeType = computed(()=> store.state.hts.currentTradeType);
      const currentOrderList = computed(()=> store.state.hts.orderList);
      const currentAssetList = computed(()=> store.state.hts.assetlist);
      
      const {apiCall_gt} = useApicall()
      // setTimeout(()=>{
      //  console.log("currentOrderList:", currentOrderList.value.length, currentOrderList.value)
      //  console.log("currentAssetList:", currentAssetList.value)
      // },3000)
      
     // const leverageDatas= ref({}) 
      //console.log( )
      const txtTitle_alert = ref("시장가 정리");
      const txtBody_alert = ref("시장가로 포지션 종료를 원하시면 아래 확인 버튼을 눌러주세요.")


      const txtTitle_alert2 = ref("주문 확인");
      const txtBody_alert2 = ref("주문을 취소 하시겠습니까?")
     
     
      const allTrade = computed(()=> store.state.hts.allTrade);


      const showModal = ref(false);        
      const showModal2 = ref(false); 
      const showModal3 = ref(false);
      const showModal4 = ref(false);

      const  currentAssetItem = ref({})
      const  currentOrderHistoryItem = ref({})

      var intervalId;

      const allTradeList = ref([])
      const closedTradeList = ref([]);
      const dailyProfitData = ref({});

      var orderCancleItem = null;    
      var selectFositionOutAssetData = null;

      const applySpaceList = computed(()=> store.state.hts.applySpaceList);
      const memberData = computed(()=> store.state.user.memberData)

      const getIsApplySpace = (spaceId) =>{
          var  found =    applySpaceList.value.find(element => element.SPACE_ID == spaceId);          
          if(found)
          {
            return true;
          }
          else
          {
            return false;
          }
      }


      const onClickedItemName = (item) =>{

          var t_item = props.items.find(element => element.CODE ==  item.CODE);             
          emit('onSelectItem', t_item);   

        // console.log("onClickedItemName", t_item, item)
      }

      onUnmounted(()=>{
        //  console.log("onUnmounted~~~~~~~~~~~2222")          
          store.dispatch('hts/leaveAssetItems');
          clearInterval(intervalId);
      })
           
      onMounted(()=>{

          store.dispatch('hts/setApplySpace');
          store.commit('hts/CHANGE_ASSETLIST',[]);
          store.commit('hts/CHANGE_ORDERLIST', []);
          setTimeout(()=>{
            var isApply = getIsApplySpace(store.state.hts.currentSpaceId);
            if(isApply)
            {
                store.dispatch('hts/getOrderList');
                store.dispatch('hts/getAssetList')
            }
            else
            {              
               store.commit('hts/CHANGE_ASSETLIST',[]);
               store.commit('hts/CHANGE_TOTAL_POL',{}); 
               store.commit('hts/CHANGE_ORDERLIST', []);
            }     
           // console.log("getIsApplySpace:", isApply)

          }, 2000)

          clearInterval(intervalId);
          intervalId = setInterval(()=>{
              var totalProfit = 0;
             // var totalPrice = 0;
              for(var i=0; i<currentAssetList.value.length ; i++)
              {
                  if(currentAssetList.value[i])
                  {
                      totalProfit += Number(currentAssetList.value[i]._unrealizedPL);
                      //totalProfit += Number(currentAssetList.value[i]._unrealizedPL) *Number(currentAssetList.value[i].COUNT);
                      //totalPrice += (Number(currentAssetList.value[i].PRICE) + Number(currentAssetList.value[i]._unrealizedPL))  *Number(currentAssetList.value[i].COUNT);                           
                  }  
              }
              //console.log("onMounted111:", totalProfit, "totalPrice:", totalPrice )
             // store.commit('hts/CHANGE_TOTAL_PRICE',totalPrice); 
              store.commit('hts/CHANGE_TOTAL_PROFIT',totalProfit); 
          },1000)      
     
          
      })

      const getTradeType = (type, _call) =>{
          switch(type)
          {
              case '3':
                  return "롱 포지션 진입"                        
              case '4':
                  return "숏 포지션 진입"                       
              case '5':
                  return _call == 'C'?'롱 포지션 정리':'롱 포지션 정리(청산발동)'                        
              case '6':
                  return  _call == 'C'?'숏 포지션 정리':'숏 포지션 정리(청산발동)'
          }
      }

      const getTradeStatus = (type) =>{
          switch(type)
          {
              case '1':
                  return "신규"                        
              case '2':
                  return "체결"                       
              case '8':
                  return "거부"                        
              case '9':
                  return "취소"
          }
      }


  


    //  watch(allTrade, ()=>{   
    //     var  tradeItem = allTrade.value; 
    //    // console.log("watch", tradeItem.code)
    //     const found =  currentOrderList.value.find(element => element.CODE ==  tradeItem.code);
    //     if(!found)return; 
    //     found.currentPrice = tradeItem.price;
    //    // console.log("watch~~", found.currentPrice )
    //  })

      watch(allTrade, ()=>{              
          var  tradeItem = allTrade.value;   
          const found_long =  currentAssetList.value.find(element => element.CODE ==  tradeItem.code  && element.TRADETYPE == '3'  ); 
          const found_short =  currentAssetList.value.find(element => element.CODE ==  tradeItem.code  && element.TRADETYPE == '4'); 
          var unrealizedPL; 
          var unrealizedPLPer; 
          var realizedPL; 
          var feeVal;

          if(found_long)
          {
                feeVal = Number(found_long.PRICE) *  Number(found_long.COUNT) * (Number(found_long.FEES)/100);
                unrealizedPL = (Number(tradeItem.price) - Number(found_long.PRICE)) * Number(found_long.COUNT);  
                unrealizedPLPer = unrealizedPL / (Number(found_long.ASSET_MARGIN) + feeVal) * 100;
               // realizedPL =   unrealizedPL - feeVal; 
                realizedPL =   -feeVal; 
               // console.log("unrealizedPL:", unrealizedPL, feeVal)
                found_long._unrealizedPLPer = unrealizedPLPer;
                found_long._unrealizedPL = unrealizedPL;
                found_long._realizedPL = realizedPL;

                found_long.COLOR_CLASS = getCssColor(realizedPL);       
                found_long.currentPrice = tradeItem.price;
          }

          if(found_short)
          {
                feeVal = Number(found_short.PRICE) *  Number(found_short.COUNT) * (Number(found_short.FEES)/100);
                unrealizedPL = (Number(found_short.PRICE) - Number(tradeItem.price)) * Number(found_short.COUNT);  
                unrealizedPLPer = unrealizedPL / (Number(found_short.ASSET_MARGIN) + feeVal) * 100;
                //realizedPL =   unrealizedPL - feeVal;       
                realizedPL =  -feeVal; 
                found_short._unrealizedPLPer = unrealizedPLPer;
                found_short._unrealizedPL = unrealizedPL;
                found_short._realizedPL = realizedPL;

                found_short.COLOR_CLASS = getCssColor(realizedPL);  
                found_short.currentPrice =  tradeItem.price;            
          }
      })


      const getCssColor = (num) => {
          var result = ''
          if(num > 0)
          {
              result = "up" 
          }
          else if(num < 0)
          {
              result = "down"         
          }
          else
          {
              result = ""      
          } 
          return result;
      }



   
    const onDailyProfitList = async()=>{
       // var dateStr = moment(new Date()).format('YYYY-MM-DD');        
       //console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^", store.state.hts.)
        var res = await apiCall_gt(`vspace/getprofitlistbyday?type=${currentTradeType.value}&spaceId=${store.state.hts.currentSpaceId}`);
        dailyProfitData.value = res.dataList;
        
        var prevSumPol = null;
        for(let i= dailyProfitData.value.length -1 ; i>=0; i-- )
        {
          var _data = dailyProfitData.value[i];
          if(prevSumPol)
          {
            if(currentTradeType.value == 'F' )
            {
                _data._sumProfit = prevSumPol +  Number(_data.KF_TPOL) + Number(_data.FF_TPOL);
            }
            else
            {
                 _data._sumProfit = prevSumPol +  Number(_data.BUY_TPOL) + Number(_data.SELL_TPOL);
            }
            
          }
          else
          {
            if(currentTradeType.value == 'F' )
            {
                 _data._sumProfit = Number(_data.KF_TPOL) + Number(_data.FF_TPOL);
            }
            else
            {
               _data._sumProfit = Number(_data.BUY_TPOL) + Number(_data.SELL_TPOL);
            }             
          }         
          prevSumPol = _data._sumProfit;
        }
      }

      const onDailyTradeList = async()=>{
       
        var res = await apiCall_gt(`vspace/gettradelist?type=${currentTradeType.value}&spaceId=${store.state.hts.currentSpaceId}&status=2`);        
       if(res &&  res.dataList )
       {            
            closedTradeList.value = []; 
            allTradeList.value =  res.dataList;
            res.dataList.forEach(element => {
                  if(element.STATUS == '2'){
                      if(element.TRADETYPE == '5' || element.TRADETYPE == '6')
                      {
                        closedTradeList.value.push(element);                   
                      }
                  }
            });
       }       
      }


      


     const onAssetSellSelected = ()=>{
          showModal.value = true;
     }
     const onOrderCencelSelected = ()=>{
          showModal2.value = true;
     }


     const closeModal =()=>{
        showModal.value = false;
        selectFositionOutAssetData = null;
     }

    const closeModal2 =()=>{
        showModal2.value = false;
        orderCancleItem = null;
     }

 
    const onModalOk = async()=>{
        showModal.value = false;
        var token = sessionStorage.getItem('token');   

         var params = {
              spaceId: selectFositionOutAssetData.SPACE_ID,
              type: 'G',
              orderType:'M',
              tradeType : selectFositionOutAssetData.TRADETYPE == '3'?'5':'6',
              code:selectFositionOutAssetData.CODE,                        
              count:selectFositionOutAssetData.COUNT,                                         
          } 

          try{
                const res = await axios.post('vspace/orderregister', params ,{
                    headers: {
                            'token': token,                    
                    }
                });   
                let json =  res.data;                     
                if (json.returnCode == 1) {
                
                    if (json.resultMap.returnVal == 1) {  
                      // triggerToast('주문이 완료되었습니다.');                           
                      store.dispatch('hts/getAssetList')   
                      setTimeout(()=>{
                            emitter.emit('EVENT_UPDATE_AVAILABLE_COUNT');
                      },1000);

                      var gtag_type = null;
                      if(params.tradeType == '5')
                      {
                        gtag_type = 'long_out'
                      }
                      else if(params.tradeType == '6')
                      {
                        gtag_type = 'short_out'
                      }

                    event( 'coinFutures_tranding', { 'ID': memberData.value.MID, 'transaction_type': gtag_type, 'time': moment(new Date()).format('YYYYMMDD HH:mm:ss')});
                    window.fbq('trackCustom', 'coinFutures_tranding', {'ID':  memberData.value.MID,  'transaction_type': gtag_type,'time': moment(new Date()).format('YYYYMMDD HH:mm:ss') });
                   // console.log("event~ :", 'coinFutures_tranding', memberData.value.MID , gtag_type,  moment(new Date()).format('YYYYMMDD HH:mm:ss'));


                     

                    } else {
                        emitter.emit('EVENT_ALERT',{title:"알림", body:json.message});     
                        //triggerToast(json.message, 'danger');                        
                        
                    }
                }
                else if(json.returnCode == -102 )
                {
                    alert("로그인이 필요합니다.");
                }
                else
                {
                  //triggerToast(json.message, 'danger');                     
                }              
          }catch(error){
              //triggerToast('Something went wrong!', 'danger');
              console.log(error);
          } 
     }


   const onOrderCancleModal = (item)=>{
       showModal2.value = true;
       orderCancleItem = item;
   }

    const onOrderCancle = async ()=>{
       showModal2.value = false;
       
      var token = sessionStorage.getItem('token'); 
      var params = {                            
                          type:  currentTradeType.value,
                          orderId:orderCancleItem.ID,       
                    } 
          try{
            
              const res = await axios.post('vspace/ordercancel', params ,{
                  headers: {
                          'token': token,                    
                  }
              });   
              let json =  res.data;                     
              if (json.returnCode == 1) {
              
                  if (json.resultMap.returnVal == 1) {  
                     // triggerToast('주문이 취소가 완료되었습니다.'); 
                      store.dispatch('hts/getOrderList');      
                      setTimeout(()=>{
                            emitter.emit('EVENT_UPDATE_AVAILABLE_COUNT');
                      },1000)
                  } else {
                     // triggerToast(json.message, 'danger'); 
                      emitter.emit('EVENT_ALERT',{title:"알림", body:json.message});                            
                      
                  }
              }
              else if(json.returnCode == -102 )
              {
                  alert("로그인이 필요합니다.");
              }
              else
              {
                //triggerToast(json.message, 'danger');                     
              } 
              
              orderCancleItem = null;
          }catch(error){
              //triggerToast('Something went wrong!', 'danger');
              console.log(error);
          } 
    }

    const onScroll = (e) =>{
          e
          // trace(e.target.scrollTop); 
      }

     const onModalOk2 = ()=>{
            showModal2.value = false;
            if(orderCancleItem)
            {
               onOrderCancle()
            } 

     }
   
      const onFositionOut_M = (item) =>{
        //  console.log(item.TRADETYPE)
          showModal.value = true;
          selectFositionOutAssetData = item;        
      }

      const getNdotpos = (_code) =>{
        var result = 0
        if(store.state.hts.currentItemDatas && store.state.hts.currentItemDatas.length > 0)
        {
            var found = store.state.hts.currentItemDatas.find(element => element.CODE ==  _code); 
            if(found && found.NDOTPOS )
            {
              result = parseInt(found.NDOTPOS);
            }
        }
        // console.log("getNdotpos ", _code , result, store.state.hts.currentItemDatas)
        return result;
      }

      const getleverage = async(_code)=>{       
          var result = 0;           
          if(!store.state.hts.currentAccountId || store.state.hts.currentAccountId == null)return 0; 
          var res = await apiCall_gt(`vspace/getleverage?accountId=${store.state.hts.currentAccountId}&code=${_code}`);     
          if(res)
          {
            
            result = parseInt(res.data.L_LEVERAGE);         
          }  
        //  console.log("result",res  )
          return result 
     }



      
    const getLongShortColor=(_tradeType)=>{ 
      var result = '' 
      if(_tradeType == '3' || _tradeType == '5')
      {
            result = 'long'
      }
      else if(_tradeType == '4' || _tradeType == '6')
      {
           result = 'short'
      }
       
      return result;
     }



    const getChangeDateStr=(str)=>{  
       return moment(str, "YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss");
     }

    const onClosePolTap = () =>{
           onDailyTradeList();
    }

    const onOrderHistory = () =>{
       onDailyTradeList()
    }

    const cpModal = (item) => {
      currentAssetItem.value = item
      showModal3.value = true; 
    }

    const okModal3 = () => {
      showModal3.value = false; 
    }

    const closeModal3 = () => {
      showModal3.value = false; 
    }  

    const cpModalSell = (item) => {
      currentOrderHistoryItem.value = item;
      showModal4.value = true; 
    }

    const okModal4 = () => {
      showModal4.value = false; 
    }

    const closeModal4 = () => {
      showModal4.value = false; 
    }   



     return{
          options: {       
                wheelSpeed: 1,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true,
          },
          watcher: true,
          currentOrderList,
          currentAssetList,
          Constant,
          getTradeType,
        
         // periodInitDataKeep,
         
      
          onAssetSellSelected,
          closeModal,
          onModalOk,
          showModal,
          txtTitle_alert,
          txtBody_alert,
          showModal2,
          txtTitle_alert2,
          txtBody_alert2,
          closeModal2,
          onModalOk2,
          onOrderCencelSelected,
          onOrderCancleModal,
          currentTradeType,
       
          closedTradeList,
          onScroll,
          onDailyProfitList,
          dailyProfitData,
          getChangeDateStr,
          onClickedItemName,
          getleverage,
          onFositionOut_M,
          getNdotpos,
         // leverageDatas,
          allTradeList,
          getTradeStatus,
          getLongShortColor,
          getCssColor,
          onClosePolTap,
          onOrderHistory,
          cpModal,
          showModal3,
          closeModal3,
          okModal3,         
          currentAssetItem,
          cpModalSell,
          currentOrderHistoryItem,
          showModal4,
          closeModal4,
          okModal4,  
         
     }
  }

}
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css">

</style>