

module.exports = {
    
   
   
    UTIL: {
        numberWithCommas: (x) => {
            //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");            
            if(x == null)return  '';
            if(isNaN(x))return 0
           // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

            const parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        },
        emailIsValid: (email) => {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        },
        midValid: (mid) => {
            let returnVal = true;

            //영문숫자만
            var mid_rep = /^[A-Za-z0-9+]*$/;
            if (!mid_rep.test(mid)) {
                returnVal = false;
            } else if (mid.length < 4 || mid.length > 20) {
                returnVal = false;
            }
            return returnVal;
        },
        removeTrash: (str) => {
            return ('' + str).replace(/\0/g, '')
        },
        getUpdownColor: (num) =>{
            //console.log("getUpdownColor : ~~", num)
            var result = Number(num)
            if(num > 0)
            {
               result = 'up'
            }
            else if(num < 0){
              result = 'down'
            }
            else
            {
               result = ''
            }           
            return result;
        },
        getPlusMinus: (num) =>{
            var result = Number(num)
            if(num > 0)
            {
               result = '+'
            }
            else if(num < 0){
              result = '-'
            }
            else
            {
               result = ''
            }           
            return result;
        },
        fillZero(width, str){         
            return str.length >= width ? str:new Array(width-str.length+1).join('0')+str;//남는 길이만큼 0으로 채움
        },
        toFixedNum:(value, fixNum)=>{
            var  result = 0;
            var num = Number(value);
            var fixed = parseInt(fixNum);
 
            if(!num || isNaN(num)) return result;
            if(!fixed || isNaN(fixed)) return result;          
            
            fixed =  Math.pow(10, fixed);
            return Math.floor(Number(num) * fixed) / fixed;
        }


    }

   



}