<template>
  <!-- <input :style="widthPx?`width:${widthPx}px`:'width:110px'" style="text-align:right;"   type="text"  :class="sClass" v-model="sValue"  @keydown="onInputKeydown" @keyup="onInputKeyup"  ref="inputTxt"> -->
  <input  type="text"  :class="sClass" v-model="sValue"  @keydown="onInputKeydown" @keyup="onInputKeyup" @blur="onInputBlur" ref="inputTxt">

</template>

<script>
import {ref, getCurrentInstance, watchEffect} from 'vue';
import Constant from '@/constant.js';

export default {
   props:{
       nValue:{
           type:Number,
           required:true
       },
       sClass:{
           type:String,
           required:false
       },
    //    widthPx:{
    //       type:Number,
    //       required:false
    //    },   
       comma:{
          type:Boolean,
          required:false
       },
       ndotpos:{
          type:Number,
          required:false
       }           
   },
   emits:['updateNvalue'],
   setup(props)
   {       
        const { emit } = getCurrentInstance();
        const inputTxt = ref(null)
        const sValue = ref(0);
        var updateValue = 0;
        var  timeOutId = null;

        const toFixedNum = (value, fixNum )=>{
           var  result = 0;
           var num = Number(value);
           var fixed = parseInt(fixNum);

           if(!num || isNaN(num)) return result;
           if(!fixed || isNaN(fixed)) return result;          
           
           fixed =  Math.pow(10, fixed);
           return Math.floor(Number(num) * fixed) / fixed;
        }

        watchEffect(()=>{
            //console.log("props.nValue~~~~~~~~" , props.nValue)
            if(!props.nValue && props.nValue != 0 )return;
           

                if(props.comma)
                { 
                    if(props.ndotpos)
                    {
                        sValue.value = Constant.UTIL.numberWithCommas(toFixedNum(props.nValue, props.ndotpos));
                    }
                    else
                    {
                        sValue.value = Constant.UTIL.numberWithCommas(Number(props.nValue));
                    }
                }
                else
                {
                    
                    sValue.value = Constant.UTIL.numberWithCommas(Math.floor(props.nValue));
                }
                
           
        })


   
  
        const onInputKeydown = () =>{            
          
            // if(e.keyCode == 13)
            // {
            //     emit('updateNvalue', updateValue);
            // }

   
        }

        const onInputKeyup = (e) => {
           inputNumberFormat(inputTxt.value)
           removeChar(e);
           if(timeOutId)clearTimeout(timeOutId); 
            timeOutId = setTimeout(()=>{
               // console.log("onInputKeyup:", e.keyCode, updateValue, sValue.value)
                if( e.keyCode != 190 && e.keyCode != 110)
                {
                   emit('updateNvalue', updateValue);
                }               
           },1000)    
        }
       //문자 제거
        const removeChar = (event)=>{
              event = event || window.event;
            var keyID = (event.which) ? event.which : event.keyCode;
            if (keyID == 8 || keyID == 46 || keyID == 37 || keyID == 39)
                return;
            else
                //숫자와 소수점만 입력가능
                /* eslint-disable */
                var  pattern;
                if(props.comma)
                {
                   // console.log("comma_true")
                    pattern  = /[^\.0-9]/g; 
                }
                else
                {
                  //  console.log("comma_false")
                    pattern  =  /[^0-9]/g; 
                }    
                event.target.value = event.target.value.replace(pattern, "");
        }



        //콤마 찍기
        const comma = (obj) => {
            /* eslint-disable */
            var regx = new RegExp(/(-?\d+)(\d{3})/);
            var bExists = obj.indexOf(".", 0);//0번째부터 .을 찾는다.
            var strArr = obj.split('.');
            while (regx.test(strArr[0])) {//문자열에 정규식 특수문자가 포함되어 있는지 체크
                //정수 부분에만 콤마 달기 
                strArr[0] = strArr[0].replace(regx, "$1,$2");//콤마추가하기
            }
            if (bExists > -1) {
                //. 소수점 문자열이 발견되지 않을 경우 -1 반환
                obj = strArr[0] + "." + strArr[1].substring(0,5);
            } else { 
                //정수만 있을경우 //소수점 문자열 존재하면 양수 반환 
                obj = strArr[0];
            }
        
            //console.log(obj);
            return obj;//문자열 반환
        }
        //콤마 풀기
        const uncomma = (str)=> {
            str = "" + str.replace(/,/gi, ''); // 콤마 제거 
            str = str.replace(/(^\s*)|(\s*$)/g, ""); // trim()공백,문자열 제거 
            return (new Number(str));//문자열을 숫자로 반환
        }
        //input box 콤마달기
        const inputNumberFormat = (obj) => {
            if(obj.value.length > 14) 
            {
                obj.value = obj.value.substring(0,14); 
            }

            //console.log(obj.value.length)
            obj.value = comma(obj.value); 
            updateValue = uncomma(obj.value.substr(0));
        }
        //input box 콤마풀기 호출
        // const uncomma_call = ()=>{
        //     var input_value = document.getElementById('input1');
        //     input_value.value = uncomma(input_value.value);
        // }


        const onInputBlur = () =>{
          // console.log("onInputBlur:", e)
           inputNumberFormat(inputTxt.value);
           emit('updateNvalue', updateValue);
        }









        

        return{
            onInputKeydown,
            onInputKeyup,
            
            sValue,
            inputTxt,
            onInputBlur
        }


   }
}
</script>

<style>

</style>