<template>
<!-- live list start -->
<div class="trade-live-area">

    <div class="live-sub-container">
        <div class="live-sub-section live-sub-list">

        <!-- list title -->
        <div class="live-sub-header">
            <span>FLEX TV</span>
            <!-- <div><a :href="Config.URL.flextvUrl" target="_blank">바로가기</a></div> -->
        </div>

        <!-- tab area start -->
        <div class="tab-area live-list-tab">
            <ul>
                <li><a href="javascript:;" @click="ontabType('1')" :class="tabType=='1'?'selected':''">Live</a></li>
                <li><a href="javascript:;" @click="ontabType('2')" :class="tabType=='2'?'selected':''">최근시청</a></li>
                <li><a href="javascript:;" @click="ontabType('3')" :class="tabType=='3'?'selected':''">즐겨찾기</a></li>
            </ul>
        </div>
        <!-- tab area end -->

        <!-- search -->
        <div 
            class="broadcast-category"
            v-if="tabType==1"
        >
            <!-- <div class="input">
                <input placeholder="LIVE 검색" type="text"  v-model="searchText" >
                <button class="btn-search-confirm" @click="onDeleteSerchTxt">search</button>                    
            </div> -->
            <!-- 검색 -->
            <div class="search">
                <input type="text" class="form-control" placeholder="LIVE 검색"  v-model="searchText">
                <!-- <button class="btn-search" type="button" ><img src="@/assets/images/common/ico_header_search.png" alt="search"></button> -->

                <button v-if="!searchText"    class="btn-search" type="button"><img src="@/assets/images/common/ico_header_search.png" alt="search"></button>   
                <button v-else class="btn-search" type="button" ><img src="@/assets/images/common/ico_close.png" alt="cancel"   @click="onDeleteSerchTxt"></button> 



                
            </div>            
            
            <div class="select">
                <select class="form-select form-select-sm" @input="onSelcetSort($event)" ref="selectSort">
                    <option value="0">시청인원순</option>
                    <option value="1">최신순</option>
                    <option value="2">랭킹순</option>
                    <option value="3">신입BJ</option>
                </select>
            </div>
        </div>

        <!-- broadcast information -->
        <div class="broadcast-count">
            <div class="count-box" v-if="tabType==1">
                <button class="btn-refresh-new" @click="onRefresh">refresh</button>
                <span>{{'전체방송: '+nowLiveList.length}}</span>
            </div>
            <div class="count-box" v-else>
                <button class="btn-refresh-new" @click="onRefresh">refresh</button>
                <span>{{'전체방송: '+nowLiveList.length}}</span>
            </div>

            
            <div
                class="chk-area"
                v-if="tabType==1"
            >
           
                    <span>19+</span>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="switchchk1" checked @input="onCheckInput($event)" ref="checkAdult">
                    </div>
            </div>
        </div>

        <!-- live list start -->
        <!-- <div class="live-list-area"> -->

        <perfect-scrollbar 
            @ps-scroll-y="onScroll" 
            :options="options"
            :watch-options="watcher"
            ref="scrollbar"
            class="live-list-area"
            :class="tabType == 1 ? 'tab1':''"
        > 

            <div class="broadcast-list">

            <!-- list item start -->

            <div v-if="!filteredItems.length && tabType==1" class="live-list-none">
               검색된 LIVE가 없습니다.
            </div>

            <div class="broadcast-item"
               v-for="(item, index) in filteredItems"
               :key = index
               @click="onItemClicked(item)"
            >
                <div class="img">
                    <img :src="getThumbUrl(item)" style="object-fit: cover" >
                    <img v-if="item.themeId == 7" class="ico-adult" src="@/assets/images/icon/icon_19_plus.png" alt="19연령제한">
                    <img v-else-if="item.isForAdult" class="ico-adult" src="@/assets/images/icon/icon_19.png" alt="19연령제한">                    

                    <!-- 비밀방 표시 아이콘 추가 -->
                    <div class="card-icon-bottom" v-if="item.isLocked || item.barrier.minRatingLevel">
                        <span class="ico-bottom-lock"><img src="@/assets/images/icon/icon_lock.png" alt="icon_lock"></span>
                    </div>


                </div>
                <div class="broadcast-info">
                    <div class="title">{{item.title}}</div>
                    <div class="nickname">
                        <span><span>{{item.owner?item.owner.nickname:item.hero.nickname}}</span></span>
                    </div>
                    <div class="count">
                        <span class="icons users3"></span>
                        <span class="num">{{item.playerCount?item.playerCount:''}}</span>
                    </div>
                </div>
            </div>
            <!-- //list item end -->




            </div>
        </perfect-scrollbar >
        <!-- //live list end -->

        </div>
    </div>
    <!-- //live list end -->
</div>    
</template>

<script>
import Config from '../../../public/config.js';
// import Config from '@/config.js'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { useRouter}from 'vue-router'
import { onMounted, computed, ref, getCurrentInstance} from 'vue';
import { useStore } from 'vuex';
import {useGetChannels} from '@/composables/commonFn';

export default {
    components: { 
         PerfectScrollbar
    },
    emits:['select-live'],
    setup(){
       // const route = useRoute();        
        const router = useRouter();
      
        const store = useStore();
        //1:live 2:최근시청 3:즐겨찾기
        const tabType = ref(1);
        const { emit } = getCurrentInstance()

        const checkAdult = ref(null);
        const selectSort = ref(null);
        const internalInstance = getCurrentInstance(); 
        const emitter = internalInstance.appContext.config.globalProperties.emitter;
       
        const searchText = ref('');
        const {
            getChannels,
            getRecentChannels,
            getSubscribesChannels,           
        } = useGetChannels(); 
        const nowLiveList = computed(()=>store.state.live.liveList)

        const onRefresh = () =>{
            //console.log("onRefresh")
             getChannels( checkAdult.value.checked ,  2, getSortTypeForSelectVal());
        }
        onMounted(()=>{   
            //getChannels("false", "true")
          
            checkAdult.value.checked = true;
            selectSort.value.value =2;
            getChannels( checkAdult.value.checked ,  2, getSortTypeForSelectVal());
        })

        const onCheckInput = () =>{
            //console.log("onCheckInput", e.target)
            getChannels( checkAdult.value.checked , 2, getSortTypeForSelectVal());
        }
        const ontabType = async(type)=>{
          tabType.value = type;
          if(type == 1){
              setTimeout(()=>{
                
                getChannels( checkAdult.value.checked ,  2, getSortTypeForSelectVal()); 
              },500);
          }
          else if(type == 2)
          {

            var isToken =  await getRecentChannels();
            //  console.log("isToken:", isToken)
            if(!isToken)
            {
                  //console.log("isToken:", isToken)
               emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인후 사용가능합니다.", fn:goHome});
            }

             
              
          }
          else if(type == 3)
          {
             

            var isToken2 =  await getSubscribesChannels();          
            if(!isToken2)
            {
                  //console.log("isToken:", isToken2)
                   emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인후 사용가능합니다.", fn:goHome});
            }

          }

         // console.log("route :", route.name)
        }

        const goHome = () =>{
            router.push({
                    name: 'Home'                    
            })   
        }
        

        const getSortTypeForSelectVal = ()=>{
            var result;    
            if(selectSort.value.value == 0)
            {
                result = 3;
            }   
            else if(selectSort.value.value == 1)
            {
                 result = 2;
            }   
            else if(selectSort.value.value == 2)
            {
                 result = 1;
            }
            else if(selectSort.value.value == 3)
            {
                 result = 4;
            }
            return result;

        }
      
        const onSelcetSort = () =>{
            //console.log("onSelcetSort", e.target.value);             
            getChannels( checkAdult.value.checked ,  2, getSortTypeForSelectVal());
        }

        const onItemClicked =(item)=>{
           // console.log("onItemClicked",id );

         if(item.isLocked || item.barrier.minRatingLevel){
            emitter.emit('EVENT_ALERT',{title:"알림", body:"입장제한된 방입니다."});
            return;
         }

            emit('select-live', item.channelId);  
            store.commit('live/CHANGE_CURRENT_LIVE_ID', item.channelId);

            
        }
        
        const onDeleteSerchTxt = () =>{           
            searchText.value = "";            
        }


        const getThumbUrl = (item) =>{
           var result;
           if(item.isForAdult || item.isLocked )
           {
               if(item.owner)
               {
                 result = item.owner.thumbUrl;
               }

               if(item.hero)
               {
                 result = item.hero.thumbUrl;
               }
           }
           else
           {
              result = item.thumbUrl;
           }
           
          // console.log("getThumbUrl item:", result)
            return result;
        }



        const filteredItems = computed(()=>{
          
          if(searchText.value){
              return nowLiveList.value.filter(item => {
                  return  item.title.includes(searchText.value) || (item.owner?item.owner.nickname.includes(searchText.value):item.hero.nickname.includes(searchText.value))
                 //  return  item.title.includes(searchText.value) || item.owner.nickname.includes(searchText.value)
              })
          }          
          return nowLiveList.value;
        });

        const onScroll = (e) =>{
                e
                // trace(e.target.scrollTop); 
        }

        const contsBoxClose = (id) =>{
         emitter.emit('EVENT_ON_OFF_VIEWS', id);        
        }

        return{
          nowLiveList,
          onRefresh,
          tabType,
          ontabType,
          onCheckInput,
           options: {       
                wheelSpeed: 1,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true,
          },
          watcher: true,
          checkAdult,
          onSelcetSort,
          selectSort,
          onItemClicked,
          onDeleteSerchTxt,
          contsBoxClose,
           onScroll,
          filteredItems,
          searchText,
          getThumbUrl,
         
          Config
        }
    }

}
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css">

</style>