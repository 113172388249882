<template>
  <!-- my data -->
                  <div class="rank-mydata"  >
                    <h4><img src="@/assets/images/trade/trade_mydata.png" alt="main" class="mr-2">My 자산현황</h4>
                    <ul class="rank-mydata-box">
                      <li>
                        <dl>
                          <dt>랭킹</dt>
                          <dd><strong class="text-danger">{{myRankData.RANK}}</strong> 위</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>닉네임</dt>
                          <dd>{{myRankData.MEMBER_NICK}}</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>합산손익</dt>
                          <dd><strong class="mydata-profit">{{Constant.UTIL.numberWithCommas(Number(myRankData.TOTAL_PROFIT).toFixed(tradeType=='G'?2:0))}}</strong></dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>총 보유자산</dt>
                          <dd>{{Constant.UTIL.numberWithCommas(Number(myRankData.TOTAL_ASSET).toFixed(tradeType=='G'?2:0))}}</dd>
                        </dl>
                      </li>
                      <li :class="getColor(getProfitPer())">
                        <dl>
                          <dt>수익률</dt>
                          <dd>
                            <span class="per">
                              <i></i>{{ Constant.UTIL.numberWithCommas(Math.abs(getProfitPer().toFixed(2))) + '%' }}
                            </span>
                          </dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>거래일수</dt>
                          <dd>{{ myRankData.TRADEDAYCOUNT? Constant.UTIL.numberWithCommas(myRankData.TRADEDAYCOUNT):"-" }}</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
</template>

<script>
import { computed, onMounted, watch, ref} from 'vue'
import {useApicall} from '@/composables/commonFn';
import Constant from '@/constant.js';
export default {
    props:{
        spaceId:{
          type:String,
          require:false
        },
        tradeType:{
            type:String,
            require:true
        },
        isEventLeague:{
           type:Boolean,
           require:false
        }
    },
    
    setup(props){
        const {apiCall_gt} = useApicall();
        const spaceId = computed(()=>props.spaceId);
        const myRankData = ref({})

        const getMyRankData = async (spaceId) =>{
         //   var res = await apiCall_gt(`vspace/getprofitlistbyday?spaceId=${spaceId}&type=${type}`);            
            var res = await apiCall_gt(`vspace/getmyrank?spaceId=${spaceId}`);
            return res.rankRow;  
        }

        onMounted(async()=>{
          if(props.isEventLeague)
          {             
              if(!props.spaceId)return;
              myRankData.value = await getMyRankData(props.spaceId);

              if(!myRankData.value)
              {
                  myRankData.value = {
                      MEMBER_NAME:"-",
                      RANK:"-",
                      PROFIT:"-",
                      BALANCE:"-"
                  };
              } 
          }
    
          

        })

        watch(spaceId, async()=>{
           
            if(!spaceId.value)return;
             myRankData.value = await getMyRankData(spaceId.value);
            // console.log("spaceId~~~", spaceId.value)
             if(!myRankData.value)
             {
                myRankData.value = {
                    MEMBER_NAME:"-",
                    RANK:"-",
                    PROFIT:"-",
                    BALANCE:"-"
                };
             } 
            // console.log("myRankData.valu~~", myRankData.value)

        })

         const getProfitPer = ( )=>{
               var result = 0
               if(!myRankData.value)return result;              

               var totalProfit = Number(myRankData.value.TOTAL_PROFIT);
              //  var balance =  Number(myRankData.value.BALANCE);
               var paymoney = Number(myRankData.value.PAYMONEY);
            //  console.log("getProfitPer: ", totalProfit, paymoney)
               result = (totalProfit/paymoney)*100;



               return result;
               
         }


         const getColor= (num)=>{
            var  result = ''
           if(num > 0)
           {
             result='up'
           }
           else if(num < 0)
           {
              result='down'
           }
           return result;
         }
    
     
        return{
          props,
          myRankData,
          Constant,
          getProfitPer,
          getColor

        }
    }

}
</script>

<style>

</style>