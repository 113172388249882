import { computed, } from 'vue';
import { useStore } from 'vuex';

export const useToast = () => {

    const store = useStore();
    const toasts = computed(() => store.state.toast.toasts);
    const toastFcm = computed(() => store.state.toast.toastFcm);
    const isAvailable = true;

    // const triggerToast = (message, type = 'success') => {           
    //     if(isAvailable)
    //     {
    //         store.dispatch('toast/triggerToast', { message, type });
    //     }       
    // }
    const triggerToast = (tradeData) => {     
       // console.log("aaaa~~~~~")        
        if(isAvailable)
        {     
          // console.log("aaaa~~~~~", tradeData)      
            store.dispatch('toast/triggerToast', tradeData);
        }       
    }
    const triggerToastFcm = (fcmData) => {
        if(isAvailable)
        {     
        //   console.log("aaaa~~~~~", fcmData)      
            store.dispatch('toast/triggerToastFcm', fcmData);
        }       
    }


    return {
        toasts,
        toastFcm,
        triggerToast,
        triggerToastFcm,
    }

}