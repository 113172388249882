<template>
        <HeaderTrade />
      <!-- Container Start { -->
      <div id="container" class="sts-container">
        <!-- Contents Start { -->
        <div id="contents">

          <!-- 랭킹 리스트 start -->
          <div class="sts-rank-list">

            <div class="rank-list-tab">
              <!-- tab button -->
              <ul class="nav nav-tabs" id="stsRankTabButton" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="rank-stocks-tab" data-bs-toggle="tab"  type="button" role="tab" aria-controls="rankStocksTab" aria-selected="true" @click="onTapClick('S')">
                    주식
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="rank-futures-tab" data-bs-toggle="tab"  type="button" role="tab" aria-controls="rankFuturesTab" aria-selected="false" @click="onTapClick('F')">
                    선물
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="rank-coin-tab" data-bs-toggle="tab"  type="button" role="tab" aria-controls="rankCoinTab" aria-selected="false" @click="onTapClick('C')">
                    코인
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="rank-coinfutures-tab" data-bs-toggle="tab"  type="button" role="tab" aria-controls="rankCoinfuturesTab" aria-selected="false" @click="onTapClick('G')">
                    코인선물
                  </button>
                </li>
              </ul>

              <!-- 검색 -->
              <div class="sts-rank-search w-30" >
                  <!-- <input type="search" class="form-control" placeholder="닉네임을 입력하세요" aria-label="search">
                  <button class="btn-search" type="button"><img src="@/assets/images/common/ico_header_search.png" alt="search"></button>              -->
                  <select class="form-select w-100" ref="rSeletBox" @change="onSelectChange" >
                    <!-- <option value="kstock0" selected>제 1회 주식 모의투자 대회</option>
                    <option value="kstock1">제 2회 주식 모의투자 대회</option> -->
                    <option                 
                     v-for="(item, index) in arrSpaceList"      
                    :key = index
                    :value="item.ID" 
                    >
                      {{item.NAME}}                 
                    </option>

                  </select>                   
              </div>
              
              <!-- tab contents -->
              <div class="tab-content" id="stsRankTabContents">

                <!-- 주식거래 start -->
                <div class="tab-pane fade show active" id="rankStocksTab" role="tabpanel" aria-labelledby="rank-stocks-tab">

                  <!-- <div class="rank-cnt-time">
                    <p class="txt">대회 종료까지 남은 시간</p>
                    <div class="time-box">
                      <div>
                        <span>{{ rankCntDays }}</span>
                        <span>days</span>
                      </div>
                      <div>
                        <span>{{ rankCntHours }}</span>
                        <span>hours</span>                        
                      </div>
                      <div>
                        <span>{{ rankCntMinute }}</span>
                        <span>minute</span>                        
                      </div>
                      <div>
                        <span>{{ rankCntSecond }}</span>
                        <span>second</span>                        
                      </div>
                    </div>
                  </div> -->

                  <MyBoard
                     v-if="isLogin"
                     :spaceId="spaceId"
                     :tradeType="tradeType"
                   />

                  <!-- class 설명 
                      .table > tr >> 보합
                      .table > tr.up  >> 상승
                      .table > tr.down  >> 하락
                  -->

                  <div class="rank-update-time">
                    <span>&#8251; 마지막 업데이트 : <span class="time">{{ getUpdateDate() }}</span></span>
                    <!-- <button type="button" class="btn btn-sm refresh" @click="onTapClick(tradeType)"><img src="@/assets/images/trade/ico_refresh.png"></button> -->
                  </div>

                  <table class="table">
                    <colgroup>
                      <col style="width:8%">
                      <col style="width:20%">
                      <col style="width:16%">
                      <col style="width:16%">
                      <col style="width:16%">
                      <col style="width:8%">
                      <col style="width:16%">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>닉네임</th>
                        <th>총 보유자산</th>
                        <th>합산손익</th>
                        <th>수익률</th>
                        <th>거래일수</th>
                        <th>예상상금</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr 
                          v-for="(item, index) in arrRankingList"      
                          :key = index
                          :class="getColor(Number(getProfitPer(Number(item.TOTAL_PROFIT), Number(item.PAYMONEY))))"
                      >
                        <td scope="row" :class="parseInt(item.RANK)<4?'text-danger':''">{{item.RANK}}</td>
                        <!-- <td>PRO</td> -->
                        <td>
                          <span class="nickname text-truncate">
                            <UserNickGrade
                              :itemGrade="item.GRADE"
                              :itemGradeRank="item.GRADE_RANK"
                              :itemNickName="item.MEMBER_NICK"
                            />
                            <!-- {{item.MEMBER_NICK}} -->
                          </span>
                        </td>
                        <td>{{Constant.UTIL.numberWithCommas(Number(item.TOTAL_ASSET).toFixed(tradeType=='G'?2:0))}}</td>
                        <td>
                          <span class="rate">{{Constant.UTIL.numberWithCommas(Number(item.TOTAL_PROFIT).toFixed(tradeType=='G'?2:0))}}</span>
                        </td>
                        <td>
                          <span class="per"><i></i>{{Constant.UTIL.numberWithCommas(Math.abs(getProfitPer(Number(item.TOTAL_PROFIT), Number(item.PAYMONEY))).toFixed(2)) + '%' }}</span>
                        </td>
                        <td>{{ Constant.UTIL.numberWithCommas(item.TRADEDAYCOUNT) }}</td>
                        <td>{{ Constant.UTIL.numberWithCommas(Number(getRankForReward(item.RANK))) }}</td>
                      </tr>
                    </tbody>
                  </table>

                
                </div>

              </div>

            </div>

            <!-- 페이징 start-->
            <div class="section paging-area" v-if="arrRankingList.length">
              <v-pagination                  
                    v-model="currentPage"
                    :pages= numberOfPages
                    :rangeSize="1"
                    activeColor="#ff5d8b"                   
                    @update:modelValue="updateHandler(currentPage)"
                />
            </div>
            <!-- //페이징 end -->        

          </div>
          <!-- //랭킹 리스트 end -->

        </div>
        <!-- } Contents End -->
      </div>
      <!-- } Container End -->
    <Footer/>
</template>

<script>
import MyBoard from '@/components/ranking/MyBoard.vue';
import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import { useStore } from 'vuex';
import { computed, onMounted, ref , getCurrentInstance, onUnmounted } from 'vue';
import {useApicall} from '@/composables/commonFn';
import Constant from '@/constant.js';
import VPagination from "@hennge/vue3-pagination";
import '@/assets/css/vue3-pagination.css';
import moment from 'moment';
import UserNickGrade from '@/components/UserNickGrade.vue';


export default {
 components:{   
        HeaderTrade,
        Footer,
        MyBoard,
        VPagination,
        UserNickGrade
    },

  setup(){

      const store = useStore();
      const {apiCall_g} = useApicall();
      store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_0');
      store.commit('common/CHANGE_CURRENT_PAGE', 'Ranking');
      const isLogin = computed(() => store.state.user.isLogin);
      const spaceId = ref(null);
      const tradeType = ref('S');
      const arrSpaceList = ref([]);
      const arrRankingList = ref([]);
      const rSeletBox = ref(null);
      const currentPage = ref(1);
      const numberOfData = ref(0);
      const limit = ref(10);
      const numberOfPages = computed(()=>{
        return Math.ceil(numberOfData.value/limit.value)
      });
      const updateTime = ref(new Date()) 
      const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
      const getSpacelist = async()=>{
        var res = await apiCall_g(`index/getspacelist?type=${tradeType.value}&isRanking=Y&applyType=A`);
        spaceId.value = res.dataList[0].ID;
        spaceStatus.value = res.dataList[0].STATUS;
        return res.dataList;  
      }

      var rankCountTimer  = null;
      const spaceEndDate = ref([])
      const rankCntDays = ref('00');
      const rankCntHours = ref('00');
      const rankCntMinute = ref('00');
      const rankCntSecond = ref('00');

      const arrRewardList = ref([]);
      const spaceStatus = ref('');

     onMounted(async()=>{
         emitter.emit('EVENT_CHECK_LOGIN');
         arrSpaceList.value = await getSpacelist();
         arrRankingList.value =  await getRankList(1, spaceId.value);           
        // console.log("arrRankingList.value :", arrRankingList.value)
        // getEndDate();
        getRewardList();
     })

     onUnmounted(() => {
         if(rankCountTimer) clearInterval(rankCountTimer);
     })

    //  const getEndDate = () => {
    //     if(rankCountTimer) clearInterval(rankCountTimer);

    //     rankCntDays.value = "00";
    //     rankCntHours.value = "00";
    //     rankCntMinute.value = "00";
    //     rankCntSecond.value = "00";
    //     spaceEndDate.value = [];

    //     // var isSpaceList = (el) => {
    //     //   if(el.ID == spaceId.value) return true;
    //     // }
    //     // spaceEndDate.value = arrSpaceList.value.find(isSpaceList);
    //     spaceEndDate.value = arrSpaceList.value.find(element => element.ID == spaceId.value);
    //     rankCountTimer = setInterval(endDateDiff, 1000);
    //  }

    //  const endDateDiff = () => {
    //     var end = moment(spaceEndDate.value.ENDDATE, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss");
    //     var now = moment(new Date());
    //     var duration = moment.duration(now.diff(end));
    //     var timeBox = document.querySelector('.time-box');
          
    //     if( Math.sign(duration) == -1 ) {
    //       var day = duration.asDays();

    //       // console.log('day : ', typeof(moment(duration.hours(),"hh").format("HH")), day)
    //       rankCntDays.value = Math.floor(Math.abs(day));
    //       // rankCntDays.value = moment(duration.days(),"DD").format("DD");
    //       rankCntHours.value = moment(duration.hours(),"HH").format("HH");
    //       rankCntMinute.value = moment(duration.minutes(),"mm").format("mm");
    //       rankCntSecond.value = moment(duration.seconds(),"ss").format("ss");

    //       // if(test)return;
    //       if( duration.asDays() == 0 ) {
    //         rankCntDays.value = "00";
    //       }
    //       if( duration.hours() == 0 ) {
    //         rankCntHours.value = "00";
    //       }
    //       if( duration.minutes() == 0 ) {
    //         rankCntMinute.value = "00";
    //       }
            
    //       if(timeBox) timeBox.classList.remove('hidden');
    //       // console.log('음수' , duration);
    //     } else {
    //       if(timeBox) timeBox.classList.add('hidden');
    //       if(rankCountTimer) clearInterval(rankCountTimer);
    //     }
    //     // console.log("currentSpaceList =======>", spaceEndDate.value.ENDDATE, end, now, duration, duration.days(),duration.hours(),duration.minutes(),duration.seconds());
    //  }


    const getRewardList = async() => {    
      const res = await apiCall_g(`index/getrewardlist?spaceId=${spaceId.value}`);
      var result = res.dataList;
      // console.log("getRewardList:", result)
      if(!result || result==null || result==undefined) result = [];
      return arrRewardList.value = result
      // console.log("getRankForReward :", getRankForReward(4))
    }

    const getRankForReward = (rank) =>{
       var result = 0;
       arrRewardList.value.forEach(element => {
        if(rank >= parseInt(element.START_RANK) && rank <= parseInt(element.END_RANK) )
        {
        //console.log("element:", element.REWARD)
          result =  Number(element.REWARD);
        }
      });
      return result;
    }

     const changeData = async ( type )=>{
         arrRankingList.value = [];
         arrRewardList.value = [];
         tradeType.value = type;
         arrSpaceList.value = await getSpacelist();
         arrRankingList.value =  await getRankList(1, spaceId.value);  
         rSeletBox.value.value = spaceId.value;
         spaceStatus.value = arrSpaceList.value[0].STATUS;
        //  getEndDate();
         getRewardList();
     }

    const getProfitPer = ( totalProfit, paymoney)=>{
         //console.log( "getProfitPer :", profit, balance, paymoney)
         return (totalProfit/paymoney)*100
    }

    const getColor= (num)=>{
      var  result = ''
      if(num > 0)
      {
        result='up'
      }
      else if(num < 0)
      {
        result='down'
      }
      return result;
    }
    
     const onTapClick = (type) =>{
       updateTime.value = new Date();       
       changeData(type)
     }

     const onSelectChange = async ()=>{        
        arrRankingList.value = [];
        arrRewardList.value = [];    
        spaceId.value = rSeletBox.value.value;
        arrRankingList.value =  await getRankList(1, rSeletBox.value.value);            

        var arrSpaceStatus = [];
        arrSpaceStatus = arrSpaceList.value.find(element => element.ID == spaceId.value);   
        spaceStatus.value = arrSpaceStatus.STATUS;
        // console.log('spaceStatus.value===>', arrSpaceStatus, spaceStatus.value, )
        // getEndDate();
        getRewardList();
        // if(spaceStatus.value == 1) getRewardList();
     }

     const onRefresh = async() => {
        arrSpaceList.value = await getSpacelist();
        arrRankingList.value =  await getRankList(1, spaceId.value);
        spaceStatus.value = arrSpaceList.value[0].STATUS;
     }

      const updateHandler = async (page) => {
       
        arrRankingList.value =  await getRankList(page, spaceId.value);

        // console.log("updateHandler :", arrRankingList.value)
      }

      // const getRankList = async (spaceId) =>{
      //      var res = await apiCall_g(`index/getrankinglist?spaceId=${spaceId}&below_rank=10`);
      //      return res.dataList;  
      // }


      const getUpdateDate = () =>{
          if(arrRankingList.value[0])
          {
             return moment(arrRankingList.value[0].MODIFICATIONDATE, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:00");
          } 
          else
          {
              return  "0000-00-00 00:00:00"
          }
      }


      const getRankList  = async(page = currentPage.value, spaceId) =>{    
          currentPage.value = page; 
          //console.log("currentPage.value", currentPage.value)
          var offset = (currentPage.value -1)*limit.value;
          //var getUrl = `board/list?limit=${limit.value}&offset=${offset}&categoryType=${categoryType}&categoryCode=${categoryCode}`;

           var getUrl = `index/getrankinglist?spaceId=${spaceId}&below_rank=60&limit=${limit.value}&offset=${offset}`;      
           var res = await apiCall_g(getUrl);
         
          try{
              if(res.dataList == null) res.dataList = [];           
              numberOfData.value = res.dataCount;
          }catch(error)
          {
            res.dataList = [];
            numberOfData.value  = 0;
          }


          if(res.spaceOk == 'N' )
          {
            var prevTotalAsset = null;
            var prevRank = null
            for(let i=0; i<res.dataList.length; i++)
            {
              let _item = res.dataList[i];    
              if(prevTotalAsset == Number(_item.TOTAL_ASSET))
              {
                 _item.RANK = prevRank;
              }
              else
              {
                 _item.RANK = (i+1)+offset;
              }
              prevTotalAsset = Number(_item.TOTAL_ASSET)
              prevRank =  _item.RANK;
            }
          }


           //numberOfData.value = res.dataCount;
           //console.log("res.dataList", res.dataList)
           return res.dataList;  
      }

     return{
      onTapClick,
      isLogin,
      spaceId,
      tradeType,
      Constant,
      arrRankingList,
      getProfitPer,
      getColor,
      arrSpaceList,
      rSeletBox,
      onSelectChange,
      updateTime,
      onRefresh,
      numberOfPages,
      currentPage,
      updateHandler,
      getUpdateDate,
      spaceEndDate,
      rankCntDays,
      rankCntHours,
      rankCntMinute,
      rankCntSecond,
      arrRewardList,
      getRankForReward,
      spaceStatus,
     }
  }
}
</script>

<style>

</style>