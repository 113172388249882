<template>
     <HeaderTrade />
      <!-- Container Start { -->
      <div id="container" class="sts-trade-container">
        <!-- Contents Start { -->
        <div id="contents">

          <!-- 모의투자 content start -->
          <div class="trade-content-area">

             <ItemListCoinFutures
              :items="items"
              @onSelectItem="onSelectItem"
              tradeType="G"
             />

            <!-- 우측 start -->
            <div class="trade-container" :class="isLiveActive?'live':''">
              
              <div class="trade-container-top">

                <div class="trade-top-tab">
                  <!-- tab button -->
                  <ul class="nav nav-tabs" id="tradeTopTabButton" role="tablist">
                    <li class="nav-item" role="presentation">
                     <button class="nav-link"  :class="!isLiveActive?'active':''" @click="topTabClickHandle(false)">
                        모의투자
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" :class="isLiveActive?'active':''" @click="topTabClickHandle(true)">
                        실시간 방송
                      </button>
                    </li>
                  </ul>

                  <div class="trade-btn-layout">
                    <ul class="layout-list-btn">
                      <li><button type="button" class="btn-layout-search" @click="contsBoxClose('tradeSearchArea')">검색창접기</button></li>
                      <!-- <li><button type="button" class="btn-layout-item" @click="contsBoxClose('tradeResultArea')">보유종목접기</button></li> -->
                    </ul>
                  </div>

                  <!-- tab contents -->
                  <div class="tab-content" id="tradeTopTabContents">

                    <!-- 모의투자 start -->
                    <div   v-show="!isLiveActive">
                      <div class="trade-top-null" style="display:none;">데이터를 가져오지 못했습니다.<br>새로고침하여 주십시오.</div>

                        <div class="trade-top-contents">
                          <!-- 차트 영역 start -->
                          <ChartContainerCoinFutures 
                            :selectItem="selectItem"
                          />
                        
                          <!-- 호가 영역 start -->
                          <HTSContainerCF 
                            :selectItem="selectItem"                           
                            @change-select-price = "onChangeSelectPrice"
                          />

                          <TradeOrderContainerCF
                             :selectItem="selectItem"
                             :currentSelectOrderPrice="currentSelectOrderPrice" 
                           />


                      </div>
                    </div>
                    <!-- //모의투자 end -->

                    <!-- 실시간 방송 start -->
                    <div  v-if="isLiveActive">
                       <LiveIframe                         
                        :isActive="isLiveActive"
                       />
                    </div>
                    <!-- //실시간 방송 end -->

                  </div>
                </div>

              </div>

              <div class="trade-container-bottom">
                <!-- 체결 / 손익 start -->
                <div class="trade-bottom-contents">
                  <TradeResultCF
                    :items="items"
                     @onSelectItem="onSelectItem"
                   />
                  <LiveList
                   @select-live="onLiveSelect"
                   />
                </div>
                <!-- //체결 / 손익 end -->

              </div>

            </div>
            <!-- //우측 end -->

          </div>
          <!-- //모의투자 content end -->

          </div>
          <!-- } Contents End -->
    
    <!-- 레이어팝업 - popup : tp/sl 설정 start -->
    <div class="modal fade" id="modalTpsl" tabindex="-1" aria-labelledby="modal-tp-sl" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal-tp-sl">TP/SL 설정하기</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>        
          <div class="modal-body">

            <div class="tpsl-item-box">
              <ul class="tpsl-item-info">
                <li>
                  <span class="txt">진입가격</span>
                  <div class="num">
                    <span>1,988</span>
                    <span>KRW</span>
                  </div>
                </li>
                <li>
                  <span class="txt">청산가</span>
                  <div class="num">
                    <span>1,988</span>
                    <span>KRW</span>
                  </div>
                </li>
              </ul>
              <div class="tpsl-range-area">
                <p class="tit">Take Profit 설정</p>
                <div class="trade-item order-input">
                  <div class="item-group">
                    <label class="item-title" for="tpPrice">익절가</label>
                    <input type="text" id="tpPrice"  value="" class="form-control">
                    <span class="item-txt">KRW</span>
                  </div>
                </div>
                <div class="range">
                  <div class="range__slider">
                    <input type="range" id="tpRange" step="1">
                  </div>
                  <div class="range-value">
                    <input type="text" id="tpValue" class="form-control form-control-sm" readonly>
                    <span class="unit">%</span>
                  </div>
                </div>
              </div>
              <div class="tpsl-range-area">
                <p class="tit">Stop Loss 설정</p>
                <div class="trade-item order-input">
                  <div class="item-group">
                    <label class="item-title" for="slPrice">손절가</label>
                    <input type="text" id="slPrice"  value="" class="form-control">
                    <span class="item-txt">KRW</span>
                  </div>
                </div>
                <div class="range">
                  <div class="range__slider">
                    <input type="range" id="slRange" step="1">
                  </div>
                  <div class="range-value">
                    <input type="text" id="slValue" class="form-control form-control-sm" readonly>
                    <span class="unit">%</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">확인</button>
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
    <!-- //레이어팝업 - popup : tp/sl 설정 end -->

    <!-- 레이어팝업 - alert : 주문 취소 start -->
    <div class="modal fade modal-alert" id="alertOrderConfirmCancel" tabindex="-1" aria-labelledby="alert-order-confirm-cancel" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered">         
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="alert-order-confirm-cancel">주문확인</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>              
          <div class="modal-body">
            <p class="txt">
              주문을 취소 하시겠습니까?
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal">확인</button>
            <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 레이어팝업 - alert : 일괄 취소 end -->


      </div>
      <!-- } Container End -->
      <Footer/>
</template>

<script>

import HeaderTrade from '@/components/HeaderTrade.vue';
import Footer from '@/components/Footer.vue';
import ItemListCoinFutures from '@/components/trade/coinFutures/ItemListCoinFutures.vue';
import { useStore } from 'vuex';
import {ref, getCurrentInstance, onMounted, onUnmounted, onBeforeUnmount, computed, watch} from 'vue';
import { useScoket } from '@/socket';
import { useRoute }from 'vue-router';
import {useGetItemList, useSpaceId, useBalance} from '@/composables/vtradeFn';
import ChartContainerCoinFutures from '@/components/trade/coinFutures/ChartContainerCoinFutures.vue';
import HTSContainerCF from '@/components/trade/coinFutures/HTSContainer_cf.vue';
import LiveList from '@/components/trade/LivelistRight.vue';
import LiveIframe from '@/components/LiveIframe.vue';
import TradeOrderContainerCF from '@/components/trade/TradeOrderContainer_cf.vue';
import TradeResultCF from '@/components/trade/TradeResult_cf.vue';

export default {
  
components:{   
    HeaderTrade,
    Footer,
    ItemListCoinFutures,
    ChartContainerCoinFutures,
    HTSContainerCF,
    LiveList,
    TradeOrderContainerCF,
    TradeResultCF,
    LiveIframe
 },
 
 setup(){

    const isLiveActive = ref(false);
    const {socket} = useScoket(); 
    const route = useRoute();

      //초기데이터 임시
    var startObj = null
    if(sessionStorage.getItem('startItem_G'))
    {
      startObj = JSON.parse(sessionStorage.getItem('startItem_G'));     
    }
    const selectItem = ref(startObj);
  
    //var isSession  =  sessionStorage.getItem('isLive_g'); 
    
    //const  trace = getCurrentInstance().appContext.config.globalProperties.$log;     
    const internalInstance = getCurrentInstance(); 
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const store = useStore();
    const currentTradeType = ref("G");

    const items =  ref([]);  
    const currentSelectOrderPrice = ref(0);

    const  {getItemList} = useGetItemList()
    const {setCurrentSpaceId, setCurrentSpaceIdForEvent } = useSpaceId();
    const {setCurrentBalance} = useBalance(); 

    var  prevItem = null;
     
    const routeId = computed(()=> route.params.id )
    watch(routeId,()=>{
        // console.log("~~~~~~~~~~", routeId.value)
        if(route.params.id != '0')window.location.reload();    

    })

    if(route.params.id == '0')
    {
      store.commit('trade/CHANGE_CURRENT_LEAGUETYPE', 'normal');
      setCurrentSpaceId(currentTradeType.value);
      store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_0'); 
    }
    else
    {
      store.commit('trade/CHANGE_CURRENT_LEAGUETYPE', 'event');
      setCurrentSpaceIdForEvent(currentTradeType.value, route.params.id);
      store.commit('trade/CHANGE_CURRENT_PAGE_ID', 'navi_eventLeague_trade'); 
    }

    
    const isLocal =  ref(store.state.common.isLocal);

    setTimeout(()=>{      
      var t_price = Number(store.state.hts.trade.price);       
      if(!isNaN(t_price))
      {
            currentSelectOrderPrice.value = t_price; 
      }
    }, 1000);


    emitter.on('EVENT_INIT_TRADE_FOR_SPACE', (obj) => {
      emitter.off('EVENT_INIT_TRADE_FOR_SPACE');    
      store.commit('common/CHANGE_CURRENT_PAGE', "TRADE");  
      store.commit('hts/CHANGE_TRADE_TYPE', 'G');
      onSelectItem (startObj);
      if(obj == null)
      {
        store.commit('hts/CHANGE_SPACE_ID', null);
      }
      else
      {
        store.commit('hts/CHANGE_SPACE_ID', obj.id);             
        setCurrentBalance(obj.id);        
      }       
    })





    const onSelectItem = (item) =>{
      // trace("selectItem:~~~",  item)
      if(isLiveActive.value) 
      {         
        isLiveActive.value = false;
        sessionStorage.setItem('isLive_g', false);
      }

      // if(isSession == 'true')
      // {     
      //   isLiveActive.value = true;
      //   isSession = 'false';
      // }
 
      emitter.emit('EVENT_SELECTED_ITEM', item);
      emitter.emit('EVENT_SELECTED_ITEM_TOORDER', item); 
      selectItem.value = item; 
      store.commit('hts/CHANGE_CURRENT_ITEM', item);
      sessionStorage.setItem('startItem_G', JSON.stringify(item));
      initItem(item); 

      if(prevItem != item)
      {
        if(prevItem && prevItem.CODE)
        {
            socket.emit("leaveCode", {
                itemType:  prevItem.TYPE,
                code: prevItem.CODE
            }, function() { 
                //  console.log("leaveCode:",  item.CODE)    
            })                  
        }  
        
        initItem(item); 
      }     
      prevItem = item;




      // setTimeout(() => {
      //   store.dispatch('hts/getOrderList');
      //   store.dispatch('hts/getAssetList');
      // },500)
    }
   
    const initItem = (item) =>
    { 

   
       store.commit('hts/CHANGE_ORDERBOOK', []);
       store.commit('hts/CHANGE_INIT_TEADE', []);



        socket.emit("requestInit", {
          itemType: item.TYPE,
          code: item.CODE       
        }, function() {         
          setTimeout(()=>{
          socket.emit("requestCode", {
                itemType:  item.TYPE,
                code: item.CODE
            }, function() {                        
          })



            var t_price = Number(store.state.hts.trade.price);                   
            if(!isNaN(t_price))
            {
                  currentSelectOrderPrice.value = t_price; 
            }
          },500)
          
          emitter.emit('EVENT_INIT_ITEM_HTS');
          emitter.emit('EVENT_INIT_ITEM_HTS_CON');
        })
    }

     onBeforeUnmount(()=>{
       //console.log("onBeforeUnmount")
       isLiveActive.value = false;
     })

    onUnmounted(()=>{
      isLiveActive.value = false;
      if(prevItem && prevItem.CODE)
      {
          socket.emit("leaveCode", {
              itemType:  prevItem.TYPE,
              code: prevItem.CODE
          }, function() { 
              //  console.log("leaveCode:",  item.CODE)    
          })                  
      }  

    })

    onMounted(()=>{
      emitter.emit('EVENT_CHECK_LOGIN');
      //trace("route:~~", route.params) 
      getItemList(currentTradeType.value, items); 
       
    })
    
    // 검색 아이템 및 하단 결과 닫기 토글
    const contsBoxClose = (id) => {
      setTimeout(()=>{ emitter.emit('EVENT_SCROLL_RESET_HTS')},500);       
      document.getElementById(id).classList.toggle("hidden");
      contsBoxControl();
    }

    // 검색 아이템 및 하단 결과 닫기 토글
    const contsBoxControl = () => {
      let tradeContainer= document.querySelector('.trade-container');
      let tradeLeftArea = document.getElementById('tradeSearchArea');
      let tradeBottomArea = document.getElementById('tradeResultArea');
      let searchLayoutbtn = document.querySelector('.btn-layout-search');
      let layoutItembtn = document.querySelector('.btn-layout-item');

      if ( tradeLeftArea.classList.contains('hidden') ) {
        searchLayoutbtn.classList.add('on');
        tradeContainer.classList.add('w100');
      } else {
        searchLayoutbtn.classList.remove('on');
        tradeContainer.classList.remove('w100');
      }

      if ( tradeBottomArea.classList.contains('hidden') ) {
        layoutItembtn.classList.add('on');
        tradeContainer.classList.add('h100');
        // scrollReset('#tradeArea'); 
      } else {
        layoutItembtn.classList.remove('on');
        tradeContainer.classList.remove('h100');
        // scrollReset('#tradeArea'); 
      }        
    }

    // 모의투자, 라이브 탭 토글
    const topTabClickHandle = (_stt) => {
      isLiveActive.value = _stt;   
      if(_stt) 
      {
        if( !localStorage.getItem('isFirstNotice'))
        {
          localStorage.setItem('isFirstNotice', true)
          emitter.emit('EVENT_ALERT',{title:"알림", body:"최초 가입 한정 10렉스를 드립니다! 지금 후원하시고 열혈팬이 되어보세요!"});
        }
      }
     // sessionStorage.setItem('isLive_g', _stt);  
    }

    const onChangeSelectPrice = (e) =>{
      currentSelectOrderPrice.value = e     
    }

    const onLiveSelect = () =>{        
      if(!isLiveActive.value) 
      {
        isLiveActive.value = true;
       // sessionStorage.setItem('isLive_g', true);
      }
    }

    return{
        onSelectItem,
        selectItem,
        items,
        contsBoxClose,
        topTabClickHandle,
        onChangeSelectPrice,
        currentSelectOrderPrice,
        isLiveActive,
        isLocal,
        onLiveSelect,
        
    }
 }
}
</script>

<style>

</style>